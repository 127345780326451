import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { Icon } from '@upsales/components';
import './UpSelect.scss';

class UpSelect extends Component {
	constructor(props) {
		super(props);

		const value = props.storeIdInState
			? this.getIdFromDefaultValue(props.defaultValue)
			: props.defaultValue || null;
		this.state = { value };
	}
	id = Date.now();
	// eslint-disable-next-line camelcase
	UNSAFE_componentWillReceiveProps(nextProps) {
		const { value } = this.state;

		if (_.isArray(value) && _.isEqual(value, nextProps.defaultValue)) {
			return;
		}
		if (nextProps.defaultValue !== undefined && nextProps.defaultValue !== value) {
			const value = this.props.storeIdInState
				? this.getIdFromDefaultValue(nextProps.defaultValue)
				: nextProps.defaultValue;
			this.setState({ value });
			this.setSelect2Value(nextProps.defaultValue);
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps.disabled !== this.props.disabled) {
			const element = jQuery(this.input);
			element.select2('enable', !this.props.disabled);
		}
	}

	setRef(name, ref) {
		this[name] = ref;

		if (name === 'input' && this.props.inputRef) {
			if (typeof this.props.inputRef === 'function') {
				this.props.inputRef(ref);
			} else {
				this.props.inputRef.current = ref;
			}
		}
	}

	getIdFromDefaultValue = () => {
		const getId = object => (this.props.getId ? this.props.getId(object) : object.id);

		if (this.props.multiple) {
			if (Array.isArray(this.props.defaultValue)) {
				return this.props.defaultValue.map(getId);
			} else {
				return [];
			}
		} else {
			return this.props.defaultValue ? getId(this.props.defaultValue) : null;
		}
	};

	componentWillUnmount() {
		const element = jQuery(this.input);
		element.select2('destroy');
		// Calling .off() with no arguments removes all handlers attached to the elements.
		element.off();
	}

	setSelect2Value(value) {
		const input = jQuery(this.input);
		if (this.props.multiple) {
			if (value === undefined || value === null) {
				input.select2('data', []);
			} else {
				const val = Array.isArray(value) ? value : [value];
				input.select2('data', val);
			}
		} else {
			if (typeof value === 'object') {
				input.select2('data', value);
			} else {
				input.select2('val', value);
			}
		}
	}

	addOrRemoveStaticBtn = () => {
		const btn = document.querySelectorAll(`.UpSelect--${this.id} .UpSelect__static-add-btn`)[0];

		// Check if button should be removed
		if (btn && !this.props.staticAddButton) {
			btn.remove();
		} else if (!btn && this.props.staticAddButton) {
			const input = jQuery(this.input);
			const select2Drop = document.getElementsByClassName(`UpSelect--${this.id}`)[0];
			const addNewContactBtn = document.createElement('div');
			addNewContactBtn.classList.add(
				'UpSelect__static-add-btn',
				'UpSelect__overflowText',
				'Button',
				'up-btn',
				'btn-bright-blue',
				'btn-link',
				'btn-block'
			);
			addNewContactBtn.innerText = this.props.staticAddButton.label;
			select2Drop.appendChild(addNewContactBtn);
			addNewContactBtn.addEventListener('click', () => {
				input.select2('close');
				this.props.staticAddButton.onClick();
			});
		}
	};
	componentDidMount() {
		let opts = {
			allowClear: !this.props.required ? 1 : 0,
			multiple: this.props.multiple,
			placeholder: this.props.placeholder || ' ',
			formatSelection: this.props.formatSelection || ((object, container, escape) => escape(object.name)),
			formatResult: this.props.formatResult || ((object, container, query, escape) => escape(object.name)),
			id: this.props.getId || (object => object.id),
			matcher:
				this.props.matcher ||
				((term, undef, item) => {
					return item.name && item.name.toLowerCase().indexOf(term.toLowerCase()) !== -1;
				}),
			createSearchChoice: this.props.createSearchChoice || undefined,
			formatNoMatches: this.props.formatNoMatches || undefined,
			minimumResultsForSearch: this.props.minimumResultsForSearch || undefined,
			dropdownCssClass: `${this.props.dropdownCssClass || ''} UpSelect--${this.id}`
		};

		if (this.props.tags) {
			opts.tags = this.props.tags;
			opts.tokenSeparators = this.props.tokenSeparators || [',', ' '];
		}

		if (this.props.initSelection) {
			opts.initSelection = this.props.initSelection;
		}
		opts = _.merge({}, opts, this.props.options);

		// This is put after the merge otherwise merge clones the data array and you looses the reference to it
		if (this.props.data) {
			opts.data = this.props.data;
		}

		const input = jQuery(this.input);

		const select2 = input.select2(opts);

		const fixSelect2Value = value => {
			if (!Array.isArray(value)) {
				return value;
			}
			// idk why this happens but it does
			return value.filter(v => v !== '[object Object]');
		};

		select2.on('change', e => {
			this.props.onChange({
				target: {
					value: fixSelect2Value(e.val),
					added: fixSelect2Value(e.added),
					removed: fixSelect2Value(e.removed)
				}
			});
			this.setState({ value: fixSelect2Value(e.val) });
		});

		if (this.props.eventListeners) {
			_.each(this.props.eventListeners, function (fn, event) {
				select2.on(event, fn);
			});
		}

		if (!this.props.initSelection && this.state.value) {
			if (this.props.storeIdInState) {
				this.setSelect2Value(this.props.defaultValue);
			} else {
				this.setSelect2Value(this.state.value);
			}
		}

		if (this.props.autoFocus) {
			input.select2('focus');
		}

		if (this.props.autoOpen) {
			input.select2('open');
		}
		if (this.props.staticAddButton) {
			this.addOrRemoveStaticBtn();
		}
	}

	render() {
		return (
			<Fragment>
				{this.props.icon ? <Icon className="UpSelect__icon" name={this.props.icon} /> : null}
				<input
					id={this.props.id}
					value={this.props.selectedValues || this.state.value || ''}
					tabIndex={this.props.tabIndex || 0}
					disabled={this.props.disabled || false}
					className={this.props.className + (this.props.state === 'error' ? ' has-error' : '')}
					ref={this.setRef.bind(this, 'input')}
					type="hidden"
					autoComplete={this.props.autoComplete || 'off'}
				/>
				{/* To be able to scroll to element by name */}
				<span name={this.props.name} />
			</Fragment>
		);
	}
}

UpSelect.propTypes = {
	id: PropTypes.string,
	state: PropTypes.string,
	name: PropTypes.string,
	className: PropTypes.string,
	disabled: PropTypes.bool,
	autoFocus: PropTypes.bool,
	autoOpen: PropTypes.bool,
	options: PropTypes.object,
	multiple: PropTypes.bool,
	onChange: PropTypes.func.isRequired,
	required: PropTypes.bool,
	placeholder: PropTypes.string,
	icon: PropTypes.string,
	formatSelection: PropTypes.func,
	formatResult: PropTypes.func,
	matcher: PropTypes.func,
	tokenSeparators: PropTypes.arrayOf(PropTypes.string),
	tags: PropTypes.oneOfType([PropTypes.array, PropTypes.func]),
	dropdownCssClass: PropTypes.string,
	// I am not sure what the following should be
	data: PropTypes.any,
	initSelection: PropTypes.any,
	defaultValue: PropTypes.any,
	getId: PropTypes.any,
	createSearchChoice: PropTypes.any,
	formatNoMatches: PropTypes.func,
	minimumResultsForSearch: PropTypes.number,
	eventListeners: PropTypes.object,
	tabIndex: PropTypes.number,
	storeIdInState: PropTypes.bool,
	staticAddButton: PropTypes.shape({ label: PropTypes.string, onClick: PropTypes.func }),
	selectedValues: PropTypes.array,
	autoComplete: PropTypes.string,
	inputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })])
};

export default UpSelect;
