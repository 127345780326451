import React from 'react';

import { Modal } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import EditDocumentTemplate, { Props } from './EditDocumentTemplate';
import './EditDocumentTemplateModal.scss';
import { asyncModalAdapter } from 'App/helpers/angularPortingHelpers';

type EditDocumentTemplateModalProps = Props & { className?: string };

export default (props: EditDocumentTemplateModalProps) => {
	const classes = new BemClass('EditDocumentTemplateModal', props.className);
	return (
		<Modal className={classes.b()}>
			<EditDocumentTemplate {...props} />
		</Modal>
	);
};

export const openEditDocumentTemplateModal = asyncModalAdapter({
	upModalName: 'editDocumentTemplate',
	openModalName: 'EditDocumentTemplateModal',
	featureFlag: 'EDIT_DOCUMENT_TEMPLATE_REACT'
});
