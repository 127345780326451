import findAll from 'Helpers/findAll';
import Resource from './Resource';

export type HasId = {
	id: number;
};

export type Error = object | null;

export type Metadata = {
	limit: number;
	offset: number;
	total: number;
};

export default class ResourceTyped<
	GetType extends HasId,
	ListType = GetType,
	SaveType = Partial<GetType>,
	MetadataSave = Metadata
> extends Resource {
	eventName?: string;
	merge?(data: {
		from: number | number[];
		to: number;
		name: string;
	}): Promise<{ data: GetType; metadata?: MetadataSave; error: Error }>;

	find(filters = {}, opts = {}): Promise<{ data: ListType[]; metadata: Metadata; error: Error }> {
		return super.find(filters, opts);
	}

	get(id: number | string, opts = {}): Promise<{ data: GetType; metadata?: Metadata; error: Error }> {
		return super.get(id, opts);
	}

	save(data: SaveType, opts = {}): Promise<{ data: GetType; metadata?: MetadataSave; error: Error }> {
		return super.save(data, opts);
	}

	delete(id: number, opts = {}): Promise<{ error: Error }> {
		return super.delete(id, opts);
	}

	findAll(filters = {}, limit?: number) {
		return findAll(this.find, filters, limit);
	}

	count(filters = {}): Promise<number> {
		return super.count(filters);
	}
}
