import { Card, EllipsisTooltip, Flex, Icon, Select, Text, Tooltip } from '@upsales/components';
import { ThirdButton } from '@upsales/components/Buttons';
import IconName from '@upsales/components/Icon/IconName';
import BemClass from '@upsales/components/Utils/bemClass';
import { ColorsEnum } from '@upsales/components/Utils/colors';
import { SizesLargeEnum } from '@upsales/components/Utils/Sizes';
import UserStack from 'App/components/UserStack';
import Client from 'App/resources/Model/Client';
import Contact from 'App/resources/Model/Contact';
import { mapToSelectItem } from 'Components/EditProjectPlan/Helpers/Helpers';
import { getJourneyStep } from 'Components/Helpers/journeyStep';
import SubAccountLabel from 'Components/Misc/SubAccountLabel';
import React, { createContext, Fragment, useContext } from 'react';
import { useTranslation } from 'react-i18next';

/**
 * Classic: Regular dropdown select look
 * Full: Full details
 */
export type RowSkin = 'classic' | 'full';

type RowProps = {
	classes?: BemClass;
	contact?: Contact;
	client?: Client;
	highlighted: string | null;
	removable?: boolean;
	extraIcons: boolean;
	disabled?: boolean;
	skin?: RowSkin;
	removeSelectedRow?: () => void;
	onClick?: () => void;
	children?: React.ReactNode;
	id: string;
};

const initialProps = {
	highlighted: null,
	extraIcons: false,
	classes: new BemClass('ClientContactSelectRow'),
	id: '-1'
};

const ClientContactContext = createContext<RowProps>(initialProps);

const EllipsedTooltipText = ({
	text,
	className,
	icon,
	textSize,
	textColor
}: {
	text?: string;
	className?: string;
	icon?: IconName;
	textSize?: keyof typeof SizesLargeEnum;
	textColor?: keyof typeof ColorsEnum;
}) => (
	<EllipsisTooltip title={text}>
		{icon ? <Icon name={icon} space="mrm" /> : null}
		<Text ellipsis size={textSize} className={className} color={textColor}>
			{text}
		</Text>
	</EllipsisTooltip>
);
const Remove = () => {
	const { removable, removeSelectedRow, disabled } = useContext(ClientContactContext);
	return removable && !disabled ? (
		<Flex>
			<ThirdButton
				onClick={e => {
					e.stopPropagation();
					removeSelectedRow?.();
				}}
				color="gray"
			>
				<Icon name="times" />
			</ThirdButton>
		</Flex>
	) : null;
};

const FullSkin = () => {
	const { onClick, classes, id, highlighted, children } = useContext(ClientContactContext);
	return (
		<Card className={classes?.mod('normal').b()} data-id={id}>
			<Flex
				onClick={() => onClick?.()}
				alignItems="center"
				className={classes
					?.elem('row')
					.mod({ highlighted: id === highlighted })
					.b()}
			>
				{children}
			</Flex>
		</Card>
	);
};

const ClassicSkin = () => {
	const { children } = useContext(ClientContactContext);
	return <>{children}</>;
};

const ClientClassicSkin = () => {
	const { client, removeSelectedRow, disabled, onClick } = useContext(ClientContactContext);
	if (!client) {
		return null;
	}
	const selectedClient = mapToSelectItem(client);
	return (
		<Select
			disabled={disabled}
			multi={false}
			value={selectedClient}
			options={[]} // We show the courtain to show the options, but we want the select appearance
			onRemove={removeSelectedRow}
			onClear={removeSelectedRow}
			onClick={() => onClick?.()}
		/>
	);
};

const ClientFullSkin = () => {
	const { classes, client } = useContext(ClientContactContext);
	return (
		<>
			<Flex className={classes?.elem('userInfo').b()} direction="column" flex={1} space="mll">
				<Flex alignItems="center" justifyContent="left" direction="row">
					<EllipsedTooltipText text={client?.name} className={classes?.elem('row').elem('title').b()} />
					<SubAccountLabel operationalAccount={client?.operationalAccount ?? null} />
				</Flex>
				<Text size="sm" color="grey-11" ellipsis>
					{getJourneyStep(client?.journeyStep)?.name}
				</Text>
			</Flex>
			<Remove />
		</>
	);
};

const ContactClassicSkin = () => {
	const { contact, classes, removeSelectedRow, disabled, onClick } = useContext(ClientContactContext);
	if (!contact) {
		return null;
	}
	const selectedClient = mapToSelectItem(contact);
	return (
		<Select
			className={classes?.elem('classicSelected').b()}
			disabled={disabled}
			multi={false}
			value={selectedClient}
			options={[]}
			onRemove={removeSelectedRow}
			onClear={removeSelectedRow}
			onClick={() => onClick?.()}
		/>
	);
};

const ContactFullSkin = () => {
	const { classes, contact, extraIcons, client, removable } = useContext(ClientContactContext);
	const { t } = useTranslation();
	const isRelated = client ? client.id !== contact?.client.id : false;
	return (
		<>
			<Flex className={classes?.elem('userInfo').b()} direction="column" flex={1} space="mll">
				{isRelated ? (
					<EllipsedTooltipText
						text={contact?.name}
						className={classes?.elem('row').elem('title').b()}
						icon="sitemap"
					/>
				) : (
					<>
						<EllipsedTooltipText text={contact?.name} className={classes?.elem('row').elem('title').b()} />
						<EllipsedTooltipText
							text={contact?.title}
							className={classes?.elem('row').elem('subtitle').b()}
							textColor="grey-11"
						/>
					</>
				)}
				{contact?.client ? (
					<Flex space="prl" className={classes?.elem('row').elem('subtitle').b()}>
						<Text size="sm" color="grey-11" ellipsis>
							{contact?.client.name} • {getJourneyStep(contact?.journeyStep)?.name}
						</Text>
					</Flex>
				) : null}
			</Flex>
			<Flex
				alignContent="flex-end"
				space={removable ? 'plm' : 'plm prxl'}
				alignItems="center"
				justifyContent="space-between"
				gap={removable ? 'u3' : 'u6'}
			>
				{extraIcons && contact ? (
					<>
						<Tooltip disabled={!contact.email} title={contact.email}>
							<Icon color={contact.email ? 'black' : 'grey-7'} name="envelope" />
						</Tooltip>
						<Tooltip
							position="left"
							disabled={!contact.cellPhone && !contact.phone}
							title={`${contact.phone ? t('contact.row.phone') + contact.phone + '\n' : ''}${
								contact.cellPhone ? t('contact.row.mobile') + contact.cellPhone : ''
							}`}
						>
							<Icon color={contact.cellPhone || contact.phone ? 'black' : 'grey-7'} name="phone" />
						</Tooltip>
					</>
				) : null}
			</Flex>
			<Remove />
		</>
	);
};

const clientSkins = {
	full: ClientFullSkin,
	classic: ClientClassicSkin
};

const contactSkins = {
	full: ContactFullSkin,
	classic: ContactClassicSkin
};

const skins = {
	full: FullSkin,
	classic: ClassicSkin
};

const ClientContactSelectRow = ({
	classes,
	highlighted,
	removable,
	removeSelectedRow,
	onClick,
	extraIcons,
	children,
	id,
	contact,
	disabled,
	skin = 'full',
	client
}: RowProps) => {
	const getSkin = () => React.createElement(skins[skin]);
	return (
		<ClientContactContext.Provider
			value={{
				classes,
				highlighted,
				removable,
				removeSelectedRow,
				onClick,
				extraIcons,
				children,
				id,
				contact,
				disabled,
				skin,
				client
			}}
		>
			<Fragment>{getSkin()}</Fragment>
		</ClientContactContext.Provider>
	);
};

ClientContactSelectRow.Client = function Client() {
	const { skin } = useContext(ClientContactContext);
	return skin ? React.createElement(clientSkins[skin]) : null;
};

ClientContactSelectRow.SubAccount = function SubAccount() {
	const { client, classes } = useContext(ClientContactContext);
	const { t } = useTranslation();

	let address = null;
	if (client) {
		const streetAddress = client.addresses?.find(address => address.address?.length);
		address = streetAddress?.address;
	}

	return (
		<>
			<Flex
				className={classes?.elem('subAccountContainer').b()}
				alignContent="center"
				justifyContent="space-between"
				space="prl"
			>
				<Flex className={classes?.elem('subAccountInfo').b()} direction="column" space="mll">
					<Flex alignItems="center" justifyContent="left">
						<EllipsedTooltipText text={client?.name} className={classes?.elem('row').elem('title').b()} />
					</Flex>
					{address ? (
						<Text size="sm" color="grey-11" ellipsis>
							{address}
						</Text>
					) : null}
					{client?.operationalAccount ? (
						<Flex>
							<Text size="sm" color="grey-11">
								{t('account.subaccount.of')}
							</Text>
							<Text
								className={classes?.elem('subAccountName').b()}
								space="mls"
								size="sm"
								color="grey-11"
								bold
								ellipsis
							>
								{client.operationalAccount.name}
							</Text>
						</Flex>
					) : null}
				</Flex>
				{client?.users?.length ? <UserStack users={client.users} size="sm" /> : null}
			</Flex>
			<Remove />
		</>
	);
};

ClientContactSelectRow.Contact = function Contact() {
	const { skin } = useContext(ClientContactContext);
	return skin ? React.createElement(contactSkins[skin]) : null;
};

export default ClientContactSelectRow;
