import _ from 'lodash';

type Field = {
	visible: boolean;
	$required: boolean;
	required: boolean;
	name: string;
	$gettingValuesErr: unknown;
	$gettingValues: boolean;
	$tested?: { valid: boolean };
	defaultValue: string;
	dependencies: string[];
	externalValidation: boolean;
	label: string;
	externalValues: boolean;
	type: string;
	values?: { valid: unknown; message: unknown };
	value: string | { id: number };
	alwaysShowIfValue?: boolean;
	showForCustomers?: number[];
};
type Fields = Field[];

const validateDependency = function (field: Field, fields: Fields, config: { [k: string]: any }) {
	let valid = true;
	field.dependencies.forEach(function (dep) {
		const depField = _.find(fields, { name: dep });
		if (!depField?.externalValidation && !config[dep]) {
			valid = false;
		}

		if (depField?.externalValidation && (!depField?.$tested || !depField?.$tested.valid)) {
			valid = false;
		}
	});
	return valid;
};

type RunReturnType = Promise<{ valid: unknown; message: string } | { err: unknown }>;

type FieldDependentOnValue = { name: string; value: string };

const IntegrationHelper = {} as {
	getIntegrationLang: (langTagPrefix: string, element: string, type: string, fallback: string) => string;
	validateField: (fieldName: unknown, config: string, integrationId: number) => RunReturnType;
	validateFields: () => void;
	validateFieldData: (fieldName: string, importConfig: string, integrationId: number) => object | { err: unknown };
	checkField: (f: Field, fields: Fields, config: { [key: string]: boolean | FieldDependentOnValue }) => object;
	configStatus: (integrationId: number) => RunReturnType;
	getFieldValues: (f: Field, fields: Fields, integrationId: number, config: object) => Promise<Field>;
	getFieldValuesData: (f: Field, fields: Fields, integrationId: number, config: object) => Promise<unknown>;
	getFieldInitValue: (field: Field, currentValue: unknown | null) => unknown;
	configButtonClick: (forUser: boolean, fieldName: string, config: object, integrationId: number) => Promise<any>;
};

// Returns translated text for integration
IntegrationHelper.getIntegrationLang = function (langTagPrefix, element, type, fallback) {
	const t = Tools.$translate;
	const tag = 'integration.' + langTagPrefix + '.' + element + '.' + type;
	const translated = t(tag);

	if (translated === tag) {
		return fallback;
	}

	return translated;
};

// Returns promise, resolves to new validated fieldObject
IntegrationHelper.validateField = (fieldName, config, integrationId) => {
	return new Promise(resolve => {
		const opts = {
			integrationId,
			field: fieldName,
			config: JSON.parse(JSON.stringify(config))
		};

		Tools.StandardIntegration.data(Tools.AppService.getCustomerId())
			.test(opts)
			.then(function (test) {
				resolve({
					valid: test.data.valid,
					message: test.data.message
				});
			})
			.catch(function (e) {
				resolve({
					err: e
				});
			});
	});
};

// Returns promise, resolves to new validated fieldObject
IntegrationHelper.validateFieldData = async (fieldName, importConfig, integrationId) => {
	const customerId = Tools.AppService.getCustomerId();
	const data = {
		type: 'testField',
		typeId: fieldName,
		data: importConfig,
		integrationId: integrationId
	};

	try {
		const { data: testResult } = await Tools.StandardIntegration.data(customerId).run(data);
		return testResult;
	} catch (error) {
		return { err: error };
	}
};

// Checking for field dependencies and if they are valid, returns new field
IntegrationHelper.checkField = (f, fields, config) => {
	const customerId = Tools.AppService.getCustomerId();
	const field = Object.assign({}, f);
	let valid = true;
	field.visible = true;
	field.$required = true;

	if (field.dependencies) {
		field.dependencies.forEach(function (dep) {
			const [depName, depValues] = dep.split(':');
			const depField = _.find(fields, { name: depName });
			const configField = config[depName];

			if (!depField?.externalValidation && !configField) {
				valid = false;
				field.visible = false;
			}

			if (depValues && !depValues.split(',').includes((configField as FieldDependentOnValue).value)) {
				valid = false;
				field.visible = false;
			}

			if (depField?.externalValidation && (!depField?.$tested || !depField?.$tested.valid)) {
				valid = false;
				field.visible = false;
			}
		});
	}

	if (f?.alwaysShowIfValue && !!config[f.name]) {
		valid = true;
		field.visible = true;
	}

	if (!valid) {
		// can't be required atm
		field.$required = false;
	}

	if (!field.externalValues || field.values) {
		if (field.required) {
			field.$required = true;
		} else {
			field.$required = false;
		}
	}

	if (field.showForCustomers && !field.showForCustomers.includes(customerId)) {
		field.visible = false;
	}

	return field;
};

// Returns promise, resolves to new validated fieldArray
IntegrationHelper.validateFields = () => {};

// Returns promise, resolves to new validated fieldArray
IntegrationHelper.getFieldValues = (f, fields, integrationId, config) => {
	return new Promise(resolve => {
		const field = Object.assign({}, f);
		if (!field.externalValues) {
			return resolve(field);
		}
		// Do not fetch values if field has unmet dependencies
		if (field.dependencies && !validateDependency(field, fields, config)) {
			return resolve(field);
		}

		const opts = {
			integrationId,
			field: field.name,
			config: JSON.parse(JSON.stringify(config))
		};
		Tools.StandardIntegration.data(Tools.AppService.getCustomerId())
			.values(opts)
			.then(function (values) {
				field.values = values.data;
				if (field.type === 'textTemplate') {
					field.value = values.data[0].value;
				}
				return resolve(field);
			})
			.catch(function (err) {
				field.$gettingValuesErr = err;
				return resolve(field);
			});
	});
};

// Returns promise, resolves to new validated fieldArray
IntegrationHelper.getFieldValuesData = async (f, fields, integrationId, config) => {
	const field = Object.assign({}, f);

	if (!field.externalValues || field.values) {
		return field;
	}

	const customerId = Tools.AppService.getCustomerId();
	const data = {
		type: 'fieldValues',
		typeId: field.name,
		data: config,
		integrationId: integrationId
	};

	try {
		const { data: fieldOptions } = await Tools.StandardIntegration.data(customerId).run(data);
		field.values = fieldOptions;
	} catch (error) {
		field.$gettingValuesErr = error;
	}
	return field;
};

IntegrationHelper.configButtonClick = (forUser, fieldName, config, integrationId) => {
	return new Promise(resolve => {
		const data = {
			type: forUser ? 'userConfigButton' : 'configButton',
			data: { config: config },
			typeId: fieldName,
			integrationId: integrationId
		};
		Tools.StandardIntegration.data(Tools.AppService.getCustomerId())
			.run(data)
			.then(function (res: { data: { valid: unknown; message: unknown } }) {
				resolve({
					valid: res.data.valid,
					message: res.data.message
				});
			})
			.catch(function (err: Error) {
				resolve({
					err: err
				});
			});
	});
};

IntegrationHelper.getFieldInitValue = (field, currentValue) => {
	if (currentValue != null) {
		return currentValue;
	}
	let value = null;
	if (field.defaultValue && currentValue == null) {
		if (['select', 'multiselect'].indexOf(field.type) !== -1) {
			value = _.find(field.values || [], { value: field.defaultValue });
		} else {
			value = field.defaultValue;
		}
	}
	return value;
};

IntegrationHelper.configStatus = integrationId => {
	const data = {
		type: 'integrationConfig',
		typeId: 'status',
		integrationId
	};
	return Tools.StandardIntegration.data(Tools.AppService.getCustomerId()).run(data);
};

export default IntegrationHelper;
