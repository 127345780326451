import { Component, FunctionComponent, ComponentProps } from 'react';
import EditPhonecall from 'Components/EditPhonecall';
import EditTodo from 'Components/EditTodo';
import EditCallListDrawer from 'App/components/drawers/EditCallListDrawer';
import ExportDataReact from 'App/components/ExportDataReact/ExportDataReact';
import EditColumnsModal from 'App/components/EditColumnsModal';
import CreateTodo from 'Components/CreateTodo';
import CreateCall from 'Components/CreateCall';
import PlanPhonecallsAction from 'Components/PlanPhonecallsAction';
import PlanPhonecallsMulti from 'Components/PlanPhonecallsMulti';
import AdditionalBoardMembers from 'Components/AdditionalBoardMembers';
import UtmLinkGenerator from 'Components/UtmLinkGenerator';
import EditSocialEventAddContacts from 'Components/SocialEvent/EditSocialEventAddContacts';
import GoalSummaryDrawer from 'Components/GoalSummaryDrawer';
import NotificationDropdown from 'Components/NotificationDropdown';
import PlanPhonecallsTriggerAction from 'Components/PlanPhonecallsTriggerAction';
import CreateTodoTriggerAction from 'Components/CreateTodoTriggerAction';
import MergeContacts from 'App/components/MergeContacts';
import ReportcenterDrilldownDrawer from 'App/pages/Reportcenter/ReportcenterDrilldownDrawer';
import SubscriptionListDrawer from 'Components/Admin/SubscriptionIndexing/SubscriptionListDrawer/SubscriptionListDrawer';
import AccountListDrawer from 'Components/Admin/SubscriptionIndexing/AccountListDrawer/AccountListDrawer';
import ListDrawer from 'Components/ListDrawer/ListDrawer';
import SubscriptionIndexing from 'Components/Admin/SubscriptionIndexing/SubscriptionIndexingModal';
import NotificationDetailModal from 'App/components/NotificationDetailModal';
import SubscriptionConflictModal from 'Components/Admin/SubscriptionIndexing/SubscriptionConflictModal';
import ConfirmBuyProspectModal from 'App/components/ConfirmBuyProspect/ConfirmBuyProspectModal';
import TargetCalculator from 'Components/TargetCalculator';
import ApiKey from 'App/components/ApiKey';
import { MergeWithBoardmemberModal } from 'Components/MergeWithBoardmember';
import CompetitorDrawer from 'App/pages/Admin/CompetitorDrawer';
import SessionExpireWarning from 'Components/Modals/SessionExpireWarning';
import EditClient from 'App/components/EditClient';
import EditDynamicLink from 'App/components/EditDynamicLink';
import AccountDeactivationModal from 'App/babel/components/Account/AccountDeactivationModal';
import SentMail from 'Components/MailDrawers/SentMail';
import OpenAccountPlan from 'App/components/EditAccountPlan/OpenAccountPlan';
import NewSingleMail from 'Components/MailDrawers/NewSingleMail';
import AddLabelsMulti from 'Components/Modals/AddLabelsMulti';
import AcceptTerms from 'App/components/AcceptTerms';
import SendWebhook from 'App/components/SendWebhook';
import RequestSupport from 'App/components/RequestSupport';
import CreateFlow from 'App/components/CreateFlow';
import { CreateFormReact } from 'Components/CreateForm/CreateForm';
import CreateTemplateFlow from 'App/components/CreateTemplateFlow';
import SubscriptionPreview from 'App/components/SubscriptionPreview';
import { CreateSubscription, EditSubscription } from 'App/components/EditSubscription';
import MoveSubscription from 'App/components/MoveSubscription';
import SubscriptionPromoModal from 'App/components/EditSubscription/SubscriptionPromo';
import FlowLoopSettings from 'App/components/FlowLoopSettings';
import DeleteForbiddenAlert from 'App/components/DeleteForbiddenAlert';
import { LandingpageEditorModal } from 'App/components/LandingpageEditor/LandingpageEditor';
import LeadDrawer from 'Components/LeadDrawer';
import VisitDrawer from 'Components/VisitDrawer';
import { AssignModalReact } from 'Components/AssignModal/AssignModal';
import ForecastingDrawer from 'App/components/Forecasting';
import AllowAccess from 'App/components/AllowAccess';
import CleanMyData from 'App/components/CleanMyData';
import HandleDuplicates from 'App/components/HandleDuplicates';
import MailPreview from 'App/components/MailPreview';
import { MaxCeilingReachedOnSeatsReact } from 'Components/MaxCeilingReachedOnSeats/MaxCeilingReachedOnSeats';
import { ChooseMailIntegrationModal } from 'Components/Modals/ChooseMailIntegration';
import WonOpportunityWithSubs from 'App/components/WonOpportunityWithSubs';
import PriceListDrawer from 'App/components/PriceListDrawer';
import ProductBundleDrawer from 'App/components/ProductBundleDrawer';
import CreateAccount from 'App/pages/CreateAccount';
import EditProductBundleOrder from 'App/components/EditProductBundleOrder';
import NewMarketEvent from 'App/components/NewMarketEvent';
import EditClientTarget from 'App/components/EditClientTarget';
import ARRChangeDrawerV2 from 'App/components/ARRChangeDrawerV2';
import CreateTodosAction from 'App/components/CreateTodosAction';
import ProductSearch from 'Components/ProductSearch';
import ReportcenterTV from 'App/pages/Reportcenter/ReportcenterTV';
import FindSocialEventModal from 'App/components/FindSocialEventModal';
import FindClientModal from 'App/components/FindClientModal';
import FindFormModal from 'App/components/FindFormModal';
import EditFormSubmitModal from 'App/components/EditFormSubmitModal/EditFormSubmitModal';
import FindContactModal from 'App/components/FindContactModal';
import SignalsNews from 'App/components/SignalsNews';
import RisksCompanyGroup from 'App/components/RisksCompanyGroup';
import UpsellCompanyGroup from 'App/components/UpsellCompanyGroup';
import ReportcenterTVSettings from 'App/pages/Reportcenter/ReportcenterTV/ReportcenterTVSettings';
import UserSurvey from 'App/components/UserSurvey';
import EditProductCategory from 'App/components/EditProductCategory';
import CopySocialEvent from 'Components/SocialEvent/CopySocialEvent';
import Alert, { UnsavedChangesAlert, RemoveAlert } from 'App/components/Alerts';
import EditMailSignatureRoot from 'Components/MailSignature/EditMailSignatureModal';
import CreateUpsalesAccount from 'Components/CreateAccount';
import RefreshAccount from 'Components/Modals/RefreshAccount/RefreshAccount';
import EditSocialEventAddResource from 'App/babel/components/SocialEvent/EditSocialEventAddResource';
import FullTermsModal from 'App/babel/components/Modals/FullTermsModal';
import AnonFieldPage from 'App/babel/components/anonymization/fieldPage/fieldPage';
import MultiUpdateActivity from 'App/components/MultiActionModal/MultiUpdateActivity/MultiUpdateActivity';
import MultiUpdateClient from 'App/components/MultiActionModal/MultiUpdateClient/MultiUpdateClient';
import MultiCloseActivityModal from 'App/components/MultiActionModal/MultiCloseActivity/MultiCloseActivity';
import AskForInput from 'App/components/Alerts/AskForInput/AskForInput';
import MatchCompaniesBeforeMerge from 'App/components/MatchCompaniesBeforeMerge';
import DuplicateIntroModal from 'App/components/DuplicateIntroModal';
import { AddToSegmentModal } from 'Components/addToSegmentModal/addToSegmentModal';
import { TemplateEditorModal } from 'Components/Admin/DocumentTemplate/TemplateEditor';
import InactivateContacts from 'App/components/CleanMyData/InactivateContacts/InactivateContacts';
import EditTicketResponseTemplate from 'Components/EditTicketResponseTemplate';
import EditTicketAutoReplyTemplate from 'Components/EditTicketAutoReplyTemplate/EditTicketAutoReplyTemplate';
import RiskDrawer from 'App/components/RiskDrawer';
import EditOrder from 'App/components/EditOrder';
import OpenTicket from 'App/components/EditTicket';
import { VerifyTimeframeModal } from 'Components/Admin/SalesProcess/SalesCoach/Modals/SalesCoachModal/VerifyTimeframe/VerifyTimeframe';
import EditProjectPlanTemplate from 'Components/Admin/ProjectPlanTemplate/Modals/EditProjectPlanTemplate/EditProjectPlanTemplate';
import EditTaskTemplate from 'Components/Admin/ProjectPlanTemplate/Modals/EditTaskTemplate';
import { EditEventDomainModal } from 'Components/Admin/EditEventDomain';
import { ImageUrlModal } from 'Components/ImageUrl';
import EditProjectPlan from 'Components/EditProjectPlan';
import CreateRelation from 'Components/CreateRelation';
import ViewTickets from 'App/components/ViewTickets';
import OptIn from 'App/components/OptIn/OptIn';
import SubaccountDrawer from 'Components/SubaccountDrawer/SubaccountDrawer';
import IndustryInsightsDrawer from 'Components/IndustryInsights/IndustryInsightsDrawer';
import AgreementFulfillmentModal from 'App/components/AgreementFulfillmentModal';
import LostOpportunityModal from 'App/components/LostOpportunity/LostOpportunityModal';
import ListModal from 'App/components/List/List';
import UploadFileModal from 'App/components/UploadFileModal';
import SelectFieldModal from 'App/components/SelectFieldModal';
import EditNote from 'Components/Modals/EditNote';
import MoveOrCloseActivity from 'App/components/MoveOrCloseActivity/MoveOrCloseActivity';
import MoveContactModal from 'App/components/MoveContactModal/MoveContactModal';
import ConfirmBuyProspectsModal from 'App/components/ConfirmBuyProspects/ConfirmBuyProspectsModal';
import { CreateGroupMailReact as CreateGroupMail } from 'App/babel/components/CreateGroupMail/CreateGroupMail';
import StageListModal from 'App/components/StageListModal/StageListModal';
import CreateDocumentModal from 'App/components/CreateDocument/CreateDocumentModal';
import PdfGeneric from 'Components/CreateAccount/PdfGeneric';
import { PreviewPdfModal } from 'Components/Admin/DocumentTemplate/PreviewPdf/PreviewPdf';
import ConfirmEsignModal from 'App/components/Esign/ConfirmEsign/ConfirmEsign';
import { SalesCoachModalReact } from 'Components/Admin/SalesProcess/SalesCoach/Modals/SalesCoachModal/SalesCoachModal';
import { AccountRelationModal } from 'Components/Account/AccountRelationModal/AccountRelationModal';
import { AssignModalLead } from 'Components/AssignModal/AssignModalLead';
import { CreateActivityModal } from 'Components/Modals/CreateActivity/CreateActivity';
import { OptOutModal } from 'Components/Admin/OptOutModal';
import { WonOpportunityModal } from 'App/components/WonOpportunity/WonOpportunity';
import { YourYearWithUpsalesModal } from 'Components/YourYearWithUpsales/YourYearWithUpsales';
import InsertTag from 'App/components/InsertTag/InsertTag';
import LandingPageSettings from 'Components/LandingpageSettings';
import EditAppointment from 'Components/Modals/Appointment/EditAppointment';
import { FileBrowserModal } from 'Components/FileBrowser';
import { EditProductModal } from 'Components/Modals/EditProduct/EditProduct';
import OpenCallModal from 'App/components/OpenCallModal';
import ClientListModal from 'App/components/ClientListModal/ClientListModal';
import { SharedViewsModal } from 'Components/Admin/SharedViews/SharedViewsModal';
import { VerifyBudgetModal } from 'Components/Admin/SalesProcess/SalesCoach/Modals/SalesCoachModal/VerifyBudget/VerifyBudget';
import { SolutionModal } from 'Components/Admin/SalesProcess/SalesCoach/Modals/SalesCoachModal/Solution/Solution';
import { ToggleFiscalYearModalReact } from 'Components/Admin/ToggleFiscalYearModal/ToggleFiscalYearModal';
import CreateReportModal from 'Components/Insights/CreateReportModal';
import { MailTemplateBrowserModal } from 'App/components/MailTemplateBrowserModal/MailTemplateBrowserModal';
import MultiUpdateContact from 'App/components/MultiActionModal/MultiUpdateContact/MultiUpdateContact';
import IntegrationModal from 'Components/IntegrationModal/IntegrationModal';
import GenericModal from 'App/components/GenericModal/GenericModal';
import EditRoleModal from 'App/components/EditRoleModal/EditRoleModal';
import { ConfirmLandingPageModal } from 'Components/Modals/ConfirmLandingPage';
import { EditCustomFieldModal } from 'Components/Modals/Fields/EditCustomField';
import AppWidget from 'Components/Modals/AppWidgetModal/AppWidgetModal';
import MultiUpdateAppointmentModal from 'App/components/MultiActionModal/MultiUpdateAppointment/MultiUpdateAppointment';
import SelectOpportunity from 'App/components/SelectOpportunity/SelectOpportunity';
import DelayedPayment from 'App/components/DelayedPayment/DelayedPayment';
import ResetScore from 'App/components/ResetScore/ResetScore';
import ValidDomainModal from 'Components/ValidDomainModal/ValidDomainModal';
import ExportContactDataModal from 'App/components/ExportContactDataModal/ExportContactDataModal';
import { EditStandardFieldModal } from 'Components/Admin/EditStandardFieldModal';
import UpdateClientDuns from 'App/components/UpdateClientDuns/UpdateClientDuns';
import MergeClients from 'App/components/MergeClients';
import EditDocumentTemplateModal from 'App/components/EditDocumentTemplate/EditDocumentTemplateModal';
import ConfirmSoliditetUpdateModal from 'App/components/ConfirmSoliditetUpdateModal';
import DownloadList from 'App/components/DownloadList/DownloadList';
import { TranslateFieldsModal } from 'App/babel/components/Admin/TranslateFieldModal';
import { NewSegmentModal } from 'App/components/NewSegment/NewSegmentModal';
import SendEmailAction from 'App/components/SendEmailAction/SendEmailAction';
import ConfirmParentProspecting from 'App/babel/components/Modals/ConfirmParentProspecting/ConfirmParentProspecting';
import { NextStepModal } from 'Components/Admin/SalesProcess/SalesCoach/Modals/SalesCoachModal/NextStep/NextStep';
import { ChecklistItemModal } from 'Components/Admin/SalesProcess/SalesCoach/Modals/ChecklistItem/ChecklistItem';
import EditCampaignModal from 'App/components/EditCampaign/EditCampaign';
import { DecisionMakersModal } from 'Components/Admin/SalesProcess/SalesCoach/Modals/SalesCoachModal/DecisionMakers/DecisionMakers';
import CreateActivityActionModal from 'App/components/MultiActionModal/CreateActivityAction/CreateActivityAction';
import { DecisionMakersProModal } from 'Components/Admin/SalesProcess/SalesCoach/Modals/SalesCoachModal/DecisionMakersPro/DecisionMakersPro';
import ConfirmSoliditetBuyParentModal from 'App/components/ConfirmSoliditetBuyParent/ConfirmSoliditetBuyParent';
import { CreateMailTemplateModal } from 'Components/CreateMailTemplate';
import { EditEsign } from 'App/components/Esign/EditEsign/EditEsign';
import CreateExternalContact from 'App/components/EditTicket/EditTicket/CreateExternalContact';
import { ResetLeadMulti } from 'App/components/ResetLeadMulti/ResetLeadMulti';
import RelationsSignalDrawer from 'App/components/RelationsSignalWidget/components/RelationsSignalDrawer/RelationsSignalDrawer';
import MergeTickets from 'App/components/MergeTickets';

export enum Animation {
	Fade = 'fade',
	SlideBottom = 'slide-bottom',
	DrawerRight = 'drawer-right',
	None = 'none'
}

export type ModalOpts = {
	closeOnCurtain?: boolean;
	animation?: Animation;
	drawer?: boolean;
	closeOnEscape?: boolean;
};

export type ModalConfigName =
	| 'LeadDrawer'
	| 'ApiKey'
	| 'VisitDrawer'
	| 'ForecastingDrawer'
	| 'EditPhonecall'
	| 'EditCallList'
	| 'ExportDataReact'
	| 'EditColumnsModal'
	| 'MultiUpdateActivity'
	| 'MultiUpdateClient'
	| 'MultiCloseActivityModal'
	| 'EditTodo'
	| 'CreateTodo'
	| 'CreateCall'
	| 'CreateTodosAction'
	| 'PlanPhonecallsAction'
	| 'PlanPhonecallsMulti'
	| 'AdditionalBoardMembers'
	| 'UtmLinkGenerator'
	| 'AddSocialEventContacts'
	| 'GoalSummaryDrawer'
	| 'NotificationDropdown'
	| 'PlanPhonecallsTriggerAction'
	| 'CreateTodoTriggerAction'
	| 'ReportcenterDrilldownDrawer'
	| 'SubscriptionListDrawer'
	| 'AccountListDrawer'
	| 'ListDrawer'
	| 'TargetCalculator'
	| 'MergeWithBoardmemberModal'
	| 'CompetitorDrawer'
	| 'SignalsNews'
	| 'RisksCompanyGroup'
	| 'UpsellCompanyGroup'
	| 'SessionExpireWarning'
	| 'EditClient'
	| 'AccountDeactivationModal'
	| 'SentMail'
	| 'EditAccountPlan'
	| 'NewSingleMail'
	| 'ChooseMailIntegration'
	| 'AddLabelsMulti'
	| 'AcceptTerms'
	| 'RequestSupport'
	| 'SendWebhook'
	| 'CreateFlow'
	| 'CreateForm'
	| 'CreateTemplateFlow'
	| 'EditDynamicLink'
	| 'SubscriptionPreview'
	| 'CreateSubscription'
	| 'EditSubscription'
	| 'MoveSubscription'
	| 'SubscriptionPromoModal'
	| 'SubscriptionIndexing'
	| 'SubscriptionConflictModal'
	| 'ConfirmBuyProspectModal'
	| 'FlowLoopSettings'
	| 'WonOpportunityWithSubs'
	| 'DeleteForbiddenAlert'
	| 'AllowAccess'
	| 'CleanMyData'
	| 'HandleDuplicates'
	| 'MailPreview'
	| 'MaxCeilingReachedOnSeats'
	| 'PriceListDrawer'
	| 'ProductBundleDrawer'
	| 'CreateAccount'
	| 'EditProductBundleOrder'
	| 'NewMarketEvent'
	| 'EditClientTarget'
	| 'NotificationDetail'
	| 'ARRChangeDrawerV2'
	| 'ProductSearch'
	| 'EditTicket'
	| 'CreateExternalContact'
	| 'CopySocialEvent'
	| 'AssignModal'
	| 'ReportcenterTV'
	| 'FindSocialEvent'
	| 'FindClient'
	| 'FindForm'
	| 'EditFormSubmit'
	| 'FindContact'
	| 'ToggleFiscalYear'
	| 'MoveContact'
	| 'ReportcenterTVSettings'
	| 'UserSurvey'
	| 'EditProductCategory'
	| 'Alert'
	| 'FullTerms'
	| 'UnsavedChangesAlert'
	| 'RemoveAlert'
	| 'AskForInput'
	| 'MatchCompaniesBeforeMerge'
	| 'MergeContacts'
	| 'DuplicateIntroModal'
	| 'InactivateContacts'
	| 'EditMailSignature'
	| 'CreateUpsalesAccount'
	| 'RefreshAccount'
	| 'EditSocialEventAddResource'
	| 'RiskDrawer'
	| 'AnonFieldPage'
	| 'EditOrder'
	| 'AddToSegmentModal'
	| 'ViewTickets'
	| 'VerifyTimeframeModal'
	| 'EditTicketResponseTemplate'
	| 'EditTicketAutoReplyTemplate'
	| 'EditProjectPlanTemplate'
	| 'EditTaskTemplate'
	| 'ImageUrlModal'
	| 'EditProjectPlan'
	| 'CreateRelation'
	| 'SubaccountDrawer'
	| 'IndustryInsightsDrawer'
	| 'TemplateEditorModal'
	| 'AgreementFulfillmentModal'
	| 'LostOpportunityModal'
	| 'MergeClients'
	| 'ConfirmParentProspecting'
	| 'DelayedPayment'
	| 'UpdateClientDuns'
	| 'ListModal'
	| 'OptIn'
	| 'ConfirmBuyProspectsModal'
	| 'SelectOpportunity'
	| 'DownloadList'
	| 'UploadFileModal'
	| 'SelectFieldModal'
	| 'EditNote'
	| 'MoveOrCloseActivity'
	| 'CreateGroupMail'
	| 'StageListModal'
	| 'CreateDocumentModal'
	| 'PdfGeneric'
	| 'PreviewPdfModal'
	| 'ConfirmEsignModal'
	| 'AccountRelationModal'
	| 'NextStepModal'
	| 'TranslateFieldsModal'
	| 'CreateMailTemplate'
	| 'AssignModalLead'
	| 'FileBrowserModal'
	| 'CreateActivityModal'
	| 'OptOutModal'
	| 'WonOpportunityModal'
	| 'SalesCoachModal'
	| 'YourYearWithUpsalesModal'
	| 'InsertTag'
	| 'LandingPageSettings'
	| 'EditAppointment'
	| 'ClientListModal'
	| 'DecisionMakersModal'
	| 'VerifyBudgetModal'
	| 'EditEventDomainModal'
	| 'AddProductModal'
	| 'SharedViewsModal'
	| 'SolutionModal'
	| 'OpenCall'
	| 'CreateReportModal'
	| 'MailTemplateBrowserModal'
	| 'MultiUpdateContact'
	| 'EditCustomFieldModal'
	| 'IntegrationModal'
	| 'EditRoleModal'
	| 'GenericModal'
	| 'ConfirmSoliditetBuyParentModal'
	| 'ConfirmLandingPageModal'
	| 'ResetLeadMulti'
	| 'EditEsign'
	| 'ResetScore'
	| 'AppWidgetModal'
	| 'MultiUpdateAppointmentModal'
	| 'EditCampaign'
	| 'EditStandardFieldModal'
	| 'ExportContactData'
	| 'ValidDomainModal'
	| 'EditDocumentTemplateModal'
	| 'ConfirmSoliditetUpdateModal'
	| 'NewSegmentModal'
	| 'ChecklistItemModal'
	| 'CreateActivityAction'
	| 'SendEmailAction'
	| 'DecisionMakersProModal'
	| 'LandingpageEditorModal'
	| 'RelationsSignalDrawer'
	| 'MergeTickets';

export type ModalConfig = {
	component: FunctionComponent<any> | typeof Component;
	opts?: ModalOpts;
	props?: { [key: string]: any; onClose?: (data?: any) => void };
};

const configs = {
	LeadDrawer: {
		component: LeadDrawer,
		opts: { drawer: true, animation: Animation.DrawerRight, closeOnCurtain: true }
	},
	ApiKey: {
		component: ApiKey,
		opts: { animation: Animation.SlideBottom }
	},
	VisitDrawer: {
		component: VisitDrawer,
		opts: { drawer: true, animation: Animation.DrawerRight, closeOnCurtain: true }
	},
	ForecastingDrawer: {
		component: ForecastingDrawer,
		opts: { drawer: true, animation: Animation.DrawerRight, closeOnCurtain: true }
	},
	EditPhonecall: {
		component: EditPhonecall,
		opts: { drawer: true, animation: Animation.DrawerRight, closeOnCurtain: true }
	},
	EditCallList: {
		component: EditCallListDrawer,
		opts: { drawer: true, animation: Animation.DrawerRight, closeOnCurtain: true }
	},
	ExportDataReact: {
		component: ExportDataReact,
		opts: { closeOnCurtain: true, closeOnEscape: true }
	},
	EditColumnsModal: {
		component: EditColumnsModal,
		opts: { closeOnCurtain: true, closeOnEscape: true }
	},
	MultiUpdateActivity: {
		component: MultiUpdateActivity,
		opts: { drawer: false, animation: Animation.SlideBottom, closeOnCurtain: true }
	},
	MultiUpdateClient: {
		component: MultiUpdateClient,
		opts: { drawer: false, animation: Animation.SlideBottom, closeOnCurtain: true, closeOnEscape: true }
	},
	MultiCloseActivityModal: {
		component: MultiCloseActivityModal,
		opts: { drawer: false, animation: Animation.SlideBottom, closeOnCurtain: true }
	},
	EditTodo: { component: EditTodo, opts: { drawer: true, animation: Animation.DrawerRight, closeOnCurtain: true } },
	CreateTodo: {
		component: CreateTodo,
		opts: { drawer: true, animation: Animation.DrawerRight, closeOnCurtain: true }
	},
	CreateCall: {
		component: CreateCall,
		opts: { drawer: true, animation: Animation.DrawerRight, closeOnCurtain: true }
	},
	CreateTodosAction: {
		component: CreateTodosAction,
		opts: { drawer: true, animation: Animation.DrawerRight, closeOnCurtain: true }
	},
	PlanPhonecallsAction: {
		component: PlanPhonecallsAction,
		opts: { drawer: true, animation: Animation.DrawerRight, closeOnCurtain: true }
	},
	PlanPhonecallsMulti: {
		component: PlanPhonecallsMulti,
		opts: { drawer: true, animation: Animation.DrawerRight, closeOnCurtain: true }
	},
	AdditionalBoardMembers: {
		component: AdditionalBoardMembers,
		opts: { drawer: true, animation: Animation.DrawerRight, closeOnCurtain: true, closeOnEscape: true }
	},
	UtmLinkGenerator: {
		component: UtmLinkGenerator,
		opts: { drawer: true, animation: Animation.DrawerRight, closeOnCurtain: true }
	},
	NotificationDetail: {
		component: NotificationDetailModal,
		opts: { animation: Animation.Fade }
	},
	AddSocialEventContacts: {
		component: EditSocialEventAddContacts,
		opts: { animation: Animation.Fade },
		props: { modal: true }
	},
	GoalSummaryDrawer: {
		component: GoalSummaryDrawer,
		opts: { drawer: true, animation: Animation.DrawerRight, closeOnCurtain: true }
	},
	NotificationDropdown: {
		component: NotificationDropdown,
		opts: { drawer: true, animation: Animation.DrawerRight, closeOnCurtain: true }
	},
	PlanPhonecallsTriggerAction: {
		component: PlanPhonecallsTriggerAction,
		opts: { drawer: true, animation: Animation.DrawerRight, closeOnCurtain: true }
	},
	CreateTodoTriggerAction: {
		component: CreateTodoTriggerAction,
		opts: { drawer: true, animation: Animation.DrawerRight, closeOnCurtain: true }
	},
	ReportcenterDrilldownDrawer: {
		component: ReportcenterDrilldownDrawer,
		opts: { drawer: true, animation: Animation.DrawerRight, closeOnCurtain: true }
	},
	SubscriptionListDrawer: {
		component: SubscriptionListDrawer,
		opts: { drawer: true, animation: Animation.DrawerRight, closeOnCurtain: true }
	},
	AccountListDrawer: {
		component: AccountListDrawer,
		opts: { drawer: true, animation: Animation.DrawerRight, closeOnCurtain: true }
	},
	ListDrawer: {
		component: ListDrawer,
		opts: { drawer: true, animation: Animation.DrawerRight, closeOnCurtain: true, closeOnEscape: true }
	},
	TargetCalculator: { component: TargetCalculator, opts: { drawer: true, animation: Animation.DrawerRight } },
	MergeWithBoardmemberModal: {
		component: MergeWithBoardmemberModal,
		opts: { closeOnCurtain: true }
	},
	CompetitorDrawer: {
		component: CompetitorDrawer,
		opts: { drawer: true, animation: Animation.DrawerRight, closeOnCurtain: true }
	},
	SignalsNews: {
		component: SignalsNews,
		opts: { drawer: true, animation: Animation.DrawerRight, closeOnCurtain: true, closeOnEscape: true }
	},
	RisksCompanyGroup: {
		component: RisksCompanyGroup,
		opts: { drawer: true, animation: Animation.DrawerRight, closeOnCurtain: true, closeOnEscape: true }
	},
	UpsellCompanyGroup: {
		component: UpsellCompanyGroup,
		opts: { drawer: true, animation: Animation.DrawerRight, closeOnCurtain: true, closeOnEscape: true }
	},
	SessionExpireWarning: {
		component: SessionExpireWarning,
		opts: {
			animation: Animation.Fade
		}
	},
	SubscriptionIndexing: {
		component: SubscriptionIndexing,
		opts: {
			animation: Animation.SlideBottom
		}
	},
	SubscriptionConflictModal: {
		component: SubscriptionConflictModal,
		opts: {
			animation: Animation.SlideBottom
		}
	},
	EditClient: {
		component: EditClient,
		opts: {
			animation: Animation.SlideBottom
		}
	},
	AccountDeactivationModal: {
		component: AccountDeactivationModal,
		opts: {
			animation: Animation.Fade
		}
	},
	AssignModal: {
		component: AssignModalReact,
		opts: { closeOnEscape: true }
	},
	SentMail: {
		component: SentMail,
		opts: { drawer: true, animation: Animation.DrawerRight, closeOnCurtain: false }
	},
	EditAccountPlan: {
		component: OpenAccountPlan,
		opts: { drawer: true, animation: Animation.DrawerRight }
	},
	NewSingleMail: {
		component: NewSingleMail,
		opts: { drawer: true, animation: Animation.DrawerRight }
	},
	ChooseMailIntegration: {
		component: ChooseMailIntegrationModal,
		opts: { animation: Animation.Fade }
	},
	AddLabelsMulti: {
		component: AddLabelsMulti,
		opts: { animation: Animation.Fade }
	},
	AcceptTerms: {
		component: AcceptTerms,
		opts: { animation: Animation.SlideBottom, closeOnCurtain: false }
	},
	RequestSupport: {
		component: RequestSupport,
		opts: { animation: Animation.SlideBottom, closeOnCurtain: false }
	},
	SendWebhook: {
		component: SendWebhook,
		opts: {
			animation: Animation.SlideBottom
		}
	},
	CreateFlow: {
		component: CreateFlow,
		opts: {
			animation: Animation.SlideBottom
		}
	},
	CreateForm: {
		component: CreateFormReact,
		opts: {
			animation: Animation.SlideBottom
		}
	},
	CreateTemplateFlow: {
		component: CreateTemplateFlow,
		opts: {
			animation: Animation.Fade
		}
	},
	EditDynamicLink: {
		component: EditDynamicLink,
		opts: {
			closeOnCurtain: true,
			animation: Animation.SlideBottom
		}
	},
	SubscriptionPreview: {
		component: SubscriptionPreview,
		opts: {
			animation: Animation.SlideBottom,
			closeOnCurtain: true
		}
	},
	CreateSubscription: {
		component: CreateSubscription,
		opts: {
			animation: Animation.Fade
		}
	},
	EditSubscription: {
		component: EditSubscription,
		opts: {
			animation: Animation.Fade
		}
	},
	MoveSubscription: {
		component: MoveSubscription,
		opts: {
			animation: Animation.SlideBottom
		}
	},
	SubscriptionPromoModal: {
		component: SubscriptionPromoModal,
		opts: {
			animation: Animation.Fade
		}
	},
	FlowLoopSettings: {
		component: FlowLoopSettings,
		opts: { drawer: true, animation: Animation.DrawerRight, closeOnCurtain: true }
	},
	WonOpportunityWithSubs: {
		component: WonOpportunityWithSubs,
		opts: {
			animation: Animation.Fade
		}
	},
	DeleteForbiddenAlert: {
		component: DeleteForbiddenAlert,
		opts: {
			animation: Animation.Fade
		}
	},
	UserSurvey: {
		component: UserSurvey,
		opts: {
			animation: Animation.Fade
		}
	},
	EditProductCategory: {
		component: EditProductCategory,
		opts: {
			animation: Animation.SlideBottom
		}
	},
	AllowAccess: {
		component: AllowAccess,
		opts: {
			animation: Animation.Fade
		}
	},
	CleanMyData: {
		component: CleanMyData,
		opts: {
			animation: Animation.SlideBottom
		}
	},
	HandleDuplicates: {
		component: HandleDuplicates,
		opts: {
			animation: Animation.SlideBottom
		}
	},
	MailPreview: {
		component: MailPreview,
		opts: {
			animation: Animation.Fade
		}
	},
	MaxCeilingReachedOnSeats: {
		component: MaxCeilingReachedOnSeatsReact,
		opts: {}
	},
	PriceListDrawer: {
		component: PriceListDrawer,
		opts: { drawer: true, animation: Animation.DrawerRight }
	},
	ProductBundleDrawer: {
		component: ProductBundleDrawer,
		opts: { drawer: true, animation: Animation.DrawerRight, closeOnCurtain: true }
	},
	CreateAccount: {
		component: CreateAccount,
		opts: {
			animation: Animation.SlideBottom
		}
	},
	EditProductBundleOrder: {
		component: EditProductBundleOrder
	},
	NewMarketEvent: {
		component: NewMarketEvent,
		opts: { closeOnEscape: true, closeOnCurtain: true }
	},
	EditClientTarget: {
		component: EditClientTarget,
		opts: {
			drawer: true,
			closeOnCurtain: true,
			animation: Animation.DrawerRight
		}
	},
	ARRChangeDrawerV2: {
		component: ARRChangeDrawerV2,
		opts: {
			drawer: true,
			closeOnCurtain: true,
			animation: Animation.DrawerRight
		}
	},
	ProductSearch: {
		component: ProductSearch,
		opts: {}
	},
	EditTicket: {
		component: OpenTicket,
		opts: { drawer: true, animation: Animation.DrawerRight, closeOnCurtain: true, closeOnEscape: true }
	},
	CreateExternalContact: {
		component: CreateExternalContact,
		opts: { drawer: true, animation: Animation.DrawerRight, closeOnCurtain: true }
	},
	CopySocialEvent: {
		component: CopySocialEvent,
		opts: {}
	},
	ReportcenterTV: {
		component: ReportcenterTV,
		opts: {}
	},
	FindSocialEvent: {
		component: FindSocialEventModal,
		opts: {}
	},
	FindClient: {
		component: FindClientModal,
		opts: {}
	},
	FindForm: {
		component: FindFormModal,
		opts: {}
	},
	EditFormSubmit: {
		component: EditFormSubmitModal,
		opts: {}
	},
	FindContact: {
		component: FindContactModal,
		opts: {}
	},
	MoveContact: {
		component: MoveContactModal,
		opts: {}
	},
	ReportcenterTVSettings: {
		component: ReportcenterTVSettings,
		opts: {
			drawer: true,
			animation: Animation.DrawerRight,
			closeOnCurtain: true
		}
	},
	Alert: {
		component: Alert,
		opts: {
			closeOnEscape: true
		}
	},
	UnsavedChangesAlert: {
		component: UnsavedChangesAlert,
		opts: {
			closeOnEscape: true
		}
	},
	RemoveAlert: {
		component: RemoveAlert,
		opts: {
			closeOnEscape: true
		}
	},
	AskForInput: {
		component: AskForInput,
		opts: {
			closeOnEscape: true
		}
	},
	MatchCompaniesBeforeMerge: {
		component: MatchCompaniesBeforeMerge,
		opts: {
			animation: Animation.SlideBottom
		}
	},
	DuplicateIntroModal: {
		component: DuplicateIntroModal,
		opts: {
			animation: Animation.SlideBottom
		}
	},
	ToggleFiscalYear: {
		component: ToggleFiscalYearModalReact,
		opts: {
			animation: Animation.SlideBottom
		}
	},
	InactivateContacts: {
		component: InactivateContacts,
		opts: {
			animation: Animation.SlideBottom
		}
	},
	EditMailSignature: {
		component: EditMailSignatureRoot,
		opts: {}
	},
	MergeContacts: {
		component: MergeContacts,
		opts: { closeOnEscape: true }
	},
	CreateUpsalesAccount: {
		component: CreateUpsalesAccount,
		opts: {}
	},
	RefreshAccount: {
		component: RefreshAccount,
		opts: {}
	},
	EditSocialEventAddResource: {
		component: EditSocialEventAddResource,
		opts: {}
	},
	FullTerms: {
		component: FullTermsModal,
		opts: {}
	},
	RiskDrawer: {
		component: RiskDrawer,
		opts: { drawer: true, animation: Animation.DrawerRight, closeOnCurtain: true }
	},
	AnonFieldPage: {
		component: AnonFieldPage,
		opts: {}
	},
	EditOrder: {
		component: EditOrder,
		opts: { closeOnCurtain: true }
	},
	ViewTickets: {
		component: ViewTickets,
		opts: { drawer: true, animation: Animation.DrawerRight, closeOnCurtain: true }
	},
	VerifyTimeframeModal: {
		component: VerifyTimeframeModal,
		opts: { animation: Animation.SlideBottom, closeOnEscape: true }
	},
	EditTicketResponseTemplate: {
		component: EditTicketResponseTemplate,
		opts: { animation: Animation.SlideBottom }
	},
	EditTicketAutoReplyTemplate: {
		component: EditTicketAutoReplyTemplate,
		opts: { animation: Animation.SlideBottom }
	},
	EditProjectPlanTemplate: {
		component: EditProjectPlanTemplate,
		opts: {
			animation: Animation.SlideBottom
		}
	},
	EditTaskTemplate: {
		component: EditTaskTemplate,
		opts: {
			animation: Animation.SlideBottom
		}
	},
	ImageUrlModal: {
		component: ImageUrlModal,
		opts: { closeOnEscape: true }
	},
	EditProjectPlan: {
		component: EditProjectPlan,
		opts: { drawer: true, closeOnCurtain: true, animation: Animation.DrawerRight, closeOnEscape: true }
	},
	CreateRelation: {
		component: CreateRelation,
		opts: { drawer: true, closeOnCurtain: true, closeOnEscape: true, animation: Animation.DrawerRight }
	},
	SubaccountDrawer: {
		component: SubaccountDrawer,
		opts: { drawer: true, closeOnCurtain: true, animation: Animation.DrawerRight }
	},
	IndustryInsightsDrawer: {
		component: IndustryInsightsDrawer,
		opts: { drawer: true, closeOnEscape: true, closeOnCurtain: true, animation: Animation.DrawerRight }
	},
	AgreementFulfillmentModal: {
		component: AgreementFulfillmentModal,
		opts: {}
	},
	ConfirmBuyProspectModal: {
		component: ConfirmBuyProspectModal,
		opts: {
			closeOnEscape: true
		}
	},
	LostOpportunityModal: {
		component: LostOpportunityModal,
		opts: {}
	},
	EditNote: {
		component: EditNote,
		opts: { closeOnEscape: true }
	},
	ListModal: {
		component: ListModal,
		opts: { closeOnCurtain: true }
	},
	UploadFileModal: {
		component: UploadFileModal,
		opts: {}
	},
	AddToSegmentModal: {
		component: AddToSegmentModal,
		opts: { closeOnEscape: true }
	},
	SelectFieldModal: {
		component: SelectFieldModal,
		opts: { drawer: false, animation: Animation.SlideBottom, closeOnCurtain: true }
	},
	MoveOrCloseActivity: {
		component: MoveOrCloseActivity,
		opts: { closeOnEscape: true }
	},
	ConfirmBuyProspectsModal: {
		component: ConfirmBuyProspectsModal,
		opts: {
			closeOnEscape: true
		}
	},
	TemplateEditorModal: {
		component: TemplateEditorModal,
		opts: {}
	},
	CreateGroupMail: {
		component: CreateGroupMail,
		opts: { closeOnEscape: true }
	},
	StageListModal: {
		component: StageListModal,
		opts: { drawer: false, animation: Animation.SlideBottom, closeOnCurtain: true, closeOnEscape: true }
	},
	CreateDocumentModal: {
		component: CreateDocumentModal,
		opts: {}
	},
	PdfGeneric: {
		component: PdfGeneric,
		opts: {}
	},
	PreviewPdfModal: {
		component: PreviewPdfModal as any,
		opts: { closeOnEscape: true, animation: Animation.SlideBottom }
	},
	ConfirmEsignModal: {
		component: ConfirmEsignModal,
		opts: { drawer: false, animation: Animation.SlideBottom, closeOnCurtain: false, closeOnEscape: true }
	},
	AccountRelationModal: {
		component: AccountRelationModal,
		opts: { closeOnCurtain: false, closeOnEscape: true }
	},
	AssignModalLead: {
		component: AssignModalLead,
		opts: { closeOnCurtain: false, closeOnEscape: true }
	},
	CreateActivityModal: {
		component: CreateActivityModal,
		opts: {}
	},
	OptOutModal: {
		component: OptOutModal,
		opts: { closeOnEscape: true, closeOnCurtain: false }
	},
	WonOpportunityModal: {
		component: WonOpportunityModal,
		opts: {}
	},
	YourYearWithUpsalesModal: {
		component: YourYearWithUpsalesModal,
		opts: { animation: Animation.SlideBottom, closeOnCurtain: false, closeOnEscape: false }
	},
	InsertTag: {
		component: InsertTag,
		opts: { closeOnCurtain: true }
	},
	LandingPageSettings: {
		component: LandingPageSettings,
		opts: { closeOnEscape: true }
	},
	EditAppointment: {
		component: EditAppointment,
		opts: { closeOnEscape: true }
	},
	FileBrowserModal: {
		component: FileBrowserModal,
		opts: {}
	},
	SalesCoachModal: {
		component: SalesCoachModalReact,
		opts: { closeOnCurtain: true }
	},
	CreateReportModal: {
		component: CreateReportModal,
		opts: { drawer: false, animation: Animation.SlideBottom, closeOnEscape: true }
	},
	EditEventDomainModal: {
		component: EditEventDomainModal,
		opts: { closeOnEscape: true }
	},
	AddProductModal: {
		component: EditProductModal,
		opts: { closeOnEscape: true, closeOnCurtain: true }
	},
	OpenCall: {
		component: OpenCallModal,
		opts: {}
	},
	EditCustomFieldModal: {
		component: EditCustomFieldModal,
		opts: { closeOnEscape: true }
	},
	OptIn: {
		component: OptIn,
		opts: { closeOnEscape: true }
	},
	ClientListModal: {
		component: ClientListModal,
		opts: {
			closeOnCurtain: true
		}
	},
	SharedViewsModal: {
		component: SharedViewsModal,
		opts: { closeOnEscape: true, closeOnCurtain: false }
	},
	IntegrationModal: {
		component: IntegrationModal,
		opts: {
			closeOnCurtain: true
		}
	},
	VerifyBudgetModal: {
		component: VerifyBudgetModal,
		opts: { animation: Animation.SlideBottom, closeOnEscape: true }
	},
	GenericModal: {
		component: GenericModal,
		opts: { closeOnEscape: true }
	},
	ConfirmLandingPageModal: {
		component: ConfirmLandingPageModal,
		opts: {
			closeOnEscape: true
		}
	},
	SolutionModal: {
		component: SolutionModal,
		opts: { animation: Animation.SlideBottom }
	},
	MailTemplateBrowserModal: {
		component: MailTemplateBrowserModal,
		opts: { closeOnEscape: true }
	},
	EditRoleModal: {
		component: EditRoleModal,
		opts: { animation: Animation.SlideBottom }
	},
	AppWidgetModal: {
		component: AppWidget,
		opts: { closeOnCurtain: false, closeOnEscape: false }
	},
	MultiUpdateContact: {
		component: MultiUpdateContact,
		opts: { closeOnCurtain: true }
	},
	MultiUpdateAppointmentModal: {
		component: MultiUpdateAppointmentModal,
		opts: { closeOnEscape: true }
	},
	CreateActivityAction: {
		component: CreateActivityActionModal,
		opts: { closeOnEscape: true }
	},
	SelectOpportunity: {
		component: SelectOpportunity,
		opts: { closeOnCurtain: false, closeOnEscape: true }
	},
	DelayedPayment: {
		component: DelayedPayment,
		opts: { closeOnCurtain: false, closeOnEscape: false }
	},
	ResetScore: {
		component: ResetScore,
		opts: { closeOnEscape: true }
	},
	EditStandardFieldModal: {
		component: EditStandardFieldModal,
		opts: { animation: Animation.SlideBottom, closeOnEscape: true }
	},
	UpdateClientDuns: {
		component: UpdateClientDuns,
		opts: {}
	},
	MergeClients: {
		component: MergeClients,
		opts: { closeOnEscape: true }
	},
	EditCampaign: {
		component: EditCampaignModal,
		opts: { closeOnEscape: true }
	},
	ExportContactData: {
		component: ExportContactDataModal,
		opts: { closeOnEscape: true }
	},
	ValidDomainModal: {
		component: ValidDomainModal
	},
	EditDocumentTemplateModal: {
		component: EditDocumentTemplateModal
	},
	ConfirmSoliditetUpdateModal: {
		component: ConfirmSoliditetUpdateModal,
		opts: { closeOnEscape: true, closeOnCurtain: true }
	},
	DownloadList: {
		component: DownloadList,
		opts: {}
	},
	TranslateFieldsModal: {
		component: TranslateFieldsModal,
		opts: {}
	},
	NewSegmentModal: {
		component: NewSegmentModal,
		opts: { closeOnEscape: true }
	},
	SendEmailAction: {
		component: SendEmailAction,
		opts: { closeOnEscape: true }
	},
	ConfirmParentProspecting: {
		component: ConfirmParentProspecting,
		opts: { closeOnEscape: true }
	},
	NextStepModal: {
		component: NextStepModal,
		opts: { animation: Animation.SlideBottom, closeOnEscape: true }
	},
	ChecklistItemModal: {
		component: ChecklistItemModal,
		opts: { animation: Animation.SlideBottom, closeOnEscape: true }
	},
	DecisionMakersModal: {
		component: DecisionMakersModal,
		opts: { animation: Animation.SlideBottom, closeOnEscape: true }
	},
	DecisionMakersProModal: {
		component: DecisionMakersProModal,
		opts: { animation: Animation.SlideBottom, closeOnEscape: true }
	},
	ConfirmSoliditetBuyParentModal: {
		component: ConfirmSoliditetBuyParentModal,
		opts: { closeOnEscape: true }
	},
	CreateMailTemplate: {
		component: CreateMailTemplateModal,
		opts: { closeOnEscape: true }
	},
	EditEsign: {
		component: EditEsign,
		opts: {}
	},
	LandingpageEditorModal: {
		component: LandingpageEditorModal,
		opts: { closeOnEscape: true }
	},
	ResetLeadMulti: {
		component: ResetLeadMulti,
		opts: { closeOnEscape: true }
	},
	RelationsSignalDrawer: {
		component: RelationsSignalDrawer,
		opts: { drawer: true, closeOnCurtain: true, closeOnEscape: true, animation: Animation.DrawerRight }
	},
	MergeTickets: {
		component: MergeTickets,
		opts: { animation: Animation.SlideBottom, closeOnEscape: true }
	}
} satisfies Record<ModalConfigName, ModalConfig>;

export type ModalConfigByName<Conf extends ModalConfigName> = (typeof configs)[Conf];
export type ModalComponentProps<Conf extends ModalConfigName> = Collect<
	ComponentProps<ModalConfigByName<Conf>['component']>
>;

export default configs;
