import React, { Fragment, useEffect, useRef, useState, useMemo, createContext, useContext } from 'react';
import { Input, Block, Text, Button, Icon, Loader, OutsideClick, Link, Flex, IconName } from '@upsales/components';
import T, { useTranslation } from 'Components/Helpers/translate';
import BemClass from '@upsales/components/Utils/bemClass';
import './ClientContactSelect.scss';
import { makeCancelable } from 'App/babel/helpers/promise';
import Contact from 'App/resources/Model/Contact';
import Client from 'App/resources/Model/Client';
import RequestBuilder, { comparisonTypes } from 'Resources/RequestBuilder';
import ClientAttributes from 'App/babel/attributes/Client';
import ContactResource from 'App/resources/Contact';
import ClientResource from 'App/resources/Client';
import logError from 'Helpers/logError';
import { binaryGroup, fetchClientsFromCustomFields, setCustomClientFieldsFilters } from 'App/helpers/accountsHelper';
import ClientContactSelectRow, { RowSkin } from './ClientContactSelectRow';
import { useSelf } from 'App/components/hooks/appHooks';

import { SlideFade } from '@upsales/components/animations';
import { useSoftDeployAccess } from 'App/components/hooks';
import { Metadata } from 'Resources/ResourceTyped';

const LIMIT = 10;
const MIN_LENGTH = 2;

export type ClientValue = (Partial<Client> & Pick<Client, 'id' | 'name'>) | null;
export type ContactValue = (Partial<Contact> & Pick<Contact, 'id' | 'name'>) | null;

type ModeTypes = 'clients' | 'contacts' | undefined;
type SelectorType = 'plain' | 'dropdown';

type Props = Omit<React.ComponentProps<typeof Block>, 'onChange'> & {
	className?: string;
	client?: ClientValue;
	contact?: ContactValue;
	onChange: (
		selected: { client: ClientValue | null; contact: null } | { client: Contact['client']; contact: Contact }
	) => void;
	clientId?: number;
	inline?: boolean;
	mode?: ModeTypes;
	inputRef?: (r: HTMLInputElement) => void;
	disabled?: boolean;
	autoFocus?: boolean;
	optionSkin?: RowSkin;
	selectedSkin?: RowSkin;
	selectorType?: SelectorType;
	searchIcon?: IconName | null;
	clientFields?: string[];
	onlyActive?: boolean;
	withHits?: boolean;
};

type ContextProps = Props & {
	open: boolean;
	setOpen: (open: boolean) => void;
	localInputRef: React.MutableRefObject<HTMLInputElement | null | undefined> | undefined;
	keyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void;
	searchString: string;
	setSearchString: (searchString: string) => void;
	listRef: React.MutableRefObject<HTMLDivElement | null | undefined> | undefined;
	classes: BemClass;
	searching: boolean;
	contacts: Contact[];
	clients: Client[];
	highlighted: string | null;
	resetSearch: () => void;
	hasSubAccounts: boolean;
	mainContacts: Contact[];
	subAccounts: Client[];
	permissionToCreateContact: boolean | undefined;
	addContact: () => void;
	removeSelectedRow: () => void;
	setLocalInputRef: (r: HTMLInputElement) => void;
	setListRef: (r: HTMLDivElement) => void;
	hits: number;
};
const initialProps = {
	className: '',
	onChange: () => {},
	client: undefined,
	contact: undefined,
	clientId: undefined,
	inputRef: undefined,
	disabled: false,
	inline: false,
	mode: undefined,
	autoFocus: false,
	optionSkin: 'full' as RowSkin,
	selectedSkin: 'full' as RowSkin,
	open: false,
	setOpen: (open: boolean) => {},
	localInputRef: undefined,
	keyDown: (e: React.KeyboardEvent<HTMLInputElement>) => {},
	searchString: '',
	setSearchString: (searchString: string) => {},
	listRef: undefined,
	classes: new BemClass('ClientContactSelect'),
	searching: false,
	contacts: [],
	clients: [],
	highlighted: null,
	resetSearch: () => {},
	hasSubAccounts: false,
	mainContacts: [],
	subAccounts: [],
	permissionToCreateContact: false,
	addContact: () => {},
	removeSelectedRow: () => {},
	setLocalInputRef: () => {},
	setListRef: () => {},
	selectorType: 'dropdown' as SelectorType,
	clientFields: [],
	withHits: false,
	hits: 0
};

const ClientContactContext = createContext<ContextProps>(initialProps);

const fetchContacts = async (
	searchString: string,
	clientIds: number[] | null,
	limit?: number,
	customFieldClientIds?: number[]
) => {
	limit = limit || LIMIT;
	const [clientId] = clientIds || [];
	return ContactResource.search(searchString, clientIds, limit, undefined, customFieldClientIds).then(res => {
		const sorted = res.data.sort((a, b) => (a.client.id === clientId ? -1 : 0));
		return { data: sorted, metadata: res.metadata };
	});
};

const fetchClients = async (
	searchString: string,
	hasSubAccounts: boolean = false,
	onlyActive?: boolean,
	clientFields?: string[]
) => {
	const accountFilter = new RequestBuilder();
	accountFilter.fields = ['id', 'name', 'addresses', 'journeyStep'];
	if (onlyActive) {
		accountFilter.addFilter(ClientAttributes.active, comparisonTypes.Equals, true);
	}
	const orBuilder = accountFilter.orBuilder();
	const numericSearch = parseInt(searchString);
	if (!isNaN(numericSearch)) {
		orBuilder.next();
		orBuilder.addFilter(ClientAttributes.id, comparisonTypes.Equals, numericSearch);
		accountFilter.addScore({ field: ClientAttributes.id.field }, comparisonTypes.Equals, numericSearch);
	}
	orBuilder.next();
	orBuilder.addFilter(ClientAttributes.name, comparisonTypes.Search, searchString);
	orBuilder.next();
	orBuilder.addFilter(ClientAttributes.name, comparisonTypes.Fuzzy, searchString);

	setCustomClientFieldsFilters(orBuilder, searchString);

	orBuilder.done();
	// prioritize the best matches
	accountFilter.addScore({ field: 'custom.value' }, comparisonTypes.Search, searchString);
	accountFilter.addScore(ClientAttributes.name, comparisonTypes.Equals, searchString);
	accountFilter.addScore(ClientAttributes.name, comparisonTypes.WildcardEnd, searchString);
	accountFilter.addScore(ClientAttributes.name, comparisonTypes.WildcardStart, searchString);
	accountFilter.addScore(ClientAttributes.name, comparisonTypes.Wildcard, searchString);
	accountFilter.addScore(ClientAttributes.active, comparisonTypes.Equals, 1);

	if (hasSubAccounts) {
		accountFilter.fields.push('operationalAccount');
		accountFilter.addScore(ClientAttributes.operationalAccount.attr.id, comparisonTypes.NotEquals, null);
	}

	if (clientFields?.length) {
		accountFilter.fields = clientFields;
	}

	accountFilter.limit = LIMIT;

	return ClientResource.find(accountFilter.build());
};

const search = (
	searchString: string,
	clientIds: number[] | null,
	mode: ModeTypes,
	hasSubAccounts: boolean = false,
	onlyActive = false,
	clientFields: string[]
) => {
	const promises: [
		Promise<{ data: Contact[]; metadata: Metadata }>,
		Promise<{ data: Client[]; metadata: Metadata }>
	] = [
		mode === 'clients'
			? Promise.resolve({ data: [] as Contact[], metadata: { total: 0, limit: 0, offset: 0 } })
			: fetchClientsFromCustomFields(searchString).then(customClientIds =>
					fetchContacts(searchString, clientIds, undefined, customClientIds)
			  ),
		mode === 'contacts'
			? Promise.resolve({
					data: [] as Client[],
					metadata: {
						total: 0,
						limit: 0,
						offset: 0
					}
			  })
			: fetchClients(searchString, hasSubAccounts, onlyActive, clientFields)
	];

	return makeCancelable(Promise.all(promises));
};

const groupContacts = (contacts: Contact[], operationalAccountId?: number) => {
	const predicate = (c: Contact) => (operationalAccountId ? c.client.id === operationalAccountId : false);
	return binaryGroup(contacts, predicate);
};

const Header = ({ classes, title, white }: { classes: BemClass; title: string; white?: boolean }) => (
	<Block
		className={classes.elem('header').b()}
		backgroundColor={white ? 'white' : 'grey-2'}
		border="bs"
		borderColor="grey-4"
		space="ptm prm pbm plm"
	>
		<Text size="sm" bold>
			{T(title)}
		</Text>
	</Block>
);

function isElementInViewport(list: HTMLDivElement, el: HTMLDivElement) {
	const rect = el.getBoundingClientRect();
	const viewTop = list.scrollTop;
	const viewBottom = viewTop + list.offsetHeight;
	const _top = el.offsetTop;
	const _bottom = _top + rect.height;
	const compareTop = _top;
	const compareBottom = _bottom;

	return compareBottom <= viewBottom && compareTop >= viewTop;
}

const InlineSelected = () => {
	const { classes, contact, onChange } = useContext(ClientContactContext);
	return (
		<div className={classes.elem('selected').mod('inline').b()}>
			<div>
				<Link
					onClick={e => e.stopPropagation()}
					href={Tools.$state.href('contact.dashboard', {
						id: contact?.id,
						customerId: Tools.AppService.getCustomerId()
					})}
				>
					{contact?.name}
				</Link>
			</div>
			<div>
				<Button type="link" color="grey" onClick={() => onChange({ client: null, contact: null })}>
					<Icon name="times" />
				</Button>
			</div>
		</div>
	);
};

const Selected = () => {
	const { classes, contact, removeSelectedRow, disabled, client, selectedSkin, setOpen } =
		useContext(ClientContactContext);
	const onClick = () => {
		if (!disabled) setOpen(true);
	};
	return (
		<Fragment>
			{contact ? (
				<ClientContactSelectRow
					skin={selectedSkin}
					classes={classes}
					highlighted={null}
					removable={true}
					removeSelectedRow={removeSelectedRow}
					extraIcons={true}
					id={`con-${contact.id}`}
					contact={contact as Contact}
					disabled={disabled}
					onClick={onClick}
				>
					<ClientContactSelectRow.Contact />
				</ClientContactSelectRow>
			) : null}
			{client && !contact ? (
				<ClientContactSelectRow
					skin={selectedSkin}
					classes={classes}
					highlighted={null}
					removable={true}
					removeSelectedRow={removeSelectedRow}
					extraIcons={true}
					id={`cli-${client.id}`}
					client={client as Client}
					disabled={disabled}
					onClick={onClick}
				>
					<ClientContactSelectRow.Client />
				</ClientContactSelectRow>
			) : null}
		</Fragment>
	);
};

const InputField = () => {
	const { inline, disabled, mode, searchString, setSearchString, keyDown, setOpen, setLocalInputRef, searchIcon } =
		useContext(ClientContactContext);
	return (
		<Input
			icon={inline ? undefined : searchIcon ? searchIcon : ''}
			inputRef={r => setLocalInputRef(r as HTMLInputElement)}
			placeholder={T(
				mode
					? mode === 'contacts'
						? 'default.searchContact'
						: 'client.search'
					: 'todo.searchContactsAndCompanies'
			)}
			onFocus={() => setOpen(true)}
			onKeyDown={keyDown}
			inline={inline}
			value={searchString}
			onChange={e => setSearchString(e.target.value)}
			disabled={disabled}
		/>
	);
};

const OptionList = () => {
	const {
		searchString,
		disabled,
		mode,
		classes,
		searching,
		contacts,
		clients,
		highlighted,
		resetSearch,
		onChange,
		client,
		hasSubAccounts,
		mainContacts,
		subAccounts,
		permissionToCreateContact,
		addContact,
		optionSkin,
		setListRef,
		selectorType,
		open
	} = useContext(ClientContactContext);
	return !open ? null : (
		<div
			className={classes.elem(selectorType === 'dropdown' ? 'list' : 'plain').b()}
			ref={(r: HTMLInputElement) => {
				setListRef(r);
			}}
		>
			{searchString.length < MIN_LENGTH && !contacts.length && !clients.length ? (
				<Header classes={classes} title={'default.typeToSearch'} white={true} />
			) : null}
			{searching ? (
				<Flex justifyContent="center">
					<Loader size="sm" />{' '}
				</Flex>
			) : null}
			{contacts.length ? (
				<Fragment>
					<Header classes={classes} title={'default.contacts'} />
					{contacts.map((contact: Contact) => (
						<ClientContactSelectRow
							key={`con-${contact.id}`}
							id={`con-${contact.id}`}
							classes={classes}
							highlighted={highlighted}
							contact={contact as Contact}
							removable={false}
							disabled={disabled}
							onClick={() => {
								resetSearch();
								onChange({
									client: client ? (client as Contact['client']) : contact.client,
									contact
								});
							}}
							extraIcons={true}
							skin={optionSkin}
						>
							<ClientContactSelectRow.Contact />
						</ClientContactSelectRow>
					))}
				</Fragment>
			) : null}
			{hasSubAccounts && mainContacts.length ? (
				<Fragment>
					<Header
						classes={classes}
						title={`${T('default.contacts')} ${T('default.fromMainAccount').toLowerCase()}`}
					/>

					{mainContacts.map((contact: Contact) => (
						<ClientContactSelectRow
							id={`con-${contact.id}`}
							key={`con-${contact.id}`}
							classes={classes}
							highlighted={highlighted}
							contact={contact as Contact}
							removable={false}
							disabled={disabled}
							onClick={() => {
								resetSearch();
								onChange({
									client: client ? (client as Contact['client']) : contact.client,
									contact
								});
							}}
							extraIcons={true}
							skin={optionSkin}
						>
							<ClientContactSelectRow.Contact />
						</ClientContactSelectRow>
					))}
				</Fragment>
			) : null}
			{clients.length ? (
				<Fragment>
					<Header classes={classes} title={'default.accounts'} />
					{clients.map((client: Client) => (
						<ClientContactSelectRow
							id={`cli-${client.id}`}
							key={`cli-${client.id}`}
							classes={classes}
							highlighted={highlighted}
							removable={false}
							onClick={() => {
								resetSearch();
								onChange({ client, contact: null });
							}}
							client={client}
							disabled={disabled}
							extraIcons={false}
							skin={optionSkin}
						>
							<ClientContactSelectRow.Client />
						</ClientContactSelectRow>
					))}
				</Fragment>
			) : null}
			{hasSubAccounts && subAccounts.length ? (
				<Fragment>
					<Header classes={classes} title={'account.subaccounts'} />
					{subAccounts.map((client: Client) => (
						<ClientContactSelectRow
							id={`cli-${client.id}`}
							key={`cli-${client.id}`}
							classes={classes}
							highlighted={highlighted}
							removable={false}
							onClick={() => {
								resetSearch();
								onChange({ client, contact: null });
							}}
							client={client}
							extraIcons={false}
							disabled={disabled}
							skin={optionSkin}
						>
							<ClientContactSelectRow.SubAccount />
						</ClientContactSelectRow>
					))}
				</Fragment>
			) : null}
			{searchString && !searching && !contacts.length && !clients.length && !subAccounts.length ? (
				<Text className={classes.elem('no-results').b()} center>
					{T('default.noResults')}
				</Text>
			) : null}
			{!searching && permissionToCreateContact && mode !== 'clients' ? (
				<Button block size="lg" color="bright-blue" type="link" onClick={addContact} tabIndex={-1}>
					<Icon name="user-plus" space="mrs" /> {T('default.createAContact')}
				</Button>
			) : null}
		</div>
	);
};

const Hits = () => {
	const { withHits, hits } = useContext(ClientContactContext);
	const { t } = useTranslation();
	return withHits && hits > 0 ? (
		<Text space="mll mbm" size="sm" color="grey-11">
			{hits} {t(hits === 1 ? 'default.hit' : 'default.hits')}
		</Text>
	) : null;
};

const PlainSearchList = () => {
	return (
		<>
			<Block space="ptl prl pbl pll">
				<InputField />
			</Block>
			<Hits />
			<OptionList />
		</>
	);
};

const DropdownSearchList = () => {
	const { open, setOpen, client, contact, mode, setSearchString } = useContext(ClientContactContext);
	const mainRef = useRef<HTMLDivElement>();
	return (
		<OutsideClick
			targetRef={() => mainRef.current || null}
			listen={open}
			outsideClick={() => {
				setOpen(false);
				if (
					(!mode && !client?.id && !contact?.id) ||
					(mode === 'clients' && !client?.id) ||
					(mode === 'contacts' && !contact?.id)
				) {
					setSearchString('');
				}
			}}
		>
			<div ref={(r: HTMLInputElement) => (mainRef.current = r)}>
				<InputField />
				<SlideFade direction="top" bounce visible={!open}>
					<OptionList />
				</SlideFade>
			</div>
		</OutsideClick>
	);
};

const selectorComponent = {
	plain: PlainSearchList,
	dropdown: DropdownSearchList
};

// TODO: Move provider logic to a different file. Set functions can be implemented there as well
const ClientContactSelect = ({
	className,
	onChange,
	client,
	contact,
	clientId,
	inputRef,
	disabled = false,
	inline = false,
	mode,
	autoFocus = false,
	optionSkin = 'full',
	selectedSkin = 'full',
	searchIcon = 'search',
	selectorType = 'dropdown',
	clientFields = [],
	withHits = false,
	onlyActive,
	...props
}: Props) => {
	const listRef = useRef<HTMLDivElement>();
	const localInputRef = useRef<HTMLInputElement | null>();
	const [open, setOpen] = useState(false);
	const [contacts, setContacts] = useState<Contact[]>([]);
	const [clients, setClients] = useState<Client[]>([]);
	const [hits, setHits] = useState<number>(0);
	const [mainContacts, setMainContacts] = useState<Contact[]>([]);
	const [subAccounts, setSubAccounts] = useState<Client[]>([]);
	const [searching, setSearching] = useState(false);
	const [searchString, setSearchString] = useState('');
	const [keys, setKeys] = useState<string[]>([]);
	const [highlighted, setHighlighted] = useState<string | null>(null);
	const createContactRights = useSelf()?.createRights.Contact;

	const hasSubAccounts = useSoftDeployAccess('SUB_ACCOUNTS');
	const classes = new BemClass('ClientContactSelect', className);
	const operationalAccountId = client?.operationalAccount?.id;

	const clientIds: number[] = [];
	if (client) {
		clientIds.push(client.id);
		if (hasSubAccounts && client.operationalAccount) {
			clientIds.push(client.operationalAccount.id);
		}
	} else if (clientId) {
		clientIds.push(clientId);
	}

	const clientIdsString = clientIds.join(',');

	const resetSearch = () => {
		setSearchString('');
		if (mode === 'contacts') {
			setClients([]);
		}
		setContacts([]);
		setOpen(false);
	};
	const addContact = () => {
		// eslint-disable-next-line promise/catch-or-return
		Tools.$upModal.open('editContact', { clientId }).then((contact: Contact) => {
			onChange({ client: contact.client, contact });
		});
	};
	const scrollIntoView = (key: string | null) => {
		const element = listRef.current?.querySelectorAll<HTMLDivElement>(`[data-id="${key}"]`)[0];
		if (listRef.current && element && !isElementInViewport(listRef.current, element)) {
			element.scrollIntoView({ block: 'nearest' });
		}
	};
	const keyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
		// Do nothing if no keys or if pressed key not interesting
		if (
			(!keys.length && ['ArrowUp', 'ArrowDown', 'Enter'].includes(e.key)) ||
			!['ArrowUp', 'ArrowDown', 'Enter', 'Escape', 'Tab'].includes(e.key)
		) {
			return;
		}
		e.preventDefault();
		const key = highlighted || keys[0];
		const currentI = keys.indexOf(key);
		switch (e.key) {
			case 'ArrowUp':
			case 'ArrowDown': {
				let newKey = null;
				if (e.key === 'ArrowUp') {
					if (currentI === 0) {
						newKey = keys[keys.length - 1]; // go to end
					} else {
						newKey = keys[currentI - 1]; // go to prev
					}
				} else if (e.key === 'ArrowDown') {
					if (currentI === keys.length - 1) {
						newKey = keys[0]; // go to beginning
					} else {
						newKey = keys[currentI + 1]; // go to next
					}
				}
				setHighlighted(newKey);
				scrollIntoView(newKey);
				break;
			}
			case 'Tab':
			case 'Escape':
				resetSearch();
				localInputRef.current?.blur();
				break;
			case 'Enter':
				if (highlighted) {
					const findFunction = (c: Contact | Client, prefix: string) =>
						c.id === parseInt(highlighted.replace(prefix, ''));
					if (highlighted.startsWith('cli-')) {
						let client = clients.find(c => findFunction(c, 'cli-')) || null;
						if (!client && hasSubAccounts) {
							client = subAccounts.find(c => findFunction(c, 'cli-')) || null;
						}
						onChange({ client, contact: null });
					}
					if (highlighted.startsWith('con-')) {
						let contact = contacts.find(c => findFunction(c, 'con-')) || null;
						if (!contact && hasSubAccounts) {
							contact = mainContacts.find(c => findFunction(c, 'con-')) || null;
						}
						if (contact) {
							onChange({ client: contact.client, contact });
						}
					}
					resetSearch();
				}
				break;
		}
	};

	useEffect(() => {
		if (clientIds.length && !searchString && mode !== 'clients') {
			const searchPromise = makeCancelable(fetchContacts('', clientIds, 500));
			searchPromise.promise
				.then(res => {
					if (res.data.length === res.metadata.total) {
						if (hasSubAccounts) {
							const [mainContacts, clientContacts] = groupContacts(res.data, operationalAccountId);
							setMainContacts(mainContacts);
							setContacts(clientContacts);
						} else {
							setContacts(res.data);
						}
						const keys = [...res.data.map((c: Contact) => `con-${c.id}`)];
						setKeys(keys);
						setHighlighted(keys[0] || null);
					}
				})
				.catch(e => logError(e, 'Failed to fetch contacts'));
			return () => {
				if (searchPromise) {
					searchPromise.cancel();
				}
			};
		}
	}, [searchString, clientIdsString, mode]);

	useEffect(() => {
		let searchPromise: ReturnType<typeof search> | null = null;
		let loadingDebounce: NodeJS.Timeout | null;
		const debounce = setTimeout(() => {
			if (searchString.length >= MIN_LENGTH) {
				setSearching(true);
				// As we merge some behaviors of different company selectors,
				// for this case we want to clear the list every time we search.
				// just move the code out of the condition if at some point they want it for every case
				if (selectorType === 'plain') {
					setHighlighted(null);
					setContacts([]);
					setClients([]);
					setSubAccounts([]);
					setMainContacts([]);
					setHits(0);
				}
				searchPromise = search(searchString, clientIds ?? null, mode, hasSubAccounts, onlyActive, clientFields);
				const initTime = Date.now();
				searchPromise.promise
					.then(
						([
							{ data: contacts, metadata: contactsMetadata },
							{ data: clients, metadata: clientsMetadata }
						]) => {
							const resolveSearch = () => {
								if (hasSubAccounts) {
									const [keyContacts, clientContacts] = groupContacts(contacts, operationalAccountId);
									setMainContacts(keyContacts);
									setContacts(clientContacts);
								} else {
									setContacts(contacts);
								}
								let keys = [...contacts.map(c => `con-${c.id}`)];
								if (clients) {
									if (hasSubAccounts) {
										const [subAccounts, accounts] = binaryGroup(
											clients,
											c => c.operationalAccount !== null
										);

										setClients(accounts);
										setSubAccounts(subAccounts);
									} else {
										setClients(clients);
									}
									keys = keys.concat(clients.map(c => `cli-${c.id}`));
								}
								setHits(clientsMetadata.total);
								setKeys(keys);
								setHighlighted(keys[0] || null);
								setSearching(false);
							};
							const timeDiff = Date.now() - initTime;
							// When it is a plain search we want the loading state to show for a minimum moment to avoid flickering
							if (selectorType === 'plain' && timeDiff < 500) {
								loadingDebounce = setTimeout(resolveSearch, 500 - timeDiff);
								return;
							}

							resolveSearch();
						}
					)
					.catch(e => logError(e, 'Failed to search for contacts and clients'));
			} else {
				searchPromise?.cancel();
				setSearching(false);
			}
		}, 300);
		return () => {
			if (debounce) {
				clearTimeout(debounce);
			}
			if (loadingDebounce) {
				clearTimeout(loadingDebounce);
			}
			if (searchPromise) {
				searchPromise.cancel();
			}
		};
	}, [searchString, clientIdsString, mode, hasSubAccounts, JSON.stringify(clientFields), onlyActive, selectorType]);

	const permissionToCreateContact = useMemo(() => {
		const canAlwaysCreateContacts = createContactRights === 'ALL';
		const canCreateContactsOnClient = client?.createRights?.Contact;
		return canAlwaysCreateContacts || canCreateContactsOnClient;
	}, [client, createContactRights]);

	const removeSelectedRow = () => {
		if (contact && client) {
			onChange({ client: client, contact: null });
		} else {
			onChange({ client: null, contact: null });
		}
	};

	const setLocalInputRef = (r: HTMLInputElement) => {
		localInputRef.current = r;

		if (inputRef) {
			inputRef(r);
		}
	};

	const setListRef = (r: HTMLDivElement | null) => {
		listRef.current = r as HTMLDivElement;
	};

	let content;
	if (!open && ((mode === 'contacts' && contact) || (mode !== 'contacts' && (client || contact)))) {
		content = inline ? <InlineSelected /> : <Selected />;
	} else {
		content = React.createElement(selectorComponent[selectorType]);
	}

	useEffect(() => {
		if (autoFocus) {
			localInputRef.current?.focus();
		}
	}, [autoFocus]);

	return (
		<ClientContactContext.Provider
			value={{
				open,
				setOpen,
				inline,
				localInputRef,
				inputRef,
				keyDown,
				searchString,
				setSearchString,
				disabled,
				mode,
				listRef,
				classes,
				searching,
				contacts,
				clients,
				highlighted,
				resetSearch,
				onChange,
				client,
				contact,
				hasSubAccounts,
				mainContacts,
				subAccounts,
				permissionToCreateContact,
				addContact,
				selectedSkin,
				optionSkin,
				autoFocus,
				removeSelectedRow,
				setLocalInputRef,
				selectorType,
				setListRef,
				searchIcon,
				clientFields,
				withHits,
				hits
			}}
		>
			<Block {...props} className={classes.b()}>
				{content}
			</Block>
		</ClientContactContext.Provider>
	);
};

export default ClientContactSelect;

export const ClientContactSelectWrapper = (props: Props) => {
	const localClientInputRef = useRef<HTMLInputElement>();
	const localContactInputRef = useRef<HTMLInputElement>();

	return (
		<ClientContactSelect
			className="ClientContactSelectWrapper"
			{...props}
			inputRef={r => {
				localClientInputRef.current = r;
				localContactInputRef.current = r;
			}}
		/>
	);
};
