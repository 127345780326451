import SubscriptionIndexingState, { State } from '../SubscriptionIndexingState/SubscriptionIndexingState';
import { Action } from '../SubscriptionIndexingState/SubscriptionIndexingActions';
import { Block, Text, Row, Headline, Flex, Button } from '@upsales/components';
import Avatar from 'Components/Avatar';
import { useTranslation } from 'Components/Helpers/translate';
import BemClass from '@upsales/components/Utils/bemClass';
import { currencyFormat } from 'Components/Filters/Currencies';
import { useMetadata } from 'App/components/hooks/appHooks';
import SubscriptionListConflict from '../SubscriptionList/SubscriptionListConflict';
import SubscriptionListPreview from '../SubscriptionList/SubscriptionListPreview';
import ArrChangelogRawList from '../SubscriptionList/ArrChangelogRawList';
import { usesMRR } from 'App/helpers/salesModelHelpers';
import { useUsers } from 'App/components/hooks/appHooks';
import { BasicUserWithPermissions } from 'App/resources/Model/User';
import React from 'react';
import moment from 'moment';

import './SubscriptionIndexingSummary.scss';
import { ColorsEnum } from '@upsales/components/Utils/colors';

type Props = {
	state: State;
	dispatch: React.Dispatch<Action>;
	hidePreview?: boolean;
};

const InfoColumn = ({
	title,
	subtitle,
	color
}: {
	title: string | number;
	subtitle: string;
	color?: keyof typeof ColorsEnum;
}) => (
	<Row direction="column">
		<Text bold color={color}>
			{title}
		</Text>
		<Text size="sm" color="grey-11">
			{subtitle}
		</Text>
	</Row>
);

const IndexInfo = ({
	name,
	isPassed,
	indexInterval,
	indexingEnabled,
	indexPercentage,
	subscriptionCount,
	modDate,
	totalIncreaseFormatted,
	createdBy,
	specificDate,
	filter,
	hasMRR,
	indexId
}: Pick<
	State,
	| 'name'
	| 'isPassed'
	| 'indexInterval'
	| 'indexingEnabled'
	| 'indexPercentage'
	| 'subscriptionCount'
	| 'modDate'
	| 'specificDate'
	| 'filter'
> & {
	totalIncreaseFormatted: string;
	hasMRR: boolean;
	createdBy: BasicUserWithPermissions | undefined;
	indexId: State['id'];
}) => {
	const { t } = useTranslation();
	const langStatus = t(`subscription.indexing.status.${isPassed ? 'ended' : indexingEnabled ? 'active' : 'paused'}`);

	return (
		<>
			<Flex space="ptxl pbl" justifyContent="space-between" alignItems="center">
				<Headline>{name}</Headline>

				{!isPassed ? (
					<Button
						icon="file-excel"
						onClick={() => {
							Tools.$upModal.open('ExportData', {
								optionsByType: true,
								columns: [],
								selectables: [],
								customfields: null,
								filters: filter,
								entity: 'Agreement',
								exportType: 'agreement',
								sort: filter.sort,
								justExport: true,
								options: {}
							});
						}}
					>
						{t('default.export')}
					</Button>
				) : null}
			</Flex>

			<Block space="pbl">
				<Row align="space-between">
					<InfoColumn
						title={
							indexInterval === 'once'
								? moment(specificDate).format('L')
								: indexInterval === 'renewal'
								? t('subscription.indexing.renewal')
								: t('subscription.indexing.yearly')
						}
						subtitle={t(
							`subscription.indexing.summary.details.${isPassed ? 'endedIncrease' : 'plannedIncrease'}`
						)}
					/>
					<InfoColumn
						color={isPassed ? 'danger-5' : 'grey-11'}
						title={langStatus}
						subtitle={t('subscription.indexing.summary.details.status')}
					/>
					<InfoColumn
						title={`+ ${indexPercentage}%`}
						subtitle={t('subscription.indexing.summary.details.increase')}
					/>
					<InfoColumn
						title={subscriptionCount ?? ''}
						subtitle={t('subscription.indexing.summary.details.count')}
					/>
					{indexId && modDate ? (
						<InfoColumn
							title={moment(modDate).format('L')}
							subtitle={t('default.lastUpdated')}
						/>
					) : null}
					<InfoColumn
						title={totalIncreaseFormatted}
						subtitle={t('subscription.indexing.summary.details.value' + (hasMRR ? '.MRR' : ''))}
					/>
					{indexId && createdBy ? (
						<Flex alignItems="center" gap={8}>
							<Avatar size={28} user={createdBy} />
							<InfoColumn title={createdBy.name} subtitle={t('default.createdBy')} />
						</Flex>
					) : null}
				</Row>
			</Block>
		</>
	);
};

const SubscriptionIndexingSummary = (props: Props) => {
	const { state, dispatch, hidePreview } = props;
	const classNames = new BemClass('SubscriptionIndexingSummary');

	const metadata = useMetadata();
	const hasMRR = usesMRR(metadata);

	const {
		name,
		regBy,
		modDate,
		filter,
		isPassed,
		specificDate,
		indexInterval,
		indexingEnabled,
		indexPercentage,
		totalIncreaseARR,
		subscriptionCount,
		subscriptionValue,
		indexConflictDecisionsMap,
		id
	} = state;

	const { setConflictDecision } = SubscriptionIndexingState(dispatch);
	const [totalIncrease, setTotalIncrease] = React.useState<number>(0);
	const totalIncreaseFormatted = currencyFormat(totalIncrease, metadata?.defaultCurrency?.iso || 'SEK');

	React.useEffect(() => {
		if (isPassed) {
			setTotalIncrease(hasMRR ? totalIncreaseARR / 12 : totalIncreaseARR);
		} else {
			setTotalIncrease(((subscriptionValue ?? 0) * (indexPercentage / 100)) / (hasMRR ? 12 : 1));
		}
	}, [subscriptionValue, totalIncreaseARR, indexPercentage, hasMRR, isPassed]);

	const activeCrmUsers = useUsers('active', false, 'crm', true);
	const createdBy = activeCrmUsers.find(user => user.id === regBy?.id);
	const conflictCount = Object.keys(indexConflictDecisionsMap).length;
	const regularCount = (subscriptionCount ?? 0) - conflictCount;

	return (
		<div className={classNames.b()}>
			<div className={classNames.elem('content').b()}>
				<Block space="ptl" />
				<IndexInfo
					createdBy={createdBy}
					hasMRR={hasMRR}
					isPassed={isPassed}
					indexInterval={indexInterval}
					indexingEnabled={indexingEnabled}
					indexPercentage={indexPercentage}
					totalIncreaseFormatted={totalIncreaseFormatted}
					subscriptionCount={subscriptionCount}
					modDate={modDate}
					name={name}
					specificDate={specificDate}
					indexId={id}
					filter={filter}
				/>

				{Object.keys(indexConflictDecisionsMap)?.length && !isPassed ? (
					<Block space="ptl pbxl">
						<SubscriptionListConflict
							indexConflictDecisionsMap={indexConflictDecisionsMap}
							setConflictDecision={setConflictDecision}
							dispatch={dispatch}
							state={state}
						/>
					</Block>
				) : null}

				{
					// if it is a passed index increase and regular count 0 we still want to load the ArrChangeLogRawList
					// untill we solve the issue where the count is 0 even when it ran successfully
					hidePreview || (regularCount === 0 && !isPassed) ? null : (
						<Block space="ptl">
							{isPassed ? (
								<ArrChangelogRawList state={state} dispatch={dispatch} />
							) : (
								<SubscriptionListPreview state={state} dispatch={dispatch} />
							)}
						</Block>
					)
				}
			</div>
		</div>
	);
};

export default SubscriptionIndexingSummary;
