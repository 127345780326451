import {
	Block,
	Card,
	EllipsisTooltip,
	Flex,
	Help,
	Icon,
	Link,
	Table,
	TableColumn,
	TableRow,
	Text
} from '@upsales/components';
import { renderLoadingTable } from 'App/pages/Reportcenter/ReportcenterWidget/TableWidget/TableWidget';
import { makeCancelable } from '@upsales/components/Utils/CancelablePromise';
import { ContactWithSignals, MailWithSignals } from 'App/resources/Model/AISignals';
import { useTranslation } from 'Components/Helpers/translate';
import { closeAllModals } from 'Store/actions/ModalActions';
import BemClass from '@upsales/components/Utils/bemClass';
import AISignalsResource from 'App/resources/AISignals';
import { useAppDispatch } from 'App/components/hooks';
import React, { useEffect, useState } from 'react';
import SignalsBar from '../SignalsBar/SignalsBar';
import openModal from 'App/services/Modal';
import logError from 'Helpers/logError';
import moment from 'moment';

import './ContactSignals.scss';

type ContactSignalsProps = {
	contactId: number;
};

const ContactSignals = ({ contactId }: ContactSignalsProps) => {
	const classes = new BemClass('ContactSignals');
	const [contactData, setContactData] = useState<ContactWithSignals | null>(null);
	const [loading, setLoading] = useState(true);
	const dateFormat = 'YYYY-MM-DD HH:mm';
	const dispatch = useAppDispatch();

	const { t } = useTranslation();

	useEffect(() => {
		setLoading(true);
		const cancellable = makeCancelable(AISignalsResource.getEmailSignals(contactId));
		cancellable.promise
			.then(({ data }) => {
				setContactData(data);
				setLoading(false);
			})
			.catch(err => {
				logError(err, 'It could not load email signals');
			});

		return () => {
			cancellable?.cancel?.();
		};
	}, [contactId]);

	return (
		<Flex direction="column" gap="u3" space="mll">
			<Flex alignItems="center" gap="u1">
				<Text bold size="xl" loading={loading}>
					{`${t('default.signals.from')} ${contactData?.name}`}
				</Text>
				<Help articleId={1543} sidebar />
			</Flex>
			{contactData ? (
				<Flex alignItems="center" gap="u1">
					<Text loading={loading} color="grey-11">
						{contactData?.title}
					</Text>
					{!loading && contactData?.client ? (
						<>
							<Text color="grey-11" className={classes.elem('at').b()}>
								{t('default.at')}
							</Text>
							<Link
								onClick={() => {
									dispatch(closeAllModals());
								}}
								href={Tools.$state.href('account.dashboard', {
									id: contactData.client.id,
									customerId: Tools.AppService.getCustomerId()
								})}
							>
								{contactData.client.name}
							</Link>
						</>
					) : null}
				</Flex>
			) : null}
			<Text color="grey-11" loading={loading}>
				{t('default.signals.clarification')}
			</Text>
			{contactData ? (
				<Block space="mbl mtl">
					<SignalsBar
						signals={contactData.signals}
						periodText={`(${t('default.lastNMonths', { months: 6 })})`}
						loading={loading}
					/>
				</Block>
			) : null}
			<Card>
				{loading ? (
					renderLoadingTable(classes.elem('loadingTable'), 1)
				) : (
					<Table loading={!contactData}>
						{contactData?.mails?.map((mail: MailWithSignals) => (
							<TableRow
								key={mail.id}
								className={classes.elem('mailRow').b()}
								onClick={() => {
									openModal('SentMail', { mail });
								}}
							>
								<TableColumn className={classes.elem('column').elem('icon').b()}>
									<Icon name="email" />
								</TableColumn>
								<TableColumn>
									<EllipsisTooltip title={mail.signals.summary}>
										<Text className={classes.elem('mailRowText').b()}>{mail.signals.summary}</Text>
									</EllipsisTooltip>
									<EllipsisTooltip title={`${mail.subject}`}>
										<Text className={classes.elem('mailRowText').b()} color="grey-11" size="sm">
											{`${moment(mail.date).format(dateFormat)} • ${mail.subject}`}
										</Text>
									</EllipsisTooltip>
								</TableColumn>
								<TableColumn>
									<Flex gap="u2" justifyContent="flex-end" space="prl">
										{mail.signals.positive ? (
											<Text
												className={classes.elem('numericalIndicator').elem('positive').b()}
												color="success-4"
												space="pts prs pbs pls"
											>
												{`+${mail.signals.positive}`}
											</Text>
										) : null}
										{mail.signals.negative ? (
											<Text
												className={classes.elem('numericalIndicator').elem('negative').b()}
												color="danger-4"
												space="pts prs pbs pls"
											>
												{`-${mail.signals.negative}`}
											</Text>
										) : null}
									</Flex>
								</TableColumn>
							</TableRow>
						))}
					</Table>
				)}
			</Card>
		</Flex>
	);
};

export default ContactSignals;
