import React, { useEffect, useRef } from 'react';
import useEditOrderContext from '../hooks/useEditOrderContext';

import type { ComponentProps } from 'react';
import type { ProjectIdName } from 'App/resources/Model/Project';

type Props = Omit<ComponentProps<typeof ReactTemplates.INPUTS.upCampaigns>, 'value' | 'onChange'> & {
	value: ProjectIdName | null;
	onChange: (item: ProjectIdName) => void;
};

const CampaignInput = ({ name, required, onChange: _onChange, value, ...props }: Props) => {
	const { registerFormComponent, unregisterFormComponent, onFormConponentChange } = useEditOrderContext();
	const lastCommittedValueRef = useRef<ProjectIdName | null>(value);

	useEffect(() => {
		if (name) {
			const valid = required ? !!value : true;
			registerFormComponent(name, valid);
			return () => unregisterFormComponent(name);
		}
		// Will not add name to the dependency array because then I need something like renameControl
	}, []);

	useEffect(() => {
		if (value !== lastCommittedValueRef.current) {
			if (name) {
				const valid = required ? !!value : true;
				onFormConponentChange(name, valid, false);
			}
		}
	}, [value]);

	function onChange(value: ProjectIdName) {
		lastCommittedValueRef.current = value;

		_onChange?.(value);

		if (name) {
			const valid = required ? !!value : true;
			onFormConponentChange(name, valid, true);
		}
	}

	return (
		<ReactTemplates.INPUTS.upCampaigns
			{...props}
			multiple={false}
			name={name}
			value={value}
			onChange={onChange}
			required={required}
		/>
	);
};

export default CampaignInput;
