import Attribute, { DisplayType, Type } from './Attribute';

const SegmentAttributes = {
	id: Attribute({
		title: 'default.id',
		field: 'id',
		type: Type.Number,
		displayType: DisplayType.Number,
		groupable: false,
		selectableColumn: true,
		filterable: false,
		sortable: 'id'
	}),
	description: Attribute({
		title: 'default.description',
		field: 'description',
		type: Type.String,
		displayType: DisplayType.Text,
		groupable: false,
		selectableColumn: true
	}),
	name: Attribute({
		title: 'default.name',
		field: 'name',
		type: Type.String,
		displayType: DisplayType.Text,
		groupable: false,
		sortable: 'name',
		selectableColumn: true
	}),
	createDate: Attribute({
		title: 'default.appointmentCreateDate',
		field: 'createDate',
		type: Type.Date,
		displayType: DisplayType.Date,
		groupable: false,
		selectableColumn: true,
		filterable: false,
		sortable: 'createDate'
	}),
	regBy: Attribute({
		title: 'default.createdBy',
		field: 'regBy',
		type: Type.Number,
		displayType: DisplayType.User,
		groupable: false,
		selectableColumn: true
	}),
	active: Attribute({
		title: 'default.status',
		field: 'active',
		type: Type.Boolean,
		displayType: DisplayType.Boolean,
		groupable: false,
		selectableColumn: true,
		filterable: false,
		sortable: 'active'
	}),
	flowStatus: Attribute({
		title: 'flow.flowStatus',
		field: 'flow.status',
		type: Type.String,
		displayType: DisplayType.Text,
		groupable: false,
		// sortable: 'flow.status',
		selectableColumn: false
	}),
	flowName: Attribute({
		title: 'flow.flowName',
		field: 'flow.name',
		type: Type.String,
		displayType: DisplayType.Text
	}),
	filter: Attribute({
		title: 'default.filter',
		type: Type.Array,
		field: 'filter'
	}),
	sourceTemplate: Attribute({
		title: 'default.sourceTemplate',
		field: 'sourceTemplate',
		type: Type.String,
		displayType: DisplayType.Text,
		groupable: false,
		sortable: false,
		selectableColumn: false
	})
};

export default SegmentAttributes;
