import Resource from 'Resources/Resource';
import Segment from './Model/Segment';
import Attributes from 'App/babel/attributes/SegmentAttributes';

export type { Segment };

class SegmentResource extends Resource {
	notifications: { [key: string]: () => { [key: string]: string } };

	constructor() {
		super('segments', Attributes);

		this.notifications = {
			save: () => ({
				title: 'default.saved',
				body: 'saved.segment',
				style: 'success',
				icon: 'check',
				type: 'body'
			}),
			saveError: () => ({
				title: 'default.error',
				body: 'saveError.segment',
				style: 'error',
				icon: 'times',
				type: 'body'
			}),
			delete: () => ({
				title: 'default.deleted',
				body: 'deleted.segment',
				style: 'success',
				icon: 'check',
				type: 'body'
			}),
			deleteError: () => ({
				title: 'default.error',
				body: 'deleteError.segment',
				style: 'error',
				icon: 'times',
				type: 'body'
			})
		};
	}

	add(selectedIds: number[], properties: any) {
		if (!Array.isArray(properties)) {
			properties = [properties];
		}
		return super._postRequest(`add`, { selectedIds, properties, filters: {} });
	}
}

const resource = new SegmentResource();

export default resource;
