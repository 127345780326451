import React from 'react';
import SegmentResource, { Segment } from '../../resources/Segment';
import { asyncModalAdapter } from 'App/helpers/angularPortingHelpers';
import './NewSegmentModal.scss';
import { ModalProps } from '../Modals/Modals';

type Props = ModalProps & {
	saving: boolean;
	segment: Segment;
	fromFlow: boolean;
	noRedirect: boolean;
};

export function NewSegmentModal(props: Props) {
	const [saving, setSaving] = React.useState(false);
	const [segment, setSegment] = React.useState(props.segment ?? { name: '', description: '', active: true });
	const returnData = props.fromFlow || props.noRedirect || false;

	const closeModal = () => {
		props.close();
	};

	function onSave(obj: any, isEdit: boolean, returnData: boolean) {
		setSaving(true);

		if (isEdit) {
			obj.id = segment.id;
		} else {
			obj.filter = [
				{
					orGroup: false,
					isExclude: false,
					config: {
						Active: {
							comparisonType: 'Equals',
							filterName: 'Active',
							dataType: 'radio',
							field: 'active',
							value: true,
							inactive: false
						}
					},
					body: {
						q: [
							{
								a: 'active',
								c: 'eq',
								v: true
							}
						]
					}
				}
			];
		}

		SegmentResource.save(obj)
			.then(res => {
				const options = {
					id: res.data.id
				};

				if (isEdit || returnData) {
					props.close(res.data);
				} else {
					setSegment(res.data);
					closeModal();
					Tools.$state.go('segment.contacts', options);
				}
			})
			.catch(function () {
				setSaving(false);
			});
	}

	return (
		<div
			style={{
				width: '970px',
				height: '797px',
				display: 'block',
				visibility: 'visible'
			}}
			id="newSegment"
			className="up-modal notranslate v2 ng-scope compiled"
		>
			<ReactTemplates.segment.newSegmentModal
				saving={saving}
				segment={segment}
				closeModal={closeModal}
				onSave={onSave}
				returnData={returnData}
				tools={Tools}
			/>
		</div>
	);
}

const openNewSegmentModal = asyncModalAdapter({
	upModalName: 'NewSegment',
	openModalName: 'NewSegmentModal',
	featureFlag: 'REACT_NEW_SEGMENT_MODAL'
});

export default openNewSegmentModal;
