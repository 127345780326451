import { Dispatch } from 'redux';
import { cloneDeep, isEqual, isMatch, sortBy } from 'lodash';
import moment from 'moment';

import { easyBookingTracker } from 'App/babel/helpers/Tracker';
import logError from 'App/babel/helpers/logError';
import { AppThunk } from '../../../store';
import EasyBookingSettings from 'Resources/EasyBookingSettings';
import RequestBuilder, { comparisonTypes } from 'Resources/RequestBuilder';
import { EasyBookingRedux } from './EasyBookingTypes';
import EasyBookingLocalStorage from './common/EasyBookingLocalStorage';

/*********** Action types **********/
export const SET_DATA = '[EasyBookingSettingsV2] SET_DATA';
export const SET_STATE = '[EasyBookingSettingsV2] SET_STATE';
export const SET_OLDSTATE = '[EasyBookingSettingsV2] SET_OLDSTATE';
export const INIT = '[EasyBookingSettingsV2] INIT';
export const REMOVE_BOOKING_PAGES = '[EasyBookingSettingsV2] REMOVE_BOOKING_PAGES';
export const SET_USER_PARAMS = '[EasyBookingSettingsV2] SET_USER_PARAMS';

/**
 * All Actions available in the reducer.
 *
 * Example usage:
 *
 * ```dispatch<SetState>(...);```
 */
export type SetDataType = typeof SET_DATA;
export type SetStateType = typeof SET_STATE;
export type SetOldStateType = typeof SET_OLDSTATE;
export type InitType = typeof INIT;
export type RemoveBookingPagesType = typeof REMOVE_BOOKING_PAGES;

interface BookingPageAction<Action, DataType> {
	type: Action;
	data: Partial<DataType>;
	bookingPageId: number;
}

export type SetState = Omit<BookingPageAction<SetStateType, EasyBookingRedux.StateModel>, 'bookingPageId'>;
export type RemoveBookingPages = Omit<
	BookingPageAction<RemoveBookingPagesType, EasyBookingRedux.StateModel>,
	'bookingPageId' | 'data'
>;
export type SetData = BookingPageAction<SetDataType, EasyBookingRedux.LocalBookingPage>;
export type SetOldState = BookingPageAction<SetOldStateType, EasyBookingRedux.LocalBookingPage>;
export type Init = BookingPageAction<
	InitType,
	{
		curState: Omit<EasyBookingRedux.LocalBookingPage, 'oldState'>;
		oldState: EasyBookingRedux.BookingPageOldState;
	}
>;

// Easy booking is locally active until it has been properly configured,
// This is in localStorage as some page reloads are done during configuration.
// --
// This means that the local active state starts when the user is trying to
// configure Easy booking, while the remote active property is when it is
// successfully configured.
const isLocallyActiveLocalStorage = new EasyBookingLocalStorage<boolean>('isLocallyActive');

/***********************************/

const reducer = (
	state: EasyBookingRedux.StateModel = EasyBookingRedux.INITIAL_STATE,
	action: SetState | SetData | SetOldState | Init | RemoveBookingPages
): EasyBookingRedux.StateModel => {
	switch (action.type) {
		case INIT:
			return {
				...state,
				bookingPages: {
					...state.bookingPages,
					[action.bookingPageId]: {
						...state.bookingPages[action.bookingPageId],
						...action.data.curState,
						oldState: {
							...state.bookingPages[action.bookingPageId]?.oldState,
							...action.data.oldState
						}
					}
				}
			};
		case REMOVE_BOOKING_PAGES:
			return {
				...state,
				bookingPages: {}
			};
		case SET_STATE:
			return {
				...state,
				...action.data
			};
		case SET_DATA:
			return {
				...state,
				bookingPages: {
					...state.bookingPages,
					[action.bookingPageId]: {
						...state.bookingPages[action.bookingPageId],
						...action.data
					}
				}
			};
		case SET_OLDSTATE:
			return {
				...state,
				bookingPages: {
					...state.bookingPages,
					[action.bookingPageId]: {
						...state.bookingPages[action.bookingPageId],
						oldState: {
							...state.bookingPages[action.bookingPageId]?.oldState,
							...action.data
						}
					}
				}
			};
		default:
			return state;
	}
};

export default reducer;

/*********** Helpers **********/
export const formatAndFilterTimes = (
	recurringArray: EasyBookingRedux.RecurringInterval[],
	deviatingArray: EasyBookingRedux.DeviatingInterval[],
	applyToDates: string[],
	intervals: EasyBookingRedux.Interval[],
	isRecurring: boolean,
	isAvailable: boolean
): EasyBookingRedux.OutputIntervals => {
	let _recurringArray = recurringArray;
	let _deviatingArray = deviatingArray;

	for (const date of applyToDates) {
		const dayOfWeek = moment.utc(date).day();

		if (isRecurring) {
			_recurringArray = _recurringArray.filter(d => d.dayOfWeek !== dayOfWeek);
		}

		if (isRecurring && !isAvailable) {
			_deviatingArray = _deviatingArray.filter(d => {
				const newDayOfWeek = moment.utc(d.date).day();
				return newDayOfWeek !== dayOfWeek;
			});
		}

		_deviatingArray = _deviatingArray.filter(d => {
			return d.date !== date;
		});

		if (isAvailable) {
			for (const obj of intervals) {
				if (isRecurring) {
					_recurringArray = [
						..._recurringArray,
						{
							startTime: obj.startTime,
							endTime: obj.endTime,
							dayOfWeek
						}
					];
				} else {
					_deviatingArray = [
						..._deviatingArray,
						{
							startTime: obj.startTime,
							endTime: obj.endTime,
							date,
							isAvailable: true
						}
					];
				}
			}
		} else if (!isRecurring) {
			_deviatingArray = [
				..._deviatingArray,
				{
					startTime: '',
					endTime: '',
					date,
					isAvailable: false
				}
			];
		}
	}

	return { deviating: _deviatingArray, recurring: _recurringArray };
};

/**
 * Formats the object with the given schema. Both obj and schema are Objects.
 * All keys that are in obj, but not in schema will not be in the resulting object.
 * All keys that are in schema, but not in obj will be given the value of that key
 * from the schema object in the resulting object.
 * @param obj
 * @param schema
 * @returns An object formatted as per the given schema
 */
const formatWithSchema = <InputType, ReturnType = InputType>(
	obj: InputType,
	schema: Partial<InputType>
): ReturnType => {
	const formattedCal = cloneDeep(obj) as any;

	for (const key in { ...formattedCal, ...schema }) {
		if (!(key in schema)) {
			delete formattedCal[key];
		} else {
			formattedCal[key] ??= cloneDeep(schema[key as keyof InputType]);
		}
	}

	return formattedCal as ReturnType;
};

/** Tells us which configuration state the user is in
 *
 */
const checkActivationState = (
	calendarIntegrations: EasyBookingRedux.EasyBookingCalendarIntegration[],
	tryingToActivate?: boolean
) => {
	let activationState = EasyBookingRedux.ActivationState.Disabled;

	if (!tryingToActivate && !isLocallyActiveLocalStorage.getValue()) return activationState;

	if (calendarIntegrations.some(ci => ci.activatedByAdmin && ci.activatedByUser && ci.authorized)) {
		// Everything needed
		activationState = EasyBookingRedux.ActivationState.CanActivate;
	} else if (calendarIntegrations.some(ci => ci.activatedByAdmin && ci.activatedByUser)) {
		// Everything but auth
		activationState = EasyBookingRedux.ActivationState.ActiveIntegrationWithoutAuth;
	} else if (calendarIntegrations.some(ci => ci.activatedByAdmin)) {
		// User app inactivated
		activationState = EasyBookingRedux.ActivationState.InactiveIntegrationForUser;
	} else if (Tools.AppService.getSelf().administrator) {
		// No calendar app for customer, user is admin
		activationState = EasyBookingRedux.ActivationState.InactiveIntegrationForCustomerAsAdmin;
	} else if (isLocallyActiveLocalStorage.getValue() || tryingToActivate) {
		// User has started before or is starting activation process
		activationState = EasyBookingRedux.ActivationState.InactiveIntegrationForCustomerAsUser; // No valid apps
	}

	return activationState;
};

/**
 * @param dispatch A Redux dispatch of any action
 */
export const updateCalendarIntegration = async (
	dispatch: Dispatch
): Promise<EasyBookingRedux.EasyBookingCalendarIntegration[]> => {
	const appServiceCalendarIntegrations = Tools.AppService.getCalendarIntegrations();
	const customerId = Tools.AppService.getCustomerId();
	let calendarIntegrations: EasyBookingRedux.EasyBookingCalendarIntegration[] = [];
	try {
		const customerId = Tools.AppService.getCustomerId() ?? Tools.$stateParams.customerId;
		const [{ data: allIntegrations }, { data: adminSettings }, { data: userSettings }] = await Promise.all([
			Tools.StandardIntegration.find({ active: true, init: 'calendar' }),
			Tools.StandardIntegration.setting(customerId).get(),
			Tools.StandardIntegration.userSetting(customerId).get()
		]);

		calendarIntegrations = allIntegrations.map(ci => {
			const adminSetting = adminSettings.find(s => s.integrationId === ci.id);
			const userSetting = userSettings.find(s => s.integrationId === ci.id);
			const activatedByUser = appServiceCalendarIntegrations.some(({ id }) => id === ci.id);

			const authorization = userSetting ? JSON.parse(userSetting.configJson ?? '{}')?.oauth : null;
			const authorized = authorization ?? null !== null;

			return {
				name: ci.name,
				id: ci.id,
				imageLink: ci.imageLink,
				color: ci.color,
				authorized,
				activatedByAdmin: adminSetting ? adminSetting.active : false,
				activatedByUser
			};
		});
	} catch (err) {
		logError(err, '[EasyBookingSettingsV2] failed to get or update calendarIntegration');
	}

	dispatch<SetState>({ type: SET_STATE, data: { calendarIntegrations } });
	return calendarIntegrations;
};

/**
 * Updates the userParam for easy booking as active/inactive
 */
const setUserParam = async (value: boolean) => {
	try {
		await Tools.UserParam.save(EasyBookingRedux.UserParamId, value, { skipNotification: true });
		const self = Tools.AppService.getSelf();
		Tools.AppService.setSelf({
			...self,
			userParams: {
				...self.userParams,
				['easyBooking']: value
			}
		});
	} catch (err) {
		logError(err, `[EasyBookingSettingsV2] Failed to set user param of easy booking to ${value}`);
	}
};

// Functions for editing metadata in Tools.AppService
const getEZBMetadata = () => Tools.AppService.getMetadata().activatedFeatures.easyBooking;

type EZBMetadata = ReturnType<typeof getEZBMetadata>;
type EZBMetadataBookingPage = EZBMetadata['bookingPages'][0];

const setEZBMetadata = (data: EZBMetadata) => {
	const metadata = Tools.AppService.getMetadata();
	Tools.AppService.setMetadata({
		...metadata,
		activatedFeatures: {
			...metadata.activatedFeatures,
			easyBooking: data
		}
	});
};

const toMetadataBookingPage = (bookingPage: EasyBookingRedux.LocalBookingPage): EZBMetadataBookingPage => ({
	active: bookingPage.active,
	bookingUrl: bookingPage.bookingUrl,
	id: bookingPage.id,
	isShared: bookingPage.isShared,
	title: bookingPage.title
});

const addOrEditBookingPageInMetadata = (bookingPage: EasyBookingRedux.LocalBookingPage) => {
	const metadata = getEZBMetadata();
	metadata.bookingPages = metadata.bookingPages.filter(bp => bp.id !== bookingPage.id);
	metadata.bookingPages.push(toMetadataBookingPage(bookingPage));
	if (bookingPage.isDefault) {
		metadata.bookingUrl = bookingPage.bookingUrl;
	}
	setEZBMetadata(metadata);
};

const removeBookingPageInMetadata = (id: number) => {
	const metadata = getEZBMetadata();
	metadata.bookingPages = metadata.bookingPages.filter(bp => bp.id !== id);
	setEZBMetadata(metadata);
};

const setActiveInMetadata = (active: boolean) => {
	setEZBMetadata({ ...getEZBMetadata(), active });
};

// Set the true user "active" state - Userparam, metadata and redux state
const setUserActive = async (dispatch: Dispatch, value: boolean) => {
	setUserParam(value);
	setActiveInMetadata(value);
	dispatch<SetState>({ type: SET_STATE, data: { active: value } });
};

/*********** Actions **********/
/**
 * Updates the Redux state of a key-value pair in a booking page with the given booking page id
 * @param bookingPageId the id of the booking page that is to be updated
 * @param key the key in the booking page object
 * @param value the new value
 */
export const setData =
	(
		bookingPageId: number,
		key: keyof EasyBookingRedux.LocalBookingPage,
		value: EasyBookingRedux.LocalBookingPage[keyof EasyBookingRedux.LocalBookingPage]
	): AppThunk<Promise<void>> =>
	async (dispatch, getState) => {
		const state = getState().EasyBookingSettingsV2;

		try {
			if (!bookingPageId) {
				throw new Error('Calendar id not specified');
			}

			if (!(bookingPageId in state.bookingPages) || !state.bookingPages[bookingPageId]) {
				throw new Error('No such booking page');
			}

			if (!(key in state.bookingPages[bookingPageId])) {
				throw new Error('Invalid key');
			}

			if (typeof EasyBookingRedux.BOOKING_PAGE_LOCAL_SCHEMA[key] !== typeof value) {
				throw new Error(`The type of the given value does not match the type in Redux.`);
			}
		} catch (err) {
			logError(err, `[EasyBookingSettingsV2] failed to set the state of booking page with id ${bookingPageId}.`);
			return;
		}

		dispatch<SetData>({ type: SET_DATA, bookingPageId, data: { [key]: value } });

		if (key === 'stateHasChanged') {
			return;
		}

		const stateAfterUpdate = getState().EasyBookingSettingsV2;

		const curState: Optional<EasyBookingRedux.LocalBookingPage, 'oldState'> = cloneDeep(
			stateAfterUpdate.bookingPages[bookingPageId]
		);
		const formattedCurState = formatWithSchema<
			Optional<EasyBookingRedux.LocalBookingPage, 'oldState'>,
			EasyBookingRedux.BookingPageOldState
		>(curState, EasyBookingRedux.OLD_STATE_SCHEMA);
		const oldState = cloneDeep(curState.oldState as EasyBookingRedux.BookingPageOldState);
		delete curState.oldState;

		const sortByAppointmentLengths = ({ appointmentLength }: EasyBookingRedux.AppointmentLength) =>
			appointmentLength;
		const appointmentLengthsMatch = isEqual(
			sortBy(formattedCurState.appointmentLengths, sortByAppointmentLengths),
			sortBy(oldState.appointmentLengths, sortByAppointmentLengths)
		);
		const sortByRecurring = ({ endTime, startTime, dayOfWeek }: EasyBookingRedux.RecurringInterval) =>
			`${endTime}$-${startTime}-${dayOfWeek}`;
		const recurringDaysMatch = isEqual(
			sortBy(formattedCurState.recurringDays, sortByRecurring),
			sortBy(oldState.recurringDays, sortByRecurring)
		);
		const sortByDeviating = ({ endTime, startTime, date, isAvailable }: EasyBookingRedux.DeviatingInterval) =>
			`${endTime}$-${startTime}-${date}-${isAvailable}`;
		const deviatingDaysMatch = isEqual(
			sortBy(formattedCurState.deviatingDays, sortByDeviating),
			sortBy(oldState.deviatingDays, sortByDeviating)
		);

		const sortByRoleId = ({ roleId }: EasyBookingRedux.Role) => roleId;
		const sortByUserId = ({ userId }: EasyBookingRedux.User) => userId;

		const usersMatch = isEqual(sortBy(formattedCurState.users, sortByUserId), sortBy(oldState.users, sortByUserId));
		const rolesMatch = isEqual(sortBy(formattedCurState.roles, sortByRoleId), sortBy(oldState.roles, sortByRoleId));

		if (
			!isMatch(formattedCurState, oldState) ||
			!usersMatch ||
			!rolesMatch ||
			!appointmentLengthsMatch ||
			!recurringDaysMatch ||
			!deviatingDaysMatch
		) {
			dispatch<SetData>({ type: SET_DATA, bookingPageId, data: { stateHasChanged: true } });
		} else {
			dispatch<SetData>({ type: SET_DATA, bookingPageId, data: { stateHasChanged: false } });
		}
	};

export const setAvailableTimes =
	(
		bookingPageId: number,
		applyToDates: string[],
		intervals: EasyBookingRedux.Interval[],
		isRecurring: boolean,
		isAvailable: boolean
	): AppThunk<Promise<void>> =>
	async (dispatch, getState) => {
		try {
			const state = getState().EasyBookingSettingsV2;

			if (!(bookingPageId in state.bookingPages)) {
				throw new Error(`booking page with id ${bookingPageId} does not exist`);
			}

			const { deviating, recurring } = formatAndFilterTimes(
				state.bookingPages[bookingPageId].recurringDays,
				state.bookingPages[bookingPageId].deviatingDays,
				applyToDates,
				intervals,
				isRecurring,
				isAvailable
			);

			dispatch(setData(bookingPageId, 'deviatingDays', deviating));
			dispatch(setData(bookingPageId, 'recurringDays', recurring));
		} catch (err) {
			logError(err, `[EasyBookingSettingsV2] failed to set available times`);
		}
	};

/***** Dispatched actions *****/
/**
 * Initializes the Redux state of an Easy booking page
 * @param bookingPageId the id of the booking page that is fetched and updated in the Redux state
 * @returns whether the booking page exists or not
 */
export const initOne =
	(bookingPageId: number): AppThunk<Promise<boolean>> =>
	async dispatch => {
		let exists = false;
		dispatch<SetState>({ type: SET_STATE, data: { loading: true } });

		try {
			if (!Number.isInteger(bookingPageId)) {
				throw new Error(`The booking page id is invalid`);
			}

			const resCal: { data: EasyBookingRedux.RemoteBookingPage } = await EasyBookingSettings.get(bookingPageId);

			if (resCal.data.id !== bookingPageId) {
				throw new Error(`There is no booking page with id ${bookingPageId}`);
			}

			if (resCal.data.appointmentLengths.length === 0 && resCal.data.appointmentLength) {
				// if appointment length is in old format, convert to new format
				resCal.data.appointmentLengths = [{ appointmentLength: resCal.data.appointmentLength }];
			}

			const curState = formatWithSchema<EasyBookingRedux.RemoteBookingPage, EasyBookingRedux.LocalBookingPage>(
				resCal.data,
				EasyBookingRedux.BOOKING_PAGE_LOCAL_SCHEMA
			);

			curState.users = (curState.users as any[]).map(user => {
				const newStructure = {
					...user,
					...user.user
				};
				delete newStructure.user;
				return newStructure;
			});

			const oldState = formatWithSchema<EasyBookingRedux.LocalBookingPage, EasyBookingRedux.BookingPageOldState>(
				curState,
				EasyBookingRedux.OLD_STATE_SCHEMA
			);

			dispatch<Init>({ type: INIT, bookingPageId, data: { oldState, curState } });
			exists = true;
		} catch (err) {
			logError(
				err,
				`[EasyBookingSettingsV2] failed to init the state of the booking page with id ${bookingPageId}`
			);
		} finally {
			dispatch<SetState>({ type: SET_STATE, data: { loading: false } });
		}

		return exists;
	};

/**
 * Initializes the Redux state with all Easy booking pages
 */
export const initAll =
	(onShared?: boolean): AppThunk<Promise<void>> =>
	async dispatch => {
		dispatch<SetState>({ type: SET_STATE, data: { loading: true } });
		dispatch<RemoveBookingPages>({ type: REMOVE_BOOKING_PAGES });
		try {
			const rb = new RequestBuilder();

			const user = Tools.AppService.getSelf();
			const easyBookingActive = user.userParams.easyBooking;
			//Check if everything is ok to load
			const calenderIntegrations = await updateCalendarIntegration(dispatch);
			// Pass true / false depending on if the user har activated before
			const activationState = checkActivationState(calenderIntegrations, easyBookingActive);
			dispatch<SetState>({ type: SET_STATE, data: { activationState } });

			if (onShared) {
				rb.addFilter({ field: 'isShared' }, comparisonTypes.Equals, 1);
			} else {
				// If something is wrong with the configuration we can exit and the guide will be shown
				if (activationState !== EasyBookingRedux.ActivationState.CanActivate) {
					return;
				}

				//The user has completed the activation steps -> update their userparam, metadata and redux state
				if (!easyBookingActive) {
					setUserActive(dispatch, true);
				} else {
					dispatch<SetState>({ type: SET_STATE, data: { active: easyBookingActive } });
				}

				const orBuilder = rb.orBuilder();

				orBuilder.next();
				orBuilder.addFilter({ field: 'users.userId' }, comparisonTypes.Equals, user.id);
				if (user.role) {
					orBuilder.next();
					orBuilder.addFilter({ field: 'roles.roleId' }, comparisonTypes.Equals, user.role.id);
				}
				orBuilder.done();
			}

			const res: { data: EasyBookingRedux.RemoteBookingPage[] } = await EasyBookingSettings.find(rb.build());

			// In order to await all booking pages before setting 'loading' to false
			await new Promise<void>(r => {
				const remoteBookingPages = cloneDeep(res.data);
				let bookingPage: EasyBookingRedux.RemoteBookingPage | undefined;

				// eslint-disable-next-line no-cond-assign
				while ((bookingPage = remoteBookingPages.shift())) {
					if (bookingPage.appointmentLengths.length === 0 && bookingPage.appointmentLength) {
						// if appointment length is in old format, convert to new format
						bookingPage.appointmentLengths = [{ appointmentLength: bookingPage.appointmentLength }];
					}

					const curState = formatWithSchema<
						EasyBookingRedux.RemoteBookingPage,
						EasyBookingRedux.LocalBookingPage
					>(bookingPage, EasyBookingRedux.BOOKING_PAGE_LOCAL_SCHEMA);

					curState.users = (curState.users as any[]).map(user => {
						const newStructure = {
							...user,
							...user.user
						};
						delete newStructure.user;
						return newStructure;
					});

					const oldState = formatWithSchema<
						EasyBookingRedux.LocalBookingPage,
						EasyBookingRedux.BookingPageOldState
					>(curState, EasyBookingRedux.OLD_STATE_SCHEMA);

					dispatch<Init>({
						type: INIT,
						bookingPageId: bookingPage.id as number,
						data: { oldState, curState }
					});
				}

				r();
			});
		} catch (err) {
			logError(err, `[EasyBookingSettingsV2] failed to init the state of all booking pages`);
		} finally {
			dispatch<SetState>({ type: SET_STATE, data: { loading: false } });
		}
	};

/**
 * Creates a new booking page and adds it to the Redux state
 * @param isShared whether the booking page should be shared or not
 * @returns the id of the created booking page
 */
export const createBookingPage =
	(isShared: boolean): AppThunk<Promise<number>> =>
	async dispatch => {
		let bookingPageId = -1;

		try {
			await updateCalendarIntegration(dispatch);
			const user = Tools.AppService.getSelf();
			const hasSharedAccess = user.administrator || user.userParams?.mailAdmin;

			if (hasSharedAccess && isShared) {
				const res = await EasyBookingSettings.save({ isShared: true, active: true });
				bookingPageId = res.data.id;
				const oldState = cloneDeep(res.data);
				delete oldState.bookingUrl;
				const curState = res.data;

				dispatch<Init>({ type: INIT, bookingPageId, data: { oldState, curState } });
			} else {
				const res = await EasyBookingSettings.save({ isShared: false, active: true });
				bookingPageId = res.data.id;
				const oldState = cloneDeep(res.data);
				delete oldState.bookingUrl;
				const curState = res.data;

				curState.users = (curState.users as any[]).map(user => {
					const newStructure = {
						...user,
						...user.user
					};
					delete newStructure.user;
					return newStructure;
				});

				// Only add for non shared booking page as user is not neccesarily
				// in the users/roles list of a shared booking page.
				addOrEditBookingPageInMetadata(curState);

				dispatch<Init>({ type: INIT, bookingPageId, data: { oldState, curState } });
			}
		} catch (err) {
			logError(err, `[EasyBookingSettingsV2] failed to create a${isShared ? ' shared' : ''} booking page`);
		}

		return bookingPageId;
	};

/**
 * Deletes a booking page and removes it from the Redux state
 *
 * @param bookingPageId
 * @returns whether it was successfully deleted or not
 */
export const deleteBookingPage =
	(bookingPageId: number): AppThunk<Promise<boolean>> =>
	async (dispatch, getState) => {
		let wasDeleted = false;

		try {
			await updateCalendarIntegration(dispatch);
			const state = getState().EasyBookingSettingsV2;
			const bookingPages = cloneDeep(state.bookingPages);

			await EasyBookingSettings.delete(bookingPageId);
			delete bookingPages[bookingPageId];
			wasDeleted = true;

			dispatch<SetState>({ type: SET_STATE, data: { bookingPages } });

			removeBookingPageInMetadata(bookingPageId);

			Tools.NotificationService.addNotification({
				style: Tools.NotificationService.style.SUCCESS,
				icon: 'check',
				title: 'admin.appointmentAvailability.deleteBookingPage'
			});
		} catch (err) {
			logError(err, `[EasyBookingSettingsV2] failed to delete the booking page`);
			Tools.NotificationService.addNotification({
				style: Tools.NotificationService.style.ERROR,
				icon: 'times',
				title: 'default.error',
				body: 'admin.appointmentAvailability.deleteBookingPageError'
			});
		}

		return wasDeleted;
	};

/**
 * Saves the current booking page state in the backend
 * @param bookingPageId
 */
export const saveSettings =
	(bookingPageId: number, notification?: boolean): AppThunk<Promise<void>> =>
	async (dispatch, getState) => {
		const state = getState().EasyBookingSettingsV2;

		if (!(bookingPageId in state.bookingPages)) {
			logError(
				new Error(
					`Invalid booking page id. Got: ${bookingPageId}. Expected one of: ${Object.keys(
						state.bookingPages
					).join(', ')}`
				),
				`[EasyBookingSettingsV2] failed to save the settings of booking page ${bookingPageId}`
			);
			return;
		}

		const self = Tools.AppService.getSelf();
		if (!state.bookingPages[bookingPageId].isShared && self?.userParams?.easyBooking === false) {
			logError(
				new Error('Easy booking is not activated.'),
				`[EasyBookingSettingsV2] failed to save the settings of booking page ${bookingPageId}`
			);
			return;
		}

		try {
			dispatch<SetData>({ type: SET_DATA, bookingPageId, data: { saving: true } });

			const apiFormattedCalendar = formatWithSchema<
				EasyBookingRedux.LocalBookingPage,
				EasyBookingRedux.RemoteBookingPage & { oldState: EasyBookingRedux.LocalBookingPage['oldState'] }
			>(state.bookingPages[bookingPageId], {
				...EasyBookingRedux.BOOKING_PAGE_REMOTE_SCHEMA,
				oldState: EasyBookingRedux.OLD_STATE_SCHEMA
			});

			await EasyBookingSettings.save(apiFormattedCalendar);

			if (!state.bookingPages[bookingPageId].isShared) {
				addOrEditBookingPageInMetadata(state.bookingPages[bookingPageId]);
			}

			const oldState = formatWithSchema<EasyBookingRedux.RemoteBookingPage, EasyBookingRedux.BookingPageOldState>(
				state.bookingPages[bookingPageId],
				EasyBookingRedux.OLD_STATE_SCHEMA
			);
			dispatch<SetOldState>({ type: SET_OLDSTATE, bookingPageId, data: oldState });
			dispatch<SetData>({ type: SET_DATA, bookingPageId, data: { stateHasChanged: false } });

			if (notification) {
				Tools.NotificationService.addNotification({
					style: Tools.NotificationService.style.SUCCESS,
					icon: 'check',
					title: 'saved.settings'
				});
			}
		} catch (err) {
			Tools.NotificationService.addNotification({
				style: Tools.NotificationService.style.ERROR,
				icon: 'times',
				title: 'default.error',
				body: 'admin.appointmentAvailability.savedError'
			});
			logError(err, `[EasyBookingSettingsV2] failed to save the settings of booking page ${bookingPageId}`);
		} finally {
			dispatch<SetData>({ type: SET_DATA, bookingPageId, data: { saving: false } });
		}
	};

/**
 * Activates or deactivates Easy Booking for a user
 * @param active
 */
export const setEasyBookingActivation =
	(active: boolean): AppThunk<Promise<void>> =>
	async (dispatch, getState) => {
		try {
			isLocallyActiveLocalStorage.setValue(active);
			if (!active) {
				dispatch<SetState>({
					type: SET_STATE,
					data: { activationState: EasyBookingRedux.ActivationState.Disabled }
				});
				setUserActive(dispatch, active);
				return;
			}
			const state = getState().EasyBookingSettingsV2;

			// Check if user has calendar apps, integrations etc.
			const activationState = checkActivationState(state.calendarIntegrations, active);
			dispatch<SetState>({ type: SET_STATE, data: { activationState } });

			// If everything is not configured correctly and trying to activate -> show guide
			if (activationState !== EasyBookingRedux.ActivationState.CanActivate) {
				return;
			} else {
				//Everything is configured correctly
				// Set the userparam in DB, metadata and reduxstate
				setUserActive(dispatch, active);

				if (active) {
					Tools.NotificationService.addNotification({
						style: Tools.NotificationService.style.SUCCESS,
						icon: 'check',
						title: 'default.successful',
						body: 'saved.settings'
					});
					easyBookingTracker.track(easyBookingTracker.events.ACTIVATE, {
						date: moment().format('YYYY-MM-DD')
					});
				}
			}
		} catch (e) {
			Tools.NotificationService.addNotification({
				style: Tools.NotificationService.style.ERROR,
				icon: 'times',
				title: 'default.error',
				body: `admin.appointmentavailability.activateEasyBooking.${active ? 'active' : 'inactive'}.error`
			});
		}
	};

/**
 * Activates or deactivates the booking page with the given booking page id
 * @param bookingPageId
 * @param active
 * @param notification whether the function should show notifications during execution
 */
export const setBookingPageActivation =
	(bookingPageId: number, active: boolean, notification?: boolean): AppThunk<Promise<void>> =>
	async (dispatch, getState) => {
		const state = getState().EasyBookingSettingsV2;

		try {
			const apiFormattedCalendar = formatWithSchema<
				EasyBookingRedux.LocalBookingPage,
				EasyBookingRedux.RemoteBookingPage
			>(state.bookingPages[bookingPageId], EasyBookingRedux.BOOKING_PAGE_REMOTE_SCHEMA);
			await EasyBookingSettings.save({ ...apiFormattedCalendar, active });

			dispatch<SetData>({ type: SET_DATA, bookingPageId, data: { active } });

			if (notification) {
				Tools.NotificationService.addNotification({
					style: Tools.NotificationService.style.SUCCESS,
					icon: 'check',
					title: 'default.successful',
					body: `admin.appointmentavailability.activateBookingPage.${active ? 'active' : 'inactive'}`
				});
			}

			if (!state.bookingPages[bookingPageId].isShared) {
				addOrEditBookingPageInMetadata(state.bookingPages[bookingPageId]);
			}
		} catch (e) {
			if (notification) {
				Tools.NotificationService.addNotification({
					style: Tools.NotificationService.style.ERROR,
					icon: 'times',
					title: 'default.error',
					body: `admin.appointmentavailability.activateBookingPage.${active ? 'active' : 'inactive'}.error`
				});
			}
			logError(e, `[EasyBookingSettingsV2] failed to set the booking page ${bookingPageId} to active`);
		}
	};

export const setRole =
	(
		bookingPageId: number,
		selected: boolean,
		updateBookingLimit: boolean,
		role: EasyBookingRedux.Role
	): AppThunk<Promise<void>> =>
	async (dispatch, getState) => {
		try {
			const state = getState().EasyBookingSettingsV2;

			if (!(bookingPageId in state.bookingPages)) {
				throw new Error(`booking page with id ${bookingPageId} does not exist`);
			}

			const { roles } = state.bookingPages[bookingPageId];

			let newRoles = cloneDeep(roles);

			if (updateBookingLimit && selected) {
				//UPDATE
				const roleIdx = newRoles.findIndex(_role => _role.roleId === role.roleId);

				newRoles[roleIdx].bookingLimit = role.bookingLimit;
			} else if (!selected) {
				//ADD
				newRoles.push(role);
			} else {
				//DELETE
				newRoles = newRoles.filter(_role => _role.roleId !== role.roleId);
			}

			dispatch(setData(bookingPageId, 'roles', newRoles));
		} catch (err) {
			logError(err, `[EasyBookingSettingsV2] failed to set role`);
		}
	};

export const setUser =
	(
		bookingPageId: number,
		selected: boolean,
		roleSelected: boolean,
		updateBookingLimit: boolean,
		user: EasyBookingRedux.User
	): AppThunk<Promise<void>> =>
	async (dispatch, getState) => {
		try {
			const state = getState().EasyBookingSettingsV2;

			if (!(bookingPageId in state.bookingPages)) {
				throw new Error(`booking page with id ${bookingPageId} does not exist`);
			}

			const { users } = state.bookingPages[bookingPageId];

			let newUsers = cloneDeep(users);

			if (updateBookingLimit && selected) {
				//UPDATE
				const userIdx = newUsers.findIndex(_user => _user.userId === user.userId);
				newUsers[userIdx].bookingLimit = user.bookingLimit;
			} else if (!selected) {
				//ADD
				newUsers.push(user);
			} else {
				//DELETE
				newUsers = newUsers.filter(_user => _user.userId !== user.userId);
				//DELETE Role aswell if its selected and a real role
				if ((user.role?.id ?? -1) !== -1 && roleSelected) {
					const role = state.bookingPages[bookingPageId].roles.find(
						_role => _role.roleId === (user.role?.id ?? -1)
					);
					//Keep existing booking limit on users which was set by the role

					for (const _user of newUsers) {
						if ((_user.role?.id ?? -1) === role?.roleId && _user.bookingLimit === -1) {
							_user.bookingLimit = role?.bookingLimit ?? -1;
						}
					}
					dispatch(
						setRole(bookingPageId, true, false, {
							roleId: user.role?.id ?? -1,
							bookingLimit: 0
						})
					);
				}
			}

			dispatch(setData(bookingPageId, 'users', newUsers));
		} catch (err) {
			logError(err, `[EasyBookingSettingsV2] failed to set user`);
		}
	};
