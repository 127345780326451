import {
	Block,
	Flex,
	Icon,
	Table,
	TableHeader,
	Title,
	TableRow,
	TableColumn,
	NumberInput,
	Text,
	Label,
	Input,
	Button,
	Tooltip,
	EllipsisTooltip
} from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import React, { useEffect, useState } from 'react';

import ProductResource from 'Resources/Product';
import './ProjectInvoice.scss';
import { DefaultButton, PrimaryButton } from '@upsales/components/Buttons';
import { useTranslation } from 'react-i18next';
import ProductCategorySelect from 'App/components/OrderRows/OrderRow/ProductCategorySelect';
import { useSelector } from 'App/components/hooks';
import { RootState } from 'Store/index';
import { useEditProjectPlanContext } from '../Context';
import Product from 'App/resources/Model/Product';
import ProjectPlan from 'App/resources/Model/ProjectPlan';
import { getTierFromQuantity } from 'Helpers/order';
import logError from 'Helpers/logError';
import Order from 'App/resources/Model/Order';
import OrderResource from 'App/resources/Order';
import { currencyFormat } from 'Components/Filters/Currencies';
import InlineConfirm from 'Components/Dialogs/InlineConfirm';
import moment from 'moment';
import { makeCancelable } from 'Helpers/promise';
import { getProducts } from 'Store/selectors/AppSelectors';
import ProjectPlanInvoiceResource from 'App/resources/ProjectPlanInvoice';

type InvoiceProducts = ProjectPlan['relProducts'];

const ProjectInvoice = () => {
	const classes = new BemClass('ProjectInvoice');
	const { t } = useTranslation();
	const {
		totals: { products: productTotal },
		self
	} = useSelector((state: RootState) => state.App);
	const allProducts = getProducts();

	const { onProjectPlanChange, setProjectPlan, state } = useEditProjectPlanContext();
	const { projectPlan } = state as { projectPlan: ProjectPlan };
	const [inlineVisible, setInlineVisible] = useState(false);
	const [isCreatingInvoice, setIsCreatingInvoice] = useState(false);
	const [editingInvoiceName, setEditingInvoiceName] = useState(0);
	const [invoiceName, setInvoiceName] = useState('');

	useEffect(() => {
		if (state.fetchedInvoiceData) {
			return;
		}

		const updatedProjectPlan = { ...projectPlan, invoices: [...projectPlan.invoices].sort((a, b) => b.id - a.id) };
		let foundEmptyInvoice = false;
		projectPlan.invoices.forEach(invoice => {
			if (!projectPlan.relProducts.some(product => product.invoiceId === invoice.id)) {
				foundEmptyInvoice = true;
				updatedProjectPlan.relProducts = [...updatedProjectPlan.relProducts, createNewProduct(invoice.id)];
			}
		});
		if (foundEmptyInvoice) {
			setProjectPlan(updatedProjectPlan);
		}

		const productIds = new Set(updatedProjectPlan.relProducts.map(product => product.product.id));
		const fetchProducts =
			productTotal < 4000
				? Promise.resolve(allProducts.filter(product => productIds.has(product.id)))
				: ProductResource.find({ id: Array.from(productIds) }).then(({ data }) => data);

		const { promise, cancel } = makeCancelable(fetchProducts);
		promise
			.then(([products]) => {
				const newRelProducts = updatedProjectPlan.relProducts.map(product => ({
					...product,
					product: products.find((p: Product) => p.id === product.product.id) ?? product.product
				}));

				setProjectPlan({
					...updatedProjectPlan,
					relProducts: newRelProducts
				});
			})
			.catch(logError);

		return cancel;
	}, []);

	useEffect(() => {
		const onOrderEvent = (event: any, updatedOrder: Order) => {
			if (projectPlan.invoiceOrders.some(({ orderId }) => orderId === updatedOrder.id)) {
				if (event.name.split('.')[1] === 'deleted') {
					const newInvoiceOrders = projectPlan.invoiceOrders.filter(
						({ orderId }) => orderId !== updatedOrder.id
					);
					setProjectPlan({ ...projectPlan, invoiceOrders: newInvoiceOrders });
				} else {
					const newInvoiceOrders = projectPlan.invoiceOrders.map(invoiceOrder => {
						if (invoiceOrder.orderId === updatedOrder.id) {
							return { ...invoiceOrder, order: updatedOrder };
						}
						return invoiceOrder;
					});
					setProjectPlan({ ...projectPlan, invoiceOrders: newInvoiceOrders });
				}
			}
		};

		const listeners = ['order.updated', 'order.deleted', 'opportunity.updated', 'opportunity.deleted'].map(
			eventName => Tools.$rootScope.$on(eventName, onOrderEvent)
		);

		return () => {
			listeners.forEach(listener => listener());
		};
	}, [projectPlan.invoiceOrders.length]);

	const onAddProduct = (invoiceId: number) => {
		setProjectPlan({ ...projectPlan, relProducts: [...projectPlan.relProducts, createNewProduct(invoiceId)] });
	};

	const onSetProduct = (newProduct: Product, index: number, invoiceProducts: InvoiceProducts, invoiceId: number) => {
		const newInvoiceProducts = invoiceProducts.map((relProduct, i) => {
			if (i === index) {
				return { ...relProduct, product: newProduct };
			}
			return relProduct;
		});
		const otherProducts = projectPlan.relProducts.filter(relProduct => relProduct.invoiceId !== invoiceId);
		onProjectPlanChange({ ...projectPlan, relProducts: [...otherProducts, ...newInvoiceProducts] }, true);
	};

	const onSetQuantity = (
		quantity: number | undefined,
		index: number,
		invoiceProducts: InvoiceProducts,
		invoiceId: number
	) => {
		let hasSetProduct = false;
		const newInvoiceProducts = invoiceProducts.map((relProduct, i) => {
			if (i === index) {
				hasSetProduct = !!relProduct.product.id;
				return { ...relProduct, quantity: quantity ?? 1 };
			}
			return relProduct;
		});
		const otherProducts = projectPlan.relProducts.filter(relProduct => relProduct.invoiceId !== invoiceId);
		const newProducts = [...otherProducts, ...newInvoiceProducts];

		if (hasSetProduct) {
			onProjectPlanChange({ ...projectPlan, relProducts: newProducts }, true);
		} else {
			setProjectPlan({ ...projectPlan, relProducts: newProducts });
		}
	};

	const onRemoveProduct = (index: number, invoiceProducts: InvoiceProducts, invoiceId: number) => {
		const newInvoiceProducts = invoiceProducts.filter((_, i) => i !== index);
		const otherProducts = projectPlan.relProducts.filter(relProduct => relProduct.invoiceId !== invoiceId);
		const newProducts = [...otherProducts, ...newInvoiceProducts];

		if (invoiceProducts[index].product.id) {
			onProjectPlanChange({ ...projectPlan, relProducts: newProducts }, true);
		} else {
			setProjectPlan({ ...projectPlan, relProducts: newProducts });
		}
	};

	const onRemoveOrder = (deletedId: number) => {
		OrderResource.delete(deletedId);
		const newInvoiceOrders = projectPlan.invoiceOrders.filter(({ orderId }) => orderId !== deletedId);
		setProjectPlan({ ...projectPlan, invoiceOrders: newInvoiceOrders });
	};

	const onOrderSave = (order: Order, invoiceId: number) => {
		ProjectPlanInvoiceResource.delete(invoiceId, { skipNotification: true });
		const newInvoices = projectPlan.invoices.filter(invoice => invoice.id !== invoiceId);
		const newProducts = projectPlan.relProducts.filter(relProduct => relProduct.invoiceId !== invoiceId);
		onProjectPlanChange(
			{
				...projectPlan,
				invoices: newInvoices,
				relProducts: newProducts,
				invoiceOrders: [...projectPlan.invoiceOrders, { orderId: order.id, order }]
			},
			true
		);
	};

	const onCreateInvoice = async () => {
		const { data: savedInvoice } = await ProjectPlanInvoiceResource.save({
			name: invoiceName,
			projectPlanId: projectPlan.id
		});

		setProjectPlan({
			...projectPlan,
			invoices: [savedInvoice, ...projectPlan.invoices],
			relProducts: [...projectPlan.relProducts, createNewProduct(savedInvoice.id)]
		});

		setInvoiceName('');
		setIsCreatingInvoice(false);
	};

	const onEditInvoice = async (invoiceId: number) => {
		const { data: savedInvoice } = await ProjectPlanInvoiceResource.save({
			name: invoiceName,
			projectPlanId: projectPlan.id,
			id: invoiceId
		});

		const newInvoices = projectPlan.invoices.map(invoice => {
			if (invoice.id === invoiceId) {
				return savedInvoice;
			}
			return invoice;
		});

		setProjectPlan({
			...projectPlan,
			invoices: newInvoices
		});

		setInvoiceName('');
		setEditingInvoiceName(0);
	};

	return (
		<Block className={classes.b()} space="mrl pll ptxl prxl pbl">
			<Flex direction="row" justifyContent="space-between" alignItems="center">
				<Flex direction="column" gap="u1">
					<Title>{t('editProjectPlan.invoice.invoices')}</Title>
					<Text color="grey-11">{t('editProjectPlan.invoice.invoices.toolTip')}</Text>
				</Flex>
				{!isCreatingInvoice ? (
					<PrimaryButton
						icon="plus"
						text={t('editProjectPlan.invoice.createInvoice')}
						onClick={() => {
							setIsCreatingInvoice(true);
							setEditingInvoiceName(0);
							setInvoiceName('');
						}}
					/>
				) : (
					<Text
						className={classes.elem('clickable').b()}
						onClick={() => {
							setIsCreatingInvoice(false);
							setInvoiceName('');
						}}
					>
						{t('default.cancel')}
					</Text>
				)}
			</Flex>
			{isCreatingInvoice ? (
				<Block space="mtxl mbxl">
					<Label
						maxLength={128}
						value={invoiceName}
						maxLengthReachedText={t('default.characterLimitReached')}
						className={classes.elem('invoice-input').b()}
					>
						{t('editProjectPlan.invoice.invoiceName')}
					</Label>
					<Flex gap="u1">
						<Input
							className={classes.elem('invoice-input').b()}
							placeholder={t('default.enterName')}
							maxLength={128}
							value={invoiceName}
							onChange={e => setInvoiceName(e.target.value)}
							onKeyPress={e => {
								if (e.key === 'Enter') {
									onCreateInvoice();
								}
							}}
							autofocus
						/>
						<Button text={t('default.create')} onClick={onCreateInvoice} disabled={!invoiceName.trim()} />
					</Flex>
				</Block>
			) : null}
			{projectPlan.invoices.map(invoice => {
				const relProducts = projectPlan.relProducts.filter(p => p.invoiceId === invoice.id);
				return (
					<Block key={invoice.id} className={classes.elem('invoice-wrapper').b()} space="mtxl">
						<Flex direction="row" justifyContent="space-between" alignItems="center">
							{editingInvoiceName !== invoice.id ? (
								<Flex gap="u4" alignItems="center" space="mrxl">
									<Title bold>{invoice.name}</Title>
									<Tooltip title={t('default.editName')}>
										<Icon
											name="edit"
											color="grey-8"
											className={classes.elem('clickable').b()}
											onClick={() => {
												setEditingInvoiceName(invoice.id);
												setInvoiceName(invoice.name);
												setIsCreatingInvoice(false);
											}}
										/>
									</Tooltip>
								</Flex>
							) : (
								<Flex gap="u2" alignItems="center" flex={1} space="mrl">
									<Input
										className={classes.elem('edit-invoice-input').b()}
										placeholder={t('default.enterName')}
										value={invoiceName}
										onChange={e => setInvoiceName(e.target.value)}
										autofocus
										maxLength={128}
										onKeyPress={e => {
											if (e.key === 'Enter') {
												onEditInvoice(invoice.id);
											}
										}}
									/>
									<Button
										icon="check"
										color="green"
										className={classes.elem('icon-button').b()}
										onClick={() => onEditInvoice(invoice.id)}
										disabled={!invoiceName.trim()}
									/>
									<Button
										icon="times"
										color="white"
										shadow="none"
										className={classes.elem('icon-button').b()}
										onClick={() => {
											setEditingInvoiceName(0);
											setInvoiceName('');
										}}
									/>
								</Flex>
							)}
							<Flex gap="u4" alignItems="center">
								<DefaultButton
									icon="plus"
									text={t('admin.products.addProduct')}
									onClick={() => onAddProduct(invoice.id)}
								/>
								<InlineConfirm
									key={invoice.id}
									show
									onConfirm={() => {
										ProjectPlanInvoiceResource.delete(invoice.id);
										setProjectPlan({
											...projectPlan,
											invoices: projectPlan.invoices.filter(i => i.id !== invoice.id)
										});
									}}
									entity="editProjectPlan.invoice"
									keepTabPosition
								>
									<Icon className={classes.elem('clickable').b()} name="trash" />
								</InlineConfirm>
							</Flex>
						</Flex>
						{!relProducts.length ? (
							<Block space="mtl mbl">
								<Text color="grey-11" italic>
									{t('editProjectPlan.invoice.noProducts')}
								</Text>
							</Block>
						) : (
							<Block className={classes.elem('table-wrapper').b()} space="mtl mbl">
								<Table>
									<TableHeader
										columns={[
											{ title: t('default.product') },
											{ title: t('default.quantity') },
											{ title: '' }
										]}
									/>
									{relProducts.map((relProduct, index) => {
										let tierObject = null;
										if (relProduct.product.tiers?.length) {
											tierObject = getTierFromQuantity(
												relProduct.product.tiers,
												relProduct.quantity
											);
										}
										return (
											<TableRow key={index}>
												<TableColumn className={classes.elem('product-column').b()}>
													<ProductCategorySelect
														className={classes.elem('product-select').b()}
														tierObject={tierObject}
														orderRow={{ product: relProduct.product } as any}
														useDiscount={false}
														productTotal={productTotal}
														virtualized={productTotal <= 4000}
														setProduct={product =>
															onSetProduct(product, index, relProducts, invoice.id)
														}
														anchor={'.EditProjectPlan'}
													/>
												</TableColumn>
												<TableColumn className={classes.elem('quantity-column').b()}>
													<NumberInput
														value={relProduct.quantity}
														min={0}
														align="center"
														onChange={quantity =>
															onSetQuantity(quantity, index, relProducts, invoice.id)
														}
													/>
												</TableColumn>
												<TableColumn align="right">
													<Icon
														className={classes.elem('remove-row').b()}
														name="times"
														onClick={() => onRemoveProduct(index, relProducts, invoice.id)}
													/>
												</TableColumn>
											</TableRow>
										);
									})}
								</Table>
							</Block>
						)}
						<Flex justifyContent="end">
							<PrimaryButton
								icon="sales"
								text={t('default.createOrder')}
								onClick={() => {
									Tools.$upModal.open('editOrder', {
										customerId: self?.id,
										clientId: projectPlan.client.id,
										contactId: projectPlan.contact?.id,
										type: 'order',
										description: invoice.name,
										products: relProducts
											.filter(relProduct => relProduct.product.id)
											.map(relProduct => ({
												id: relProduct.product.id,
												quantity: relProduct.quantity
											})),
										afterSave: (order: Order) => onOrderSave(order, invoice.id)
									});
								}}
							/>
						</Flex>
					</Block>
				);
			})}
			<Block space="mtxl mbl">
				<Title>{t('editProjectPlan.invoice.createdOrders')}</Title>
			</Block>
			<Block className={classes.elem('table-wrapper').mod({ inlineVisible }).b()} space="mtl mbl">
				<Table>
					<TableHeader
						columns={[
							{ title: t('default.description') },
							{ title: t('default.value') },
							{ title: t('default.wasClosed') },
							{ title: '' }
						]}
					/>
					{projectPlan.invoiceOrders.length ? (
						projectPlan.invoiceOrders.map(({ order }) => (
							<TableRow
								key={order.id}
								onClick={() => Tools.$upModal.open('editOrder', { type: order, id: order.id })}
							>
								<TableColumn className={classes.elem('order-table-name').b()}>
									<EllipsisTooltip title={order.description}>
										<Text size="sm" ellipsis>
											{order.description}
										</Text>
									</EllipsisTooltip>
								</TableColumn>
								<TableColumn
									title={order.value != null ? currencyFormat(order.value, order.currency!) : ''}
								/>
								<TableColumn
									title={moment(order.closeDate).format('YYYY-MM-DD')}
									subtitle={order.user?.name}
								/>
								<TableColumn align="right">
									<InlineConfirm
										show
										onConfirm={() => onRemoveOrder(order.id!)}
										entity="default.order"
										onVisibleChange={setInlineVisible}
										keepTabPosition
									>
										<Icon
											className={classes.elem('remove-row').b()}
											space="ptm prl pbm pll"
											name="trash"
										/>
									</InlineConfirm>
								</TableColumn>
							</TableRow>
						))
					) : (
						<TableRow className={classes.elem('empty-table').b()}>
							<TableColumn colSpan={4}>
								<Flex alignItems="center" justifyContent="center">
									<Text color="grey-10" italic>
										{t('salesboard.noSales')}
									</Text>
								</Flex>
							</TableColumn>
						</TableRow>
					)}
				</Table>
			</Block>
		</Block>
	);
};

function createNewProduct(invoiceId: number) {
	return {
		quantity: 1,
		invoiceId,
		product: {
			id: 0
		} as Product
	};
}

export default ProjectInvoice;
