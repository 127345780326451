import Appointment from 'App/resources/Model/Appointment';
import AppointmentEvents from 'App/resources/AppointmentEvents';
import AppointmentResource from 'Resources/Appointment';
import CommentResource from 'Resources/Comment';
import DateChange from '../AppointmentController/dateAndTimeFunctions';
import React, { useMemo } from 'react';
import ValidationService from 'Services/ValidationService';
import _ from 'lodash';
import buildTitle from '../AppointmentController/buildTitle';
import createCampaign from '../AppointmentController/createCampaign';
import createContact from '../AppointmentController/createContact';
import createOpportunity from '../AppointmentController/createOpportunity';
import getAccount from '../AppointmentController/getAccount';
import getOpportunity from '../AppointmentController/getOpportunity';
import logError from 'App/babel/helpers/logError';
import moment from 'moment';
import openModal from 'App/services/Modal';
import participantSelect from '../AppointmentController/participantSelect';
import { EVENT_TYPES } from 'App/babel/enum/appointmentEvents';
import { appointmentOutcomeTracker } from 'App/babel/helpers/Tracker';
import { comparisonTypes } from 'Resources/RequestBuilder';
import { generateDeferredPromise } from 'App/babel/helpers/promise';
import { openEditAppointment } from 'Components/Modals/Appointment/EditAppointment';

const setInviteOnContactsAndEmailAttendees = (
	appointment: any,
	invitedContacts: any,
	invitedEmailAttendees: any,
	shouldInvite: any
) => {
	if (appointment?.contacts?.length) {
		appointment.contacts.forEach((contact: any) => {
			if (shouldInvite) {
				contact.isInvited = 1;
			} else {
				contact.isInvited = Number(invitedContacts[contact.id] ?? 0);
			}
		});
	}
	if (appointment?.emailAttendees?.length) {
		appointment.emailAttendees.forEach((attendee: any) => {
			if (shouldInvite) {
				attendee.isInvited = 1;
			} else {
				attendee.isInvited = Number(invitedEmailAttendees[attendee.email] ?? 0);
			}
		});
	}
};

const selectOption = (value: any, label: any, data: any, render: any, disabled = false) => {
	return { value, label, data, render, disabled };
};

const filterMyOpportunities = (data: any, users: any) => {
	const list = _.filter(data, (item: any) => {
		return !!_.find(users, { id: item.user.id });
	});

	return list;
};

const changeDate = (store: any, value: any, options = {}) => {
	try {
		const { appointment, currentAppointmentLength } = store.pluck('appointment', 'currentAppointmentLength');
		const dateProps = DateChange(
			{
				date: appointment.date,
				endDate: appointment.endDate,
				currentAppointmentLength: currentAppointmentLength
			},
			value,
			options
		);

		appointment.date = dateProps.date;
		appointment.endDate = dateProps.endDate;

		store.setStore({
			appointment,
			currentAppointmentLength: dateProps.currentAppointmentLength,
			dateAvailability: dateProps.date,
			isDirty: true
		});
	} catch (error: any) {
		console.warn(error.message);
	}
};

export const validateFields = (requiredFields: any, appointment: any, extra: any) => {
	let valid = true;
	const errors: any = {
		Notes: false,
		Project: false,
		Description: false,
		Startdate: false,
		Enddate: false,
		Type: false
	};
	let faultyField: any;

	// Validate customfields
	const fields = Tools.AppService.getCustomFields('appointment');
	fields.forEach(f => {
		if (f.obligatoryField && f.editable && f.visible && f.$hasAccess) {
			// Find value
			const fieldVal: any = _.find(appointment.custom, { fieldId: f.id });
			if (!fieldVal || !fieldVal.value || !fieldVal.value.toString().length) {
				// Invalid
				valid = false;
				faultyField = f.name;
				errors['Custom_' + f.id] = true;
			}
		}
	});

	// Validate standardfields
	if (requiredFields.Notes && (!appointment.notes || !appointment.notes.length)) {
		valid = false;
		faultyField = 'default.notes';
		errors.Notes = true;
	}
	if (requiredFields.Description && (!appointment.description || !appointment.description.length)) {
		valid = false;
		faultyField = 'default.description';
		errors.Description = true;
	}
	if (requiredFields.Project && !appointment.project) {
		valid = false;
		faultyField = 'default.project';
		errors.Project = true;
	}
	if (!appointment.activityType) {
		valid = false;
		faultyField = 'default.activityType';
		errors.Type = true;
	}

	if (extra.timeDirty && extra.timeDirty.isInvalid) {
		valid = false;

		if (extra.timeDirty.storeKey === 'date') {
			faultyField = 'default.startDate';
			errors.Startdate = true;
		}

		if (extra.timeDirty.storeKey === 'endDate') {
			faultyField = 'default.endDate';
			errors.Enddate = true;
		}
	}

	if (extra.dateDirty && extra.dateDirty.isInvalid) {
		valid = false;

		if (extra.dateDirty.storeKey === 'date') {
			faultyField = 'default.startDate';
			errors.Startdate = true;
		}

		if (extra.dateDirty.storeKey === 'endDate') {
			faultyField = 'default.endDate';
			errors.Enddate = true;
		}
	}

	if (!valid) {
		Tools.NotificationService.addNotification({
			style: Tools.NotificationService.style.WARN,
			icon: 'warning',
			title: 'default.error',
			body: `${Tools.$translate('default.youHaveFormErrorsMissing')} ${Tools.$translate(
				faultyField
			).toLowerCase()}`
		});
	}

	return { valid, errors };
};

const saveAppointment = async (store: any, followup: any, inviteContacts?: any) => {
	store.setStore({ saving: true, fieldErrors: {} });
	const { waitForPromises = {} } = store.pluck('waitForPromises');
	await Promise.all(Object.values(waitForPromises).map((p: any) => p.promise));

	const {
		appointment,
		requiredFields,
		timeDirty,
		dateDirty,
		reschedule: isRescheduling,
		invitedContacts,
		invitedEmailAttendees
	} = store.pluck(
		'appointment',
		'requiredFields',
		'timeDirty',
		'dateDirty',
		'reschedule',
		'invitedContacts',
		'invitedEmailAttendees'
	);

	// check required Fields
	const fieldValidation = validateFields(requiredFields, appointment, { timeDirty, dateDirty });

	if (!fieldValidation.valid) {
		store.setStore({
			saving: false,
			fieldErrors: fieldValidation.errors,
			savingType: ''
		});
		return Promise.reject();
	}

	return new Promise(resolve => {
		Tools.ScriptService.appointment
			.save(appointment)
			.then(() => {
				appointment.description = appointment.description || store.get('defaultDesc');
				setInviteOnContactsAndEmailAttendees(
					appointment,
					invitedContacts,
					invitedEmailAttendees,
					inviteContacts
				);
				AppointmentResource.save(appointment, { params: { saveOutcomeAction: isRescheduling } })
					.then(response => {
						const { isDirty, options } = store.pluck('isDirty', 'options');

						if (!isDirty) {
							resolve({ appointment: response.data, shouldResolve: true });
						}

						const opportunitiesByUser = filterMyOpportunities(options.opportunities, appointment.users);

						if (
							!appointment.id &&
							!appointment.opportunity &&
							opportunitiesByUser.length > 0 &&
							!followup
						) {
							store.setStore({
								appointment: response.data,
								isDirty: false,
								created: true
							});

							store.set('saving', false);
							store.set('savingType', '');
							resolve({ appointment: response.data, shouldResolve: false });
						} else {
							store.set('isDirty', false);
							resolve({ appointment: response.data, shouldResolve: true });
						}
					})
					.catch(() => {
						store.set('saving', false);
						store.set('savingType', '');
					});
			})
			.catch(() => {
				store.set('saving', false);
				store.set('savingType', '');
			});
	});
};

const saveAppointmentNotes = async (store: any, notes: any) => {
	const appointment = store.get('appointment');
	store.setStore({ appointment: { ...appointment, notes } });
	const data = { id: appointment.id, notes };
	await AppointmentResource.save(data, { skipNotification: true, skipEvent: true });
};

export const resetCompanyScore = (clientId: any, customerId: any) => {
	Tools.ResetScore.customer(customerId).reset({
		clientId,
		userId: Tools.AppService.getSelf().id,
		customerId,
		synch: false
	});
};

const trackOutcome = (outcome: any) => {
	appointmentOutcomeTracker.track(appointmentOutcomeTracker.events.SET_OUTCOME, {
		location: 'modal',
		outcome
	});
};

// These actions should be a 1 = 1 copy of those in ui/app/babel/components/Modals/Appointment/AppointmentCtrl.js
const useActions = (
	store: any,
	resolve: (result?: Appointment) => void,
	reject: (error?: unknown) => void,
	calculateHeight: () => void
) => {
	const hasSubaccounts = Tools.FeatureHelper.hasSoftDeployAccess('SUB_ACCOUNTS');
	const utils = Tools.utils;

	// Wrap it in a useMemo as this object is kinda laaarge
	const actions = useMemo(
		() => ({
			// reloadModalPosition is still needed with the MutationObserver due to the fact that both the fields and files tab are always in the DOM, one just has a display: none
			reloadModalPosition: () => calculateHeight(),
			dateTimeDirty: () => {
				/*
				If someone tries to set a dirty time what will happen is just that you will get the old one
				so no need to update dirty state here
			*/
			},
			closeCalendar: () => {
				store.set('calendarIsOpenOnFirstLoad', false);
			},
			close: () => {
				if (store.get('isDirty')) {
					openModal('UnsavedChangesAlert', {
						onClose: async confirmed => {
							if (confirmed === undefined) {
								return;
							}
							if (confirmed) {
								await saveAppointment(store, true, undefined);
							}
							reject();
						}
					});
				} else {
					reject();
				}
			},
			discardFromInline: () => {
				reject();
			},
			hideOutcome: () => {
				store.set('hideOutcome', true);
			},
			setJourneyStep: (entity: any, object: any, journeyStep: any) => {
				const { customerId } = store.pluck('appointment', 'customerId');
				const Resource: any = { contact: Tools.Contact, client: Tools.Account };

				return Resource[entity]
					.customer(customerId)
					.save({
						id: object.id,
						journeyStep: journeyStep
					})
					.then((res: any) => {
						if (entity === 'contact') {
							const currentContacts = store.get('appointment.contacts');
							const contactIndex = _.findIndex(currentContacts, { id: object.id });

							object.journeyStep = res.data.journeyStep;
							currentContacts.splice(contactIndex, 1, object);

							store.set('appointment.contacts', currentContacts);
						} else {
							object.journeyStep = res.data.journeyStep;
							store.set('account', object);
						}
					});
			},
			qualifyCompany: () => {
				const { appointment, customerId } = store.pluck('appointment', 'customerId');

				store.set('saving', true);

				Tools.Account.customer(customerId)
					.save(
						{
							id: appointment.client.id,
							journeyStep: 'sql'
						},
						{
							skipNotification: true
						}
					)
					.then(function () {
						appointment.client.journeyStep = 'sql';
						store.setStore({ appointment, saving: false });
					})
					.catch(err => {
						logError(err, 'Error saving account');
					});
			},
			disqualifyCompany: () => {
				const { appointment, customerId } = store.pluck('appointment', 'customerId');

				store.set('saving', true);
				Tools.Account.customer(customerId)
					.save(
						{
							id: appointment.client.id,
							journeyStep: 'disqualified'
						},
						{
							skipNotification: true
						}
					)
					.then(function () {
						appointment.client.journeyStep = 'disqualified';
						store.setStore({ appointment, saving: false });
						resetCompanyScore(appointment.client.id, customerId);
					})
					.catch(err => {
						logError(err, 'Error saving account');
					});
			},
			answerOutcome: (isCompleted: any) => {
				const { appointment, customerId } = store.pluck('appointment', 'customerId');

				appointment.outcome = isCompleted ? 'completed' : 'notCompleted';
				store.setStore({ appointment, outcomeAnswered: true });

				if (appointment.id) {
					if (!Tools.FeatureHelper.hasSoftDeployAccess('NEW_APPOINTMENT_OUTCOME_EVENTS')) {
						store.set('saving', true);
						Tools.Appointment.customer(customerId)
							.save({ id: appointment.id, outcome: appointment.outcome }, { skipNotification: true })
							.then(() => {
								store.set('saving', false);
								trackOutcome(appointment.outcome);
							})
							.catch(err => {
								logError(err, 'Error saving appointment');
							});
					}
				}
			},
			resolve,
			firstBackSpace: (bool: any) => {
				const participants = store.get('participants');
				const lastParticipant = participants[participants.length - 1];
				if (lastParticipant) {
					lastParticipant.isInRemoveState = true;
					const updatedStore = { firstBackSpace: bool, participants };
					store.setStore(updatedStore);
				}
			},
			setContactOpened: (value: any) => store.set('contactOpened', value),
			link: (url: any) => {
				if (store.get('isDirty')) {
					if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
						openModal('UnsavedChangesAlert', {
							onClose: async confirmed => {
								if (confirmed === undefined) {
									return;
								}
								if (confirmed) {
									await saveAppointment(store, true, undefined);
								}
								window.location.href = url;
							}
						});
						return;
					}

					// eslint-disable-next-line promise/catch-or-return
					Tools.$upModal
						.open('warningConfirm', {
							title: 'cancel',
							body: 'confirm.abortEdit',
							resolveTrue: 'default.abortEdit',
							no: 'default.returnToEdit',
							icon: 'fa-warning'
						})
						.then(function () {
							window.location.href = url;
						});
				} else {
					window.location.href = url;
				}
			},
			openContact: (event: any, url: any) => {
				event.preventDefault();
				if (store.get('isDirty')) {
					Tools.$upModal
						.open('infoConfirm', {
							title: 'default.saveChanges',
							body: 'confirm.changesWillBeLost',
							icon: 'fa-exclamation-triangle',
							resolveFalse: 'default.save',
							resolveFalseBtnClass: 'btn-bright-blue',
							resolveTrue: 'default.discardChanges',
							resolveTrueBtnClass: 'btn-bright-blue btn-lined'
						})
						.then(function (skipSave) {
							if (skipSave === false) {
								saveAppointment(store, true, undefined);
								window.location.href = url;
							} else {
								window.location.href = url;
							}
						})
						.catch(error => {
							console.warn(error);
						});
				} else {
					window.location.href = url;
				}
			},
			checkAgainstFieldErrors: (key: any, value: any, field?: any) => {
				const fieldErrors = store.get('fieldErrors');

				let fieldErrorKey = key[0].toUpperCase() + key.substring(1);
				if (key === 'custom') {
					fieldErrorKey = 'Custom_' + field.id;
				}

				if (fieldErrors[fieldErrorKey] === true && !!value) {
					fieldErrors[fieldErrorKey] = false;
				}

				store.set('fieldErrors', fieldErrors);
			},
			addWaitForPromise: (key: any) => {
				const waitForPromises = store.get('waitForPromises') || {};
				waitForPromises[key] = generateDeferredPromise();
				store.set('waitForPromises', waitForPromises);
			},
			changeAppointmentParam: (key: any, value: any, isDirty: any) => {
				const appointment = store.get('appointment');

				if (key === 'agenda') {
					appointment.isAgendaDirty = isDirty;
				} else if (key === 'activityType') {
					actions.checkAgainstFieldErrors('type', value);
				}
				if (appointment[key] !== value) {
					appointment[key] = value;
					// Don't change dirty state if the edited field is "notes" - which is autosaved
					store.setStore({
						appointment,
						...(key === 'notes' && appointment?.id > 0 ? {} : { isDirty: true })
					});
				}

				const waitForPromises = store.get('waitForPromises') || {};
				if (waitForPromises[key]) {
					waitForPromises[key]?.resolve?.();
				}
			},
			participantChange: (value: any) => {
				const appointment = store.get('appointment');

				const newState: any = {
					isDirty: true,
					participants: value.participants,
					appointment: appointment
				};

				if ((!appointment.contacts || !appointment.contacts.length) && value.contacts.length) {
					newState.contactOpened = value.contacts[0].id;
				}
				appointment.contacts = value.contacts;
				appointment.users = value.users;

				store.setStore(newState);
			},
			addEmailAttendee: (email: any) => {
				const appointment = store.get('appointment');
				if (!appointment.client?.id || !email || !ValidationService.validEmail(email)) {
					console.warn('No client or valid email, not adding email attendee');
					return;
				}
				if (appointment.emailAttendees?.find((attendee: any) => attendee.email === email)) {
					return;
				}

				const newState = {
					isDirty: true,
					appointment: appointment
				};

				appointment.emailAttendees = [...(appointment.emailAttendees ?? []), { email, isInvited: 0 }];
				store.setStore(newState);
			},
			removeEmailAttendee: (email: any) => {
				const appointment = store.get('appointment');
				appointment.emailAttendees = appointment.emailAttendees.filter(
					(attendee: any) => attendee.email !== email
				);
				const newState = {
					isDirty: true,
					appointment: appointment
				};
				store.setStore(newState);
			},
			clearEmailAttendees: () => {
				const appointment = store.get('appointment');
				appointment.emailAttendees = [];
				const newState = {
					isDirty: true,
					appointment: appointment
				};
				store.setStore(newState);
			},
			setIsRescheduling: (reschedule: any) => {
				store.set('reschedule', reschedule);
			},
			isRescheduling: () => {
				if (!store) {
					return false;
				}
				return store.get('reschedule');
			},
			changeCustom: (field: any, value: any, skipSetDirty: any) => {
				const appointment = store.get('appointment');

				actions.checkAgainstFieldErrors('custom', value, field);
				appointment.custom = _.map(appointment.custom, (item: any) => {
					if (item.id === field.id) {
						item.value = value;
					}

					return item;
				});

				if (skipSetDirty) {
					return store.setStore({ appointment });
				}

				store.setStore({
					appointment,
					isDirty: true
				});
			},
			changeDayInAvailabilityTable: (date: any) => {
				store.setStore({
					dateAvailability: moment(date).toDate(),
					isDirty: true
				});
			},
			changeStartDate: (date: any, opts: any) => {
				changeDate(store, date, opts);
				store.set('calendarIsOpenOnFirstLoad', false);
			},
			changeStartTime: (date: any) => {
				changeDate(store, moment(date, 'L LT'), { setTime: true });
			},
			changeEndDate: (date: any) => {
				changeDate(store, date, { setEnd: true });
			},
			changeEndTime: (date: any) => {
				changeDate(store, moment(date, 'L LT'), { setEnd: true, setTime: true });
			},
			titleBuilder: (injectedStore: any) => {
				if (injectedStore) {
					injectedStore.title = buildTitle({ store: injectedStore, Tools });
					return injectedStore.title;
				}

				store.set('title', buildTitle({ store: null, Tools }).join(' '));
			},
			extendOpportunity: (value: any) => {
				getOpportunity({ opportunity: value })
					.then(OPPORTUNITY_DATA => {
						const appointment = store.get('appointment');
						appointment.opportunity = { ...appointment.opportunity, ...OPPORTUNITY_DATA };
						store.setStore({ appointment });
					})
					.catch(err => {
						logError(err, 'Error getting opportunity');
					});
			},
			accountChange: (value: any, init: any) => {
				if (!value) {
					const { appointment, options, participantSelect } = store.pluck(
						'appointment',
						'options',
						'participantSelect'
					);

					let participants = store.get('participants');

					appointment.contacts = [];
					appointment.emailAttendees = [];
					appointment.client = null;

					participantSelect.data[0].children = [];
					participantSelect.data[2].children = [];
					participants = participants.filter((p: any) => {
						return p.hasOwnProperty('role');
					});

					options.contacts = [];
					store.setStore({
						account: null,
						appointment,
						participantSelect,
						contacts: [],
						options,
						participants
					});
				} else {
					const appointment = store.get('appointment');
					const previousAccount = store.get('account');

					if (!init) {
						appointment.contacts = [];
						appointment.emailAttendees = [];
					}
					appointment.client = value;
					store.setStore({
						account: value,
						appointment
					});

					if (previousAccount && previousAccount.hasOwnProperty('id') && previousAccount.id !== value.id) {
						// New account selected. Filter out non user participants
						const participants = store.get('participants');
						store.setStore({ participants: _.filter(participants, (p: any) => p.hasOwnProperty('role')) });
					}

					getAccount({ client: value })
						.then(ACCOUNT_DATA => {
							const appointment = store.get('appointment');
							if (!init) {
								appointment.contacts = [];
							}
							appointment.client = ACCOUNT_DATA;
							store.setStore({
								account: ACCOUNT_DATA,
								appointment
							});

							actions
								.getOpportunities(ACCOUNT_DATA)
								.then((opportunities: any) => {
									const { appointment, options } = store.pluck('appointment', 'options');
									options.opportunities = opportunities;

									/* Dont do this on initial load of an edit */
									if (!(appointment.id && init)) {
										if (appointment.opportunity && appointment.opportunity.id) {
											appointment.opportunity =
												_.find(opportunities, {
													id: appointment.opportunity.id
												}) || appointment.opportunity;
										} else if (opportunities && opportunities.length === 1) {
											appointment.opportunity = opportunities[0];
											store.set('isDirty', true);
										}
									}

									store.setStore({
										appointment,
										options
									});
								})
								.catch(err => {
									logError(err, 'Error getting opportunities');
								});
						})
						.catch(err => {
							logError(err, 'Error getting account');
						});

					const operationalAccountId = hasSubaccounts ? value.operationalAccount?.id : undefined;
					participantSelect(value.id, store.get('users'), utils, operationalAccountId)
						.then(response => {
							const existingOpts = store.get('options');
							existingOpts.contacts = actions.makeOptions(response.contacts);

							const contacts = response.object.data[0].children;
							response.object.data[0].children = contacts.reduce((res: any, contact: any) => {
								if (!res.find((c: any) => c.id === contact.id)) {
									res.push(contact);
								}
								return res;
							}, []);

							store.setStore({
								participantSelect: response.object,
								contacts: response.contacts,
								loadingContacts: false,
								options: existingOpts
							});
						})
						.catch(err => {
							logError(err, 'Error selecting participant');
						});
				}
			},
			makeOptions: (type: any, array?: any) => {
				if (Array.isArray(type)) {
					return actions.transformOptions(array);
				}

				store.set(`options.${type}`, actions.transformOptions(array));
			},
			transformOptions: (options: any) => {
				return _.reduce(
					options,
					(res: any, el: any) => {
						const parent = selectOption(el.name, el.name, el, <strong>{el.name}</strong>, true);
						const children = _.map(el.children, (child: any) => {
							return _.assign(
								{},
								selectOption(
									child.id,
									child.name,
									child,
									<span style={{ paddingLeft: 10 }}>{child.name}</span>
								),
								{ parent: el.name }
							);
						});

						// remove children that is already selected
						const filteredChildren = _.filter(children, (child: any) => {
							return !_.find(store.get('participants'), (item: any) => item.name === child.label);
						});

						return res.concat(parent).concat(filteredChildren);
					},
					[]
				);
			},
			updateAppointmentLength: (returnInstead: any, init: any) => {
				const appointment = store.get('appointment');
				let currentAppointmentLength = moment(appointment.endDate).diff(appointment.date, 'minutes');

				if (currentAppointmentLength === 0) {
					currentAppointmentLength = 15;
				}

				if (returnInstead) {
					return currentAppointmentLength;
				}

				store.setStore({
					currentAppointmentLength,
					isDirty: !init
				});
			},
			createCampaign: () => {
				const { customerId } = store.pluck('appointment', 'customerId');
				createCampaign(customerId)
					.then(newCampaign => {
						const appointment = store.get('appointment');
						appointment.project = newCampaign;
						store.setStore({ appointment, isDirty: true });
					})
					.catch(err => {
						logError(err, 'Error creating campaign');
					});
			},
			createOpportunity: (isOrder: any = false, comment: any, skipTimelineRow: any) => {
				const { appointment, customerId, invitedContacts, invitedEmailAttendees } = store.pluck(
					'appointment',
					'customerId',
					'invitedContacts',
					'invitedEmailAttendees'
				);
				return new Promise<void>((resolve, reject) => {
					return createOpportunity(customerId, appointment, isOrder)
						.then(async newOpportunity => {
							const appointment = store.get('appointment');
							appointment.opportunity = newOpportunity;
							store.setStore({ appointment, isDirty: true });

							if (appointment.id) {
								const hasNewEvents =
									Tools.FeatureHelper.hasSoftDeployAccess('NEW_APPOINTMENT_OUTCOME_EVENTS') &&
									Tools.FeatureHelper.hasSoftDeployAccess('TODO_LIST');
								store.set('saving', true);
								let saveFunc;
								if (!skipTimelineRow) {
									saveFunc = actions.saveAppointmentWithAction;
								} else {
									saveFunc = (data: any) => {
										setInviteOnContactsAndEmailAttendees(
											data,
											invitedContacts,
											invitedEmailAttendees,
											false
										);
										return AppointmentResource.save(data);
									};
								}
								let outcomeCommentId;
								if (comment) {
									try {
										({ id: outcomeCommentId } = await actions.saveComment(comment));
									} catch (err) {
										logError(err, 'Could not save comment');
									}
								}
								saveFunc(
									hasNewEvents
										? {
												id: appointment.id,
												outcome: appointment.outcome,
												outcomeAction: isOrder
													? EVENT_TYPES.ORDERCREATED
													: EVENT_TYPES.OPPORTUNITYCREATED,
												outcomeExtra: JSON.stringify({
													id: newOpportunity.id,
													description: newOpportunity.description
												}),
												opportunity: appointment.opportunity,
												...(outcomeCommentId ? { outcomeCommentId } : {})
										  }
										: {
												...appointment,
												outcomeAction: isOrder
													? EVENT_TYPES.ORDERCREATED
													: EVENT_TYPES.OPPORTUNITYCREATED
										  }
								)
									.then(() => {
										store.set('saving', false);
										resolve();
									})
									.catch(err => {
										logError(err, 'Error saving appointment');
										reject();
									});
							}
						})
						.catch(createOpportunityError => {
							console.warn(createOpportunityError);
							reject();
						});
				});
			},
			createContact: () => {
				const { account, customerId } = store.pluck('account', 'customerId');
				createContact(customerId, account)
					.then(newContact => {
						if (newContact.active) {
							const { appointment, participantSelect, participants } = store.pluck(
								'appointment',
								'participantSelect',
								'participants'
							);
							newContact.$id = `contact-${newContact.id}`;

							participantSelect.data[0].children.push(newContact);

							participants.push(newContact);

							if (Array.isArray(appointment.contacts)) {
								appointment.contacts.push(newContact);
							} else {
								appointment.contacts = [newContact];
							}

							store.setStore({
								participantSelect,
								participants,
								appointment,
								isDirty: true
							});
						}
					})
					.catch(err => {
						logError(err, 'Error creating contact');
					});
			},
			getAppointments: (month?: string, returnInstead?: boolean) => {
				let MOMENT_OBJECT;

				if (month) {
					MOMENT_OBJECT = moment(month);
				} else {
					MOMENT_OBJECT = moment(store.get('appointment.date'));
				}

				const START_DATE = MOMENT_OBJECT.clone().subtract(1, 'month').startOf('month').toDate();
				const END_DATE = MOMENT_OBJECT.clone().add(1, 'month').endOf('month').toDate();
				const AppointmentAttrs = Tools.Appointment.attr;
				// @ts-expect-error
				var rb = new Tools.RequestBuilder();
				rb.addFilter(AppointmentAttrs.users, rb.comparisonTypes.Equals, Tools.AppService.getSelf().id);

				var orBuilder = rb.orBuilder();
				orBuilder.next();
				// Current appointment intercept with end of another one
				orBuilder.addFilter(AppointmentAttrs.date, rb.comparisonTypes.GreaterThanEquals, START_DATE);
				orBuilder.addFilter(AppointmentAttrs.date, rb.comparisonTypes.LessThan, END_DATE);
				orBuilder.next();
				// Current appointment spans over another one
				orBuilder.addFilter(AppointmentAttrs.date, rb.comparisonTypes.GreaterThanEquals, START_DATE);
				orBuilder.addFilter(AppointmentAttrs.endDate, rb.comparisonTypes.LessThan, END_DATE);
				orBuilder.next();
				// Current appointment intercepts with the end of another one
				orBuilder.addFilter(AppointmentAttrs.date, rb.comparisonTypes.LessThanEquals, START_DATE);
				orBuilder.addFilter(AppointmentAttrs.endDate, rb.comparisonTypes.LessThanEquals, END_DATE);
				orBuilder.addFilter(AppointmentAttrs.endDate, rb.comparisonTypes.GreaterThan, START_DATE);
				orBuilder.next();
				// Current appointment start and ends during another one
				orBuilder.addFilter(AppointmentAttrs.date, rb.comparisonTypes.LessThan, START_DATE);
				orBuilder.addFilter(AppointmentAttrs.endDate, rb.comparisonTypes.GreaterThan, END_DATE);
				orBuilder.done();

				if (returnInstead) {
					return new Promise(resolve => {
						Tools.Appointment.customer(Tools.AppService.getCustomerId())
							.find(rb.build())
							.then(function (res) {
								return resolve(res.data);
							})
							.catch(err => {
								logError(err, 'Error finding appointment');
							});
					});
				} else {
					Tools.Appointment.customer(Tools.AppService.getCustomerId())
						.find(rb.build())
						.then(function (res) {
							store.set('calendarAppointments', res.data);
						})
						.catch(err => {
							logError(err, 'Error finding appointment');
						});
				}
			},
			getOpportunities: (CLIENT: any) => {
				return new Promise((resolve, reject) => {
					if (CLIENT && CLIENT.id) {
						const Order = Tools.Order;
						const OrderAttr = Order.attr;
						// @ts-expect-error
						const opportunityFilter = new Tools.RequestBuilder();
						const eq = opportunityFilter.comparisonTypes.Equals;
						const neq = opportunityFilter.comparisonTypes.NotEquals;

						opportunityFilter.addFilter(OrderAttr.account, eq, CLIENT.id);
						opportunityFilter.addFilter(OrderAttr.probability, neq, 100);
						opportunityFilter.addFilter(OrderAttr.probability, neq, 0);

						Order.customer(Tools.AppService.getCustomerId())
							.find(opportunityFilter.build())
							.then(response => {
								return resolve(response.data);
							})
							.catch(err => {
								logError(err, 'Error finding order');
							});
					} else {
						return reject('no appointment client..');
					}
				});
			},
			saveComment: async (comment: any) => {
				const appointment = store.get('appointment');
				const { data: createdComment } = await CommentResource.save({
					// @ts-expect-error
					user: { id: Tools.AppService.getSelf().id },
					description: comment,
					client: appointment.client,
					appointment,
					outcomeType: appointment.outcome
				});
				return createdComment;
			},
			createFollowUp: async (isActivity: any, shouldCreateEvent: any, comment: any) => {
				const hasNewAppOutcomeEvents =
					Tools.FeatureHelper.hasSoftDeployAccess('NEW_APPOINTMENT_OUTCOME_EVENTS') &&
					Tools.FeatureHelper.hasSoftDeployAccess('TODO_LIST');
				const appointment = store.get('appointment');
				const getNewEntity = (response: any = {}) => {
					let item;

					if (isActivity) {
						item = Tools.Activity.new().data;
						item.description = appointment.description;
						item.contacts = appointment.contacts ? appointment.contacts[0] : null;
					} else {
						item = Tools.Appointment.new().data;
						item.activityType = null;
						item.custom = response.custom;
						item.sourceType = 'appointment';
						item.sourceId = appointment.id;
						item.contacts = appointment.contacts;
						if (item.contacts?.length) {
							item.contacts = item.contacts.map((contact: any) => ({ ...contact, isInvited: 0 }));
						}
						item.emailAttendees = appointment.emailAttendees;
						if (item.emailAttendees?.length) {
							item.emailAttendees = item.emailAttendees.map((attendee: any) => ({
								...attendee,
								isInvited: 0
							}));
						}
					}

					item.notes = appointment.notes;
					item.client = appointment.client;
					item.project = appointment.project;
					item.users = [_.pick(Tools.AppService.getSelf(), ['id', 'name', 'role', 'email'])];

					if (appointment.closeDate) {
						actions.resolve(appointment);
					}
					return item;
				};
				const openActivityModal = (appointment: any, item: any = appointment) => {
					// eslint-disable-next-line promise/catch-or-return
					return Tools.$upModal.open('editActivity', { activity: item }).then(async res => {
						if (shouldCreateEvent && appointment.id) {
							if (hasNewAppOutcomeEvents) {
								let outcomeCommentId;
								if (comment) {
									try {
										({ id: outcomeCommentId } = await actions.saveComment(comment));
									} catch (err) {
										logError(err, 'Could not save comment');
									}
								}
								actions.saveAppointmentWithAction({
									id: appointment.id,
									outcome: appointment.outcome,
									outcomeAction: EVENT_TYPES.ACTIVITYCREATED,
									outcomeExtra: JSON.stringify({ id: res.id, description: res.description }),
									...(outcomeCommentId ? { outcomeCommentId } : {})
								});
							} else {
								AppointmentEvents.createEvent(appointment.id, {
									// @ts-expect-error
									action: EVENT_TYPES.ACTIVITYCREATED,
									fields: { activity: { id: res.id, description: res.description } }
								});
							}
						}
					});
				};
				const openAppointmentModal = (appointment: any, item: any) => {
					// eslint-disable-next-line promise/catch-or-return
					return openEditAppointment({ appointment: item }).then(async res => {
						if (shouldCreateEvent && appointment.id) {
							if (hasNewAppOutcomeEvents) {
								let outcomeCommentId;
								if (comment) {
									try {
										({ id: outcomeCommentId } = await actions.saveComment(comment));
									} catch (err) {
										logError(err, 'Could not save comment');
									}
								}
								actions.saveAppointmentWithAction({
									id: appointment.id,
									outcome: appointment.outcome,
									outcomeAction: EVENT_TYPES.APPOINTMENTCREATED,
									outcomeExtra: JSON.stringify({ id: res.id, description: res.description }),
									...(outcomeCommentId ? { outcomeCommentId } : {})
								});
							} else {
								AppointmentEvents.createEvent(appointment.id, {
									// @ts-expect-error
									action: EVENT_TYPES.APPOINTMENTCREATED,
									fields: {
										appointment: { id: res.id, description: res.description }
									}
								});
							}
						}
					});
				};
				try {
					if (hasNewAppOutcomeEvents) {
						if (isActivity) {
							return openActivityModal(appointment, getNewEntity());
						} else {
							return openAppointmentModal(appointment, getNewEntity());
						}
					} else {
						const response: any = await saveAppointment(store, true);
						if (!appointment.id) {
							store.set('appointment.id', response.id);
						}
						if (isActivity) {
							await openActivityModal(appointment, getNewEntity(response));
						} else {
							await openAppointmentModal(appointment, getNewEntity(response));
						}
						store.set('saving', false);
						return;
					}
				} catch (err) {
					// eslint-disable-next-line no-empty
				}
			},
			createDocument: (template: any) => {
				const params = {
					entityId: store.get('appointment.id'),
					templateId: template.id,
					type: 'appointment',
					name: template.name
				};

				if (!store.get('isDirty')) {
					return Tools.$upModal.open('pdfPreview', params);
				}

				store.set('saving', true);

				if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
					openModal('UnsavedChangesAlert', {
						title: 'default.saveChanges',
						body: 'confirm.saveAndCreateDocument',
						cancelButtonText: 'default.abort',
						onClose: confirmed => {
							if (confirmed) {
								actions
									.saveAppointment()
									.then(function () {
										store.setStore({ saving: false, isDirty: false });
										Tools.$upModal.open('pdfPreview', params);
									})
									.catch(err => {
										logError(err, 'Error saving appointment');
									});
							} else {
								store.setStore({ saving: false });
							}
						}
					});
					return;
				}

				Tools.$upModal
					.open('warningConfirm', {
						title: 'default.saveChanges',
						body: 'confirm.saveAndCreateDocument',
						resolveFalse: 'default.save',
						resolveFalseBtnClass: 'btn-submit',
						no: 'default.abort',
						icon: 'fa-warning'
					})
					.then(function () {
						actions
							.saveAppointment()
							.then(function () {
								store.setStore({ saving: false, isDirty: false });
								Tools.$upModal.open('pdfPreview', params);
							})
							.catch(err => {
								logError(err, 'Error saving appointment');
							});
					})
					.catch(() => {});
			},
			promptRemoval: (opts: any) => {
				if (opts.closePrompt) {
					store.set('promptRemoval', false);
				} else {
					store.set('promptRemoval', true);
				}
			},
			deleteAppointment: () => {
				const APPOINTMENT = store.get('appointment');

				if (APPOINTMENT.userRemovable) {
					resolve();
					Tools.Appointment.customer(Tools.AppService.getCustomerId()).delete(APPOINTMENT);
				}
			},
			saveAppointment: (followup?: any) => {
				return saveAppointment(store, followup).then(({ appointment, shouldResolve }: any) => {
					if (shouldResolve) {
						actions.resolve(appointment);
					}

					return { appointment, openSelectOpportunity: true };
				});
			},
			saveSelectedOpportunity: (opportunity: any) => {
				const { appointment, customerId } = store.pluck('appointment', 'customerId');
				Tools.Appointment.customer(customerId)
					.save({ id: appointment.id, opportunity: opportunity })
					.then((response: any) => {
						return actions.resolve(response.data);
					})
					.catch(err => console.error(err));
			},
			saveAppointmentWithFiles: (newFiles: any, deletedFiles: any, inviteContacts: any) => {
				return saveAppointment(store, false, inviteContacts).then(({ appointment, shouldResolve }: any) => {
					return Promise.all([
						[...newFiles].map(file =>
							Tools.File.upload(file, {
								fileEntity: Tools.File.entityTypes.APPOINTMENT,
								fileId: appointment.id
							})
						),
						[...deletedFiles].map(file =>
							Tools.File.delete(file, {
								noConfirm: true
							})
						)
					])
						.then(() => {
							if (shouldResolve) {
								actions.resolve(appointment);
							}

							return { appointment, openSelectOpportunity: true };
						})
						.catch(err => {
							logError(err, 'Error saving appointment');
						});
				});
			},
			saveAppointmentNotes: (notes: any) => saveAppointmentNotes(store, notes),
			getContacts: () => {
				let appointmentContacts = store.get('appointment.contacts');
				if (!Array.isArray(appointmentContacts)) appointmentContacts = [];

				const contactIds = appointmentContacts.map((o: any) => o.id);
				// @ts-expect-error
				const rb = new Tools.RequestBuilder();
				rb.addFilter(Tools.Contact.attr.id, 'eq', contactIds);

				Tools.Contact.find(rb.build())
					.then(response => {
						if (Array.isArray(response.data)) {
							const contactMap = response.data?.reduce((map: any, contact: any) => {
								map[contact.id] = contact;
								return map;
							}, {});
							const fullInfoContacts = appointmentContacts.map((contact: any) => ({
								...contact,
								...(contactMap[contact.id] ?? {})
							}));
							store.set('appointment.contacts', fullInfoContacts);
						} else {
							store.set('appointment.contacts', response.data);
						}
					})
					.catch(err => {
						logError(err, 'Error finding contact');
					});
			},
			getLinks: () => {
				const appointmentId = store.get('appointment.id');
				if (appointmentId) {
					Tools.Links.customer(Tools.AppService.getCustomerId())
						.get('appointment', appointmentId)
						.then(response => {
							store.set('links', response.data);
						})
						.catch(error => {
							store.set('linksError', error);
						})
						.finally(() => store.set('loadingLinks', false));
				} else {
					store.set('loadingLinks', false);
				}
			},
			saveContact: (contact: any) => {
				Tools.Contact.save(contact)
					.then(res => {
						if (!res.error && res.data) {
							const currentContacts = store.get('appointment.contacts');
							const contactIndex = _.findIndex(currentContacts, { id: contact.id });
							currentContacts.splice(contactIndex, 1, { ...contact, ...res.data });
							store.set('appointment.contacts', currentContacts);
						}
					})
					.catch(err => {
						logError(err, 'Error saving contact');
					});
			},
			setParticipantData: (init: any) => {
				const appointment = store.get('appointment');
				const client = appointment && appointment.client ? appointment.client : null;
				const operationalAccountId = hasSubaccounts && client ? client.operationalAccount?.id : undefined;

				participantSelect(client?.id ?? null, store.get('users'), utils, operationalAccountId)
					.then(response => {
						store.setStore({
							participantSelect: response.object,
							isDirty: !init
						});
					})
					.catch(err => {
						logError(err, 'Error selecting participant');
					});
			},
			getContactsFromOtherCompanies: async (searchStr: any) => {
				const appointment = store.get('appointment');
				const client = appointment && appointment.client ? appointment.client : null;
				if (!client?.id || !searchStr) {
					const participantSelect = store.get('participantSelect');
					participantSelect.data[2].children = [];
					store.setStore({
						participantSelect
					});
					return;
				}

				const { Contact } = Tools;
				// @ts-expect-error
				const contactFilter = new Tools.RequestBuilder();

				contactFilter.addFilter(Contact.attr.name, comparisonTypes.Search, searchStr);
				contactFilter.addFilter(Contact.attr.active, comparisonTypes.Equals, 1);
				contactFilter.addFilter({ field: 'client.active' }, comparisonTypes.Equals, 1);
				contactFilter.addFilter({ field: 'client.id' }, comparisonTypes.NotEquals, client.id);

				contactFilter.limit = 20;
				contactFilter.addSort({ field: 'name' }, true);

				Contact.find(contactFilter.build())
					.then(res => {
						const participantSelect = store.get('participantSelect');
						res.data = res.data.map(c => ({ ...c, subtitle: c.client.name }));
						participantSelect.data[2].children = res.data;
						store.setStore({
							participantSelect
						});
					})
					.catch(e => logError(e, 'Error getting contacts from other companies'));
			},
			saveAppointmentWithAction: async (appointmentData: any, extraParams?: any) => {
				const appointment = store.get('appointment');
				const invitedContacts = store.get('invitedContacts');
				const invitedEmailAttendees = store.get('invitedEmailAttendees');
				setInviteOnContactsAndEmailAttendees(appointmentData, invitedContacts, invitedEmailAttendees, false);
				const { data: newAppointment } = await AppointmentResource.save(appointmentData, {
					params: { saveOutcomeAction: appointmentData.outcomeAction ? true : false },
					...extraParams
				});

				store.setStore({
					appointment: { ...appointment, outcome: newAppointment.outcome },
					originalOutcome: newAppointment.outcome
				});
			},
			saveOutcomeComment: async (outcomeCommentId: any) => {
				const appointment = store.get('appointment');
				const isRescheduling = store.get('reschedule');

				let saveData: any = { id: appointment.id, outcome: appointment.outcome };
				if (isRescheduling) {
					saveData.date = appointment.date;
				}
				if (outcomeCommentId) {
					saveData = {
						...saveData,
						outcomeAction: EVENT_TYPES.COMMENTCREATED,
						...(outcomeCommentId ? { outcomeCommentId } : {})
					};
				}
				await actions.saveAppointmentWithAction(saveData);
			},
			setSavingType: (savingType: any) => {
				store.set('savingType', savingType);
			}
		}),
		[store, resolve, reject, calculateHeight]
	);

	return actions;
};

export default useActions;
