import {
	Block,
	Checkbox,
	Column,
	Label,
	Modal,
	ModalContent,
	ModalControls,
	ModalHeader,
	Row
} from '@upsales/components';
import { asyncModalAdapter } from 'App/helpers/angularPortingHelpers';
import { useTranslation } from 'Components/Helpers/translate';
import React, { useState } from 'react';
import { ModalProps } from '../Modals/Modals';
import MarketRejectList from 'App/resources/MarketRejectList';
import { PrimaryButton, ThirdButton } from '@upsales/components/Buttons';
import Client from 'App/resources/Client';

type Props = ModalProps & {
	account: any;
	isAdmin: boolean;
	contact: any;
	customerId: number;
	synch: boolean;
	userId: number;
};

export default function ResetScore(props: Props) {
	const [rejectList, setRejectList] = useState(false);
	const [resetting, setResetting] = useState(false);

	const { t } = useTranslation();

	const isAdmin = Tools.AppService.getSelf().administrator;

	const handleSubmit = async () => {
		try {
			setResetting(true);

			if (props.account && props.account.id) {
				const accountRes = await Tools.Account.customer(props.customerId).get(props.account.id);

				await Tools.ResetScore.customer(props.customerId).reset({
					clientId: accountRes.data.id,
					userId: props.userId,
					synch: props.synch || false,
					customerId: props.customerId
				});

				await Client.unassign(accountRes.data.id, props.customerId);

				accountRes.data.score = 0;
				accountRes.data.assigned = undefined;

				if (!rejectList) {
					return props.close();
				} else {
					await MarketRejectList.save({
						clientId: accountRes.data.id,
						name: accountRes.data.name,
						dunsNo: accountRes.data.dunsNo
					});
					return props.close();
				}
			}

			if (props.contact && props.contact.id) {
				const contactRes = await Tools.Contact.customer(props.customerId).get(props.contact.id);

				await Tools.ResetScore.customer(props.customerId).reset({
					clientId: contactRes.data.id,
					userId: props.userId,
					synch: props.synch || false,
					customerId: props.customerId
				});

				return props.close();
			}
		} catch (err) {
			setResetting(false);
		} finally {
			setResetting(false);
		}
	};

	const lang = {
		title: t('default.resetScore'),
		resetscore: t('confirm.resetScore'),
		market: t('market.addToRejectList'),
		close: t('default.close'),
		resetScoreContact: t('confirm.resetScoreContact'),
		resetting: t('default.resetting'),
		reset: t('default.reset'),
		abort: t('default.abort'),
		addToRejectList: t('market.addToRejectList')
	};

	return (
		<Modal className={props.className}>
			<ModalHeader title={lang.title} onClick={() => props.close()} />
			<ModalContent>
				<Column>
					{props.account ? (
						<Row>
							<Block space="mbl">{lang.resetscore}</Block>
							{isAdmin ? (
								<Row>
									<Checkbox
										space="mrl"
										onChange={(value: boolean) => setRejectList(value)}
										checked={rejectList}
									/>
									<Label>{lang.addToRejectList}</Label>
								</Row>
							) : null}
						</Row>
					) : null}
					{props.contact ? (
						<Row>
							<Label>{lang.resetScoreContact}</Label>
						</Row>
					) : null}
				</Column>
			</ModalContent>
			<ModalControls>
				<PrimaryButton shadow="none" onClick={handleSubmit} disabled={resetting}>
					{resetting ? (
						<>
							<b className="fa fa-refresh fa-spin"></b> {lang.resetting}
						</>
					) : (
						lang.reset
					)}
				</PrimaryButton>
				<ThirdButton onClick={() => props.close()}>{lang.abort}</ThirdButton>
			</ModalControls>
		</Modal>
	);
}

export const openResetScoreModal = asyncModalAdapter({
	featureFlag: 'RESETSCORE_REACT',
	openModalName: 'ResetScore',
	upModalName: 'resetScore',
	rejectOnEmpty: false
});
