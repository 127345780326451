import { Modal } from '@upsales/components';
import Button from '@upsales/components/Button';
import Help from '@upsales/components/Help';
import Input from '@upsales/components/Input';
import Label from '@upsales/components/Label';
import ModalContent from '@upsales/components/ModalContent';
import ModalControls from '@upsales/components/ModalControls';
import ModalHeader from '@upsales/components/ModalHeader';
import Progress from '@upsales/components/Progress';
import { ModalProps } from 'App/components/Modals/Modals';
import { asyncModalAdapter } from 'App/helpers/angularPortingHelpers';
import React from 'react';
import { StandardFieldConfig } from 'App/resources/AllIWant';
import './EditStandardFieldModal.scss';

type Props = {
	actions: {
		onClose: () => void;
		onSave: (field: StandardFieldConfig) => void;
	};
	field: any;
	name?: string;
};

type State = {
	fieldName: string;
	permissionToRole: string;
	tooltip: string;
	helpTextLenght: number;
};

class EditStandardField extends React.Component<Props, State> {
	lang: { [key: string]: string };

	constructor(props: Props) {
		super(props);

		const t = Tools.$translate;

		this.lang = {
			title: t('admin.standardfields'),
			body: t('admin.editStandardField.body').replace('. ', '.\n'),
			fieldName: t('admin.customfieldName'),
			permissionToRole: t('default.permissionToRole'),
			helpText: t('default.helpText'),
			nothing: t('default.nothing'),
			save: t('default.save'),
			cancel: t('cancel')
		};

		this.state = {
			fieldName: props.name || props.field.name,
			permissionToRole: this.lang.nothing,
			tooltip: props.field.tooltip || '',
			helpTextLenght: 100
		};
		this.changeHelpText = this.changeHelpText.bind(this);
	}

	changeHelpText(value: string) {
		if (value.split('\n').length <= 3 && value.length <= this.state.helpTextLenght) {
			this.setState({ tooltip: value });
		}
	}

	render() {
		const { onClose, onSave } = this.props.actions;
		const { tooltip, helpTextLenght } = this.state;
		const { title, body, fieldName, permissionToRole, helpText, save, cancel } = this.lang;
		const helpTextPercentage = (tooltip.length / helpTextLenght) * 100;
		return (
			<div className="EditStandardFieldModal">
				<ModalHeader className="ModalHeader--no-border-bottom" title={title} onClose={onClose} />
				<ModalContent className="ModalContent--no-padding">
					<div className="EditStandardFieldModal__title-body">{body}</div>
					<div className="EditStandardFieldModal__input-wrapper">
						<div className="EditStandardFieldModal__input col-xs-6">
							<Label>{fieldName}</Label>
							<Input value={this.state.fieldName} disabled />
						</div>
						<div className="EditStandardFieldModal__input col-xs-6">
							<Label>{permissionToRole}</Label>
							<Input value={this.state.permissionToRole} disabled />
						</div>
						<div className="EditStandardFieldModal__input col-xs-6">
							<Label>
								<div className="EditStandardFieldModal__input-label">
									{helpText}
									<Help articleId={552} sidebar={true} />
									<Progress
										className="EditStandardFieldModal__progress"
										percentage={helpTextPercentage}
										// @ts-ignore
										strokeWidth={12}
										hideText
										state={helpTextPercentage < 100 ? undefined : 'error'}
									/>
								</div>
							</Label>
							<textarea
								className="EditStandardFieldModal__textarea"
								value={tooltip}
								onChange={e => this.changeHelpText(e.target.value)}
								rows={3}
								title="Change help text"
							/>
						</div>
					</div>
				</ModalContent>
				<ModalControls>
					<Button
						color="bright-blue"
						shadow="none"
						onClick={() => onSave(Object.assign({}, this.props.field, { tooltip }))}
					>
						{save}
					</Button>
					<Button color="grey" type="link" onClick={onClose}>
						{cancel}
					</Button>
				</ModalControls>
			</div>
		);
	}
}

type EditStandardFieldModalProps = ModalProps<StandardFieldConfig> & Pick<Props, 'field' | 'name'>;

export const EditStandardFieldModal = ({ className, close, ...props }: EditStandardFieldModalProps) => {
	const actions: Props['actions'] = {
		onClose: () => close(),
		onSave: (field: StandardFieldConfig) => {
			close(field);
			//Hacky solution but we couldn't find any other way to update the UI,
			//we could get the correct value on init from AppService or API instead but this is only
			//to fix tooltip text in UI so feels kinda overkill and $modalParams
			//should really come with updated data after saving so it should get fixed there
			props.field.tooltip = field.tooltip;
		}
	};

	return (
		<Modal className={className}>
			<EditStandardField actions={actions} {...props} />
		</Modal>
	);
};

export const openEditStandardFieldModal = asyncModalAdapter({
	featureFlag: 'EDIT_STANDARD_FIELD_REACT',
	openModalName: 'EditStandardFieldModal',
	upModalName: 'editStandardFields'
});

export default EditStandardField;
