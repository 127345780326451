import PropTypes from 'prop-types';
import React from 'react';
import { Loader, Text, Card, CardContent, Row, Column, Label } from '@upsales/components';
import bemClass from '@upsales/components/Utils/bemClass';
import './PdfGeneric.scss';
import logError from 'App/babel/helpers/logError';
import t from 'Components/Helpers/translate';
import { makeCancelable } from 'Helpers/promise';

import * as pdfjs from 'pdfjs-dist';

class PdfGeneric extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			pdfDoc: null,
			pageNum: 1,
			page_count: null,
			pageRendering: false,
			pageNumPending: null,
			scale: 1.1455,
			loading: true,
			previewError: false
		};

		this.lang = {
			headline: t('admin.salesProcess.create.title'),
			description: t('admin.salesProcess.create.description'),
			name: t('default.name'),
			roleAcces: t('default.permissionToRole'),
			create: t('admin.salesProcess.create'),
			placeholder: t('admin.salesProcess.create.placeholder'),
			abort: t('default.abort'),
			close: t('default.close'),
			downloadPdf: t('document.downloadPdfFile'),
			sendMail: t('mail.sendEmail'),
			previewError: t('document.previewError')
		};
	}

	canvas = React.createRef();

	queueRenderPage = num => {
		if (this.state.pageRendering) {
			this.setState({ pageNumPending: num });
		} else {
			this.renderPage(num);
		}
	};

	onNextPag = () => {
		if (this.state.pageNum >= this.state.pdfDoc.numPages) {
			return;
		}
		this.setState({ pageNum: this.state.pageNum + 1 });
		this.queueRenderPage(this.state.pageNum + 1);
	};

	onPrevPage = () => {
		if (this.state.pageNum <= 1) {
			return;
		}
		this.setState({ pageNum: this.state.pageNum - 1 });
		this.queueRenderPage(this.state.pageNum - 1);
	};

	renderPage = num => {
		const canvas = this.canvas;
		this.setState({
			pageRendering: true
		});

		// Using promise to fetch the page
		this.state.pdfDoc
			.getPage(num)
			.then(page => {
				const parent = document.querySelector(this.props.parentSelector);
				const parentContainer = parent.getBoundingClientRect();
				const parentComputed = getComputedStyle(parent);

				const width =
					parentContainer.width -
					(parseInt(parentComputed.paddingLeft) + parseInt(parentComputed.paddingRight));
				const height =
					parentContainer.height -
					(parseInt(parentComputed.paddingTop) + parseInt(parentComputed.paddingBottom));

				let viewport = page.getViewport({ scale: 1 });
				const scale = Math.min(width / viewport.width, height / viewport.height);
				viewport = page.getViewport({ scale: scale });

				canvas.current.height = viewport.height;
				canvas.current.width = viewport.width;

				// Render PDF page into canvas context
				const renderContext = {
					canvasContext: canvas.current.getContext('2d'),
					viewport: viewport
				};
				this.renderTaskPromise = makeCancelable(page.render(renderContext).promise);
				// Wait for rendering to finish
				this.renderTaskPromise.promise
					.then(() => {
						this.setState({
							pageRendering: false
						});
						if (this.state.pageNumPending !== null) {
							// New page rendering is pending
							this.renderPage(this.state.pageNumPending);
							this.setState({
								pageNumPending: null
							});
						}
						this.setState({ loading: false, previewError: false });
						document.getElementsByClassName('PdfGeneric')[0].style.height = '100%';
					})
					.catch(() => {
						console.warn('Page render did not happen properly');
					});
			})
			.catch(e => {
				console.warn('Something went wrong when retrieving page', e);
			});
		// Update page counters
		this.setState({ pageNum: num });
	};

	loadDoc = () => {
		pdfjs
			.getDocument(this.props.url)
			.promise.then(pdfDoc_ => {
				const pdf = pdfDoc_;
				this.setState({ pdfDoc: pdf });
				this.setState({ page_count: pdf.numPages });
				this.renderPage(this.state.pageNum);
			})
			.catch(e => {
				logError(e, 'Failed to preview PDF');
			});
	};

	componentDidMount() {
		this.loadDoc();
	}

	componentWillUnmount() {
		if (this.renderTaskPromise) {
			this.renderTaskPromise.cancel();
		}
	}

	render() {
		const classes = new bemClass('PdfGeneric', this.props.className);

		return (
			<div className={classes}>
				<div className="preview--modal--header">
					{this.state.loading ? (
						<Loader size="lg" className="pdf-loader" />
					) : !this.state.previewError ? (
						<Row style={{ height: '20px' }}>
							<Column size={3}></Column>
							<Column size={5} style={{ textAlign: 'center' }}>
								<Label>
									<span className="options">
										<button className="prev" onClick={this.onPrevPage}>
											<i className="fa fa-angle-left"></i>
										</button>{' '}
										<span className="page_num">{this.state.pageNum}</span> {'/'}{' '}
										<span className="page_count">{this.state.page_count}</span>{' '}
										<button className="next" onClick={this.onNextPag}>
											<i className="fa fa-angle-right"></i>
										</button>
									</span>
								</Label>
							</Column>
							<Column size={3}></Column>
						</Row>
					) : null}
				</div>
				<div style={{ textAlign: 'center' }}>
					{this.state.previewError ? (
						<Card color="grey-11" border="blue" className="error-card">
							<CardContent>
								<Text center size="xl" color="white">
									{this.lang.previewError}
								</Text>
							</CardContent>
						</Card>
					) : (
						<canvas ref={this.canvas} />
					)}
				</div>
			</div>
		);
	}
}

PdfGeneric.propTypes = {
	url: PropTypes.string.isRequired,
	className: PropTypes.string,
	parentSelector: PropTypes.string.isRequired
};

export default PdfGeneric;
