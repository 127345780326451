import { createDraft, finishDraft, current, isDraft, setAutoFreeze, freeze } from 'immer';
import { useState, useRef, useEffect, useMemo } from 'react';

import type { Draft, WritableDraft } from 'immer';

setAutoFreeze(false);

export const getPlainObject = <T>(object: T | WritableDraft<T>) => {
	if (isDraft(object)) {
		return current(object) as T;
	} else {
		return object as T;
	}
};

export const getPlainArray = <T>(object: (T | WritableDraft<T>)[]) => {
	return object.map(item => getPlainObject(item));
};

const useDraftableStateStore = <State extends { [key: string]: any }>(initialState: Partial<State> = {}) => {
	const [state, setState] = useState<State>(initialState as State);
	const stateRef = useRef<State>(initialState as State);
	const draftRef = useRef<Draft<State> | null>(null);
	const oldStateRef = useRef<State | null>(null);
	const timeoutRef = useRef<NodeJS.Timeout | null>(null);

	const { getState, startDraftState, finishDraftState, getAutoDraftState } = useMemo(() => {
		function getState() {
			return draftRef.current ?? stateRef.current;
		}

		function commitState(state: State) {
			stateRef.current = state;
			setState(state);
		}

		function startDraftState() {
			if (draftRef.current) {
				return draftRef.current;
			}
			// The reason I do the freeze is because I understood it that it was a perfomance improvement + you will get an error if you try to mutate the frozen object
			const shallowlyFrozenState = freeze(stateRef.current);
			const draft = createDraft<State>(shallowlyFrozenState);
			draftRef.current = draft;
			return draft;
		}

		function finishDraftState() {
			if (draftRef.current) {
				const nextState = finishDraft<Draft<State>>(draftRef.current) as State;
				draftRef.current = null;
				commitState(nextState);
			}
		}

		function getAutoDraftState() {
			if (timeoutRef.current) {
				clearTimeout(timeoutRef.current);
			}

			const draft = startDraftState();
			timeoutRef.current = setTimeout(() => finishDraftState(), 0);

			return draft;
		}

		return {
			getState,
			startDraftState,
			finishDraftState,
			getAutoDraftState
		};
	}, []);

	useEffect(() => {
		return () => {
			if (timeoutRef.current) {
				clearTimeout(timeoutRef.current);
			}
		};
	}, []);

	useEffect(() => {
		oldStateRef.current = state;
	}, [state]);

	const oldState = oldStateRef.current;

	return {
		state,
		oldState,
		getState,
		startDraftState,
		finishDraftState,
		getAutoDraftState
	};
};

export type DraftableStateStore<T extends { [key: string]: any }> = ReturnType<typeof useDraftableStateStore<T>>;

export default useDraftableStateStore;
