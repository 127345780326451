import {
	SplitButtonWithActions,
	DropDownButton,
	ModalControls,
	DropDownMenu,
	ModalContent,
	OutsideClick,
	ModalHeader,
	WhiteModal,
	Tooltip,
	Button,
	Input,
	Flex,
	Text,
	Label,
	DropdownContainer,
	EllipsisTooltip
} from '@upsales/components';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { DangerButton, ThirdButton } from '@upsales/components/Buttons';
import { useTranslation } from 'Components/Helpers/translate';
import { FormComponent } from 'App/components/FormComponent';
import BemClass from '@upsales/components/Utils/bemClass';

import './templateHeaderSelector.scss';
import { Column } from 'App/components/ExportDataReact/types';

export type ExportTemplate = {
	id: number;
	selected?: boolean;
	exportOrderRows?: boolean;
	name: string;
	columns: Column[];
};

type Props = {
	hasChanged: boolean;
	options: { id: number; name: string; exportOrderRows?: boolean; columns: Column[] }[];
	onChange: (value: any) => void;
	onSave: (name: string, asNew?: boolean) => void;
	onDelete: () => void;
	selectedValue: ExportTemplate;
	defaultTemplate?: ExportTemplate;
	templatesLimit?: number;
};

const TEMPLATES_LIMIT = 15;

const TemplateHeaderSelector = ({
	hasChanged,
	options = [],
	onChange = () => {},
	selectedValue,
	onSave = () => {},
	onDelete = () => {},
	defaultTemplate = { id: -1, name: 'default', columns: [] },
	templatesLimit = TEMPLATES_LIMIT
}: Props) => {
	const { t } = useTranslation();
	const classes = new BemClass('TemplateHeaderSelector');
	const [templateName, setTemplateName] = useState<string>(selectedValue?.name || '');
	const saveAsNewRef = useRef(false);
	const lang = {
		cancel: t('default.cancel'),
		default: t('default.default'),
		delete: t('default.delete'),
		save: t('default.save'),
		deleteConfirmSubtitle: t('templateHeaderSelector.controls.deleteConfirm.subtitle'),
		deleteConfirmTitle: t('templateHeaderSelector.controls.deleteConfirm.title'),
		enterName: t('templateHeaderSelector.save.placeholder'),
		nameNotEmpty: t('templateHeaderSelector.save.nameNotEmpty'),
		nameNotUnique: t('saveError.nameNotUnique'),
		update: t('templateHeaderSelector.button.updateTemplate'),
		saveAsNew: t('templateHeaderSelector.button.saveAsNewTemplate'),
		saveTemplate: t('templateHeaderSelector.button.saveTemplate'),
		updateTemplate: t('templateHeaderSelector.button.updateTemplate'),
		saveLimit: t('templateHeaderSelector.saveLimit', { limit: templatesLimit })
	};

	const [showSaveModal, setShowSaveModal] = useState(false);

	const onCancelSave = () => {
		setTemplateName(selectedValue?.name || '');
		setShowSaveModal(false);
	};

	const isValidName = useCallback(
		(name: string) => name.trim().length > 0 && !options?.find(option => option.name === name),
		[options]
	);

	useEffect(() => {
		setTemplateName(selectedValue?.name || '');
		saveAsNewRef.current = false;
	}, [selectedValue]);

	const memoedOptions = useMemo(() => {
		const newOptions = [...options].map(option => ({
			id: option.id,
			name: option.name ?? '',
			exportOrderRows: option.exportOrderRows,
			columns: option.columns
		}));
		return newOptions;
	}, [options]);

	return (
		<Flex className={classes.b()} alignItems="center">
			{showSaveModal ? (
				<OutsideClick
					targetClass={classes.elem('modal').b()}
					outsideClick={() => setShowSaveModal(false)}
					onKeyDown={e => {
						if (e.key === 'Enter') {
							e.preventDefault();
							if (isValidName(templateName)) {
								onSave(templateName, saveAsNewRef.current);
								setShowSaveModal(false);
							}
						}
					}}
				>
					<WhiteModal space="pl" className={classes.elem('modal').b()}>
						<ModalHeader title={lang.saveTemplate}></ModalHeader>
						<ModalContent>
							<FormComponent label={t('Name')} required value={templateName} maxLength={64}>
								<Input
									placeholder={t('templateName')}
									onChange={({ target: { value } }) => {
										setTemplateName(value);
									}}
									value={templateName}
									maxLength={64}
									autoComplete="off"
									id="search" //ignore lastpass and others
								/>
							</FormComponent>
						</ModalContent>
						<ModalControls className={classes.elem('modal').elem('controls').b()}>
							<Flex alignItems="center" justifyContent="flex-end">
								{!saveAsNewRef.current ? (
									<DropDownMenu
										className={classes.elem('modal').elem('controls').elem('delete').b()}
										renderTrigger={(isExpanded, setExpanded) => (
											<DropDownButton
												icon="trash"
												expanded={isExpanded}
												onClick={setExpanded}
												size="sm"
											/>
										)}
									>
										{close => (
											<DropdownContainer>
												<Flex
													justifyContent="space-between"
													alignItems="center"
													space="ptm prm pbm plm"
													direction="column"
												>
													<Label>{lang.deleteConfirmTitle}</Label>
													<Text>{lang.deleteConfirmSubtitle}</Text>
													<DangerButton
														className={classes
															.elem('modal')
															.elem('controls')
															.elem('deleteConfirm')
															.b()}
														onClick={() => {
															onDelete();
															setShowSaveModal(false);
															close();
														}}
													>
														{lang.delete}
													</DangerButton>
												</Flex>
											</DropdownContainer>
										)}
									</DropDownMenu>
								) : null}
								<Tooltip
									title={`${templateName.length > 0 ? lang.nameNotUnique : lang.nameNotEmpty}`}
									disabled={isValidName(templateName)}
								>
									<Button
										disabled={!isValidName(templateName)}
										onClick={() => {
											onSave(templateName, saveAsNewRef.current);
											setShowSaveModal(false);
										}}
									>
										{lang.save}
									</Button>
								</Tooltip>
								<ThirdButton onClick={onCancelSave}>{lang.cancel}</ThirdButton>
							</Flex>
						</ModalControls>
					</WhiteModal>
				</OutsideClick>
			) : null}
			{!(options.length <= 1 && selectedValue.id === defaultTemplate?.id) ? (
				<DropDownMenu
					className={classes.elem('options').b()}
					renderTrigger={(isExpanded, setExpanded) => (
						<DropDownButton
							className={classes.elem('options').elem('selectedValue').b()}
							expanded={isExpanded}
							onClick={setExpanded}
							size="sm"
							title={selectedValue?.name}
						/>
					)}
				>
					{close => (
						<DropdownContainer>
							{memoedOptions.map(option => (
								<Flex
									justifyContent="space-between"
									alignItems="center"
									space="plm"
									className={classes
										.elem('options')
										.elem('option')
										.mod({ selected: selectedValue?.id === option.id })
										.b()}
									key={option.id}
									onClick={() => {
										onChange(option);
										close();
									}}
								>
									<EllipsisTooltip title={option.name}>
										<Text>{option.name}</Text>
									</EllipsisTooltip>
									{option?.id !== defaultTemplate.id ? (
										<ThirdButton
											className={classes.elem('editButton').b()}
											icon="edit"
											size="sm"
											onClick={e => {
												close();
												saveAsNewRef.current = false;
												setShowSaveModal(true);
											}}
										></ThirdButton>
									) : null}
								</Flex>
							))}
						</DropdownContainer>
					)}
				</DropDownMenu>
			) : null}
			{/** Show tooltip only if the option to save is to create a new template */}
			<Tooltip
				title={lang.saveLimit}
				disabled={
					!(options.length > templatesLimit && (!hasChanged || selectedValue?.id === defaultTemplate.id))
				}
			>
				<SplitButtonWithActions
					className={classes.elem('saveButton').b()}
					splitDisabled={options.length === 0 || options.length > templatesLimit}
					trigger="click"
					disabled={
						(!hasChanged && selectedValue?.id !== defaultTemplate.id) ||
						(selectedValue?.id === defaultTemplate.id && options.length > templatesLimit)
					}
					actions={[
						{
							id: '1',
							title:
								hasChanged && selectedValue?.id !== defaultTemplate.id
									? lang.update
									: lang.saveTemplate,
							onClick: () => {
								if (selectedValue?.id === defaultTemplate.id) {
									saveAsNewRef.current = true;
									setShowSaveModal(prev => !prev);
									return;
								}
								onSave(selectedValue?.name);
							}
						},
						{
							id: '2',
							title: lang.saveAsNew,
							onClick: () => {
								saveAsNewRef.current = true;
								setShowSaveModal(true);
							}
						}
					]}
				></SplitButtonWithActions>
			</Tooltip>
		</Flex>
	);
};
export default TemplateHeaderSelector;
