import Avatar from 'Components/Avatar';
import React, { useState, useMemo } from 'react';
import Tooltip from './Tooltip';
import getAngularModule from 'App/babel/angularHelpers/getAngularModule';
import logError from 'App/babel/helpers/logError';
import { dateCalendar } from 'App/helpers/DateHelpers';
import { openEditAppointment } from 'Components/Modals/Appointment/EditAppointment';
import { openEditOrder } from 'App/components/EditOrder';
import { useTranslation } from 'react-i18next';

import type Appointment from 'App/resources/Model/Appointment';
import type { AppointmentEvent } from '../types';

type Props = {
	event: AppointmentEvent;
};

function openAppointment(event: React.MouseEvent<HTMLAnchorElement>, customerId: number, appointmentId: number) {
	event.stopPropagation();

	openEditAppointment({ id: appointmentId });
}

function editOpportunity(event: React.MouseEvent<HTMLAnchorElement>, customerId: number, opportunityId: number) {
	event.stopPropagation();

	const options = {
		customerId,
		type: 'opportunity',
		id: opportunityId
	};

	openEditOrder(options);
}

function goToContact(event: React.MouseEvent<HTMLAnchorElement>, customerId: number, contactId: number) {
	event.stopPropagation();

	const $state = getAngularModule('$state');
	$state.go('contact.dashboard', {
		customerId,
		id: contactId
	});
}

function goToCampaign(event: React.MouseEvent<HTMLAnchorElement>, customerId: number, projectId: number) {
	event.stopPropagation();

	const $state = getAngularModule('$state');
	$state.go('campaign.dashboard', {
		customerId,
		id: projectId
	});
}

/**
 * @deprecated
 * This should not be used!
 * Just ported this so I can be certain we get a 1:1 port the Angular edit order modal.
 */
const EventAppointment = ({ event }: Props) => {
	const { t } = useTranslation();
	const [expanded, setExpanded] = useState(false);
	const [loading, setLoading] = useState(false);
	const [appointment, setAppointment] = useState<Appointment | null>(null);

	const { customerId } = useMemo(() => {
		const AppService = getAngularModule('AppService');
		const customerId = AppService.getCustomerId();

		return { customerId };
	}, []);

	function expand() {
		if (expanded || loading) {
			setExpanded(false);
		} else if (appointment) {
			setExpanded(true);
		} else {
			setLoading(true);

			const Appointment = getAngularModule('Appointment');
			Appointment.customer(customerId)
				.get(event.entityId)
				.then(res => {
					if (res.data) {
						setAppointment(res.data);
						setExpanded(true);
					}

					setLoading(false);
				})
				.catch(logError);
		}
	}

	return (
		<td className="clickable">
			<div>
				<table className="details-table" onClick={expand} ng-className="{'no-icon': noIcon !== undefined}">
					<thead>
						<tr>
							<th style={{ width: 40 }}>
								{event?.users?.[0] ? <Avatar user={event.users[0]} size={30} /> : null}
							</th>
							<th colSpan={4}>
								{event.users.length === 0 ? <span>{t('default.someone')}</span> : null}
								{event.users.length === 1 ? <b>{event.users[0].name}</b> : null}
								{event.users.length === 2 ? (
									<span>
										<b>{`${event.users[0].name.split(' ')[0]} `}</b>
										{`${t('default.and').toLowerCase()} `}
										<b>{`${event.users[1].name.split(' ')[0]}`}</b>
									</span>
								) : null}
								{event.users.length === 3 ? (
									<span>
										<b>{`${event.users[0].name.split(' ')[0]}, `}</b>
										<b>{`${event.users[1].name.split(' ')[0]} `}</b>
										{`${t('default.and').toLowerCase()} `}
										<b>{event.users[2].name.split(' ')[0]}</b>
									</span>
								) : null}
								{event.users.length > 3 ? (
									<span>
										<b>{`${event.users[0].name.split(' ')[0]} `}</b>
										{`${t('default.and').toLowerCase()} `}
										<b>{`${event.users.length - 1} ${t('filters.more').toLowerCase()}`}</b>
									</span>
								) : null}
								{event.subType === 'Passed' && event.appointment.outcome === 'completed' ? (
									<span>{`${t('default.completed').toLowerCase()} `}</span>
								) : null}
								{event.subType === 'Passed' && event.appointment.outcome === 'planned' ? (
									<span>{`${t('appointment.hadPlanned').toLowerCase()} `}</span>
								) : null}
								{event.subType === 'Passed' && event.appointment.outcome === 'notCompleted' ? (
									<span>{`${t('appointment.notCompleted').toLowerCase()} `}</span>
								) : null}
								{event.subType === 'Passed' && event.appointment.outcome === null ? (
									<span>{`${t('appointment.hadAnAppointment').toLowerCase()} `}</span>
								) : null}
								{event.subType === 'Created' ? (
									<span>{`${t('default.hasPlannedAppointment').toLowerCase()} `}</span>
								) : null}
								<a
									onClick={clickEvent =>
										openAppointment(clickEvent, customerId, event.appointment.id)
									}
								>
									<i>{event.appointment.description}</i>
								</a>
								{event.contacts?.length ? <span>{` ${t('default.with').toLowerCase()} `}</span> : null}
								{event.contacts?.length === 1 ? (
									<span>
										<a
											onClick={clickEvent =>
												goToContact(clickEvent, customerId, event.contacts![0].id)
											}
										>
											{event.contacts[0].name}
										</a>
									</span>
								) : null}
								{event.contacts?.length === 2 ? (
									<span>
										{` ${t('default.and').toLowerCase()} `}
										<a
											onClick={clickEvent =>
												goToContact(clickEvent, customerId, event.contacts![1].id)
											}
										>
											{event.contacts[1].name}
										</a>
									</span>
								) : null}
								{(event.contacts?.length ?? 0) > 2 ? (
									<span>
										{` ${t('default.and').toLowerCase()} ${event.contacts!.length - 1} ${t(
											'filters.more'
										)}`}
									</span>
								) : null}
								<br />
								<Tooltip title={dateCalendar(event.date!, true, '', true, true) as string}>
									<span className="date">
										{dateCalendar(event.date!, true, '', false, true) as string}
									</span>
								</Tooltip>
								<span> &bull;{` ${t('default.appointment')}`}</span>
							</th>
						</tr>
					</thead>
					<tbody>
						{expanded && appointment ? (
							<tr className="animate-repeat">
								<td colSpan={5} className="no-overflow">
									<b>{appointment.description}</b>
									{appointment.project && appointment.opportunity ? (
										<div>
											{`${t('activity.relatedTo')} ${t('default.theCampaign').toLowerCase()} `}
											<a
												onClick={clickEvent =>
													goToCampaign(clickEvent, customerId, appointment.project!.id)
												}
											>
												{appointment.project.name}
											</a>
											{` ${t('default.and').toLowerCase()} ${t(
												'default.theOpportunity'
											).toLowerCase()} `}
											<a
												onClick={clickEvent =>
													editOpportunity(clickEvent, customerId, appointment.opportunity!.id)
												}
											>
												{appointment.opportunity.description}
											</a>
										</div>
									) : null}
									{appointment.project && !appointment.opportunity ? (
										<div>
											{`${t('activity.relatedTo')} ${t('default.theCampaign').toLowerCase()} `}
											<a
												onClick={clickEvent =>
													goToCampaign(clickEvent, customerId, appointment.project!.id)
												}
											>
												{appointment.project.name}
											</a>
										</div>
									) : null}
									{!appointment.project && appointment.opportunity ? (
										<div>
											{`${t('activity.relatedTo')} ${t('default.theOpportunity').toLowerCase()} `}
											<a
												onClick={clickEvent =>
													editOpportunity(clickEvent, customerId, appointment.opportunity!.id)
												}
											>
												{appointment.opportunity.description}
											</a>
										</div>
									) : null}
									{appointment.notes ? (
										<div style={{ whiteSpace: 'pre-wrap' }}>{appointment.notes}</div>
									) : null}
								</td>
							</tr>
						) : null}
					</tbody>
				</table>
				<div className="details-table-arrow"></div>
			</div>
		</td>
	);
};

export default EventAppointment;
