import Resource from 'Resources/ResourceTyped';
import MailAccountModel, { MailDomain } from './Model/MailAccount';

class MailAccount extends Resource<MailAccountModel> {
	constructor() {
		super('master/mailAccount');
	}

	async get() {
		return this._getRequest('', { methodName: 'get' }).then(r => r.data);
	}

	domains(): Promise<{ data: MailDomain[] }> {
		return this._getRequest('domains', { methodName: 'domains', _url: 'mail' }).then(r => r.data);
	}
}

const resource = new MailAccount();

export default resource;
