import getAngularModule from 'App/babel/angularHelpers/getAngularModule';
import getSpeedTracker from 'App/helpers/speedTracker';
import { useEffect } from 'react';
import { useModalClose } from 'App/components/Modals/Modals';

import type { Reject, EditOrderDraftableStateStore } from '../types';

const useOnModalClose = (store: EditOrderDraftableStateStore, modalId: number, reject: Reject) => {
	useEffect(() => {
		const $rootScope = getAngularModule('$rootScope');

		const unsubscribeHandler = $rootScope.$on('$stateChangeStart', event => {
			const state = store.getState();

			if (state.OrderForm.$dirty) {
				event.preventDefault();
			}

			reject();
		});

		return () => unsubscribeHandler();
	}, []);

	useModalClose(
		modalId,
		event => {
			const state = store.getAutoDraftState();

			const speedTracker = getSpeedTracker('EditOrder');
			speedTracker?.endTracking();

			if (state.tabs.isActive('form') && state.OrderForm.$dirty) {
				event.preventDefault();
				state.showInlineAction = 'bottom';
			} else {
				state.showInlineAction = 'none';
				const skipEvent = true;
				reject(undefined, skipEvent);
			}
		},
		[]
	);
};

export default useOnModalClose;
