import Resource from './Resource';

class ClientIndustry extends Resource {
	constructor() {
		super('clientIndustry');
	}

	getClientIndustryStatistics(clientId: number, group?: string) {
		return this._getRequest(`statistics/${clientId}?group=${group}`).then(response => response.data);
	}

	getCompetitorRuleOfForty(clientId: number, group?: string) {
		return this._getRequest(`competitorRuleOfForty/${clientId}?group=${group}`).then(response => response.data);
	}

	getKeyFigures(clientId: number, group?: string) {
		return this._getRequest(`keyFigures/${clientId}?group=${group}`).then(response => response.data);
	}

	getMarketShareData(clientId: number, group?: string) {
		return this._getRequest(`marketShareData/${clientId}?group=${group}`).then(response => response.data);
	}
}

export default new ClientIndustry();
