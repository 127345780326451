import { Select, SelectAsync, Text, ButtonSelect, Icon, AssistChip, EllipsisTooltip } from '@upsales/components';
import { CancelablePromise, makeCancelable } from 'App/babel/helpers/promise';
import { ProductDescriptionTooltip } from './ProductDescriptionTooltip';
import { SelectCoreProps } from '@upsales/components/Select/Select';
import React, { useEffect, useMemo, useState } from 'react';
import { getProducts } from 'Store/selectors/AppSelectors';
import BemClass from '@upsales/components/Utils/bemClass';
import { OrderRow } from '../../Context/OrderContext';
import Product from 'App/resources/Model/Product';
import T from 'Components/Helpers/translate';
import logError from 'Helpers/logError';

import './ProductCategorySelect.scss';

type Props = {
	className?: string;
	tierObject: { tierNr: number; tier: { start: number; end: number }; last: boolean } | null;
	orderRow: OrderRow;
	useDiscount: boolean;
	productTotal: number;
	setProduct: (product: Product) => void;
	onlyRecurring?: boolean;
	onlyOneOff?: boolean;
	anchor: Element | string | null;
	disabled?: boolean;
	editProductBundle?: (orderRow: OrderRow) => void;
	usePriceLists?: boolean;
	dontFetch?: boolean;
	virtualized?: boolean;
	excludeBundles?: boolean;
	async?: boolean;
	autoFocus?: boolean;
	onBlur?: () => void;
};

const ProductCategorySelect = ({
	className,
	orderRow,
	setProduct,
	tierObject,
	useDiscount,
	productTotal,
	onlyRecurring,
	onlyOneOff,
	anchor,
	disabled,
	editProductBundle,
	usePriceLists = false,
	dontFetch,
	virtualized = false,
	excludeBundles = false,
	autoFocus = false,
	onBlur
}: Props) => {
	const classes = new BemClass('ProductCategorySelect', className);

	const [treeProducts, setTreeProducts] = useState([]);
	const [showProducts, setShowProducts] = useState(true);

	const hasProductBundles = useMemo(
		() =>
			Tools.FeatureHelper.hasSoftDeployAccess('PRODUCT_BUNDLE') &&
			Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.PRODUCT_BUNDLES),
		[]
	);
	const hasArticleNo = useMemo(() => Tools.AppService.getMetadata().standardFields.Product.ArticleNo.active, []);
	const isBundleProduct = !!orderRow.bundleRows?.length;

	const activeProducts = getProducts(true, false, usePriceLists, excludeBundles);
	const products = useMemo(() => {
		let products = activeProducts.map(p => ({ ...p, title: p.name }));
		if (onlyRecurring) {
			products = products.filter(product => product.isRecurring);
		}
		if (onlyOneOff) {
			products = products.filter(product => !product.isRecurring);
		}

		return products;
	}, [activeProducts, onlyRecurring, onlyOneOff]);

	const useAsyncSelect = productTotal > 4000;

	const fetcherTree = async (searchString = '') => {
		const res = await Tools.Product.customer(Tools.AppService.getCustomerId()).getProductTree(
			true,
			searchString,
			orderRow.product?.id,
			usePriceLists,
			excludeBundles
		);
		return res.data;
	};
	const fetcherProducts = async (searchString = '') => {
		const q: { a: string; c: string; v: string | number | boolean | null }[] = [
			{ a: 'name', c: 'wc', v: searchString },
			{ a: 'active', c: 'eq', v: true }
		];
		if (onlyRecurring) {
			q.push({ a: 'isRecurring', c: 'eq', v: 1 });
		}
		if (onlyOneOff) {
			q.push({ a: 'isRecurring', c: 'eq', v: 0 });
		}
		if (excludeBundles) {
			q.push({ a: 'bundlePriceAdjustment', c: 'eq', v: null });
		}
		const res = await Tools.Product.customer(Tools.AppService.getCustomerId()).find({ q, usePriceLists });
		return res.data.map(p => ({ ...p, title: p.name }));
	};

	useEffect(() => {
		let treePromise: CancelablePromise;
		if (!useAsyncSelect && !dontFetch) {
			treePromise = makeCancelable(fetcherTree());
			treePromise.promise
				.then(data => {
					setTreeProducts(data);
				})
				.catch(e => logError(e, 'Could not fetch products'));
		}

		return () => {
			treePromise?.cancel();
		};
	}, []);

	const getTierTitle = () => {
		if (!tierObject) return '';
		const start = tierObject.tier.start;
		const end = tierObject.last ? '∞' : tierObject.tier.end;
		return `${start} - ${end}`;
	};

	const getBundleTitle = () => {
		const bundleProductCount = orderRow.bundleRows?.filter(row => row.product && row.quantity)?.length;
		return `${bundleProductCount} ${bundleProductCount === 1 ? T('default.product') : T('default.products')}`;
	};

	const renderCustomHeader = () => (
		<ButtonSelect
			className={classes.elem('productButtonSelect').b()}
			size="sm"
			value={showProducts}
			onChange={setShowProducts}
			options={[
				{ value: true, title: T('default.product') },
				{ value: false, title: T('default.productCategory') }
			]}
		/>
	);

	const renderSelectedItem = (option: Product | null) => {
		if (!option)
			return (
				<Text italic color="grey-10">
					{T('product.searchProducts')}
				</Text>
			);

		const title = `${option.name}${hasArticleNo && option.articleNo ? ` (${option.articleNo})` : ''}`;
		const nameText = (
			<EllipsisTooltip title={title}>
				<Text>{title}</Text>
			</EllipsisTooltip>
		);

		let selectedItem = (
			<div
				className={classes
					.elem('selectedItem')
					.mod({ hasIcon: option.bundlePriceAdjustment != null })
					.b()}
			>
				{hasProductBundles && option.bundlePriceAdjustment != null ? <Icon name="bundle" /> : null}
				{nameText}
			</div>
		);
		if (option.category) {
			selectedItem = (
				<div
					className={classes
						.elem('selectedItem')
						.mod({ center: true, hasIcon: option.bundlePriceAdjustment != null })
						.b()}
				>
					{hasProductBundles && option.bundlePriceAdjustment != null ? <Icon name="bundle" /> : null}
					<Text color="grey-10" className={classes.elem('selectedItem').elem('category').b()}>
						{option.category.name.toUpperCase()}
					</Text>
					{nameText}
				</div>
			);
		}

		if (option.description) {
			selectedItem = (
				<div className={classes.elem('selectedItemWrapper').b()}>
					<ProductDescriptionTooltip description={option.description} />
					{selectedItem}
				</div>
			);
		}

		return selectedItem;
	};

	const renderItem = (option: {
		title: string;
		articleNo: string | null;
		tiers?: unknown[];
		bundle?: Product['bundle'];
	}) => {
		return (
			<div className={classes.elem('selectOption').b()}>
				<Text>
					{option.title + ' '}
					{option.tiers?.length ? <Icon name="tiers" /> : null}
					{hasProductBundles && option.bundle?.length ? <Icon name="bundle" /> : null}
				</Text>
				{hasArticleNo ? <Text color="grey-7">{option.articleNo ?? ''}</Text> : null}
			</div>
		);
	};

	const generalProps: SelectCoreProps<Product & { title: string }, false> = {
		anchor,
		disabled,
		renderItem,
		renderCustomHeader,
		renderSelectedItem,
		onChange: setProduct,
		extraScrollOffset: 45,
		optionHeaderType: 'disabled',
		autoFocus: autoFocus || orderRow.justAdded,
		dropdownClassName: classes.elem('select-width').b(),
		language: { typeToSearch: T('product.searchProducts') },
		value: orderRow.product?.id ? { ...orderRow.product, title: orderRow.product.name } : null,
		virtualized,
		onBlur,
		ellipsisTooltip: true
	};

	return (
		<div className={classes.mod({ tiered: !!tierObject, useDiscount, bundle: isBundleProduct }).b()}>
			{tierObject ? <AssistChip title={getTierTitle()} /> : null}
			{hasProductBundles && isBundleProduct && editProductBundle ? (
				<AssistChip title={getBundleTitle()} onClick={() => editProductBundle(orderRow)} />
			) : null}
			{useAsyncSelect ? (
				<SelectAsync {...generalProps} fetcher={showProducts ? fetcherProducts : fetcherTree} fetchOnOpen />
			) : (
				<Select {...generalProps} options={showProducts ? products : treeProducts} />
			)}
		</div>
	);
};

export default ProductCategorySelect;
