import React, { useMemo } from 'react';
import { Text, Link, IconName } from '@upsales/components';
import TimelineRow from 'Components/TimelineRow';
import { SubTitle } from '../Helpers';
import { openDrawer } from 'Services/Drawer';
import { AppointmentEvent } from 'App/resources/Model/Event';
import { openEditAppointment } from 'Components/Modals/Appointment/EditAppointment';

type Props = {
	event: AppointmentEvent;
	id: number;
	hideIcon?: boolean;
};

function ActivityEvent({ event, id: appointmentId, hideIcon }: Props) {
	const { data: { activity, appointment, call } = {}, date, user } = event;
	const { $translate: t } = Tools;
	function getEntity() {
		if (activity) {
			return t('entity.activity');
		}
		if (appointment) {
			return t('entity.appointment');
		}
		if (call) {
			return t('entity.call');
		}
		return '';
	}

	async function openEntity(id: number, description: string) {
		if (activity) {
			return Tools.$upModal.open('editActivity', { id });
		}
		if (appointment) {
			return openEditAppointment({ id });
		}
		if (call) {
			return openDrawer('EditPhonecall', { activity: { id, description } });
		}
	}

	const entity = activity || appointment || call;
	const title = useMemo(() => {
		const { id, description } = JSON.parse(entity || '');
		return (
			<Text key="title">
				{user?.name ?? ''} {t('default.regDate').toLowerCase()} {`${getEntity().toLowerCase()} `}
				<Link onClick={() => openEntity(id, description)}>{description}</Link>
				{` ${t('appointment.outcomeLog.fromThisAppointment')}`}
			</Text>
		);
	}, [entity, user]);

	const subTitle = <SubTitle date={date} />;

	return (
		<TimelineRow
			event={event}
			icon={(hideIcon ? '' : activity || call ? 'phone' : 'calendar') as IconName}
			title={title}
			subTitle={subTitle}
		/>
	);
}

export default ActivityEvent;
