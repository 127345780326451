import {
	Card,
	Flex,
	Help,
	Icon,
	Link,
	Table,
	TableColumn,
	TableHeader,
	TableRow,
	Text,
	Tooltip
} from '@upsales/components';
import { renderLoadingTable } from 'App/pages/Reportcenter/ReportcenterWidget/TableWidget/TableWidget';
import { makeCancelable } from '@upsales/components/Utils/CancelablePromise';
import { useAppDispatch, useSoftDeployAccess } from 'App/components/hooks';
import { ClientWithSignals, ContactWithSignals } from 'App/resources/Model/AISignals';
import { openNewMailWithContact } from 'App/helpers/mailHelpers';
import { useTranslation } from 'Components/Helpers/translate';
import { closeAllModals } from 'Store/actions/ModalActions';
import BemClass from '@upsales/components/Utils/bemClass';
import AISignalsResource from 'App/resources/AISignals';
import React, { useEffect, useState } from 'react';
import SignalsBar from '../SignalsBar/SignalsBar';
import logError from 'Helpers/logError';

import './ClientSignals.scss';

type ClientSignalsProps = {
	clientId: number;
	onlyClient?: boolean;
	onContactChange?: (contactId: number) => void;
};

const ContactRow = ({
	contact,
	classes,
	onContactChange
}: {
	contact: ContactWithSignals;
	classes: BemClass;
	onContactChange?: (contactId: number) => void;
}) => {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const hasNewMail = useSoftDeployAccess('NEW_MAIL');

	return (
		<TableRow key={contact.id}>
			<TableColumn>
				<Tooltip title={t('default.viewContactRelationSignals', { name: contact.name })}>
					<Link
						onClick={e => {
							e.preventDefault();
							onContactChange?.(contact.id);
						}}
					>
						{contact.name}
					</Link>
				</Tooltip>
				<Text color="grey-11" size="sm">
					{contact.title}
				</Text>
			</TableColumn>
			<TableColumn>
				<SignalsBar signals={contact.signals} periodText={`(${t('default.lastNMonths', { months: 6 })})`} />
			</TableColumn>
			<TableColumn align="right">
				<Flex gap="u2" justifyContent="flex-end" space="prl">
					<Link
						href={Tools.$state.href('contact.dashboard', {
							id: contact.id,
							customerId: Tools.AppService.getCustomerId()
						})}
						onClick={() => {
							dispatch(closeAllModals());
						}}
					>
						<Tooltip title={t('default.viewContact')}>
							<Icon name="user" color="medium-blue" />
						</Tooltip>
					</Link>
					<Link
						type="phone"
						{...(ReactTemplates.TOOLS.upVoice(
							{ ...contact },
							'contact',
							contact.phone ?? contact.cellPhone ?? ''
						) ?? {})}
					>
						<Icon name="phone" color="medium-blue" />
					</Link>
					{contact.email ? (
						<Icon
							name="email"
							color="medium-blue"
							className={classes.elem('mail').b()}
							onClick={() => {
								if (contact.email) {
									if (hasNewMail) {
										openNewMailWithContact(contact);
									} else {
										Tools.$upModal.open('sendEmail', {
											customerId: contact.client?.id,
											email: contact.email,
											contactId: contact.id
										});
									}
								}
							}}
						/>
					) : null}
					<Link
						target="_blank"
						type="url"
						href={`https://www.linkedin.com/search/results/people/?keywords=${contact.name}%20${
							contact.client?.name ?? ''
						}`}
					>
						<Icon name="linkedin" color="medium-blue" />
					</Link>
				</Flex>
			</TableColumn>
		</TableRow>
	);
};

const ClientSignals = ({ clientId, onlyClient, onContactChange }: ClientSignalsProps) => {
	const { t } = useTranslation();
	const classes = new BemClass('ClientSignals');
	const [loading, setLoading] = useState(true);
	const [clientSignals, setClientSignals] = useState<ClientWithSignals | null>(null);

	useEffect(() => {
		setLoading(true);
		const cancellable = makeCancelable(AISignalsResource.getClientContactsWithSignals(clientId));
		cancellable.promise
			.then(({ data }) => {
				setClientSignals(data);
				setLoading(false);
			})
			.catch(err => {
				logError(err, 'It could not load client signals');
			});

		return () => {
			cancellable?.cancel?.();
		};
	}, [clientId]);

	return (
		<Flex direction="column" gap="u3" space="mll" className={classes.b()}>
			<Flex alignItems="center" gap="u1">
				<Text bold size="xl" loading={loading}>
					{`${t('default.signals.from')} ${clientSignals?.name}`}
				</Text>
				<Help articleId={1543} sidebar />
			</Flex>
			<Flex space="mtl mbl">
				<SignalsBar
					loading={loading}
					signals={clientSignals?.signals}
					periodText={`(${t('default.lastNMonths', { months: 6 })})`}
				/>
			</Flex>
			{onlyClient ? (
				<Flex gap="u3" direction="column">
					<Text bold loading={loading}>
						{t('account.signals.drawer.allContactsWithSignals')}
					</Text>
				</Flex>
			) : null}
			<Card>
				{loading ? (
					renderLoadingTable(classes.elem('loadingTable'), 1)
				) : (
					<Table>
						<TableHeader
							columns={[
								{ title: t('default.contact') },
								{ title: t('default.signals.relationSignals') },
								{ title: t('todoListTable.actions') }
							]}
						></TableHeader>
						{clientSignals?.contacts?.map((contact: any) => (
							<ContactRow
								key={contact.id}
								contact={contact}
								classes={classes}
								onContactChange={onContactChange}
							/>
						))}
					</Table>
				)}
			</Card>
		</Flex>
	);
};

export default ClientSignals;
