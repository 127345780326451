import { type ActivityPriority } from 'App/babel/enum/activity';
import { useTranslation } from 'Components/Helpers/translate';
import React, { useEffect, useRef, useState } from 'react';

import { Flex, Input, Link, NumberInput, Select, Text } from '@upsales/components';
import Block from '@upsales/components/Block/Block';
import ButtonSelect from '@upsales/components/ButtonSelect/ButtonSelect';
import { PrimaryButton, ThirdButton } from '@upsales/components/Buttons';
import Column from '@upsales/components/Column/Column';
import Modal from '@upsales/components/Modal/Modal';
import ModalContent from '@upsales/components/ModalContent/ModalContent';
import ModalControls from '@upsales/components/ModalControls/ModalControls';
import ModalHeader from '@upsales/components/ModalHeader/ModalHeader';
import Row from '@upsales/components/Row/Row';
import BemClass from '@upsales/components/Utils/bemClass';
import { SelectItem } from '@upsales/components/Utils/selectHelpers';
import { CoreSelectItem } from 'App/components/Inputs/Selects/StaticSelect';
import { StaticUserSelect, useUserOptions } from 'App/components/Inputs/Selects/StaticUserSelect/StaticUserSelect';
import { ModalProps } from 'App/components/Modals/Modals';
import { ResourceIdSelect } from 'App/components/ResourceSelect';
import { useActivityTypes, useCustomFields } from 'App/components/hooks/appHooks';
import { TagEntity } from 'App/helpers/actionHelpers';
import { defaultFilterGetter } from 'App/helpers/multiActionRunnerHelpers';
import { CustomFieldWithValue } from 'App/resources/Model/CustomField';
import ProjectResource from 'App/resources/Project';
import NotesWithSignature from 'Components/Inputs/NotesWithSignature/NotesWithSignature';
import ModalTagList from 'Components/Modals/ModalTagList';
import { CampaignIdSelect } from 'Components/PlanPhonecallsDrawer/CampaignSelect';
import { formatDateType, getUsersByTag, prepDates } from 'Helpers/multiModals';
import ComparisonTypes from 'Resources/ComparisonTypes';
import RequestBuilder from 'Resources/RequestBuilder';
import { mapCustomFields, mapProperties, parseCustomFields, parseProperties } from 'Services/ActionProperties';
import { getActiveUsers } from 'Store/selectors/AppSelectors';
import DateTimePicker from '../../Inputs/DateTimeSelect';
import LabeledInput from '../../Inputs/LabeledInput';
import { MultiSelect } from '../../ListView/ListViewRenderHelpers';
import { useSelector, useSoftDeployAccess } from '../../hooks';
import type { PropertyArray, PropertyMap } from '../MultiActionModal';
import { parseDatePropToState } from '../MultiHelpers';
import './MultiUpdateActivity.scss';

type Props = {
	entity: string;
	tagEntity: string;
	extraParams?: any;
	filters: RequestBuilder;
	removeAction?: (actionIndex: number) => void;
	actionIndex: number;
	isTrigger?: boolean;
	isAutomation?: boolean;
	isTodoList?: boolean;
	multiSelect: MultiSelect;
	properties?: PropertyArray;
	onSave?: (props: PropertyArray) => Promise<void>;
} & ModalProps<PropertyArray>;

export type UserData = { id: string | number; name: string };

export const prepTriggerCategories = (dateTypesRaw: {
	[k: string]: { name: string; children: { tag: string; name: string }[] };
}) => {
	return Object.keys(dateTypesRaw).map(key => {
		const val = dateTypesRaw[key];
		return {
			id: key,
			title: val.name,
			children: val.children?.map(child => ({
				id: child.tag,
				title: child.name
			}))
		} as SelectItem;
	});
};

const MultiUpdateActivity = ({ multiSelect, onSave, close, className, ...modalParams }: Props) => {
	const { entity, tagEntity, isTrigger, isAutomation, isTodoList, actionIndex, removeAction } = modalParams;
	const { t } = useTranslation();

	const modalRef = useRef<Element | null>(null);

	useEffect(() => {
		if (!modalRef.current) {
			modalRef.current = document.querySelector('.Modals');
		}
	}, [modalRef.current]);

	const classes = new BemClass('MultiUpdateActivity', className);

	const hasCallLists = useSoftDeployAccess('CALL_LISTS');
	const hasTodosCallLists = useSoftDeployAccess('TODO_LIST') && hasCallLists;
	const hasRemoveActivities = useSoftDeployAccess('REMOVE_ACTIVITIES');

	const meta = {
		activeUsers: getActiveUsers() as UserData[],
		activityTypes: useActivityTypes(),
		activityCustomFields: useCustomFields('activity')
	};

	const { customerId } = useSelector(({ App }) => ({
		customerId: App.customerId
	}));

	const activityTypeOptions = useActivityTypes().map(({ id, name }) => ({ id, title: name }));
	const users = useUserOptions();
	const userTags = getUsersByTag(tagEntity as TagEntity, isAutomation).map(p => ({
		id: p.id,
		title: p.name
	}));

	const [saving, setSaving] = useState(false);
	const [tagsVisible, setTagsVisible] = useState(false);

	const [properties, setProperties] = useState<PropertyMap>({ Priority: null });

	const [selectedUser, setSelectedUser] = useState<CoreSelectItem | null>(null);
	const [selectedActivityType, setSelectedActivityType] = useState<(typeof activityTypeOptions)[number] | null>(null);

	const [availableFields, setAvailableFields] = useState<CustomFieldWithValue[]>([]);
	const [customFields, setCustomFields] = useState<CustomFieldWithValue[]>([]);
	const [customDateTypes, setCustomDateTypes] = useState<{ [key: string]: any }>();

	const [triggerDateGroups, setTriggerDateGroups] = useState<SelectItem[]>([]);
	const [triggerDate, setTriggerDate] = useState<{
		selectItem: SelectItem | null;
		offset: number;
	}>({ selectItem: null, offset: 0 });

	const isEdit = !!modalParams.properties;

	const findSelectedUserOrTag = (value: any) => {
		const user = users.find(user => user.id === value);
		if (user) {
			return user;
		}

		const tag = userTags.find(tag => tag.id === value);
		if (tag) {
			return tag;
		}

		return null;
	};

	useEffect(() => {
		const { customDateTypes, dateTypes, dateTags } = prepDates(entity || tagEntity);
		const newFields = structuredClone(meta.activityCustomFields)
			.filter(field => field.editable)
			.map(field => {
				if (customDateTypes && field.datatype === 'Date') {
					(field as any).customDateSelect = true;
				}
				return field;
			});

		const dateSelectItems = prepTriggerCategories(dateTypes);

		setCustomDateTypes(formatDateType(customDateTypes));
		setTriggerDateGroups(dateSelectItems);

		if (modalParams.properties) {
			const parsedProperties = parseProperties(modalParams.properties);

			for (const [name, value] of Object.entries(parsedProperties)) {
				switch (name) {
					case 'Priority':
						parsedProperties[name] = parseInt(value as string);
						break;

					case 'User':
						setSelectedUser(findSelectedUserOrTag(value));
						break;

					case 'ActivityType':
						setSelectedActivityType(
							activityTypeOptions.find(type => type.id === parseInt(value as string)) ?? null
						);
						break;

					case 'Date':
						setTriggerDate(parseDatePropToState(value as string, dateSelectItems));
						parsedProperties[name] = null;
						break;
				}
			}
			const [customFieldsRes, availableFields] = parseCustomFields(
				newFields,
				parsedProperties,
				'Activity',
				dateTags
			);

			setCustomFields(customFieldsRes);
			setAvailableFields(availableFields);
			setProperties(parsedProperties);
		} else {
			setAvailableFields(newFields as CustomFieldWithValue[]);
		}
	}, []);

	const save = async () => {
		setSaving(true);

		const propArray: PropertyArray = [];

		mapCustomFields(propArray, customFields, 'Activity');
		propArray.push(...mapProperties(properties));

		if (triggerDate.selectItem?.id) {
			propArray.push({
				name: 'Date',
				value: '{{' + triggerDate.selectItem?.id + ':' + (triggerDate.offset || 0) + '}}'
			});
		}

		if (onSave) {
			return onSave(propArray)
				.then(() => {
					close();
				})
				.finally(() => {
					setSaving(false);
				});
		}

		if (isTrigger || !modalParams.filters || !propArray.length) {
			return close(propArray);
		}

		const filters = defaultFilterGetter(new RequestBuilder(), multiSelect);

		Tools.MultiActions.customer(Tools.AppService.getCustomerId()).updateActivity(
			multiSelect.selectedIds.length,
			propArray,
			filters.build()
		);
	};

	const createCampaign = async () => {
		return Tools.$upModal
			.open('editCampaign', { customerId: customerId, noRedirect: true })
			.then(res => setProperties({ ...properties, Project: res.id }));
	};

	const handleCustomFieldChange = (value: {
		id: CustomFieldWithValue['id'];
		title: CustomFieldWithValue['name'];
	}) => {
		const selectedField = availableFields.find(field => field.id === value.id);
		if (selectedField) {
			setCustomFields(prev => [...prev, selectedField]);
		}

		setAvailableFields(prev => prev.filter(field => field.id !== value.id));
	};

	const removeCustomField = (field: CustomFieldWithValue) => {
		if (customDateTypes && field.datatype === 'Date') {
			(field as any).customDateSelect = true;
		}
		setCustomFields(prev => prev.filter(f => f.id !== field.id));
		setAvailableFields(prev => [...prev, field]);
	};

	const handleCustomFieldValueChange = (field: CustomFieldWithValue, value: any) => {
		setCustomFields(
			customFields.map(f => {
				if (f.id === field.id) {
					let newValue = value;
					if (field.datatype === 'Date' && value?.tag === 'RegularDate') {
						(field as any).customDateSelect = false;
						newValue = '';
					}

					return { ...f, value: newValue };
				}
				return f;
			})
		);
	};

	return (
		<Modal size="lg" className={classes.b()}>
			<div className={classes.elem('content').b()}>
				<ModalHeader
					title={
						isTodoList
							? t('todo.multi.updatePhonecallsAndTodos')
							: `${t('default.update')} ${t('default.activity').toLocaleLowerCase()}`
					}
					onClose={() => close()}
					icon="edit"
				>
					{isTrigger ? (
						<button
							type="button"
							id="toggle-tags-btn"
							className="up-btn btn-lined no-shadow btn-bright-blue btn-sm"
							onClick={() => setTagsVisible(!tagsVisible)}
						>
							{t('tag.available')}
						</button>
					) : null}
				</ModalHeader>
				<ModalContent>
					{isTrigger ? (
						<LabeledInput title={t('default.description')} classes="col-span2">
							<Input
								value={properties.Description ?? ''}
								onChange={e => {
									setProperties(prev => ({ ...prev, Description: e.target.value }));
								}}
							/>
						</LabeledInput>
					) : null}

					{!isTrigger ? (
						<DateTimePicker
							label={t('default.date')}
							onUpdate={value => {
								setProperties({ ...properties, Date: value.day, Time: value.time });
							}}
							scrollContainer={modalRef.current}
						></DateTimePicker>
					) : null}

					{isTrigger ? (
						<Row className={classes.elem('trigger-date').b()}>
							<LabeledInput title={t('default.date')}>
								<Select
									options={triggerDateGroups}
									optionHeaderType="disabled"
									value={triggerDate.selectItem}
									onChange={item => {
										setTriggerDate(prev => ({ ...prev, selectItem: item ?? null }));
									}}
									placeholder={`${t('default.select')} ${t('default.date').toLowerCase()}`}
									anchor={modalRef.current}
								/>
							</LabeledInput>
							<LabeledInput title={t('automationAction.DateOffset')}>
								<NumberInput
									value={triggerDate.offset}
									onChange={offset => {
										setTriggerDate(prev => ({ ...prev, offset: offset ?? 0 }));
									}}
								/>
							</LabeledInput>
						</Row>
					) : null}

					{!hasRemoveActivities ? (
						<LabeledInput title={t('default.activityType')}>
							<Select
								options={activityTypeOptions}
								value={selectedActivityType ?? null}
								placeholder={`${t('default.select')} ${t('default.activityType').toLowerCase()}`}
								onChange={value => {
									setSelectedActivityType(value);
									setProperties({ ...properties, ActivityType: value?.id ?? null });
								}}
								anchor={modalRef.current}
								onClear={() => {
									setSelectedActivityType(null);
									setProperties({ ...properties, ActivityType: null });
								}}
							/>
						</LabeledInput>
					) : null}

					<LabeledInput title={t('default.user')}>
						<StaticUserSelect
							extraOptions={userTags}
							value={selectedUser}
							onChange={value => {
								setSelectedUser(value);
								setProperties({ ...properties, User: value?.id ?? null });
							}}
							anchor={modalRef.current}
						/>
					</LabeledInput>

					<LabeledInput
						title={t('default.select') + ' ' + t('default.campaign')}
						topRight={
							<Link
								nostyle={true}
								className={classes.elem('create-campaign').b()}
								onClick={createCampaign}
							>
								{t('default.create')} {t('default.new').toLowerCase()}
							</Link>
						}
					>
						<ResourceIdSelect
							resource={ProjectResource}
							placeholder={t('default.select') + ' ' + t('default.campaign')}
							value={properties.Project ?? null}
							onChange={campaign => {
								setProperties(prev => ({
									...prev,
									Project: campaign
								}));
							}}
							modifyRb={rb => {
								rb.addFilter(ProjectResource.attr.active, ComparisonTypes.Equals, true);
							}}
							anchor={modalRef.current}
						/>
					</LabeledInput>

					{hasTodosCallLists ? (
						<Block space="ptm">
							<CampaignIdSelect
								alwaysShowInput
								isCallList
								value={properties.CallList ?? null}
								onChange={val => {
									setProperties({ ...properties, CallList: val });
								}}
								anchor={modalRef.current}
								withToggle={false}
								title={`${t('default.callList')}`}
							/>
						</Block>
					) : null}

					<LabeledInput title={t('default.priority')} classes="col-span2">
						<ButtonSelect<ActivityPriority | null>
							options={[
								{
									title: t('updateActivityMulti.prio.dontChange'),
									// ButtonSelectOption in ui-components has poor typing. This isn't worth the effort
									value: null as any
								},
								{
									title: t('updateActivityMulti.prio.notPrioritized'),
									value: 0
								},
								{
									title: t('updateActivityMulti.prio.prioritized'),
									value: 3
								}
							]}
							value={properties.Priority ?? null}
							onChange={(val: ActivityPriority | null) => {
								setProperties({ ...properties, Priority: val });
							}}
						/>
					</LabeledInput>

					<Block className="col-span2">
						<NotesWithSignature
							value={properties.Notes ?? ''}
							onChange={value => {
								setProperties({ ...properties, Notes: value });
							}}
						/>
					</Block>

					<Block className="col-span2">
						{customFields.length || availableFields.length ? (
							<legend>
								<span>{t('default.otherInfo')}</span>
							</legend>
						) : null}

						{customFields.length ? (
							<>
								<div className="row">
									{customFields.map((field, index) => (
										<div className="col-md-6" key={field.id}>
											<div className="form-group">
												<Flex justifyContent="space-between">
													<Text className={classes.elem('custom-title').b()} bold>
														{field.name}
														<Text
															size="lg"
															color="red"
															bold={true}
															style={{
																display: field.obligatoryField ? 'inline' : 'none'
															}}
														>
															&bull;
														</Text>
													</Text>
													<ThirdButton
														className={classes.elem('remove-custom').b()}
														icon={'trash'}
														onClick={() => removeCustomField(field)}
														size="sm"
														color="blue"
													/>
												</Flex>
												{customDateTypes &&
												field.datatype === 'Date' &&
												(field as any).customDateSelect ? (
													<ReactTemplates.INPUTS.upSelect
														className={classes
															.elem('custom-field-input', 'form-control')
															.b()}
														getId={customDateTypes.id}
														options={{ data: customDateTypes.data }}
														defaultValue={field.value}
														multiple={false}
														required={false}
														formatResult={customDateTypes.formatResult}
														formatSelection={customDateTypes.formatSelection}
														matcher={customDateTypes.matcher}
														onChange={value =>
															handleCustomFieldValueChange(field, value.target.added)
														}
													/>
												) : (
													<ReactTemplates.INPUTS.customFieldInput
														field={field}
														name={field.name}
														className={classes.elem('custom-field-input').b()}
														multiple={field.datatype === 'Users'}
														onChange={(value: string) =>
															handleCustomFieldValueChange(field, value)
														}
														usenewdate
														useNewTime
														useNumberInput
														useNewCheckbox
														anchor={modalRef.current}
													/>
												)}
											</div>
										</div>
									))}
								</div>
							</>
						) : null}

						{availableFields.length ? (
							<div className="row">
								<div className="col-md-6">
									<Select
										data-testid="Input_field"
										anchor={modalRef.current}
										options={availableFields.map(field => ({
											id: field.id,
											title: field.name
										}))}
										value={null}
										onChange={handleCustomFieldChange}
										disabled={!availableFields.length}
										className={classes.elem('custom-field-select').b()}
										placeholder={t('default.add') + ' ' + t('default.field').toLowerCase()}
									/>
								</div>
							</div>
						) : null}
					</Block>
				</ModalContent>
				<ModalControls>
					{isEdit && removeAction ? (
						<button
							type="button"
							className="up-btn btn-lined btn-grey btn-hover-red no-shadow pull-left ng-scope"
							onClick={() => {
								removeAction(actionIndex);
								close();
							}}
						>
							<b className="fa fa-trash-o"></b>
						</button>
					) : null}
					<Column align="right">
						<PrimaryButton disabled={saving} onClick={() => save()}>
							{t('default.save')}
						</PrimaryButton>
						<ThirdButton onClick={() => close()}>{t('default.cancel')}</ThirdButton>
					</Column>
				</ModalControls>
			</div>
			{isTrigger ? (
				<div className={classes.elem('tags').mod({ translateTagsList: tagsVisible }).b()}>
					<ModalTagList modalParams={modalParams} onClose={() => setTagsVisible(false)} />
				</div>
			) : null}
		</Modal>
	);
};

export default MultiUpdateActivity;
