import AISignals, { ClientWithSignals, ContactWithSignals } from './Model/AISignals';
import { Metadata } from 'Resources/ResourceTyped';
import Resource from 'Resources/Resource';

type Response<GetType> = {
	data: GetType;
	metadata?: Metadata;
	error?: Error;
};

class AISignalsResource extends Resource {
	eventName: string = 'aiSignals';
	constructor() {
		super('ai/signals');
	}

	getEntityTotalSignals(entity: 'client' | 'contact', id: number) {
		return this._getRequest(`${entity}/${id}/count`) as Promise<Response<AISignals>>;
	}

	getClientContactsWithSignals(clientId: number) {
		return this._getRequest(`client/${clientId}`) as Promise<Response<ClientWithSignals>>;
	}

	getEmailSignals(contactId: number) {
		return this._getRequest(`contact/${contactId}`) as Promise<Response<ContactWithSignals>>;
	}
}

const resource = new AISignalsResource();

export default resource;
