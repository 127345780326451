import EditOrder from './components/EditOrder';
import EditOrderProvider from './Provider';
import React, { useCallback, useEffect, useState } from 'react';
import getAngularModule from 'App/babel/angularHelpers/getAngularModule';
import getMeta from './getMeta';
import { asyncModalAdapter } from 'App/helpers/angularPortingHelpers';

import type Order from 'App/resources/Model/Order';
import type { ModalProps } from 'App/components/Modals/Modals';
import type { ModalParams, Meta } from './types';

import './EditOrder.scss';

enum PromiseType {
	Resolve,
	Reject
}

// Kinda scuffed, but this is the correct typing as I had to use customOnCloseResolver to simulate the behaviour of the Angular code
type OnCloseReturnType = [PromiseType.Resolve, Partial<Order> | void] | [PromiseType.Reject, unknown | void];
type Props = ModalParams & ModalProps<OnCloseReturnType>;

const EditOrderWrap = ({ className, modalId, close, ...modalParams }: Props) => {
	const resolve = useCallback((result?: Partial<Order>) => close([PromiseType.Resolve, result]), []);
	const reject = useCallback(
		(error?: unknown, skipEvent?: boolean) => close([PromiseType.Reject, error], skipEvent),
		[]
	);
	const [meta, setMeta] = useState<Meta | null>(null);

	useEffect(() => {
		getMeta(modalParams).then(setMeta).catch(reject);
	}, []);

	if (meta === null) {
		return null;
	}

	return (
		<EditOrderProvider modalId={modalId} modalParams={modalParams} meta={meta} reject={reject} resolve={resolve}>
			<EditOrder modalId={modalId} className={className} />
		</EditOrderProvider>
	);
};

export default EditOrderWrap;

export const openEditOrder = asyncModalAdapter({
	featureFlag: 'EDIT_ORDER_REACT',
	openModalName: 'EditOrder',
	upModalName: 'editOrder',
	rejectOnEmpty: false,
	rejectOnEvent: false,
	extraCondition: () => {
		const AppService = getAngularModule('AppService');
		const FeatureHelper = getAngularModule('FeatureHelper');

		const customerHasScript =
			FeatureHelper.isAvailable(FeatureHelper.Feature.UI_SCRIPTS) &&
			AppService.getScripts().some(script => script.active && ['order_edit', 'order_save'].includes(script.type));
		return !customerHasScript;
	},
	customOnCloseResolver: ([resolveType, resolveValue], resolve, reject) => {
		if (resolveType === PromiseType.Resolve) {
			resolve(resolveValue);
		} else {
			reject(resolveValue);
		}
	}
});
