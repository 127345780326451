import CampaignInput from './CampaignInput';
import CustomFieldInput from './CustomFieldInput';
import DateInput from './DateInput';
import InvoiceRelatedClient from 'App/components/InvoiceRelatedClient';
import NumberInput from './NumberInput';
import OrderRow from './OrderRow';
import OrderRows from './OrderRows';
import React, { useMemo, useRef, useState, useEffect } from 'react';
import Select from './Select';
import TextArea from './TextArea';
import TextInput from './TextInput';
import Tooltip from './Tooltip';
import useEditOrderContext from '../hooks/useEditOrderContext';
import { UpdateEvent } from '../hooks/useInputController';
import { currencyFormat, numberFormat } from 'Components/Filters/Currencies';
import { getCalculatingFieldTooltip } from 'App/helpers/formulaText';
import { sortBy } from 'lodash';
import { useTranslation } from 'react-i18next';
// @ts-ignore
import classNames from 'classnames';

import type { RecurringInterval, ClientConnection } from '../types';
import type PriceList from 'App/resources/Model/PriceList';
import type SalesCoach from 'App/resources/Model/SalesCoach';
import type Stage from 'App/resources/Model/OrderStage';
import type { Currency } from 'App/resources/AllIWant';

const OrderForm = () => {
	const EditOrder = useEditOrderContext();
	const { t } = useTranslation();
	const notesRef = useRef<HTMLTextAreaElement>(null);
	const [notesStyle, setNotesStyle] = useState<{ height?: string }>({});

	useEffect(() => {
		if (notesRef.current) {
			const element = notesRef.current;
			const outerHeight = element.getBoundingClientRect().height;

			if (element.scrollHeight > outerHeight) {
				setNotesStyle({ height: `${element.scrollHeight}px` });
			}
		}
	}, []);

	const sortedCustomFields = useMemo(() => sortBy(EditOrder.order.custom, 'sortId'), [EditOrder.order.custom]);
	const sortedOrderRows = useMemo(() => sortBy(EditOrder.order.orderRow, 'sortId'), [EditOrder.order.orderRow]);

	return (
		<div key="orderForm" className="row-wrapper">
			<div id="select-account-cover" className="hide-when-account">
				<b className="fa fa-long-arrow-left"></b> <i>{t('default.chooseAnAccount')}</i>
			</div>

			{EditOrder.isVisibleField('description') ? (
				<div className="row">
					<div className="col-sm-12">
						{/* DESCRIPTION */}
						<div
							id="form-description"
							className={classNames('form-group', {
								'has-error':
									EditOrder.OrderForm.description?.$invalid &&
									(EditOrder.OrderForm.description?.$dirty || EditOrder.OrderForm.$submitted),
								'field-sync-disabled':
									EditOrder.highlightedFields.length > 0 &&
									!EditOrder.highlightedFields.includes('description')
							})}
						>
							<div className="form-input-sync-header">
								<label htmlFor="description">{t('default.description')}</label>
								{EditOrder.requiredFields.Description ? <b className="text-danger">*</b> : null}
								{EditOrder.fieldsSyncDir.description ? (
									<div className="form-input-sync-direction">
										{EditOrder.fieldsSyncDir.description === 'import' ? (
											<Tooltip title={t('integration.onlySyncedToUpsales')}>
												<b className="fa fa-long-arrow-left"></b>
											</Tooltip>
										) : null}
										{EditOrder.fieldsSyncDir.description === 'export' ? (
											<Tooltip title={EditOrder.syncedToApp} dynamic={true}>
												<b className="fa fa-long-arrow-right"></b>
											</Tooltip>
										) : null}
										{EditOrder.fieldsSyncDir.description === 'both' ? (
											<Tooltip title={t('integration.twoWaySync')}>
												<b className="fa fa-exchange"></b>
											</Tooltip>
										) : null}
									</div>
								) : null}
							</div>
							<Tooltip title={EditOrder.fieldsDescription.description} dynamic={true}>
								<div>
									<TextInput
										autoComplete="description-field"
										id="description"
										name="description"
										className={classNames('form-control', {
											'field-sync-highlight': EditOrder.highlightedFields.includes('description')
										})}
										value={EditOrder.order.description}
										onChange={EditOrder.descriptionChanged}
										placeholder={`${t('default.enter')} ${t('default.description').toLowerCase()}`}
										disabled={EditOrder.isDisabledField('description', !EditOrder.editable)}
										maxLength={130}
										required={EditOrder.requiredFields.Description}
										tabIndex={3}
										data-test-id="order-description"
										controllerOptions={
											EditOrder.hasUpdateCustomFieldsOnBlur
												? { updateOn: UpdateEvent.Blur }
												: {
														debounce: {
															change: EditOrder.inputDebounceSetting || 500,
															blur: 0
														}
												  }
										}
									/>
								</div>
							</Tooltip>
						</div>
					</div>
				</div>
			) : null}

			<div className="row">
				{EditOrder.isVisibleField('date') ? (
					<div className="col-sm-6">
						{/* DATE */}
						<div
							id="form-closeDate"
							className={classNames('form-group', {
								'has-error':
									EditOrder.OrderForm.closeDate?.$invalid &&
									(EditOrder.OrderForm.closeDate?.$dirty || EditOrder.OrderForm.$submitted),
								'field-sync-disabled':
									EditOrder.highlightedFields.length > 0 &&
									!EditOrder.highlightedFields.includes('closeDate')
							})}
						>
							<div className="form-input-sync-header">
								<label htmlFor="closeDate">{t('order.date')}</label>
								{EditOrder.fieldsSyncDir.closeDate ? (
									<div className="form-input-sync-direction">
										{EditOrder.fieldsSyncDir.closeDate === 'import' ? (
											<Tooltip title={t('integration.onlySyncedToUpsales')}>
												<b className="fa fa-long-arrow-left"></b>
											</Tooltip>
										) : null}
										{EditOrder.fieldsSyncDir.closeDate === 'export' ? (
											<Tooltip title={EditOrder.syncedToApp} dynamic={true}>
												<b className="fa fa-long-arrow-right"></b>
											</Tooltip>
										) : null}
										{EditOrder.fieldsSyncDir.closeDate === 'both' ? (
											<Tooltip title={t('integration.twoWaySync')}>
												<b className="fa fa-exchange"></b>
											</Tooltip>
										) : null}
									</div>
								) : null}
							</div>
							<div>
								<Tooltip title={EditOrder.fieldsDescription.closeDate} dynamic={true}>
									<div>
										<DateInput
											className={classNames({
												'field-sync-highlight':
													EditOrder.highlightedFields.includes('closeDate')
											})}
											closeOnSelect={true}
											disabled={EditOrder.isDisabledField('date', !EditOrder.editable)}
											icon={'calendar'}
											id="closeDate"
											name="closeDate"
											onChange={EditOrder.dateChanged}
											required={true}
											tabIndex={4}
											value={EditOrder.order.date}
										/>
									</div>
								</Tooltip>
							</div>
						</div>
					</div>
				) : null}

				<div className="col-sm-6">
					<div className="row">
						{EditOrder.isVisibleField('stage', EditOrder.stages.length > 1) ? (
							<div
								className={classNames({
									'col-sm-12': EditOrder.type === 'order' || EditOrder.hideProbability,
									'col-sm-8': EditOrder.type !== 'order' && !EditOrder.hideProbability
								})}
							>
								{/* STAGE */}
								<div
									id="form-stage"
									className={classNames('form-group', {
										'has-error':
											EditOrder.OrderForm.stage?.$invalid &&
											(EditOrder.OrderForm.stage?.$dirty || EditOrder.OrderForm.$submitted),
										'field-sync-disabled':
											EditOrder.highlightedFields.length > 0 &&
											!EditOrder.highlightedFields.includes('stage')
									})}
								>
									<div className="form-input-sync-header">
										<label htmlFor="stage">{t('default.stage')}</label>
										{EditOrder.fieldsSyncDir.stage ? (
											<div className="form-input-sync-direction">
												{EditOrder.fieldsSyncDir.stage === 'import' ? (
													<Tooltip title={t('integration.onlySyncedToUpsales')}>
														<b className="fa fa-long-arrow-left"></b>
													</Tooltip>
												) : null}

												{EditOrder.fieldsSyncDir.stage === 'export' ? (
													<Tooltip title={EditOrder.syncedToApp} dynamic={true}>
														<b className="fa fa-long-arrow-right"></b>
													</Tooltip>
												) : null}
												{EditOrder.fieldsSyncDir.stage === 'both' ? (
													<Tooltip title={t('integration.twoWaySync')}>
														<b className="fa fa-exchange"></b>
													</Tooltip>
												) : null}
											</div>
										) : null}
									</div>
									<Tooltip title={EditOrder.fieldsDescription.stage} dynamic={true}>
										<div>
											<Select<Stage>
												id="select2-form-stage"
												className={classNames('form-control', {
													'field-sync-highlight':
														EditOrder.highlightedFields.includes('stage')
												})}
												required={true}
												value={EditOrder.order.stage}
												onChange={EditOrder.stageSelectChanged}
												options={EditOrder.select.stage}
												placeholder={`${t('default.select')} ${t(
													'default.stage'
												).toLowerCase()}`}
												disabled={EditOrder.isDisabledField('stage', !EditOrder.editable)}
												name="stage"
												tabIndex={5}
											/>
										</div>
									</Tooltip>
								</div>
							</div>
						) : null}

						{/* Don't show probability on order */}
						{EditOrder.isVisibleField(
							'probability',
							EditOrder.type !== 'order' && !EditOrder.hideProbability
						) ? (
							<div className="col-sm-4">
								{/* PROBABILITY */}
								<div
									className={classNames('form-group', {
										'has-error':
											EditOrder.OrderForm.probability?.$invalid &&
											(EditOrder.OrderForm.probability?.$dirty || EditOrder.OrderForm.$submitted),
										'field-sync-disabled':
											EditOrder.highlightedFields.length > 0 &&
											!EditOrder.highlightedFields.includes('probability')
									})}
									id="form-probability"
								>
									<div className="form-input-sync-header">
										<label htmlFor="probability">{t('default.probability')}</label>
										{EditOrder.fieldsSyncDir.probability ? (
											<div className="form-input-sync-direction">
												{EditOrder.fieldsSyncDir.probability === 'import' ? (
													<Tooltip title={t('integration.onlySyncedToUpsales')}>
														<b className="fa fa-long-arrow-left"></b>
													</Tooltip>
												) : null}
												{EditOrder.fieldsSyncDir.probability === 'export' ? (
													<Tooltip title={EditOrder.syncedToApp} dynamic={true}>
														<b className="fa fa-long-arrow-right"></b>
													</Tooltip>
												) : null}
												{EditOrder.fieldsSyncDir.probability === 'both' ? (
													<Tooltip title={t('integration.twoWaySync')}>
														<b className="fa fa-exchange"></b>
													</Tooltip>
												) : null}
											</div>
										) : null}
									</div>
									<Tooltip title={EditOrder.fieldsDescription.probability} dynamic={true}>
										<div>
											<NumberInput
												autoComplete="field-probability"
												className={classNames('form-control', {
													'field-sync-highlight':
														EditOrder.highlightedFields.includes('probability')
												})}
												disabled={EditOrder.isDisabledField('probability', !EditOrder.editable)}
												id="field_probability"
												max="100"
												min="0"
												name="probability"
												onChange={EditOrder.probabilityChanged}
												placeholder="0-100"
												required={true}
												tabIndex={6}
												type="number"
												value={EditOrder.order.probability}
											/>
										</div>
									</Tooltip>
								</div>
							</div>
						) : null}
					</div>
				</div>
			</div>

			<div className="row">
				{EditOrder.isVisibleField('clientConnection', EditOrder.hasClientRelations()) ? (
					<div className="col-sm-6">
						{/* ACCOUNT2 */}
						<div id="form-relation">
							<div
								className={classNames('form-group', {
									'field-sync-disabled':
										EditOrder.highlightedFields.length > 0 &&
										!EditOrder.highlightedFields.includes('relation')
								})}
							>
								<div className="form-input-sync-header">
									<label htmlFor="field_client_2">{EditOrder.clientOrderRelationTitle}</label>
									<Tooltip title={t('order.relatedClientInfo')} placement="right">
										<b className="fa fa-info-circle"></b>
									</Tooltip>
									{EditOrder.editable && EditOrder.order.client ? (
										<Tooltip
											title={`${t('client.createRelationDescription')} ${
												EditOrder.order.client.name
											}`}
											placement="right"
											dynamic={true}
										>
											<a className="pull-right" onClick={EditOrder.addRelation}>
												{t('default.new')} {t('default.relation').toLowerCase()}
											</a>
										</Tooltip>
									) : null}
									{EditOrder.fieldsSyncDir.relation ? (
										<div className="form-input-sync-direction">
											{EditOrder.fieldsSyncDir.relation === 'import' ? (
												<Tooltip title={t('integration.onlySyncedToUpsales')}>
													<b className="fa fa-long-arrow-left"></b>
												</Tooltip>
											) : null}
											{EditOrder.fieldsSyncDir.relation === 'export' ? (
												<Tooltip title={EditOrder.syncedToApp} dynamic={true}>
													<b className="fa fa-long-arrow-right"></b>
												</Tooltip>
											) : null}
											{EditOrder.fieldsSyncDir.relation === 'both' ? (
												<Tooltip title={t('integration.twoWaySync')}>
													<b className="fa fa-exchange"></b>
												</Tooltip>
											) : null}
										</div>
									) : null}
								</div>
								<Tooltip title={EditOrder.fieldsDescription.relation} dynamic={true}>
									<div>
										<Select<ClientConnection | undefined, ClientConnection>
											id="field_client_2"
											autoComplete="field-client-2"
											name="field_client_2"
											className={classNames('form-control', {
												'field-sync-highlight': EditOrder.highlightedFields.includes('relation')
											})}
											value={EditOrder.order.clientConnection}
											onChange={EditOrder.relatedAccountChange}
											options={EditOrder.select.relatedClients}
											placeholder={`${t('default.select')} ${t(
												'default.clientConnection'
											).toLowerCase()}`}
											disabled={EditOrder.isDisabledField(
												'clientconnection',
												!EditOrder.editable ||
													!EditOrder.order.client ||
													((!EditOrder.relatedClients || !EditOrder.relatedClients.length) &&
														!EditOrder.order.clientConnection)
											)}
											tabIndex={7}
										/>
										<div>
											<InvoiceRelatedClient
												disabled={!EditOrder.order.clientConnection}
												checked={EditOrder.order.invoiceRelatedClient}
												onChange={EditOrder.changeInvoiceRelatedClient}
												orderModal={true}
												clientRelated={!EditOrder.order.clientConnection}
											/>
										</div>
									</div>
								</Tooltip>
							</div>
						</div>
					</div>
				) : null}

				<div className="col-sm-6">
					{/* CAMPAIGN */}
					{EditOrder.isVisibleField('project') ? (
						<div
							id="form-campaign"
							className={classNames('form-group', {
								'has-error':
									EditOrder.OrderForm.campaign?.$invalid &&
									(EditOrder.OrderForm.campaign?.$dirty || EditOrder.OrderForm.$submitted),
								'field-sync-disabled':
									EditOrder.highlightedFields.length > 0 &&
									!EditOrder.highlightedFields.includes('campaign')
							})}
						>
							<div className="form-input-sync-header">
								<label htmlFor="campaign">{t('default.campaign')}</label>
								{EditOrder.requiredFields.Project ? <b className="text-danger">*</b> : null}
								{EditOrder.fieldsSyncDir.campaign ? (
									<div className="form-input-sync-direction">
										{EditOrder.fieldsSyncDir.campaign === 'import' ? (
											<Tooltip title={t('integration.onlySyncedToUpsales')}>
												<b className="fa fa-long-arrow-left"></b>
											</Tooltip>
										) : null}
										{EditOrder.fieldsSyncDir.campaign === 'export' ? (
											<Tooltip title={EditOrder.syncedToApp} dynamic={true}>
												<b className="fa fa-long-arrow-right"></b>
											</Tooltip>
										) : null}
										{EditOrder.fieldsSyncDir.campaign === 'both' ? (
											<Tooltip title={t('integration.twoWaySync')}>
												<b className="fa fa-exchange"></b>
											</Tooltip>
										) : null}
									</div>
								) : null}
							</div>
							<Tooltip title={EditOrder.fieldsDescription.campaign} dynamic={true}>
								<div>
									<CampaignInput
										id="campaign-select"
										autoComplete="campaign-field"
										name="campaign"
										value={EditOrder.order.project}
										onChange={EditOrder.projectChanged}
										className={classNames('form-control', {
											'field-sync-highlight': EditOrder.highlightedFields.includes('campaign')
										})}
										placeholder={`${t('default.select')} ${t('default.campaign').toLowerCase()}`}
										required={EditOrder.requiredFields.Project}
										linked={true}
										tabIndex={8}
										disabled={EditOrder.isDisabledField(
											'project',
											!EditOrder.editable || EditOrder.saving
										)}
									/>
								</div>
							</Tooltip>
						</div>
					) : null}
				</div>

				{EditOrder.isVisibleField(
					'recurringinterval',
					EditOrder.hasRecurringInterval && EditOrder.recurringInterval % 1 === 0
				) ? (
					<div className="col-sm-6">
						{/* RECURRING */}
						<div
							className={classNames('form-group', {
								'field-sync-disabled':
									EditOrder.highlightedFields.length > 0 &&
									!EditOrder.highlightedFields.includes('recurringInterval')
							})}
						>
							<div className="form-input-sync-header">
								<label>{t('admin.products.recurringInterval')}</label>
								<Tooltip title={t('order.intervalToolTip')} placement="right">
									<b className="fa fa-info-circle"></b>
								</Tooltip>
								{EditOrder.fieldsSyncDir.recurringInterval ? (
									<div className="form-input-sync-direction">
										{EditOrder.fieldsSyncDir.recurringInterval === 'import' ? (
											<Tooltip title={t('integration.onlySyncedToUpsales')}>
												<b className="fa fa-long-arrow-left"></b>
											</Tooltip>
										) : null}
										{EditOrder.fieldsSyncDir.recurringInterval === 'export' ? (
											<Tooltip title={EditOrder.syncedToApp} dynamic={true}>
												<b className="fa fa-long-arrow-right"></b>
											</Tooltip>
										) : null}
										{EditOrder.fieldsSyncDir.recurringInterval === 'both' ? (
											<Tooltip title={t('integration.twoWaySync')}>
												<b className="fa fa-exchange"></b>
											</Tooltip>
										) : null}
									</div>
								) : null}
							</div>
							<Tooltip title={EditOrder.fieldsDescription.recurringInterval} dynamic={true}>
								<div>
									<Select<number, RecurringInterval>
										className={classNames('form-control', {
											'field-sync-highlight':
												EditOrder.highlightedFields.includes('recurringInterval')
										})}
										value={EditOrder.recurringInterval}
										required={true}
										onChange={EditOrder.intervalChanged}
										options={EditOrder.select.recurringInterval}
										disabled={EditOrder.isDisabledField(
											'recurringinterval',
											!EditOrder.editable || EditOrder.saving
										)}
										tabIndex={9}
										storeIdInState={true}
									/>
								</div>
							</Tooltip>
						</div>
					</div>
				) : null}

				{/* CURRENCY PICKER (only if new && multiple currencies) */}

				{EditOrder.isVisibleField('currency', EditOrder.hasCurrencyPicker()) ? (
					<div className="col-sm-6">
						<div
							id="form-currency"
							className={classNames('form-group', {
								'field-sync-disabled':
									EditOrder.highlightedFields.length > 0 &&
									!EditOrder.highlightedFields.includes('currency')
							})}
						>
							<div className="form-input-sync-header">
								<label htmlFor="field_currency">{t('default.currency')}</label>
								{EditOrder.fieldsSyncDir.currency ? (
									<div className="form-input-sync-direction">
										{EditOrder.fieldsSyncDir.currency === 'import' ? (
											<Tooltip title={t('integration.onlySyncedToUpsales')}>
												<b className="fa fa-long-arrow-left"></b>
											</Tooltip>
										) : null}
										{EditOrder.fieldsSyncDir.currency === 'export' ? (
											<Tooltip title={EditOrder.syncedToApp} dynamic={true}>
												<b className="fa fa-long-arrow-right"></b>
											</Tooltip>
										) : null}
										{EditOrder.fieldsSyncDir.currency === 'both' ? (
											<Tooltip title={t('integration.twoWaySync')}>
												<b className="fa fa-exchange"></b>
											</Tooltip>
										) : null}
									</div>
								) : null}
							</div>
							<Tooltip title={EditOrder.fieldsDescription.currency} dynamic={true}>
								<div>
									<Select<Currency>
										autoComplete="field-currency"
										className={classNames('form-control', {
											'field-sync-highlight': EditOrder.highlightedFields.includes('currency')
										})}
										disabled={EditOrder.isDisabledField(
											'selectedcurrency',
											EditOrder.saving || EditOrder.isOrder || !EditOrder.editable
										)}
										id="field_currency"
										name="field_currency"
										onChange={EditOrder.changeCurrency}
										options={EditOrder.select.currency}
										placeholder={`${t('default.select')} ${t('default.currency').toLowerCase()}`}
										required={true}
										tabIndex={10}
										value={EditOrder.selectedCurrency}
									/>
								</div>
							</Tooltip>
						</div>
					</div>
				) : null}

				{/* SALES COACH  */}
				{EditOrder.isVisibleField('salescoach', EditOrder.hasSalesCoach) ? (
					<div className="col-sm-6">
						<div
							className={classNames('form-group', {
								'field-sync-disabled':
									EditOrder.highlightedFields.length > 0 &&
									!EditOrder.highlightedFields.includes('salesCoach')
							})}
						>
							<label>
								{t('admin.salesProcess')} <b className="text-danger">*</b>
							</label>
							<div
								className={classNames('input-group', {
									'has-error':
										EditOrder.OrderForm.salesCoaches?.$invalid &&
										(EditOrder.OrderForm.salesCoaches?.$dirty || EditOrder.OrderForm.$submitted)
								})}
							>
								<span className="input-group-addon">
									<span className="fa fa-columns"></span>
								</span>
								<Select<SalesCoach | null, SalesCoach>
									className={classNames('form-control expand align-left', {
										'field-sync-highlight': EditOrder.highlightedFields.includes('salesCoach')
									})}
									disabled={EditOrder.isDisabledField('salescoach', !EditOrder.editable)}
									id="salesCoaches-select"
									name="salesCoaches"
									onChange={EditOrder.salesCoachChanged}
									options={EditOrder.select.salesCoaches}
									placeholder={t('order.selectProcess')}
									required={true}
									tabIndex={11}
									value={EditOrder.order.salesCoach}
								/>
							</div>
						</div>
					</div>
				) : null}

				{/* MARKET CONTRIBUTION  */}
				{EditOrder.isVisibleField('marketingContribution', EditOrder.hasMarketContribution) ? (
					<div className="col-sm-6">
						<div
							className={classNames('form-group', {
								'field-sync-disabled':
									EditOrder.highlightedFields.length > 0 &&
									!EditOrder.highlightedFields.includes('marketingContribution')
							})}
						>
							<div>
								<label>{t('order.marketingContribution')}</label>
							</div>
							<div>
								<div className="ButtonSelect ButtonSelect--md">
									<button
										className={classNames('Button up-btn btn-md', {
											'btn-light-grey no-shadow': EditOrder.order.marketingContribution !== 1,
											'btn-white': EditOrder.order.marketingContribution === 1
										})}
										type="button"
										onClick={() => EditOrder.marketingContributionChanged(1)}
										disabled={EditOrder.isDisabledField(
											'marketingContribution',
											!EditOrder.editable
										)}
									>
										<div
											className={classNames('Text Text--md Text--inherit', {
												'Text--bold': EditOrder.order.marketingContribution === 1
											})}
										>
											{t('default.yes')}
										</div>
									</button>
									<button
										className={classNames('Button up-btn btn-md', {
											'btn-light-grey no-shadow ': EditOrder.order.marketingContribution !== 0,
											'btn-white': EditOrder.order.marketingContribution === 0
										})}
										type="button"
										onClick={() => EditOrder.marketingContributionChanged(0)}
										disabled={EditOrder.isDisabledField(
											'marketingcontribution',
											!EditOrder.editable
										)}
									>
										<div
											className={classNames('Text Text--md Text--inherit', {
												'Text--bold': EditOrder.order.marketingContribution === 0
											})}
										>
											{t('default.no')}
										</div>
									</button>
								</div>
							</div>
						</div>
					</div>
				) : null}

				{/* PRICE LIST  */}
				{EditOrder.hasPriceLists ? (
					<div className="col-sm-6">
						<div
							className={classNames('form-group', {
								'field-sync-disabled':
									EditOrder.highlightedFields.length > 0 &&
									!EditOrder.highlightedFields.includes('priceLists')
							})}
						>
							<label>{t('default.priceList')}</label>
							<div>
								<Select<PriceList>
									id="select-priceLists"
									name="priceLists"
									required={true}
									options={EditOrder.select.priceLists}
									value={EditOrder.priceList}
									className={classNames('form-control', {
										'field-sync-highlight': EditOrder.highlightedFields.includes('priceLists')
									})}
									onChange={EditOrder.changePriceList}
									disabled={EditOrder.saving || EditOrder.isOrder || !EditOrder.editable}
									tabIndex={12}
								/>
							</div>
						</div>
					</div>
				) : null}
			</div>

			<div className="modal-section-divider no-margin-bottom">
				<h3>{t('default.orderrows')}</h3>
				{EditOrder.hasPeriodization ? (
					<button
						type="button"
						className="btn up-btn btn-sm btn-green btn-lined no-shadow pull-right"
						onClick={() =>
							EditOrder.togglePeriodization(
								EditOrder.OrderForm.$dirty || EditOrder.OrderForm.$invalid || !EditOrder.order.id
							)
						}
						disabled={EditOrder.saving || !EditOrder.editable}
						id="form-periodise"
						tabIndex={-1}
					>
						{t(
							EditOrder.OrderForm.$dirty || EditOrder.OrderForm.$invalid || !EditOrder.order.id
								? 'default.saveBeforePeriodizing'
								: EditOrder.isPeriodized()
								? 'default.periodiseOrderEdit'
								: 'default.periodiseOrder'
						)}
					</button>
				) : null}
			</div>

			{/* ORDER ROWS */}

			<div className="order-rows">
				<table className="main-table">
					<thead>
						<tr>
							<th>
								<div className="row">
									<div
										className={classNames('product', {
											'col-sm-4': EditOrder.useDiscount && EditOrder.hasContributionMargin,
											'col-sm-6': !EditOrder.useDiscount && EditOrder.hasContributionMargin,
											'col-sm-5': EditOrder.useDiscount && !EditOrder.hasContributionMargin,
											'col-sm-7': !EditOrder.useDiscount && !EditOrder.hasContributionMargin
										})}
									>
										{EditOrder.isVisibleField('orderrow.product') ? (
											<span>
												{t('default.product')} <b className="text-danger">*</b>
											</span>
										) : null}
									</div>
									<div className="col-sm-2 price">{EditOrder.quantityPriceTitle()}</div>
									{EditOrder.useDiscount ? (
										<div className="col-sm-2 discount">{EditOrder.discountTitle()}</div>
									) : null}
									<div
										className={classNames('total col-sm-3', {
											'col-sm-4': EditOrder.hasContributionMargin
										})}
									>
										{t('default.sum')}
									</div>
								</div>
							</th>
						</tr>
					</thead>
					<tbody>
						<OrderRows>
							{sortedOrderRows.map((orderRow, index) => {
								const rowEditable = !EditOrder.isDisabledField(
									'orderrow.all',
									!EditOrder.editable,
									'orderrow@' + orderRow.id + '.all'
								);
								const isProductBundle =
									EditOrder.hasProductBundles && orderRow.product?.bundle?.length ? true : false;
								const hasCustom =
									EditOrder.orderRowCustomFieldsLength && EditOrder.isAvailable.orderRowCustom
										? true
										: false;

								return (
									<OrderRow
										OrderForm={EditOrder.OrderForm}
										copyRow={EditOrder.copyOrderRow}
										currency={EditOrder.order.currency}
										customFieldChange={EditOrder.orderRowCustomFieldChange}
										decimalsPrice={EditOrder.numOfDecimalsPrice}
										deleteRow={EditOrder.deleteOrderRow}
										discountChange={EditOrder.calc.discountChange}
										discountPercentChange={EditOrder.calc.discountPercentChange}
										fieldsDescription={EditOrder.fieldsDescription}
										fieldsSyncDir={EditOrder.fieldsSyncDir}
										hasContributionMargin={EditOrder.hasContributionMargin}
										hasCustom={hasCustom}
										highlightedFields={EditOrder.highlightedFields}
										inputDebounceSetting={EditOrder.inputDebounceSetting}
										isDisabledField={EditOrder.isDisabledField}
										isProductBundle={isProductBundle}
										isVisibleField={EditOrder.isVisibleField}
										key={orderRow.uuid}
										openEditProductBundle={EditOrder.openEditProductBundle}
										orderRow={orderRow}
										priceChange={EditOrder.calc.priceChange}
										productChange={EditOrder.calc.productChange}
										purchaseCostChange={EditOrder.calc.purchaseCostChange}
										quantityChange={EditOrder.calc.quantityChange}
										recurringInterval={EditOrder.order.recurringInterval}
										rowEditable={rowEditable}
										rowIndex={index}
										saveDisabled={EditOrder.saveDisabled}
										saving={EditOrder.saving}
										sortDown={EditOrder.sortRowDown}
										sortUp={EditOrder.sortRowUp}
										syncedToApp={EditOrder.syncedToApp}
										useDiscount={EditOrder.useDiscount}
									/>
								);
							})}
						</OrderRows>

						<tr className="sum-row">
							<td colSpan={100}>
								<button
									type="button"
									className="btn up-btn btn-sm btn-green no-shadow pull-left"
									onClick={() => EditOrder.addOrderRow()}
									disabled={EditOrder.saving || !EditOrder.editable}
									id="form-add-order-row"
									tabIndex={-1}
								>
									<b className="fa fa-plus"></b> {t('default.addOrderRow')}
								</button>
								{EditOrder.hasProductSearch ? (
									<button
										type="button"
										className="btn up-btn btn-sm btn-green no-shadow pull-left"
										onClick={() => EditOrder.openProductSearch()}
										disabled={EditOrder.saving || !EditOrder.editable}
										id="form-search-products"
										tabIndex={-1}
									>
										<b className="fa fa-search"></b> {t('product.searchProducts')}
									</button>
								) : null}

								<table className="sum-table">
									<tbody>
										{EditOrder.isVisibleField('totalgross') ? (
											<tr>
												<td className="bold">{t('default.amount')}</td>
												<td>
													<span id="form-totalGross">
														{currencyFormat(
															EditOrder.calc.totalGross(),
															EditOrder.order.currency,
															true,
															EditOrder.numOfDecimalsPrice,
															undefined,
															true
														)}
													</span>
												</td>
											</tr>
										) : null}
										{EditOrder.isVisibleField('totaldiscount', EditOrder.useDiscount) ? (
											<tr>
												<td className="bold">{t('default.discount')}</td>
												<td>
													<span id="form-totalDiscount">
														{EditOrder.calc.totalDiscount() ? '- ' : ''}
														{currencyFormat(
															EditOrder.calc.totalDiscount() || 0,
															EditOrder.order.currency,
															true,
															EditOrder.numOfDecimalsPrice,
															undefined,
															true
														)}
													</span>
												</td>
											</tr>
										) : null}
										{EditOrder.isVisibleField(
											'totalcontributionmargin',
											EditOrder.hasContributionMargin
										) ? (
											<tr>
												<td className="bold">{t('default.contributionMarginShort')}</td>
												<td>
													<span id="form-totalCb">
														{currencyFormat(
															EditOrder.calc.totalContributionMargin(),
															EditOrder.order.currency,
															true,
															EditOrder.numOfDecimalsPrice,
															undefined,
															true
														)}
														{`(${EditOrder.calc.totalContributionMarginPercentage()}%)`}
													</span>
												</td>
											</tr>
										) : null}
										{EditOrder.isVisibleField('totalrr', EditOrder.hasRecurringInterval) ? (
											<tr>
												{EditOrder.salesModelOption === 'mrr' ? (
													<td className="bold">{t('default.mrr')}</td>
												) : null}
												{EditOrder.salesModelOption !== 'mrr' ? (
													<td className="bold">{t('default.arr')}</td>
												) : null}
												<td>
													<span id="form-totalCb">
														{currencyFormat(
															EditOrder.calc.totalRR(),
															EditOrder.order.currency,
															true,
															EditOrder.numOfDecimalsPrice,
															undefined,
															true
														)}
													</span>
												</td>
											</tr>
										) : null}
										{EditOrder.isVisibleField('totaloneoff', EditOrder.hasRecurringInterval) ? (
											<tr>
												<td className="bold">{t('admin.products.oneOff')}</td>
												<td>
													<span id="form-totalCb">
														{currencyFormat(
															EditOrder.calc.totalOneOff(),
															EditOrder.order.currency,
															true,
															EditOrder.numOfDecimalsPrice,
															undefined,
															true
														)}
													</span>
												</td>
											</tr>
										) : null}
										{EditOrder.isVisibleField('totalnet') ? (
											<tr>
												<td className="large">{t('default.netAmount')}</td>
												<td className="large">
													<span id="form-totalNet">
														{currencyFormat(
															EditOrder.calc.totalNet() || 0,
															EditOrder.order.currency,
															true,
															EditOrder.numOfDecimalsPrice,
															undefined,
															true
														)}
													</span>
												</td>
											</tr>
										) : null}
										{EditOrder.isVisibleField('totalnet', EditOrder.convert) ? (
											<tr>
												<td className="bold">{t('default.convertedNetAmount')}</td>
												<td>
													<span id="form-convertedNet">
														{currencyFormat(
															EditOrder.calc.convertedNet() || 0,
															EditOrder.masterCurrency,
															true,
															EditOrder.numOfDecimalsPrice,
															undefined,
															true
														)}
													</span>
													<span>
														{' '}
														({numberFormat(EditOrder.order.currencyRate, false, 2)})
													</span>
												</td>
											</tr>
										) : null}
									</tbody>
								</table>
							</td>
						</tr>
					</tbody>
				</table>

				<div className="clearfix"></div>
			</div>

			{/* CUSTOMFIELDS */}
			{EditOrder.customFieldsLength
				? EditOrder.formGroups.map((groupName: string | null) => {
						return (
							<div key={groupName ?? 'default'}>
								<div className="modal-section-divider">
									{groupName === null ? <h3>{t('default.otherInfo')}</h3> : <h3>{groupName}</h3>}
								</div>
								<div className="row">
									{sortedCustomFields.map(field => {
										const visible =
											(!EditOrder.canShowFormGroups || groupName === field.formGroup) &&
											EditOrder.isVisibleField(
												'custom.' + field.id,
												field.$hasAccess && (field.editable || field.visible) ? true : false
											);

										if (!visible) {
											return null;
										}

										return (
											<div key={field.id} className="col-sm-6" style={{ minHeight: 80 }}>
												<Tooltip
													title={
														field.datatype === 'Calculation' &&
														!EditOrder.highlightedFields.includes('custom.' + field.id) &&
														(!EditOrder.hasFormulaVisible || field.formulaVisible)
															? getCalculatingFieldTooltip(field.formula, field.name)
															: ''
													}
												>
													<div
														id={`form-cf_${field.id}`}
														className={classNames('form-group', {
															'has-error':
																EditOrder.OrderForm['upCustomField_' + field.id]
																	?.$invalid &&
																(EditOrder.OrderForm['upCustomField_' + field.id]
																	?.$dirty ||
																	EditOrder.OrderForm.$submitted),
															'field-sync-disabled':
																EditOrder.highlightedFields.length > 0 &&
																!EditOrder.highlightedFields.includes(
																	'custom.' + field.id
																)
														})}
													>
														<div className="form-input-sync-header">
															<label htmlFor={`upCustomField_${field.id}`}>
																{field.name}
															</label>
															{field.obligatoryField === 1 ? (
																<b className="text-danger"> *</b>
															) : null}
															{EditOrder.fieldsSyncDir[`custom.${field.id}`] ? (
																<div className="form-input-sync-direction">
																	{EditOrder.fieldsSyncDir[`custom.${field.id}`] ===
																	'import' ? (
																		<Tooltip
																			title={t('integration.onlySyncedToUpsales')}
																		>
																			<b className="fa fa-long-arrow-left"></b>
																		</Tooltip>
																	) : null}
																	{EditOrder.fieldsSyncDir[`custom.${field.id}`] ===
																	'export' ? (
																		<Tooltip
																			title={EditOrder.syncedToApp}
																			dynamic={true}
																		>
																			<b className="fa fa-long-arrow-right"></b>
																		</Tooltip>
																	) : null}
																	{EditOrder.fieldsSyncDir[`custom.${field.id}`] ===
																	'both' ? (
																		<Tooltip title={t('integration.twoWaySync')}>
																			<b className="fa fa-exchange"></b>
																		</Tooltip>
																	) : null}
																</div>
															) : null}
														</div>
														<Tooltip
															title={EditOrder.fieldsDescription[`custom.${field.id}`]}
															dynamic={true}
														>
															<div>
																<div
																	className={classNames('input', {
																		'date-icon-with-label':
																			field.datatype === 'Date'
																	})}
																>
																	<div
																		className={classNames({
																			'form-control order-field':
																				field.datatype === 'Calculation',
																			'field-sync-highlight':
																				EditOrder.highlightedFields.includes(
																					'custom.' + field.id
																				)
																		})}
																	>
																		<div>
																			<CustomFieldInput
																				disabled={
																					field.datatype !== 'Calculation' &&
																					EditOrder.isDisabledField(
																						'custom.' + field.id,
																						EditOrder.saving ||
																							!field.editable ||
																							!EditOrder.editable
																					)
																				}
																				entity={'order'}
																				field={field}
																				inputDebounceSetting={
																					EditOrder.inputDebounceSetting
																				}
																				name={`upCustomField_${field.id}`}
																				onChange={EditOrder.customFieldChanged}
																				tabIndex={14}
																				updateOnBlur={true}
																				useNewTime={true}
																				useNumberInput={true}
																				useExternalDisabled={
																					EditOrder.hasAppValidationWithOrderEdit
																				}
																			/>
																		</div>
																	</div>
																</div>
															</div>
														</Tooltip>
													</div>
												</Tooltip>
											</div>
										);
									})}
								</div>
							</div>
						);
				  })
				: null}

			{/* NOTES */}
			{EditOrder.isVisibleField('notes') ? (
				<div className="modal-section-divider no-margin-bottom">
					<div className="form-input-sync-header">
						{EditOrder.fieldsSyncDir.notes ? (
							<div className="form-input-sync-direction">
								{EditOrder.fieldsSyncDir.notes === 'import' ? (
									<Tooltip title={t('integration.onlySyncedToUpsales')}>
										<b className="fa fa-long-arrow-left"></b>
									</Tooltip>
								) : null}
								{EditOrder.fieldsSyncDir.notes === 'export' ? (
									<Tooltip title={EditOrder.syncedToApp} dynamic={true}>
										<b className="fa fa-long-arrow-right"></b>
									</Tooltip>
								) : null}
								{EditOrder.fieldsSyncDir.notes === 'both' ? (
									<Tooltip title={t('integration.twoWaySync')}>
										<b className="fa fa-exchange"></b>
									</Tooltip>
								) : null}
							</div>
						) : null}
						<Tooltip title={EditOrder.fieldsDescription.notes} placement="right" dynamic={true}>
							<h3>{t('default.notes')}</h3>
						</Tooltip>
						{EditOrder.requiredFields.Notes ? <b className="text-danger">*</b> : null}
						<button
							type="button"
							className="btn up-btn btn-sm btn-link btn-green no-shadow"
							style={
								EditOrder.isDisabledField('notes', !EditOrder.editable || EditOrder.saving)
									? { display: 'none' }
									: {}
							}
							id="insert-sign-btn"
							onClick={event => EditOrder.insertSign(event, notesRef.current!)}
							tabIndex={-1}
						>
							<b className="Icon Icon-pencil"></b> <span>{t('default.insertSign').toLowerCase()}</span>
						</button>
					</div>
				</div>
			) : null}
			{EditOrder.isVisibleField('notes') ? (
				<div
					id="form-notes"
					className={classNames('form-group', {
						'has-error':
							EditOrder.OrderForm.notes?.$invalid &&
							(EditOrder.OrderForm.notes?.$dirty || EditOrder.OrderForm.$submitted),
						'field-sync-disabled':
							EditOrder.highlightedFields.length > 0 && !EditOrder.highlightedFields.includes('notes')
					})}
				>
					<TextArea
						name="notes"
						id="notes"
						ref={notesRef}
						className={classNames({
							'field-sync-highlight': EditOrder.highlightedFields.includes('notes')
						})}
						value={EditOrder.order.notes ?? ''}
						onChange={EditOrder.notesChanged}
						rows={18}
						disabled={EditOrder.isDisabledField('notes', !EditOrder.editable || EditOrder.saving)}
						required={EditOrder.requiredFields.Notes}
						placeholder={t('default.notes')}
						tabIndex={15}
						style={notesStyle}
						controllerOptions={
							EditOrder.hasUpdateCustomFieldsOnBlur
								? { updateOn: UpdateEvent.Blur }
								: { debounce: { change: EditOrder.inputDebounceSetting || 500, blur: 0 } }
						}
					/>
				</div>
			) : null}
		</div>
	);
};

export default OrderForm;
