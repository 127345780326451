import EditAppointment from './EditAppointment';
import React, { useCallback } from 'react';
import useMeta from './useMeta';
import { asyncModalAdapter } from 'App/helpers/angularPortingHelpers';
import { ModalProps } from 'App/components/Modals/Modals';
import Appointment from 'App/resources/Model/Appointment';
import AppointmentOutcome from 'App/resources/Model/AppointmentOutcome';

export enum PromiseType {
	Resolve,
	Reject
}

export type ModalParams = {
	id?: number;
	appointment?: Partial<Appointment>;
	preSelectedOutcome?: AppointmentOutcome;
};

// Kinda scuffed, but this is the correct typing as I had to use customOnCloseResolver to simulate the behaviour of the Angular code
type OnCloseReturnType = [PromiseType.Resolve, Partial<Appointment> | void] | [PromiseType.Reject, unknown | void];

type Props = ModalParams & ModalProps<OnCloseReturnType>;

// This is just to simulate the behaviour of the resolves object (just EditAppointmentMeta for the editAppointment modal) in the angular code
const EditAppointmentWrap = ({ className, close, id, appointment, preSelectedOutcome }: Props) => {
	const resolve = useCallback(result => close([PromiseType.Resolve, result]), []);
	const reject = useCallback(error => close([PromiseType.Reject, error]), []);
	const meta = useMeta({ id, appointment, preSelectedOutcome }, reject);

	if (meta === null) {
		return null;
	}

	return <EditAppointment className={className} meta={meta} reject={reject} resolve={resolve} />;
};

export default EditAppointmentWrap;

/**
 * Had to run with a customOnCloseResolver as ui/app/babel/components/Modals/Appointment/AppointmentCtrl.js both
 * resolves with an Appointment | void, and rejects with both Error | void.
 */
export const openEditAppointment = asyncModalAdapter({
	featureFlag: 'EDIT_APPOINTMENT_REACT',
	openModalName: 'EditAppointment',
	upModalName: 'editAppointment',
	rejectOnEmpty: false,
	rejectOnEvent: false,
	customOnCloseResolver: ([resolveType, resolveValue], resolve, reject) => {
		if (resolveType === PromiseType.Resolve) {
			resolve(resolveValue);
		} else {
			reject(resolveValue);
		}
	}
});
