import InlineConfirm from 'App/babel/components/Dialogs/InlineConfirm';
import React from 'react';
import fileSize from 'App/babel/helpers/fileSize';
import useEditOrderContext from '../hooks/useEditOrderContext';
import { dateCalendar } from 'App/helpers/DateHelpers';
import { getFileIcon } from 'App/babel/helpers/file';
import { sortBy } from 'lodash';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const OrderFiles = () => {
	const EditOrder = useEditOrderContext();
	const { t } = useTranslation();

	const sortedFiles = useMemo(() => sortBy(EditOrder.files, 'uploadDate').reverse(), [EditOrder.files]);

	return (
		<div id="order-files">
			<div>
				<table className="main-table">
					<thead>
						<tr>
							<th style={{ width: 20 }}></th>
							<th>{t('file.fileName')}</th>
							<th>{t('file.fileSize')}</th>
							<th>{t('file.uploadedDate')}</th>
							<th>{t('file.uploader')}</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						{sortedFiles.map(file => (
							<tr key={file.id}>
								<td onClick={() => EditOrder.downloadFile(file)}>
									<b className={`fa ${getFileIcon(file.mimetype)}`}></b>
								</td>
								<td onClick={() => EditOrder.downloadFile(file)}>{file.filename}</td>
								<td onClick={() => EditOrder.downloadFile(file)}>{fileSize(file.size)}</td>
								<td onClick={() => EditOrder.downloadFile(file)}>{dateCalendar(file.uploadDate)}</td>
								<td onClick={() => EditOrder.downloadFile(file)}>{file.user?.name ?? ''}</td>
								<td className="row-actions column-tools">
									<div className="adcampaign-list-button">
										<InlineConfirm
											entity="file.file"
											onConfirm={() => EditOrder.removeFile(file)}
											tooltip={t('default.delete')}
											show={file.userRemovable}
										>
											<button className="btn btn-link delete-trigger fa fa-trash-o"></button>
										</InlineConfirm>
									</div>
								</td>
							</tr>
						))}
						<tr className="no-result-row" style={!EditOrder.files.length ? {} : { display: 'none' }}>
							<td colSpan={100}>{t('file.noFiles')}</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	);
};

export default OrderFiles;
