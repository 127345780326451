import React from 'react';
import PropTypes from 'prop-types';
import { Card, Icon, Link, Text, Button, IconName } from '@upsales/components';
import T from 'Components/Helpers/translate';
import bemClass from '@upsales/components/Utils/bemClass';
import $ from 'jquery';
import moment from 'moment';
import OrderType from 'App/resources/Model/Order';
import { openEditAppointment } from 'Components/Modals/Appointment/EditAppointment';

import './OpportunityRow.scss';

type Props = {
	isWarning: boolean;
	appointmentId?: number;
	activityId?: number;
	date?: string | Date;
	description?: string;
	onlyBookAppointmentButton?: boolean;
	opportunity: OrderType;
	setCreateData?: (data: { type: string; description: string }) => void;
	disabled?: boolean;
};

class OpportunityRow extends React.Component<Props> {
	lang: {
		nextStep: string;
		planned: string;
		noNextStep: string;
	};

	static propTypes = {
		isWarning: PropTypes.bool.isRequired,
		opportunity: PropTypes.object,
		appointmentId: PropTypes.number,
		activityId: PropTypes.number,
		username: PropTypes.string,
		setCreateData: PropTypes.func,
		description: PropTypes.string,
		date: PropTypes.string,
		onlyBookAppointmentButton: PropTypes.bool,
		disabled: PropTypes.bool
	};

	constructor(p: Props) {
		super(p);

		this.lang = {
			nextStep: T('opportunity.aNextStep'),
			planned: T('opportunity.planned'),
			noNextStep: T('opportunity.noNextStepPlanned')
		};
	}

	createActivity = () => {
		const { opportunity } = this.props;
		const options = {
			activity: {
				client: opportunity.client,
				opportunity: opportunity
			}
		};
		Tools.$upModal.open('editActivity', options);
	};

	scrollToTimelinesection = () => {
		$('.up-modal-content').animate({ scrollTop: 235 }, '400');
	};

	openCreate = (type: string) => {
		const { opportunity, setCreateData } = this.props;
		if (type === 'appointment') {
			openEditAppointment({
				appointment: {
					client: opportunity.client,
					opportunity: { id: opportunity.id },
					sourceType: 'opportunity',
					sourceId: opportunity.id
				}
			});
			return;
		}

		this.scrollToTimelinesection();
		setCreateData?.({
			type,
			description: ''
		});
	};

	renderButton = (icon: IconName, title: string, type: string) => {
		return (
			<Button disabled={this.props.disabled} onClick={() => this.openCreate(type)} type="link">
				<Icon name={icon} space="mrs" />
				{title}
			</Button>
		);
	};

	render() {
		const classes = new bemClass('OpportunityRow');
		const { isWarning, appointmentId, activityId, date, description, onlyBookAppointmentButton } = this.props;

		const hasDate = date && date !== '0000-00-00';

		return (
			<Card className={classes.b()}>
				<Icon
					className={classes.elem('iconTitle').b()}
					color={'green'}
					name={isWarning ? 'lightbulb-o' : 'check'}
				/>
				{isWarning && !appointmentId ? (
					<div className={classes.elem('con').b()}>
						<Text className={classes.elem('description').b()}>{this.lang.noNextStep}</Text>
						<div className={classes.elem('actions').b()}>
							{Tools.FeatureHelper.hasSoftDeployAccess('TODO_LIST') ? (
								<React.Fragment>
									{!onlyBookAppointmentButton
										? this.renderButton('phone', T('todo.planACall'), 'phonecall')
										: ''}
									{this.renderButton('calendar', T('todo.bookAppointment'), 'appointment')}
								</React.Fragment>
							) : (
								<React.Fragment>
									{!onlyBookAppointmentButton ? (
										<Button onClick={this.createActivity} type="link">
											<Icon name={'activity'} space="mrs" />
											{T('create_activity')}
										</Button>
									) : (
										''
									)}
									{this.renderButton('calendar', T('todo.bookAppointment'), 'appointment')}
								</React.Fragment>
							)}
						</div>
					</div>
				) : (
					<React.Fragment>
						<div className={classes.elem('con').b()}>
							<Text className={classes.elem('description').b()}>{this.lang.nextStep}</Text>

							<Link
								onClick={
									this.props.disabled
										? undefined
										: () =>
												appointmentId
													? openEditAppointment({ id: appointmentId })
													: Tools.$upModal.open('editActivity', { id: activityId })
								}
								className={classes.elem('appointmentLink').b()}
							>
								{this.lang.planned}
							</Link>
							<div className={classes.elem('descriptionDate').b()}>
								{description ? (
									<Text
										className={
											classes
												.elem('descriptionDate')
												.elem('description')
												.mod({ moveUp: hasDate })
												.b() + ' text-ellipsis'
										}
									>
										{description}
									</Text>
								) : null}
								{date && date !== '0000-00-00' ? (
									<Text className={classes.elem('descriptionDate').elem('date').b()} size="sm">
										{moment(date).format('YYYY-MM-DD')}
									</Text>
								) : null}
							</div>
						</div>
					</React.Fragment>
				)}
			</Card>
		);
	}
}

export default OpportunityRow;
