import Form from 'App/resources/Model/Form';
import FormSubmit, { fieldValue } from 'App/resources/Model/FormSubmit';
import T from 'Components/Helpers/translate';
import moment from 'moment';
import { Field, ParsedFieldValue } from './EditFormSubmitModal';
import { sortBy } from 'lodash';

const parseFieldValue = (field: Field, fieldValue: fieldValue) => {
	const trimmedFieldValue = fieldValue.value ? fieldValue.value.trim() : '';

	if (field.options && Array.isArray(field.options)) {
		if (trimmedFieldValue.indexOf('admin.anonymization') > -1) {
			return T(trimmedFieldValue);
		}
	} else if (field.datatype === 'number') {
		const parsedFieldValue = parseInt(trimmedFieldValue);
		return isNaN(parsedFieldValue) ? '' : parsedFieldValue;
	} else if (field.datatype === 'date') {
		return moment(trimmedFieldValue).isValid() ? moment(trimmedFieldValue).toDate() : null;
	}

	return trimmedFieldValue;
};

const parseOptions = (options: string) => {
	if (options && typeof options === 'string') {
		return options.split(',').reduce<string[]>((options, option) => {
			if (option) {
				options.push(decodeURIComponent(option).trim());
			}
			return options;
		}, []);
	}
	return options;
};

export const parseFieldsAndValues = (form: Form, submit: FormSubmit) => {
	const { fields, values } = form.fields.reduce<{
		values: { [fieldName: string]: ParsedFieldValue };
		fields: Field[];
	}>(
		(res, formField) => {
			if (
				formField.name.indexOf('Prefs.') === 0 ||
				formField.datatype === 'password' ||
				formField.datatype === 'info'
			) {
				return res;
			}

			const field: Field = {
				...formField,
				options: parseOptions(formField.options)
			};

			const submittedValue = submit.fieldValues.find(fieldValue => {
				return fieldValue.name === field.name;
			});

			if (submittedValue) {
				res.values[field.name] = parseFieldValue(field, submittedValue);
			}

			res.fields.push(field);

			return res;
		},
		{ fields: [], values: {} }
	);

	return { fields: sortBy(fields, 'sort'), values };
};

export const validate = (fields: Field[], values: { [fieldName: string]: ParsedFieldValue }) => {
	return fields.filter(({ required }) => required).every(field => !!values[field.name]);
};
