import React, { useState, useEffect, useRef } from 'react';
import BemClass from '@upsales/components/Utils/bemClass';
import T from 'Components/Helpers/translate';
import { Text, Icon, ModalContent, Select, Label, Button, Flex, Input } from '@upsales/components';
import RoleSelect from 'Components/RoleSelect';
import { DocumentTemplate } from 'App/resources/AllIWant';
import FileUpload, { FileUploadDropzone, FileUploadDropzoneActive } from 'Components/FileUpload';
import _ from 'lodash';
import './EditDocumentTemplate.scss';
import File from 'App/resources/Model/File';
import Role from 'App/resources/Model/Role';
import { ThirdButton } from '@upsales/components/Buttons';
import NotificationService from 'App/babel/NotificationService';
import fileSize from 'App/babel/helpers/fileSize';
import { ModalProps } from '../Modals/Modals';
import getAngularModule from 'App/babel/angularHelpers/getAngularModule';
import { useFeatureAvailable, useForceRender, useSoftDeployAccess } from '../hooks';
import { useMetadata } from '../hooks/appHooks';
import { Feature } from 'Store/actions/FeatureHelperActions';

export type Props = ModalProps & {
	documentTemplate: DocumentTemplate;
	close: () => void;
};

interface DocumentType {
	id: string;
	name: string;
}

type RoleIdName = Pick<Role, 'id' | 'name'>;

const EditDocumentTemplate = (props: Props) => {
	const classes = new BemClass('EditDocumentTemplate');
	const customerId = Tools.AppService.getCustomerId();
	const anchor = '.Modals';
	const file = useRef<any | null>(null);
	const [fakeFile, setFakeFile] = useState<string | null>(null);
	const [saving, setSaving] = useState(false);
	const hasRoleFeature = useFeatureAvailable(Feature.USER_PERMISSIONS_ADVANCED);
	const hasTreeSelect = useSoftDeployAccess('TREE_SELECT');
	const documentRoles = Tools.AppService.getRoleMap().document;
	const metadata = useMetadata();
	const predefinedTypes: DocumentType[] = [
		{
			name: T('default.order'),
			id: 'order'
		},
		{
			name: T('default.agreement'),
			id: 'agreement'
		},
		{
			name: T('feature.companiesAndContacts'),
			id: 'client'
		},
		{
			name: T(
				Tools.FeatureHelper.hasSoftDeployAccess('REMOVE_ACTIVITIES')
					? 'default.appointment'
					: 'default.activityAndAppointment'
			),
			id: 'activity'
		}
	];
	const udoTypes =
		metadata?.params.UserDefinedObject.map(udo => ({
			id: 'object' + udo.id,
			name: udo.name
		})) ?? [];
	const types = predefinedTypes.concat(udoTypes);
	const defaultDocumentTemplate = {
		id: 0,
		name: '',
		type: '',
		roles: []
	};
	const [documentTemplate, setDocumentTemplate] = useState<DocumentTemplate>(
		props.documentTemplate ? _.cloneDeep(props.documentTemplate) : defaultDocumentTemplate
	);
	const [selectedType, setSelectedType] = useState<{ id: DocumentType['id']; title: DocumentType['name'] } | null>(
		documentTemplate.type
			? {
					id: documentTemplate.type,
					title: types.find(type => type.id === documentTemplate.type)?.name || ''
			  }
			: null
	);
	const [selectedRoleIdNames, setSelectedRoleIdNames] = useState<RoleIdName[]>(documentTemplate?.roles || []);
	const [selectedRoles, setSelectedRoles] = useState<Role | undefined>();

	const ResourceHelper = getAngularModule('ResourceHelper') as any;
	const helper = new ResourceHelper();
	const { triggerRender } = useForceRender();

	const fileUploadConfig = {
		url: '/api/' + 'v2/' + customerId.toString() + '/documentTemplates/upload',
		withCredentials: true,
		acceptedFiles: 'application/msword',
		autoUpload: false,
		onAfterAddingFile: function (item: any) {
			if (item.file.size > 8388608) {
				item.cancel();
				item.remove();

				Tools.NotificationService.addNotification({
					title: 'default.error',
					body: 'admin.documentTemplateModal.toLargeFile',
					style: Tools.NotificationService.style.ERROR,
					icon: 'times'
				});
			} else {
				file.current = item;
				triggerRender();
			}
		},
		onErrorItem: function (item: any) {
			item.cancel();
			item.remove();
			file.current = null;

			helper.notifications.saveError({}, !documentTemplate.id);
		},
		onSuccessItem: function (item: any, res: any) {
			if (res.error || !res.data) {
				item.cancel();
				item.remove();
				file.current = null;
				helper.onSaveError({}, !documentTemplate.id);
			} else {
				helper.onSave({}, res.data, !documentTemplate.id);
				props.close(res.data);
			}
		}
	};
	const FileUploader = getAngularModule('FileUploader') as any;
	const uploader = new FileUploader(fileUploadConfig);

	helper.setDefaults({
		eventPrefix: 'documentTemplate',
		createSuccessBody: 'saved.documentTemplate',
		updateSuccessBody: 'updated.documentTemplate',
		createErrorBody: 'saveError.documentTemplate',
		updateErrorBody: 'saveError.documentTemplate',
		deleteErrorBody: 'deleteError.documentTemplate'
	});

	// Will only be one file but FileUpload expects an array of files, so we send one file in an array
	// if multiple files are dropped the last one to finish uploading will be the one that is saved... one to one with old code.
	const dropFile: <File>(files: File[]) => void = async rawfiles => {
		rawfiles.forEach((rawfile, i) => {
			const rfile = rawfile as File & { name: string };
			if (rfile) {
				if (rfile.type !== 'application/msword') {
					Tools.NotificationService.addNotification({
						title: 'default.error',
						body: 'admin.documentTemplateModal.whrongFileType',
						style: NotificationService.style.ERROR,
						icon: 'times'
					});
				} else if (rfile.size > 8388608) {
					Tools.NotificationService.addNotification({
						title: 'default.error',
						body: 'admin.documentTemplateModal.toLargeFile',
						style: NotificationService.style.ERROR,
						icon: 'times'
					});
				} else {
					rfile.filename = rfile.name;
					uploader.addToQueue(rfile);
				}
			}
		});
	};

	useEffect(() => {
		if (documentTemplate.id) {
			setFakeFile(documentTemplate.name + '.doc');
		}
		if (!hasTreeSelect) {
			const initialRole = documentTemplate?.roles?.length ? documentTemplate.roles[0] : undefined;
			setSelectedRoles(initialRole ? documentRoles.find(role => role.id === initialRole.id) : undefined);
		}
	}, []);

	const handleChangeRole = (role: RoleIdName[] | Role, isUpRoles: boolean = false) => {
		if (Array.isArray(role) && !isUpRoles) {
			setSelectedRoleIdNames(role);
		} else {
			setSelectedRoles(role as Role); // We are sure that this is a role because of the isUpRoles check
		}
	};

	const removeFakeFile = () => {
		setFakeFile(null);
	};

	const saveIsDisabled = () => {
		if (saving) {
			return true;
		}

		if (!documentTemplate.id) {
			return !documentTemplate.name || !documentTemplate.type || !file;
		} else {
			const sameName = documentTemplate.name === props.documentTemplate.name;

			const sameType = selectedType?.id === props.documentTemplate.type || !selectedType;
			const sameFile = !file.current;
			const hasFile = fakeFile || file.current;

			const roleStructure = hasTreeSelect ? selectedRoleIdNames : selectedRoles ? [selectedRoles] : [];
			const sameRoles = _.isEqual(_.map(roleStructure, 'id'), _.map(props.documentTemplate.roles, 'id'));

			if (!hasFile) {
				return true;
			} else {
				return sameName && sameType && sameRoles && sameFile;
			}
		}
	};

	const save = () => {
		const DocumentTemplateResource: any = getAngularModule('DocumentTemplate');

		if (file.current) {
			file.current.formData = [
				{ name: documentTemplate.name },
				{ type: documentTemplate.type },
				{ roles: JSON.stringify(documentTemplate.roles ?? []) }
			];

			if (documentTemplate.id) {
				file.current.formData = [...file.current.formData, { id: documentTemplate.id }];
			}

			file.current.upload();
		} else {
			setSaving(true);
			const docTemplate = _.pick(documentTemplate, ['id', 'name', 'roles', 'type']);

			DocumentTemplateResource.customer(customerId)
				.save(docTemplate)
				.then(function (res: any) {
					//$scope.resolve(res.data);
					props.close(res.data);
					setSaving(false);
				})
				.catch((e: Error) => console.error(e));
		}
	};

	const removeAttachment = () => {
		if (file.current) {
			file.current.cancel();
			file.current.remove();
		}
		file.current = null;
		triggerRender();
	};

	const roleTreeChanged = (roles: RoleIdName[]) => {
		setDocumentTemplate({ ...documentTemplate, roles: roles as (RoleIdName & { description: string })[] });
	};

	return (
		<form
			onSubmit={e => {
				e.preventDefault();
				save();
			}}
		>
			<ModalContent className={classes.elem('up-modal-content has-sidebar up-modal-admin').b()}>
				<div className="column-wrap">
					<div className="left-side">
						<h2 className="left-side-row">{T('admin.documentTemplateModal.newTemplate')}</h2>

						<div className="left-side-row">
							<Label required={true}>{T('admin.documentTemplateModal.name')}</Label>
							<Input
								type="text"
								autofocus={true}
								value={documentTemplate.name}
								onChange={e => setDocumentTemplate({ ...documentTemplate, name: e.target.value })}
							/>
						</div>

						{hasRoleFeature && (
							<div id="role-select-wrap" className="left-side-row">
								<Label>{T('default.permission')}</Label>
								{hasTreeSelect ? (
									<RoleSelect
										roles={documentRoles}
										selectedRoles={selectedRoleIdNames || []}
										onChange={roles => {
											handleChangeRole(roles);
											roleTreeChanged(roles);
										}}
									/>
								) : (
									<ReactTemplates.INPUTS.upRoles
										id="admin-stages-role-input"
										key={'role-select'}
										tools={Tools}
										data={Tools.AppService.getRoles()}
										value={selectedRoles}
										multiple={true}
										onChange={role => handleChangeRole(role, true)}
										placeholder={T('default.selectRoles')}
									/>
								)}
							</div>
						)}

						<div className="left-side-row">
							<Label required={true}>{T('admin.documentTemplateModal.type')}</Label>
							<div className="select2-wrap full-width">
								<Select<{ id: DocumentType['id']; title: DocumentType['name'] }>
									anchor={anchor}
									value={selectedType}
									onChange={selected => {
										setSelectedType(selected);
										setDocumentTemplate({ ...documentTemplate, type: selected?.id || '' });
									}}
									onClear={() => {
										setSelectedType(null);
										setDocumentTemplate({ ...documentTemplate, type: '' });
									}}
									placeholder={T('admin.documentTemplateModal.typePlaceholder')}
									options={types.map(type => ({
										id: type.id,
										title: type.name
									}))}
								/>
							</div>
						</div>

						<div className="left-side-save-button-wrap">
							<Button
								disabled={saveIsDisabled()}
								onClick={save}
								className="btn up-btn btn-bright-blue left-side-save-button"
								children={T('default.save')}
							/>
							<div className="left-side-cancel-button">
								<ThirdButton
									onClick={props.close}
									className="btn up-btn btn-link"
									style={{ color: 'darkgrey' }}
									children={T('default.abort')}
								/>
							</div>
						</div>
					</div>
					<div id="file-wrapper" className="right-side">
						<button type="button" className="btn btn-link modal-close cancel-button" onClick={props.close}>
							<Icon name="times" />
						</button>
						{!file.current && !fakeFile && (
							<Flex gap="u5" direction="column" alignItems="center" className="right-side-content-wrap">
								<FileUpload onDrop={dropFile} accept={['.doc']}>
									{({ open, isDragActive }) => (
										<Flex direction="column" gap="u2" alignItems="center">
											<svg
												width="120"
												height="151"
												viewBox="0 0 120 151"
												className="file-drop-icon"
												xmlns="http://www.w3.org/2000/svg"
												xmlnsXlink="http://www.w3.org/1999/xlink"
											>
												<g className="card" fill="none" fill-rule="evenodd">
													<path
														className="paper"
														fill="#E4EDF7"
														d="M13,3.00972645 C13,1.34750043 14.3424077,0 16.0020839,0 L85,0 L107,22 L107,113.999935 C107,115.656825 105.653127,117 104.005268,117 L15.9947322,117 C14.3407873,117 13,115.665878 13,113.990274 L13,3.00972645 Z"
													/>
													<path
														className="arrow"
														fill="#4A90E2"
														d="M73.0412946,61.2142857 C73.0412946,61.8058065 72.8348235,62.3080336 72.421875,62.7209821 L61.5234375,73.6361607 C61.0881675,74.0491092 60.58036,74.2555804 60,74.2555804 C59.4084792,74.2555804 58.9062521,74.0491092 58.4933036,73.6361607 L47.5948661,62.7209821 C47.1707568,62.3191944 46.9587054,61.8169673 46.9587054,61.2142857 C46.9587054,60.6227649 47.1707568,60.1149575 47.5948661,59.6908482 L48.8337054,58.4352679 C49.2689754,58.0223194 49.7767828,57.8158482 50.3571429,57.8158482 C50.9486637,57.8158482 51.4508908,58.0223194 51.8638393,58.4352679 L56.7857143,63.3571429 L56.7857143,51.5714286 C56.7857143,50.9910685 56.9977657,50.4888414 57.421875,50.0647321 C57.8459843,49.6406229 58.3482114,49.4285714 58.9285714,49.4285714 L61.0714286,49.4285714 C61.6517886,49.4285714 62.1540157,49.6406229 62.578125,50.0647321 C63.0022343,50.4888414 63.2142857,50.9910685 63.2142857,51.5714286 L63.2142857,63.3571429 L68.1361607,58.4352679 C68.5491092,58.0223194 69.0513363,57.8158482 69.6428571,57.8158482 C70.2232172,57.8158482 70.7310246,58.0223194 71.1662946,58.4352679 L72.421875,59.6908482 C72.8348235,60.1261182 73.0412946,60.6339257 73.0412946,61.2142857 Z"
													/>
													<path
														className="fold"
														fill="#4A90E2"
														opacity=".2"
														d="M84,0.0459127487 C84,-0.511600348 84.3143399,-0.649214134 84.7031002,-0.260453851 L106.789944,21.8263904 C107.178256,22.2147019 107.044718,22.5294906 106.483578,22.5294906 L85.0094668,22.5294906 C84.4519537,22.5294906 84,22.0811639 84,21.5200238 L84,0.0459127487 Z"
													/>
												</g>
												<ellipse
													className="shadow"
													id="shadow"
													cx="60"
													cy="147"
													fill="#000000"
													opacity=".1"
													rx="60"
													ry="4"
												/>
											</svg>
											<FileUploadDropzoneActive visible={isDragActive} />
											<FileUploadDropzone title={T('file.dropToUpload')} onClick={open} />
										</Flex>
									)}
								</FileUpload>
							</Flex>
						)}

						{fakeFile ? (
							<Flex gap="u5" direction="column" alignItems="center" className="right-side-content-wrap">
								<Icon name="file-text" style={{ fontSize: '60px' }} />
								<div className="right-side-file-name">{fakeFile}</div>
								<Button
									onClick={removeFakeFile}
									className="btn up-btn btn-bright-blue btn-lined right-side-remove-file-button"
								>
									<Icon name="trash" />
									{T('file.changeFile')}
								</Button>
							</Flex>
						) : null}

						{file.current ? (
							<Flex gap="u5" direction="column" alignItems="center" className="right-side-content-wrap">
								<Icon name="file-text" style={{ fontSize: '60px' }} />
								<div className="right-side-file-name">
									{file.current.file.name}
									{file.current.file.size && (
										<div className="right-side-file-size">
											{fileSize(file.current.file.size ?? 0)}
										</div>
									)}
								</div>
								<Button
									onClick={removeAttachment}
									className="btn up-btn btn-bright-blue btn-lined right-side-remove-file-button"
								>
									<Icon name="trash" />
									{T('file.removeFile')}
								</Button>
							</Flex>
						) : null}
						<Text className="right-side-filetype-warning">
							{T('admin.documentTemplateModal.whrongFileType')}
						</Text>
					</div>
				</div>
			</ModalContent>
		</form>
	);
};

export default EditDocumentTemplate;
