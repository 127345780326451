import React, { useEffect, useRef } from 'react';
import { ModalProps } from '../../Modals/Modals';
import { asyncModalAdapter } from 'App/helpers/angularPortingHelpers';
import {
	Avatar,
	Checkbox,
	Flex,
	Icon,
	Label,
	Modal,
	Row,
	Select,
	SelectAsync,
	Text,
	Toggle
} from '@upsales/components';
import { useTranslation } from 'react-i18next';
import { Esign, EsignInvolved } from 'App/resources/Model/Esign';
import RequestBuilder from 'Resources/RequestBuilder';
import AppService from 'App/legacy/AppService';
import Contact from 'App/resources/Model/Contact';
import Order from 'App/resources/Model/Order';
import Document from 'App/resources/Model/Document';
import Client from 'App/resources/Model/Client';
import './EditEsign.scss';
import BemClass from '@upsales/components/Utils/bemClass';
import { BasicUserWithPermissions } from 'App/resources/Model/User';
import { openFileBrowserModal } from 'Components/FileBrowser';
import BrowserService from 'Services/BrowserService';
import openModal from 'App/services/Modal';
import logError from 'Helpers/logError';
import { openIntegrationModal } from 'Components/IntegrationModal/IntegrationModal';
import _ from 'lodash';
import { SelectItem } from '@upsales/components/Utils/selectHelpers';
import FileUpload, { FileUploadDropzone, FileUploadDropzoneActive } from 'Components/FileUpload';
import File from 'App/babel/resources/File';
import axios from 'axios';
import PdfGeneric from 'Components/PdfGeneric';
import { ThirdButton } from '@upsales/components/Buttons';
import CountrySelect from 'App/components/Inputs/Selects/CountrySelect';
import { CancelablePromise, makeCancelable } from 'Helpers/promise';
import { useSelector } from 'App/components/hooks';
import { useStages } from 'App/components/hooks/appHooks';

type Props = ModalProps & {
	id?: number;
	esign?: any;
	integrationId?: number;
};

export const EditEsign = ({ id, esign: esignInput, integrationId, className, close }: Props) => {
	const { t } = useTranslation();
	const classes = new BemClass('EditEsign', className);
	const anchor = useRef<Element | null>(null);

	const fileStatuses = {
		EMPTY: 'EMPTY',
		UPLOADING: 'UPLOADING',
		SET: 'SET',
		ERROR: 'ERROR'
	};

	// ************************************** RANDOM VARIABLES

	const isEdit = !!id;
	const { customerId, esignIntegrations } = useSelector(({ App }) => App);
	const stages = useStages();
	const noPreview = BrowserService.isUnsupported() || BrowserService.isIE9();
	let languages: { title: string; value: string }[] = [];

	const cancelablePromises = useRef<{ [key: string]: CancelablePromise | null }>({
		getData: null,
		setFile: null,
		uploadFile: null
	});

	// ************************************** STATE VARIABLES

	const [esign, setEsign] = React.useState<Esign | null>(null);
	const [users, setUsers] = React.useState<BasicUserWithPermissions[]>([]);
	const [opportunities, setOpportunities] = React.useState<Order[]>([]);
	const [accountFiles, setAccountFiles] = React.useState<Document[]>([]);
	const [contacts, setContacts] = React.useState<{
		data: Contact[];
		metadata: {
			total: number;
		};
	}>({
		data: [],
		metadata: {
			total: 0
		}
	});
	const [senderSigns, setSenderSigns] = React.useState<boolean>(false);
	const [showSenderInfo, setShowSenderInfo] = React.useState<boolean>(false);
	const [senderSignModel, setSenderSignModel] = React.useState<any>(null);
	const [saving, setSaving] = React.useState<boolean>(false);
	const [sending, setSending] = React.useState<boolean>(false);
	const [moveToStage, setMoveToStage] = React.useState<boolean>(false);
	const [openingHtmlFrame, setOpeningHtmlFrame] = React.useState<boolean>(false);
	const [personToAdd, setPersonToAdd] = React.useState<SelectItem | null>(null);
	const [fields, setFields] = React.useState<
		{ title: string; label: string; required: boolean; type: string; name: string; data: any[] }[]
	>([]);
	const [settings, setSettings] = React.useState<{
		allowDraft: boolean;
		smsDelivery: boolean;
		multiSign: boolean;
		userCanSign: boolean;
		bankId: number | null;
		bankIdCountries: any[];
		documentType: string;
		hideCenter: boolean;
	}>({
		allowDraft: false,
		smsDelivery: false,
		multiSign: false,
		userCanSign: false,
		bankId: null,
		bankIdCountries: [],
		documentType: '',
		hideCenter: false
	});
	const [selectedPerson, setSelectedPerson] = React.useState<number | null>(null);
	const [esignAppPlaceholderProps, setEsignAppPlaceholderProps] = React.useState<any>();
	const [pdfPath, setPdfPath] = React.useState<string | null>(null);
	const [pdfLoading, setPdfLoading] = React.useState<boolean>(false);
	const [fileStatus, setFileStatus] = React.useState<string>(fileStatuses.EMPTY);
	const [participantSelectData, setParticipantSelectData] = React.useState<
		{
			id: number;
			title: string;
			disabled: boolean;
			children: { selected: boolean }[];
		}[]
	>([]);

	// ************************************** FETCH DATA

	useEffect(() => {
		if (!anchor.current) {
			anchor.current = document.querySelector('.Modals');
		}

		const getData = async () => {
			const es: Esign = await getEsign();
			if (!es) {
				logError('Missing esign or esign id');
			}
			cancelablePromises.current.getData = makeCancelable(
				Promise.all([
					getAccount(es.client.id!),
					getAccountFiles(es.client.id!),
					getContacts(es.client.id!),
					getOpportunities(es.client.id!),
					AppService.getActiveUsers(),
					Tools.StandardIntegration.find({
						onlyPublished: true,
						init: 'esign'
					})
				])
			);
			cancelablePromises.current.getData.promise
				.then(
					async ([resAccount, resAccountFiles, resContacts, resOpportunities, resUsers, resIntegrations]) => {
						let settings = null;
						if (es.id && es.integrationId) {
							settings = await getSettings(es.integrationId);
						} else {
							if (esignIntegrations.length === 1) {
								settings = await getSettings(integrationId || esignIntegrations[0].id);
							}
						}
						if (resAccount) {
							es.client = resAccount;
						}
						if (settings) {
							applySettings(settings);
							if (!es.id) {
								es.integrationId = integrationId || esignIntegrations[0].id;
							}
						}

						setAccountFiles(resAccountFiles);

						const availableContacts = resContacts.data.filter((c: any) => c.id !== es.client.id);
						setContacts({
							metadata: resContacts.metadata,
							data: availableContacts
						});

						const availableUsers = resUsers.filter((u: any) => u.id !== es.user.id);
						setUsers(availableUsers);

						setOpportunities(resOpportunities);

						if (esignInput?.opportunityId) {
							es.opportunity =
								resOpportunities.find((o: any) => o.id === esignInput.opportunityId) || null;
						}

						if (es.involved.length) {
							// Check each involveds phone nr
							for (const involved of es.involved) {
								validateInvolvedForView(involved);
							}

							// check for sender in involvees
							const foundSender = es.involved.find(person => person.userId === es.user.id);

							if (foundSender) {
								setSenderSigns(true);
								setSenderSignModel(foundSender);
								es.involved = es.involved.filter(involved => involved.userId !== foundSender.userId);
							} else {
								setSenderSigns(false);
							}
						}

						const esAppPlaceholderProps: any = {
							visible: false,
							integrations: [],
							selectMode: false,
							loading: false,
							onClick: function (integration: { id: any; name: any }) {
								setEsign({
									...es,
									integrationId: integration.id
								});
								Tools.Esign.runAction({
									type: 'settings',
									integrationId: integration.id
								})
									.then(function (res) {
										setEsignAppPlaceholderProps({ ...esignAppPlaceholderProps, visible: false });
										applySettings(res.data);
									})
									.catch(function (e) {
										console.error('Failed to load esign integration settings', e);
										setEsign({
											...es,
											integrationId: Number.NaN
										});
										Tools.NotificationService.addNotification({
											title: 'default.error',
											body: t('esign.integrationError', { name: integration.name }),
											style: Tools.NotificationService.style.ERROR,
											icon: 'times'
										});
									});
							}
						};

						if (!es.id && (!esignIntegrations.length || esignIntegrations.length > 1)) {
							esAppPlaceholderProps.visible = true;
							if (esignIntegrations.length) {
								esAppPlaceholderProps.selectMode = true;
							}
						}

						// Get apps
						if (esAppPlaceholderProps.visible) {
							if (esignIntegrations.length) {
								// If there are active integrations we only want to show these, if not we show all available so the user can activate one
								esAppPlaceholderProps.integrations = resIntegrations.data.filter((integration: any) => {
									return esignIntegrations.find(i => integration.id === i.id);
								});
							} else {
								esAppPlaceholderProps.integrations = resIntegrations.data;
							}
						}

						setParticipantSelectData([
							{
								id: 1,
								title: t('default.contacts'),
								children: availableContacts.map((c: any) => ({
									...c,
									selected: !!es.involved.find(i => i.contactId === c.id)
								})),
								disabled: true
							},
							{
								id: 2,
								title: t('default.users'),
								children: availableUsers.map((u: any) => ({
									...u,
									title: u.name,
									selected: !!es.involved.find(i => i.userId === u.id)
								})),
								disabled: true
							}
						]);

						setEsignAppPlaceholderProps(esAppPlaceholderProps);
						setMoveToStage(!!es.orderStage);
						if (es.file) {
							setPdfPath(es.file.$$previewUrl);
							setFile(es.file, false);
						}
						setEsign(es);
					}
				)
				.catch(err => {
					let title = 'default.error';
					let body = 'openError.esign';

					if (err.data?.error?.standardIntegrationError) {
						title = 'openError.esign';
						body = err.data.error.standardIntegrationError;
					}

					Tools.NotificationService.addNotification({
						title: title,
						body: body,
						style: 'error',
						icon: 'times'
					});
				});
		};
		getData();
		return () => {
			if (!cancelablePromises.current) return;
			for (const key in cancelablePromises.current) {
				cancelablePromises.current![key]?.cancel();
			}
		};
	}, []);

	async function getEsign() {
		if (id) {
			const res = await Tools.Esign.get(id);
			return res.data;
		} else if (esignInput) {
			return {
				...Tools.Esign.new(),
				...esignInput
			};
		}
	}

	async function getContacts(accountId: number): Promise<{
		data: Contact[];
		metadata: {
			total: number;
		};
	}> {
		const contactFilter = new RequestBuilder();
		contactFilter.fields = ['id', 'name', 'email', 'cellPhone', 'phone', 'client', 'title'];
		contactFilter.addFilter(Tools.Contact.attr.active, contactFilter.comparisonTypes.Equals, 1);
		contactFilter.addSort(Tools.Contact.attr.name, true);
		contactFilter.offset = 0;

		const or = contactFilter.orBuilder();
		or.next();
		or.addFilter(Tools.Contact.attr.account, contactFilter.comparisonTypes.Equals, accountId);
		or.next();
		or.addFilter(Tools.Contact.attr.connectedClients, contactFilter.comparisonTypes.Equals, accountId);
		or.done();

		return await Tools.Contact.customer(customerId).find(contactFilter.build());
	}

	async function getOpportunities(accountId: number): Promise<Order[]> {
		const orderFilter = new RequestBuilder();
		orderFilter.addFilter(Tools.Order.attr.account, orderFilter.comparisonTypes.Equals, accountId);
		orderFilter.addFilter(Tools.Order.attr.probability, orderFilter.comparisonTypes.NotEquals, 100);
		orderFilter.addFilter(Tools.Order.attr.probability, orderFilter.comparisonTypes.NotEquals, 0);

		const res = await Tools.Order.customer(customerId).find(orderFilter.build());
		return res.data;
	}

	async function getAccountFiles(accountId: number): Promise<Document[]> {
		// Get number of files(pdf) on this account
		const fileFilter = new RequestBuilder();
		fileFilter.addFilter(Tools.File.attr.account.attr.id, fileFilter.comparisonTypes.Equals, accountId);
		fileFilter.addFilter(Tools.File.attr.mimetype, fileFilter.comparisonTypes.WildcardEnd, ['pdf']);
		fileFilter.limit = 0;

		const res = await Tools.File.find(fileFilter.build());
		return res.data;
	}

	async function getAccount(accountId: number): Promise<Client> {
		const res = await Tools.Account.customer(customerId).get(accountId);
		return res.data;
	}

	async function getSettings(id: number) {
		const res = await Tools.Esign.runAction({
			type: 'settings',
			integrationId: id
		});
		return res.data;
	}

	// ************************************** COMPONENT SETTINGS

	function applySettings(settingsObj: any) {
		setSettings({
			smsDelivery: settingsObj.delivery.indexOf('sms') !== -1,
			allowDraft: settingsObj.allowDraft,
			multiSign: settingsObj.multiSign,
			userCanSign: settingsObj.userCanSign,
			bankId: settingsObj.bankIdCountries.map((x: any) => x).length,
			bankIdCountries: settingsObj.bankIdCountries,
			documentType: settingsObj.documentType,
			hideCenter: settingsObj.documentType === 'html'
		});

		if (esign) {
			// Map languages
			languages = settingsObj.languages.map((lang: string) => ({ title: 'lang.' + lang, value: lang }));

			if (languages.filter(lang => lang.value === esign.localeLanguage).length === 0) {
				esign.localeLanguage = languages.length ? languages[0].value : '';
			}
		}

		if (settingsObj.fields) {
			setFields(settingsObj.fields);
		}
	}

	// ************************************** COMPONENT SETUP

	if (!esign) return null;

	const useAjaxParticipantSelect = contacts.metadata.total > 1000;

	// ************************************** LANG TAGS

	const lang = {
		headerText: `${isEdit ? t('default.edit') : t('default.new2')} ${t('default.eSign')} ${t(
			'default.at'
		).toLowerCase()} ${esign.client.name}`,
		mailSender: t('mail.sender'),
		senderSigning: t('esign.senderSigning'),
		showUserSign: t('esign.showUserSign'),
		sentToSenderBy: t('esign.sentToSenderBy'),
		email: t('esign.email'),
		phoneFormatInfo: t('esign.phoneFormatInfo'),
		willSignWithBankId: t('esign.willSignWithBankId'),
		willSignWithBankIdInfo: t('esign.willSignWithBankIdInfo'),
		personalNumber: t('esign.personalNumber'),
		recievers: t('mail.recievers'),
		signOrderInfo: t('esign.signOrderInfo'),
		isSigningBankId: t('esign.isSigningBankId'),
		sentWithEmail: t('esign.sentWithEmail'),
		andSms: t('esign.andSms'),
		emailMissingInfo: t('esign.emailMissingInfo'),
		edit: t('default.edit'),
		contact: t('default.contact'),
		hide: t('default.hide'),
		show: t('default.show'),
		sentBy: t('esign.sentBy'),
		removeAsReciever: t('esign.removeAsReciever'),
		noInvolvees: t('esign.noInvolvees'),
		contacts: t('default.contacts'),
		users: t('default.users'),
		relatedOpportunity: t('esign.relatedOpportunity'),
		selectOpportunity: t('esign.selectOpportunity'),
		moveToStageSign: t('esign.moveToStageSign'),
		selectStage: t('esign.selectStage'),
		language: t('default.language'),
		selectExistingFile: t('esign.selectExistingFile'),
		dropPdfHereOr: t('esign.dropPdfHereOr'),
		uploadFromComputer: t('esign.uploadFromComputer'),
		dropPdfNow: t('esign.dropPdfNow'),
		uploadFail: t('mail.uploadFail'),
		uploading: t('esign.uploading'),
		docToSign: t('esign.docToSign'),
		remove: t('default.remove'),
		loadingPreview: t('esign.loadingPreview'),
		previewNotAvailable: t('document.previewNotAvailable'),
		downloadFile: t('document.downloadFile'),
		sendAndConfirm: t('esign.sendAndConfirm'),
		buttonText: isEdit ? t('esign.viewEsign') : t('esign.createEsign'),
		save: t('default.save'),
		abort: t('default.abort'),
		select: t('default.select')
	};

	// ************************************** FUNCTIONS

	function isUser(person: any) {
		return person.role !== undefined;
	}

	function personSelected(person: any) {
		const isInvolved = esign!.involved.find(
			p => (p.userId === person.id && isUser(person)) || (p.contactId === person.id && !isUser(person))
		);
		const isSigningSender = senderSigns && isUser(person) && person.userId === esign!.user.id;
		return isInvolved || isSigningSender;
	}

	function validateInvolvedForView(person: { [key: string]: any }) {
		const phoneValid = validPhoneNr(person.mobile);
		const emailValid = validEmail(person.email);

		if (!phoneValid) {
			person.$invalidPhone = true;
			person.deliveryMethod = 'email';
		} else {
			person.$invalidPhone = false;
		}

		if (!emailValid) {
			person.$invalidEmail = true;
		} else {
			person.$invalidEmail = false;
		}
	}

	function validPhoneNr(nr: string) {
		if (nr && nr.length && nr[0] !== '+') {
			return false;
		}
		return true;
	}

	function validEmail(email: string) {
		const re =
			/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line no-useless-escape
		const isEmail = re.test(email);
		if (email && isEmail) {
			return true;
		}

		return false;
	}

	function editContact(person: { [key: string]: any }) {
		if (person.contactId) {
			// eslint-disable-next-line promise/catch-or-return
			Tools.$upModal.open('editContact', { id: person.contactId }).then(updatedContact => {
				const contactInvolved = Tools.Esign.newInvolved({ contact: updatedContact });
				validateInvolvedForView(contactInvolved);
				angular.extend(person, contactInvolved);
			});
		}
	}

	function selectPerson(index: number) {
		if (selectedPerson === index) {
			setSelectedPerson(null);
			return;
		}
		setSelectedPerson(index);
		setShowSenderInfo(false);
	}

	function removeInvolvee(index: number) {
		setSelectedPerson(null);
		if (!esign) return;
		setEsign({
			...esign,
			involved: esign.involved.filter((_, i) => i !== index)
		});
	}

	function findSenderInInvolvees() {
		return esign!.involved.find(person => person.userId === esign!.user.id);
	}

	function setFile(inputFile: Esign['file'], updateEsign: boolean = true) {
		if (!inputFile) return;
		setFileStatus(fileStatuses.SET);
		const tempPdfPath = getUrl(inputFile.id.toString());

		cancelablePromises.current.setFile = makeCancelable(axios.get(tempPdfPath));
		cancelablePromises.current.setFile.promise
			.then(function () {
				// Set file to esign
				if (updateEsign) {
					setEsign({
						...esign!,
						file: {
							...inputFile,
							$$previewUrl: tempPdfPath
						},
						title: inputFile.filename
					});
				}

				// Generate url
				setPdfPath(tempPdfPath);
			})
			.catch(function () {
				setFileStatus(fileStatuses.ERROR);

				Tools.NotificationService.addNotification({
					title: 'default.error',
					body: 'mail.uploadFail',
					style: Tools.NotificationService.style.ERROR,
					icon: 'times'
				});
			});
	}

	function getUrl(id: string) {
		return Tools.URL + Tools.API + customerId + '/resources/download/internal/' + id + '?inline=true';
	}

	function removeFile() {
		// We do not want to delete non-esign files
		if (esign!.file?.entity !== 'Esign') {
			clearFileAndSave();
			return;
		}

		if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
			openModal('RemoveAlert', {
				title: 'file.file',
				body: 'esign.removeFileConfirm',
				onClose: confirmed => {
					if (confirmed) {
						// Delete file then set new stuff in ctrl
						Tools.File.customer(customerId)
							.delete(esign!.file!)
							.then(function () {
								clearFileAndSave();
							})
							.catch(e => {
								logError(e, 'Failed deleting file');
							});
					}
				}
			});
			return;
		}

		// eslint-disable-next-line promise/catch-or-return
		Tools.$upModal
			.open('warningConfirm', {
				title: 'file.removeFile',
				body: 'esign.removeFileConfirm',
				resolveTrue: 'default.remove',
				icon: 'fa-warning'
			})
			.then(function () {
				// Delete file then set new stuff in ctrl
				Tools.File.customer(customerId)
					.delete(esign!.file!)
					.then(function () {
						clearFileAndSave();
					})
					.catch(e => {
						logError(e, 'Failed deleting file');
					});
			});
	}

	function clearFileAndSave() {
		setFileStatus(fileStatuses.EMPTY);
		setPdfPath(null);
		setEsign({
			...esign!,
			file: null
		});

		// Save esign with no file
		if (esign!.id) {
			Tools.Esign.save({ ...esign!, file: null }, { skipNotification: true });
		}
	}

	const onDropFile: <File>(rawfile: File) => void = rawfile => {
		setPdfLoading(true);

		cancelablePromises.current.uploadFile = makeCancelable(
			File.upload(rawfile, {
				esign: true,
				customerId: customerId,
				onUploadProgress: (progress: number) => {}
			})
		);
		cancelablePromises.current.uploadFile.promise
			.then(res => {
				setFile(res);
				Tools.NotificationService.addNotification({
					style: Tools.NotificationService.style.SUCCESS,
					title: 'default.done',
					body: 'file.fileUploaded',
					icon: 'check'
				});

				Tools.$rootScope.$broadcast('file.uploaded', res);
				setPdfLoading(false);
			})
			.catch(err => {
				var errorBody = 'file.uploadFailed';

				const error = err?.response?.data?.error;

				if (error.errorCode === 114) {
					errorBody = 'file.uploaded.invalidType';
				} else if (err === 'file.tooLarge' || error.errorCode === 148) {
					errorBody = 'file.tooLarge';
				}
				Tools.NotificationService.addNotification({
					style: Tools.NotificationService.style.ERROR,
					title: 'default.error',
					body: errorBody,
					icon: 'times'
				});
				Tools.$rootScope.$broadcast('file.uploadFailed', error);
				setPdfLoading(false);
			});
	};

	function openHtmlFrame() {
		if (Tools.FeatureHelper.hasSoftDeployAccess('ESIGN_VIEW_NEW')) {
			openHtmlFrameNew();
		} else if (!openingHtmlFrame) {
			setOpeningHtmlFrame(true);
			save(true)
				.then(function (res: { data: { id: any } }) {
					// This will be the case if the validation in save fails
					if (!res || !res.data || !res.data.id) {
						return;
					}
					setOpeningHtmlFrame(false);
					var opts = {
						name: 'esignDocument',
						type: 'editEsign',
						integrationId: esign!.integrationId,
						objectId: res.data.id || null,
						fullscreen: true
					};
					openIntegrationModal(opts);
				})
				.catch((e: any) => {
					logError(e, 'Failed saving Esign');
				})
				.finally(() => {
					setOpeningHtmlFrame(false);
				});
		}
	}

	async function openHtmlFrameNew() {
		if (openingHtmlFrame) return;

		setOpeningHtmlFrame(true);
		let esignId = esign!.id;

		if (!esignId) {
			try {
				const res = await save(true);
				if (!res || !res.data || !res.data.id) {
					return;
				}
				esignId = res.data.id;
			} catch (e) {
				logError(e, 'Failed saving Esign');
			} finally {
				setOpeningHtmlFrame(false);
			}
		}

		const opts = {
			name: 'esignDocument',
			type: 'editEsign',
			integrationId: esign!.integrationId,
			objectId: esignId || null,
			fullscreen: true
		};
		openIntegrationModal(opts);
		setOpeningHtmlFrame(false);
	}

	function save(send: boolean): any {
		// Validate form
		return validateForm(send)
			.then(function () {
				const esignCopy = _.cloneDeep(esign!);

				// Insert sender to involvees
				if (senderSigns && !findSenderInInvolvees()) {
					esignCopy.involved.unshift(senderSignModel);
				}

				if (send) {
					setSending(true);
					esignCopy.sendToScrive = true;
				} else {
					setSaving(true);
					esignCopy.sendToScrive = false;
				}

				var savePromise;
				if (send && settings.documentType !== 'html') {
					savePromise = new Promise<void>((resolve, reject) => {
						if (Tools.FeatureHelper.hasSoftDeployAccess('CONFIRM_ESIGN_REACT')) {
							openModal('ConfirmEsignModal', {
								esign: esignCopy,
								onClose: confirmed => (confirmed ? resolve() : reject())
							});
						} else {
							Tools.$upModal
								.open('confirmEsign', {
									esign: esignCopy
								})
								.then(resolve)
								.catch(reject);
						}
					});
				} else {
					var integration = _.find(Tools.AppService.getEsignIntegrations(), { id: esignCopy.integrationId });
					if (!esignCopy.title && integration) {
						esignCopy.title = integration.name;
					}
					savePromise = Tools.Esign.save(esignCopy);
				}

				return savePromise
					.then(function (res: any) {
						close();
						return res;
					})
					.catch(() => {})
					.finally(function () {
						setSaving(false);
						setSending(false);
					});
			})
			.catch(function (err: string) {
				// Ignore if modal was rejected due to state change
				if (err === 'State changed') {
					return;
				}
				if (typeof err === 'string') {
					if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
						openModal('Alert', {
							title: 'esign.formError',
							body: 'esign.formError.' + err,
							confirmButtonText: 'default.close',
							headerIcon: 'warning'
						});
						return;
					}

					Tools.$upModal.open('warningConfirm', {
						title: 'esign.formError',
						body: 'esign.formError.' + err,
						no: 'default.close',
						icon: 'fa-warning'
					});
				} else {
					throw err;
				}
			});
	}

	function validateForm(sendToScrive: boolean) {
		return new Promise<void>((resolve, reject) => {
			if (sendToScrive && !(esign!.file && esign!.file.id) && settings.documentType !== 'html') {
				reject('MISSING_FILE');
			} else if (!esign!.involved || !esign!.involved.length) {
				reject('NO_INVOLVEES');
			} else {
				const invalidEmailPerson = esign!.involved.find((person: any) => person.$invalidEmail);
				if (invalidEmailPerson) {
					reject('INVALID_EMAIL');
				} else {
					resolve();
				}
			}
		});
	}

	const metadata = AppService.getMetadata();
	const masterCurrency = metadata.customerCurrencies.find(
		(currency: { masterCurrency: boolean }) => currency.masterCurrency
	);

	function addInvolvee(user: BasicUserWithPermissions | Contact) {
		let add: any = {};
		if (user?.hasOwnProperty('role')) {
			add = Tools.Esign.newInvolved({ user: user });
		} else {
			add = Tools.Esign.newInvolved({ contact: user });
		}

		validateInvolvedForView(add);

		add.bankIdCountry = { id: settings.bankId ? settings.bankIdCountries[0].id : null };

		setEsign({
			...esign!,
			involved: [...esign!.involved, add]
		});

		if (user) {
			const involvedIndex = esign!.involved.findIndex(p => {
				if (user.hasOwnProperty('role')) {
					return p.userId === user.id;
				}
				return p.contactId === user.id;
			});
			selectPerson(involvedIndex);
		}

		setPersonToAdd(null);
	}

	function browseFiles() {
		var browserOptions = {
			types: ['pdf'],
			disableUpload: true,
			filters: [{ a: File.attr.account.attr.id, c: 'eq', v: esign!.client.id }]
		};

		// eslint-disable-next-line promise/catch-or-return
		openFileBrowserModal(browserOptions).then(function (file) {
			setFile(file[0]);
		});
	}

	return (
		<Modal
			className={`${classes.b()} ${
				settings.hideCenter ? 'esign-narrow' : ''
			} notranslate form default dynamic pattern no-border wide-sidebar ng-scope compiled has-controls up-modal`}
			size="xl"
			style={{ display: 'block', height: '657px' }}
		>
			<form
				autoComplete="off"
				id="esign-modal"
				name="EsignForm"
				scroll-in=".up-modal-content"
				className={esignAppPlaceholderProps.visible ? 'select-app-mode' : ''}
			>
				<div className="up-modal-content has-sidebar">
					<div className="modal-sidebar">
						<h2>
							<Icon name="esign" />
							&nbsp;{lang.headerText}
						</h2>
						<div className="sidebar-section no-border">
							<Label>{lang.mailSender}</Label>
							<Select<Esign['user'] & SelectItem>
								options={users.map(user => ({ ...user, title: user.name }))}
								value={{ ...esign.user, title: esign.user.name }}
								onChange={user => {
									const newUser = Tools.Esign.newInvolved({ user: user });
									validateInvolvedForView(newUser);
									setSenderSignModel(newUser);
									setEsign({
										...esign,
										user: user
									});
								}}
								disabled={
									saving ||
									sending ||
									esignAppPlaceholderProps.visible ||
									(!settings.allowDraft && !!esign.id)
								}
								anchor={anchor.current}
							/>
						</div>
						<div className="sidebar-section no-border no-margin">
							{settings.multiSign && settings.userCanSign && (
								/* SENDER SIGN */
								<div id="sender-sign-row">
									<label>
										<Toggle
											id="user-sign-toggle"
											className="pull-right"
											checked={senderSigns}
											onChange={active => {
												setSenderSigns(active);
												setShowSenderInfo(active);

												if (active) {
													const user = Tools.Esign.newInvolved({ user: esign.user });
													validateInvolvedForView(user);
													setSenderSignModel({
														...user,
														bankIdCountry: {
															id: settings.bankId ? settings.bankIdCountries[0].id : null
														}
													});
												}

												setSelectedPerson(null);
												setSenderSigns(!senderSigns);
											}}
											disabled={
												saving ||
												sending ||
												esignAppPlaceholderProps.visible ||
												(!settings.allowDraft && !!esign.id)
											}
										/>
										{lang.senderSigning}
									</label>
								</div>
							)}
							{senderSigns && (
								<div
									className={`involvee-row-wrap ${showSenderInfo ? 'row-open' : ''}`}
									data-test-id="esign-user-sign-options"
								>
									<button
										type="button"
										id="toggle-sender-info"
										onClick={() => {
											setShowSenderInfo(true);
											setSelectedPerson(null);
										}}
									>
										<span>{lang.showUserSign}</span>
									</button>
									{showSenderInfo && (
										<>
											<div className="involvee-options">
												<div className="option-row option-label">
													<span>{lang.sentToSenderBy}</span>:
												</div>
												<div className="option-row">
													<Flex gap="8px" alignItems="center">
														<Checkbox checked disabled size="xs" />
														<span>{lang.email}</span>
														<div className="option-value">
															<span>{senderSignModel?.email}</span>
														</div>
													</Flex>
												</div>
												{!!settings.smsDelivery && (
													<div className="option-row">
														<Flex gap="8px" alignItems="center">
															<Checkbox
																size="xs"
																onChange={e => {
																	setSenderSignModel({
																		...senderSignModel,
																		deliveryMethod:
																			senderSignModel?.deliveryMethod === 'email'
																				? 'email_mobile'
																				: 'email'
																	});
																}}
																checked={
																	senderSignModel?.deliveryMethod === 'email_mobile'
																}
																disabled={
																	!senderSignModel?.mobile ||
																	senderSignModel?.$invalidPhone ||
																	(!settings.allowDraft && !!esign.id)
																}
															/>
															<span>SMS</span>
															<div className="option-value">
																<span>{senderSignModel?.mobile}</span>
																{senderSignModel?.$invalidPhone && (
																	<b
																		className="fa fa-warning"
																		data-placement="right"
																		data-container="body"
																	>
																		{lang.phoneFormatInfo}
																	</b>
																)}
															</div>
														</Flex>
													</div>
												)}
												{settings.bankId && (
													<div className="option-row">
														<div style={{ position: 'relative' }}>
															<span>{lang.willSignWithBankId}</span>
															<Toggle
																className="pull-right"
																checked={senderSignModel?.authMethod === 'eleg'}
																onChange={active => {
																	setSenderSignModel({
																		...senderSignModel,
																		authMethod: active ? 'eleg' : 'standard'
																	});
																}}
																disabled={
																	saving ||
																	sending ||
																	(!settings.allowDraft && !!esign.id)
																}
															/>
															{senderSignModel?.authMethod === 'eleg' && (
																<div style={{ marginTop: '8px', marginBottom: '8px' }}>
																	<CountrySelect
																		value={
																			senderSignModel.bankIdCountry?.id || null
																		}
																		onChange={country => {
																			setSenderSignModel({
																				...senderSignModel,
																				bankIdCountry: country || null
																			});
																		}}
																		availableCountries={settings.bankIdCountries}
																		anchor={anchor.current!}
																		size="sm"
																	/>
																</div>
															)}
															<div className="bank-id-info">
																{lang.willSignWithBankIdInfo}
															</div>
														</div>
													</div>
												)}
												{senderSignModel?.authMethod === 'eleg' &&
													senderSignModel?.bankIdCountry?.id !== 'se' && (
														<div className="option-row">
															<label className="normal">{lang.personalNumber}</label>
															<div className="option-value">
																<input
																	type="text"
																	className="option-input"
																	value={senderSignModel?.personalnr || ''}
																	onChange={e => {
																		setSenderSignModel({
																			...senderSignModel,
																			personalnr: e.target.value
																		});
																	}}
																	disabled={!settings.allowDraft && !!esign.id}
																/>
															</div>
															<div className="clearfix"></div>
														</div>
													)}
											</div>
										</>
									)}
								</div>
							)}
						</div>
						<div className="sidebar-section no-border no-margin" style={{ position: 'relative' }}>
							<label>{lang.recievers}</label>
							{settings.userCanSign && (
								<div
									style={{ position: 'absolute', top: 0, right: 0, fontSize: '12px', color: '#999' }}
								>
									<span data-elevio-article="282">{lang.signOrderInfo}</span>
								</div>
							)}
							{esign.involved.map(
								(
									person: EsignInvolved & {
										$invalidEmail?: boolean;
										$invalidPhone?: boolean;
										bankIdCountry?: { id: string };
									},
									index: number
								) => (
									<div
										key={person.id}
										className={`involvee-row-wrap ${selectedPerson === index ? 'row-open' : ''} ${
											person.contactId ? 'is-contact' : ''
										} ${person.$invalidEmail ? 'missing-email' : ''}`}
									>
										<div className="involvee-row">
											<Avatar
												className="generic-avatar"
												type="round"
												initials={`${person.fstname} ${person.sndname}`}
												size="sm"
											/>
											<span
												className="person-name"
												data-test-id={`esign-involvee-${index}`}
											>{`${person.fstname} ${person.sndname}`}</span>

											<div className="subtitle">
												{person.authMethod === 'eleg' && <span>{lang.isSigningBankId}</span>}
												{person.authMethod === 'standard' && (
													<>
														<span>{lang.sentWithEmail}</span>
														{person.deliveryMethod === 'email_mobile' && (
															<span>{lang.andSms}</span>
														)}
													</>
												)}
											</div>
											<b
												className="fa fa-warning missing-email-indicator"
												data-title={lang.emailMissingInfo}
												data-placement="right"
												data-container="body"
											></b>
											{person.contactId && (
												<button
													type="button"
													className="edit-contact-btn"
													data-title={`${lang.edit} ${lang.contact}`.toLowerCase()}
													data-placement="left"
													data-container="body"
													onClick={() => editContact(person)}
													disabled={!settings.allowDraft && !!esign.id}
												>
													<b className="fa fa-edit"></b>
												</button>
											)}
											<button
												type="button"
												className="toggle-row"
												onClick={() => selectPerson(index)}
											>
												<span>{selectedPerson === index ? lang.hide : lang.show}</span>
											</button>
										</div>
										{selectedPerson === index && (
											<>
												<div className="involvee-options">
													<div className="option-row option-label">
														<span>{lang.sentBy}:</span>
													</div>
													<div className="option-row">
														<Flex alignItems="center" gap="8px">
															<Checkbox checked={true} size="xs" disabled />
															<Text>{lang.email}</Text>
															<div className="option-value">
																<span>{person.email}</span>
																{person.$invalidEmail && (
																	<b
																		className="fa fa-warning"
																		data-title={lang.emailMissingInfo}
																		data-placement="right"
																		data-container="body"
																	></b>
																)}
															</div>
														</Flex>
													</div>
													{!!settings.smsDelivery && (
														<div className="option-row">
															<Flex alignItems="center" gap="8px">
																<Checkbox
																	checked={person.deliveryMethod === 'email_mobile'}
																	size="xs"
																	disabled={
																		!person.mobile ||
																		person.$invalidPhone ||
																		(!settings.allowDraft && !!esign.id)
																	}
																	onChange={() => {
																		setEsign({
																			...esign,
																			involved: esign.involved.map(
																				(involved, i) => {
																					if (i === index) {
																						return {
																							...involved,
																							deliveryMethod:
																								involved.deliveryMethod ===
																								'email'
																									? 'email_mobile'
																									: 'email'
																						};
																					}
																					return involved;
																				}
																			)
																		});
																	}}
																/>
																<Text>SMS</Text>
																<div className="option-value">
																	<span>{person.mobile}</span>
																	{person.$invalidPhone && (
																		<b
																			className="fa fa-warning"
																			data-title={lang.phoneFormatInfo}
																			data-placement="right"
																			data-container="body"
																		></b>
																	)}
																</div>
															</Flex>
														</div>
													)}
													{!!settings.bankId && (
														<div className="option-row">
															<div style={{ position: 'relative' }}>
																<span>{lang.willSignWithBankId}</span>
																<input
																	type="checkbox"
																	className="pull-right toggle-bright-blue"
																	checked={person.authMethod === 'eleg'}
																	onChange={() => {
																		setEsign({
																			...esign!,
																			involved: esign!.involved.map(
																				(involved, i) => {
																					if (i === index) {
																						return {
																							...involved,
																							authMethod:
																								involved.authMethod ===
																								'eleg'
																									? 'standard'
																									: 'eleg'
																						};
																					}
																					return involved;
																				}
																			)
																		});
																	}}
																	disabled={
																		saving ||
																		sending ||
																		(!settings.allowDraft && !!esign.id)
																	}
																/>
																<div className="bank-id-info">
																	{lang.willSignWithBankIdInfo}
																</div>
																{person.authMethod === 'eleg' && (
																	<div
																		style={{
																			marginTop: '8px',
																			marginBottom: '8px'
																		}}
																	>
																		<CountrySelect
																			value={person.bankIdCountry?.id || null}
																			onChange={country => {
																				setEsign({
																					...esign!,
																					involved: esign!.involved.map(
																						(involved, i) => {
																							if (i === index) {
																								return {
																									...involved,
																									bankIdCountry:
																										country || null
																								};
																							}
																							return involved;
																						}
																					)
																				});
																			}}
																			availableCountries={
																				settings.bankIdCountries
																			}
																			anchor={anchor.current!}
																			size="sm"
																			disabled={
																				!settings.allowDraft && !!esign.id
																			}
																		/>
																	</div>
																)}
																{person.authMethod === 'eleg' &&
																	person.bankIdCountry?.id &&
																	person.bankIdCountry?.id !== 'SE' && (
																		<div className="option-row">
																			<label className="normal">
																				{lang.personalNumber}
																			</label>
																			<div className="option-value">
																				<input
																					type="text"
																					style={{
																						borderBottomColor: '#ddd'
																					}}
																					className="option-input"
																					value={person.personalnr || ''}
																					disabled={
																						!settings.allowDraft &&
																						!!esign.id
																					}
																					onChange={e => {
																						setEsign({
																							...esign!,
																							involved:
																								esign!.involved.map(
																									(involved, i) => {
																										if (
																											i === index
																										) {
																											return {
																												...involved,
																												personalnr:
																													e
																														.target
																														.value
																											};
																										}
																										return involved;
																									}
																								)
																						});
																					}}
																				/>
																			</div>
																			<div className="clearfix"></div>
																		</div>
																	)}
															</div>
														</div>
													)}
													<div className="option-row"></div>
													<button
														className="remove-involvee"
														type="button"
														onClick={() => {
															removeInvolvee(index);
														}}
														disabled={!settings.allowDraft && !!esign.id}
													>
														<b className="fa fa-trash-o"></b>{' '}
														<span>{lang.removeAsReciever}</span>
													</button>
												</div>
											</>
										)}
									</div>
								)
							)}
							{!esign.involved.length && <div id="involvees-placeholder">{lang.noInvolvees}</div>}
						</div>
						{(!esign.involved.length || settings.multiSign) && (
							<div
								id="involvee-select-wrap"
								className={`sidebar-section no-margin ${esign.involved.length ? '' : 'no-involvees'}`}
							>
								{!useAjaxParticipantSelect && (
									<Select<SelectItem>
										id="participant-select"
										className="no-transition"
										options={participantSelectData.map(item => ({
											...item,
											children: item.children.filter(c => !personSelected(c))
										}))}
										value={personToAdd}
										onChange={(item: SelectItem) => {
											setPersonToAdd(item);
											(item as any).selected = true;
											addInvolvee(item as any);
										}}
										hideSelected={true}
										placeholder={`${lang.select} ${lang.recievers.toLowerCase()}`}
										disabled={
											saving ||
											sending ||
											esignAppPlaceholderProps.visible ||
											(!settings.allowDraft && !!esign.id)
										}
										anchor={anchor.current}
									/>
								)}
								{useAjaxParticipantSelect && (
									<SelectAsync<SelectItem>
										id="participant-select"
										className="no-transition"
										value={personToAdd}
										onChange={(item: SelectItem) => {
											setPersonToAdd(item);
											const person =
												users.find(u => u.id === item.id) ||
												contacts.data.find(c => c.id === item.id) ||
												null;
											if (person) {
												addInvolvee(person);
											}
										}}
										hideSelected={true}
										placeholder={`${lang.select} ${lang.recievers.toLowerCase()}`}
										disabled={
											saving ||
											sending ||
											esignAppPlaceholderProps.visible ||
											(!settings.allowDraft && !!esign.id)
										}
										anchor={anchor.current}
										fetcher={async searchStr => {
											if (searchStr && esign.client) {
												const contactFilter = new RequestBuilder();
												contactFilter.addFilter(
													Tools.Contact.attr.name,
													contactFilter.comparisonTypes.Search,
													searchStr
												);
												contactFilter.addFilter(
													Tools.Contact.attr.client.attr.id,
													contactFilter.comparisonTypes.Equals,
													esign.client.id
												);
												return await Tools.Contact.customer(customerId)
													.find(contactFilter.build())
													.then(res => {
														return res.data
															.filter((d: any) => !personSelected(d))
															.map((d: any) => ({ ...d, title: d.name }));
													});
											}

											return [];
										}}
									/>
								)}
							</div>
						)}
						{!!opportunities.length && (
							<div className="sidebar-section no-border">
								<label>{lang.relatedOpportunity}</label>
								<Select<Esign['opportunity'] & SelectItem>
									id="opportunity-select"
									className="align-right"
									options={opportunities.map(opportunity => ({
										...opportunity,
										title: opportunity.description
									}))}
									value={
										esign.opportunity && {
											...esign.opportunity,
											title: esign.opportunity.description
										}
									}
									onChange={opportunity => {
										setEsign({ ...esign, opportunity: opportunity });
									}}
									placeholder={lang.selectOpportunity}
									disabled={
										saving ||
										sending ||
										!opportunities.length ||
										esignAppPlaceholderProps.visible ||
										(!settings.allowDraft && !!esign.id)
									}
									anchor={anchor.current}
									renderItem={(item: Esign['opportunity']) => {
										const opportunity = opportunities.find(o => o.id === item!.id)!;
										return (
											<Flex style={{ width: '100%' }}>
												<Flex direction="column">
													<b>
														{Tools.$filter('upsalesDate')(opportunity.date)}{' '}
														{opportunity.description}
													</b>
													{Tools.$filter('currencyFormat')(
														opportunity.value,
														masterCurrency!.iso
													)}
												</Flex>
												<span
													style={{
														flexGrow: 1,
														alignSelf: 'flex-end',
														textAlign: 'right'
													}}
												>
													{opportunity.user ? opportunity.user.name : ''}
												</span>
											</Flex>
										);
									}}
									onClear={() => {
										setEsign({ ...esign, opportunity: null, orderStage: null });
										setMoveToStage(false);
									}}
								/>
							</div>
						)}
						{esign.opportunity && (
							<div>
								<label className="normal">
									<Flex gap="8px" alignItems="center">
										<Checkbox
											checked={moveToStage}
											onChange={checked => {
												setMoveToStage(checked);
												if (!checked) {
													setEsign({ ...esign, orderStage: null });
												}
											}}
											disabled={!settings.allowDraft && !!esign.id}
											size="xs"
										/>
										<span>{lang.moveToStageSign}</span>
									</Flex>
								</label>
							</div>
						)}
						{moveToStage && (
							<div className="sidebar-section">
								<Select<Esign['orderStage'] & SelectItem>
									value={
										esign.orderStage && {
											title: esign.orderStage.name,
											...esign.orderStage
										}
									}
									options={stages.map(stage => ({
										...stage,
										title: stage.name
									}))}
									onChange={val => setEsign({ ...esign, orderStage: val })}
									placeholder={lang.selectStage}
									required
									anchor={anchor.current}
								/>
							</div>
						)}
						{!esignAppPlaceholderProps.visible && !!languages.length && (
							<div className="sidebar-section">
								<label>{lang.language}</label>
								<Select<SelectItem>
									value={{
										id: esign.localeLanguage,
										title: esign.localeLanguage
									}}
									options={languages.map(lang => ({
										id: lang.value,
										title: lang.title
									}))}
									onChange={(val: SelectItem) =>
										setEsign({ ...esign, localeLanguage: val.id.toString() })
									}
									disabled={!!(!settings.allowDraft && esign.id)}
									anchor={anchor.current}
								/>
							</div>
						)}
						{!esignAppPlaceholderProps.visible &&
							fields.map(field => (
								<div key={field.title} className="sidebar-section">
									<div style={{ display: 'flex', gap: '4px' }}>
										<label>{field.label}</label>
										{field.required && <b className="text-danger">*</b>}
									</div>
									{field.type === 'select' && (
										<Select<SelectItem>
											value={
												(esign.fieldValues as any)?.[field.name] && {
													id: (esign.fieldValues as any)[field.name],
													title: field.data.find(
														d => d.value === (esign.fieldValues as any)[field.name]
													)?.name
												}
											}
											options={field.data.map(field => ({
												id: field.value,
												title: field.name
											}))}
											onChange={(val: SelectItem) =>
												setEsign({
													...esign,
													fieldValues: { ...esign.fieldValues, [field.name]: val.id }
												})
											}
											disabled={!!(!settings.allowDraft && esign.id)}
											anchor={anchor.current}
										/>
									)}
								</div>
							))}
					</div>
					<div id="esign-app-modal-placeholder" className="modal-center">
						<ReactTemplates.esignAppPlaceholder {...esignAppPlaceholderProps} />
					</div>
					{!esignAppPlaceholderProps.visible && !settings.hideCenter && (
						<div className="modal-center">
							<button
								type="button"
								className="btn btn-link modal-close"
								onClick={() => close()}
								disabled={settings.allowDraft && !!esign.id}
							>
								<i className="fa fa-times"></i>
							</button>
							<div id="file-wrapper">
								{/* FILE - PLACEHOLDER */}
								{(fileStatus === fileStatuses.EMPTY || fileStatus === fileStatuses.ERROR) &&
									!pdfLoading && (
										<>
											<FileUpload
												onDrop={files => onDropFile(files[0])}
												accept={['.pdf']}
												className="file-dropzone"
											>
												{({ open, isDragActive }) => (
													<>
														<div style={{ height: '100%' }}>
															<FileUploadDropzoneActive visible={isDragActive} />
															<FileUploadDropzone
																onClick={open}
																title={
																	fileStatus === fileStatuses.ERROR
																		? lang.uploadFail
																		: lang.docToSign
																}
																iconName={
																	fileStatus === fileStatuses.ERROR
																		? 'frown'
																		: 'file-pdf'
																}
															/>
														</div>
														{!!accountFiles.length && (
															<button
																id="browse-files-btn"
																type="button"
																className="up-btn btn-lined no-shadow btn-bright-blue hide-on-hover"
																onClick={browseFiles}
																disabled={!settings.allowDraft && !!esign.id}
															>
																{lang.selectExistingFile}
															</button>
														)}
													</>
												)}
											</FileUpload>
										</>
									)}
								{/* FILE - UPLOADING */}
								{fileStatus === fileStatuses.UPLOADING && (
									<div id="file-preview" className="is-set no-animate">
										<div className="upload-status">
											<b className="file-icon fa fa-refresh fa-spin"></b>
											<span>{lang.uploading}</span>...
										</div>
									</div>
								)}
								{/* FILE - PREVIEW */}
								<div id="file-preview" className="is-set no-animate" ng-switch-when="SET">
									<div id="preview-area" className={pdfLoading ? 'loading-preview' : ''}>
										<div id="loading-preview">
											<div className="upload-status">
												<b className="file-icon fa fa-refresh fa-spin"></b>
												<span>{lang.loadingPreview}</span>...
											</div>
										</div>

										{/* Preview not available */}
										{noPreview && (
											<div className="upload-status">
												<span>{lang.previewNotAvailable}</span>
												<br />
												{pdfPath !== null && (
													<a href={pdfPath} download>
														<b className="fa fa-download"></b>
														<span>{lang.downloadFile}</span>
													</a>
												)}
											</div>
										)}

										{!noPreview && pdfPath !== null && (
											<>
												<Text>{lang.docToSign}</Text>
												<Row style={{ alignItems: 'center' }}>
													<Text size="sm" id="pdf-filename">
														{esign.file?.filename}
													</Text>
													<ThirdButton text={lang.remove} size="sm" onClick={removeFile} />
												</Row>
												<PdfGeneric
													id="pdf-viewer"
													url={pdfPath}
													scale={1}
													showToolbar={false}
													loading={pdfLoading}
													parentSelector={'#file-preview'}
												/>
											</>
										)}
									</div>
								</div>
							</div>
						</div>
					)}
				</div>
				<div className="up-modal-controls">
					{!esignAppPlaceholderProps.visible && settings.documentType !== 'html' && (
						<button
							type="submit"
							className="btn up-btn btn-green no-shadow main-action"
							onClick={() => save(true)}
							data-test-id="esign-send"
						>
							{lang.sendAndConfirm}
						</button>
					)}

					{!esignAppPlaceholderProps.visible && settings.documentType === 'html' && (
						<button
							type="button"
							onClick={openHtmlFrame}
							className="btn up-btn btn-green no-shadow main-action"
							disabled={openingHtmlFrame}
						>
							<span className={isEdit ? '' : `fa fa-refresh ${openingHtmlFrame ? 'fa-spin' : ''}`}></span>
							&nbsp;
							<span>{lang.buttonText}</span>
						</button>
					)}

					{!esignAppPlaceholderProps.visible && settings.allowDraft && (
						<button
							type="button"
							className="btn up-btn btn-green btn-lined no-shadow"
							onClick={() => save(false)}
							data-test-id="esign-save"
						>
							<span className={`fa fa-refresh ${saving ? 'fa-spin' : ''}`}></span>
							&nbsp;
							<span>{lang.save}</span>
						</button>
					)}

					<button type="button" className="btn up-btn btn-grey btn-link" onClick={() => close()}>
						{lang.abort}
					</button>
				</div>

				<div className="sidebar-bg"></div>
			</form>
		</Modal>
	);
};

const openEditEsign = asyncModalAdapter({
	upModalName: 'editEsign',
	openModalName: 'EditEsign',
	featureFlag: 'REACT_EDIT_ESIGN',
	rejectOnEmpty: false
});

export default openEditEsign;
