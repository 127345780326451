/* eslint-disable you-dont-need-lodash-underscore/find-index */
/* eslint-disable you-dont-need-lodash-underscore/map */

/*
IMPORTANT INFO:
This port/component is weird since it ports controller in /ui/app/upsales/domain/form/controllers/landingpageEditor.js
which is used as controller for both landingpageEditor modal and route in /ui/app/upsales/domain/form/form.js
so we need hacky logic to make it work both as a modal and a route
*/
import React, { useEffect, useMemo, useState } from 'react';
import LandingPageTemplateResource from 'App/resources/LandingPageTemplate';
import { asyncModalAdapter } from 'App/helpers/angularPortingHelpers';
import RequestBuilder from 'Resources/RequestBuilder';
import T from 'Components/Helpers/translate';
import _ from 'lodash';
import { ModalProps, useModalClose } from 'App/components/Modals/Modals';
import { openConfirmLandingPageModal } from 'Components/Modals/ConfirmLandingPage';
import openModal from 'App/services/Modal';
import { Modal } from '@upsales/components';
import logError from 'Helpers/logError';
import { openDrawer } from 'Services/Drawer';
import { openMailTemplateBrowserModal } from 'App/components/MailTemplateBrowserModal/MailTemplateBrowserModal';
import { openUpdateContactAction } from 'App/components/MultiActionModal/MultiUpdateContact/OpenMultiUpdateContact';
import { openUpdateClientAction } from 'App/components/MultiActionModal/MultiUpdateClient/openMultiUpdateClientModal';
import { openCreateActivityAction } from 'App/components/MultiActionModal/CreateActivityAction/openCreateActivityAction';
import { openSendEmailAction } from 'App/components/SendEmailAction/openSendEmailAction';
import ReduxRootComponent from 'Components/ReduxComponentRoot';
import { useLocation, useRouteMatch } from 'react-router-dom';
import bemClass from '@upsales/components/Utils/bemClass';
import './LandingpageEditor.scss';
import { reactRouteCompatibility } from 'App/helpers/angularRouteHelpers';
import DomainsResource from 'Resources/Domains';

type Props = Partial<ModalProps> & { isModal: boolean; meta: any } & Record<string, any>;

const LandingpageEditor = function ({ isModal, meta, className, ...params }: Props) {
	const TemplateParser: any = (window as unknown as any).TemplateParser;
	const [profileColors, setProfileColors] = useState<any>([]);
	// Setup meta for action modals
	const modalMeta = {
		accountCategories: Tools.AppService.getCategories('account'),
		accountCustomFields: Tools.AppService.getCustomFields('account'),
		contactCategories: Tools.AppService.getCategories('account'),
		contactCustomFields: Tools.AppService.getCustomFields('contact'),
		activityCustomFields: Tools.AppService.getCustomFields('activity'),
		activeUsers: Tools.AppService.getActiveUsers(),
		roles: Tools.AppService.getRoles(),
		activityTypes: Tools.AppService.getActivityTypes('activity', true)
	};
	const [initialHash, setInitialHash] = useState<any>();
	const [currentHash, setCurrentHash] = useState<any>();
	const [allowStateChange, setAllowStateChange] = useState(false);
	const [availableFields, setAvailableFields] = useState<any>([]);
	const [initializing, setInitializing] = useState(true);
	const liveTags = params.liveTags || null;

	const multipleActions = ['SendEmail', 'SendWebhook'];

	const hasNameField = meta.form?.data?.fields.find((field: any) => field.name === 'Contact.name');
	const hasNewFields = Tools.FeatureHelper.hasSoftDeployAccess(Tools.FeatureHelper.Feature.NEW_FIELDS);

	const language =
		(Tools.FeatureHelper.hasSoftDeployAccess('FORM_COUNTRY_LANGUAGE') && Tools.AppService.getSelf().language) || '';

	const [rootData, setRootData] = useState<any>({
		saving: false,
		selectedRowId: null,
		selectedColumnId: null,
		selectedRow: null,
		selectedColumn: null,
		toolTab: 'column',
		html: '',
		templates: [],
		editMode: false,
		thankUPaige: false,
		isModal: isModal,
		strictTagList: params.strictTagList,
		hasVerifyDomainAccess: false,
		verifiedDomains: _.filter(meta.domains.data, { verified: true }),
		saveBtnText: T(isModal ? params.saveBtnText || 'default.save' : 'default.save'),
		isSocialEvent: params.isSocialEvent,
		accountCustomFields: Tools.AppService.getCustomFields('account'),
		contactCustomFields: Tools.AppService.getCustomFields('contact')
	});

	const rootDataRef = React.useRef();
	const allowStateChangeRef = React.useRef<boolean>();
	const initialHashRef = React.useRef();
	const currentHashRef = React.useRef();
	rootDataRef.current = rootData;
	allowStateChangeRef.current = allowStateChange;
	initialHashRef.current = initialHash;
	currentHashRef.current = currentHash;

	const clientFields: any = useMemo(() => {
		const translateCfDatatype = function (cf: any) {
			if (!cf.datatype || cf.datatype === 'String') {
				return 'text';
			} else if (cf.datatype === 'Date') {
				return 'date';
			} else if (cf.datatype === 'Email') {
				return 'email';
			} else if (cf.datatype === 'Currency' || cf.datatype === 'Integer') {
				return 'number';
			} else if (cf.datatype === 'Boolean') {
				return 'checkbox';
			} else if (cf.datatype === 'Select') {
				return 'select';
			} else if (cf.datatype === 'User') {
				return 'user';
			} else if (cf.datatype === 'Users') {
				return 'users';
			} else if (cf.datatype === '') {
				return 'textarea';
			}
			return 'text';
		};

		const getCfOptions = function (cf: any) {
			if (!cf['default'] || !Array.isArray(cf['default'])) {
				return null;
			}
			return cf['default'].map(encodeURIComponent).join(',');
		};

		const fields = [
			{
				title: 'default.extraField',
				name: 'Extra',
				datatype: 'text',
				type: 'extra',
				disabled: false,
				options: null
			},
			{ title: 'tag.client.name', name: 'Client.name', datatype: 'text', type: 'standard', disabled: false },
			{ title: 'tag.client.phone', name: 'Client.phone', datatype: 'text', type: 'standard', disabled: false },
			{
				title: 'tag.client.webpage',
				name: 'Client.webpage',
				datatype: 'text',
				type: 'standard',
				disabled: false
			},
			{
				title: 'tag.client.address',
				name: 'Client.address',
				datatype: 'text',
				type: 'standard',
				disabled: false
			},
			{
				title: 'tag.client.zipcode',
				name: 'Client.zipcode',
				datatype: 'text',
				type: 'standard',
				disabled: false
			},
			{ title: 'tag.client.city', name: 'Client.city', datatype: 'text', type: 'standard', disabled: false },
			{
				title: 'tag.client.country',
				name: 'Client.country',
				datatype: 'text',
				type: 'standard',
				disabled: false,
				language
			},
			{ title: 'tag.Contact.email', name: 'Contact.email', datatype: 'text', type: 'standard', disabled: true },
			{ title: 'tag.Contact.title', name: 'Contact.title', datatype: 'text', type: 'standard', disabled: false },
			{ title: 'tag.Contact.phone', name: 'Contact.phone', datatype: 'text', type: 'standard', disabled: false },
			{
				title: 'tag.Contact.cellPhone',
				name: 'Contact.cellPhone',
				datatype: 'text',
				type: 'standard',
				disabled: false
			}
		];

		if (hasNewFields && !hasNameField) {
			fields.splice(
				8,
				0,
				{
					title: 'tag.Contact.firstname',
					name: 'Contact.firstname',
					datatype: 'text',
					type: 'standard',
					disabled: false
				},
				{
					title: 'tag.Contact.lastname',
					name: 'Contact.lastname',
					datatype: 'text',
					type: 'standard',
					disabled: false
				}
			);
		} else {
			fields.splice(8, 0, {
				title: 'tag.Contact.name',
				name: 'Contact.name',
				datatype: 'text',
				type: 'standard',
				disabled: false
			});
		}

		if (hasNewFields) {
			const standardFieldNames = [
				{ name: 'OrgNo', datatype: 'text' },
				{ name: 'NoEmployees', datatype: 'number' },
				{ name: 'Facebook', datatype: 'text' },
				{ name: 'Twitter', datatype: 'text' },
				{ name: 'LinkedIn', datatype: 'text' }
			];
			const standardFields = Tools.AppService.getMetadata().standardFields.Client || {};
			standardFieldNames.forEach(({ name, datatype }) => {
				const field = standardFields[name];
				if (!field || !field.active) return null;

				fields.push({
					title: field.nameTag,
					name: `Client.${field.field}`,
					type: 'standard',
					disabled: false,
					datatype
				});
			});
		}

		const accountCustomFields = Tools.AppService.getCustomFields('account');
		if (accountCustomFields.length) {
			// add client customFields to select from
			accountCustomFields.forEach(function (cf: any) {
				if (!cf.editable) {
					return;
				}
				const tag = 'Client.custom_' + cf.id;
				fields.push({
					name: tag,
					title: cf.name,
					datatype: translateCfDatatype(cf),
					type: 'cf',
					options: getCfOptions(cf) as any,
					disabled: false
				});
			});
		}

		const contactCustomFields = Tools.AppService.getCustomFields('contact');
		if (contactCustomFields.length) {
			// add contact custom fields to select from
			contactCustomFields.forEach(function (cf: any) {
				if (!cf.editable) {
					return;
				}
				const tag = 'Contact.custom_' + cf.id;
				fields.push({
					name: tag,
					title: cf.name,
					datatype: translateCfDatatype(cf),
					type: 'cf',
					options: getCfOptions(cf) as any,
					disabled: false
				});
			});
		}
		return fields;
	}, [initializing]);

	const actions = [
		{ title: 'automationAction.SendEmail', action: 'SendEmail' },
		{ title: 'automationAction.AssignToUser', action: 'AssignToUser' },
		{ title: 'automationAction.UpdateClient', action: 'UpdateClient' },
		{ title: 'automationAction.UpdateContact', action: 'UpdateContact' },
		{ title: 'automationAction.SendWebhook', action: 'SendWebhook' },
		{
			title: 'automationAction.CreateActivity',
			action: 'CreateActivity',
			available: () =>
				!(
					Tools.FeatureHelper.hasSoftDeployAccess('REMOVE_ACTIVITIES') &&
					Tools.FeatureHelper.hasSoftDeployAccess('TODO_LIST')
				)
		},
		{
			title: 'automationAction.PlanPhonecalls',
			action: 'PlanPhonecalls',
			available: () => Tools.FeatureHelper.hasSoftDeployAccess('TODO_LIST')
		},
		{ title: 'automationAction.SendTemplate', action: 'SendMapMail' }
	];

	const getAvailableOptIns = (type: any) => {
		return _.filter(meta.optIns.data, function (optIn: any) {
			return !_.includes(_.pluck(rootData.fields, 'options'), String(optIn.id)) && optIn.type === type;
		});
	};

	var generateNote = function (fields: any) {
		var keys = _.keys(fields);
		var output = '';
		_.forEach(keys, function (field) {
			if (!fields[field].text && !fields[field].name) {
				return;
			}
			if (fields[field].type !== 'cf' && fields[field].type !== 'extra' && fields[field].type !== 'standard') {
				return;
			}
			output += fields[field].title + ':\n';
			if (fields[field].text) {
				output += '{{FormSubmit.' + fields[field].text + '}}';
			} else {
				output += '{{FormSubmit.' + fields[field].name + '}}';
			}
			output += '\n\n';
		});
		return output;
	};

	var generateJson = function (fields: any) {
		var keys = _.keys(fields);
		var output = '[';
		var noOfFields = 0;
		_.forEach(keys, function (field) {
			if (!fields[field].text && !fields[field].name) {
				return;
			}
			if (fields[field].type !== 'cf' && fields[field].type !== 'extra' && fields[field].type !== 'standard') {
				return;
			}
			if (noOfFields > 0) {
				output += ',';
			}
			output += '\n    {"' + fields[field].title + '": ';
			if (fields[field].text) {
				output += '"{{FormSubmit.' + fields[field].text + '}}"';
			} else {
				output += '"{{FormSubmit.' + fields[field].name + '}}"';
			}
			output += '}';
			noOfFields++;
		});
		output += '\n  ]';
		return output;
	};

	var generateEmail = function (header: any, fields: any, footer: any) {
		var keys = _.keys(fields);
		var output = '<font face="arial" style="font-family:arial;size:13px;" size=2>';
		output += header;
		output += '<br><br></font>';
		output += '<table border=0 width=400 cellpadding=0 cellspacing=0>';
		_.forEach(keys, function (field) {
			if (!fields[field].text && !fields[field].name) {
				return;
			}

			if (fields[field].name.includes('Prefs.')) {
				return;
			}

			output +=
				'<tr><td style="font-family:arial;size:13px;padding:4px;background-color:#002f65;color:#ffffff"><font face="arial" size=2>';
			output += fields[field].title;
			output += '</font></td></tr>';
			output +=
				'<tr><td style="font-family:arial;size:13px;padding:4px;background-color:#dddddd"><font face="arial" size=2>';
			if (fields[field].text) {
				output += '{{FormSubmit.' + fields[field].text + '}}';
			} else {
				output += '{{FormSubmit.' + fields[field].name + '}}';
			}
			output += '</font></td></tr>';
		});
		output += '</table>';
		output += '<font face="arial" size=2 style="font-family:arial;size:13px;">';
		output += '<br>';
		output += footer;
		output += '</font>';

		return output;
	};

	var generateExtraTags = function (fields: any) {
		var keys = _.keys(fields);
		var output: any = [];
		_.forEach(keys, function (field) {
			var text;
			var value;
			if (!fields[field].text && !fields[field].name) {
				return;
			}
			if (fields[field].type !== 'cf' && fields[field].type !== 'extra' && fields[field].type !== 'standard') {
				return;
			}
			text = T('form.form') + ': ' + fields[field].title;
			if (fields[field].text) {
				value = '{{FormSubmit.' + fields[field].text + '}}';
			} else {
				value = '{{FormSubmit.' + fields[field].name + '}}';
			}
			output.push({
				text: text,
				value: value
			});
		});
		return output;
	};

	var updateAvailableFields = function (rootDataFields: any) {
		const filteredFields = _.filter(clientFields, function (field: any) {
			if (field.type !== 'standard') {
				return field;
			}
			var usedField = _.find(rootDataFields, { name: field.name });
			if (!usedField) {
				return field;
			}
		});
		setAvailableFields(filteredFields);
	};

	var getAvailableOptInsMap = function () {
		var out: any = {};
		var optInGroup = _.groupBy(meta.optIns.data, function (optIn: any) {
			return optIn.type;
		});
		_.forEach(optInGroup, function (arr: any, type: any) {
			out[type] = _.filter(arr, function (optIn: any) {
				return !_.includes(_.pluck(rootData.form.fields, 'options'), String(optIn.id)) && optIn.type === type;
			});
		});
		return out;
	};

	function checkAndSetWidthValues(element: any) {
		if (element?.type === 'image' && element.value?.src) {
			if (element.value.naturalWidth === undefined) {
				const selector = element.id === 'logo' ? '.up-element#logo' : `[id="${element.id}"]`;
				const imgElem: any = document.querySelector(selector);
				element.value.naturalWidth = imgElem?.naturalWidth!;
			}
			if (element.value.width === undefined && element.value.maxWidth === undefined) {
				element.value.width = element.value.naturalWidth;
			}
		}
	}

	var onSelectRow = function (data: any) {
		const rootDataCopy = _.clone(rootData);
		rootDataCopy.selectedRowId = data.row;
		rootDataCopy.selectedColumnId = data.column;

		rootDataCopy.selectedRow = _.find(rootDataCopy.form.landingPageBody.rows, function (row: any) {
			return row.id.toString() === data.row;
		});

		if (rootDataCopy.selectedRow && rootDataCopy.selectedRow.special) {
			const logoElement = rootDataCopy.selectedRow.elements.find((element: any) => element.id === 'logo');
			checkAndSetWidthValues(logoElement);
			setRootData({ ...rootDataCopy });
			return;
		}

		const column = _.find(rootDataCopy.selectedRow.columns, function (column: any) {
			return column.id.toString() === data.column;
		});

		rootDataCopy.selectedColumn = column;

		if (column) {
			const element = column.elements[0];
			checkAndSetWidthValues(element);
		}
		setRootData({ ...rootDataCopy, toolTab: data.showRow ? 'row' : 'column' });
	};

	var onRowBgChange = function (data: any) {
		setRootData((rootData: any) => {
			rootData.selectedRow.background = data;
			parseAll(rootData);
			return { ...rootData };
		});
	};

	var onClearColumn = function () {
		TemplateParser.clearColumn(
			rootData.selectedRowId,
			rootData.selectedColumnId,
			rootData.form.landingPageBody,
			function (err: any, config: any) {
				setRootData((rootData: any) => {
					rootData.form.landingPageBody = config;
					parseAll(rootData);
					return { ...rootData };
				});
			}
		);
	};

	var onDeleteRow = function (rowId: any) {
		TemplateParser.deleteRow(
			rowId || rootData.selectedRowId,
			rootData.form.landingPageBody,
			function (err: any, config: any) {
				setRootData((rootData: any) => {
					rootData.form.landingPageBody = config;
					rootData.selectedRowId = null;
					rootData.selectedColumnId = null;
					rootData.selectedRow = null;
					rootData.selectedColumn = null;
					parseAll(rootData);
					return { ...rootData };
				});
			}
		);
	};

	var onAddRow = function (afterRowId: any, layout: any) {
		TemplateParser.addRow(afterRowId, layout, rootData.form.landingPageBody, function (err: any, config: any) {
			setRootData((rootData: any) => {
				rootData.form.landingPageBody = config;
				parseAll(rootData);
				return { ...rootData };
			});
		});
	};

	var onSetColumnType = function (type: any) {
		TemplateParser.setColumnType(
			rootData.selectedRowId,
			rootData.selectedColumnId,
			rootData.form.landingPageBody,
			type,
			rootData.accountProfile,
			function (err: any, config: any) {
				setRootData((rootData: any) => {
					rootData.form.landingPageBody = config;
					parseAll(rootData);
					return { ...rootData };
				});
			}
		);
	};

	var onElementChange = function (element: any) {
		if (
			rootData.selectedColumn &&
			rootData.selectedColumn.elements &&
			rootData.selectedColumn.elements.length &&
			rootData.selectedColumn.elements[0].id === element.id
		) {
			setRootData((rootData: any) => {
				rootData.selectedColumn.elements = [element];
				parseAll(rootData);
				return { ...rootData };
			});
		}
	};

	var onPaddingChange = function (padding: any) {
		setRootData((rootData: any) => {
			rootData.selectedColumn.padding = padding;
			parseAll(rootData);
			return { ...rootData };
		});
	};

	var toggleHeaderVisibility = function () {
		setRootData((rootData: any) => {
			rootData.selectedRow.visible = !rootData.selectedRow.visible;
			parseAll(rootData);
			return { ...rootData };
		});
	};

	var transparentHeaderChanged = function (value: any) {
		setRootData((rootData: any) => {
			rootData.selectedRow.transparentHeader = value;
			parseAll(rootData);
			return { ...rootData };
		});
	};

	var onPaddingChangeRow = function (padding: any) {
		const rootDataCopy = _.clone(rootData);
		var rowIndex = _.findIndex(rootDataCopy.form.landingPageBody.rows, function (row: any) {
			return row.id.toString() === rootDataCopy.selectedRowId;
		});
		if (rowIndex !== -1) {
			rootDataCopy.form.landingPageBody.rows[rowIndex].padding = padding;
			setRootData({ ...rootDataCopy });
			parseAll(rootDataCopy);
		}
	};

	var onTemplateSelect = function (template: any) {
		setRootData((rootData: any) => {
			rootData.editMode = true;
			var config = TemplateParser.generateConfig(template.bodyJson, rootData.accountProfile);
			rootData.form = _.merge(rootData.form, config);

			// eslint-disable-next-line no-unused-expressions
			rootData.form?.landingPageBody?.rows?.some((row: any) => {
				const logoElement = row.elements?.find((element: any) => element.id === 'logo');
				if (logoElement) {
					logoElement.value.maxWidth = 200;
				}
				return !!logoElement;
			});
			parseAll(rootData, function (hash: any) {
				setInitialHash(hash);
			});
			return { ...rootData };
		});
	};

	var onFormChange = function (form: any) {
		setRootData((rootData: any) => {
			rootData.form = form;
			rootData.form.fields = _.map(rootData.form.fields, function (f: any, i: any) {
				f.sort = i + 1;
				return f;
			});
			rootData.fields = rootData.form.fields;
			updateAvailableFields(rootData.fields);
			parseAll(rootData);
			return { ...rootData };
		});
	};

	var onFieldsChange = function (fields: any) {
		setRootData((rootData: any) => {
			rootData.fields = fields;
			updateAvailableFields(rootData.fields);
			parseAll(rootData);
			return { ...rootData };
		});
	};

	var onRowElementChange = function (element: any) {
		setRootData((rootData: any) => {
			var rowIndex = _.findIndex(rootData.form.landingPageBody.rows, function (row: any) {
				return row.id.toString() === rootData.selectedRowId;
			});
			var index = _.findIndex(rootData.form.landingPageBody.rows[rowIndex].elements, {
				id: element.id
			});
			rootData.form.landingPageBody.rows[rowIndex].elements[index] = element;
			parseAll(rootData);
			return { ...rootData };
		});
	};

	var onEditAction = function (actionIndex: any, add?: any) {
		const rootDataCopy = _.clone(rootData);
		const dataHelper = () => {
			return _.map(
				_.filter(actions, function (a) {
					var listObj = _.find(rootDataCopy.form.actions, { action: a.action });
					if ((!listObj || multipleActions.indexOf(a.action) !== -1) && !(a.available && !a.available())) {
						return a;
					}
				}),
				function (a) {
					var obj: any = { title: T(a.title), action: a.action };
					if (
						a.action === 'SendWebhook' &&
						!Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.TRIGGERS)
					) {
						obj.$$disabled = true;
						obj.title = '<span class="text-grey">' + T(a.title) + '</span>';
						obj.missingFeature =
							'<span class="text-grey text-xs">' +
							T('default.versionMissingFeature') +
							' ' +
							T('feature.triggers').toLowerCase() +
							'</span>';
					}

					return obj;
				}
			);
		};
		if (add) {
			if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_LIST')) {
				openModal('ListModal', {
					title: 'admin.addAction',
					hideHeader: true,
					columns: [
						{ title: 'default.name', value: 'title' },
						{ title: '', value: 'missingFeature' }
					],
					data: dataHelper()
				});
			} else {
				// eslint-disable-next-line promise/catch-or-return
				Tools.$upModal
					.open('list', {
						title: 'admin.addAction',
						hideHeader: true,
						columns: [
							{ title: 'default.name', value: 'title' },
							{ title: '', value: 'missingFeature' }
						],
						data: dataHelper()
					})
					.then(function (action: any) {
						onEditAction(action);
					});
			}
			return;
		}

		let action = rootDataCopy.form.actions[actionIndex];
		if (typeof actionIndex === 'object') {
			add = true;
			action = actionIndex;
		}

		// ModalParams
		const modalParams: any = {
			accountCategories: modalMeta.accountCategories,
			accountCustomFields: modalMeta.accountCustomFields,
			contactCategories: modalMeta.contactCategories,
			contactCustomFields: modalMeta.contactCustomFields,
			activityCustomFields: modalMeta.activityCustomFields,
			activeUsers: modalMeta.activeUsers,
			roles: modalMeta.roles,
			activityTypes: modalMeta.activityTypes,
			action: action.action,
			entity: 'landingpage',
			hideDate: true,
			isTrigger: true,
			isAutomation: true
		};

		// Send current properties to modal
		if (!add) {
			modalParams.properties = action.properties;
		}

		if (add) {
			if (!rootDataCopy.fields) {
				rootDataCopy.fields = rootDataCopy.form.fields;
			}

			if (action.action === 'SendEmail') {
				modalParams.prefill = {
					to: '',
					subject: T('form.emailSubject') + ' {{Form.Name}}',
					body: generateEmail(T('form.emailHeader'), rootDataCopy.fields, T('form.emailFooter'))
				};
				modalParams.extraTags = generateExtraTags(rootDataCopy.fields);
				modalParams.extraTags.push({
					text: T('form.form2') + ' ' + T('default.name'),
					value: '{{Form.Name}}'
				});
			}
			if (action.action === 'SendWebhook') {
				modalParams.prefill = {
					body: '{\n  "form": "{{Form.Name}}",\n  "fields": ' + generateJson(rootDataCopy.fields) + '\n}',
					contentType: 'application/json',
					encoding: 'UTF-8',
					method: 'POST'
				};
			}
			if (action.action === 'CreateActivity') {
				modalParams.prefill = {
					note: generateNote(rootDataCopy.fields),
					description: T('form.filledOut') + ' {{Form.Name}}'
				};
			}
		}

		// Add angular/ react openModal here, key = name of angular modal
		const openModalActions: any = {
			UpdateContactAction: openUpdateContactAction,
			UpdateClientAction: openUpdateClientAction,
			CreateActivityAction: openCreateActivityAction,
			SendEmailAction: openSendEmailAction
		};

		const modalName = action.action + 'Action';

		let propertiesPromise;
		if (action.action === 'PlanPhonecalls') {
			propertiesPromise = new Promise(resolve => {
				openDrawer('PlanPhonecallsAction', {
					properties: add
						? [
								{ name: 'Description', value: T('form.filledOut') + ' {{Form.Name}}' },
								{ name: 'Notes', value: generateNote(rootDataCopy.fields) },
								{ name: 'User', value: Tools.AppService.getSelf().id }
						  ]
						: action.properties,
					assignOneUser: true,
					onSave: resolve
				});
			});
		} else {
			// Open modal
			if (Tools.FeatureHelper.hasSoftDeployAccess('SEND_WEBHOOK_REACT') && modalName === 'SendWebhookAction') {
				propertiesPromise = new Promise((res, rej) => {
					openModal('SendWebhook', {
						tagEntity: 'landingpage',
						properties: modalParams.properties || modalParams.prefill,
						onClose: data => {
							if (data) {
								res(data);
							} else {
								rej();
							}
						}
					});
				});
			} else if (modalName === 'SendMapMailAction') {
				const properties = action.properties ?? [];
				let templateProperty = properties.find((property: any) => property.name === 'MailTemplate');
				if (!templateProperty) {
					templateProperty = { name: 'MailTemplate', value: null };
					properties.push(templateProperty);
				}

				propertiesPromise = openMailTemplateBrowserModal({
					selectedTemplateId:
						templateProperty && templateProperty.value ? parseInt(templateProperty.value) : null,
					requireUnsub: false
				}).then(selectedTemplate => {
					templateProperty.value = selectedTemplate.id;
					return properties;
				});
			} else if (openModalActions[modalName]) {
				propertiesPromise = openModalActions[modalName](modalParams);
			} else {
				propertiesPromise = Tools.$upModal.open(modalName, modalParams);
			}
		}
		setRootData({ ...rootDataCopy });
		// eslint-disable-next-line promise/catch-or-return
		propertiesPromise
			.then((properties: any) => {
				// Update action or push new
				if (add) {
					rootDataCopy.form.actions.push({
						action: action.action,
						properties: properties
					});
				} else {
					action.properties = properties;
				}
				setRootData({ ...rootDataCopy });
			})
			.catch(() => {});
	};

	var beforeSave = function (name: any, form: any, rootData: any) {
		return new Promise((resolve, reject) => {
			if (!name || !name.length) {
				openConfirmLandingPageModal({ form: form })
					.then(formInput => {
						resolve(formInput);
					})
					.catch(() => {
						reject('CancelledFromModal');
					});
			} else {
				resolve({
					name: rootData.form.name,
					internalName: rootData.form.internalName
				});
			}
		});
	};

	var onSave = function (shouldUseRootDataRef?: boolean) {
		//rootDataRef used to avoid stale state in event handler listener
		const rootDataCopy = _.clone(shouldUseRootDataRef ? rootDataRef.current : rootData);
		const form = rootDataCopy.form;

		if (form.landingPageBody.hasForm && !form.thankYouElement && !form.landingPage) {
			// Show notification
			Tools.NotificationService.addNotification({
				style: Tools.NotificationService.style.WARN,
				icon: 'warning',
				title: 'form.enterThanksPage',
				body: 'form.formRequiresThanksPage'
			});

			let selectedCol: any;
			// Find form row and column
			rootDataCopy.selectedRow = _.find(form.landingPageBody.rows, function (r: any) {
				var formCol = _.find(r.columns, function (c: any) {
					if (c.elements.length && c.elements[0].type === 'form') {
						return true;
					}
					return false;
				});

				if (formCol) {
					selectedCol = formCol;
					return true;
				}
				return false;
			});

			rootDataCopy.selectedColumn = selectedCol;
			rootDataCopy.selectedRowId = rootDataCopy.selectedRow.id;
			rootDataCopy.selectedColumnId = selectedCol.id;

			rootDataCopy.toolTab = 'column';
			rootDataCopy.thankUPaige = true;
			rootDataCopy.submitted = true;
			rootDataCopy.scrollToForm = true;
			setRootData(rootDataCopy);
			return;
		}

		beforeSave(rootDataCopy.form.name, form, shouldUseRootDataRef ? rootDataRef.current : rootData)
			.then(function (data: any) {
				form.name = data.name;
				form.internalName = data.internalName;
				// Set default btn text
				form.buttonText = form.buttonText || T('mail.send');

				setRootData({ ...rootDataCopy, form: form, saving: true });
				TemplateParser.parseDist(
					form,
					rootDataCopy.accountProfile,
					{
						optIns: meta.optIns.data,
						liveTags: liveTags,
						isSocialEvent: params.isSocialEvent,
						domains: meta.domains.data
					},
					function (err: any, html: any) {
						form.html = html;
						setRootData((rootData: any) => ({ ...rootData, form: form }));
						// eslint-disable-next-line promise/catch-or-return
						Tools.Form.save(form)
							.then(function (res: any) {
								localStorage.removeItem('forms.internalName');
								// Go to form
								if (isModal) {
									setTimeout(() => {
										params.close!(res.data);
									}, 20);
								} else {
									setTimeout(() => {
										window.Tools.routerHistory.push(
											'/form-overview/' + res.data.id + '?tab=publishingOptions'
										);
									}, 20);
								}
							})
							.finally(() => {
								setAllowStateChange(true);
								setRootData((rootData: any) => ({
									...rootData,
									saving: false
								}));
							});
					}
				);
			})
			.catch((e: any) => {
				if (e !== 'CancelledFromModal') {
					logError(e, 'Failed to save landing page');
				}
			});
	};

	useEffect(() => {
		if (rootData.scrollToForm) {
			setRootData((rootData: any) => ({ ...rootData, scrollToForm: false }));
		}
	}, [rootData.scrollToForm]);

	var onSetupProfile = function () {
		Tools.$state.go('administration.accountProfile');
	};

	var onOpenSettings = function () {
		// eslint-disable-next-line promise/catch-or-return
		if (Tools.FeatureHelper.hasSoftDeployAccess('LANDING_PAGE_SETTINGS_REACT')) {
			openModal<any>('LandingPageSettings', {
				profileColors: profileColors,
				form: rootData.form,
				onClose: (form: any) => {
					if (form) {
						setRootData((rootData: any) => {
							rootData.form = form;
							parseAll(rootData);
							return { ...rootData };
						});
					}
				}
			});
		} else {
			// eslint-disable-next-line promise/catch-or-return
			Tools.$upModal
				.open('landingpageSettings', {
					profileColors: profileColors,
					form: rootData.form,
					oldModal: true
				})
				.then(function (form) {
					setRootData((rootData: any) => {
						rootData.form = form;
						parseAll(rootData);
						return { ...rootData };
					});
				});
		}
	};

	var onToggleFormPreview = function (type: any) {
		setRootData((rootData: any) => ({ ...rootData, thankUPaige: type === 'form' ? false : true }));
	};

	var onSetThankYouElem = function (type: any) {
		setRootData((rootData: any) => {
			rootData.form.thankYouElement = TemplateParser.getElementConfig(type);
			parseAll(rootData);
			return { ...rootData };
		});
	};

	var onThankUChange = function (element: any) {
		setRootData((rootData: any) => {
			rootData.form.thankYouElement = element;
			parseAll(rootData);
			return { ...rootData };
		});
	};

	var onThankYouTitleTextChange = function (field: any, value: any) {
		switch (field) {
			case 'thankYouTitle':
			case 'thankYouText':
				setRootData((rootData: any) => {
					rootData.form[field] = value;
					parseAll(rootData);
					return { ...rootData };
				});
				break;
		}
	};

	var onClearThankPage = function () {
		setRootData((rootData: any) => {
			rootData.form.thankYouElement = null;
			parseAll(rootData);
			return { ...rootData };
		});
	};

	var onMoveRow = function (direction: any, rowId: any) {
		setRootData((rootData: any) => {
			if (direction === 'down') {
				rootData.form.landingPageBody = TemplateParser.moveRowDown(
					rootData.form.landingPageBody,
					rowId || rootData.selectedRowId
				);
			} else {
				rootData.form.landingPageBody = TemplateParser.moveRowUp(
					rootData.form.landingPageBody,
					rowId || rootData.selectedRowId
				);
			}
			parseAll(rootData);
			return { ...rootData };
		});
	};

	var onSelectField = function () {
		if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_SELECT_FIELD_MODAL')) {
			return new Promise((resolve, reject) => {
				openModal('SelectFieldModal', {
					availableFields: availableFields,
					availableOptIns: getAvailableOptInsMap(),
					onClose: (field: any) => {
						if (field) {
							if (field.type === 'extra') {
								field.name = 'Extra.' + Date.now();
							}
							field.required = false;
							field.sort = rootData.form.fields.length + 1;

							if (field.datatype === 'optIn') {
								var optIn: any = _.first(getAvailableOptIns(field.optInType));
								field.title = optIn.title;
								field.options = String(optIn.id);
								field.required = optIn.type === 'double';
							} else if (field.type === 'standard') {
								field.title = T('form.' + field.name);
							} else if (field.type !== 'cf') {
								field.title = T(
									'datatype.' + (field.datatype.charAt(0).toUpperCase() + field.datatype.slice(1))
								);
							}
							setRootData((rootData: any) => {
								rootData.form.fields.push(field);
								onFormChange(rootData.form);
								return { ...rootData };
							});
						}
						reject();
					}
				});
			});
		}
		// eslint-disable-next-line promise/catch-or-return
		Tools.$upModal
			.open('selectField', { availableFields: availableFields, availableOptIns: getAvailableOptInsMap() })
			.then(function (field) {
				if (field.type === 'extra') {
					field.name = 'Extra.' + Date.now();
				}
				field.required = false;
				field.sort = rootData.form.fields.length + 1;

				if (field.datatype === 'optIn') {
					var optIn: any = _.first(getAvailableOptIns(field.optInType));
					field.title = optIn.title;
					field.options = String(optIn.id);
					field.required = optIn.type === 'double';
				} else if (field.type === 'standard') {
					field.title = T('form.' + field.name);
				} else if (field.type !== 'cf') {
					field.title = T('datatype.' + (field.datatype.charAt(0).toUpperCase() + field.datatype.slice(1)));
				}
				setRootData((rootData: any) => {
					rootData.form.fields.push(field);
					onFormChange(rootData.form);
					return { ...rootData };
				});
			});
	};

	var previewInNewWin = function () {
		TemplateParser.parseDist(
			rootData.form,
			rootData.accountProfile,
			{
				isPreview: true,
				noTags: true,
				optIns: meta.optIns.data,
				liveTags: liveTags,
				isSocialEvent: params.isSocialEvent
			},
			function (err: any, html: any) {
				var toggleStyle =
					' style="width: 50%; height: 40px; line-height: 40px; background-color: #fff; border: 1px solid #ccc; color: #888;"';
				var toggles =
					'<div style="position: absolute; width: 100%; z-index: 10000; top: 0; left: 0; font-family: sans-serif;"><button type="button"' +
					toggleStyle +
					' onClick=document.getElementById("preview-iframe").style.width="100%"><b class="fa fa-desktop"></b> ' +
					T('ads.desktop') +
					'</button>';
				toggles +=
					'<button type="button"' +
					toggleStyle +
					' onClick=document.getElementById("preview-iframe").style.width="400px"><b class="fa fa-mobile" style="margin-right: 3px;"></b>' +
					T('default.phone') +
					'</button></div>';
				var preview = window.open(
					'',
					'',
					'height=768,width=1024,scrollbars=yes,resizable=yes,toolbar=no,status=no,menu=no,titlebar=no,location=no,addressbar=no'
				);
				html = html.replace(
					'class="template-scoped-style" style="',
					'class="template-scoped-style" style="margin-top:30px;'
				);
				if (preview) {
					if (typeof preview.document.write === 'function') {
						preview.document.write(
							toggles +
								'<div style="width: 100%; height: 100%; display: flex; justify-content: center; position: absolute; left: 0;"><iframe id="preview-iframe" style="width: 100%; height: 100%;"></iframe></div>'
						);
						const iframe: any = preview.document.getElementById('preview-iframe');
						const script = preview.document.createElement('script');
						script.src = 'https://use.fontawesome.com/01a8d84c9a.js';
						preview.document.head.appendChild(script);
						iframe?.contentWindow!.document.open();
						iframe?.contentWindow!.document.write(html);
						iframe?.contentWindow!.document.close();
					} else {
						preview.close();
					}
				}
			}
		);
	};

	var addPreset = function (color: any) {
		const rootDataCopy = _.clone(rootData);
		rootDataCopy.accountProfile.extraColors.push({ value: color });
		setRootData(rootDataCopy);
		return Tools.AccountProfile.save(rootDataCopy.accountProfile);
	};

	var abort = function () {
		if (isModal) {
			params.close!();
		}
	};

	async function parseAll(rootDataToParse: any, cb?: any) {
		// should be run with updated rootData values so should pass the rootData directly
		// setTimeout so that setRootData state is updated before parsing
		setTimeout(() => {
			TemplateParser.parse(
				rootDataToParse.form,
				rootDataToParse.accountProfile,
				{ optIns: meta.optIns.data, liveTags: liveTags, isSocialEvent: params.isSocialEvent },
				function (err: any, html: any) {
					const hash = Tools.LZString.compressToBase64(JSON.stringify(html));
					setCurrentHash(hash);
					setRootData((rootData: any) => ({ ...rootData, html: html }));
					if (cb && typeof cb === 'function') {
						cb(hash);
					}
				}
			);
		}, 0);
	}

	useEffect(() => {
		// Init function for this page
		const init = function () {
			const rootDataCopy = _.clone(rootData);
			rootDataCopy.form = meta.form.data;
			rootDataCopy.fields = meta.form.data.fields;
			if (rootDataCopy.form.hasOwnProperty('socialMediaTags') === false) {
				rootDataCopy.form.socialMediaTags = null;
			}

			rootDataCopy.accountProfile = meta.accountProfile.data;
			rootDataCopy.optIns = meta.optIns.data;
			rootDataCopy.hasVerifyDomainAccess = Tools.FeatureHelper.isAvailable(
				Tools.FeatureHelper.Feature.REQUIRE_BUSINESS_EMAIL
			);

			if (rootDataCopy.form.id || params.copy) {
				rootDataCopy.editMode = true;
				setTimeout(
					() =>
						parseAll(rootDataCopy, (hash: any) => {
							setInitialHash(hash);
						}),
					0
				);
			} else if (params.templateName && meta.template) {
				// same logic as onTemplateSelect but we mutate rootDataCopy directly here instead
				rootDataCopy.editMode = true;
				var config = TemplateParser.generateConfig(meta.template.bodyJson, rootDataCopy.accountProfile);
				rootDataCopy.form = _.merge(rootDataCopy.form, config);

				// eslint-disable-next-line no-unused-expressions
				rootDataCopy.form?.landingPageBody?.rows?.some((row: any) => {
					const logoElement = row.elements?.find((element: any) => element.id === 'logo');
					if (logoElement) {
						logoElement.value.maxWidth = 200;
					}
					return !!logoElement;
				});
				setTimeout(
					() =>
						parseAll(rootDataCopy, (hash: any) => {
							setInitialHash(hash);
						}),
					0
				);
			}

			// Get all colors from the accountProfile
			const colorVariables: any = [
				{ title: T('admin.colors'), colors: {} },
				{ title: T('admin.extraColors'), colors: {} }
			];
			if (rootDataCopy.accountProfile) {
				_.forEach(rootDataCopy.accountProfile.colors, function (value) {
					colorVariables[0].colors[value] = value;
				});
				_.forEach(rootDataCopy.accountProfile.extraColors, function (color) {
					colorVariables[1].colors[color.value] = color.value;
				});
			}
			setProfileColors(colorVariables);

			rootDataCopy.form.fields = _.map(rootDataCopy.form.fields, function (field: any) {
				// Extend the field in available fieldsArr and set it to use = true
				var availableField = _.find(clientFields, { name: field.name });
				if (availableField) {
					field = _.merge(_.clone(availableField), field);
					field.required = !!field.required;
					if (!field.datatype) {
						field.datatype = 'text';
					}
					var i = _.findIndex(clientFields, { text: field.name });
					if (i !== -1 && !clientFields[i].disabled) {
						clientFields[i].disabled = true;
					}
				} else if (field.name.indexOf('Extra.') === 0) {
					field.type = 'extra';
					field.required = !!field.required;
				} else if (field.name.indexOf('Prefs') === 0) {
					// availableField = _.findIndex(LandingpageEditor.prefs.data, {name: field.name});
					// if(availableField !== -1) {
					// 	LandingpageEditor.prefs.data[availableField].value = true;
					// }
				}
				return field;
			});
			updateAvailableFields(rootDataCopy.fields);

			rootDataCopy.templates = meta.templates.data;
			setRootData({ ...rootDataCopy });
			setInitializing(false);
		};
		init();
	}, []);

	const showWarning = function () {
		if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
			return new Promise<void>((resolve, reject) => {
				openModal('UnsavedChangesAlert', {
					onClose: async confirmed => {
						if (confirmed === undefined) {
							reject();
							return;
						}
						if (confirmed) {
							await onSave(true);
						}
						resolve();
					}
				});
			});
		}

		return Tools.$upModal.open('warningConfirm', {
			title: 'confirm.abortEdit',
			body: 'confirm.changesWillBeLost',
			resolveTrue: 'default.abortEdit',
			no: 'default.continueEdit'
		});
	};

	// Show warning when leaving page with unsaved changes for when it's a MODAL,
	useModalClose(
		params.modalId as number,
		event => {
			if (!isModal) {
				return;
			}
			if (!allowStateChangeRef.current && initialHashRef.current !== currentHashRef.current) {
				event.preventDefault();
				// eslint-disable-next-line promise/catch-or-return
				showWarning().then(function () {
					setAllowStateChange(true);
					params.close!();

					if (event.state) {
						event.resumeState();
					}
				});
			}
		},
		[]
	);
	// Show warning when leaving page with unsaved changes for when it's a ROUTE/PAGE
	useEffect(() => {
		if (!isModal) {
			// -- TO DO --:
			// This will only work in angular router for now since we use $stateChangeStart
			// will need to be updated to add code for react router route change/block listener at some point
			const listener = Tools.$rootScope.$on(
				'$stateChangeStart',
				function (
					event: any,
					toState: any,
					toStateParams: any,
					fromState: any,
					fromStateParams: any,
					options: any
				) {
					const getResumeState = function (stateObj: any) {
						return function (mergeParams?: any) {
							var stateParams = _.merge(stateObj.stateParams, mergeParams || {});

							Tools.$state.go(stateObj.state.name, stateParams, stateObj.options);
						};
					};

					if (!allowStateChangeRef.current && initialHashRef.current !== currentHashRef.current) {
						event.preventDefault();
						// eslint-disable-next-line promise/catch-or-return
						showWarning().then(function () {
							var stateObj = {
								state: toState,
								stateParams: toStateParams,
								options: { ...options, location: true }
							};
							setAllowStateChange(true);
							getResumeState(stateObj)();
						});
					}
				}
			);
			return () => {
				listener?.();
			};
		}
	}, []);

	if (initializing) {
		return null;
	}

	return (
		<ReduxRootComponent
			rootData={{
				...rootData,
				getAvailableOptIns: getAvailableOptIns,
				onSelectRow: onSelectRow,
				onSetToolTab: (type: any) => {
					setRootData((rootData: any) => ({ ...rootData, toolTab: type }));
				},
				onDeselectColumn: () => {
					setRootData((rootData: any) => ({
						...rootData,
						selectedRowId: null,
						selectedColumnId: null,
						selectedRow: null,
						selectedColumn: null
					}));
				},
				onRowBgChange: onRowBgChange,
				onClearColumn: onClearColumn,
				onDeleteRow: onDeleteRow,
				onSetColumnType: onSetColumnType,
				onAddRow: onAddRow,
				onElementChange: onElementChange,
				onPaddingChange: onPaddingChange,
				onPaddingChangeRow: onPaddingChangeRow,
				onTemplateSelect: onTemplateSelect,
				onFormChange: onFormChange,
				onEditAction: onEditAction,
				onFieldsChange: onFieldsChange,
				onSetupProfile: onSetupProfile,
				onOpenSettings: onOpenSettings,
				onSave: onSave,
				onToggleFormPreview: onToggleFormPreview,
				onSetThankYouElem: onSetThankYouElem,
				onThankUChange: onThankUChange,
				onThankYouTitleTextChange,
				onClearThankPage: onClearThankPage,
				onRowElementChange: onRowElementChange,
				onMoveRow: onMoveRow,
				onSelectField: onSelectField,
				transparentHeaderChanged: transparentHeaderChanged,
				toggleHeaderVisibility: toggleHeaderVisibility,
				previewInNewWin: previewInNewWin,
				addPreset: addPreset,
				abort: abort
			}}
			component={ReactTemplates.form.editLandingpageRoot}
		/>
	);
};

const LandingpageEditorWrapper = ({ isModal, ...props }: any) => {
	const [meta, setMeta] = useState<any>();
	const [loadingMeta, setLoadingMeta] = useState(true);
	useEffect(() => {
		getMeta(props)
			.then(res => {
				setMeta(res);
			})
			.catch(e => {
				if (!isModal) {
					window.Tools.routerHistory.push('/forms');
				} else {
					props.close();
				}
				logError(e, 'Failed to load meta data');
			})
			.finally(() => {
				setLoadingMeta(false);
			});
	}, []);
	if (loadingMeta) {
		return null;
	}
	return <LandingpageEditor {...props} meta={meta} isModal={isModal} />;
};

export const LandingpageEditorModal = (props: ModalProps & Record<string, any>) => {
	// Don't think modal is used anymore but maybe we will use it in the future
	const [checkingAccess, setCheckingAccess] = useState(true);
	const classes = new bemClass('LandingpageEditor', props.className);
	classes.add('LandingpageEditor__modal');
	classes.add('FullScreenModal');

	useEffect(() => {
		if (
			// isAvailable
			!Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.FORMS) && // <-- TODO: Remove the first check when this has been live for a while (scopes need to be refrshed)
			!Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.FORMS_BACKEND)
		) {
			logError('Missing access rights to landing page editor');
			props.close();
			return;
		}
		setCheckingAccess(false);
	}, []);

	if (checkingAccess) {
		return null;
	}
	// props and params are the same
	return (
		<Modal className={classes.b()}>
			<LandingpageEditorWrapper {...props} isModal={true} />
		</Modal>
	);
};

export const openLandingpageEditorModal = asyncModalAdapter({
	upModalName: 'landingpageEditor',
	openModalName: 'LandingpageEditorModal',
	featureFlag: 'PORTED_LANDINGPAGE_EDITOR_ROUTE_AND_MODAL'
});

const LandingpageEditorRoute = (props: any) => {
	const self = Tools.AppService.getSelf();
	const [checkingAccess, setCheckingAccess] = useState(true);
	const { params } = useRouteMatch<{ id?: string }>();
	const { search } = useLocation();
	const query = new URLSearchParams(search);
	const isCopy: any = query.get('copy') ?? undefined;
	const classes = new bemClass('LandingpageEditor');
	classes.add('LandingpageEditor__route');

	useEffect(() => {
		if (
			// isAvailable
			(!(Tools as any).FeatureHelper.placeholder(Tools.FeatureHelper.Feature.FORMS) && // <-- TODO: Remove the first check when this has been live for a while (scopes need to be refrshed)
				!(Tools as any).FeatureHelper.placeholder(Tools.FeatureHelper.Feature.FORMS_BACKEND)) ||
			// authUser
			!Tools.AppService.isLoggedIn() ||
			// authRights
			!(self.userParams.mailAdmin || self.administrator)
		) {
			logError('Missing access rights to landing page editor');
			window.Tools.routerHistory.push('/forms');
			return;
		}
		setCheckingAccess(false);
	}, []);

	if (checkingAccess) {
		return null;
	}
	return (
		<div className={classes.b()}>
			{' '}
			<LandingpageEditorWrapper
				{...props}
				{...params}
				isModal={false}
				copy={isCopy === 'true' || isCopy === '1' || isCopy === true}
			/>
		</div>
	);
};

export const PortedLandingpageEditorRoute = reactRouteCompatibility(LandingpageEditorRoute, {
	featureFlag: 'PORTED_LANDINGPAGE_EDITOR_ROUTE_AND_MODAL'
});

async function getMeta(params: any) {
	// // Do not use manual opt-ins in forms
	const rb = new RequestBuilder();
	const { OptIn, AccountProfile } = Tools;
	rb.addFilter(OptIn.attr.type, rb.comparisonTypes.NotEquals, 'manual');
	const [accountProfile, templates, optIns, domains, form]: any = await Promise.all([
		AccountProfile.get(),
		LandingPageTemplateResource.find(),
		OptIn.find(rb.build()),
		DomainsResource.find(),
		params.id
			? Tools.Form.get(params.id).then(function (res: any) {
					if (params.copy) {
						res.data.name = res.data.name + ' (' + T('default.copied').toLowerCase() + ')';
						delete res.data.id;
					}
					return res;
			  })
			: undefined
	]);

	const res: any = {
		accountProfile,
		templates,
		optIns,
		domains,
		form
	};

	if (!params.id) {
		res.form = { data: Tools.Form.new(res.accountProfile.data, res.optIns.data) };

		if (params.name) {
			res.form.data.name = params.name;
		}

		if (params.eventId) {
			res.form.data.actions.push({
				action: 'EventRegistration',
				properties: [
					{
						name: 'EventId',
						value: params.eventId
					}
				]
			});
			res.form.data.socialEventId = params.eventId;
		}

		if (params.domainProps) {
			res.form.data.domain = params.domainProps.domain;
			res.form.data.urlName = params.domainProps.urlName;
		}
	} else if (params.eventId) {
		res.form.data.socialEventId = params.eventId;
	}

	if (params.templateName) {
		res.template = _.find(res.templates.data, { name: params.templateName });
	}

	res.templates.data = _.filter(res.templates.data, 'active');

	return res;
}
