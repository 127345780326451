import React, { JSXElementConstructor } from 'react';
import TimelineGeneric from '../TimelineGeneric/TimelineGeneric';
import { EVENT_TYPES } from 'App/babel/enum/appointmentEvents';
import { EVENT_TIMELINE_SUB_TYPES } from 'App/babel/enum/timeline';
import { TimelinePostponeSubtitle } from '../TimelinePostpone';
import CreatedEvent from 'App/components/HistoryLog/AppointmentHistoryLog/CreatedEvent';
import MailSentEvent from 'App/components/HistoryLog/AppointmentHistoryLog/MailSentEvent';
import UpdatedEvent from 'App/components/HistoryLog/AppointmentHistoryLog/UpdatedEvent';
import ActivityEvent from 'App/components/HistoryLog/AppointmentHistoryLog/ActivityEvent';
import AppointmentOutcome from 'App/resources/Model/AppointmentOutcome';
import getPostponedDuration from 'Components/Helpers/getTimelinePostponeDuration';
import { useTranslation } from 'Components/Helpers/translate';
import { openEditAppointment } from 'Components/Modals/Appointment/EditAppointment';
import type { TimelineCardComponent } from '../TimelineGeneric/TimelineCardConfigs';

const TimelineCardAppointment = (({ event, renderedById, ...passthrough }) => {
	const { source } = event.appointment || {};
	const { t } = useTranslation();

	const appointmentEvent = {
		data: event.data || {},
		date: event.date,
		user: event.users?.[0],
		type: event.subType || undefined,
		id: event.id
	};

	const bypassConfig = (Component: JSXElementConstructor<any>, additionalProps?: { [key: string]: any }) => (
		<TimelineGeneric
			event={event}
			bypassComponent={<Component event={appointmentEvent} {...(additionalProps || {})} />}
			{...passthrough}
		/>
	);

	// TODO: Adapt these components to TimelineGeneric instead of bypassing
	switch (event.subType) {
		case EVENT_TYPES.CREATED:
			return bypassConfig(CreatedEvent, { source });
		case EVENT_TYPES.MAILSENT:
			return bypassConfig(MailSentEvent);
		case EVENT_TYPES.UPDATED:
			return bypassConfig(UpdatedEvent);
		case EVENT_TYPES.ACTIVITYCREATED:
		case EVENT_TYPES.APPOINTMENTCREATED:
		case EVENT_TYPES.CALLCREATED:
			return bypassConfig(ActivityEvent, { id: event.entityId });
	}

	const alwaysIncludeProps = {
		event: event,
		commentFetchFilters: { appointmentId: event.entityId },
		notes: event.appointment?.notes || '',
		...passthrough
	};

	const rescheduled = event.data?.rescheduled;
	if (rescheduled) {
		const postponeDuration = getPostponedDuration(new Date(rescheduled.date));
		return (
			<TimelineGeneric
				actionTitle={'activity.outcome.rescheduledTo'}
				titleElements={['users', 'action', () => postponeDuration]}
				subTitleElements={[
					() => <TimelinePostponeSubtitle date={rescheduled.date} oldDate={rescheduled.oldDate} />
				]}
				{...alwaysIncludeProps}
			/>
		);
	}

	const isPassed = event.subType?.toLowerCase() === EVENT_TIMELINE_SUB_TYPES.PASSED;
	const {
		description = '',
		outcome,
		activityType,
		id: eventAppointmentId
	} = event.appointment || {
		activityType: { name: '' }
	};
	const activityTypeName = activityType?.name ?? '';

	const actionTitle = () => {
		if (renderedById === eventAppointmentId) {
			return t('appointment.hadPlannedThis').toLowerCase();
		} else if (isPassed) {
			switch (outcome) {
				case AppointmentOutcome.COMPLETED:
					return t('appointment.outcome.completed').toLowerCase();
				case AppointmentOutcome.NOT_COMPLETED:
					return t('appointment.notCompleted').toLowerCase();
				case AppointmentOutcome.PLANNED:
				default:
					return t('appointment.hadPlanned').toLowerCase();
			}
		} else {
			return t('default.hasPlannedAppointment').toLowerCase();
		}
	};

	return (
		<TimelineGeneric
			actionTitle={actionTitle}
			eventLinkTitle={renderedById !== eventAppointmentId ? () => description : undefined}
			onLinkClick={() => openEditAppointment({ id: eventAppointmentId })}
			afterTime={() => activityTypeName ?? ''}
			{...alwaysIncludeProps}
		/>
	);
}) satisfies TimelineCardComponent;

export default TimelineCardAppointment;
