import { Loader, Flex, Row, Text, Link } from '@upsales/components';
import SimilarCustomersResource from 'Resources/SimilarCustomers';
import React, { useState, useEffect, useRef } from 'react';
import Bem from '@upsales/components/Utils/bemClass';
import { makeCancelable } from 'Helpers/promise';
import Client from 'App/resources/Model/Client';
import T from 'Components/Helpers/translate';
import logError from 'Helpers/logError';
import './SimilarCustomers.scss';

type Props = {
	client: Client;
	bold?: boolean;
	renderTitle?: boolean;
	variant?: 'account' | 'editOrder' | 'editPhonecall';
	close?: () => void;
};

type SimilarCustomersResponse = {
	data: SimilarCustomer[];
};

type SimilarCustomer = {
	id: number;
	name: string;
	sniCode: string;
	sales: number;
	takeRate: number | null;
};

const SimilarCustomers = ({ client, bold = true, renderTitle = false, variant = 'account', close }: Props) => {
	const classes = new Bem('SimilarCustomers');
	const lang = {
		similarCustomers: T('default.similarCustomers'),
		and: T('default.and')
	};
	const hasNewClientCard = Tools.FeatureHelper.hasSoftDeployAccess('REACT_CLIENT_CARD');

	const [similarCustomers, setSimilarCustomers] = useState<SimilarCustomersResponse | null>(null);
	const [loading, setLoading] = useState(false);
	const getSimilarCustomersPromise = useRef<any>(null);

	const goToAccount = (id: number, hasNewClientCard: boolean) => {
		const route = hasNewClientCard ? 'react-root-clientCard' : 'account.dashboard';

		Tools.$state.go(
			route,
			{
				customerId: Tools.AppService.getCustomerId(),
				id,
				page: 'overview'
			},
			{
				inherit: false
			}
		);
	};

	const getSpacing = () => {
		switch (variant) {
			case 'editPhonecall':
			case 'editOrder':
				return 'mts mbs';
			case 'account':
			default:
				return hasNewClientCard ? 'mtm mbs' : 'mtm mbs';
		}
	};

	useEffect(() => {
		if (!client.id) return;

		setLoading(true);
		getSimilarCustomersPromise.current = makeCancelable(SimilarCustomersResource.get(client.id));

		getSimilarCustomersPromise.current.promise
			.then((data: SimilarCustomersResponse) => {
				setSimilarCustomers(data);
			})
			.catch((err: Error) => {
				if (!err.message.includes('canceled')) {
					logError(err, 'Failed to fetch similar customers');
				}
				setSimilarCustomers(null);
			})
			.finally(() => setLoading(false));

		return () => {
			getSimilarCustomersPromise.current?.cancel();
		};
	}, [client.id]);

	if (loading) return <Loader size="sm" />;

	return (
		<>
			{similarCustomers?.data && similarCustomers.data.length > 0 ? (
				<Flex direction="column" space={getSpacing()}>
					{renderTitle ? (
						<Text size="sm" bold={variant === 'account' && bold}>
							{lang.similarCustomers}
						</Text>
					) : null}
					<Flex className={classes.mod(variant).b()} direction="column" gap="u2" flex={1}>
						<Row>
							{similarCustomers?.data
								?.map((item: SimilarCustomer) => (
									<Link
										className={classes.elem('customer').b()}
										key={item.id}
										onClick={e => {
											e.stopPropagation();
											close?.(); // Closes e.g. EditPhoneCall or EditOrder
											goToAccount(item.id, hasNewClientCard);
										}}
										target="_blank"
										nostyle
									>
										{item.name}
									</Link>
								))
								.reduce((prev: React.ReactNode[], curr: React.ReactNode, i: number) => {
									if (i === 0) return [curr];
									return [
										...prev,
										<Text key={`separator-${i}`} className={classes.elem('and').b()} size="sm">
											{lang.and.toLowerCase()}
										</Text>,
										curr
									];
								}, [])}
						</Row>
					</Flex>
				</Flex>
			) : null}
		</>
	);
};

export default SimilarCustomers;
