import BemClass from '@upsales/components/Utils/bemClass';
import { useTranslation } from 'Components/Helpers/translate';
import { Flex, Help, Modal, ModalContent, ModalControls, ModalHeader, Toggle, Tooltip } from '@upsales/components';
import React, { useEffect, useState } from 'react';
import AlertConfirm from 'Components/Dialogs/AlertConfirm';

import './ExportDataReact.scss';
import { useSelector, useSoftDeployAccess } from '../hooks';
import TemplateHeaderSelector, {
	ExportTemplate
} from 'App/upsales/common/components/react/templateHeaderSelector/templateHeaderSelector';
import { ModalProps, useModalClose } from '../Modals/Modals';
import { asyncModalAdapter } from 'App/helpers/angularPortingHelpers';
import {
	getCustomfieldsByType,
	getNewTemplate,
	getReducedColumnInfo,
	getSelectablesByType,
	EntityToExportTypes,
	EntityToCustomFields,
	getDefaultColumnsByType
} from './helpers';
import logError from 'Helpers/logError';
import ColumnsTemplateResource from '../../resources/ColumnsTemplate';
import { useMetadata, useSelf } from '../hooks/appHooks';
import { MultiSelectContext, useMultiselect } from '../MultiselectProvider/MultiselectProvider';
import RequestBuilder, { BuildFilters, comparisonTypes } from 'Resources/RequestBuilder';
import openModal from 'App/services/Modal';
import ExportResource, { ExportSaveType } from 'Resources/Export';
import type { Column, FieldType, Selectables, Widget } from './types';
import CustomField from 'App/resources/Model/CustomField';
import _ from 'lodash';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

export type OnCloseReturnType = {};

type Props = ModalProps<OnCloseReturnType> & {
	exportType: string;
	filters: BuildFilters;
	sort?: { attribute: string; ascending: boolean };
	includes?: string[];
	entity: string;
	justExport?: boolean;
	widget?: Widget;
	selectedIds?: MultiSelectContext['selectedIds'];
	allSelected?: MultiSelectContext['allSelected'];
	optionsByType?: boolean;
	selectables?: EntityToExportTypes;
	customfields?: EntityToCustomFields;
	columns?: string[];
	options?: { idField?: string };
};

const ExportDataReact = (props: Props) => {
	const classes = new BemClass('ExportDataReact', props.className);
	const { t } = useTranslation();
	const hasExportTemplates = useSoftDeployAccess('EXCEL_EXPORT_TEMPLATES');
	const hasNewFields = useSoftDeployAccess('NEW_FIELDS');
	const hasAccountPlans = useSoftDeployAccess('ACCOUNT_PLAN');
	const [columns, setColumns] = useState([] as ExportTemplate['columns']);
	const [templates, setTemplates] = useState([] as ExportTemplate[]);
	const [defaultTemplate, setDefaultTemplate] = useState<null | ExportTemplate>(null);
	const [selectedTemplate, setSelectedTemplate] = useState<null | ExportTemplate>(null);
	const [selectables, setSelectables] = useState<null | Selectables>(null);
	const [udoTitle, setUdoTitle] = useState<null | string>(null);
	const [templateHasChanged, setTemplateHasChanged] = useState(false);
	const [exportOrderRows, setExportOrderRows] = useState(false);
	const [isExporting, setIsExporting] = useState(false);
	const [showOrderRowToggle, setShowOrderRowToggle] = useState(false);
	const multiSelect = useMultiselect();
	const self = useSelf();
	const metadata = useMetadata();
	const customFieldsMap = useSelector(({ App }) => App.customFields);
	const categoryTypeMap = useSelector(({ App }) => App.categoryTypes);

	const isWidgetExport = !!props.widget;
	const templatesKey = props.exportType + 'ExportTemplates';
	const options = props.options || {};

	// TODO: This makes Opportunities behave exactly like Orders. This will work, but the text/notifications will be wrong.
	const exportEntity = props.entity === 'Opportunity' ? 'Order' : props.entity;

	useEffect(() => {
		const cols = setFields() ?? []; // We do it first to get the default values
		if (hasExportTemplates) {
			const defaultTemplate = getNewTemplate(-1, t('default.default'), [...cols]);
			setDefaultTemplate(defaultTemplate);
			setTemplates([defaultTemplate]);
			getTemplates()
				.then(res => {
					setTemplates(prev => [...prev, ...res]);
					const defaultSelected = res.find(t => t.selected) ?? defaultTemplate;
					onTemplateChange(defaultSelected);
				})
				.catch(logError);
		}
	}, []);

	useEffect(() => {
		setFields(true);
	}, [selectedTemplate?.id]);

	const saveTemplate = async function (template: Partial<ExportTemplate>) {
		const { data, error } = await ColumnsTemplateResource.save({
			...template,
			userId: self?.id,
			columns: getReducedColumnInfo(template.columns),
			templateKey: templatesKey
		});
		if (error) {
			throw error;
		}
		return data as any;
	};

	const saveDefault = async () => {
		if (hasExportTemplates) {
			const previousSelected = templates.find(t => t.selected);
			if (previousSelected?.id !== selectedTemplate?.id) {
				const promises = [];
				if (previousSelected && previousSelected.id !== -1) {
					previousSelected.selected = false;
					promises.push(saveTemplate(previousSelected));
				}
				if (selectedTemplate && selectedTemplate.id !== -1) {
					selectedTemplate.selected = true;
					promises.push(saveTemplate(selectedTemplate));
				}
				await Promise.all(promises);
			}
		}
	};

	const onChangeExportOrderRows = () => {
		const newValue = !exportOrderRows;
		setExportOrderRows(newValue);
		if (hasExportTemplates && selectedTemplate) {
			setTemplateHasChanged(true);
			setSelectedTemplate(prev => ({ ...prev!, exportOrderRows: newValue }));
		}
	};

	const getValidColumns = () => {
		return columns.filter(c => c.isValidField);
	};

	// This is is called from modal when the courtain is closed
	useModalClose(props.modalId, saveDefault, [templates, selectedTemplate?.id]);

	const onDone = async () => {
		setIsExporting(true);
		if (hasExportTemplates) {
			await saveDefault();
		}

		const filters = props.filters;
		if (!isWidgetExport && props.sort && (!filters.sort || !filters.sort.length)) {
			if (props.sort.attribute) {
				const sort = {
					a: props.sort.attribute,
					s: props.sort.ascending ? 'A' : 'Z'
				};
				filters.sort = [sort];
			}
		}

		const columns = getValidColumns().map(column => {
			/**
				This is for deep customFields, agreements orderrow hax
				and orderrow on orders
			**/
			var key = column.entity + '_' + column.key;
			if (column.innerEntity) {
				key = column.innerEntity + '_' + column.key;
			} else if (column.field) {
				key = column.entity + '_' + column.field;
			}

			const entity = column.entity === 'Client' ? 'column.clientId' : column.entity;
			const title = `${t(entity)}: ${t(column.title)}`;

			return {
				title,
				key: key
			};
		});

		const data = { entity: exportEntity, columns } as ExportSaveType;

		if (props.includes) {
			data.includes = props.includes;
		}

		if (exportEntity.indexOf('UserDefinedObject') === 0 && props.exportType) {
			data.name = props.exportType;
		}

		if (!props.justExport && !isWidgetExport) {
			if (!multiSelect.allSelected && !props.allSelected) {
				var requestBuilder = new RequestBuilder();

				var field = 'id';

				if (options.idField) {
					field = options.idField;
				}

				const selected = multiSelect.selectedIds.length ? multiSelect.selectedIds : props.selectedIds;

				requestBuilder.addFilter({ field: field }, requestBuilder.comparisonTypes.Equals, selected);
				if (props.sort) {
					requestBuilder.addSort(props.sort.attribute, props.sort.ascending);
				}
				filters.q = filters.q ? filters.q.concat(requestBuilder.build().q) : requestBuilder.build().q;
			}
		}

		data.filters = filters;

		if (['Order', 'Agreement'].includes(exportEntity) && exportOrderRows) {
			data.exportOrderRows = true;
		}

		if (isWidgetExport) {
			data.widget = props.widget;
		}

		ExportResource.save(data)
			.then(props.close)
			.catch(e => {
				logError(e, 'export error');
				setIsExporting(false);
			});
	};

	const onTemplateHeaderSave = async (name: string, isNew?: boolean) => {
		let selected: ExportTemplate | null = null;
		try {
			if (isNew) {
				selected = (await saveTemplate({ name, columns: columns })) as ExportTemplate;
				setTemplates([...templates, selected]);
			} else {
				selected = await saveTemplate({
					...selectedTemplate,
					name,
					columns: columns
				});
				setTemplates(
					templates.map(t => {
						if (t.id === selected!.id) {
							return selected!;
						}
						return t;
					})
				);
			}
		} catch (error) {
			logError(error, 'Error saving columns template');
		}

		setSelectedTemplate(selected);
		setTemplateHasChanged(false);
	};

	function toggleSelect(entity: string, fieldType: FieldType) {
		if (getVisibleCount(entity, fieldType) === 0) {
			deselectAll(entity, fieldType);
		} else {
			selectAll(entity, fieldType);
		}
	}

	function selectAll(entity: string, fieldType: FieldType) {
		selectables![entity][fieldType].forEach(col => {
			if (col.visible) {
				add(col, true);
			}
		});
	}

	function deselectAll(entity: string, fieldType: FieldType) {
		selectables![entity][fieldType].forEach(col => {
			remove(col);
		});
	}

	function add(col: Column, hasChanged = false, setFieldsSelectables?: Selectables, setFieldsColumns?: Column[]) {
		const cols = setFieldsColumns ?? columns;
		setTemplateHasChanged(hasChanged);
		if (!cols.find(c => c.key === col.key && c.entity === col.entity)) {
			if (setFieldsColumns) {
				setFieldsColumns.push(col);
			} else {
				setColumns(prev => [...prev, col]);
			}
			setVisibility(col.entity, col.key, false, col.innerEntity, setFieldsSelectables);
		} else if (
			col.innerEntity &&
			!cols.find(c => c.key === col.key && c.entity === col.entity && c.innerEntity === col.innerEntity)
		) {
			if (setFieldsColumns) {
				setFieldsColumns.push(col);
			} else {
				setColumns(prev => [...prev, col]);
			}
			setVisibility(col.entity, col.key, false, col.innerEntity, setFieldsSelectables);
		}
	}

	function remove(col: Column) {
		setTemplateHasChanged(true);
		if (col.locked && col.entity === exportEntity) {
			return;
		}
		setColumns(prevColumns => prevColumns.filter(c => c.key !== col.key || c.entity !== col.entity));
		setVisibility(col.entity, col.key, true, col.innerEntity);
	}

	function getVisibleCount(entity: string, fieldType: FieldType) {
		var entitySelectable = selectables![entity];
		return _.where(entitySelectable[fieldType], { visible: true }).length;
	}

	function setVisibility(
		entity: Column['entity'],
		key: Column['key'],
		visible: Column['visible'],
		innerEntity: Column['innerEntity'],
		setFieldsSelectables?: Selectables
	) {
		const entitySelectable = setFieldsSelectables ? setFieldsSelectables[entity] : selectables![entity];
		let col = null;
		const columns = key.indexOf('Custom_') === 0 ? entitySelectable.customFields : entitySelectable.attributes;

		if (innerEntity) {
			col = _.find(columns, { key: key, innerEntity: innerEntity });
		} else {
			col = _.find(columns, { key: key });
		}

		if (col) {
			col.visible = visible;
		}
		if (!setFieldsSelectables) {
			setSelectables(prev => ({ ...prev, [entity]: entitySelectable }));
		}
	}

	function mapField(entity: string, col: any) {
		const key = entity + '_' + col.field;
		const c = { ...col };
		c.entity = entity;
		c.$translated = t(col.title) || col.field;
		c.key = key;
		c.visible = true;
		c.isValidField = true;
		c.locked = (col.locked && entity === exportEntity) || false;
		return c;
	}

	function mapCustomFields(entity: string, field: CustomField, innerEntity?: string) {
		const key = 'Custom_' + field.id;
		return {
			key: key,
			entity: entity,
			innerEntity: innerEntity,
			title: field.name,
			$translated: t(field.name),
			visible: (field.visible || field.editable) && field.$hasAccess,
			isValidField: field.$hasAccess
		};
	}

	function mapAlreadySelected(mapped: Column, setFieldsColumns: Column[]) {
		if (hasExportTemplates) {
			const alreadySelected = setFieldsColumns.findIndex(
				c =>
					c.key === mapped.key &&
					c.entity === mapped.entity &&
					(mapped.innerEntity ?? null) === (c.innerEntity ?? null)
			);
			if (alreadySelected > -1) {
				setFieldsColumns[alreadySelected] = mapped;
				return true;
			}
		}
		return false;
	}

	function setFields(skipDefaults = false) {
		let customfields: EntityToCustomFields;
		let selectablesToEvaluate: EntityToExportTypes;
		const updatedSelectables = {} as Selectables;
		const updatedColumns = [...columns];
		if (props.optionsByType) {
			customfields = getCustomfieldsByType(props.exportType, customFieldsMap);
			selectablesToEvaluate = getSelectablesByType(props.exportType, categoryTypeMap);
		} else {
			customfields = props.customfields!;
			selectablesToEvaluate = props.selectables!;
		}

		let udoTitle = null;

		setShowOrderRowToggle(['Order', 'Agreement'].indexOf(exportEntity) !== -1);

		if (exportEntity.indexOf('UserDefinedObject') === 0) {
			const udos = metadata?.params.UserDefinedObject ?? [];
			let id = parseInt(exportEntity[exportEntity.length - 1]);
			id = isNaN(id) ? 1 : id;

			const udo = _.find(udos, { id: id });
			if (udo) {
				udoTitle = udo.name;
			}
		}
		setUdoTitle(udoTitle);

		const standardFields = metadata?.standardFields ?? {};
		Object.entries(selectablesToEvaluate).forEach(([key, attributes]) => {
			const columns = Object.values(attributes);
			if (!updatedSelectables[key]) {
				updatedSelectables[key] = { attributes: [], customFields: [] };
			}

			columns.forEach(column => {
				const mapped = mapField(key, column);
				let found;

				if (mapped.unreleasedFeature === 'NEW_FIELDS') {
					found = _.find(standardFields[key], { field: mapped.field });
					if (!hasNewFields || !found || !found.active) {
						return;
					}
				}
				if (mapped?.unreleasedFeature === 'ACCOUNT_PLAN' && !hasAccountPlans) {
					return;
				}
				if (mapped !== null && mapped.field) {
					if (mapAlreadySelected(mapped, updatedColumns)) {
						mapped.visible = false;
					}
					updatedSelectables[key].attributes.push(mapped);
					if (mapped.locked && exportEntity === key) {
						add(mapped, false, updatedSelectables, updatedColumns);
					}
				}
			});
			const entityCustom = customfields![key];
			if (!entityCustom || (!entityCustom.length && typeof entityCustom !== 'object')) {
				return;
			}

			if (Array.isArray(entityCustom)) {
				entityCustom.forEach(column => processColumn(column));
			} else {
				Object.entries(entityCustom).forEach(([innerKey, column]) => {
					column.forEach(innerColumn => {
						processColumn(innerColumn, innerKey);
					});
				});
			}

			function processColumn(column: CustomField, innerKey?: string) {
				const mapped = mapCustomFields(key, column, innerKey);
				if (mapped !== null && column.visible) {
					if (mapAlreadySelected(mapped, updatedColumns)) {
						mapped.visible = false;
					}
					updatedSelectables[key].customFields.push(mapped);
				}
			}
		});
		setSelectables(updatedSelectables);
		if (skipDefaults) {
			setColumns(updatedColumns);
			return;
		}
		(props.columns || getDefaultColumnsByType(props.exportType) || []).forEach(columnName => {
			// Ugly hack. ColumnName refers to old status column which SHOULD have been named History.
			if (exportEntity === 'Client' && columnName === 'status') {
				return;
			}
			const exportField = selectablesToEvaluate[exportEntity][columnName];
			let field;
			if (exportField) {
				field = exportField.field;
			} else {
				field = columnName;
			}
			const entitySelectable = updatedSelectables[exportEntity];
			let col = null;
			col = _.find(entitySelectable.attributes, { field: field });
			if (!col) {
				col = _.find(entitySelectable.customFields, { key: field });
			}
			if (col) {
				add(col, false, updatedSelectables, updatedColumns);
			}
		});
		setColumns(updatedColumns);
		return updatedColumns;
	}

	function changeTemplate(selectedOption: ExportTemplate) {
		setColumns([...selectedOption.columns].sort((a, b) => (a.sorting ?? 0) - (b.sorting ?? 0)));
		setTemplateHasChanged(false);
		setSelectedTemplate(selectedOption);
		setExportOrderRows(selectedOption.exportOrderRows ?? false);
	}

	function onTemplateChange(selectedOption: ExportTemplate) {
		if (selectedOption.id === selectedTemplate?.id) {
			return;
		}
		if (templateHasChanged) {
			const alertConfirmOptions = {
				type: 'confirm',
				reactive: true,
				dialog: AlertConfirm,
				id: 'confirm-non-saved-changes',
				body: t('confirm.doYouWantToContinue'),
				title: t('confirm.changesWillBeLost'),
				confirmButtonText: t('default.confirm')
			};
			openModal('Alert', {
				...alertConfirmOptions,
				onClose: confirmed => {
					if (confirmed) {
						changeTemplate(selectedOption);
					}
				}
			});
			return;
		}
		changeTemplate(selectedOption);
	}

	async function onDelete() {
		const { error } = await ColumnsTemplateResource.delete(selectedTemplate!.id);
		if (error) {
			logError(error, 'Error deleting columns template');
			return;
		}
		const newTemplates = templates.filter(t => t.id !== selectedTemplate!.id);
		setTemplates(newTemplates);
		const defaultTemplate = newTemplates[0];
		onTemplateChange(defaultTemplate);
	}

	async function getTemplates() {
		let templates: ExportTemplate[] = [];
		try {
			const filters = new RequestBuilder();
			filters.addFilter({ field: 'userId' }, comparisonTypes.Equals, self?.id);
			filters.addFilter({ field: 'templateKey' }, comparisonTypes.Equals, templatesKey);
			const response = await ColumnsTemplateResource.find(filters.build());
			templates = response.data as any;
		} catch (e) {
			logError(e, 'error getting columns templates');
		}
		return templates;
	}

	function onDrop({ destination, source }: { source: { index: number }; destination?: { index: number } }) {
		if (!destination) {
			return;
		}

		const newColumns = [...columns];
		const [draggedCol] = newColumns.splice(source.index, 1);
		newColumns.splice(destination.index, 0, draggedCol);
		setColumns(newColumns);
		setTemplateHasChanged(true);
	}

	return (
		<Modal className={classes.b()} size="lg">
			<ModalHeader onClose={() => props.close()} title={t('export.exportFields')} icon="edit">
				{hasExportTemplates && defaultTemplate && selectedTemplate ? (
					<TemplateHeaderSelector
						onChange={onTemplateChange}
						hasChanged={templateHasChanged}
						options={templates}
						onSave={onTemplateHeaderSave}
						selectedValue={selectedTemplate}
						onDelete={onDelete}
						defaultTemplate={defaultTemplate}
					/>
				) : null}
			</ModalHeader>

			<ModalContent>
				<div className="up-panel">
					<div className="row">
						<div className="col-md-6">
							<b>{t('filters.selectedColumns')}</b>
							<DragDropContext onDragEnd={onDrop}>
								<Droppable droppableId="columns">
									{droppableProvided => (
										<div
											className="dropzone"
											{...droppableProvided.droppableProps}
											ref={droppableProvided.innerRef}
										>
											{columns.map((column, index) =>
												column.isValidField ? (
													<Draggable
														key={`${column.key}`}
														draggableId={column.key}
														index={index}
													>
														{draggableProvided => (
															<div
																className="draggable"
																ref={draggableProvided.innerRef}
																{...draggableProvided.draggableProps}
																{...draggableProvided.dragHandleProps}
															>
																<div className="inner">
																	<div className="textContent">
																		<b className="fa fa-bars handle"></b>
																		<span>
																			{!udoTitle
																				? t(
																						`default.${column.entity.toLowerCase()}`
																				  )
																				: udoTitle}
																		</span>
																		<span>: </span>
																		<span>{column.$translated}</span>
																	</div>
																	{!column.locked ? (
																		<Tooltip
																			position="right"
																			title={t('filters.columns.remove')}
																		>
																			<b
																				className="fa fa-times remove"
																				onClick={() => remove(column)}
																			></b>
																		</Tooltip>
																	) : null}
																</div>
															</div>
														)}
													</Draggable>
												) : null
											)}
											{droppableProvided.placeholder}
										</div>
									)}
								</Droppable>
							</DragDropContext>
						</div>
						<div className="col-md-6">
							<p>
								<i>{t('filters.columns.clickToAdd')}</i>
							</p>
							{selectables
								? Object.entries(selectables).map(([entity, data]) => (
										<div key={entity}>
											<div className="header-entity">
												<h4>
													{!udoTitle
														? t(`filters.columns.${entity.toLowerCase()}`)
														: udoTitle}
												</h4>
												<a onClick={() => toggleSelect(entity, 'attributes')}>
													<span>
														{` (${
															getVisibleCount(entity, 'attributes') !== 0
																? t('export.selectAll')
																: t('export.deselectAll')
														})`}
													</span>
												</a>
											</div>
											<p>
												{data.attributes
													.filter(column => column.visible)
													.sort((a, b) => a.$translated.localeCompare(b.$translated))
													.map(column => (
														<a
															key={column.key}
															className="addable"
															onClick={() => add(column, true)}
														>
															{column.$translated}
														</a>
													))}
											</p>
											<p>
												<span>
													<b>
														{!udoTitle
															? `${t('default.field.other')} ${t(
																	`filters.columns.${entity.toLowerCase()}`
															  )}`
															: `${t('default.field.other')} ${udoTitle}`}
													</b>
													<a onClick={() => toggleSelect(entity, 'customFields')}>
														<span>
															{getVisibleCount(entity, 'customFields') !== 0
																? ` (${t('export.selectAll')})`
																: ` (${t('export.deselectAll')})`}
														</span>
													</a>
												</span>
												{data.customFields
													.filter(column => column.visible)
													.sort((a, b) => a.$translated.localeCompare(b.$translated))
													.map(column => (
														<a
															key={`${column.innerEntity ?? column.entity}_${column.key}`}
															className="addable"
															onClick={() => add(column, true)}
														>
															{column.$translated}
														</a>
													))}
											</p>
										</div>
								  ))
								: null}
						</div>
					</div>
				</div>
			</ModalContent>

			<ModalControls>
				{showOrderRowToggle ? (
					<Flex
						direction="row"
						alignItems="center"
						gap="u1"
						inline
						className={classes.elem('row-toggle').b()}
					>
						<Toggle checked={exportOrderRows} onChange={onChangeExportOrderRows} />
						<label>{t('export.exportOrderRows')}</label>
						<Help articleId={1061} sidebar />
					</Flex>
				) : null}

				{isExporting ? <b className="fa fa-spin fa-refresh loader"></b> : null}
				<button
					disabled={getValidColumns().length === 0 || isExporting}
					type="submit"
					onClick={onDone}
					className="btn up-btn btn-bright-blue no-shadow btn-primary-action"
				>
					{t('default.export')}
				</button>

				<button type="button" className="btn up-btn btn-grey btn-link" onClick={() => props.close()}>
					{t('default.abort')}
				</button>
			</ModalControls>
		</Modal>
	);
};

export const openExportData = asyncModalAdapter({
	upModalName: 'ExportData',
	openModalName: 'ExportDataReact',
	featureFlag: 'REACT_EXPORT_DATA'
});

export default ExportDataReact;
