import { asyncModalAdapter } from 'App/helpers/angularPortingHelpers';
import React, { useEffect, useState } from 'react';
import SegmentResource, { Segment } from 'App/resources/Segment';
import logError from 'Helpers/logError';
import { Loader } from '@upsales/components';

type Props = {
	saving: boolean;
	selectedSegments: number[];
	selected: number[];
	close: () => void;
};

export function AddToSegmentModal(props: Props) {
	const [offset, setOffset] = useState(0);
	const [saving, setSaving] = useState(false);
	const [segments, setSegments] = useState<Segment[]>([]);
	const [selectedSegments, setSelectedSegments] = useState(props.selectedSegments ?? []);
	const [selectedIds, setSelectedIds] = useState(props.selected);
	const [allLoaded, setAllLoaded] = useState(false);
	const limit = 40;

	const closeModal = () => {
		props.close();
	};

	const getData = () => {
		SegmentResource.find({ limit: limit, offset: offset })
			.then(function (res) {
				if (res.data.length === 0) {
					setAllLoaded(true);
				}
				setSegments(prev => prev.concat(res.data));
				setOffset(prev => prev + limit);
			})
			.catch(error => logError(error, 'addToSegmentModal.js - getData failed'));
	};

	const refetch = () => {
		if (!allLoaded) {
			getData();
		}
	};

	const selectNone = () => {
		setSelectedIds([]);
	};

	const closeAfterSave = () => {
		setSaving(false);

		// Deselect all
		selectNone();

		// Close modal
		props.close();
	};

	const onSave = () => {
		setSaving(true);
		SegmentResource.add(selectedIds, { name: 'AddSegments', value: selectedSegments })
			.then(function () {
				Tools.NotificationService.addNotification({
					style: Tools.NotificationService.style.SUCCESS,
					icon: 'check',
					title: 'default.saved',
					body: 'saved.contacts'
				});
				closeAfterSave();
			})
			.catch(function () {
				setSaving(false);
				Tools.NotificationService.addNotification({
					style: Tools.NotificationService.style.ERROR,
					icon: 'times',
					title: 'default.error',
					body: 'saveError.contacts'
				});
			});
	};

	const checkbox = (id: number) => {
		if (selectedSegments.indexOf(id) > -1) {
			setSelectedSegments(prev => prev.filter(segmentId => segmentId !== id));
		} else {
			setSelectedSegments(prev => [...prev, id]);
		}
	};

	useEffect(() => {
		/* init */
		SegmentResource.find({ limit: limit, offset: offset })
			.then(res => {
				if (res.data.length === 0) {
					setAllLoaded(true);
				}
				setSegments(res.data);
				setOffset(prev => prev + limit);
			})
			.catch(error => logError(error, 'addToSegmentModal.js - getData failed'));
	}, []);

	if (!segments) {
		return (
			<div
				style={{
					width: '550px',
					height: '428px',
					display: 'block',
					visibility: 'visible'
				}}
				id="addToSegment__root"
				className="up-modal notranslate width550 info ng-scope compiled has-controls"
			>
				<div style={{ display: 'flex', justifyContent: 'center', position: 'relative', top: '100px' }}>
					<Loader size="lg" />
				</div>
			</div>
		);
	}

	// Inline styling is used here just to make the modal visible, since it is otherwise called with angular and there it gets some different styling
	// via classes that it doesn't get with opening via react
	return (
		<div
			style={{
				width: '550px',
				height: '428px',
				display: 'block',
				visibility: 'visible'
			}}
			id="addToSegment__root"
			className="up-modal notranslate width550 info ng-scope compiled has-controls"
		>
			<ReactTemplates.segment.addToSegmentModal
				limit={limit}
				offset={offset}
				saving={saving}
				segments={segments}
				selectedSegments={selectedSegments}
				all_loaded={allLoaded}
				sort={null}
				tools={{
					AppService: Tools.AppService,
					$translate: Tools.$translate
				}}
				closeModal={closeModal}
				refetch={refetch}
				onSave={onSave}
				checkbox={checkbox}
			/>
		</div>
	);
}

const openAddToSegmentModal = asyncModalAdapter({
	upModalName: 'addToSegment',
	openModalName: 'AddToSegmentModal',
	featureFlag: 'REACT_ADD_TO_SEGMENT',
	debug: true
});

export default openAddToSegmentModal;
