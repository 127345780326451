import _ from 'lodash';
import getAngularModule from 'App/babel/angularHelpers/getAngularModule';
import LZString from 'lz-string';
import { trim, get } from 'lodash';
import { openDrawer } from 'Services/Drawer';
import openModal from 'App/services/Modal';
import { removeHtml } from '@upsales/common';
import SocialEventResource from 'App/babel/resources/SocialEvents';
import T from 'Components/Helpers/translate';

import { TABS } from './FormOverviewActions';
import { TABS as SOCIAL_EVENT_TABS } from '../reducers/SocialEventReducer';
import { openMailTemplateBrowserModal } from 'App/components/MailTemplateBrowserModal/MailTemplateBrowserModal';
import { openAssignModalLead } from 'Components/AssignModal/AssignModalLead';
import { openUpdateContactAction } from 'App/components/MultiActionModal/MultiUpdateContact/OpenMultiUpdateContact';
import { openUpdateClientAction } from 'App/components/MultiActionModal/MultiUpdateClient/openMultiUpdateClientModal';
import { openCreateActivityAction } from 'App/components/MultiActionModal/CreateActivityAction/openCreateActivityAction';
import { openSendEmailAction } from 'App/components/SendEmailAction/openSendEmailAction';

const prefix = '[FormEditor] ';
export const RESET = `${prefix} RESET`;
export const ADD_NEW_FIELD = `${prefix} ADD_NEW_FIELD`;
export const UPDATE = `${prefix} UPDATE`;
export const UPDATE_HTML = `${prefix} UPDATE_HTML`;
export const UPDATE_FORM = `${prefix} UPDATE_FORM`;
export const TOGGLE_SIDEBAR = `${prefix} TOGGLE_SIDEBAR`;
export const SELECT_FIELD_TYPE = `${prefix} SELECT_FIELD_TYPE`;
export const UPDATE_SIDEBAR_VIEW = `${prefix} UPDATE_SIDEBAR_VIEW`;
export const EDIT_PREVIEW_FIELD = `${prefix} EDIT_PREVIEW_FIELD`;
export const SEARCH_FIELD = `${prefix} SEARCH_FIELD`;
export const CLEAR_SEARCH_FIELD = `${prefix} CLEAR_SEARCH_FIELD`;
export const UPDATE_VALIDITY = `${prefix} UPDATE_VALIDITY`;

export const SIDEBAR_VIEW = {
	FIELD_TYPE: 'fieldType',
	ADD_FIELD: 'addField',
	EDIT: 'edit'
};

export const STEP = {
	DESIGN: 'design',
	SETTINGS: 'settings'
};

export const FIELD_TYPE = {
	OPT_IN: 'optIn',
	FORM_SPECIFIC: 'formSpecific',
	RECAPTCHA: 'reCaptcha',
	DATA_FIELD: 'dataField'
};

const formActions = [
	{ title: 'automationAction.SendEmail', action: 'SendEmail' },
	{ title: 'automationAction.AssignToUser', action: 'AssignToUser' },
	{ title: 'automationAction.UpdateClient', action: 'UpdateClient' },
	{ title: 'automationAction.UpdateContact', action: 'UpdateContact' },
	{ title: 'automationAction.SendWebhook', action: 'SendWebhook' },
	{
		title: 'automationAction.CreateActivity',
		action: 'CreateActivity',
		available: () =>
			!(
				Tools.FeatureHelper.hasSoftDeployAccess('REMOVE_ACTIVITIES') &&
				Tools.FeatureHelper.hasSoftDeployAccess('TODO_LIST')
			)
	},
	{
		title: 'automationAction.PlanPhonecalls',
		action: 'PlanPhonecalls',
		available: () => Tools.FeatureHelper.hasSoftDeployAccess('TODO_LIST')
	},
	{
		title: 'automationAction.CreateTodo',
		action: 'CreateTodo',
		available: () => Tools.FeatureHelper.hasSoftDeployAccess('TODO_LIST')
	},
	{ title: 'automationAction.SendTemplate', action: 'SendMapMail' }
];

const formActionMultipleActions = ['SendEmail', 'SendWebhook'];

export const init = (meta, params) => async dispatch => {
	const FeatureHelper = getAngularModule('FeatureHelper');
	const $upModal = getAngularModule('$upModal');
	const AppService = getAngularModule('AppService');
	// Reset state
	dispatch({ type: RESET });

	const form = meta.form.data;
	const accountProfile = meta.accountProfile.data;
	const optIns = meta.optIns.data;
	const hasVerifyDomainAccess = FeatureHelper.isAvailable(FeatureHelper.Feature.REQUIRE_BUSINESS_EMAIL);
	const hasNewFields = Tools.FeatureHelper.hasSoftDeployAccess(Tools.FeatureHelper.Feature.NEW_FIELDS);
	const hasNameField = _.find(form.fields, { name: 'Contact.name' });
	const accountCustomFields = AppService.getCustomFields('account');
	const language =
		(Tools.FeatureHelper.hasSoftDeployAccess('FORM_COUNTRY_LANGUAGE') && Tools.AppService.getSelf().language) || '';

	const standartFieldNames = [
		{ name: 'OrgNo', datatype: 'text' },
		{ name: 'NoEmployees', datatype: 'number' },
		{ name: 'Facebook', datatype: 'text' },
		{ name: 'Twitter', datatype: 'text' },
		{ name: 'LinkedIn', datatype: 'text' }
	];
	const clientFields = [
		{ title: 'tag.client.name', name: 'Client.name', datatype: 'text', type: 'standard', disabled: false },
		{ title: 'tag.client.phone', name: 'Client.phone', datatype: 'text', type: 'standard', disabled: false },
		{ title: 'tag.client.webpage', name: 'Client.webpage', datatype: 'text', type: 'standard', disabled: false },
		{ title: 'tag.client.address', name: 'Client.address', datatype: 'text', type: 'standard', disabled: false },
		{ title: 'tag.client.zipcode', name: 'Client.zipcode', datatype: 'text', type: 'standard', disabled: false },
		{ title: 'tag.client.city', name: 'Client.city', datatype: 'text', type: 'standard', disabled: false },
		{
			title: 'tag.client.country',
			name: 'Client.country',
			datatype: 'text',
			type: 'standard',
			disabled: false,
			language
		},
		{ title: 'tag.Contact.email', name: 'Contact.email', datatype: 'text', type: 'standard', disabled: true },
		{ title: 'tag.Contact.title', name: 'Contact.title', datatype: 'text', type: 'standard', disabled: false },
		{ title: 'tag.Contact.phone', name: 'Contact.phone', datatype: 'text', type: 'standard', disabled: false },
		{
			title: 'tag.Contact.cellPhone',
			name: 'Contact.cellPhone',
			datatype: 'text',
			type: 'standard',
			disabled: false
		}
	];

	if (form.socialEventId) {
		const { data: socialEvent } = await SocialEventResource.get(form.socialEventId);
		dispatch({ type: UPDATE, payload: { socialEvent } });
	}

	if (hasNewFields && !hasNameField) {
		clientFields.splice(
			7,
			0,
			{
				title: 'tag.Contact.firstname',
				name: 'Contact.firstname',
				datatype: 'text',
				type: 'standard',
				disabled: false
			},
			{
				title: 'tag.Contact.lastname',
				name: 'Contact.lastname',
				datatype: 'text',
				type: 'standard',
				disabled: false
			}
		);
	} else {
		clientFields.splice(7, 0, {
			title: 'tag.Contact.name',
			name: 'Contact.name',
			datatype: 'text',
			type: 'standard',
			disabled: false
		});
	}

	if (hasNewFields) {
		const standartFields = Tools.AppService.getMetadata().standardFields.Client || {};
		const mappedFields = standartFieldNames
			.map(({ name, datatype }) => {
				const field = standartFields[name];
				if (!field || !field.active) return null;

				return {
					title: field.nameTag,
					name: `Client.${field.field}`,
					type: 'standard',
					disabled: false,
					datatype
				};
			})
			.filter(field => !!field);
		clientFields.push(...mappedFields);
	}

	if (meta.oldForm) {
		$upModal.open('warningAlert', {
			title: 'form.oldForm',
			body: 'form.oldFormInfo'
		});
	}

	dispatch({ type: UPDATE, payload: { meta, params, form, accountProfile } });
	const { currentHash } = await dispatch(parseForm());

	if (accountCustomFields.length) {
		// add client customFields to select from
		accountCustomFields.forEach(cf => {
			if (!cf.editable) {
				return;
			}
			const tag = 'Client.custom_' + cf.id;
			clientFields.push({
				name: tag,
				title: cf.name,
				datatype: translateCfDatatype(cf),
				type: 'cf',
				options: getCfOptions(cf),
				disabled: false
			});
		});
	}

	const contactCustomFields = AppService.getCustomFields('contact');

	if (contactCustomFields.length) {
		// add contact custom fields to select from
		contactCustomFields.forEach(cf => {
			if (!cf.editable) {
				return;
			}
			const tag = 'Contact.custom_' + cf.id;
			clientFields.push({
				name: tag,
				title: cf.name,
				datatype: translateCfDatatype(cf),
				type: 'cf',
				options: getCfOptions(cf),
				disabled: false
			});
		});
	}

	form.fields = _.map(form.fields, field => {
		// Extend the field in available fieldsArr and set it to use = true
		const availableField = _.find(clientFields, { name: field.name });
		if (availableField) {
			field = _.merge(_.clone(availableField), field);
			field.required = !!field.required;
			if (!field.datatype) {
				field.datatype = 'text';
			}
			const i = _.findIndex(clientFields, { text: field.name });
			if (i !== -1 && !clientFields[i].disabled) {
				clientFields[i].disabled = true;
			}
		} else if (field.name.indexOf('Extra.') === 0) {
			field.type = 'extra';
			field.required = !!field.required;
		}
		return field;
	});

	dispatch({
		type: UPDATE,
		payload: {
			clientFields,
			accountCustomFields,
			contactCustomFields,
			meta,
			form,
			optIns,
			hasVerifyDomainAccess,
			hasNewFields,
			fields: form.fields,
			editPreviewField: get(form.fields, '[0].name', null),
			initialHash: currentHash
		}
	});

	dispatch(updateAvailableFields());
	dispatch(validateFields());

	return Promise.resolve();
};

export const removeAction = action => async (dispatch, getState) => {
	const { form } = getState().FormEditor;

	const newForm = _.cloneDeep(form);

	newForm.actions.splice(form.actions.indexOf(action), 1);

	dispatch(saveForm(newForm));
};

export const updateAction = (action, newProperties) => async (dispatch, getState) => {
	const { form } = getState().FormEditor;

	const updatedActions = form.actions.map(item => {
		if (item.action === 'AssignToUser') {
			return { ...action, properties: newProperties };
		}
		return item;
	});

	const newForm = { ...form, actions: updatedActions };
	dispatch(saveForm(newForm));
};

export const editAction = action => async (dispatch, getState) => {
	const { form, accountCustomFields, contactCustomFields } = getState().FormEditor;

	let propertiesPromise;
	if (action.action === 'PlanPhonecalls' || action.action === 'CreateTodo') {
		if (action.action === 'PlanPhonecalls') {
			propertiesPromise = new Promise(onSave => {
				openDrawer('PlanPhonecallsAction', {
					properties: action.properties,
					assignOneUser: true,
					onSave
				});
			});
		} else {
			propertiesPromise = new Promise(onSave => {
				openDrawer('CreateTodosAction', {
					properties: action.properties,
					assignOneUser: true,
					onSave
				});
			});
		}
	} else {
		const modalMeta = {
			accountCategories: Tools.AppService.getCategories('account'),
			accountCustomFields: accountCustomFields,
			contactCategories: Tools.AppService.getCategories('account'),
			contactCustomFields: contactCustomFields,
			activityCustomFields: Tools.AppService.getCustomFields('activity'),
			activeUsers: Tools.AppService.getActiveUsers(),
			roles: Tools.AppService.getRoles(),
			activityTypes: Tools.AppService.getActivityTypes('activity', true)
		};

		const params = {
			properties: action.properties,
			accountCategories: modalMeta.accountCategories,
			accountCustomFields: modalMeta.accountCustomFields,
			contactCategories: modalMeta.contactCategories,
			contactCustomFields: modalMeta.contactCustomFields,
			activityCustomFields: modalMeta.activityCustomFields,
			campaigns: modalMeta.campaigns,
			leadSources: modalMeta.leadSources,
			activeUsers: modalMeta.activeUsers,
			roles: modalMeta.roles,
			activityTypes: modalMeta.activityTypes,
			action: action.action,
			entity: 'form',
			hideDate: true,
			isTrigger: true,
			isAutomation: true
		};

		if (action.action === 'SendEmail') {
			params.extraTags = generateExtraTags(form.fields);
			params.extraTags.push({
				text: T('form.form2') + ' ' + T('default.name'),
				value: '{{Form.Name}}'
			});
		}

		const modalName = action.action + 'Action';

		// Add angular/ react openModal here, key = name of angular modal
		const openModalActions = {
			UpdateContactAction: openUpdateContactAction,
			UpdateClientAction: openUpdateClientAction,
			CreateActivityAction: openCreateActivityAction,
			SendEmailAction: openSendEmailAction
		};

		// Open modal
		if (Tools.FeatureHelper.hasSoftDeployAccess('SEND_WEBHOOK_REACT') && modalName === 'SendWebhookAction') {
			propertiesPromise = new Promise((res, rej) => {
				openModal('SendWebhook', {
					tagEntity: 'formSubmit',
					properties: params.properties,
					onClose: data => {
						if (data) {
							res(data);
						} else {
							rej();
						}
					}
				});
			});
		} else if (modalName === 'AssignToUserAction') {
			propertiesPromise = openAssignModalLead({
				tagEntity: 'formSubmit',
				properties: params.properties
			});
		} else if (modalName === 'SendMapMailAction') {
			const properties = action.properties ?? [];
			let templateProperty = properties.find(property => property.name === 'MailTemplate');
			if (!templateProperty) {
				templateProperty = { name: 'MailTemplate', value: null };
				properties.push(templateProperty);
			}

			propertiesPromise = openMailTemplateBrowserModal({
				selectedTemplateId:
					templateProperty && templateProperty.value ? parseInt(templateProperty.value) : null,
				requireUnsub: false
			}).then(selectedTemplate => {
				if (selectedTemplate) {
					templateProperty.value = selectedTemplate.id;
					return properties;
				}
			});
		} else if (openModalActions[modalName]) {
			propertiesPromise = openModalActions[modalName](params);
		} else {
			propertiesPromise = Tools.$upModal.open(modalName, params);
		}
	}

	// eslint-disable-next-line promise/catch-or-return
	propertiesPromise
		.then(properties => {
			action.properties = properties;
			dispatch(saveForm(_.cloneDeep(form)));
		})
		.catch(() => {});
};

export const addNewAction = () => async (dispatch, getState) => {
	const { form, accountCustomFields, contactCustomFields, fields } = getState().FormEditor;
	const t = Tools.$translate;

	const modalMeta = {
		accountCategories: Tools.AppService.getCategories('account'),
		accountCustomFields: accountCustomFields,
		contactCategories: Tools.AppService.getCategories('account'),
		contactCustomFields: contactCustomFields,
		activityCustomFields: Tools.AppService.getCustomFields('activity'),
		activeUsers: Tools.AppService.getActiveUsers(),
		roles: Tools.AppService.getRoles(),
		activityTypes: Tools.AppService.getActivityTypes('activity', true)
	};

	const dataHelper = () => {
		return _.map(
			_.filter(formActions, a => {
				const listObj = _.find(form.actions, { action: a.action });

				// Remove featurewrapped actions
				if (a.available && !a.available()) {
					return false;
				}

				if (!listObj || formActionMultipleActions.indexOf(a.action) !== -1) {
					return a;
				}
			}),
			a => {
				const obj = { title: t(a.title), action: a.action };

				if (
					a.action === 'SendWebhook' &&
					!Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.TRIGGERS)
				) {
					obj.$$disabled = true;
					obj.title = '<span class="text-grey">' + t(a.title) + '</span>';
					obj.missingFeature =
						'<span class="text-grey text-xs">' +
						t('default.versionMissingFeature') +
						' ' +
						t('feature.triggers').toLowerCase() +
						'</span>';
				}

				return obj;
			}
		);
	};

	const onCloseHelper = action => {
		const params = {
			accountCategories: modalMeta.accountCategories,
			accountCustomFields: modalMeta.accountCustomFields,
			contactCategories: modalMeta.contactCategories,
			contactCustomFields: modalMeta.contactCustomFields,
			activityCustomFields: modalMeta.activityCustomFields,
			campaigns: modalMeta.campaigns,
			leadSources: modalMeta.leadSources,
			activeUsers: modalMeta.activeUsers,
			roles: modalMeta.roles,
			activityTypes: modalMeta.activityTypes,
			action: action.action,
			entity: 'form',
			hideDate: true,
			isTrigger: true,
			isAutomation: true
		};

		if (action.action === 'SendEmail') {
			params.prefill = {
				to: '',
				subject: t('form.emailSubject') + ' {{Form.Name}}',
				body: generateEmail(t('form.emailHeader'), form.fields, t('form.emailFooter'))
			};

			params.extraTags = generateExtraTags(form.fields);
			params.extraTags.push({
				text: t('form.form2') + ' ' + t('default.name'),
				value: '{{Form.Name}}'
			});
		}
		if (action.action === 'SendWebhook') {
			params.prefill = {
				body: '{\n  "form": "{{Form.Name}}",\n  "fields": ' + generateJson(form.fields) + '\n}',
				contentType: 'application/json',
				encoding: 'UTF-8',
				method: 'POST'
			};
		}
		if (action.action === 'CreateActivity') {
			params.prefill = {
				note: generateNote(fields),
				description: t('form.filledOut') + ' {{Form.Name}}'
			};
		}

		let propertiesPromise;
		if (action.action === 'PlanPhonecalls' || action.action === 'CreateTodo') {
			const properties = [
				{ name: 'Description', value: t('form.filledOut') + ' {{Form.Name}}' },
				{ name: 'Notes', value: generateNote(fields) },
				{ name: 'User', value: Tools.AppService.getSelf().id }
			];

			if (action.action === 'PlanPhonecalls') {
				propertiesPromise = new Promise(onSave => {
					openDrawer('PlanPhonecallsAction', {
						properties,
						onSave,
						assignOneUser: true
					});
				});
			} else {
				propertiesPromise = new Promise(onSave => {
					openDrawer('CreateTodosAction', {
						properties,
						onSave,
						assignOneUser: true
					});
				});
			}
		} else {
			// Add angular/ react openModal here, key = name of angular modal
			const openModalActions = {
				UpdateContactAction: openUpdateContactAction,
				UpdateClientAction: openUpdateClientAction,
				CreateActivityAction: openCreateActivityAction,
				SendEmailAction: openSendEmailAction
			};

			const modalName = action.action + 'Action';
			// Open modal
			if (Tools.FeatureHelper.hasSoftDeployAccess('SEND_WEBHOOK_REACT') && modalName === 'SendWebhookAction') {
				propertiesPromise = new Promise((res, rej) => {
					openModal('SendWebhook', {
						tagEntity: 'formSubmit',
						properties: params.properties || params.prefill,
						onClose: data => {
							if (data) {
								res(data);
							} else {
								rej();
							}
						}
					});
				});
			} else if (modalName === 'SendMapMailAction') {
				const properties = action.properties ?? [];
				let templateProperty = properties.find(property => property.name === 'MailTemplate');
				if (!templateProperty) {
					templateProperty = { name: 'MailTemplate', value: null };
					properties.push(templateProperty);
				}

				propertiesPromise = openMailTemplateBrowserModal({
					selectedTemplateId:
						templateProperty && templateProperty.value ? parseInt(templateProperty.value) : null,
					requireUnsub: false
				}).then(selectedTemplate => {
					if (selectedTemplate) {
						templateProperty.value = selectedTemplate.id;
						return properties;
					}
				});
			} else if (openModalActions[modalName]) {
				propertiesPromise = openModalActions[modalName](params);
			} else {
				propertiesPromise = Tools.$upModal.open(modalName, params);
			}
		}

		// eslint-disable-next-line promise/catch-or-return
		propertiesPromise.then(properties => {
			const newForm = _.cloneDeep(form);

			newForm.actions.push({
				properties,
				id: Date.now(),
				action: action.action
			});

			dispatch(saveForm(newForm));
		});
	};

	if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_LIST')) {
		openModal('ListModal', {
			title: 'admin.addAction',
			hideHeader: true,
			columns: [
				{ title: 'default.name', value: 'title' },
				{ title: '', value: 'missingFeature' }
			],
			data: dataHelper(),
			onClose: onCloseHelper
		});
	} else {
		// eslint-disable-next-line promise/catch-or-return
		Tools.$upModal
			.open('list', {
				title: 'admin.addAction',
				hideHeader: true,
				columns: [
					{ title: 'default.name', value: 'title' },
					{ title: '', value: 'missingFeature' }
				],
				data: dataHelper()
			})
			.then(action => onCloseHelper(action));
	}
};

export const onFormChangeDebounced = _.debounce(async (form, dispatch) => {
	if (!form) {
		throw new Error('Form is not defined for onFormChange');
	}

	form.fields = _.map(form.fields, (f, i) => {
		f.sort = i + 1;
		return f;
	});

	dispatch({
		type: UPDATE_FORM,
		payload: { form }
	});

	dispatch(updateAvailableFields());
	dispatch(validateFields());
	await dispatch(parseForm());
	await dispatch(autoSave());
}, 300);

export const onFormChange = form => dispatch => onFormChangeDebounced(form, dispatch);

export function saveForm(form) {
	return async dispatch => {
		dispatch({ type: UPDATE_FORM, payload: { form } });
		await dispatch(autoSave());
	};
}

export const editRootField = (fieldName, value) => (dispatch, getState) => {
	const { form } = getState().FormEditor;

	if (fieldName === 'name') {
		value = removeHtml(value);
	}
	const newForm = { ...form, [fieldName]: trim(value) };

	dispatch(onFormChange(newForm));
};

export function parseForm() {
	return (dispatch, getState) => {
		const { form, meta, accountProfile, params } = getState().FormEditor;

		return new Promise((resolve, reject) => {
			const $templateParser = getAngularModule('$templateParser');

			$templateParser.parse(
				form,
				accountProfile,
				{ optIns: meta.optIns.data, liveTags: params.liveTags, isSocialEvent: !!form.socialEventId },
				(err, html) => {
					if (err) {
						reject(err);
					} else {
						const payload = {
							html,
							currentHash: LZString.compressToBase64(JSON.stringify(html))
						};

						dispatch({
							payload,
							type: UPDATE_HTML
						});

						resolve(payload);
					}
				}
			);
		});
	};
}

export const editPreviewField = fieldName => dispatch => {
	dispatch({ type: EDIT_PREVIEW_FIELD, payload: { fieldName } });

	setTimeout(() => {
		dispatch({ type: UPDATE, payload: { editPreviewField: null } });
	}, 1000);
};

export const toggleSidebar = () => dispatch => dispatch({ type: TOGGLE_SIDEBAR });

export const goToAddField = fieldType => dispatch => dispatch({ type: SELECT_FIELD_TYPE, payload: { fieldType } });

export const goToFormFields = () => dispatch => dispatch(updateSidebarView(SIDEBAR_VIEW.EDIT));

export const goToFieldType = () => dispatch => dispatch(updateSidebarView(SIDEBAR_VIEW.FIELD_TYPE));

export const searchField = searchText => dispatch => dispatch({ type: SEARCH_FIELD, payload: { searchText } });
export const clearSearchText = () => dispatch => dispatch({ type: CLEAR_SEARCH_FIELD });

export function updateSidebarView(sidebarView) {
	return dispatch => dispatch({ type: UPDATE_SIDEBAR_VIEW, payload: { sidebarView } });
}

export const addPreset = color => (dispatch, getState) => {
	const AccountProfile = getAngularModule('AccountProfile');
	const { accountProfile } = getState().FormEditor;

	accountProfile.extraColors.push({ value: color });

	dispatch({ type: UPDATE, payload: { accountProfile } });

	return AccountProfile.save(accountProfile);
};

export const addNewCaptchaField = () => (dispatch, getState) => {
	const { form } = getState().FormEditor;
	const t = Tools.$translate;
	const captchaField = {
		title: t('form.Prefs.reCaptchaFake'),
		name: 'Prefs.reCaptcha',
		type: 'text',
		datatype: 'text'
	};

	captchaField.sort = form.fields.length + 1;
	captchaField.required = false;
	form.fields.push(captchaField);

	dispatch(onFormChange(form));
	dispatch(goToFormFields());
};

export const addNewField =
	(selectedField, goToEdit = false) =>
	(dispatch, getState) => {
		const { form } = getState().FormEditor;
		const t = Tools.$translate;
		const field = { ...selectedField };
		const recaptchaIndex = form.fields.findIndex(item => item.name === 'Prefs.reCaptcha');

		if (field.type === 'extra') {
			field.name = 'Extra.' + Date.now();
		}
		field.sort = form.fields.length + 1;
		field.required = false;

		if (field.datatype === 'optIn') {
			field.required = field.optInType === 'double';
		} else if (field.type === 'standard') {
			field.title = t('form.' + field.name);
		} else if (field.type !== 'cf') {
			field.title = t('datatype.' + (field.datatype.charAt(0).toUpperCase() + field.datatype.slice(1)));
		}

		if (recaptchaIndex !== -1) {
			form.fields.push(form.fields.splice(recaptchaIndex, 1)[0]);
		}

		form.fields.push(field);
		dispatch(onFormChange(form));

		if (goToEdit) {
			dispatch(editPreviewField(field.name));
		}
	};

function checkIfOptionsAreInvalid(field) {
	return !field.options || !field.options.trim().length;
}

function fieldsAreInvalid(fields) {
	let result = false;

	_.each(fields, function (field) {
		if (field.datatype === 'select' || field.datatype === 'radio') {
			field.hasError = checkIfOptionsAreInvalid(field);
		} else {
			field.hasError = false;
		}

		if (field.hasError && !result) {
			result = true;
		}
	});

	return result;
}

export function autoSave() {
	return (dispatch, getState) => {
		const { form, valid } = getState().FormEditor;
		if (!valid) {
			return;
		}

		dispatch({ type: UPDATE, payload: { saving: true } });

		const Form = getAngularModule('Form');

		return Form.save(_.clone(form), { skipNotification: true })
			.then(() => {
				dispatch({ type: UPDATE, payload: { saving: false } });
			})
			.catch(() => {
				dispatch({ type: UPDATE, payload: { saving: false } });
			});
	};
}

export const publish = () => (dispatch, getState) => {
	const { form, fields, meta, params, accountProfile } = getState().FormEditor;
	const { customerId } = getState().App;
	const $templateParser = getAngularModule('$templateParser');
	const Form = getAngularModule('Form');

	if (fieldsAreInvalid(fields)) {
		Tools.NotificationService.addNotification({
			style: Tools.NotificationService.style.WARN,
			icon: 'warning',
			title: 'default.error',
			body: 'form.multiFieldsRequireOptions'
		});

		return false;
	}

	dispatch({ type: UPDATE, payload: { saving: true } });

	$templateParser.parseDist(
		form,
		accountProfile,
		{
			optIns: meta.optIns.data,
			liveTags: params.liveTags,
			isSocialEvent: !!form.socialEventId,
			domains: meta.domains.data
		},
		(err, html) => {
			form.html = html;
			Form.save(form)
				.then(res => {
					dispatch({ type: UPDATE, payload: { saving: false } });
					if (form.socialEventId) {
						window.Tools.routerHistory.push(
							`/${customerId}/events/edit/${form.socialEventId}?tab=${SOCIAL_EVENT_TABS.RESOURCES}`
						);
					} else {
						window.Tools.routerHistory.push(`/form-overview/${res.data.id}?tab=${TABS.OPTIONS}`);
					}
				})
				.catch(function () {
					dispatch({ type: UPDATE, payload: { saving: false } });
				});
		}
	);
};

export const suggestBisnodeChanged = () => (dispatch, getState) => {
	const { form } = getState().FormEditor;
	const index = _.findIndex(form.fields, { name: 'Prefs.bisnodeAutocomplete' });

	if (index === -1) {
		form.fields.push(getPrefs('bisnodeAutocomplete'));
	} else {
		form.fields.splice(index, 1);
	}

	dispatch(onFormChange(form));
};

export const verifyEmailDomain = () => (dispatch, getState) => {
	const { form } = getState().FormEditor;
	const index = _.findIndex(form.fields, { name: 'Prefs.verifyEmailDomain' });

	if (index === -1) {
		form.fields.push(getPrefs('verifyEmailDomain'));
	} else {
		form.fields.splice(index, 1);
	}

	dispatch(onFormChange(form));
};

export const getAvailableOptInsByType = type => (dispatch, getState) => {
	const { meta, form } = getState().FormEditor;

	return _.filter(meta.optIns.data, function (optIn) {
		return !_.includes(_.pluck(form.fields, 'options'), String(optIn.id)) && optIn.type === type;
	});
};

export const getAllOptIns = () => (dispatch, getState) => {
	const { meta } = getState().FormEditor;

	return _.groupBy(meta.optIns.data, optIn => optIn.type);
};

export const getAvailableOptIns = () => (dispatch, getState) => {
	const { meta, form } = getState().FormEditor;

	const out = {};
	const optInGroup = _.groupBy(meta.optIns.data, optIn => optIn.type);
	_.forEach(optInGroup, (arr, type) => {
		out[type] = _.filter(arr, optIn => {
			return !_.includes(_.pluck(form.fields, 'options'), String(optIn.id)) && optIn.type === type;
		});
	});

	return out;
};

export function updateAvailableFields() {
	return (dispatch, getState) => {
		const { fields, clientFields } = getState().FormEditor;

		const availableFields = _.filter(clientFields, field => {
			if (field.type !== 'standard') {
				return field;
			}
			const usedField = _.find(fields, { name: field.name });
			if (!usedField) {
				return field;
			}
		});

		dispatch({ type: UPDATE, payload: { availableFields } });
	};
}

export function validateFields() {
	return (dispatch, getState) => {
		const { form } = getState().FormEditor;
		const { thankYouElement } = form;
		let valid = true;
		const stepErrors = {
			[STEP.DESIGN]: {},
			[STEP.SETTINGS]: {}
		};

		['buttonText'].forEach(field => {
			if (!trim(form[field])) {
				stepErrors[STEP.DESIGN][field] = 'inlineError.required';
				valid = false;
			}
		});

		if (thankYouElement && thankYouElement.type === 'landingPage') {
			try {
				new URL(thankYouElement.value.href);
			} catch (e) {
				stepErrors[STEP.SETTINGS].redirectUrl = 'form.linkNotValid';
				valid = false;
			}
		}

		const requiredFields = ['name'];

		if (!thankYouElement || !thankYouElement.type) {
			requiredFields.push('thankYouTitle');
		}

		requiredFields.forEach(field => {
			if (!trim(form[field])) {
				stepErrors[STEP.SETTINGS][field] = 'inlineError.required';
				valid = false;
			}
		});

		dispatch({ type: UPDATE_VALIDITY, payload: { stepErrors, valid } });
	};
}

export const updatePref = pref => (dispatch, getState) => {
	const form = _.cloneDeep(getState().FormEditor.form);

	const index = _.findIndex(form.fields, { name: pref.name });
	if (index !== -1) {
		form.fields.splice(index, 1);
	} else {
		form.fields.push(pref);
	}

	dispatch(onFormChange(form));
};

export const updateForm = form => ({
	type: UPDATE_FORM,
	payload: { form }
});

export const abort = () => (dispatch, getState) => {
	const { form } = getState().FormEditor;
	const { customerId } = getState().App;

	if (form.socialEventId) {
		window.Tools.routerHistory.push(
			`/${customerId}/events/edit/${form.socialEventId}?tab=${SOCIAL_EVENT_TABS.RESOURCES}`
		);
	} else {
		window.history.back();
	}
};

export function getPrefs(name) {
	const t = Tools.$translate;
	const prefs = {
		reCaptcha: { title: t('form.Prefs.reCaptchaFake'), name: 'Prefs.reCaptcha', type: 'checkbox' },
		dontMatchOnClientName: {
			title: t('form.Prefs.dontMatchOnClientName'),
			name: 'Prefs.dontMatchOnClientName',
			type: 'checkbox'
		},
		bisnodeAutocomplete: {
			title: 'form.Prefs.bisnodeAutocomplete',
			name: 'Prefs.bisnodeAutocomplete',
			type: 'checkbox'
		},
		verifyEmailDomain: { title: 'form.Prefs.verifyEmailDomain', name: 'Prefs.verifyEmailDomain', type: 'checkbox' },
		doubleOptIn: {
			title: t('form.Prefs.doubleOptInDefaultValue'),
			name: 'Prefs.doubleOptIn',
			defaultValue: 'form.Prefs.doubleOptInDefaultValue',
			type: 'checkbox'
		}
	};

	return name ? prefs[name] : prefs;
}

function translateCfDatatype(cf) {
	if (!cf.datatype || cf.datatype === 'String') {
		return 'text';
	} else if (cf.datatype === 'Date') {
		return 'date';
	} else if (cf.datatype === 'Email') {
		return 'email';
	} else if (cf.datatype === 'Currency' || cf.datatype === 'Integer') {
		return 'number';
	} else if (cf.datatype === 'Boolean') {
		return 'checkbox';
	} else if (cf.datatype === 'Select') {
		return 'select';
	} else if (cf.datatype === 'Text') {
		return 'textarea';
	} else if (cf.datatype === '') {
		return 'textarea';
	}

	return 'text';
}

function getCfOptions(cf) {
	if (!cf['default'] || !Array.isArray(cf['default'])) {
		return null;
	}
	return cf['default'].map(encodeURIComponent).join(',');
}

function generateFieldTable(fields, first) {
	const keys = _.keys(fields);
	let bgColor = '#ffffff';
	let table =
		'<table field-table-exists-for-real=true border=0 width=400 cellpadding=0 cellspacing=0 contentEditable=false>';

	table += '<tr><td></tr>';

	_.forEach(keys, function (field) {
		if (!fields[field].text && !fields[field].name) {
			return;
		}
		if (fields[field].type !== 'cf' && fields[field].type !== 'extra' && fields[field].type !== 'standard') {
			return;
		}
		if (bgColor === '#ffffff') {
			bgColor = '#EDF1F5';
		} else {
			bgColor = '#ffffff';
		}

		table +=
			'<tr><td style="font-family:arial;size:13px;padding:4px;background-color:' +
			bgColor +
			'"><font face="arial" size=2><b>';
		table += fields[field].title;
		table += '</b></font></td></tr>';
		table +=
			'<tr><td style="font-family:arial;size:13px;padding:4px;background-color:' +
			bgColor +
			'"><font face="arial" size=2>';
		if (fields[field].text) {
			table += '{{FormSubmit.' + fields[field].text + '}}';
		} else {
			table += '{{FormSubmit.' + fields[field].name + '}}';
		}
		table += '</font></td></tr>';
	});

	if (first) {
		table += '<tr><td></tr>';
	}

	table += '</table>';

	return table;
}

function generateEmail(header, fields, footer) {
	let output = '<font face="arial" style="font-family:arial;size:13px;" size=2>';
	output += header;
	output += '</font>';
	output += generateFieldTable(fields, true);
	output += '<font face="arial" size=2 style="font-family:arial;size:13px;">';
	output += footer;
	output += '</font>';

	return output;
}

function generateExtraTags(fields) {
	const keys = _.keys(fields);
	const output = [];

	_.forEach(keys, function (field) {
		let value;
		if (!fields[field].text && !fields[field].name) {
			return;
		}
		if (fields[field].type !== 'cf' && fields[field].type !== 'extra' && fields[field].type !== 'standard') {
			return;
		}
		const text = Tools.$translate('form.form') + ': ' + fields[field].title;
		if (fields[field].text) {
			value = '{{FormSubmit.' + fields[field].text + '}}';
		} else {
			value = '{{FormSubmit.' + fields[field].name + '}}';
		}
		output.push({
			text: text,
			value: value
		});
	});
	return output;
}

function generateNote(fields) {
	const keys = _.keys(fields);
	let output = '';

	_.forEach(keys, function (field) {
		if (!fields[field].text && !fields[field].name) {
			return;
		}
		if (fields[field].type !== 'cf' && fields[field].type !== 'extra' && fields[field].type !== 'standard') {
			return;
		}
		output += fields[field].title + ':\n';
		if (fields[field].text) {
			output += '{{FormSubmit.' + fields[field].text + '}}';
		} else {
			output += '{{FormSubmit.' + fields[field].name + '}}';
		}
		output += '\n\n';
	});

	return output;
}

function generateJson(fields) {
	const keys = _.keys(fields);
	let output = '[';
	let noOfFields = 0;

	_.forEach(keys, function (field) {
		if (!fields[field].text && !fields[field].name) {
			return;
		}
		if (fields[field].type !== 'cf' && fields[field].type !== 'extra' && fields[field].type !== 'standard') {
			return;
		}
		if (noOfFields > 0) {
			output += ',';
		}
		output += '\n    {"' + fields[field].title + '": ';
		if (fields[field].text) {
			output += '"{{FormSubmit.' + fields[field].text + '}}"';
		} else {
			output += '"{{FormSubmit.' + fields[field].name + '}}"';
		}
		output += '}';
		noOfFields++;
	});
	output += '\n  ]';

	return output;
}
