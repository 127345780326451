import {
	Table,
	TableRow,
	TableColumn,
	Text,
	TableHeader,
	TableHeaderColumn,
	Tooltip,
	Flex,
	Icon
} from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import { formatTurnoverGroup, formatValue } from '../Helpers/Helpers';
import { useIndustryInsights } from '../Context/Context';
import T from 'Components/Helpers/translate';
import './KeyFiguresTable.scss';
import React from 'react';

interface Props {
	clientName: string;
	loading?: boolean;
	metrics: {
		growth?: number;
		operatingMargin?: number;
		netDebtEbitdaRatio?: number;
		netDebt?: number;
		ebitda?: number;
		employmentRate?: number;
		netSalesPerEmployee?: number;
	};
	industryMetrics: {
		growth?: number;
		growthSampleCount?: number;
		operatingMargin?: number;
		operatingMarginSampleCount?: number;
		netDebtEbitdaRatio?: number;
		netDebtEbitdaSampleCount?: number;
		employmentRate?: number;
		employmentRateSampleCount?: number;
		netSalesPerEmployee?: number;
		netSalesPerEmployeeSampleCount?: number;
	};
	outliers: {
		count: number;
		totalSamples: number;
		highestValue: number;
		lowestValue: number;
	};
}

const KeyFiguresTable = ({ clientName, loading, metrics, industryMetrics, outliers }: Props) => {
	const { turnoverGroup, description } = useIndustryInsights();
	const classes = new BemClass('KeyFiguresTable');
	const FRACTION_DIGITS = 2;
	const lang = {
		keyFiguresTableTitle: T('ai.industryInsights.keyFiguresTableTitle', { client: clientName }),
		similarCompanies: T('default.similarCompanies'),
		inIndustry: T('default.inIndustry'),
		growth: T('client.target.growth'),
		operatingMargin: T('default.operatingMargin'),
		netDebt: T('default.netDebt'),
		ebitda: T('default.ebitda'),
		employmentRate: T('default.employmentRate'),
		netSalesPerEmployee: T('default.netSalesPerEmployee'),
		extremeValuesFound: T('ai.industryInsights.extremeValuesFound'),
		similarCompaniesTooltip: T('ai.industryInsights.similarCompaniesTooltip', {
			value: turnoverGroup ? `${formatTurnoverGroup(turnoverGroup)}` : '',
			industry: description
		}),
		clientExtremeValue: T('ai.industryInsights.clientExtremeValue'),
		clientExtremeValueContext: T('ai.industryInsights.clientExtremeValueContext', {
			client: clientName,
			avg: industryMetrics.netDebtEbitdaRatio?.toFixed(FRACTION_DIGITS)
		}),
		netDebtEbitdaDescription: T('ai.industryInsights.netDebtEbitdaDescription'),
		negativeEbitdaWarning: T('ai.industryInsights.negativeEbitdaWarning'),
		negativeNetDebtInfo: T('ai.industryInsights.negativeNetDebtInfo')
	};

	const getValueColor = (value: number | undefined, format: 'percentage' | 'multiplier' | 'currency') => {
		if (value === undefined) return undefined;

		if (format === 'multiplier') {
			return value >= 2.5 ? 'danger-4' : 'success-5';
		}

		const isNegative = value < 0;
		const isPositive = value > 0;

		if (isNegative) return 'danger-4';
		if (isPositive) return 'success-5';

		return undefined;
	};

	const isExtremeValue = (value: number | undefined) => {
		return value !== undefined && Math.abs(value) > 100;
	};

	const getNetDebtEbitdaTooltip = () => {
		if (isExtremeValue(metrics.netDebtEbitdaRatio)) {
			return lang.clientExtremeValueContext;
		}
		return undefined;
	};

	const getNetDebtEbitdaIcon = () => {
		if (isExtremeValue(metrics.netDebtEbitdaRatio)) {
			return <Icon data-testid="net-debt-warning" name="warning" color="bright-yellow" />;
		}
		return null;
	};

	const MIN_SAMPLE_SIZE = 5;

	const getIndustryValue = (value: number | undefined, sampleCount: number | undefined) => {
		if (!sampleCount || sampleCount < MIN_SAMPLE_SIZE) {
			return undefined;
		}
		return value;
	};

	const rows = [
		{
			label: lang.growth,
			clientValue: metrics.growth,
			industryValue: getIndustryValue(industryMetrics.growth, industryMetrics.growthSampleCount),
			format: 'percentage' as const
		},
		{
			label: lang.operatingMargin,
			clientValue: metrics.operatingMargin,
			industryValue: getIndustryValue(
				industryMetrics.operatingMargin,
				industryMetrics.operatingMarginSampleCount
			),
			format: 'percentage' as const
		},
		{
			label: `${lang.netDebt} /  ${lang.ebitda}`,
			clientValue:
				metrics.netDebtEbitdaRatio !== undefined && metrics.netDebtEbitdaRatio <= 0
					? undefined
					: metrics.netDebtEbitdaRatio,
			industryValue: getIndustryValue(
				industryMetrics.netDebtEbitdaRatio,
				industryMetrics.netDebtEbitdaSampleCount
			),
			format: 'multiplier' as const,
			tooltip: getNetDebtEbitdaTooltip(),
			icon: getNetDebtEbitdaIcon()
		},
		{
			label: lang.employmentRate,
			clientValue: metrics.employmentRate,
			industryValue: getIndustryValue(industryMetrics.employmentRate, industryMetrics.employmentRateSampleCount),
			format: 'percentage' as const
		},
		{
			label: lang.netSalesPerEmployee,
			clientValue: metrics.netSalesPerEmployee,
			industryValue: getIndustryValue(
				industryMetrics.netSalesPerEmployee,
				industryMetrics.netSalesPerEmployeeSampleCount
			),
			format: 'currency' as const
		}
	];

	// Adds cool loading state to look like a table
	if (loading) {
		return (
			<Table className={classes.b()}>
				{[...Array(5)].map((_, i) => (
					<TableRow key={`loading-${i}`}>
						{[...Array(3)].map((_, j) => (
							<TableColumn key={`loading-${i}-${j}`}>
								<Text loading />
							</TableColumn>
						))}
					</TableRow>
				))}
			</Table>
		);
	}

	return (
		<Flex direction="column" space="prl ptl pll pbl" gap="u2" className={classes.b()}>
			<Text bold>{lang.keyFiguresTableTitle}</Text>
			<Table className={classes.elem('table').b()}>
				<TableHeader className={classes.elem('header').b()}>
					<TableHeaderColumn>
						<Text size="sm">&nbsp;</Text>
					</TableHeaderColumn>
					<TableHeaderColumn>
						<Text size="sm">{clientName}</Text>
					</TableHeaderColumn>
					<TableHeaderColumn>
						<Flex gap="u2" alignItems="center">
							<Text size="sm">
								{lang.similarCompanies} {lang.inIndustry.toLowerCase()}
							</Text>
							<Tooltip title={lang.similarCompaniesTooltip}>
								<Icon name="info-circle" loading={loading} />
							</Tooltip>
						</Flex>
					</TableHeaderColumn>
				</TableHeader>
				{rows.map(row => (
					<TableRow className={classes.elem('row').b()} key={row.label}>
						<TableColumn>
							{row.label === `${lang.netDebt} /  ${lang.ebitda}` ? (
								<Flex gap="u2" alignItems="center">
									<Text>{row.label}</Text>
									<Tooltip title={lang.netDebtEbitdaDescription}>
										<Icon name="info-circle" />
									</Tooltip>
								</Flex>
							) : (
								<Text>{row.label}</Text>
							)}
						</TableColumn>
						<TableColumn>
							<Flex gap="u2" alignItems="center">
								<Text color={getValueColor(row.clientValue, row.format)}>
									{formatValue(row.clientValue, row.format)}
								</Text>
								{row.tooltip && row.icon && <Tooltip title={row.tooltip}>{row.icon}</Tooltip>}
							</Flex>
						</TableColumn>
						<TableColumn>
							<Flex gap="u2" alignItems="center">
								<Text>{formatValue(row.industryValue, row.format)}</Text>
								{row.label === `${lang.netDebt} /  ${lang.ebitda}` && outliers?.count > 0 ? (
									<Tooltip title={lang.extremeValuesFound}>
										<Icon data-testid="net-debt-info" name="info-circle" />
									</Tooltip>
								) : null}
							</Flex>
						</TableColumn>
					</TableRow>
				))}
			</Table>
		</Flex>
	);
};

export default KeyFiguresTable;
