import React, { useEffect, useRef } from 'react';
import T from 'Components/Helpers/translate';
import CreateCallClientContactSelect, {
	ClientValue,
	ContactValue
} from 'Components/CreateCall/CreateCallClientContactSelect';
import { Label } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import ClientContactSelect from 'Components/ClientContactSelect/ClientContactSelect';

interface Props {
	onChange: (val: { client: ClientValue; contact: ContactValue }) => void;
	client?: ClientValue | null;
	contact?: ContactValue | null;
	saving?: boolean;
	autofocus?: boolean;
	disabled?: boolean;
	clientInputRef?: (r: HTMLInputElement) => void;
	contactInputRef?: (r: HTMLInputElement) => void;
}

const ClientContactFollowupSelect = ({
	onChange,
	client,
	contact,
	saving,
	clientInputRef,
	contactInputRef,
	autofocus,
	disabled
}: Props) => {
	const localClientInputRef = useRef<HTMLInputElement>();
	const localContactInputref = useRef<HTMLInputElement>();
	const hasUnifiedClientContactSelect = Tools.FeatureHelper.hasSoftDeployAccess('UNIFIED_CLIENT_CONTACT_SELECTOR');

	useEffect(() => {
		if (autofocus) {
			setTimeout(() => {
				localClientInputRef.current?.focus();
			}, 300);
		}
	}, []);
	useEffect(() => {
		if (autofocus && client && !contact) {
			setTimeout(() => {
				localContactInputref.current?.focus();
			}, 300);
		}
	}, [client, contact]);
	const classes = new BemClass('ClientContactFollowupSelect');
	return (
		<div className={classes.b()}>
			{!hasUnifiedClientContactSelect ? (
				<CreateCallClientContactSelect
					inputRef={r => {
						localClientInputRef.current = r;
						clientInputRef?.(r);
					}}
					space="mbxl"
					onChange={onChange}
					contact={contact}
					client={client}
					disabled={saving || disabled}
				/>
			) : (
				<ClientContactSelect
					inputRef={r => {
						localClientInputRef.current = r;
						clientInputRef?.(r);
					}}
					onlyActive
					space="mbxl"
					onChange={onChange}
					contact={contact}
					client={client}
					disabled={saving || disabled}
				/>
			)}

			{client && !contact ? (
				<>
					<Label>{`${T('todo.whoToContactAt')} ${client.name}?`}</Label>
					{!hasUnifiedClientContactSelect ? (
						<CreateCallClientContactSelect
							inputRef={r => {
								localContactInputref.current = r;
								contactInputRef?.(r);
							}}
							space="mbxl"
							onChange={onChange}
							contact={contact}
							client={client}
							clientId={client.id}
							disabled={saving || disabled}
							onlyContacts={true}
						/>
					) : (
						<ClientContactSelect
							inputRef={r => {
								localContactInputref.current = r;
								contactInputRef?.(r);
							}}
							space="mbxl"
							onChange={onChange}
							contact={contact}
							client={client}
							clientId={client.id}
							disabled={saving || disabled}
							mode="contacts"
						/>
					)}
				</>
			) : null}
		</div>
	);
};

export default ClientContactFollowupSelect;
