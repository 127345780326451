import {
	Table,
	TableRow,
	TableColumn,
	Text,
	TableHeader,
	TableHeaderColumn,
	Flex,
	Icon,
	EllipsisTooltip,
	Tooltip
} from '@upsales/components';
import DistributionBar from 'App/pages/Reportcenter/ReportcenterWidget/Components/DistributionBar';
import { formatTurnoverGroup, formatPercentage, formatCurrency } from '../Helpers/Helpers';
import BemClass from '@upsales/components/Utils/bemClass';
import { useIndustryInsights } from '../Context/Context';
import { COMPANY_COLORS } from '../Helpers/Helpers';
import React, { useState, useMemo } from 'react';
import T from 'Components/Helpers/translate';
import './MarketShareTable.scss';

interface ClientMarketData {
	id: number;
	name: string;
	revenue: number;
	marketShare: number;
	marketShareChange: number;
	originalIndex?: number;
}

interface Props {
	clientData: ClientMarketData;
	competitors: ClientMarketData[];
	loading?: boolean;
	close: () => void;
}

const MarketShareTable = ({ clientData, competitors, loading, close }: Props) => {
	const { turnoverGroup, description } = useIndustryInsights();
	const hasNewClientCard = Tools.FeatureHelper.hasSoftDeployAccess('REACT_CLIENT_CARD');
	const classes = new BemClass('MarketShareTable');
	const lang = {
		marketShare: T('default.marketShare'),
		marketShareChange: T('default.marketShareChange'),
		revenue: T('account.revenue'),
		similarCompaniesTooltip: T('ai.industryInsights.similarCompaniesTooltip', {
			value: turnoverGroup ? `${formatTurnoverGroup(turnoverGroup)}` : '',
			industry: description
		})
	};

	const goToAccount = (id: number, hasNewClientCard: boolean) => {
		const route = hasNewClientCard ? 'react-root-clientCard' : 'account.dashboard';

		Tools.$state.go(
			route,
			{
				customerId: Tools.AppService.getCustomerId(),
				id,
				page: 'overview'
			},
			{
				inherit: false
			}
		);
	};

	const formatPerc = (value: number | undefined) => {
		if (value === undefined) return '-';
		return formatPercentage(value);
	};

	const formatRev = (value: number | undefined) => {
		return formatCurrency(value);
	};

	const allClients = useMemo(() => {
		return [
			{ ...clientData, originalIndex: 0 },
			...competitors.map((comp, idx) => ({
				...comp,
				originalIndex: idx + 1
			}))
		];
	}, [clientData, competitors]);

	const getClientColor = (client: ClientMarketData) => {
		if (client.id === clientData.id) return COMPANY_COLORS.CLIENT;
		return COMPANY_COLORS.COMPETITORS[(client.originalIndex! - 1) % COMPANY_COLORS.COMPETITORS.length];
	};

	const distributionData = allClients.map(client => ({
		id: `${client.id}-${client.originalIndex}`,
		label: `${client.name}${client.revenue ? ` (${formatRev(client.revenue)})` : ''}`,
		value: client.marketShare || 0,
		color: getClientColor(client),
		disableTooltip: client.marketShare === undefined
	}));

	const getChangeColor = (value: number | undefined) => {
		if (value === undefined) return undefined;
		return value < 0 ? 'danger-4' : value > 0 ? 'success-5' : undefined;
	};

	const [sorting, setSorting] = useState<{ field: string; asc: boolean }>({
		field: 'revenue',
		asc: false
	});

	const handleSort = (sort: { field: string; asc: boolean }) => {
		setSorting(sort);
	};

	const getSortedClients = () => {
		const sorted = [...allClients].sort((a, b) => {
			const aValue = a[sorting.field as keyof ClientMarketData];
			const bValue = b[sorting.field as keyof ClientMarketData];

			if (aValue < bValue) return sorting.asc ? -1 : 1;
			if (aValue > bValue) return sorting.asc ? 1 : -1;
			return 0;
		});
		return sorted;
	};

	const sortedClients = getSortedClients();
	const columns = [
		{ title: '' },
		{ title: lang.revenue, sort: 'revenue', sortType: 'numeric' },
		{ title: lang.marketShare + ' (%)', sort: 'marketShare', sortType: 'numeric' },
		{ title: lang.marketShareChange, sort: 'marketShareChange', sortType: 'numeric' }
	];

	// Adds cool loading state to look like a table
	if (loading) {
		return (
			<Flex direction="column" className={classes.b()} space="pll pbl prl ptl" gap="u2">
				<Text loading size="sm" />
				<Table className={classes.elem('table').b()}>
					{[...Array(8)].map((_, i) => (
						<TableRow key={`loading-${i}`}>
							{[...Array(4)].map((_, j) => (
								<TableColumn key={`loading-${i}-${j}`}>
									<Text loading size="sm" />
								</TableColumn>
							))}
						</TableRow>
					))}
				</Table>
			</Flex>
		);
	}

	return (
		<Flex direction="column" className={classes.b()} space="pll pbl prl ptl" gap="u2">
			<Flex gap="u2" alignItems="center">
				<Text bold>
					{T(`ai.industryInsights.clientSizeComparison`, {
						client: clientData.name
					})}
				</Text>
				<Tooltip title={lang.similarCompaniesTooltip}>
					<Icon name="info-circle" loading={loading} />
				</Tooltip>
			</Flex>
			<DistributionBar distribution={distributionData} datatype="percent" thick={true} drilldown={false} />
			<Table className={classes.elem('table').b()}>
				<TableHeader className={classes.elem('header').b()}>
					{columns.map((column, index) => (
						<TableHeaderColumn
							key={index}
							column={column}
							sorting={sorting.field}
							asc={sorting.asc}
							onSortChange={handleSort}
							className={classes.elem('header-column').b()}
						>
							<Text size="sm">{column.title}</Text>
						</TableHeaderColumn>
					))}
				</TableHeader>
				{sortedClients.map(client => (
					<TableRow className={classes.elem('row').b()} key={client.originalIndex}>
						<TableColumn>
							<Flex alignItems="center" gap="u2" className={classes.elem('client-col').b()}>
								<Icon name="circle" color={getClientColor(client)} />
								<EllipsisTooltip title={client.name}>
									<Text
										bold={client.id === clientData.id}
										color={client.id ? 'bright-blue' : undefined}
										onClick={e => {
											e.stopPropagation();
											close();
											goToAccount(client.id, hasNewClientCard);
										}}
										className={client.id ? 'clickable' : undefined}
									>
										{client.name}
									</Text>
								</EllipsisTooltip>
							</Flex>
						</TableColumn>
						<TableColumn>
							<Text>{formatRev(client.revenue)}</Text>
						</TableColumn>
						<TableColumn>
							<Text>{formatPerc(client.marketShare)}</Text>
						</TableColumn>
						<TableColumn>
							<Text color={getChangeColor(client.marketShareChange)}>
								{formatPerc(client.marketShareChange)}
							</Text>
						</TableColumn>
					</TableRow>
				))}
			</Table>
		</Flex>
	);
};

export default MarketShareTable;
