import Prospecting from 'App/babel/resources/Prospecting';
import getAngularModule from 'App/babel/angularHelpers/getAngularModule';
import logError from 'App/babel/helpers/logError';
import UpsalesAI from 'App/babel/resources/UpsalesAI';
import { StatusCategory } from 'App/services/prospectingService';

function generateFilter(field, country, overrides = {}) {
	function generate() {
		return {
			inactive: true,
			industries: 'allIndustries',
			value: []
		};
	}

	function isInactive(filter) {
		return filter.value.length ? false : true;
	}

	function toUrl(filter) {
		return { v: filter.value, i: filter.inactive };
	}

	function fromUrl(rawFilter) {
		const filter = generate();
		filter.inactive = rawFilter.hasOwnProperty('i') ? rawFilter.i : isInactive(filter);
		if (rawFilter.v) {
			filter.value = rawFilter.v;
		}
		return filter;
	}

	function build(filter, rb) {
		const value = filter.value;

		if (filter.inactive || !value || !value.length) {
			return;
		}

		rb.addFilter({ field }, rb.comparisonTypes.Equals, value);
	}

	async function dataPromise() {
		const ORG_NUMBER_MAP = getAngularModule('ORG_NUMBER_MAP');
		const $translate = getAngularModule('$translate');
		const AppService = getAngularModule('AppService');
		const RequestBuilder = getAngularModule('RequestBuilder');

		const data = ORG_NUMBER_MAP.map(topLevel => ({
			id: topLevel.id,
			name: $translate(topLevel.name),
			children: [],
			count: 0,
			orderValue: 0
		}));

		const rb = new RequestBuilder();

		rb.addFilter({ field }, rb.comparisonTypes.NotEquals, null);
		rb.addFilter({ field }, rb.comparisonTypes.NotEquals, '0');
		rb.addFilter({ field }, rb.comparisonTypes.NotEquals, '9');
		rb.addFilter({ field }, rb.comparisonTypes.NotEquals, '99999');
		rb.addFilter({ field }, rb.comparisonTypes.NotEquals, '00.000');
		rb.addFilter({ field: 'statusCategory' }, rb.comparisonTypes.Equals, StatusCategory.Active);
		rb.extraParams.push({ key: 'country', value: country });
		rb.limit = 1000;

		const [codes, translations, report] = await Promise.all([
			Prospecting.lookup(field, rb.build()).then(result => result.data),
			AppService.getStaticValuesPromise(field),
			UpsalesAI.clientFieldBy(field).then(result => result.data) // Not really needed to pass country here
		]);

		const translationMap = translations.reduce((res, translation) => {
			res[translation.id] = translation.name;
			return res;
		}, {});

		const codesWithSales = report.reduce((res, { [field]: code, totalOrderValue }) => {
			if (!code || code.length < 4) {
				return res;
			}
			res[code] = res[code] ? res[code] + totalOrderValue : totalOrderValue;
			return res;
		}, {});

		for (const { [field]: code, count } of codes) {
			const key = parseInt(code).toString();
			const groupKey = key.length === 4 ? parseInt(key.substr(0, 1)) : parseInt(key.substr(0, 2));
			const index = ORG_NUMBER_MAP.findIndex(group => group.isInGroup(groupKey));

			if (index !== -1) {
				const orderValue = codesWithSales[code] || 0;
				const parent = data[index];
				const translationKey = code.toString().padStart(5, '0');

				if (translationMap[translationKey]) {
					const codeAndName = translationMap[translationKey];
					const child = {
						name: codeAndName.slice(8),
						codeAndName,
						count,
						id: code,
						orderValue
					};
					parent.children.push(child);
					parent.count = parent.count + child.count;
					parent.orderValue = parent.orderValue + child.orderValue;
				} else {
					console.log(
						`[Error] Could not find translation for ${field}: ${code}, translationKey: ${translationKey}`
					);
				}
			} else {
				console.log(`[Error] Could not find group for ${field}: ${code}, groupKey: ${groupKey}`);
				logError(new Error(`[Error] Could not find group for ${field}: ${code}, groupKey: ${groupKey}`));
			}
		}

		return { data, metadata: { total: data.length } };
	}

	const filter = {
		type: 'raw',
		displayType: 'prospectingIndustry',
		title: 'default.companyBranch',
		entity: 'account',
		showOnSegment: true,
		dataPromise,
		generate,
		isInactive,
		toUrl,
		fromUrl,
		build
	};

	return Object.assign(filter, overrides);
}

export default generateFilter;
