import T from 'Components/Helpers/translate';
import React from 'react';
import moment from 'moment';
import { Link } from '@upsales/components';
import type { CreateCallProps } from 'Components/CreateCall';

import { openDrawer } from 'Services/Drawer';
import { phoneNumberHelper } from '@upsales/common';

type TelephoneLinkProps = {
	number?: string | null;
	contact?: CreateCallProps['contact'] | null;
	client?: CreateCallProps['client'] | null;
	className?: string;
};

const TelephoneLink: React.FC<TelephoneLinkProps> = ({ client, contact, number, className }) => {
	if (!number) return null;
	const phoneProps = ReactTemplates.TOOLS.upVoice((contact || client)!, contact ? 'contact' : 'client', number!);
	return (
		<Link
			className={className}
			href={phoneProps.href}
			target={phoneProps.target}
			onClick={e => {
				e.stopPropagation();
				phoneProps.onClick(e);
				if (
					Tools.FeatureHelper.hasSoftDeployAccess('TODO_LIST') &&
					Tools.AppService.getSelf().userParams.phoneClickDrawer
				) {
					openDrawer('CreateCall', {
						client: client,
						contact,
						date: new Date(),
						time: moment().format('LT'),
						initialDescription: T('default.phonecall')
					});
				}
			}}
		>
			{phoneNumberHelper.formatNumber((number || '').toString())}
		</Link>
	);
};

export default TelephoneLink;
