import { Flex, Link, Select, Text } from '@upsales/components';
import Block from '@upsales/components/Block/Block';
import ButtonSelect from '@upsales/components/ButtonSelect/ButtonSelect';
import { PrimaryButton, ThirdButton } from '@upsales/components/Buttons';
import Column from '@upsales/components/Column/Column';
import Input from '@upsales/components/Input/Input';
import Modal from '@upsales/components/Modal/Modal';
import ModalContent from '@upsales/components/ModalContent/ModalContent';
import ModalControls from '@upsales/components/ModalControls/ModalControls';
import ModalHeader from '@upsales/components/ModalHeader/ModalHeader';
import BemClass from '@upsales/components/Utils/bemClass';
import {
	StaticCategoryMultiSelect,
	useAppCategories
} from 'App/components/Inputs/Selects/StaticCategorySelect/StaticCategorySelect';
import { StaticJourneyStepSelect } from 'App/components/Inputs/Selects/StaticJourneyStepSelect/StaticJourneyStepSelect';
import { StaticPriceListSelect } from 'App/components/Inputs/Selects/StaticPriceListSelect/StaticPriceListSelect';
import { CoreSelectItem } from 'App/components/Inputs/Selects/StaticSelect';
import { StaticUserSelect, useUserOptions } from 'App/components/Inputs/Selects/StaticUserSelect/StaticUserSelect';
import { ModalProps } from 'App/components/Modals/Modals';
import { ResourceIdSelect } from 'App/components/ResourceSelect';
import { useFeatureAvailable, useSelector, useSoftDeployAccess } from 'App/components/hooks';
import { TagEntity, getDynamicUserProperties } from 'App/helpers/actionHelpers';
import { defaultFilterGetter } from 'App/helpers/multiActionRunnerHelpers';
import Category from 'App/resources/Model/Category';
import { CustomFieldWithValue } from 'App/resources/Model/CustomField';
import JourneyStep from 'App/resources/Model/JourneyStep';
import PriceList from 'App/resources/Model/PriceList';
import ProjectResource from 'App/resources/Project';
import { useTranslation } from 'Components/Helpers/translate';
import NotesWithSignature from 'Components/Inputs/NotesWithSignature/NotesWithSignature';
import StandardFieldInput from 'Components/Inputs/standardFieldInput';
import ModalTagList from 'Components/Modals/ModalTagList';
import { CampaignSelectItem } from 'Components/PlanPhonecallsDrawer/CampaignSelect/CampaignSelect';
import { formatDateType, prepDates } from 'Helpers/multiModals';
import ComparisonTypes from 'Resources/ComparisonTypes';
import RequestBuilder from 'Resources/RequestBuilder';
import { mapCustomFields, mapProperties, parseCustomFields, parseProperties } from 'Services/ActionProperties';
import { Feature } from 'Store/actions/FeatureHelperActions';
import _ from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import LabeledInput from '../../Inputs/LabeledInput';
import { MultiSelect } from '../../ListView/ListViewRenderHelpers';
import { PropertyArray, PropertyMap } from '../MultiActionModal';
import './MultiUpdateClient.scss';

type Props = {
	entity: string;
	tagEntity: TagEntity;
	extraParams?: any;
	filters: RequestBuilder;
	removeAction?: (actionIndex: number) => void;
	actionIndex: number;
	isTrigger?: boolean;
	isAutomation?: boolean;
	properties?: PropertyArray;
} & (
	| {
			onSave: (props: PropertyArray) => Promise<void>;
			multiSelect?: never;
	  }
	| {
			multiSelect: MultiSelect;
			onSave?: never;
	  }
) &
	ModalProps<PropertyArray>;

var arrayProperties = ['AddProject', 'RemoveProject', 'AddCategories', 'RemoveCategories'];

const MultiUpdateClient = ({ onSave, close, className, ...modalParams }: Props) => {
	const { entity, tagEntity, multiSelect, extraParams, isTrigger, isAutomation, actionIndex, removeAction } =
		modalParams;
	const { t } = useTranslation();

	const modalRef = useRef<Element | null>(null);

	useEffect(() => {
		if (!modalRef.current) {
			modalRef.current = document.querySelector('.Modals');
		}
	}, [modalRef.current]);

	const classes = new BemClass('MultiUpdateClient', className);

	const categories = useAppCategories('account');
	const users = useUserOptions();

	const [saving, setSaving] = useState(false);

	const [properties, setProperties] = useState<PropertyMap>({});

	const [addCampaigns, setAddCampaigns] = useState<CampaignSelectItem['id'][]>([]);
	const [removeCampaigns, setRemoveCampaigns] = useState<CampaignSelectItem['id'][]>([]);

	const [addCategories, setAddCategories] = useState<CoreSelectItem<Category>[]>([]);
	const [removeCategories, setRemoveCategories] = useState<CoreSelectItem<Category>[]>([]);

	const [userChangeType, setUserChangeType] = useState<'Add' | 'Set' | 'Remove'>('Add');
	const [selectedUser, setSelectedUser] = useState<CoreSelectItem | null>(null);

	const [journeyStep, setJourneyStep] = useState<CoreSelectItem<JourneyStep> | null>(null);
	const [priceList, setPriceList] = useState<CoreSelectItem<PriceList> | null>(null);
	const [availableFields, setAvailableFields] = useState<CustomFieldWithValue[]>([]);
	const [customFields, setCustomFields] = useState<CustomFieldWithValue[]>([]);
	const [customDateTypes, setCustomDateTypes] = useState<{ [key: string]: any }>();
	const [tagsVisible, setTagsVisible] = useState(false);

	const hasPriceListFeature = useFeatureAvailable(Feature.PRICE_LISTS);
	const hasPriceListAccess = useSoftDeployAccess(Feature.PRICE_LISTS);
	const hasPriceList = hasPriceListFeature && hasPriceListAccess && !isTrigger;
	const hasJourney = useFeatureAvailable(Feature.JOURNEY_STATUS);
	const hasNewFields = useSoftDeployAccess('NEW_FIELDS');
	const showJourneyStep = true;
	const isEdit = !!modalParams.properties;

	const {
		customerId,
		metadata,
		customFields: startingFields,
		journeySteps
	} = useSelector(({ App }) => ({
		customerId: App.customerId,
		metadata: App.metadata,
		customFields: App.customFields.account,
		journeySteps: App.journeySteps
	}));

	const standardFields =
		metadata &&
		_.filter(metadata.standardFields.Client, function (field) {
			return field.group === 'standard' && field.canHide && field.active;
		});

	const userTags = useMemo(() => {
		return getDynamicUserProperties(tagEntity, isAutomation).map(p => ({
			id: p.value,
			title: p.title
		}));
	}, [tagEntity, isAutomation]);

	const save = async () => {
		setSaving(true);

		const propArray: PropertyArray = [];
		mapCustomFields(propArray, customFields, 'Client');
		propArray.push(...mapProperties(properties, arrayProperties));

		if (onSave) {
			return onSave(propArray)
				.then(() => {
					close();
				})
				.finally(() => {
					setSaving(false);
				});
		}

		if (isTrigger || !modalParams.filters || !propArray.length) {
			return close(propArray);
		}

		const selectedFilters = defaultFilterGetter(modalParams.filters, modalParams.multiSelect!);

		propArray.forEach(prop => {
			if (prop.name === 'User' && userChangeType !== 'Set') {
				prop.name = userChangeType + 'User';
			}
		});

		return Tools.MultiActions.customer(Tools.AppService.getCustomerId())
			.updateAccount(
				entity || 'Client',
				multiSelect!.selectedIds.length,
				propArray,
				selectedFilters.build(),
				extraParams
			)
			.finally(() => setSaving(false));
	};

	const createCampaign = async () => {
		return Tools.$upModal
			.open('editCampaign', { customerId: customerId, noRedirect: true })
			.then(function (campaign) {
				if (campaign) {
					const updatedCampaigns = [...addCampaigns, campaign];
					setAddCampaigns(updatedCampaigns);
				}
			});
	};

	const findSelectedUserOrTag = (value: any) => {
		const user = users.find(user => user.id === value);
		if (user) {
			return user;
		}

		const tag = userTags.find(tag => tag.id === value);
		if (tag) {
			return tag;
		}

		return null;
	};

	useEffect(() => {
		const { customDateTypes, dateTags } = prepDates(entity || tagEntity);
		const newFields = structuredClone(startingFields)
			.filter(field => field.editable)
			.map(field => {
				if (customDateTypes && field.datatype === 'Date') {
					(field as any).customDateSelect = true;
				}
				return field;
			});

		setCustomDateTypes(formatDateType(customDateTypes));

		if (modalParams.properties) {
			const parsedProperties = parseProperties(modalParams.properties, arrayProperties);

			for (const [name, value] of Object.entries(parsedProperties)) {
				switch (name) {
					case 'AddProject':
						setAddCampaigns(value as string[]);
						break;

					case 'RemoveProject':
						setRemoveCampaigns(value as string[]);
						break;

					case 'AddCategories':
						setAddCategories(() => {
							const selectedCategories = (value as number[])
								.map(id => categories.find(c => c.id === id))
								.filter(Boolean);

							return selectedCategories.map(category => ({
								...category,
								title: category!.name
							})) as CoreSelectItem<Category>[];
						});
						break;

					case 'RemoveCategories':
						setRemoveCategories(() => {
							const selectedCategories = (value as number[])
								.map(id => categories.find(c => c.id === id))
								.filter(Boolean);

							return selectedCategories.map(category => ({
								...category,
								title: category!.name
							})) as CoreSelectItem<Category>[];
						});
						break;

					case 'JourneyStep':
						setJourneyStep(() => {
							const journeyStep = journeySteps.find(step => step.value === value);

							if (journeyStep) {
								return {
									...journeyStep,
									id: journeyStep.value,
									title: t(journeyStep.name)
								} as CoreSelectItem<JourneyStep>;
							}

							return null;
						});
						break;

					case 'AccountManager':
						if (isTrigger) {
							setUserChangeType('Set');
							setSelectedUser(findSelectedUserOrTag(value));
						}
						break;

					case 'AddUser':
						if (isTrigger) {
							setUserChangeType('Add');
							setSelectedUser(findSelectedUserOrTag(value));
						}
						break;

					case 'RemoveUser':
						if (isTrigger) {
							setUserChangeType('Remove');
							setSelectedUser(findSelectedUserOrTag(value));
						}
						break;

					case 'RegistrationDate':
						if (value) {
							parsedProperties[name] = new Date(value as string);
						}
				}
			}

			const [customFieldsRes, availableFields] = parseCustomFields(
				newFields,
				parsedProperties,
				'Client',
				dateTags
			);

			setCustomFields(customFieldsRes);
			setAvailableFields(availableFields);
			setProperties(parsedProperties);
		} else {
			setAvailableFields(newFields as CustomFieldWithValue[]);
		}
	}, []);

	const handleCustomFieldChange = (value: {
		id: CustomFieldWithValue['id'];
		title: CustomFieldWithValue['name'];
	}) => {
		const selectedField = availableFields.find(field => field.id === value.id);
		if (selectedField) {
			setCustomFields([...customFields, selectedField]);
		}

		setAvailableFields(
			availableFields.filter(field => field.id !== value.id).sort((a, b) => a.name.localeCompare(b.name))
		);
	};
	const removeCustomField = (field: CustomFieldWithValue) => {
		if (customDateTypes && field.datatype === 'Date') {
			(field as any).customDateSelect = true;
		}
		setCustomFields(customFields.filter(f => f.id !== field.id));
		setAvailableFields([...availableFields, field].sort((a, b) => a.name.localeCompare(b.name)));
	};

	const handleCustomFieldValueChange = (field: CustomFieldWithValue, value: any) => {
		setCustomFields(
			customFields.map(f => {
				if (f.id === field.id) {
					let newValue = value;
					if (field.datatype === 'Date' && value?.tag === 'RegularDate') {
						(field as any).customDateSelect = false;
						newValue = '';
					}

					return { ...f, value: newValue };
				}
				return f;
			})
		);
	};

	return (
		<Modal size="lg" className={classes.b()}>
			<form onSubmit={save} className={classes.elem('content').b()}>
				<ModalHeader title={t('default.updateAccounts')} onClose={close} icon="edit">
					{isTrigger ? (
						<button
							type="button"
							id="toggle-tags-btn"
							className="up-btn btn-lined no-shadow btn-bright-blue btn-sm"
							onClick={() => setTagsVisible(!tagsVisible)}
						>
							{t('tag.available')}
						</button>
					) : null}
				</ModalHeader>
				<ModalContent>
					<div className="row">
						<div className="col-md-6">
							<LabeledInput
								title={t('default.add') + ' ' + t('default.campaigns')}
								topRight={
									<Link
										nostyle={true}
										className={classes.elem('create-campaign').b()}
										onClick={createCampaign}
									>
										{t('default.create')} {t('default.new').toLowerCase()}
									</Link>
								}
							>
								<ResourceIdSelect
									resource={ProjectResource}
									placeholder={t('campaign.chooseCampaign')}
									multi
									value={addCampaigns}
									onChange={campaign => {
										setProperties(prev => ({
											...prev,
											AddProject: campaign
										}));
										setAddCampaigns(campaign);
									}}
									onRemove={campaign => {
										setProperties(prev => ({
											...prev,
											AddProject: addCampaigns.filter(c => c !== campaign)
										}));
										setAddCampaigns(addCampaigns.filter(c => c !== campaign));
									}}
									modifyRb={rb => {
										rb.addFilter(ProjectResource.attr.active, ComparisonTypes.Equals, true);
									}}
									anchor={modalRef.current}
								/>
							</LabeledInput>
						</div>
						<div className="col-md-6">
							<LabeledInput title={t('default.remove') + ' ' + t('default.campaigns')}>
								<ResourceIdSelect
									resource={ProjectResource}
									placeholder={t('campaign.chooseCampaign')}
									multi
									value={removeCampaigns}
									onChange={campaigns => {
										setProperties(prev => ({
											...prev,
											RemoveProject: campaigns
										}));
										setRemoveCampaigns(campaigns);
									}}
									onRemove={campaign => {
										setProperties(prev => ({
											...prev,
											RemoveProject: removeCampaigns.filter(c => c !== campaign)
										}));
										setRemoveCampaigns(removeCampaigns.filter(c => c !== campaign));
									}}
									modifyRb={rb => {
										rb.addFilter(ProjectResource.attr.active, ComparisonTypes.Equals, true);
									}}
									anchor={modalRef.current}
								/>
							</LabeledInput>
						</div>
					</div>
					<div className="row">
						<div className="col-md-6">
							<LabeledInput title={`${t('default.add')} ${t('default.categories')}`}>
								<StaticCategoryMultiSelect
									data-testid="selectAddCategories"
									categoryType="account"
									value={addCategories}
									onChange={value => {
										setProperties(prev => ({
											...prev,
											AddCategories: value.length ? value.map(e => e.id) : []
										}));
										setAddCategories(value);
									}}
									anchor={modalRef.current}
								/>
							</LabeledInput>
						</div>
						<div className="col-md-6">
							<LabeledInput title={`${t('default.remove')} ${t('default.categories')}`}>
								<StaticCategoryMultiSelect
									data-testid="selectRemoveCategories"
									categoryType="account"
									value={removeCategories}
									onChange={value => {
										setProperties(prev => ({
											...prev,
											RemoveCategories: value.length ? value.map(e => e.id) : []
										}));
										setRemoveCategories(value);
									}}
									anchor={modalRef.current}
								/>
							</LabeledInput>
						</div>
					</div>
					{metadata?.params.teamAccountManager ? (
						<Block space="mts">
							<ButtonSelect
								data-testid="selectUserChangeType"
								size="sm"
								value={userChangeType}
								options={[
									{ value: 'Add' as const, title: t('default.add') },
									{ value: 'Set' as const, title: t('multi.setAsOnlyAccountManager') },
									{ value: 'Remove' as const, title: t('default.remove') }
								]}
								onChange={setUserChangeType}
							/>
						</Block>
					) : null}
					<div className="row">
						<div className="col-md-6">
							<LabeledInput title={t('default.accountManager')}>
								<StaticUserSelect
									data-testid="selectAccountManager"
									extraOptions={userTags}
									value={selectedUser}
									onChange={value => {
										setSelectedUser(value);
										setProperties({ ...properties, User: value?.id ?? null });
									}}
									anchor={modalRef.current}
								/>
							</LabeledInput>
						</div>
						{isTrigger ? (
							<div className="col-md-6">
								<LabeledInput title={t('default.name')}>
									<Input
										value={properties.Name as string}
										placeholder={t('default.name')}
										onChange={e => {
											setProperties(prev => ({
												...prev,
												Name: e.target.value
											}));
										}}
									/>
								</LabeledInput>
							</div>
						) : null}
						{hasJourney && showJourneyStep ? (
							<div className="col-md-6">
								<LabeledInput title={t('default.journeyStep')}>
									<StaticJourneyStepSelect
										data-testid="selectJourneyStep"
										value={journeyStep}
										onChange={value => {
											setProperties({
												...properties,
												JourneyStep: value?.id ?? null
											});
											setJourneyStep(value);
										}}
										anchor={modalRef.current}
									/>
								</LabeledInput>
							</div>
						) : null}
						{hasPriceList ? (
							<div className="col-md-6">
								<LabeledInput title={t('default.priceList')}>
									<StaticPriceListSelect
										value={priceList}
										onChange={value => {
											setProperties({
												...properties,
												PriceListId: value?.id ?? null
											});
											setPriceList(value);
										}}
										anchor={modalRef.current}
									/>
								</LabeledInput>
							</div>
						) : null}
					</div>
					{isTrigger ? (
						<NotesWithSignature
							value={properties.Notes ?? ''}
							onChange={value => {
								setProperties({ ...properties, Notes: value });
							}}
						/>
					) : null}
					{isTrigger && hasNewFields && standardFields?.length ? (
						<>
							<legend>
								<span>{t('default.clientField')}</span>
							</legend>
							<div className="row">
								{standardFields.map(field => (
									<div key={field.id} className="col-md-6">
										<LabeledInput title={t(field.nameTag)}>
											<StandardFieldInput
												id={field.field}
												name={field.field}
												config={field}
												required={field.required && !isTrigger}
												allowTags
												value={properties[field.name]}
												onChange={(value: any) => {
													setProperties(prev => ({ ...prev, [field.name]: value }));
												}}
											/>
										</LabeledInput>
									</div>
								))}
							</div>
						</>
					) : null}
					{customFields.length || availableFields.length ? (
						<legend>
							<span>{t('default.otherInfo')}</span>
						</legend>
					) : null}

					{customFields.length ? (
						<>
							<div className="row">
								{customFields.map((field, index) => (
									<div className="col-md-6" key={field.id}>
										<div className="form-group">
											<Flex justifyContent="space-between">
												<Text className={classes.elem('custom-title').b()} bold>
													{field.name}
													<Text
														size="lg"
														color="red"
														bold={true}
														style={{ display: field.obligatoryField ? 'inline' : 'none' }}
													>
														&bull;
													</Text>
												</Text>
												<ThirdButton
													className={classes.elem('remove-custom').b()}
													icon={'trash'}
													onClick={e => removeCustomField(field)}
													size="sm"
													color="blue"
												/>
											</Flex>
											{customDateTypes &&
											field.datatype === 'Date' &&
											(field as any).customDateSelect ? (
												<ReactTemplates.INPUTS.upSelect
													className={classes.elem('custom-field-input', 'form-control').b()}
													getId={customDateTypes.id}
													options={{ data: customDateTypes.data }}
													defaultValue={field.value}
													multiple={false}
													required={false}
													formatResult={customDateTypes.formatResult}
													formatSelection={customDateTypes.formatSelection}
													matcher={customDateTypes.matcher}
													onChange={value =>
														handleCustomFieldValueChange(field, value.target.added)
													}
												/>
											) : (
												<ReactTemplates.INPUTS.customFieldInput
													field={field}
													name={field.name}
													className={classes.elem('custom-field-input').b()}
													multiple={field.datatype === 'Users'}
													onChange={(value: string) =>
														handleCustomFieldValueChange(field, value)
													}
													usenewdate
													useNewTime
													useNumberInput
													useNewCheckbox
													anchor={modalRef.current}
												/>
											)}
										</div>
									</div>
								))}
							</div>
						</>
					) : null}

					{availableFields.length ? (
						<div className="row">
							<div className="col-md-6">
								<Select
									data-testid="Input_field"
									anchor={modalRef.current}
									options={availableFields.map(field => ({
										id: field.id,
										title: field.name
									}))}
									value={null}
									onChange={handleCustomFieldChange}
									disabled={!availableFields.length}
									className={classes.elem('custom-field-select').b()}
									placeholder={t('default.add') + ' ' + t('default.field').toLowerCase()}
								/>
							</div>
						</div>
					) : null}
				</ModalContent>
				<ModalControls>
					{isEdit && removeAction ? (
						<button
							type="button"
							className="up-btn btn-lined btn-grey btn-hover-red no-shadow pull-left ng-scope"
							onClick={() => {
								removeAction(actionIndex);
								close();
							}}
						>
							<b className="fa fa-trash-o"></b>
						</button>
					) : null}
					<Column align="right">
						<PrimaryButton
							disabled={saving}
							loading={saving}
							onClick={() => {
								save();
								close();
							}}
						>
							{t(saving ? 'default.saving' : 'default.save')} {t('admin.action').toLocaleLowerCase()}{' '}
						</PrimaryButton>
						<ThirdButton onClick={() => close()}>{t('default.cancel')}</ThirdButton>
					</Column>
				</ModalControls>
			</form>
			{isTrigger ? (
				<div className={classes.elem('tags').mod({ translateTagsList: tagsVisible }).b()}>
					<ModalTagList modalParams={modalParams} onClose={() => setTagsVisible(false)} />
				</div>
			) : null}
		</Modal>
	);
};

export default MultiUpdateClient;
