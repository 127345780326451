import { useTranslation } from 'Components/Helpers/translate';
import React, { useEffect, useState } from 'react';
import type { ModalProps } from 'App/components/Modals/Modals';
import { Avatar, Block, Flex, Link, Modal, ModalContent, Text } from '@upsales/components';
import { PhoneCall } from 'App/resources/PhoneCall';
import UpAudio from 'Components/Inputs/UpAudio';
import { makeCancelable } from 'Helpers/promise';
import Client from 'App/resources/Model/Client';
import './OpenCallModal.scss';
import moment from 'moment';
import BemClass from '@upsales/components/Utils/bemClass';
import Contact from 'App/resources/Model/Contact';
import logError from 'Helpers/logError';
import { openEditAppointment } from 'Components/Modals/Appointment/EditAppointment';

const openRelatedLink = function (item?: PhoneCall['related'][number]) {
	if (!item) {
		return;
	}
	switch (item.type) {
		case 'Activity':
			return Tools.$upModal.open('editActivity', { id: item.id });
		case 'Order':
			return Tools.$upModal.open('editOrder', { id: item.id });
		case 'Appointment':
			return openEditAppointment({ id: item.id });
		case 'Opportunity':
			return Tools.$upModal.open('editOrder', { id: item.id });
	}
};

type Props = ModalProps &
	Partial<PhoneCall> & { contact?: Pick<Contact, 'id' | 'name'>; client: Pick<Client, 'id' | 'name'> };

const OpenCallModal = ({ className, close, ...props }: Props) => {
	const classes = new BemClass('OpenCallModal', className);
	const customerId = Tools.AppService.getCustomerId();
	const { t } = useTranslation();

	const callDate = moment(props.date).format('DD MMMM YYYY - HH:mm');
	const user = props.user ? props.user : null;
	const client = props.client ? props.client : null;
	const contact = props.contact ? props.contact : null;
	const phoneNumber = props.phoneNumber ? props.phoneNumber : '';
	const durationInS = props.durationInS ? props.durationInS : 0;
	const related = props.related ? props.related : null;
	const integration: any = Tools.AppService.getPhoneIntegration();
	const src =
		(integration && integration.capabilities && integration.capabilities.noRecordings) || !props.conversationUrl
			? null
			: Tools.URL +
			  Tools.API +
			  'function/voice/recording/?id=' +
			  props.conversationUrl +
			  '&type=recording&integrationId=' +
			  integration.id;

	const callObj = { ...(contact || client)!, phone: phoneNumber, client: contact ? client : undefined };
	const callType = contact ? 'contact' : 'client';
	const [activities, setActivities] = useState<
		Partial<
			Pick<Client, 'hasActivity' | 'hadActivity' | 'hasOpportunity' | 'hadOpportunity' | 'hasOrder' | 'hadOrder'>
		>
	>({});

	useEffect(() => {
		let _cancel: any;
		if (client?.id) {
			const { promise, cancel } = makeCancelable(Tools.Account.customer(customerId).get(client.id));
			_cancel = cancel;
			promise
				.then(res => {
					const activities = {
						hasActivity: res?.data?.hasActivity,
						hadActivity: res?.data?.hadActivity,
						hasOpportunity: res?.data?.hasOpportunity,
						hadOpportunity: res?.data?.hadOpportunity,
						hasOrder: res?.data?.hasOrder,
						hadOrder: res?.data?.hadOrder
					};
					setActivities(activities);
				})
				.catch(e => {
					logError(e, 'Error getting activities');
				});
		}
		return () => _cancel?.();
	}, []);

	const phoneProps = ReactTemplates.TOOLS.upVoice(
		callObj,
		callType === 'contact' ? 'contact' : 'client',
		phoneNumber
	);

	return (
		<Modal className={classes.b()} size="sm">
			<ModalContent>
				<Flex>
					<Text size="xl">{t('voice.phoneCalls')}</Text>
					<a
						data-testid="openCallCloseButton"
						className={classes.elem('close').add('btn btn-link modal-close').b()}
						onClick={() => close()}
					>
						<i className="fa fa-times"></i>
					</a>
				</Flex>
				<Block space="mbl">{callDate}</Block>
				{durationInS && src ? (
					<Block space="mbm">
						<UpAudio src={src} />
					</Block>
				) : null}
				{user ? (
					<Block space="mbl mts">
						<Flex alignItems="center" gap={4}>
							<Avatar initials={user.name} />
							<Text size="lg">
								{user.name}
								{' ' + t('voice.calledTo').toLowerCase() + ':'}
							</Text>
						</Flex>
					</Block>
				) : null}
				<Flex>
					{phoneNumber ? (
						<Flex flex={1} direction="column">
							<a
								onClick={() => close()}
								href={`#/${customerId}/${callType === 'client' ? 'accounts' : 'contacts'}/${
									callObj.id
								}/`}
							>
								{callObj.name}
							</a>
							<a href={phoneProps.href} target={phoneProps.target} onClick={phoneProps.onClick}>
								{phoneNumber}
							</a>
						</Flex>
					) : null}
					{client ? (
						<Flex flex={1} direction="column" gap={4}>
							<a onClick={() => close()} href={`#/${customerId}/accounts/${client.id}/`}>
								{client.name}
							</a>
							<ReactTemplates.TOOLS.leadStatus
								activity={activities.hasActivity}
								activityOld={activities.hadActivity}
								opportunity={activities.hasOpportunity}
								opportunityOld={activities.hadOpportunity}
								order={activities.hasOrder}
								orderOld={activities.hadOrder}
							/>
						</Flex>
					) : null}
				</Flex>
				{related?.length ? (
					<Flex direction="column" className={classes.elem('related').b()}>
						<Block>
							<Flex gap={4} alignItems="baseline">
								<label>{t('voice.relatedObject')}</label>
							</Flex>
						</Block>
						<table>
							{related.map(item => (
								<tr key={item.id} className={classes.elem('linkRow').b()}>
									<td onClick={() => openRelatedLink(item)} className={classes.elem('linkIcon').b()}>
										<i
											className={
												item.type === 'Order'
													? 'fa fa-dollar'
													: item.type === 'Opportunity'
													? 'up-icon-pipeline'
													: item.type === 'Appointment'
													? 'fa fa-calendar'
													: item.type === 'Activity'
													? 'up-icon-activity'
													: ''
											}
										></i>
									</td>
									<td>
										<Link onClick={() => openRelatedLink(item)}>{item.name}</Link>
									</td>
								</tr>
							))}
						</table>
					</Flex>
				) : null}
			</ModalContent>
		</Modal>
	);
};

export default OpenCallModal;
