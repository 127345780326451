import { setConfig, cold } from 'react-hot-loader';
import { initLanguage } from 'Components/Helpers/translate';
import config from '../../config';
import { AllIWantData } from 'App/resources/AllIWant';
import Analytics from 'App/services/Analytics';
import { SurveyStages } from 'App/enum/UserSurvey';
import InAppChat from 'Services/InAppChat';
import Axios from 'axios';
import logError from 'Helpers/logError';

const axios = Axios.create();

/**
 * Sets up the elevio script
 * @param url
 * @param id
 * @returns {void}
 * @private
 */
const setupElevioScript = (url: string, id: string) => {
	if (!window._elev) {
		window._elev = { account_id: id };
	}
	const g = document.createElement('script');
	g.type = 'text/javascript';
	g.async = true;
	g.src = url + id;
	const h = document.getElementsByTagName('script')[0];
	h?.parentNode?.insertBefore(g, h);
};

/**
 * Sets the page title and favicon based on current environment
 * @returns {void}
 * @private
 */
const setPageTitleAndFavicon = () => {
	if (config.ENV === 'DEV' || config.ENV === 'ALPHA' || config.ENV === 'BETA') {
		document.title = '[' + config.ENV + '] ' + document.title;
		var link = document.createElement('link');
		link.id = 'dynamic-favicon';
		link.rel = 'shortcut icon';
		link.href = 'favicon-' + config.ENV.toLowerCase() + '.ico';
		document.head.appendChild(link);
	}
};

export const setupElevioUser = (r: string, customerId: number) => {
	window._elev.setUser?.({ groups: customerId + '' });
	if (window._elev?.setUser) {
		window._elev.setLanguage?.(r);
	}
};
export const closeElevioWidgets = () => {
	if (window?._elev?.widgetIsOpen?.()) {
		window?._elev?.close?.();
	}
	window?._elev?.closeAllPopups?.();
};

export const setupAnalytics = (allIWant: AllIWantData) => {
	if (allIWant.metadata.params.DisableSegmentIO) {
		Analytics.disable();
	} else {
		let analyticsData = {
			name: 'Anonym ' + allIWant.self.id,
			email: 'anonym@' + allIWant.self.client.id,
			language: allIWant.self.language,
			isAdmin: !!allIWant.customerSelf.administrator,
			isBillingAdmin: !!allIWant.customerSelf.billingAdmin,
			customerId: allIWant.self.client.id,
			clientName: allIWant.self.client.name,
			clientVersion: allIWant.self.version,
			numberOfLicenses: allIWant.self.client.numberOfLicenses,
			environment: config.ENV,
			isMarketingAdmin: allIWant.customerSelf.userParams.mailAdmin,
			teamLeader: allIWant.customerSelf.teamLeader,
			bulkBuy: allIWant.customerSelf.userParams.multiSoliditetAccess,
			export: allIWant.customerSelf.export,
			isTrial: allIWant.self.client.isTrial,
			regDate: allIWant.customerSelf.regDate,
			calendarApp: allIWant.metadata.integrations.inits?.calendar?.find(a => a.public)?.name ?? null,
			mailApp: allIWant.metadata.integrations.inits?.mail?.find(a => a.public)?.name ?? null,
			erpApp: allIWant.metadata.integrations.inits?.erp?.find(a => a.public)?.name ?? null
		};

		const [userSurveyResult] = allIWant.metadata.userSurveyResult ?? [null];
		let roleTitle: string = '<none>';
		if (userSurveyResult?.stageId === SurveyStages.FINISH) {
			const surveyData = { ...userSurveyResult } as any;
			roleTitle = userSurveyResult.roleTitle;
			delete surveyData.stageId;
			analyticsData = { ...analyticsData, ...surveyData };
		}

		Analytics.enable();
		Analytics.identify(allIWant.self.id, analyticsData);
		Analytics.ready(() => {
			Analytics.peopleSetOnce('First login date', new Date());
		});

		// Make list of strings from object keys where value is true
		const unreleasedFeaturesList = Object.keys(allIWant.self.unreleasedFeatures).filter(
			key => allIWant.self.unreleasedFeatures[key]
		);
		const featuresList = Object.keys(allIWant.self.features).filter(key => allIWant.self.features[key]);
		const boughtAddonsList = Object.keys(allIWant.self.boughtAddons).filter(key => allIWant.self.boughtAddons[key]);

		window.pendo.initialize({
			visitor: {
				id: allIWant.self.id,
				email: `${allIWant.self.id}@${allIWant.self.client.id}.com`,
				upsalesLanguage: analyticsData.language,
				isAdmin: analyticsData.isAdmin,
				isBillingAdmin: analyticsData.isBillingAdmin,
				isMarketingAdmin: analyticsData.isMarketingAdmin,
				teamLeader: analyticsData.teamLeader,
				bulkBuy: analyticsData.bulkBuy,
				export: analyticsData.export,
				regDate: analyticsData.regDate,
				calendarApp: analyticsData.calendarApp ?? '<none>',
				mailApp: analyticsData.mailApp ?? '<none>',
				erpApp: analyticsData.erpApp ?? '<none>',
				roleTitle
			},
			account: {
				id: analyticsData.customerId,
				accountName: analyticsData.clientName,
				version: analyticsData.clientVersion,
				numberOfLicenses: analyticsData.numberOfLicenses,
				isTrial: analyticsData.isTrial,
				unreleasedFeatures: unreleasedFeaturesList,
				features: featuresList,
				boughtAddons: boughtAddonsList
			}
		});
	}
};

let updatingUser = false;
const fixZendeskUser = async () => {
	if (updatingUser) {
		return;
	}
	updatingUser = true;
	return axios
		.delete('/api/external/fixZdUser/', {
			headers: {
				'Content-Type': 'application/json'
			},
			withCredentials: true
		})
		.finally(() => {
			updatingUser = false;
		});
};

let anonymousChat = false;
const setupZendeskChat = async (allIWant: AllIWantData) => {
	if (!window.zE) {
		return;
	}

	window.zE('messenger:set', 'locale', allIWant.self.language);
	if (anonymousChat) {
		return;
	}
	return axios
		.get('/api/external/zendeskAuth/', {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json'
			},
			withCredentials: true
		})
		.then(res => {
			window.zE(
				'messenger',
				'loginUser',
				(authCallback: (token: string) => void) => {
					authCallback(res.data.token);
				},
				async (error: any) => {
					if (error?.reason?.startsWith('409')) {
						await fixZendeskUser().catch(() => {
							anonymousChat = true;
						});
					}
				}
			);
		})
		.catch(e => {
			logError(e, 'Failed to authenticate Zendesk chat');
		});
};

export const setupInAppChat = (allIWant: AllIWantData) => {
	if (allIWant.metadata.params.DisableIntercom || !config.chatEnabled) {
		InAppChat.disable();
	} else {
		InAppChat.enable();
		anonymousChat = false;
		setupZendeskChat(allIWant);

		// Do a secondary auth if the auth is missing userId or is expired when opening chat
		window.zE?.('messenger:on', 'open', () => {
			const hasUserId = (() => {
				// key has format <integrationId>.appUserId
				const key = Object.keys(sessionStorage).find(s => s.includes('.appUserId'));
				if (key) {
					return !!sessionStorage.getItem(key);
				}
				return false;
			})();

			let expires;
			try {
				expires = JSON.parse(sessionStorage.getItem('ZD-suid') || '{}').expiry;
			} catch {
				// JSON parse failed: expired will be undefined and it
				// will reauth (hopefully setting a valid ZD-suid this time)
			}

			if (hasUserId && expires && expires > Date.now()) {
				// Auth looks ok, do nothing
				return;
			}
			setupZendeskChat(allIWant);
		});
	}

	InAppChat.init({
		givenName: allIWant.customerSelf.name,
		email: allIWant.customerSelf.email,
		properties: {
			masterId: allIWant.self.client.id,
			version: allIWant.self.version,
			admin: allIWant.customerSelf.administrator,
			billingAdmin: allIWant.customerSelf.billingAdmin,
			clientName: allIWant.self.client.name || '',
			language: allIWant.self.language || '',
			accountManager: allIWant.self.accountManager.name || '',
			phone: allIWant.metadata.user.userPhone || '',
			cellPhone: allIWant.metadata.user.userCellPhone || '',
			title: allIWant.metadata.user.userTitle || '',
			roleTitle:
				allIWant.metadata.userSurveyResult?.[0]?.stageId === SurveyStages.FINISH
					? allIWant.metadata.userSurveyResult?.[0]?.roleTitle
					: ''
		}
	});
};

/**
 * Sets up everything that doesn't require the app to be inited
 */
export const doStaticSetup = () => {
	// Setup hot-reloading
	setConfig({
		onComponentRegister: (type, name, file) => file.indexOf('node_modules') > 0 && cold(type),

		// some components are not visible as top level variables,
		// thus its not known where they were created
		onComponentCreate: (type, name) => name.indexOf('styled') > 0 && cold(type)
	});

	// Init translation service
	initLanguage();

	// Setup elevio
	setupElevioScript('https://cdn.elev.io/sdk/bootloader/v4/elevio-bootloader.js?cid=', '58abf36c8ba0e');

	// Change title and favicon based on env
	setPageTitleAndFavicon();
};
