import { Self } from 'App/resources/AllIWant';
import CustomField, { CustomFieldWithValue } from 'App/resources/Model/CustomField';
import _ from 'lodash';

export const hasAccess = (field: CustomField, self: Self | null) => {
	const admin = self?.administrator;
	const roleId = self?.role ? self.role.id : null;
	if (admin || !field.roles || !field.roles.length || _.find(field.roles, { id: roleId })) {
		return true;
	}
	return false;
};

/** @deprecated */
export const map = (form: Record<string, any>) => {
	// I think this is meant for angular form custom field inputs, using with react fields will remove the fields so be careful
	const customFields = [];
	for (const [key, field] of Object.entries(form)) {
		const split = key.split('_');
		if (split[0] === 'upCustomField' && !_.isUndefined(field.$modelValue)) {
			if (
				_.isNull(field.$modelValue) &&
				!_.includes(['Integer', 'Currency', 'Discount', 'Select', 'User'], field.$$customField.datatype)
			) {
				return;
			}

			if (!_.isNull(field.$modelValue)) {
				if (field.$$customField.datatype === 'User') {
					field.$modelValue = field.$modelValue.id || field.$modelValue;
				} else if (field.$$customField.datatype === 'Users') {
					if (!_.isEmpty(field.$modelValue)) {
						field.$modelValue = field.$modelValue
							.map((modelValue: Record<string, any>) => modelValue.id)
							.join(',');
					}
				}
			}

			customFields.push({
				fieldId: parseInt(split[1]),
				value: field.$modelValue,
				datatype: field.$$customField.datatype
			});
		}
	}
	return customFields.length ? customFields : null;
};

export const mapValues = (parsedFields: CustomFieldWithValue[]) => {
	const customFields = [];
	for (const [, field] of Object.entries(parsedFields)) {
		if (!_.isUndefined(field.value)) {
			// Only Selects can be saved as null
			if (_.isNull(field.value) && field.datatype !== 'Select') {
				return;
			}
			customFields.push({ fieldId: field.fieldId, value: field.value });
		}
	}

	return customFields.length ? customFields : null;
};

export const parse = (fields: CustomField[], values: CustomFieldWithValue[]) => {
	const self = Tools.AppService.getSelf();
	values = values || [];
	for (const [, field] of Object.entries(fields) as [string, CustomFieldWithValue][]) {
		const valueObj = _.find(values, { fieldId: field.id });
		field.$hasAccess = hasAccess(field, self);
		field.fieldId = field.id;
		if (valueObj) {
			field.value = valueObj.value;
		}
	}
	return fields;
};

export const parseVisible = (fields: CustomField[], values: CustomFieldWithValue[]) => {
	const self = Tools.AppService.getSelf();
	values = values || [];
	const results = [];
	for (const [, valueObj] of Object.entries(values)) {
		const field = _.find(fields, { id: valueObj.fieldId }) as CustomFieldWithValue;
		if (field) {
			field.$hasAccess = hasAccess(field, self);
			if (field.visible) {
				field.value = valueObj.value;
				results.push(field);
			}
		}
	}
	return results;
};

export default {
	hasAccess,
	map,
	mapValues,
	parse,
	parseVisible
};
