import CommentInput from 'App/components/Inputs/CommentInput';
import DateInput from './DateInput';
import ErrorBoundary from 'App/components/ErrorBoundary';
import EventActivity from './EventActivity';
import EventAppointment from './EventAppointment';
import EventIcon from './EventIcon';
import OpportunityAI from 'Components/OpportunityAI';
import OpportunityTodos from 'Components/OpportunityTodos';
import OrderHistory from 'App/components/OrderHistory';
import React, { useEffect, useRef, useMemo } from 'react';
import useEditOrderContext from '../hooks/useEditOrderContext';
import { currencyFormat } from 'Components/Filters/Currencies';
import { dateCalendar } from 'App/helpers/DateHelpers';
import { sortBy } from 'lodash';
import { useTranslation } from 'react-i18next';
// @ts-ignore
import classNames from 'classnames';

import type Order from 'App/resources/Model/Order';
import type { ActivityEvent, AppointmentEvent } from '../types';

const OrderDashboard = () => {
	const EditOrder = useEditOrderContext();
	const { t } = useTranslation();
	const anchor = useRef<Element | null>(null);

	useEffect(() => {
		if (!anchor.current) {
			anchor.current = document.querySelector('.Modals');
		}
	});

	const sortedActivityUpcoming = useMemo(
		() => sortBy(EditOrder.activityUpcoming, 'date').reverse(),
		[EditOrder.activityUpcoming]
	);

	return (
		<div>
			<div className="widget-area">
				<div className="up-widget">
					<span className="widget-title">{t('default.value')}</span>
					<span className="widget-content">
						{currencyFormat(EditOrder.calc.totalNet(), EditOrder.selectedCurrency.iso)}
					</span>
				</div>
				{EditOrder.hasContributionMargin ? (
					<div className="up-widget">
						{EditOrder.hasBothRRAndCM ? (
							<span className="widget-title">{t('default.contributionMarginShort')}</span>
						) : null}
						{!EditOrder.hasBothRRAndCM ? (
							<span className="widget-title">{t('default.contributionMargin')}</span>
						) : null}
						<span className="widget-content">
							{currencyFormat(EditOrder.calc.totalContributionMargin(), EditOrder.selectedCurrency.iso)}
						</span>
					</div>
				) : null}
				{EditOrder.hasRecurringInterval ? (
					<div className="up-widget">
						{EditOrder.salesModelOption === 'mrr' ? (
							<span className="widget-title">{t('default.mrr')}</span>
						) : null}
						{EditOrder.salesModelOption !== 'mrr' ? (
							<span className="widget-title">{t('default.arr')}</span>
						) : null}
						<span className="widget-content">
							{currencyFormat(EditOrder.calc.totalRR(), EditOrder.selectedCurrency.iso)}
						</span>
					</div>
				) : null}
				{EditOrder.hasRecurringInterval ? (
					<div className="up-widget">
						<span className="widget-title">{t('admin.products.oneOff')}</span>
						<span className="widget-content">
							{currencyFormat(EditOrder.calc.totalOneOff(), EditOrder.selectedCurrency.iso)}
						</span>
					</div>
				) : null}
				<div className="up-widget">
					<span className="widget-title">{t('default.age')}</span>
					<span className="widget-content">
						{!EditOrder.isOrder ? (
							<span>
								{EditOrder.age} {t('calendar.days').toLowerCase()}
							</span>
						) : null}
						{EditOrder.isOrder ? (
							<span>{`${t('order.closedIn')} ${EditOrder.age} ${t('calendar.days').toLowerCase()}`}</span>
						) : null}
					</span>
				</div>
				{!EditOrder.isOrder ? (
					<div className={classNames('up-widget', { danger: EditOrder.isOld, linked: EditOrder.editable })}>
						{EditOrder.editable ? (
							<DateInput
								className="date-picker-fix"
								closeOnSelect={true}
								value={EditOrder.order.date}
								onChange={({ target: { value } }) => EditOrder.handleDateChange(value)}
								anchor={anchor.current}
							/>
						) : null}
						<span className="widget-title">{t('order.date')}</span>
						<span className="widget-content">
							{dateCalendar(EditOrder.order.date)}
							{!EditOrder.isOrder ? (
								<span className={classNames('days-diff', { red: EditOrder.isOld })}>
									{` ${EditOrder.getCloseDateDiff()}`}
								</span>
							) : null}
						</span>
					</div>
				) : null}
				<div
					onClick={() => !EditOrder.saving && EditOrder.editable && EditOrder.selectStage()}
					className={classNames('up-widget linked', {
						'click-disabled': !EditOrder.editable || EditOrder.saving,
						clickable: EditOrder.editable && !EditOrder.saving
					})}
					data-test-id="order-change-stage-widget"
				>
					<span className="widget-title">{t('default.stage')}</span>
					<span className="widget-content">{EditOrder.order.stage.name}</span>
				</div>
			</div>
			{EditOrder.order.id ? (
				<div>
					{/* @ts-expect-error This is what the old view provided as props, I guess the typing of the component is wrong */}
					<OpportunityAI
						orderValue={EditOrder.orderValue}
						disabled={EditOrder.saving || EditOrder.saveDisabled || !EditOrder.editable}
						opportunity={EditOrder.order as unknown as Order}
						updateOrder={EditOrder.updateOrder}
						updateProbability={EditOrder.updateProbability}
						setCreateData={EditOrder.setCreateData}
					/>
				</div>
			) : null}
			<div className="timeline-section">
				{EditOrder.hasTodo && EditOrder.hasActivitiesAndAppointments && EditOrder.order.id ? (
					<div>
						<OpportunityTodos
							createData={EditOrder.createData}
							selectUser={true}
							opportunity={EditOrder.order}
						/>
					</div>
				) : null}
				{EditOrder.hasTodo && EditOrder.order.id ? (
					<div>
						<CommentInput saveComment={EditOrder.saveComment} useTooltip={true} />
					</div>
				) : null}
				<table className={classNames('timeline', { 'has-todo': EditOrder.hasTodo })}>
					<tbody>
						{(!EditOrder.isOrder || EditOrder.activityUpcoming.length) && !EditOrder.hasTodo ? (
							<tr className="timeline-cat-divider active">
								<td className="icon-td active">
									<div className="icon"></div>
									<div className="line"></div>
								</td>
								<td>
									<div className="title no-float-title">
										{EditOrder.activityUpcoming.length ? (
											<div>
												<h2 className="pull-left">
													{t('default.openActivitiesAndAppointments')}
												</h2>
												{!EditOrder.isOrder ? (
													<div className="pull-right">
														<button
															type="button"
															className="up-btn btn btn-sm btn-link btn-bright-blue"
															onClick={() => EditOrder.createActivity()}
														>
															<span>{`${t('default.create')} `}</span>
															<span>{t('default.activity').toLowerCase()}</span>
														</button>
														<button
															type="button"
															className="up-btn btn btn-sm btn-link btn-bright-blue"
															onClick={() => EditOrder.createAppointment()}
														>
															<span>{`${t('default.create')} `}</span>
															<span>{t('default.appointment').toLowerCase()}</span>
														</button>
													</div>
												) : null}
											</div>
										) : (
											<h2>
												<span>{`${t('default.create')} `}</span>
												<a onClick={() => EditOrder.createActivity()}>
													{t('default.anActivity').toLowerCase()}
												</a>
												<span>{` ${t('default.or').toLowerCase()} `}</span>
												<a onClick={() => EditOrder.createAppointment()}>
													{`${t('default.anAppointment').toLowerCase()} `}
												</a>
												<span>{t('default.toMoveThisDealForward').toLowerCase()}</span>
											</h2>
										)}
									</div>
								</td>
							</tr>
						) : null}

						{!EditOrder.hasTodo
							? sortedActivityUpcoming.map(event => (
									<ErrorBoundary key={event.id} fallback={() => <tr key={event.id}></tr>}>
										<tr className="event-row upcoming">
											{/* ICONS */}
											<EventIcon event={event} line={true} />

											{/* ACTIVITY */}
											{event.entityType === 'Activity' && event.activity ? (
												<EventActivity event={event as ActivityEvent} />
											) : null}

											{/* APPOINTMENT */}
											{event.entityType === 'Appointment' && event.appointment ? (
												<EventAppointment event={event as AppointmentEvent} />
											) : null}
										</tr>
									</ErrorBoundary>
							  ))
							: null}
					</tbody>
				</table>

				{/* NEW EVENT HISTORY */}
				<div>
					<OrderHistory
						// @ts-expect-error So much wierd typings make my heaad hurt
						order={EditOrder.order}
						buttonOrder={EditOrder.notesFilterOrder}
					/>
				</div>
			</div>
		</div>
	);
};

export default OrderDashboard;
