import React, { useRef, useEffect } from 'react';
import { DateInput } from '@upsales/components';
import T from 'Components/Helpers/translate';
import type { Field, ParsedFieldValue } from './EditFormSubmitModal';

type Props = {
	disabled: boolean;
	field: Field;
	onChange: (event: { target: { value: ParsedFieldValue } }) => void;
	value: ParsedFieldValue;
};

const getTitle = (field: Field) => {
	return field.datatype === 'optIn' ? T('form.' + field.name.replace(/\d*\.?(\d|[a-zA-Z])+$/, '')) : field.title;
};

const CheckboxMulti = ({ field, value, onChange }: Props) => {
	if (!Array.isArray(field.options)) {
		return <></>;
	}

	const typedValue = value as string | string[];

	const toggleCheckbox = (option: string) => {
		if (typedValue) {
			const valueArray = typeof typedValue === 'string' ? typedValue.split(',') : typedValue;
			const index = valueArray.indexOf(option);

			if (index === -1) {
				valueArray.push(option);
			} else {
				valueArray.splice(index, 1);
			}

			onChange({ target: { value: valueArray } });
		} else {
			onChange({ target: { value: option } });
		}
	};

	return (
		<>
			{field.options.map((option, index) => (
				<label key={index} className="checkbox-label">
					<input
						checked={typedValue.indexOf(option) > -1}
						data-testid={field.name}
						onChange={() => toggleCheckbox(option)}
						type="checkbox"
					/>
					{` ${option} `} &nbsp;
				</label>
			))}
		</>
	);
};

const CheckboxSingle = ({ field, value, onChange }: Props) => {
	const typedValue = value as string;

	return (
		<label className="checkbox-label">
			<input
				checked={typedValue === 'on'}
				data-testid={field.name}
				onChange={() => onChange({ target: { value: typedValue === 'on' ? '' : 'on' } })}
				type="checkbox"
			/>
			{field.title}
		</label>
	);
};

const Checkbox = (props: Props) => {
	return Array.isArray(props.field.options) ? <CheckboxMulti {...props} /> : <CheckboxSingle {...props} />;
};

const Date = ({ disabled, field, value, onChange }: Props) => {
	const typedValue = value as Date | null;

	const anchor = useRef<Element | null>(null);
	useEffect(() => {
		if (!anchor.current) {
			anchor.current = document.querySelector('.Modals');
		}
	});

	return (
		<DateInput
			anchor={anchor.current}
			closeOnSelect={true}
			data-testid={field.name}
			disabled={disabled}
			onChange={onChange}
			portalPlacement="bottom"
			value={typedValue}
		/>
	);
};

const Default = ({ disabled, field, value, onChange }: Props) => {
	const typedValue = value as string;

	return (
		<input
			className="form-control"
			data-testid={field.name}
			disabled={disabled}
			onChange={onChange}
			required={field.required}
			type="text"
			value={typedValue}
		/>
	);
};

const Number = ({ disabled, field, value, onChange }: Props) => {
	const typedValue = value as number | string;

	return (
		<input
			className="form-control"
			data-testid={field.name}
			disabled={disabled}
			onChange={onChange}
			required={field.required}
			type="number"
			value={typedValue}
		/>
	);
};

const OptIn = ({ field, value }: Props) => {
	return (
		<label className="checkbox-label">
			<input checked={value === 'on'} data-testid={field.name} disabled readOnly type="checkbox" />
			{field.title}
		</label>
	);
};

const Radio = ({ disabled, field, value, onChange }: Props) => {
	if (!Array.isArray(field.options)) {
		return <></>;
	}

	return (
		<>
			{field.options.map((option, index) => (
				<label key={index} className="checkbox-label">
					<input
						checked={value === option}
						data-testid={field.name}
						disabled={disabled}
						onChange={onChange}
						type="radio"
						value={option}
					/>
					{` ${option} `} &nbsp;
				</label>
			))}
		</>
	);
};

const Select = ({ disabled, field, value, onChange }: Props) => {
	const typedValue = value as string;

	return (
		<select
			className="form-control"
			data-testid={field.name}
			disabled={disabled}
			onChange={onChange}
			value={typedValue}
		>
			{Array.isArray(field.options)
				? field.options.map((option, index) => (
						<option key={index} value={option}>
							{` ${option} `}
						</option>
				  ))
				: null}
		</select>
	);
};

const Textarea = ({ disabled, field, value, onChange }: Props) => {
	const typedValue = value as string;

	return (
		<textarea
			className="form-control"
			data-testid={field.name}
			disabled={disabled}
			onChange={onChange}
			rows={3}
			value={typedValue}
		/>
	);
};

const typeComponentMap = {
	checkbox: Checkbox,
	date: Date,
	default: Default,
	number: Number,
	optIn: OptIn,
	radio: Radio,
	select: Select,
	textarea: Textarea
};

const FieldSection = ({ disabled, field, value, onChange }: Props) => {
	const type = field.datatype as keyof typeof typeComponentMap;
	const Component = typeComponentMap[type] || typeComponentMap.default;
	const className = field.datatype === 'textarea' ? 'col-md-12' : 'col-md-6';

	return (
		<div className={className}>
			<label>{getTitle(field)}</label>
			{field.required ? <span className="text-danger">{` * `}</span> : null}
			<br />
			<Component disabled={disabled} field={field} onChange={onChange} value={value} />
		</div>
	);
};

export default FieldSection;
