import React from 'react';
import { Block, Icon, Text, Row, Column, Headline, Link, Tooltip, Flex, ClickableItem } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import { getJourneyStep } from 'Components/Helpers/journeyStep';
import T from 'Components/Helpers/translate';
import './EditPhonecallContact.scss';
import InlineInput from '../Inputs/InlineInput';
import Contact from 'App/resources/Model/Contact';
import Client from 'App/resources/Model/Client';
import Activity from 'App/resources/Model/Activity';
import { useAppDispatch } from 'App/components/hooks';
import { saveAndCloseModals } from 'Store/actions/ModalActions';
import RelationsSignalWidget from 'App/components/RelationsSignalWidget/RelationsSignalWidget';

const getContactHref = (id: number) =>
	Tools.$state.href('contact.dashboard', {
		id,
		customerId: Tools.AppService.getCustomerId()
	});

type InfoInputProps = {
	contact: Contact;
	client: Client;
	saveContact: () => void;
	type: 'phone' | 'cellPhone' | 'email';
	classes: BemClass;
	disabled?: boolean;
	missingAccess?: boolean;
	phoneRelated?: { type: string; id: number; closeDate: Activity['closeDate'] };
};

const InfoInput = ({
	contact,
	client,
	saveContact,
	type,
	disabled,
	missingAccess,
	phoneRelated,
	classes
}: InfoInputProps) => {
	const contactType = contact[type] ?? '';

	return (
		<Column className={classes.elem('edit-col').b()} size={5.5}>
			<InlineInput
				key={contactType + missingAccess}
				value={contactType}
				contact={contact}
				client={client}
				onConfirmInput={saveContact}
				type={type}
				disabled={missingAccess || disabled}
				phoneRelated={phoneRelated}
			/>
		</Column>
	);
};

interface Props {
	contact: Contact;
	onEditContact: () => void;
	contactChange: () => void;
	client: Client;
	closeDate: Activity['closeDate'];
	saveContact: () => void;
	closeDrawer: () => void;
	id: number;
	missingAccess?: boolean;
	disabled?: boolean;
}
const EditPhonecallContact = ({
	contact,
	client,
	onEditContact,
	contactChange,
	closeDate,
	saveContact,
	closeDrawer,
	id,
	missingAccess = false,
	disabled = false
}: Props) => {
	const classes = new BemClass('EditPhonecallContact');
	const journey = getJourneyStep(contact.journeyStep);
	const closed = !!closeDate;
	const phoneRelated = {
		type: 'Activity',
		id: id,
		closeDate: closeDate
	};
	const dispatch = useAppDispatch();
	const hasMinimize = Tools.FeatureHelper.hasSoftDeployAccess('MINIMIZE_TASK_DRAWERS');

	return (
		<Block space="prxl pbxl plxl" className={classes.b()} backgroundColor={missingAccess ? 'grey-1' : undefined}>
			{disabled ? null : (
				<div className={classes.elem('tools').b()}>
					{!closed ? (
						<Tooltip
							title={T(!missingAccess ? 'todo.editPhonecall.changeContact' : 'error.checkContactRights')}
							distance={15}
						>
							<ClickableItem
								borderRadius
								title={T('editPhonecall.changeContact')}
								textSize="sm"
								size="sm"
								icon="user-edit"
								iconColor="grey-10"
								onClick={() => contactChange()}
								disabled={missingAccess}
							/>
						</Tooltip>
					) : null}
					<Tooltip
						title={T(!missingAccess ? 'default.editContact' : 'error.checkContactRights')}
						distance={15}
					>
						<ClickableItem
							borderRadius
							size="sm"
							icon="edit"
							iconColor="grey-10"
							onClick={() => onEditContact()}
							disabled={missingAccess}
						/>
					</Tooltip>
				</div>
			)}
			<Row>
				<Column className={classes.elem('contact-name-col').b()}>
					{contact ? (
						missingAccess ? (
							<Headline size="xs">
								<Tooltip title={T('error.checkContactRights')} distance={15}>
									<Flex>
										<Text italic color="grey-10" ellipsis space="mrm">
											{contact.name}
										</Text>
										<Icon name="lock" color="grey-10" />
									</Flex>
								</Tooltip>
							</Headline>
						) : (
							<Link
								href={getContactHref(contact.id)}
								onClick={() => {
									if (hasMinimize) {
										closeDrawer();
										dispatch(saveAndCloseModals());
									}
								}}
							>
								<Headline size="xs">{contact.name}</Headline>
							</Link>
						)
					) : (
						<Column />
					)}
				</Column>
				<InfoInput
					contact={contact}
					client={client}
					saveContact={saveContact}
					type="cellPhone"
					disabled={disabled}
					missingAccess={missingAccess}
					phoneRelated={phoneRelated}
					classes={classes}
				/>
			</Row>
			<Row>
				{contact?.title ? (
					<Column key="title">
						<Text size="sm">{contact.title}</Text>
					</Column>
				) : journey ? (
					<Column key="journey">
						<Text size="sm">{journey?.name}</Text>
					</Column>
				) : (
					<Column />
				)}
				<InfoInput
					contact={contact}
					client={client}
					saveContact={saveContact}
					type="phone"
					disabled={disabled}
					missingAccess={missingAccess}
					phoneRelated={phoneRelated}
					classes={classes}
				/>
			</Row>
			<Row>
				{contact?.title && journey ? (
					<Column key="journey">
						<Text size="sm">{journey?.name}</Text>
					</Column>
				) : (
					<Column />
				)}
				<InfoInput
					contact={contact}
					client={client}
					saveContact={saveContact}
					type="email"
					disabled={disabled}
					missingAccess={missingAccess}
					classes={classes}
				/>
			</Row>
			{client ? <RelationsSignalWidget clientId={client.id} contactId={contact?.id} /> : null}
		</Block>
	);
};

export default EditPhonecallContact;
