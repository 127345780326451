const CountryConfig = {
	SE: {
		industryField: 'sniCode',
		currency: 'SEK',
		supportKeywords: true,
		orgNumberPattern: /^\d{6}-?\d{4}$/
	},
	NO: {
		industryField: 'naceCode',
		currency: 'NOK',
		supportKeywords: false,
		orgNumberPattern: /^\d{9}|\d{3} \d{3} \d{3}$/
	},
	GB: {
		industryField: 'ukSicCode',
		currency: 'GBP',
		supportKeywords: false,
		orgNumberPattern: /^[A-Z]{2}[0-9]{6}$|[0-9]{8}/
	},
	BE: {
		industryField: 'naceCode',
		currency: 'EUR',
		supportKeywords: false,
		orgNumberPattern: /^\d{9,10}$/
	},
	DK: {
		industryField: 'naceCode',
		currency: 'DKK',
		supportKeywords: false,
		orgNumberPattern: /^\d{8}$/
	},
	FI: {
		industryField: 'naceCode',
		currency: 'EUR',
		supportKeywords: false,
		orgNumberPattern: /^\d{7}-?\d{1}$/
	},
	FR: {
		industryField: 'naceCode',
		currency: 'EUR',
		supportKeywords: false,
		orgNumberPattern: /^\d{9}$/
	},
	IE: {
		industryField: 'naceCode',
		currency: 'EUR',
		supportKeywords: false,
		orgNumberPattern: /^IE\d{6}$/
	},
	IT: {
		industryField: 'naceCode',
		currency: 'EUR',
		supportKeywords: false,
		orgNumberPattern: /^[A-Z]{2}\d{5,6}$/
	},
	LU: {
		industryField: 'naceCode',
		currency: 'EUR',
		supportKeywords: false,
		orgNumberPattern: /^B\d{6}$/
	},
	NL: {
		industryField: 'naceCode',
		currency: 'EUR',
		supportKeywords: false,
		orgNumberPattern: /^\d{8}$/
	}
} as const;

export type Country = keyof typeof CountryConfig;

export const Countries = Object.keys(CountryConfig) as Country[];

export enum StatusCategory {
	InactiveOrDeregistered,
	ActiveWithWarning,
	Active
}

export function getAvailableCountries(): Country[] {
	const { isAvailable, Feature } = Tools.FeatureHelper;

	const countries: Country[] = [];

	TypedObject.keys(CountryConfig).forEach(country => {
		if (isAvailable(Feature[`PROSPECTING_${country}`])) {
			countries.push(country);
		}
	});

	return countries;
}

export function getCountryFromProspectingId(prospectingId: string | null): Country | null {
	if (typeof prospectingId !== 'string') {
		return null;
	}
	if (prospectingId.startsWith('UK')) {
		return 'GB';
	}

	return (
		TypedObject.keys(CountryConfig).find(country => {
			return prospectingId.startsWith(country);
		}) || null
	);
}

export function getAvailableCountryFromProspectingId(prospectingId: string): Country | null {
	const country = getCountryFromProspectingId(prospectingId);

	if (country === null) {
		return null;
	}

	const countries = getAvailableCountries();
	return countries.includes(country) ? country : null;
}

export function getDefaultIndustryFieldFromCountry(country: Country) {
	return (CountryConfig[country] || CountryConfig['SE']).industryField;
}

export function getDefaultIndustryFieldFromProspectingId(prospectingId: string) {
	const country = getCountryFromProspectingId(prospectingId);
	return getDefaultIndustryFieldFromCountry(country ?? 'SE');
}

export function getDefaultCurrencyFromCountry(country: Country) {
	return (CountryConfig[country] || CountryConfig['SE']).currency;
}

export function getDefaultCurrencyFromProspectingId(prospectingId: string) {
	const country = getCountryFromProspectingId(prospectingId);
	return getDefaultCurrencyFromCountry(country ?? 'SE');
}

export function validateOrgNumberForCountry(country: Country, orgNumber: string) {
	return (CountryConfig[country] || CountryConfig['SE']).orgNumberPattern.test(orgNumber);
}

export function isKeywordsSupportedForCountry(country: Country) {
	return CountryConfig[country]?.supportKeywords ?? false;
}
