import React from 'react';
import { Table, TableColumn, TableHeader, TableRow, Loader, Link, Text, Tooltip, Icon } from '@upsales/components';
import bemClass from '@upsales/components/Utils/bemClass';
import formatWidgetValue from 'App/pages/Reportcenter/ReportcenterWidget/formatWidgetValue';
import { Datatypes } from 'Resources/ReportWidget';
import './ReportcenterDrilldown.scss';
import _ from 'lodash';
import { openDrawer } from 'Services/Drawer';
import OrderStage from 'App/resources/Model/OrderStage';
import ReportcenterDrilldownEmpty from './ReportcenterDrilldownEmpty';
import Client from 'App/resources/Model/Client';
import openAgreement from 'App/helpers/openSubscriptionHelper';
import { openEditAppointment } from 'Components/Modals/Appointment/EditAppointment';

type row = {
	[key: string]: string | number | null;
};

type column = {
	attribute: string;
	title: string;
	link: { attribute: string; entity: string };
	placeholder?: string;
	dynamicTextPrefix?: string;
	dynamicPlaceholderPrefix?: string;
	sortType: string;
	format?: Datatypes;
	standardField?: string;
	standardFieldEntity?: string;
	textColor?: React.ComponentProps<typeof Text>['color'];
};

type Props = {
	rows: row[];
	columns: column[];
	loading: boolean;
	link: Partial<{ attribute: string; entity: string }>;
	maxRowCount: number;
	specialDrilldownType?: string;
	client: Client | null;
	sorting?: string | null;
	sortingAsc: boolean;
	onSortChange?: (sort: { field: string; asc: boolean }) => void;
	closeDrawer: () => void;
};

type State = {
	risksOpen: { [key: string]: boolean };
};

class ReportcenterDrilldown extends React.Component<Props, State> {
	standardFields: {};
	stages: OrderStage[];

	constructor(props: Props) {
		super(props);
		this.standardFields = Tools.AppService.getMetadata().standardFields;
		this.stages = Tools.AppService.getStages();
		this.state = { risksOpen: {} };
	}
	openEntity = (id: number, entity: string, row: row) => {
		switch (entity) {
			case 'client': {
				// eslint-disable-next-line security/detect-non-literal-fs-filename
				window.open(
					Tools.$state.href('account.dashboard', {
						customerId: Tools.AppService.getCustomerId(),
						id
					}),
					'_blank'
				);
				break;
			}
			case 'contact': {
				// eslint-disable-next-line security/detect-non-literal-fs-filename
				window.open(
					Tools.$state.href('contact.dashboard', {
						customerId: Tools.AppService.getCustomerId(),
						id
					}),
					'_blank'
				);
				break;
			}
			case 'mailCampaign': {
				// eslint-disable-next-line security/detect-non-literal-fs-filename
				window.open(
					Tools.$state.href('mailCampaign.dashboard', {
						customerId: Tools.AppService.getCustomerId(),
						id
					}),
					'_blank'
				);
				break;
			}
			case 'order':
			case 'create_order':
			case 'create_opportunity':
				Tools.$upModal.open('editOrder', { id });
				break;
			case 'appointment':
			case 'book_appointment':
				openEditAppointment({ id });
				break;
			case 'form':
				window.open('#/form-overview/' + id, '_blank');
				break;
			case 'flow':
				if (row.segmentId) {
					window.open(
						Tools.$state.href('segment.flow', {
							customerId: Tools.AppService.getCustomerId(),
							id: row.segmentId
						}),
						'_blank'
					);
				} else {
					window.open(
						Tools.$state.href('flow', {
							customerId: Tools.AppService.getCustomerId(),
							id
						}),
						'_blank'
					);
				}
				break;
			case 'mail':
				if (Tools.FeatureHelper.hasSoftDeployAccess('NEW_MAIL')) {
					openDrawer('SentMail', { mail: { id } });
				} else {
					Tools.$upModal.open('sentMail', { id });
				}
				break;
			case 'socialEvent':
				Tools.$state.go('react-root-editSocialEvent', { customerId: Tools.AppService.getCustomerId(), id });
				this.props.closeDrawer();
				break;
			case 'agreement':
				openAgreement({ agreementId: id });
				break;
			case 'event':
				window.open(
					Tools.$state.href('react-root-editSocialEvent', {
						customerId: Tools.AppService.getCustomerId(),
						id
					}),
					'_blank'
				);
				break;
			case 'ticket':
				openDrawer('EditTicket', { ticketId: id });
				break;
			default:
				break;
		}
	};

	isLinkable = (id: number, entity: string) => {
		const linkables = [
			'client',
			'order',
			'create_order',
			'create_opportunity',
			'appointment',
			'book_appointment',
			'mail',
			'socialEvent',
			'agreement',
			'flow',
			'ticket'
		];
		return id && linkables.includes(entity);
	};

	trimAndDisplayTooltip(text: string | number | null | undefined, column: column): text is string {
		return typeof text === 'string' && text.length > 50 && ['sniCode', 'ukSicCode'].includes(column.attribute);
	}

	getIcon = (attribute: string, value: string | number | null) => {
		if (attribute === 'stage') {
			const probability = this.stages.find(s => s.name === value)?.probability;
			if (probability) {
				return <Icon space="mrs" name="opportunity" />;
			} else {
				return <Icon space="mrs" name="times-circle" color="red" />;
			}
		}
		return null;
	};

	render() {
		const classes = new bemClass('ReportcenterDrilldown');
		const {
			rows,
			columns,
			maxRowCount,
			client,
			loading,
			link,
			sorting,
			sortingAsc,
			specialDrilldownType,
			onSortChange
		} = this.props;
		const { risksOpen } = this.state;
		const filteredColumns = columns.filter(column =>
			column.standardField &&
			column.standardFieldEntity &&
			!_.get(this.standardFields, `${column.standardFieldEntity}.${column.standardField}.active`)
				? false
				: true
		);
		const sortableColumns = filteredColumns.map(col => {
			return { ...col, sort: col.attribute, sortType: col?.sortType ?? 'alpha' };
		});
		return (
			<div className={classes.b()}>
				{loading ? (
					<div>
						<Table>
							<TableHeader columns={filteredColumns} />
						</Table>
						<div
							className={classes.elem('loader-wrapper').b()}
							style={{ minHeight: maxRowCount * 40 + 'px' }}
						>
							<Loader noU />
						</div>
					</div>
				) : (
					<Table className={classes.elem('table').b()}>
						<TableHeader
							columns={sortableColumns}
							sorting={sorting}
							asc={sortingAsc}
							onSortChange={onSortChange}
						/>
						{rows.length ? (
							rows.map((row, i) => {
								const onClick = () => {
									const entityId = row[link.attribute as string];
									const entity = link.entity === 'outcome' ? row[link.entity] : link.entity;

									return typeof entityId === 'number' &&
										typeof entity === 'string' &&
										!_.isEmpty(link) &&
										this.isLinkable(entityId, entity) &&
										!row['risks'] // don't make whole row clickable if it has risks since we will have a dropdown with risks
										? () => this.openEntity(entityId, entity, row)
										: undefined;
								};

								const risks = row['risks'] as unknown as
									| {
											color: string;
											title: string;
											risks: string[];
									  }[]
									| undefined;

								return (
									<React.Fragment key={row[link?.attribute as string] || i}>
										<TableRow onClick={onClick()}>
											{filteredColumns.map(column => {
												const hasLink =
													column.link && row[column.attribute] && row[column.link.attribute];

												const entity = hasLink ? column.link.entity : null;
												const entityId = hasLink ? row[column.link.attribute] : null;
												const icon = this.getIcon(column.attribute, row[column.attribute]);
												const placeholder = !row[column.attribute] ? column.placeholder : false;
												let text = placeholder ? column.placeholder : row[column.attribute];

												const updateDynamicText = () => {
													// needed when the text for a cell is not the row value itself, but rather based on what the row value is.
													if (column.dynamicTextPrefix && !placeholder) {
														text = window.Tools.$translate(
															column.dynamicTextPrefix + row[column.attribute]
														);
													} else if (
														column.format &&
														typeof text === 'number' &&
														!placeholder
													) {
														text = formatWidgetValue(text, column.format);
													} else if (
														column.dynamicPlaceholderPrefix &&
														column.placeholder &&
														placeholder
													) {
														text = window.Tools.$translate(
															column.dynamicPlaceholderPrefix + row[column.placeholder]
														);
													}
												};

												updateDynamicText();

												return (
													<TableColumn key={column.attribute + row[link.attribute as string]}>
														{hasLink &&
														typeof entityId === 'number' &&
														typeof entity === 'string' ? (
															<Link
																onClick={e => {
																	e.stopPropagation();
																	this.openEntity(entityId, entity, row);
																}}
															>
																{row[column.attribute]}
															</Link>
														) : column.attribute === 'risks' && risks ? (
															<Text
																size="sm"
																className={classes.elem('column-risks').b()}
															>
																{risks.map(risk => {
																	return (
																		<Tooltip key={risk.title} title={risk.title}>
																			<Icon
																				name="circle"
																				space="mrs"
																				color={risk.color}
																			/>
																			{risk.risks.length}
																		</Tooltip>
																	);
																})}
																<Icon
																	onClick={() =>
																		this.setState({
																			risksOpen: {
																				...risksOpen,
																				[row.rowId!]: !risksOpen[row.rowId!]
																			}
																		})
																	}
																	name={`${
																		risksOpen[row.rowId!]
																			? 'chevron-up'
																			: 'chevron-down'
																	}`}
																	space="mls"
																/>
															</Text>
														) : this.trimAndDisplayTooltip(text, column) ? (
															<Tooltip title={text} distance={20}>
																<div className={classes.elem('column').b()}>
																	{icon}
																	<Text
																		size="sm"
																		className={classes
																			.elem('column-text')
																			.mod({
																				placeholder: !row[column.attribute]
																			})
																			.b()}
																		color={column.textColor}
																	>
																		{text.substring(0, 50) + '...'}
																	</Text>
																</div>
															</Tooltip>
														) : (
															<div className={classes.elem('column').b()}>
																{icon}
																<Text
																	size="sm"
																	className={classes
																		.elem('column-text')
																		.mod({ placeholder: !row[column.attribute] })
																		.b()}
																	color={column.textColor}
																>
																	{text}
																</Text>
															</div>
														)}
													</TableColumn>
												);
											})}
										</TableRow>
										{risks && row.rowId && risksOpen[row.rowId] ? (
											<TableRow>
												<TableColumn
													className={classes.elem('column-risks-dropdown').b()}
													colSpan={100}
												>
													{risks.map(risk => {
														return (
															<div key={risk.color}>
																<Text bold size="sm" space="mbs">
																	{risk.title}
																</Text>
																<div>
																	{risk.risks.map(riskName => {
																		return (
																			<div
																				key={riskName}
																				className={classes
																					.elem('risks-dropdown-item')
																					.b()}
																			>
																				<Icon
																					name="circle"
																					space="mrs"
																					color={risk.color}
																				/>
																				<Text size="sm">{riskName}</Text>
																			</div>
																		);
																	})}
																</div>
															</div>
														);
													})}
												</TableColumn>
											</TableRow>
										) : null}
									</React.Fragment>
								);
							})
						) : (
							<ReportcenterDrilldownEmpty type={specialDrilldownType} client={client} />
						)}
					</Table>
				)}
			</div>
		);
	}
}

export const detached = ReportcenterDrilldown;

export default ReportcenterDrilldown;
