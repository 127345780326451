import { ClientValue } from 'Components/ClientContactSelect/ClientContactSelect';
import { Flex, Icon, Text, Tooltip } from '@upsales/components';
import { getJourneyStep } from 'Components/Helpers/journeyStep';
import SubAccountLabel from 'Components/Misc/SubAccountLabel';
import BemClass from '@upsales/components/Utils/bemClass';
import { ThirdButton } from '@upsales/components/Buttons';
import Contact from 'App/resources/Model/Contact';
import Client from 'App/resources/Model/Client';
import T from 'Components/Helpers/translate';
import React from 'react';

type RowProps = {
	classes: BemClass;
	highlighted: string | null;
	contact?: Contact;
	removable: boolean;
	removeSelectedRow?: () => void;
	onClick?: () => void;
	client?: ClientValue & Pick<Client, 'operationalAccount'>;
	extraIcons: boolean;
};

const ClientContactRow = ({
	classes,
	highlighted,
	contact,
	removable,
	removeSelectedRow,
	onClick,
	client,
	extraIcons
}: RowProps) => {
	const isRelated = client ? client.id !== contact?.client.id : false;
	const key = contact ? `con-${contact.id}` : `cli-${client?.id}`;

	return (
		<Flex
			className={classes
				.elem('row')
				.mod({ highlighted: key === highlighted })
				.b()}
			onClick={onClick}
			data-id={key}
			direction="row"
			justifyContent="space-between"
			alignItems="center"
		>
			{contact ? (
				<>
					<Flex className={classes.elem('userInfo').b()} direction="column" flex={1} space="mll">
						{isRelated ? (
							<Text className={classes.elem('row').elem('title').b()} ellipsis>
								<Icon name="sitemap" space="mrm" />
								{contact.name}
							</Text>
						) : (
							<>
								<Text ellipsis className={classes.elem('row').elem('title').b()}>
									{contact.name}
								</Text>
								<Text ellipsis size="sm" color="grey-11">
									{contact.title}
								</Text>
							</>
						)}
						{contact.client ? (
							<Flex space="prl" className={classes.elem('row').elem('subtitle').b()}>
								<Text size="sm" color="grey-11" ellipsis>
									{contact.client.name} • {getJourneyStep(contact.journeyStep)?.name}
								</Text>
							</Flex>
						) : null}
					</Flex>
					<Flex
						alignContent="flex-end"
						space={removable ? 'plm' : 'plm prxl'}
						alignItems="center"
						justifyContent="space-between"
						gap={removable ? 'u3' : 'u6'}
					>
						{extraIcons && contact ? (
							<>
								<Tooltip disabled={!contact.email} title={contact.email ?? ''}>
									<Icon color={contact.email ? 'black' : 'grey-7'} name="envelope" />
								</Tooltip>
								<Tooltip
									position="left"
									disabled={!contact.cellPhone && !contact.phone}
									title={`${contact.phone ? T('contact.row.phone') + contact.phone + '\n' : ''}${
										contact.cellPhone ? T('contact.row.mobile') + contact.cellPhone : ''
									}`}
								>
									<Icon
										color={contact.cellPhone || contact.phone ? 'black' : 'grey-7'}
										name="phone"
									/>
								</Tooltip>
							</>
						) : null}
					</Flex>
				</>
			) : (
				<>
					<Flex className={classes.elem('userInfo').b()} direction="column" flex={1} space="mll">
						<Flex alignItems="center" justifyContent="left">
							<Text ellipsis className={classes.elem('row').elem('title').b()}>
								{client?.name}
							</Text>
							<SubAccountLabel operationalAccount={client?.operationalAccount ?? null} />
						</Flex>
						<Text size="sm" color="grey-11" ellipsis>
							{getJourneyStep(client?.journeyStep)?.name}
						</Text>
					</Flex>
				</>
			)}
			{removable ? (
				<Flex>
					<ThirdButton onClick={removeSelectedRow} color="gray">
						<Icon name="times" />
					</ThirdButton>
				</Flex>
			) : null}
		</Flex>
	);
};

export default ClientContactRow;
