import useEditOrderContext from './useEditOrderContext';
import { useEffect, useRef, useState } from 'react';

import type { InputHTMLAttributes, TextareaHTMLAttributes, ChangeEvent, FocusEvent } from 'react';
import type { ModifiedOnChange } from '../types';

export type Value = number | string | null | undefined;

export type Options<ModelValue = Value, ViewValue = Value> = {
	updateOn: UpdateEvent | UpdateEvent[];
	debounce: { change?: number; blur?: number } | number;
	isEmpty: (value: ViewValue) => boolean;
	render: (value: ViewValue) => string;
	formatters: ((value: ModelValue) => ViewValue)[];
	parsers: ((value: ViewValue) => ModelValue)[];
	validators: ((props: object, modelValue: ModelValue, viewValue: ViewValue) => boolean)[];
};

export enum UpdateEvent {
	Change = 'change',
	Blur = 'blur'
}

export function render(value: Value): string {
	return isEmpty(value) ? '' : value!.toString();
}

export function isEmpty(value: Value) {
	return value === undefined || value === '' || value === null || value !== value;
}

export function requiredValidator(props: { required?: boolean | undefined }, modelValue: Value, viewValue: Value) {
	if (props.required === undefined) {
		return true;
	}

	return props.required === false || !isEmpty(viewValue);
}

export function minValidator(props: { min?: string | number }, modelValue: Value) {
	if (props.min === undefined) {
		return true;
	}

	const min = typeof props.min === 'string' ? parseFloat(props.min) : props.min;

	if (isNaN(min)) {
		return true;
	}

	return isEmpty(modelValue) || (modelValue as number) >= min;
}

export function maxValidator(props: { max?: string | number }, modelValue: Value) {
	if (props.max === undefined) {
		return true;
	}

	const max = typeof props.max === 'string' ? parseFloat(props.max) : props.max;

	if (isNaN(max)) {
		return true;
	}

	return isEmpty(modelValue) || (modelValue as number) <= max;
}

export function maxLengthValidator(props: { maxLength?: number }, modelValue: Value, viewValue: Value) {
	if (props.maxLength === undefined) {
		return true;
	}

	return isEmpty(viewValue) || (viewValue as string).length <= props.maxLength;
}

export function minLengthValidator(props: { minLength?: number }, modelValue: Value, viewValue: Value) {
	if (props.minLength === undefined) {
		return true;
	}

	return isEmpty(viewValue) || (viewValue as string).length >= props.minLength;
}

export function patternValidator(props: { pattern?: string }, modelValue: Value, viewValue: Value) {
	if (props.pattern === undefined) {
		return true;
	}
	const regexp = new RegExp(`^${props.pattern}$`);
	return isEmpty(viewValue) || regexp.test(viewValue as string);
}

export const options: { [type: string]: Options<any, any> } = {
	get default() {
		return {
			updateOn: [UpdateEvent.Change, UpdateEvent.Blur],
			debounce: { change: 0, blur: 0 },
			isEmpty,
			render,
			formatters: [],
			parsers: [],
			validators: [requiredValidator, patternValidator, minLengthValidator, maxLengthValidator]
		};
	},

	get text() {
		return {
			updateOn: [UpdateEvent.Change, UpdateEvent.Blur],
			debounce: { change: 0, blur: 0 },
			isEmpty,
			render,
			formatters: [
				function textFormatter(value: Value) {
					return isEmpty(value) ? value : value!.toString();
				}
			],
			parsers: [],
			validators: [requiredValidator, patternValidator, minLengthValidator, maxLengthValidator]
		} as Options<string, string>;
	},

	get number() {
		return {
			updateOn: [UpdateEvent.Change, UpdateEvent.Blur],
			debounce: { change: 0, blur: 0 },
			isEmpty,
			render,
			formatters: [
				function numberFormatter(value: Value) {
					if (!isEmpty(value)) {
						if (typeof value !== 'number') {
							throw new Error(`Expected \`${value}\` to be a number`);
						}
						return value.toString();
					}
					return value;
				}
			],
			parsers: [
				function numberParser(value: Value) {
					const NUMBER_REGEXP = /^\s*(-|\+)?(\d+|(\d*(\.\d*)))\s*$/;

					if (isEmpty(value)) {
						return null;
					} else if (NUMBER_REGEXP.test(value as string)) {
						return parseFloat(value as string);
					} else {
						return undefined;
					}
				}
			],
			validators: [
				requiredValidator,
				patternValidator,
				minLengthValidator,
				maxLengthValidator,
				minValidator,
				maxValidator
			]
		} as Options<number, string>;
	}
};

const useInputController = <
	E extends HTMLInputElement | HTMLTextAreaElement = HTMLInputElement,
	ModelValue = Value,
	ViewValue = string
>(
	props: Omit<
		E extends HTMLInputElement
			? InputHTMLAttributes<HTMLInputElement>
			: TextareaHTMLAttributes<HTMLTextAreaElement>,
		'value' | 'onChange'
	> & { value: ModelValue; onChange?: ModifiedOnChange<E, ModelValue> },
	options: Options<ModelValue, ViewValue>
): E extends HTMLInputElement ? InputHTMLAttributes<HTMLInputElement> : TextareaHTMLAttributes<HTMLTextAreaElement> => {
	const { onBlur: _onBlur, onChange: _onChange, value: _value, ...inputProps } = props;

	const { registerFormComponent, unregisterFormComponent, onFormConponentChange } = useEditOrderContext();

	function runFormatters(formatters: Options<ModelValue, ViewValue>['formatters'], value: ModelValue): ViewValue {
		let index = formatters.length;
		let viewValue = value;

		while (index--) {
			// @ts-expect-error No clue how to type a "pipeline" like this
			viewValue = formatters[index](viewValue);
		}

		// @ts-expect-error No clue how to type a "pipeline" like this
		return viewValue;
	}

	function runParsers(parsers: Options<ModelValue, ViewValue>['parsers'], value: ViewValue): ModelValue {
		// @ts-expect-error No clue how to type a "pipeline" like this
		return parsers.reduce((result, parser) => {
			return result === undefined ? result : parser(result);
		}, value);
	}

	function runValidators(
		props: object,
		validators: Options<ModelValue, ViewValue>['validators'],
		modelValue: ModelValue,
		viewValue: ViewValue
	) {
		return validators.every(validator => validator(props, modelValue, viewValue));
	}

	const [viewValue, setViewValue] = useState<ViewValue>(() => runFormatters(options.formatters, _value));
	const lastCommittedValueRef = useRef<ModelValue>(_value);
	const pendingDebounceRef = useRef<NodeJS.Timeout | null>(null);

	useEffect(() => {
		const name = inputProps.name;
		if (name) {
			const valid = runValidators(props, options.validators, _value, viewValue);
			registerFormComponent(name, valid);
			return () => unregisterFormComponent(name);
		}
		// Will not add name to the dependency array because then I need something like renameControl
	}, []);

	useEffect(() => {
		const name = inputProps.name;

		if (_value !== lastCommittedValueRef.current) {
			const viewValue = runFormatters(options.formatters, _value);
			setViewValue(viewValue);

			if (name) {
				const valid = runValidators(props, options.validators, _value, viewValue);
				onFormConponentChange(name, valid, false);
			}
		}
	}, [_value]);

	function commitViewValue(event: ChangeEvent<E>) {
		const viewValue = event.target.value as ViewValue;
		const modelValue = runParsers(options.parsers, viewValue);

		if (_value !== modelValue) {
			lastCommittedValueRef.current = modelValue;

			_onChange?.({ ...event, target: { ...event.target, value: modelValue } });

			const name = inputProps.name;
			if (name) {
				const valid = runValidators(props, options.validators, modelValue, viewValue);
				onFormConponentChange(name, valid, true);
			}
		}
	}

	function debounceViewValueCommit(trigger: UpdateEvent, event: ChangeEvent<E>) {
		if (pendingDebounceRef.current) {
			clearTimeout(pendingDebounceRef.current);
			pendingDebounceRef.current = null;
		}

		const debounce = typeof options.debounce === 'number' ? options.debounce : options.debounce[trigger] ?? 0;

		if (debounce) {
			pendingDebounceRef.current = setTimeout(() => commitViewValue(event), debounce);
		} else {
			commitViewValue(event);
		}
	}

	function onChange(event: ChangeEvent<any>) {
		const viewValue = event.target.value;
		setViewValue(viewValue);

		const shouldCommitViewValue = Array.isArray(options.updateOn)
			? options.updateOn.includes(UpdateEvent.Change)
			: options.updateOn === UpdateEvent.Change;
		if (shouldCommitViewValue) {
			debounceViewValueCommit(UpdateEvent.Change, event);
		}
	}

	function onBlur(event: FocusEvent<any>) {
		const shouldCommitViewValue = Array.isArray(options.updateOn)
			? options.updateOn.includes(UpdateEvent.Blur)
			: options.updateOn === UpdateEvent.Blur;
		if (shouldCommitViewValue) {
			debounceViewValueCommit(UpdateEvent.Blur, event);
		}

		_onBlur?.(event);
	}

	const value = options.render(viewValue);

	return { ...inputProps, value, onBlur, onChange };
};

export default useInputController;
