import { useTranslation } from 'Components/Helpers/translate';
import _ from 'lodash';
import React from 'react';

type Email = { id: string; text: string };

type MultiEmailSelectProps = {
	value: Email[];
	onChange: (value: Email[]) => void;
} & Omit<React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>, 'onChange' | 'defaultValue'>;

const emailRegex =
	/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; //eslint-disable-line
const tagRegex = /{{.*}}/;

const MultiEmailSelect = ({ value, ...props }: MultiEmailSelectProps) => {
	const { t } = useTranslation();

	const addMail = function (email: Email) {
		// Check if string is an email
		if (emailRegex.test(email.text) || tagRegex.test(email.text)) {
			props.onChange([...value, email]);
		}
	};

	return (
		<ReactTemplates.INPUTS.upSelect
			multiple
			defaultValue={value}
			options={{ data: [] }}
			formatResult={(obj: any) => {
				return t('default.add') + ': ' + _.escape(obj.text);
			}}
			formatSelection={(obj: any) => {
				return obj.text;
			}}
			formatNoMatches={() => {
				return t('default.enterEmail');
			}}
			createSearchChoice={(term: string) => {
				if (emailRegex.test(term) || tagRegex.test(term)) {
					return { id: term, text: term };
				} else {
					return null;
				}
			}}
			{...props}
			onChange={({ target: { added, removed } }) => {
				if (added) addMail(added);
				if (removed) props.onChange(value.filter(email => email.id !== removed.id));
			}}
		/>
	);
};

export default MultiEmailSelect;
