import ResourceTyped from 'Resources/ResourceTyped';
import { STYLE, TYPE } from 'Store/reducers/SystemNotificationReducer';

export type GroupMailCategoryType = {
	id: number;
	title: string;
	description: string;
	active: boolean;
};
class GroupMailCategory extends ResourceTyped<GroupMailCategoryType> {
	eventName = 'groupMailCategory';
	notifications: { [key: string]: () => { [key: string]: string } };

	constructor() {
		super('groupmailcategories');

		this.notifications = {
			save: () => ({
				title: 'default.saved',
				body: 'saved.groupMailCategory',
				style: STYLE.SUCCESS,
				icon: 'check',
				type: TYPE.BODY
			}),
			saveError: () => ({
				title: 'default.error',
				body: 'saveError.groupMailCategory',
				style: STYLE.ERROR,
				icon: 'times',
				type: TYPE.BODY
			})
		};
	}

	new() {
		const optOut = {
			title: '',
			description: '',
			languages: [],
			active: true
		};

		return optOut;
	}
}

const resource = new GroupMailCategory();

export default resource;
