import LZString from 'lz-string';
import getAngularModule from 'App/babel/angularHelpers/getAngularModule';
import MailCampaign, { status } from 'App/resources/MailCampaign';
import RequestBuilder, { comparisonTypes } from 'App/babel/resources/RequestBuilder';
import { removeItem, replaceItem } from '../../helpers/array';
import MailTemplate from 'App/babel/resources/MailTemplate';
import File from 'App/babel/resources/File';
import { generateMarkdownFromConfig, setPreviewTextToHtml } from 'Services/MailTemplate';
import translate from 'Components/Helpers/translate';
import { addNotification, STYLE as notificationStyle } from 'Store/reducers/SystemNotificationReducer';
import { batch } from 'react-redux';
import { generateHTML } from 'App/helpers/mailTemplateHelpers';

export const MAX_SIZE = 15000000;

export const setEmailFrom = email => {
	const AppService = getAngularModule('AppService');
	const { email: from, name: fromName } = AppService.getSelf();
	email.from = email.from || from;
	email.fromName = email.fromName || fromName;
};

export default class GenerateSharedMailActions {
	constructor(storeName, objectKey) {
		this.storeName = storeName;
		this.objectKey = objectKey;
	}

	updateFileProgress =
		(updateObject, filename, progress, data, error = false) =>
		(dispatch, getState) => {
			const { [this.objectKey]: object } = getState()[this.storeName];
			const i = object.attachments.findIndex(f => filename === f.filename && f.pending === true);
			let newFile;
			if (progress === 100 && data) {
				newFile = { filename, size: data.size, value: data.id, type: 'InternalFile', pending: false };
			} else {
				newFile = { ...object.attachments[i], progress, error };
			}
			dispatch(updateObject({ attachments: replaceItem(object.attachments, i, newFile) }));
		};

	previewInBrowser = setLoadingPreviewBrowser => () => async (dispatch, getState) => {
		dispatch(setLoadingPreviewBrowser(true));
		const { [this.objectKey]: original, config, style, accountProfile } = getState()[this.storeName];
		const object = { ...original };
		setEmailFrom(object);
		if (config) {
			object.body = await generateHTML(generateMarkdownFromConfig(config), style, accountProfile);
			object.body = object.body.replace('{{UNSUB}}', '#');
			object.body = object.body.replace('{{SUBSCRIPTIONS}}', '#');
		} else if (object.savedBody) {
			object.body = object.savedBody;
		}
		dispatch(setLoadingPreviewBrowser(false));
		const preview = window.open(
			'',
			'',
			'height=768,width=1024,scrollbars=yes,resizable=yes,toolbar=no,status=no,menu=no,titlebar=no,location=no,addressbar=no'
		);

		if (!preview) {
			Tools.NotificationService.addNotification({
				style: notificationStyle.ERROR,
				icon: 'times',
				title: translate('mail.previewOpenFail.title'),
				body: translate('mail.previewOpenFail.body')
			});
			return;
		}

		const html = `<head>
	<style>
		body {
			margin: 0;
			border-top: 5px solid #002f65;
			color: #333;
			font-family: Helvetica;
			font-size: 14px;
		}
		#info h2 {
			color: #002f65;
			font-family: Helvetica;
			font-size: 22px;
			margin-bottom: 6px;
		}
		#info p{
			margin-top: 2px;
			margin-bottom: 2px;
		}
		#emailHeaders {
			font-size:12px;
		}
		#emailHeaders tr {
			border-bottom: 1px solid #f0f0f0;
		}
	</style>
</head>
<body>
	<table border=0 width="100%" cellpadding="0" cellspacing="0">
		<tr width="100%">
			<td width="100%" id="info" bgcolor="#fff">
				<font>
					<h2>
						${translate('mail.preview')} ${translate('mail.mail').toLowerCase()}
					</h2>
					<p>${translate('mail.previewDisclaimer')}.</p>
				</font>
			</td>
		</tr>
		<tr width="100%">
			<td width="100%" id="emailHeaders">
				<br/>
				<table border=0 width="100%">
					<tr width="100%">
						<td>
							<b>${translate('mail.from')}:</b>
						</td>
						<td>
							${object.fromName || object.from} &lt;${object.from}&gt;
						</td>
					</tr>
					<tr>
						<td>
							<b>${translate('mail.to')}:</b>
						</td>
						<td>${object.to ? object.to : '-'}</td>
					</tr>
					<tr>
						<td>
							<b>${translate('mail.subject')}:</b>
						</td>
						<td>${_.escape(object.subject)}</td>
					</tr>
				</table>
				<br/>
			</td>
		</tr>
		<tr>
			<td id="previewBody">
				${object.body || ''}
			</td>
		</tr>
	</table>
</body>`;

		var toggleStyle =
			' style="width: 50%; height: 40px; line-height: 40px; background-color: #fff; border: 1px solid #ccc; color: #888;"';
		var toggles = `<div style="position: absolute; width: 100%; z-index: 10000; top: 0; left: 0; font-family: sans-serif;"><button type="button"
			${toggleStyle}
			onClick=document.getElementById("preview-iframe").style.width="100%"><b class="fa fa-desktop"></b>
			${translate('ads.desktop')}
			</button>`;
		toggles += `<button type="button"'
			${toggleStyle}
			onClick=document.getElementById("preview-iframe").style.width="400px"><b class="fa fa-mobile" style="margin-right: 3px;"></b>
			${translate('default.phone')}
			</button></div>`;
		preview.document.write(
			toggles +
				'<div style="width: 100%; height: 100%; display: flex; justify-content: center; position: absolute; left: 0;"><iframe id="preview-iframe" style="width: 100%; height: 100%;"></iframe></div>'
		);
		const iframe = preview.document.getElementById('preview-iframe');
		const script = preview.document.createElement('script');
		script.src = 'https://use.fontawesome.com/01a8d84c9a.js';
		preview.document.head.appendChild(script);
		iframe.contentWindow.document.open();
		iframe.contentWindow.document.write(html);
		iframe.contentWindow.document.close();
	};

	sendTest = addresses => async (_dispatch, getState) => {
		const Mail = getAngularModule('Mail');
		const AppService = getAngularModule('AppService');
		const {
			[this.objectKey]: object,
			config,
			style,
			accountProfile,
			previewText,
			socialEvent
		} = getState()[this.storeName];
		const mail = { ...object };
		mail.bodyJson = config ? generateMarkdownFromConfig(config) : null;
		if (mail.bodyJson) {
			mail.body = await generateHTML(mail.bodyJson, style, accountProfile);
		} else if (!mail.body) {
			mail.body = '';
		} else {
			// add/update previewText in the email
			mail.body = setPreviewTextToHtml(mail.body, previewText);
		}

		if (socialEvent?.id) {
			mail.socialEventId = socialEvent.id;
		}
		mail.to = addresses;
		mail.userId = AppService.getSelf().id;
		setEmailFrom(mail);
		return Mail.sendTest(mail);
	};

	saveAsTemplate =
		(setConfirmLoading, setActiveConfirm) =>
		({ name, subject, private: isPrivate }) =>
		async (dispatch, getState) => {
			dispatch(setConfirmLoading(true));
			const { [this.objectKey]: object, style, accountProfile } = getState()[this.storeName];

			if (object.bodyJson) {
				object.body = await generateHTML(object.bodyJson, style, accountProfile);
			}

			const template = {
				name,
				subject,
				body: object.body,
				bodyJson: object.bodyJson,
				from: object.from,
				fromName: object.fromName,
				attachments: object.attachments,
				private: isPrivate
			};

			try {
				const res = await MailTemplate.save(template);
				dispatch(setActiveConfirm(null));
				return res;
			} finally {
				dispatch(setConfirmLoading(false));
			}
		};

	generateMiniPreview = (setLoadingMiniPreview, updateObject) => () => async (dispatch, getState) => {
		dispatch(setLoadingMiniPreview(true));
		const { [this.objectKey]: object, config, style, accountProfile } = getState()[this.storeName];

		if (object.bodyJson) {
			object.body = await generateHTML(generateMarkdownFromConfig(config), style, accountProfile);
			dispatch(updateObject(object));
		}

		dispatch(setLoadingMiniPreview(false));

		return object.body || '';
	};

	removeAttachment = (setSizeOfAllFiles, updateObject) => attachment => (dispatch, getState) => {
		const { [this.objectKey]: object, sizeOfAllFiles } = getState()[this.storeName];
		const i = object.attachments.findIndex(f => attachment.filename === f.filename);
		dispatch(setSizeOfAllFiles(sizeOfAllFiles - object.attachments[i].size));
		dispatch(updateObject({ attachments: removeItem(object.attachments, i) }));
	};

	uploadFiles = (updateObject, setSizeOfAllFiles, saveBounced) => files => async (dispatch, getState) => {
		// Check total size not too large
		const sizeOfFiles = files.reduce((total, { size }) => total + size, 0);
		const { [this.objectKey]: object, sizeOfAllFiles } = getState()[this.storeName];
		if (sizeOfFiles > MAX_SIZE || sizeOfAllFiles + sizeOfFiles > MAX_SIZE) {
			dispatch(
				addNotification({
					style: notificationStyle.WARN,
					icon: 'warning',
					title: translate('default.fileSizeTooLarge'),
					body: translate('groupMail.fileLimit')
				})
			);
			return;
		}

		const pendingFiles = files.map(file => {
			return { file, filename: file.name, pending: true, error: false, progress: 0, size: file.size };
		});
		object.attachments = [...object.attachments, ...pendingFiles];
		dispatch(updateObject({ ...object }));

		for (let i = pendingFiles.length - 1; i >= 0; i--) {
			try {
				const data = await File.upload(pendingFiles[i].file, {
					onUploadProgress: progress => {
						// Update upload progress
						dispatch(this.updateFileProgress(updateObject, pendingFiles[i].filename, progress));
					},
					fileEntity: 'mail'
				});
				dispatch(this.updateFileProgress(updateObject, pendingFiles[i].filename, 100, data));
				const { sizeOfAllFiles } = getState().GroupMailEditor;
				dispatch(setSizeOfAllFiles(sizeOfAllFiles + pendingFiles[i].size));
			} catch (e) {
				dispatch(
					addNotification({
						style: notificationStyle.ERROR,
						icon: 'times',
						title: translate('mail.uploadFail'),
						body: translate('file.uploadFailed')
					})
				);
				const indexOfFaultyFile = object.attachments.indexOf(pendingFiles[i]);
				dispatch(updateObject({ attachments: removeItem(object.attachments, indexOfFaultyFile) }));
			}
		}

		if (saveBounced) {
			dispatch(saveBounced());
		}
	};
	/*
		Will push change to the changes array (for undo/redo).
		Will hold max 20 changes (to save memory).
		We will always save the original version (from this session) in a separate variable
		so you will always be able to reset the design.
	*/
	pushChange = (setChanges, setCurrentChange) => config => {
		return (dispatch, getState) => {
			const { changes, currentChange } = getState()[this.storeName];
			const compressed = LZString.compressToBase64(JSON.stringify(config));

			// Don't push same change again
			if (changes[currentChange] === compressed) {
				return;
			}

			const prevChanges = changes.length ? changes.splice(0, currentChange + 1) : [];

			let nextChanges;
			if (prevChanges.length < 20) {
				nextChanges = [...prevChanges, compressed];
			} else {
				nextChanges = [...prevChanges.splice(1, prevChanges.length), compressed];
			}
			batch(() => {
				dispatch(setChanges(nextChanges));
				dispatch(setCurrentChange(Math.max(0, nextChanges.length - 1)));
			});
		};
	};

	undoChange = (setConfig, setCurrentChange) => () => {
		return (dispatch, getState) => {
			const { changes, currentChange } = getState()[this.storeName];
			_doChange(setConfig, setCurrentChange, currentChange - 1, changes, dispatch);
		};
	};

	redoChange = (setConfig, setCurrentChange) => () => {
		return (dispatch, getState) => {
			const { changes, currentChange } = getState()[this.storeName];
			_doChange(setConfig, setCurrentChange, currentChange + 1, changes, dispatch);
		};
	};
}

function _doChange(setConfig, setCurrentChange, next, changes, dispatch) {
	const change = changes[next];
	if (change) {
		try {
			const config = JSON.parse(LZString.decompressFromBase64(change));
			dispatch(setConfig(config));
			dispatch(setCurrentChange(next));
		} catch (e) {
			console.log('Failed to parse config', e);
		}
	}
}

export const addNameFilter = (filter, term) => {
	const orBuilder = filter.orBuilder();
	orBuilder.next();
	orBuilder.addFilter({ field: 'subject' }, comparisonTypes.Search, term);
	orBuilder.next();
	orBuilder.addFilter({ field: 'name' }, comparisonTypes.Search, term);
	orBuilder.done();
};

export const getTemplatesFromMailCampaigns = async templateFilters => {
	const filter = new RequestBuilder();
	filter.addSort(MailCampaign._attributes.sendDate, false);
	if (templateFilters.user) {
		filter.addFilter(MailCampaign._attributes.user.attr.id, comparisonTypes.Equals, templateFilters.user.id);
	}
	filter.limit = templateFilters.limit;
	filter.offset = templateFilters.offset;

	// Ignore event templates
	filter.addFilter(MailCampaign._attributes.socialEventId, comparisonTypes.Equals, null);
	filter.addFilter(MailCampaign._attributes.isArchived, comparisonTypes.Equals, false);
	filter.addFilter(MailCampaign._attributes.status, comparisonTypes.Equals, status.SENT);
	filter.addFilter(MailCampaign._attributes.flowId, comparisonTypes.Equals, null);

	if (templateFilters.term) {
		addNameFilter(filter, templateFilters.term);
	}

	return MailCampaign.find(filter.build());
};

export const addLabelFilter = (requestBuilder, label) => {
	if (label) {
		if (label.id) {
			requestBuilder.addFilter(MailTemplate._attributes.labels.attr.id, comparisonTypes.Equals, label.id);
		} else if (label.type === 'Unlabeled') {
			requestBuilder.addFilter(MailTemplate._attributes.labels.attr.id, comparisonTypes.Equals, null);
		}
	}
};

export const getUserTemplates = async (templateFilters, excludeId) => {
	const AppService = getAngularModule('AppService');
	const self = AppService.getSelf();
	const filter = new RequestBuilder();

	addLabelFilter(filter, templateFilters.label);

	if (templateFilters.user) {
		filter.addFilter(MailTemplate._attributes.user.attr.id, comparisonTypes.Equals, templateFilters.user.id);
	}
	filter.addSort(MailTemplate._attributes.modDate, false);
	filter.limit = templateFilters.limit;
	filter.offset = templateFilters.offset;

	// Ignore event templates
	filter.addFilter(MailTemplate._attributes.socialEvent, comparisonTypes.Equals, null);

	if (excludeId) {
		filter.addFilter(MailTemplate._attributes.id, comparisonTypes.NotEquals, excludeId);
	}

	if (templateFilters.term) {
		addNameFilter(filter, templateFilters.term);
	}

	// Filter on non private or private but belongs to self
	const privateFilter = filter.orBuilder();
	privateFilter.next();
	privateFilter.addFilter(MailTemplate._attributes.private, comparisonTypes.Equals, false);
	privateFilter.next();
	privateFilter.addFilter(MailTemplate._attributes.private, comparisonTypes.Equals, true);
	privateFilter.addFilter(MailTemplate._attributes.user.attr.id, comparisonTypes.Equals, self.id);
	privateFilter.done();

	return MailTemplate.find(filter.build());
};

export const getStandardTemplates = async templateFilters => {
	const StandardMailTemplate = getAngularModule('StandardMailTemplate');
	// Find all standard templates, we cannot have str search from api so we do it on response
	const res = await StandardMailTemplate.find({});

	const t = getAngularModule('$translate');

	let data;

	if (templateFilters.term) {
		data = res.data.filter(template => {
			template.name = t(template.name);
			return template.name.toLowerCase().indexOf(templateFilters.term.toLowerCase()) !== -1;
		});
		res.metadata.total -= res.metadata.total - data.length;
	} else {
		data = res.data.map(template => {
			template.name = t(template.name);
			return template;
		});
	}

	return { data, metadata: res.metadata };
};

const translateSender = (sender, mailAccount) => {
	switch (sender) {
		case '{{General.CompanyEmail}}':
			return mailAccount.defaultEmail ?? '';
		case '{{General.CurrentUserEmail}}': {
			const AppService = getAngularModule('AppService');
			return AppService.getSelf().email ?? '';
		}
		default:
			return sender ?? '';
	}
};

export const validateSender = (mailAccount, domains = {}, sender = '') => {
	/**
	 * canStillUseSendgrid is a temporary check while customers migrate from Sendgrid to Halon.
	 * Backend will fallback to Sendgrid if invalid sender as long as DISABLE_SENDGRID is not true.
	 * So we do not want to prevent the user from sending the mail campaign so we return valid = true.
	 */
	const FeatureHelper = getAngularModule('FeatureHelper');
	const canStillUseSendgrid = !FeatureHelper.hasSoftDeployAccess('DISABLE_SENDGRID');
	const allwaysValid = !mailAccount?.useHalon || canStillUseSendgrid;

	if (allwaysValid) {
		return {
			valid: true,
			domain: '',
			validDomains: [],
			noneValidDomains: []
		};
	}

	// Special case as this is not one single email
	if (sender === '{{Client.UserEmail}}') {
		const AppService = getAngularModule('AppService');
		const users = AppService.getActiveUsers();
		const { validDomains, noneValidDomains } = users.reduce(
			(res, { email }) => {
				if (email) {
					const domain = email.substring(email.lastIndexOf('@') + 1);
					const valid = !!domains[domain];
					if (valid) {
						res.validDomains.add(domain);
					} else {
						res.noneValidDomains.add(domain);
					}
				}
				return res;
			},
			{ validDomains: new Set(), noneValidDomains: new Set() }
		);

		return {
			valid: validDomains.size > 0,
			validDomains: [...validDomains],
			noneValidDomains: [...noneValidDomains]
		};
	} else {
		const translatedSender = translateSender(sender, mailAccount ?? {});
		// Sender is a tag we did not now how to translate, not sure what the best pplay here is. Returning them as valid for now.
		if (translatedSender.includes('{{')) {
			return { valid: true, domain: translatedSender };
		}
		const domain = translatedSender.substring(translatedSender.lastIndexOf('@') + 1);
		return { valid: !!domains[domain], domain };
	}
};
