//Component that displays entities grouped/alone depending on config.
import BemClass from '@upsales/components/Utils/bemClass';
import { Button, Icon, Tooltip, Text, Row, Column, IconName } from '@upsales/components';
import { containsPhoneWords } from 'App/babel/helpers/phoneWords';
import Activity from 'App/resources/Model/Activity';
import Appointment from 'App/resources/Model/Appointment';
import Order from 'App/resources/Model/Order';
import ProjectPlan from 'App/resources/Model/ProjectPlan';
import React, { Fragment, useCallback } from 'react';
import moment from 'moment';
import { openDrawer } from 'Services/Drawer';
import { openEditAppointment } from 'Components/Modals/Appointment/EditAppointment';

import './RelatedButtons.scss';
import Ticket from 'App/resources/Model/Ticket';
import Agreement from 'App/resources/Model/Agreement';
import openAgreement from 'App/helpers/openSubscriptionHelper';

const currencyFormat = (value: number, currency: string) => Tools.$filter('currencyFormat')(value, currency);

interface Props {
	projectPlan?: PartialPick<ProjectPlan, 'id'> | null;
	opportunity?: PartialPick<Order, 'id'> | null;
	activity?: PartialPick<Activity, 'id'> | null;
	appointment?: PartialPick<Appointment, 'id'> | null;
	ticket?: PartialPick<Ticket, 'id'> | null;
	agreement?: PartialPick<Agreement, 'id'> | null;
	title?: string;
	smallIcons?: boolean;
	inEdit?: boolean;
	fullSize?: boolean;
}

const FullSizeRelation: React.FC<{
	icon: IconName;
	title?: string;
	subtitle?: string;
	onClick: (e: React.MouseEvent) => void;
}> = ({ icon, title, subtitle, onClick }) => {
	const classes = new BemClass('RelatedButtons').elem('full');
	return (
		<Row>
			<Row onClick={onClick} className={classes.b()}>
				<Column className={classes.elem('icon-column').b()}>
					<Text size="md" color={'black'}>
						<Icon name={icon} space={'prl'} />
					</Text>
				</Column>
				<Column className={classes.elem('text-column').b()}>
					{title && (
						<Text size="md" color="black" ellipsis>
							{title}
						</Text>
					)}
					{subtitle && (
						<Text size="sm" color="grey-11" ellipsis className={classes.elem('subtitle').b()}>
							{subtitle}
						</Text>
					)}
				</Column>
			</Row>
			<Column />
		</Row>
	);
};

const RelatedButtons = ({
	projectPlan,
	opportunity,
	activity,
	appointment,
	ticket,
	agreement,
	title,
	smallIcons,
	fullSize
}: Props) => {
	const editAppointment = useCallback(
		(e: React.MouseEvent) => {
			e?.stopPropagation();
			openEditAppointment({
				id: appointment?.id
			});
		},
		[appointment]
	);
	const editActivity = useCallback(
		(e: React.MouseEvent) => {
			e?.stopPropagation();
			Tools.$upModal.open('editActivity', {
				id: activity?.id
			});
		},
		[activity]
	);

	const editOpportunity = useCallback(
		(e: React.MouseEvent) => {
			e?.stopPropagation();
			Tools.$upModal.open('editOrder', {
				customerId: Tools.AppService.getCustomerId(),
				id: opportunity?.id
			});
		},
		[opportunity]
	);

	const editProjectPlan = useCallback(
		(e: React.MouseEvent) => {
			e?.stopPropagation();
			openDrawer('EditProjectPlan', { projectPlanId: projectPlan!.id });
		},
		[projectPlan]
	);

	const editTicket = useCallback(
		(e: React.MouseEvent) => {
			e?.stopPropagation();
			openDrawer('EditTicket', { ticketId: ticket!.id });
		},
		[ticket]
	);

	const editAgreement = useCallback(
		(e: React.MouseEvent) => {
			e?.stopPropagation();
			openAgreement({ agreementId: agreement!.id });
		},
		[agreement]
	);

	const classes = new BemClass('RelatedButtons');

	/** @deprecated Unused after TASKS_COLUMN_IMPROVEMENTS is released */
	const SingleRelation = ({ children }: { children: JSX.Element }) => (
		<div className={classes.elem('double-icon').b()}>
			<Text size="sm" className={classes.elem('ellipsis').b()}>
				{title}
			</Text>
			{children}
		</div>
	);

	const hasProjectPlanAccess = Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.PROJECT_PLAN);
	const hasSubscriptionRelation = Tools.FeatureHelper.hasSoftDeployAccess('SUBSCRIPTION_RELATE_TO_TASK_SUPPORT');

	let content;
	if (
		Number(!!opportunity) +
			Number(!!activity) +
			Number(!!appointment) +
			Number(!!projectPlan) +
			Number(!!ticket) +
			Number(!!agreement) >=
			2 ||
		(smallIcons &&
			Number(!!opportunity) +
				Number(!!activity) +
				Number(!!appointment) +
				Number(!!projectPlan) +
				Number(!!ticket) +
				Number(!!agreement) >=
				1)
	) {
		content = (
			<Fragment>
				<div className={classes.elem('double-icon').b()}>
					{title && (
						<Text size="sm" className={classes.elem('ellipsis').b()}>
							{title}
						</Text>
					)}
					<div className={classes.elem('double-icon').elem('icons').mod({ large: !!fullSize }).b()}>
						{opportunity ? (
							<Tooltip
								distance={20}
								title={
									opportunity.description +
									((opportunity.orderValue || opportunity.orderValue === 0) && opportunity.currency
										? ` - ${currencyFormat(opportunity.orderValue, opportunity.currency)}`
										: '')
								}
							>
								<Icon
									onClick={editOpportunity}
									name={opportunity.probability === 100 ? 'dollar' : 'opportunity'}
								/>
							</Tooltip>
						) : null}
						{appointment ? (
							<Tooltip
								distance={20}
								title={`${appointment.description} - ${moment(appointment.date).format(
									'YYYY-MM-DD HH:mm'
								)}`}
							>
								<Icon onClick={editAppointment} name="calendar" />
							</Tooltip>
						) : null}
						{activity ? (
							<Tooltip distance={20} title={activity.description || ''}>
								<Icon
									onClick={editActivity}
									name={containsPhoneWords(activity.activityType?.name) ? 'phone' : 'activity'}
								/>
							</Tooltip>
						) : null}
						{projectPlan && hasProjectPlanAccess ? (
							<Tooltip distance={20} title={projectPlan.name || ''}>
								<Icon onClick={editProjectPlan} name="project" />
							</Tooltip>
						) : null}
						{Tools.FeatureHelper.hasSoftDeployAccess('TICKETS_RELATE_TASKS_AND_TICKETS') && ticket ? (
							<Tooltip distance={20} title={ticket.title || ''}>
								<Icon onClick={editTicket} name="customer-support" />
							</Tooltip>
						) : null}
						{agreement && hasSubscriptionRelation ? (
							<Tooltip distance={20} title={agreement.description || ''}>
								<Icon onClick={editAgreement} name="subscription" />
							</Tooltip>
						) : null}
					</div>
				</div>
			</Fragment>
		);
	} else if (opportunity) {
		content = fullSize ? (
			<FullSizeRelation
				icon={opportunity.probability === 100 ? 'dollar' : 'opportunity'}
				title={opportunity.description}
				subtitle={
					opportunity.orderValue && opportunity.currency
						? currencyFormat(opportunity.orderValue, opportunity.currency)
						: undefined
				}
				onClick={editOpportunity}
			/>
		) : (
			<SingleRelation>
				<Button type="link" onClick={editOpportunity}>
					<Text size="sm" color="grey-11" className={classes.elem('subtitle').b()}>
						<Icon name={opportunity.probability === 100 ? 'dollar' : 'opportunity'} space="mrs" />
						{opportunity.orderValue && opportunity.currency
							? currencyFormat(opportunity.orderValue, opportunity.currency)
							: null}
					</Text>
				</Button>
			</SingleRelation>
		);
	} else if (projectPlan && hasProjectPlanAccess) {
		content = fullSize ? (
			<FullSizeRelation icon="project" title={projectPlan.name} onClick={editProjectPlan} />
		) : (
			<SingleRelation>
				<Button type="link" onClick={editProjectPlan}>
					<Text size="sm" color="grey-11" className={classes.elem('subtitle').b()}>
						<Icon name="project" space="mrs" />
					</Text>
				</Button>
			</SingleRelation>
		);
	} else if (appointment) {
		content = fullSize ? (
			<FullSizeRelation
				icon={'calendar'}
				title={appointment.description}
				subtitle={moment(appointment.date).format('YYYY-MM-DD HH:mm')}
				onClick={editAppointment}
			/>
		) : (
			<SingleRelation>
				<Button type="link" onClick={editAppointment}>
					<Text size="sm" color="grey-11" className={classes.elem('subtitle').b()}>
						<Icon name="calendar" space="mrs" />
						{moment(appointment.date).format('YYYY-MM-DD HH:mm')}
					</Text>
				</Button>
			</SingleRelation>
		);
	} else if (activity) {
		content = fullSize ? (
			<FullSizeRelation
				icon={containsPhoneWords(activity.activityType?.name) ? 'phone' : 'activity'}
				title={activity.description}
				onClick={editActivity}
			/>
		) : (
			<SingleRelation>
				<Button type="link" onClick={editActivity}>
					<Text size="sm" color="grey-11" className={classes.elem('subtitle').b()}>
						<Icon
							name={containsPhoneWords(activity.activityType?.name) ? 'phone' : 'activity'}
							space="mrs"
						/>
						{activity.description}
					</Text>
				</Button>
			</SingleRelation>
		);
	} else if (ticket && Tools.FeatureHelper.hasSoftDeployAccess('TICKETS_RELATE_TASKS_AND_TICKETS')) {
		content = fullSize ? (
			<FullSizeRelation icon={'customer-support'} title={ticket.title} onClick={editTicket} />
		) : (
			<SingleRelation>
				<Button type="link" onClick={editTicket}>
					<Text size="sm" color="grey-11" className={classes.elem('subtitle').b()}>
						<Icon name="customer-support" space="mrs" />
						{ticket.title}
					</Text>
				</Button>
			</SingleRelation>
		);
	} else if (agreement && hasSubscriptionRelation) {
		content = fullSize ? (
			<FullSizeRelation icon={'subscription'} title={agreement.description} onClick={editAgreement} />
		) : (
			<SingleRelation>
				<Button type="link" onClick={editAgreement}>
					<Text size="sm" color="grey-11" className={classes.elem('subtitle').b()}>
						<Icon name="subscription" space="mrs" />
						{agreement.description}
					</Text>
				</Button>
			</SingleRelation>
		);
	} else if (title) {
		content = (
			<Text size="md" className={classes.elem('ellipsis').b()}>
				{title}
			</Text>
		);
	}
	if (!content) {
		return null;
	}

	return <div className={classes.b()}>{content}</div>;
};

const inEditRelatedButtons = (props: Props) => {
	if (props.inEdit) {
		return null;
	}

	return <RelatedButtons {...props} />;
};

export default inEditRelatedButtons;
