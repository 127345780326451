//eslint-disable-next-line
import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Add from './Columns/Add';
import Generic from './Columns/Generic';
import GroupSize from './Columns/GroupSize';
import { ParentLine, ParentLineStraight, ParentLineLast } from './Columns/Lines';
import NameExisting from './Columns/NameExisting';
import NameSoliditet from './Columns/NameSoliditet';
import NameProspecting from './Columns/NameProspecting';
import User from './Columns/User';
import View from './Columns/View';
import CurrencyColumn from './Columns/CurrencyColumn';
import History from './Columns/History';

import Journey from './Columns/Journey';
import AccountManager from './Columns/AccountManager';
import UnlinkSubaccountColumn from './Columns/UnlinkSubaccountColumn';

export default class AccountRow extends React.Component {
	componentDidMount() {
		if (
			this.props.groupAccount &&
			parseInt(this.props.groupAccount[this.props.idField]) === parseInt(this.props.account[this.props.idField])
		) {
			let parent = ReactDOM.findDOMNode(this).parentElement;
			while (parent && !parent.classList.contains('search-results')) {
				parent = parent.parentElement;
			}

			if (parent) {
				parent.scrollTop = ReactDOM.findDOMNode(this).offsetTop;
			}
		}
	}

	render() {
		const columns = _.map(this.props.fields, (field, index) => {
			const standardColumns = {
				add: Add,
				parentLine: ParentLine,
				parentLineLast: ParentLineLast,
				parentLineStraight: ParentLineStraight,
				groupSize: GroupSize,
				nameExisting: NameExisting,
				nameSoliditet: NameSoliditet,
				nameProspecting: NameProspecting,
				currency: CurrencyColumn,
				history: History,
				view: View,
				users: User,
				journey: Journey,
				accountManager: AccountManager,
				unlinkSubaccount: UnlinkSubaccountColumn
			};

			const Component = standardColumns[field.type] || Generic;

			return (
				<Component
					key={index}
					account={this.props.account}
					field={field}
					actions={this.props.actions}
					type={this.props.type}
					addingAccount={this.props.addingAccount}
					companyGroup={this.props.companyGroup}
					dataSourceId={this.props.dataSourceId}
					idField={this.props.idField}
					pricing={this.props.pricing}
					hasRequriedFields={this.props.hasRequriedFields}
					multiselect={this.props.multiselect}
					selected={this.props.selected}
					onToggleSubaccounts={this.props.onToggleSubaccounts}
					showSubaccounts={this.props.showSubaccounts}
				/>
			);
		});

		const hasSubaccounts = Tools.FeatureHelper.hasSoftDeployAccess('SUB_ACCOUNTS');
		const hasSubaccountsV2 = Tools.FeatureHelper.hasSoftDeployAccess('SUB_ACCOUNTS_V2') && hasSubaccounts;
		const hasGroupBonanza = Tools.FeatureHelper.hasSoftDeployAccess('GROUP_BONANZA');

		const classNames = [hasGroupBonanza || hasSubaccountsV2 ? 'account-row-lg-height' : 'account-row'];

		if (
			!hasGroupBonanza &&
			!hasSubaccountsV2 &&
			this.props.groupAccount &&
			`${this.props.groupAccount[this.props.idField]}` === `${this.props.account[this.props.idField]}`
		) {
			classNames.push('selected-account');
		}

		if (
			(hasGroupBonanza || hasSubaccountsV2) &&
			this.props.groupAccount &&
			`${this.props.groupAccount[this.props.idField]}` === `${this.props.account[this.props.idField]}`
		) {
			classNames.push('selected-account-green-border');
		}

		const external = this.props.account.matchInUpsales?.isExternal || false;
		if (!hasGroupBonanza && !hasSubaccountsV2 && this.props.account.existing && !external) {
			classNames.push('existing-account');
		}

		if (hasSubaccountsV2 && this.props.account.isSubaccount) {
			classNames.push('subaccount');
		}

		const accountId = this.props.account.id || this.props.account.upsalesId;
		if (accountId && !external) {
			classNames.push('has-account-id');
		}

		const hasInactiveCompanies = Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.INACTIVE_COMPANIES);

		if (
			hasInactiveCompanies &&
			((this.props.type === 'existing' && !this.props.account.active) || this.props.account.existingInactive)
		) {
			classNames.push('inactive');
		}

		return (
			<tr
				className={classNames.join(' ')}
				ref={r => {
					this._row = r;
				}}
				onClick={() => {
					if (accountId && !external) {
						this.props.actions.goToAccount(accountId);
					}
				}}
			>
				{columns}
			</tr>
		);
	}
}

AccountRow.propTypes = {
	fields: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.object), PropTypes.object]),
	actions: PropTypes.object,
	account: PropTypes.object,
	groupAccount: PropTypes.object,
	type: PropTypes.string,
	dataSourceId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	companyGroup: PropTypes.object,
	addingAccount: PropTypes.object,
	idField: PropTypes.string,
	pricing: PropTypes.object,
	hasRequriedFields: PropTypes.bool,
	selected: PropTypes.bool,
	multiselect: PropTypes.bool,
	onToggleSubaccounts: PropTypes.func,
	showSubaccounts: PropTypes.bool
};
