export type CancelablePromise<T = any> = {
	promise: Promise<T>;
	cancel: () => void;
	isCanceled: () => boolean;
};

export function makeCancelable<T = any>(promise: Promise<T>): CancelablePromise<T> {
	let hasCanceled_ = false;

	const wrappedPromise = new Promise<T>((resolve, reject) => {
		promise
			.then(val => {
				if (!hasCanceled_) {
					resolve(val);
				}
			})
			.catch(error => {
				if (!hasCanceled_) {
					reject(error);
				}
			});
	});

	return {
		promise: wrappedPromise,
		cancel() {
			hasCanceled_ = true;
		},
		isCanceled() {
			return hasCanceled_;
		}
	};
}

// https://stackoverflow.com/questions/44600771/equivalent-of-bluebird-promise-props-for-es6-promises
export async function PromiseProps(promises: { [key: string]: Promise<any> }) {
	const values = await Promise.all(Object.values(promises));
	const result: { [key: string]: any } = {};
	Object.keys(promises).forEach((key, i) => (result[key] = values[i]));
	return result;
}

// https://stackoverflow.com/questions/38418256/how-do-i-defer-an-es6-promise-like-jquery-deferred
export const generateDeferredPromise = <T>() => {
	let resolve, reject;

	const promise = new Promise<T>((res, rej) => {
		[resolve, reject] = [res, rej];
	});

	return {
		promise,
		reject: reject as unknown as (reason?: any) => void,
		resolve: resolve as unknown as (value: T | PromiseLike<T>) => void
	};
};
