import { asyncModalAdapter } from 'App/helpers/angularPortingHelpers';
import { Button, Modal, ModalContent, ModalControls, ModalHeader } from '@upsales/components';
import React, { useEffect } from 'react';
import { ModalProps } from 'App/components/Modals/Modals';
import T from 'Components/Helpers/translate';
import UiElements from 'Components/UiElements';
import useSelector from 'App/components/hooks/useSelector';
import type { IntegrationConfig, UiElementConfig } from 'App/resources/AllIWant';
import IconName from '@upsales/components/Icon/IconName';
import StandardIntegrations from 'App/babel/resources/StandardIntegration';

interface AppWidgetModalProps extends ModalProps {
	obj: any;
	title: string;
	name: string;
	size?: string;
	fullscreen?: boolean;
	integrationId?: number;
	logId?: number;
	icon?: string;
}

const AppWidgetModal = (props: AppWidgetModalProps) => {
	const [error, setError] = React.useState<any>(null);
	const [object, setObject] = React.useState<Tools.StandardIntegrationLogData | undefined>();
	const [integrationConfig, setIntegrationConfig] = React.useState<IntegrationConfig | undefined>();
	const [integrationInactive, setIntegrationInactive] = React.useState<boolean>(false);
	const [uiElements, setUiElements] = React.useState<UiElementConfig[]>([]);

	const metadata = useSelector(({ App }) => App.metadata)!;
	const customerId = useSelector(({ App }) => App.customerId);

	useEffect(() => {
		const fetchIntegrationDataFromId = () => {
			const integration = metadata.integrations.active.find(
				integration => integration.id === props.integrationId
			);
			setIntegrationConfig(integration);
		};
		const fetchIntegrationDataFromLogId = async () => {
			try {
				let foundIntegration: IntegrationConfig | undefined;
				const log = await StandardIntegrations.log(customerId).get(props.logId!);
				const logData = log.data[0];
				if (logData) {
					foundIntegration = metadata.integrations.active.find(
						integration => integration.id === logData.integrationId
					);

					setObject(logData);

					if (foundIntegration) {
						setIntegrationConfig(foundIntegration);
					}
				}
				if (!foundIntegration) {
					setIntegrationInactive(true);
				}
			} catch (err) {
				setError(err);
			}
		};
		if (props.integrationId) {
			fetchIntegrationDataFromId();
		}
		if (props.logId) {
			fetchIntegrationDataFromLogId();
		}
	}, [props.logId, props.integrationId]);

	useEffect(() => {
		if (integrationConfig) {
			setUiElements([
				{
					integrationId: integrationConfig.id,
					integrationName: integrationConfig.name,
					type: 'widget',
					name: props.name
				}
			]);
		}
	}, [integrationConfig]);

	return (
		<Modal className={`app-widget-modal ${props.className}`}>
			<ModalHeader
				title={props.title}
				icon={props.icon ? (props.icon as IconName) : undefined}
				onClose={props.close}
			/>
			<ModalContent>
				{error ? (
					<div className="pior ui-element-integration-inactive">
						<p>{T('integration.problemLoadingApp')}</p>
					</div>
				) : null}
				{!error && integrationInactive ? (
					<div className="pior ui-element-integration-inactive">
						<img src="img/empty-briefcase.svg" />
						<h3>{T('event.appIsInactiveTitle')}</h3>
						<p>{T('event.appIsInactiveDescription')}</p>
					</div>
				) : null}
				{!error && !integrationInactive ? (
					<div className="ui-element-widget-modal">
						<UiElements elements={uiElements} object={object} type={'modal'} />
					</div>
				) : null}
			</ModalContent>
			<ModalControls>
				<Button type="link" onClick={close}>
					{T('default.close')}
				</Button>
			</ModalControls>
		</Modal>
	);
};

export const openAppWidgetModal = asyncModalAdapter({
	upModalName: 'appWidget',
	openModalName: 'AppWidgetModal',
	featureFlag: 'REACT_APP_WIDGET_MODAL'
});

export default AppWidgetModal;
