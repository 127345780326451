import { openDrawer } from 'Services/Drawer';
import MailCampaignResource from 'App/resources/MailCampaign';
import history from 'App/pages/routes/history';
import openAgreement from 'App/helpers/openSubscriptionHelper';
import ClientPlanResource from 'App/babel/resources/ClientPlan';
import { openEditAppointment } from 'Components/Modals/Appointment/EditAppointment';

export const TYPES = {
	FORM: 'form',
	FLOW: 'flow',
	EVENT: 'event',
	ASSIGN: 'assign',
	MAILCAMPAIGN: 'mailcampaign',
	ACTIVITY: 'activity',
	APPOINTMENT: 'appointment',
	OPPORTUNITY: 'opportunity',
	EASYBOOKING: 'easybooking',
	PHONECALL: 'phonecall',
	TODO: 'todo',
	REMINDER: 'reminder',
	MAIL: 'mail',
	SUBSCRIPTION_REMINDER: 'subscriptionreminder',
	CLIENT_PLAN: 'clientplan'
};

const maxNameLength = 20;
const truncate = str =>
	typeof str === 'string' && str.length > maxNameLength ? str.substr(0, maxNameLength).trim() + '...' : str;

export const onSourceClick = ({ type, id } = {}, data) => {
	switch (type) {
		case TYPES.FORM:
			return history.push('/form-overview/' + id);
		case TYPES.FLOW:
			return Tools.$state.go('flow', { id });
		case TYPES.EVENT:
			return Tools.$state.go('react-root-editSocialEvent', { id });
		case TYPES.MAILCAMPAIGN:
			return Tools.$state.go('mailCampaign.dashboard', { id });
		case TYPES.ACTIVITY:
			return Tools.$upModal.open('editActivity', { id });
		case TYPES.APPOINTMENT:
			return openEditAppointment({ id });
		case TYPES.OPPORTUNITY:
			return Tools.$upModal.open('editOrder', { id });
		case TYPES.PHONECALL:
			return openDrawer('EditPhonecall', { activity: data });
		case TYPES.TODO:
			return openDrawer('EditTodo', { todo: data });
		case TYPES.SUBSCRIPTION_REMINDER:
			return openAgreement({ agreementId: id });
		case TYPES.CLIENT_PLAN:
			return openDrawer('EditAccountPlan', { clientPlanId: id });
	}
};

export const getSourceEntity = ({ type } = {}) => {
	switch (type) {
		case TYPES.FORM:
			return Tools.$translate('entity.form');
		case TYPES.FLOW:
			return Tools.$translate('flow.flow');
		case TYPES.EVENT:
			return Tools.$translate('entity.event');
		case TYPES.MAILCAMPAIGN:
			return Tools.$translate('entity.mailCampaign');
		case TYPES.TODO:
			return Tools.$translate('entity.todo');
		case TYPES.PHONECALL:
			return Tools.$translate('entity.call');
		case TYPES.ACTIVITY:
			return Tools.$translate('entity.activity');
		case TYPES.APPOINTMENT:
			return Tools.$translate('entity.appointment');
		case TYPES.OPPORTUNITY:
			return Tools.$translate('default.theOpportunity');
		case TYPES.SUBSCRIPTION_REMINDER:
			return Tools.$translate('agreement.theAgreement');
		case TYPES.CLIENT_PLAN:
			return Tools.$translate('accountPlan');
	}
};

export const getSourceResource = ({ type } = {}) => {
	switch (type) {
		case TYPES.FORM:
			return Tools.Form;
		case TYPES.FLOW:
			return Tools.Flow;
		case TYPES.EVENT:
			return Tools.SocialEvents;
		case TYPES.MAILCAMPAIGN:
			return MailCampaignResource;
		case TYPES.TODO:
		case TYPES.ACTIVITY:
		case TYPES.PHONECALL:
			return Tools.Activity.customer(Tools.AppService.getCustomerId());
		case TYPES.APPOINTMENT:
			return Tools.Appointment.customer(Tools.AppService.getCustomerId());
		case TYPES.OPPORTUNITY:
			return Tools.Opportunity;
		case TYPES.SUBSCRIPTION_REMINDER:
			return Tools.Agreement.customer(Tools.AppService.getCustomerId());
		case TYPES.CLIENT_PLAN:
			return ClientPlanResource;
	}
};

export const getSourceName = ({ type } = {}, data) => {
	switch (type) {
		case TYPES.FORM:
		case TYPES.FLOW:
		case TYPES.EVENT:
			return truncate(data.name);
		case TYPES.MAILCAMPAIGN:
			return truncate(data.name || data.subject);
		case TYPES.TODO:
		case TYPES.ACTIVITY:
		case TYPES.APPOINTMENT:
		case TYPES.OPPORTUNITY:
		case TYPES.PHONECALL:
		case TYPES.CLIENT_PLAN:
		case TYPES.SUBSCRIPTION_REMINDER:
			return truncate(data.description);
	}
};
