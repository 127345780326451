import React, { Children, useRef, useEffect, MutableRefObject } from 'react';
// @ts-expect-error
import tippy from 'tippy.js';

const defaultProps = {
	animation: 'none',
	animateFill: false,
	arrow: true,
	arrowSize: 'small',
	duration: 0,
	theme: 'green',
	distance: 20,
	dynamicTitle: true
};

type Props = {
	children: React.ReactElement;
	title?: string;
	placement?: string;
	dynamic?: boolean;
};

function multiRef(...refs: MutableRefObject<Element | null>[]) {
	return (node: Element) => {
		for (const ref of refs) {
			ref.current = node;
		}
	};
}

/**
 * @deprecated
 * This should not be used!
 * We should fix the Tooltip component from the @ui/components package so that
 * it does not add additional dom elements that destroy your layout.....
 *
 * Until that I will use this just so I can finish the porting of the order modal.
 */
const Tooltip = ({ children, title, placement = 'left', dynamic: dynamicTitle = false }: Props) => {
	const tooltipRef = useRef<Element>(null);

	useEffect(() => {
		if (tooltipRef.current) {
			tippy.one(tooltipRef.current, { ...defaultProps, placement, dynamicTitle });
		}
	}, []);

	const child = Children.only(children);

	// @ts-expect-error I have no clue how to type this
	const props = child.ref ? { ref: multiRef(tooltipRef, child.ref), title } : { ref: tooltipRef, title };
	return React.cloneElement(child, props);
};

export default Tooltip;
