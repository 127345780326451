import React from 'react';
import ActivityResource from 'Resources/Activity';
import { DropDownMenu, Icon, Card, Text, Title, Button, Tooltip, IconName } from '@upsales/components';
import T from 'Components/Helpers/translate';
import BemClass from '@upsales/components/Utils/bemClass';
import './TodoListTableRowQuickActions.scss';
import moment from 'moment';
import Todo from 'App/resources/Model/Todo';
import openModal from 'App/services/Modal/Modal';
import { openEditAppointment } from 'Components/Modals/Appointment/EditAppointment';
import type { OnCloseReturnType } from 'App/components/MoveOrCloseActivity/MoveOrCloseActivity';

export const DeleteConfirm = ({
	todo,
	onClose
}: {
	todo: Todo | Omit<Todo, 'hot' | 'weblinkUrl' | 'location'>;
	onClose: () => void;
}) => {
	let entityName = '';
	const ref = React.useRef<HTMLDivElement>(null);
	let deleteFunc: () => void;
	const handleOutsideClick = (e: MouseEvent) => {
		if (!ref.current?.contains(e.target as Node)) {
			onClose();
		}
	};
	React.useEffect(() => {
		document.addEventListener('mousedown', handleOutsideClick);
		return () => {
			document.removeEventListener('mousedown', handleOutsideClick);
		};
	}, []);
	switch (todo.type) {
		case 'todo':
			entityName = T('todo.todo');
			deleteFunc = () => ActivityResource.delete(todo.id);
			break;
		case 'phonecall':
			entityName = T('todo.phoneCall');
			deleteFunc = () => ActivityResource.delete(todo.id);
			break;
		case 'appointment':
			entityName = T('default.appointment');
			deleteFunc = () => Tools.Appointment.customer(Tools.AppService.getCustomerId()).delete({ id: todo.id });
			break;
		case 'legacy':
			entityName = T('default.activity');
			deleteFunc = () => ActivityResource.delete(todo.id);
			break;
	}

	const classes = new BemClass('DeleteConfirm');
	return (
		<div ref={ref} className={classes.b()}>
			<Title bold>{T('admin.modal.deleteThisEntity', { entity: entityName.toLowerCase() })}</Title>
			<Text color="grey-13">{T('admin.modal.actionIrreversible')}</Text>
			<Button
				onClick={(e: React.MouseEvent) => {
					e?.stopPropagation();
					deleteFunc();
					onClose();
				}}
				color="red"
			>{`${T('default.delete')} ${entityName.toLowerCase()}`}</Button>
		</div>
	);
};
const TodoListTableRowQuickActions = ({ todo }: { todo: Todo | Omit<Todo, 'hot' | 'weblinkUrl' | 'location'> }) => {
	const [showDeleteConfirm, setShowDeleteConfirm] = React.useState(false);
	const renderQuickActions = (close: () => void) => {
		const closeActivity = {
			title: T('default.close'),
			icon: 'check' as const,
			onClick: (e: React.MouseEvent) => {
				e?.stopPropagation();
				ActivityResource.save({ id: todo.id, closeDate: new Date() });
				close();
			}
		};
		const postponeActivity = {
			title: T('activity.move'),
			icon: 'calendar-arrow-right-o' as const,
			onClick: (e: React.MouseEvent) => {
				e?.stopPropagation();
				if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_MOVE_OR_CLOSE_ACTIVITY')) {
					openModal('MoveOrCloseActivity', {
						icon: 'long-arrow-right',
						notes: todo.notes,
						description: todo.description,
						isMove: true,
						onClose: (closeData?: OnCloseReturnType) => {
							if (closeData) {
								const data = {
									id: todo.id,
									notes: closeData.notes,
									date: closeData.time ? closeData.date : moment(closeData.date).format('YYYY-MM-DD')
								};
								ActivityResource.save(data);
							}
						}
					});
				} else {
					// eslint-disable-next-line promise/catch-or-return
					Tools.$upModal
						.open('moveOrCloseActivity', {
							icon: 'fa-long-arrow-right',
							id: todo.id,
							notes: todo.notes,
							description: todo.description,
							isMove: true
						})
						.then(function (res) {
							const data = {
								id: todo.id,
								notes: res.notes,
								date: res.time ? res.Date : moment(res.Date).format('YYYY-MM-DD')
							};
							ActivityResource.save(data);
						});
				}
				close();
			}
		};
		const followUpAppointment = {
			title: T('activity.followup'),
			icon: 'calendar-plus-o' as const,
			onClick: (e: React.MouseEvent) => {
				e?.stopPropagation();
				//logic taken from editActivity controller
				let newAppointment = Tools.Appointment.new().data;
				newAppointment = {
					...newAppointment,
					sourceType: 'activity',
					sourceId: todo.id,
					activityType: null,
					notes: todo.notes,
					client: todo.client,
					project: todo.project,
					projectPlan: todo.projectPlan,
					opportunity: todo.opportunity,
					users: [Tools.AppService.getSelf()]
				};
				// eslint-disable-next-line promise/catch-or-return
				openEditAppointment({ appointment: newAppointment }).then(() =>
					Tools.Appointment.customer(Tools.AppService.getCustomerId()).save({
						id: todo.id,
						outcome: 'completed'
					})
				);
			}
		};

		let actions: { title: string; icon: IconName; onClick: (e: React.MouseEvent) => void }[] = [
			{
				title: T('default.delete'),
				icon: 'trash',
				onClick: (e: React.MouseEvent) => {
					e?.stopPropagation();
					setShowDeleteConfirm(true);
					close();
				}
			}
		];
		switch (todo.type) {
			case 'appointment':
				actions = [followUpAppointment, ...actions];
				break;
			case 'phonecall':
			case 'legacy':
				if (todo.closeDate === null) {
					actions = [postponeActivity, closeActivity, ...actions];
				}
				break;
			case 'todo':
				if (todo.closeDate === null) {
					actions = [postponeActivity, ...actions];
				}
				break;
		}
		return actions.map((a, i) => (
			<Card key={i} onClick={a.onClick}>
				<Icon name={a.icon} />
				<Text>{a.title}</Text>
			</Card>
		));
	};
	const classes = new BemClass('TodoListTableRowQuickActions');
	return (
		<div className={classes.b()}>
			<DropDownMenu
				align="right"
				renderTrigger={(expanded, setExpanded) => (
					<Tooltip title={T('todo.showOptions')}>
						<Button onClick={setExpanded} type="link">
							<Icon color="grey-8" name="ellipsis-h" />
						</Button>
					</Tooltip>
				)}
			>
				{close => <div className={classes.elem('dropdown-actions').b()}>{renderQuickActions(close)}</div>}
			</DropDownMenu>
			{showDeleteConfirm ? <DeleteConfirm onClose={() => setShowDeleteConfirm(false)} todo={todo} /> : null}
		</div>
	);
};
export default TodoListTableRowQuickActions;
