import AccountListBoardOfDirectorsCompanyCard from 'Components/Account/AccountListContacts/AccountListBoardOfDirectorsCompanyCard';
import { Drawer, DrawerHeader, Flex, Loader, Paginator, Text } from '@upsales/components';
import { DirectorWithMatch } from 'App/pages/ContactsTab/BoardMembers/BoardMembers';
import RequestBuilder, { comparisonTypes } from 'Resources/RequestBuilder';
import ProspectingClient from 'App/resources/Model/ProspectingClient';
import { useGetData, useIsFirstRender } from 'App/components/hooks';
import { FetcherFunction } from 'App/components/hooks/useGetData';
import { useTranslation } from 'Components/Helpers/translate';
import { ModalProps } from 'App/components/Modals/Modals';
import BemClass from '@upsales/components/Utils/bemClass';
import React, { useEffect, useState } from 'react';
import Prospecting from 'Resources/Prospecting';

import './AdditionalBoardMembers.scss';

type Props = {
	director: DirectorWithMatch;
	prospectingId: string;
	close: () => void;
} & ModalProps;

type FetcherProps = {
	directorProspectingId?: string;
	prospectingId?: string;
};

const LIMIT = 20;

const fetcher: FetcherFunction<FetcherProps, ProspectingClient> = (
	rb: RequestBuilder,
	{ directorProspectingId, prospectingId }: FetcherProps
) => {
	rb.addFilter({ field: 'directors.prospectingId' }, comparisonTypes.Equals, directorProspectingId);
	rb.addFilter({ field: 'headquarters' }, comparisonTypes.Equals, true);
	rb.addFilter({ field: 'prospectingId' }, comparisonTypes.NotEquals, prospectingId);
	rb.addFilter({ field: 'directors.toDate' }, comparisonTypes.Equals, null);
	rb.extraParams.push({
		key: 'country',
		value: Prospecting.getCountryFromProspectingId(prospectingId)
	});

	return Prospecting.find(rb.build());
};

const AdditionalBoardMembers = (props: Props) => {
	const { director, prospectingId, className, close } = props;
	const { t } = useTranslation();
	const [offset, setOffset] = useState(0);
	const classes = new BemClass('AdditionalBoardMembers', className);

	const {
		data: additionalBoardMembers,
		metadata,
		loading,
		refetch
	} = useGetData<FetcherProps, ProspectingClient>({
		fetcher,
		fetcherProps: {
			directorProspectingId: director.prospectingId,
			prospectingId
		},
		limit: LIMIT,
		offset
	});

	const isFirstRender = useIsFirstRender();
	// Refetch directly on offset change
	useEffect(() => {
		// All useEffects are run on mount, only refetch values changes after that
		if (!isFirstRender) {
			refetch();
		}
	}, [offset]);

	const total = metadata?.total || 0;

	return (
		<Drawer className={classes.b()}>
			<DrawerHeader
				title={t('default.boardAssignments', { name: director.firstName ?? director.name })}
				onHide={() => close?.()}
			/>

			{loading ? (
				<Flex alignItems="center" justifyContent="center" space="ptm prm pbxl plm">
					<Loader size="md" />
				</Flex>
			) : additionalBoardMembers.length ? (
				<Flex
					key={'card' + director.orgNumber}
					alignItems={'center'}
					space="ptxl prxl pbxl plxl"
					direction="column"
				>
					{additionalBoardMembers?.map(company => (
						<AccountListBoardOfDirectorsCompanyCard
							key={'card' + company.orgNumber}
							company={company}
							prospectingId={director.prospectingId}
							name={director.firstName || director.name}
							matchInUpsales={company?.matchInUpsales}
							onClickFn={() => close?.()}
						/>
					))}
					{total > LIMIT && !loading ? (
						<Paginator total={total} limit={LIMIT} align="center" offset={offset} onChange={setOffset} />
					) : null}
				</Flex>
			) : (
				<Flex alignItems="center" justifyContent="center" space="ptxl prm pbxl plm">
					<Text size="lg"> {t('account.noAdditionalBoardAssignments')}</Text>
				</Flex>
			)}
		</Drawer>
	);
};

export default AdditionalBoardMembers;
