import { Modal, ModalContent, ModalControls, ModalHeader } from '@upsales/components';
import React from 'react';
import { useTranslation } from 'Components/Helpers/translate';
import { ModalProps } from '../Modals/Modals';

type Props = ModalProps & {
	title: string;
	downloadIds: string[];
	exportUrl: string;
};

export default (props: Props) => {
	const { className, title, close, downloadIds, exportUrl } = props;
	const { t } = useTranslation();
	return (
		<Modal size="sm" className={className}>
			<ModalHeader onClose={() => close()} title={t(title)}></ModalHeader>
			<ModalContent className="ModalContent--no-padding">
				<table className="main-table">
					<tbody>
						{downloadIds.map((id, index) => (
							<tr key={id}>
								<td>
									{t('export.part')} {index + 1}:
									<a href={exportUrl + id} target="_blank">
										{` ${t('noti.export.clickDownload')}`}
									</a>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</ModalContent>
			<ModalControls>
				<button type="submit" className="btn btn-link btn-sm" onClick={() => close()}>
					{t('default.close')}
				</button>
			</ModalControls>
		</Modal>
	);
};
