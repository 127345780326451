import { Flex, Link, Paginator, Table, TableColumn, TableHeader, TableRow, Text } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import { useMetadata } from 'App/components/hooks/appHooks';
import UserStack from 'App/components/UserStack';
import { roundNumber } from 'App/pages/CompanyGroupCard/Sidebar/UpsellWidget/UpsellWidget';
import CustomerPortfolioPotential from 'App/resources/Model/CustomerPortfolioPotential';
import { currencyFormat } from 'Components/Filters/Currencies';
import { useTranslation } from 'Components/Helpers/translate';
import React, { Dispatch, SetStateAction, useMemo } from 'react';
import { DisplayValueType } from '../CustomerPortfolio';

type Props = {
	salesModel: string;
	upsellData: CustomerPortfolioPotential[];
	classes: BemClass;
	valueType: DisplayValueType;
	limit: number;
	setOffset: (val: number) => void;
	offset: number;
	total: number;
	setSort: Dispatch<SetStateAction<{ field: string; asc: boolean }>>;
	sort: { field: string; asc: boolean };
};

const List = (props: Props) => {
	const { salesModel, upsellData, classes, valueType, setOffset, limit, offset, total, setSort, sort } = props;
	const { t } = useTranslation();
	const metadata = useMetadata();
	const currency = metadata?.defaultCurrency || { iso: 'SEK', rate: 1 } || 'SEK';
	const currencyString = metadata?.defaultCurrency.iso || 'SEK';

	// Add this code when ostens PR is merged
	// const hasGrowthOnTheFly = useSoftDeployAccess('GROWTH_ON_THE_FLY');
	// const { hasFullReportAccess } = useSelector(({ App }: RootState) => App);

	// if (hasGrowthOnTheFly && !hasFullReportAccess) {
	// 	if (GROWTH_ATTRIBUTES.includes(col)) {
	// 		attr.sortable = false;
	// 	}
	// }

	const getValueTypeColumn = (valueType: DisplayValueType) => {
		switch (valueType) {
			case 'annualValue':
				return { title: t('accountGrowth.column.arr') };
			case 'monthlyValue':
				return { title: t('accountGrowth.column.mrr') };
			case 'contributionMargin':
				return { title: t('accountGrowth.column.cmLast12months') };
			default:
				return {
					title: t('accountGrowth.column.salesLast12months')
				};
		}
	};

	const columns = useMemo(
		() => [
			{ title: t('default.account'), sort: 'name', sortType: 'string' },
			{
				title:
					valueType === 'contributionMargin'
						? t('default.potential') + ` (${t('default.contributionMarginShort')})`
						: valueType === 'value'
						? t('default.potential')
						: t('default.potential') + ` (${salesModel.toLocaleUpperCase()})`
			},
			{ title: t('default.potential') + ' %' },
			getValueTypeColumn(valueType),
			{ title: t('default.accountManager') }
		],
		[valueType]
	);

	const percentageConverter = (percentage: number) => {
		return Math.round(percentage * 10000) / 100;
	};

	const goToAccount = (id: number) => {
		Tools.$state.go('account.dashboard', { id }, { inherit: false });
	};

	const mapValueTypeToGrowthKeys = () => {
		switch (valueType) {
			case 'annualValue':
			case 'monthlyValue':
				return 'arr';
			case 'contributionMargin':
				return 'cmLast12Months';
			default:
				return 'salesLast12Months';
		}
	};

	const row = upsellData.map((item: CustomerPortfolioPotential) => (
		<TableRow key={item.client.id}>
			<TableColumn className={classes.elem('firstColumn').b()} size="lg">
				<Link onClick={() => goToAccount(item.client.id)}>{item.client.name}</Link>
			</TableColumn>
			<TableColumn size="lg">
				<Flex justifyContent="space-between" alignItems="center">
					<Flex>{currencyFormat(roundNumber(item.takeRatePotential) * currency.rate, currency.iso)}</Flex>
				</Flex>
			</TableColumn>
			<TableColumn>
				<Text>
					{(() => {
						const percentage = percentageConverter(item.takeRatePotentialPercentage);
						return '+' + percentage + '%';
					})()}
				</Text>
			</TableColumn>
			<TableColumn size="lg">
				<Flex justifyContent="space-between" alignItems="center">
					{item.client?.growth ? (
						<Flex>
							{(() => {
								const growthValue = item.client.growth[mapValueTypeToGrowthKeys()];
								return currencyFormat(
									valueType === 'monthlyValue' ? growthValue / 12 : growthValue,
									currencyString
								);
							})()}
						</Flex>
					) : null}
				</Flex>
			</TableColumn>
			<TableColumn size="lg">
				<UserStack users={item.client.users} />
			</TableColumn>
		</TableRow>
	));

	return (
		<Table>
			<TableHeader columns={columns} onSortChange={setSort} sorting={sort.field} asc={sort.asc} />
			{row}
			{total > limit ? (
				<TableRow>
					<TableColumn colSpan={4} size="lg" align="center">
						<Paginator onChange={setOffset} total={total} limit={limit} offset={offset} align="center" />
					</TableColumn>
				</TableRow>
			) : null}
		</Table>
	);
};

export default List;
