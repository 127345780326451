import { openEditCampaignModal } from 'App/components/EditCampaign/EditCampaign';

const createCampaign = customerId => {
	const OPTIONS = {
		customerId,
		noRedirect: true
	};

	return new Promise(resolve => {
		// eslint-disable-next-line promise/catch-or-return
		openEditCampaignModal(OPTIONS).then(campaign => {
			return resolve(campaign);
		});
	});
};

export default createCampaign;
