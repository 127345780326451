import BemClass from '@upsales/components/Utils/bemClass';
import Client from 'App/resources/Client';
import FieldSection from './FieldSection';
import Form, { FormField } from 'App/resources/Model/Form';
import FormSubmit from 'App/resources/Model/FormSubmit';
import FormSubmitResource from 'App/resources/FormSubmit';
import NotificationService from 'App/babel/NotificationService';
import React, { FormEvent, useMemo, useState } from 'react';
import T from 'Components/Helpers/translate';
import getAngularModule from 'App/babel/angularHelpers/getAngularModule';
import logError from 'Helpers/logError';
import openModal from 'App/services/Modal/Modal';
import { Block, Flex, Modal, ModalContent, ModalHeader, ModalControls } from '@upsales/components';
import { ModalProps } from '../Modals/Modals';
import { Tooltip } from '@upsales/components';
import { asyncModalAdapter } from 'App/helpers/angularPortingHelpers';
import { getSelf } from 'Store/selectors/AppSelectors';
import { parseFieldsAndValues, validate } from './helper';
import { useMakePromiseSafe } from 'App/components/hooks';

export type ParsedFieldValue = string | string[] | number | Date | null;
export type Field = Omit<FormField, 'options'> & { options: string | string[] };
type Props = {
	form: Form;
	submit: FormSubmit;
} & ModalProps<Partial<FormSubmit>>;

const EditFormSubmitModal = ({ form, submit, close, className }: Props) => {
	const classNames = new BemClass('EditFormSubmit', className);

	const lang = useMemo(
		() => ({
			editFormSubmit: T('form.editFormSubmit'),
			editFormSubmitInfo: T('form.editFormSubmitInfo'),
			deleteFormSubmit: T('form.deleteFormSubmit'),
			abort: T('default.abort'),
			save: T('default.save'),
			saving: T('default.saving')
		}),
		[]
	);

	const userEmail = getSelf()?.email ?? '';

	const [saving, setSaving] = useState(false);
	const { fields, values: initialValues } = useMemo(() => parseFieldsAndValues(form, submit), []);
	const [values, setValues] = useState(initialValues);
	const valid = useMemo(() => validate(fields, values), [values]);
	const makePromiseSafe = useMakePromiseSafe();

	const save = (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		setSaving(true);

		const fieldValues = Object.entries(values).map(([name, value]) => {
			if (Array.isArray(value)) {
				value = value.join(',');
			}

			return { name, value: value === null || value === undefined ? '' : value.toString() };
		});

		const updatedSubmit = { id: submit.id, fieldValues };

		makePromiseSafe(FormSubmitResource.save(updatedSubmit))
			.then(() => {
				close(updatedSubmit);
			})
			.catch((error: unknown) => {
				logError(error);
				setSaving(false);
			});
	};

	const removeEntry = () => {
		openModal('RemoveAlert', {
			title: 'default.formSubmit',
			body: <div dangerouslySetInnerHTML={{ __html: T('formSubmit.deleteInfo') }} />,
			confirmButtonText: 'form.deleteFormSubmit',
			cancelButtonText: 'form.deleteFormSubmitWithAll',
			onClose: (onlyDeleteSubmit: boolean | undefined) => {
				if (onlyDeleteSubmit === undefined) {
					return;
				}

				if (onlyDeleteSubmit === false && submit.client) {
					const $upModal = getAngularModule('$upModal');
					// eslint-disable-next-line promise/catch-or-return
					$upModal
						.open('errorPrompt', {
							title: 'default.enterEmail',
							body: 'confirm.removeFormSubmitConfirm',
							placeholder: 'default.email',
							required: true,
							type: 'email'
						})
						.then((email: string) => {
							if (email && email === userEmail) {
								close();
								Client.delete(submit.client!.id).catch(logError);
							} else {
								NotificationService.add({
									title: 'login.anErrorOccured',
									body: 'confirm.removeFormSubmitConfirmFail',
									style: 'error',
									icon: 'times'
								});
							}
						});
				} else {
					close();
					FormSubmitResource.delete(submit.id).catch(logError);
				}
			}
		});
	};

	return (
		<Modal className={classNames.b()}>
			<form autoComplete="off" id="edit-form-submit" name="FormSubmit" onSubmit={save}>
				<ModalHeader onClose={close} title={lang.editFormSubmit} />
				<ModalContent>
					<div className="up-panel">
						<div className="alert alert-warning">{lang.editFormSubmitInfo}</div>

						<div className="up-panel-content row">
							{fields.map((field, index) => (
								<div key={index} className="field-wrap">
									<FieldSection
										disabled={saving}
										field={field}
										value={values[field.name] ?? ''}
										onChange={({ target: { value } }: { target: { value: ParsedFieldValue } }) =>
											setValues({ ...values, [field.name]: value })
										}
									/>
								</div>
							))}
						</div>
					</div>
				</ModalContent>
				<ModalControls>
					<Flex justifyContent="space-between">
						<Tooltip distance={24} position="left" title={lang.deleteFormSubmit}>
							<button
								className="btn-lined no-shadow btn up-btn btn-grey btn-hover-red pull-left"
								disabled={saving}
								onClick={removeEntry}
								type="button"
							>
								<i className="fa fa-trash"></i>
							</button>
						</Tooltip>

						<Block>
							<button
								data-testid="submit-button"
								className="btn up-btn btn-bright-blue no-shadow btn-primary-action"
								disabled={saving || !valid}
								type="submit"
							>
								{saving ? <b className="fa fa-refresh a-spin"></b> : null}
								{saving ? lang.saving : lang.save}
							</button>

							<button
								data-testid="abort-button"
								className="btn up-btn btn-grey btn-link"
								disabled={saving}
								onClick={() => close()}
								type="button"
							>
								{lang.abort}
							</button>
						</Block>
					</Flex>
				</ModalControls>
			</form>
		</Modal>
	);
};

export const openEditFormSubmitModal = asyncModalAdapter({
	featureFlag: 'EDIT_FORM_SUBMIT_REACT',
	openModalName: 'EditFormSubmit',
	upModalName: 'editFormSubmit',
	rejectOnEmpty: true,
	rejectOnEvent: true
});

export default EditFormSubmitModal;
