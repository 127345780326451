import CloseOpportunityModal from 'App/babel/components/Salesboard/CloseOpportunityModal';
import Comment from 'Resources/Comment';
import OnOrderEditHelpers from 'App/helpers/onOrderEditHelpers';
import ProductResource from 'Resources/Product';
import React, { useMemo } from 'react';
import SalesCoachResource from 'Resources/SalesCoach';
import T from 'Components/Helpers/translate';
import ValidationService from 'Services/ValidationService';
import _ from 'lodash';
import documentResource from 'Resources/document';
import getAngularModule from 'App/babel/angularHelpers/getAngularModule';
import getOrderChanges from 'App/helpers/getOrderChanges';
import getSpeedTracker from 'App/helpers/speedTracker';
import logError from 'App/babel/helpers/logError';
import moment from 'moment';
import openModal from 'App/services/Modal';
import pdfTemplateResource from 'Resources/pdfTemplates';
import { bundleUpdateHandler } from 'App/components/OrderRows/Context/OrderContextHelpers';
import { calculateField } from '@upsales/common';
import { checkValidityOrder as checkIfPeriodizationIsValid } from 'Store/reducers/PeriodizationReducer';
import { getAllActiveAgreements, getRecurringProductDefaultInterval } from 'App/helpers/subscriptionHelper';
import { getCMWithRROption, hasRRWithCM } from 'App/helpers/salesModelHelpers';
import { getOrderForecastingRisk, ForecastingRiskLevel } from 'App/enum/ForecastingRisks';
import { getPlainObject, getPlainArray } from './useDraftableStateStore';
import { getRelatedClientIds, binaryGroup } from 'App/helpers/accountsHelper';
import {
	getTierFromOrderRow,
	getTierPriceByCurrency,
	isTieredOrderRow,
	isTieredTotalOrderRow
} from 'App/babel/helpers/order';
import { makeCancelable } from '@upsales/components/Utils/CancelablePromise';
import { openAccountRelationModal } from 'Components/Account/AccountRelationModal/AccountRelationModal';
import { openGenericModal } from 'App/components/GenericModal/GenericModal';
import { openNewMailWithContact } from 'App/helpers/mailHelpers';
import { openPreviewPdfModal } from 'Components/Admin/DocumentTemplate/PreviewPdf/PreviewPdf';
import {
	resetAndGetBundleOrderRow,
	getMaxDiscount,
	getRecurringValue
} from 'App/components/OrderRows/Context/OrderContextHelpers';
import { useAppDispatch } from 'App/components/hooks';
// @ts-expect-error
import tippy from 'tippy.js';

import type Contact from 'App/resources/Model/Contact';
import type Order from 'App/resources/Model/Order';
import type PriceList from 'App/resources/Model/PriceList';
import type Product from 'App/resources/Model/Product';
import type SalesCoach from 'App/resources/Model/SalesCoach';
import type Stage from 'App/resources/Model/OrderStage';
import type { BasicUserWithPermissions as User } from 'App/resources/Model/User';
import type { CancelablePromise } from 'Helpers/promise';
import type { Currency, StandardFieldConfig } from 'App/resources/AllIWant';
import type { OrderRow } from 'App/components/OrderRows/Context/OrderContext';
import type { ProjectIdName } from 'App/resources/Model/Project';
import type { ProjectPlanOption } from 'App/resources/Model/Order';
import type { default as Client, ConnectedClient } from 'App/resources/Model/Client';
import type {
	ActivityEvent,
	AppointmentEvent,
	EditOrderClient,
	EditOrderContact,
	EditOrderCustomField,
	EditOrderDraftableStateStore,
	EditOrderOrder,
	EditOrderOrderRow,
	File,
	Form,
	Meta,
	ModalParams,
	ModifiedEvent,
	Reject,
	Resolve,
	StakeHolder,
	State,
	TierResponse,
	TitleCategory,
	Translation
} from '../types';

function transformFieldsToLowerCase(fields: any): any {
	try {
		return Object.fromEntries(
			Object.entries(fields).map(([key, value]) => {
				if (typeof value === 'object') {
					return [key.toLowerCase(), transformFieldsToLowerCase(value)];
				}
				return [key.toLowerCase(), value];
			})
		);
	} catch (err) {
		logError(err, 'OnOrderEdit transformFieldsToLowerCase', fields);
		return fields;
	}
}

function uniqueIdFactory(start = 1) {
	return () => start++;
}

// Stupid typegaurd as the getMeta send such WIERD data...
function isContacts(object: undefined | {} | { data: EditOrderContact[] }): object is { data: EditOrderContact[] } {
	return object?.hasOwnProperty('data') ?? false;
}

// Stupid typegaurd as the getMeta send such WIERD data...
function isContact(object: undefined | {} | { data: EditOrderContact }): object is { data: EditOrderContact } {
	return object?.hasOwnProperty('data') ?? false;
}

type ObjectWithCustomFields = { custom: EditOrderCustomField[]; $mappedCustom: Record<number, EditOrderCustomField> };

const useEditOrderLogic = (
	store: EditOrderDraftableStateStore,
	modalId: number,
	modalParams: ModalParams,
	meta: Meta,
	resolve: Resolve,
	reject: Reject
) => {
	const { getState, getAutoDraftState } = store;
	const dispatch = useAppDispatch();

	return useMemo(() => {
		const $q = getAngularModule('$q');
		const $rootScope = getAngularModule('$rootScope');
		const $state = getAngularModule('$state');
		const $translate = getAngularModule('$translate');
		const $upModal = getAngularModule('$upModal');
		const Account = getAngularModule('Account');
		const Activity = getAngularModule('Activity');
		const AppService = getAngularModule('AppService');
		const Appointment = getAngularModule('Appointment');
		const Contact = getAngularModule('Contact');
		const Esign = getAngularModule('Esign');
		const EventService = getAngularModule('EventService');
		const FeatureHelper = getAngularModule('FeatureHelper');
		const File = getAngularModule('File');
		const Links = getAngularModule('Links');
		const MapHelpers = getAngularModule('MapHelpers');
		const NotificationService = getAngularModule('NotificationService');
		const Order = getAngularModule('Order');
		const RequestBuilder = getAngularModule('RequestBuilder');
		const StandardIntegration = getAngularModule('StandardIntegration');
		const accountRelations: any = getAngularModule('accountRelations');
		const selectHelper: any = getAngularModule('selectHelper');
		const utils: any = getAngularModule('utils');

		///////////////////// STATICS /////////////////////

		const metadata = AppService.getMetadata();
		const self = AppService.getSelf();
		const productCategories = AppService.getProductCategories();
		const customerId = AppService.getCustomerId();
		const integrations = AppService.getEsignIntegrations();
		const recurringIntervals = AppService.getStaticValues('recurringInterval');
		const getUniqueId = uniqueIdFactory(Date.now());
		const hasMultiCurrency = !!metadata.params.MultiCurrency;

		function getDataFunction<K extends keyof PickOfType<State, any[]>>(key: K) {
			return function query({
				term,
				matcher,
				callback
			}: {
				term: string;
				matcher: (term: string, _: undefined, item: State[K][number]) => boolean;
				callback: (data: { results: State[K] }) => void;
			}) {
				const state: State = getState();
				const data = (state[key] ?? []) as State[K];

				if (term === '') {
					callback({ results: data });
				} else {
					// Works without the any in VSCode but not when tsc is run...
					const filtered = (data as any).filter((item: State[K][0]) =>
						matcher(term, undefined, item)
					) as State[K];
					callback({ results: filtered });
				}
			};
		}

		const selectConfigs = {
			user: {
				formatSelection: (user: User, container: JQuery, encode: (text: string) => string) =>
					encode(_.property<User, string>('name')(user)),
				formatResult: (user: User, container: JQuery, query: unknown, escape: (text: string) => string) =>
					escape(_.property<User, string>('name')(user)),
				data: meta.users.data,
				matcher: function (term: string, undef: undefined, user: User) {
					return user.name.toUpperCase().indexOf(term.toUpperCase()) >= 0;
				}
			},
			currency: {
				formatSelection: (currency: Currency, container: JQuery, escape: (text: string) => string) =>
					escape(_.property<Currency, string>('iso')(currency)),
				formatResult: (
					currency: Currency,
					container: JQuery,
					query: unknown,
					escape: (text: string) => string
				) => escape(_.property<Currency, string>('iso')(currency)),
				data: metadata.customerCurrencies.filter(currency => currency.active),
				id: 'iso',
				matcher: function (term: string, undef: undefined, currency: Currency) {
					return currency.iso.toUpperCase().indexOf(term.toUpperCase()) >= 0;
				}
			},
			stage: {
				formatResult: function (
					stage: Stage,
					container: JQuery,
					query: unknown,
					escape: (text: string | number) => string
				) {
					return (
						escape(stage.name) + ' <span class="pull-right grey">' + escape(stage.probability) + '%</span>'
					);
				},
				formatSelection: function (stage: Stage, container: JQuery, escape: (text: string | number) => string) {
					return (
						escape(stage.name) +
						' <span style="font-size: 12px; margin-left: 5px;" class="upsales-bright-blue up-opacity-60">' +
						escape(stage.probability) +
						'%</span>'
					);
				},
				query: getDataFunction('stages')
			},
			recurringInterval: {
				data: recurringIntervals,
				initSelection: function (
					element: JQuery,
					callback: (selectedInterval: { id: string; name: string } | undefined) => void
				) {
					const recurringInterval = element.val();
					const selectedInterval = recurringIntervals.find(interval => interval.id === recurringInterval);
					callback(selectedInterval);
				}
			},
			salesCoaches: {
				formatSelection: (salesCoach: SalesCoach, container: JQuery, encode: (text: string) => string) =>
					encode(salesCoach.name),
				formatResult: (
					salesCoach: SalesCoach,
					container: JQuery,
					query: unknown,
					escape: (text: string) => string
				) => escape(salesCoach.name),
				query: getDataFunction('availableSalescoaches'),
				matcher: function (term: string, undef: undefined, salesCoach: SalesCoach) {
					return salesCoach.name.toUpperCase().indexOf(term.toUpperCase()) >= 0;
				}
			},
			priceLists: {
				formatSelection: (priceList: PriceList, container: JQuery, encode: (text: string) => string) =>
					encode(priceList.name),
				formatResult: (
					priceList: PriceList,
					container: JQuery,
					query: unknown,
					escape: (text: string) => string
				) => escape(priceList.name),
				query: getDataFunction('priceLists'),
				matcher: function (term: string, undef: undefined, priceList: PriceList) {
					return priceList.name.toUpperCase().indexOf(term.toUpperCase()) >= 0;
				}
			},
			relatedClients: {
				allowClear: 1,
				formatSelection: (obj: Client, container: JQuery, encode: (text: string) => string) =>
					encode(_.property<Client, string>('name')(obj)),
				formatResult: (obj: Client, container: JQuery, query: unknown, escape: (text: string) => string) =>
					escape(_.property<Client, string>('name')(obj)),
				query: getDataFunction('relatedClients'),
				matcher: function (term: string, undef: undefined, client: Client) {
					return client.name.toUpperCase().indexOf(term.toUpperCase()) === 0;
				}
			},
			contact: {
				query: getDataFunction('contacts'),
				formatSelection: selectHelper.wrapFormatSelectionLink(
					function (contact: Contact) {
						const state = getState();

						return utils.select2.clientContactsAndRelations.formatSelection(
							contact,
							state.order.client ? state.order.client.id : null
						);
					},
					function (contact: Contact) {
						$state.go('contact.dashboard', { id: contact.id });
					}
				),
				formatResult: function (contact: Contact) {
					const state = getState();

					if (!contact.id) {
						return '<i class="grey">' + $translate(contact.name) + '</i>';
					}

					return utils.select2.clientContactsAndRelations.formatResult(
						contact,
						state.order.client ? state.order.client.id : null
					);
				},
				minimumResultsForSearch: 8,
				allowClear: true,
				matcher: function (term: string, undef: undefined, contact: Contact) {
					return contact.name.toUpperCase().indexOf(term.toUpperCase()) >= 0;
				}
			},
			contactAjax: {
				data: [],
				formatSelection: selectHelper.wrapFormatSelectionLink(
					function (contact: Contact) {
						const state = getState();

						return utils.select2.clientContactsAndRelations.formatSelection(
							contact,
							state.order.client ? state.order.client.id : null
						);
					},
					function (contact: Contact) {
						$state.go('contact.dashboard', { id: contact.id });
					}
				),
				formatResult: function (contact: Contact) {
					const state = getState();

					if (!contact.id) {
						return '<i class="grey">' + $translate(contact.name) + '</i>';
					}

					return utils.select2.clientContactsAndRelations.formatResult(
						contact,
						state.order.client ? state.order.client.id : null
					);
				},
				minimumResultsForSearch: 8,
				minimumInputLength: 1,
				allowClear: true,
				matcher: function (term: string, undef: undefined, contact: Contact) {
					return contact.name.toUpperCase().indexOf(term.toUpperCase()) >= 0;
				},
				ajax: {
					data: function (term: string) {
						return term;
					},
					transport: function (query: { data: string; success: (res: { data: Contact[] }) => void }) {
						const state = getState();

						if (query.data && state.order.client) {
							const clientIds = getClientIds();

							// @ts-expect-error
							const contactFilter = new RequestBuilder();
							contactFilter.addFilter(
								Contact.attr.name,
								contactFilter.comparisonTypes.Search,
								query.data
							);
							contactFilter.addFilter(
								Contact.attr.client.attr.id,
								contactFilter.comparisonTypes.Equals,
								clientIds
							);

							return Contact.customer(customerId)
								.find(contactFilter.build())
								.then(res => {
									const data = groupContacts(res.data, false);
									return query.success({ data });
								});
						}

						return query.success({ data: [] });
					},
					results: function (res: { data: Contact[] }) {
						return { results: res.data };
					}
				}
			}
		} as const;

		///////////////////// ACTIONS /////////////////////

		const actions = {
			setCustomFieldValue: (
				object: ObjectWithCustomFields,
				fieldId: number,
				value: EditOrderCustomField['value']
			) => {
				const customField = object.custom.find(customField => customField.id === fieldId);

				if (customField) {
					customField.value = value;
				} else {
					console.debug('Could not find custom field with id', fieldId, getPlainObject(object.custom));
				}

				const mappedCustomField = object.$mappedCustom[fieldId];

				if (mappedCustomField) {
					mappedCustomField.value = value;
				} else {
					console.debug(
						'Could not find mapped custom field with id',
						fieldId,
						getPlainObject(object.$mappedCustom)
					);
				}
			},
			isDisabledField: (
				fieldPath: string,
				isDisabled?: boolean,
				rowFieldPath?: string,
				rowSortFieldPath?: string
			) => {
				const state = getState();

				if (state.hasAppValidationWithOrderEdit) {
					return isDisabledFieldAppMaster(fieldPath, isDisabled, rowFieldPath, rowSortFieldPath);
				}

				if (isDisabled) {
					return true;
				}

				const formattedFieldPath = fieldPath.replaceAll(' ', '').toLowerCase();
				const fieldDisabled = _.get(state.disabledFields, formattedFieldPath, false);
				if (fieldDisabled || !rowFieldPath) {
					return fieldDisabled;
				}

				const formattedRowFieldPath = rowFieldPath.replaceAll(' ', '').toLowerCase();
				const formattedRowSortFieldPath = rowSortFieldPath?.replaceAll(' ', '').toLowerCase() ?? '';
				const rowFieldDisabled =
					_.get(state.disabledFields, formattedRowFieldPath, false) ||
					_.get(state.disabledFields, formattedRowSortFieldPath, false);

				return rowFieldDisabled;
			},
			isVisibleField: (
				fieldPath: string,
				isVisible?: boolean,
				rowFieldPath?: string,
				isHiddenByProductCategory?: boolean,
				rowSortFieldPath?: string
			) => {
				const state = getState();

				if (isHiddenByProductCategory) {
					return false;
				}

				const defaultIsVisible = isVisible === undefined ? true : isVisible;
				const formattedFieldPath = fieldPath.replaceAll(' ', '').toLowerCase();
				const fieldVisible = _.get(state.visibleFields, formattedFieldPath);
				const hasVisibleStateForField = fieldVisible !== undefined;

				if (hasVisibleStateForField) {
					return fieldVisible;
				}

				if (!rowFieldPath) {
					return defaultIsVisible;
				}

				const formattedRowFieldPath = rowFieldPath.replaceAll(' ', '').toLowerCase();
				const formattedRowSortFieldPath = rowSortFieldPath?.replaceAll?.(' ', '')?.toLowerCase() ?? '';
				const rowFieldVisible = _.get(state.visibleFields, formattedRowFieldPath, undefined);
				const rowSortFieldVisible = _.get(state.visibleFields, formattedRowSortFieldPath, undefined);

				if (rowFieldVisible !== undefined) {
					return rowFieldVisible;
				}

				if (rowSortFieldVisible !== undefined) {
					return rowSortFieldVisible;
				}

				return defaultIsVisible;
			},
			goToAccountAddresses: () => {
				const state = getState();

				$state.go('account.addresses', { id: state.account.id, customerId });
			},
			goToAccountDashboard: () => {
				const state = getState();

				if (state.order.client) {
					$state.go('account.dashboard', { id: state.order.client.id, customerId });
				}
			},
			customFieldChanged: (fieldId: number, value: EditOrderCustomField['value']) => {
				const state = getAutoDraftState();

				actions.setCustomFieldValue(state.order, fieldId, value);
			},
			orderRowCustomFieldChange: (
				orderRowIndex: number,
				fieldId: number,
				value: EditOrderCustomField['value']
			) => {
				const state = getAutoDraftState();

				const orderRow = state.order.orderRow[orderRowIndex];
				actions.setCustomFieldValue(orderRow, fieldId, value);
			},
			projectChanged: (project: ProjectIdName | null) => {
				const state = getAutoDraftState();

				state.order.project = project;
			},
			insertSign: (event: React.MouseEvent<HTMLButtonElement>, element: HTMLTextAreaElement) => {
				const state = getAutoDraftState();

				event.preventDefault();
				event.stopPropagation();

				const dateTimeStr = moment().format('L LT');
				const signature = `${self.name} ${dateTimeStr}:\n`;
				const position = signature.length;

				state.order.notes = `${signature}\n\n${state.order.notes || ''}`;

				element.focus();
				element.scrollTop = 0;

				setTimeout(function () {
					element.setSelectionRange(position, position);
				}, 1);
			},
			notesChanged: (event: React.ChangeEvent<HTMLTextAreaElement>) => {
				const state = getAutoDraftState();

				state.order.notes = event.target.value;
			},
			descriptionChanged: (event: React.ChangeEvent<HTMLInputElement>) => {
				const state = getAutoDraftState();

				state.order.description = event.target.value;
			},
			probabilityChanged: (event: ModifiedEvent<HTMLInputElement, number>) => {
				const state = getAutoDraftState();

				const probability = event.target.value;
				state.order.probability = probability ?? 0;
			},
			dateChanged: (event: { target: { value: Date } }) => {
				const state = getAutoDraftState();

				state.order.date = event.target.value;
			},
			validateEmail: (mail: string | undefined) => {
				if (!mail?.length) {
					return true;
				}

				return ValidationService.validEmail(mail);
			},
			getPastDate: () => {
				const state = getState();

				if (!actions.oldDateCondition(false, true)) {
					return null;
				}

				return state.order.date;
			},
			onWinLoss: async (stage: Stage, date?: Date) => {
				const state = getAutoDraftState();

				if (date) {
					state.order.date = date;
				}

				const oldStage = { ...state.order.stage };

				if (state.hasOrderCustomStages) {
					actions.stageSelectChanged(stage);

					if (!actions.validateRequiredFields(stage)) {
						return;
					}
				}

				state.savedThroughWinLossFlow = true;

				const shouldOpenNewModal = actions.wonOpportunityWithSubs();

				if (shouldOpenNewModal && stage.probability === 100) {
					const activeAgreementGroups = getPlainArray(state.activeAgreementGroups);
					const order = getPlainObject(state.order) as unknown as Order;

					const props = {
						order,
						client: order.client,
						currentStage: oldStage.name,
						activeAgreementGroups
					};

					openModal('WonOpportunityWithSubs', {
						...props,
						openWonModalFollowedByDiffOrder: () => {
							const state = getAutoDraftState();

							state.skipButtonOnWonModal = true;
							state.visibleWon = true;

							setTimeout(() => {
								const state = getState();
								$upModal.open('editOrder', { id: state.order.id });
							}, 2500);
						},
						saveWithStageAndOpenWonModal: async (stage: Stage) => {
							const preSaveState = getAutoDraftState();
							preSaveState.skipButtonOnWonModal = true;
							preSaveState.order.stage = stage;
							preSaveState.order.probability = stage.probability;

							await actions.save(true, true, true);

							const afterSaveState = getAutoDraftState();
							afterSaveState.visibleWon = true;
						},
						cancel: () => {
							const state = getAutoDraftState();

							actions.stageSelectChanged(oldStage);
							state.savedThroughWinLossFlow = false;
						}
					});
				} else {
					state.order.stage = stage;
					state.order.probability = stage.probability;

					await actions.save(true, true, true);

					const afterSaveState = getAutoDraftState();

					if (!afterSaveState.saveError) {
						if (stage.probability === 100) {
							afterSaveState.visibleWon = true;
						} else {
							afterSaveState.visibleLost = true;
						}
					}
				}
			},
			changeOrderCloseDate: (date: Date) => {
				const state = getAutoDraftState();

				state.order.closeDate = date;
				return actions.save(true, true, true);
			},
			changeInvoiceRelatedClient: (value: any) => {
				const state = getAutoDraftState();

				state.order.invoiceRelatedClient = value;
			},
			saveLostReason: (lostReason: any, competitorId: any) => {
				const state = getAutoDraftState();

				state.order.lostReason = lostReason;
				state.order.competitorId = competitorId;

				return actions.save(true, true, true);
			},
			quantityPriceTitle: () => {
				let quantityPriceTitle = '';

				const quantityIsVisible = actions.isVisibleField('orderrow.quantity');
				const priceIsVisible = actions.isVisibleField('orderrow.price');

				if (quantityIsVisible) {
					quantityPriceTitle += $translate('default.quantity');
				}

				if (quantityIsVisible && priceIsVisible) {
					quantityPriceTitle += ' / ';
				}

				if (priceIsVisible) {
					quantityPriceTitle += `${$translate('default.price')} (${$translate('default.netAmount')})`;
				}

				return quantityPriceTitle;
			},
			discountTitle: () => {
				const discountVisible = actions.isVisibleField('orderrow.discount');
				const discountPercentVisible = actions.isVisibleField('orderrow.discountpercent');
				const showTitle = discountVisible || discountPercentVisible;
				return showTitle ? $translate('default.discount') : '';
			},
			openEditProductBundle: (orderRowIndex: number) => {
				const state = getState();
				const { currency, orderRow, recurringInterval, priceListId } = state.order;
				const client = getPlainObject(state.order.client!);

				// Add uuid as it is required by OrderContext
				const editProductBundleProps = {
					uuid: orderRowIndex,
					isAgreement: false,
					order: {
						client,
						priceListId,
						currency,
						recurringInterval,
						orderRow: _.cloneDeep(orderRow).map((orderRow, index) => ({
							...orderRow,
							uuid: index, // They do have uuid now, but I will keep it like this for now
							priceListId,
							tierQuantity: orderRow.tierQuantity ?? orderRow.quantity,
							quantity:
								isTieredTotalOrderRow(orderRow) && orderRow.quantity !== 0 ? 1 : orderRow.quantity, // Should not have to do this but this controller sets quantity === tierQuantity....
							bundleRows: orderRow.bundleRows?.map((bundleRow, index) => ({
								...bundleRow,
								uuid: index,
								priceListId,
								latestDiscountChange: 'percent'
							}))
						}))
					},
					save: (product: Product) => {
						const state = getAutoDraftState();

						const orderRow = state.order.orderRow[orderRowIndex];

						orderRow.discount = orderRow.$discount!;
						orderRow.discountPercent = orderRow.$discountPercent;

						const updatedOrderRow = bundleUpdateHandler(
							{ orderInterval: recurringInterval! },
							orderRow as OrderRow,
							product,
							currency
						) as EditOrderOrderRow;

						editProductBundleSave(orderRowIndex, updatedOrderRow);
					}
				};

				// @ts-expect-error It expects an Order but we only pick the required properties here
				openModal('EditProductBundleOrder', editProductBundleProps);
			},
			onSave: () => {
				actions.save(false, false, true);
			},
			onSaveAndContinue: () => {
				actions.save(true, false, true);
			},
			showWinLossButtons: () => {
				const state = getState();

				return state.editable &&
					state.order.id &&
					!state.isOrder &&
					state.customerHasNewFields &&
					metadata.standardFields?.Order.OpportunityWonLost?.active
					? true
					: false;
			},
			calc: {
				totalGross: function () {
					const state = getState();

					const orderRows = state.order.orderRow as EditOrderOrderRow[];
					return orderRows.reduce((prev, current) => {
						const price = current.price > current.$listPrice ? current.price : current.$listPrice;

						if (isTieredTotalOrderRow(current)) {
							return prev + price * 1;
						} else {
							return prev + price * current.quantity;
						}
					}, 0);
				},
				totalDiscount: function () {
					const state = getState();

					const orderRows = state.order.orderRow as EditOrderOrderRow[];
					return orderRows.reduce(function (prev, current) {
						// @ts-expect-error parseFloat returns the number if you pass a number
						const val = prev + parseFloat(current.$discountRaw);
						return val;
					}, 0);
				},
				totalNet: function () {
					const state = getState();

					const orderRows = state.order.orderRow as EditOrderOrderRow[];
					return orderRows.reduce(function (prev: number, current) {
						return (
							prev +
							(isTieredTotalOrderRow(current) ? current.price * 1 : current.price * current.quantity)
						);
					}, 0);
				},
				totalContributionMargin: function () {
					const state = getState();

					const orderRows = state.order.orderRow as EditOrderOrderRow[];
					return orderRows.reduce(function (val, row) {
						if (!row) {
							return val;
						}

						if (isTieredTotalOrderRow(row)) {
							return val + ((row.price || 0) - (row.purchaseCost || 0) * row.quantity);
						} else {
							return val + ((row.price || 0) - (row.purchaseCost || 0)) * row.quantity;
						}
					}, 0);
				},
				totalContributionMarginPercentage: () => {
					const totalNet = actions.calc.totalNet();

					if (!totalNet || Math.abs(totalNet) < 1e-10) {
						return 0;
					}
					const per = actions.calc.totalContributionMargin() / totalNet;
					return !isNaN(per) ? (per * 100).toFixed(2) : 0;
				},
				convertedNet: function () {
					const state = getState();

					return this.totalNet() * (1 / state.order.currencyRate);
				},
				totalRR: function () {
					const state = getState();

					const orderRows = state.order.orderRow as EditOrderOrderRow[];
					return orderRows.reduce(function (val, row) {
						if (!row || !row.product || !row.product.isRecurring) {
							return val;
						}

						const monthlyValue = (row.price || 0) / state.order.recurringInterval;
						const annualValue = monthlyValue * 12;

						const value = state.salesModelOption === 'mrr' ? monthlyValue : annualValue;
						const quantity = isTieredTotalOrderRow(row) ? 1 : row.quantity;

						return val + (value || 0) * quantity;
					}, 0);
				},
				totalOneOff: function () {
					const state = getState();

					const orderRows = state.order.orderRow as EditOrderOrderRow[];
					return orderRows.reduce(function (val, row) {
						if (!row || !row.product || row.product.isRecurring) {
							return val;
						}

						const quantity = isTieredTotalOrderRow(row) ? 1 : row.quantity;

						return val + (row.price || 0) * quantity;
					}, 0);
				},
				productChange: function (orderRowIndex: number, product: Product) {
					const state = getAutoDraftState();

					const orderRow = state.order.orderRow[orderRowIndex];
					orderRow.product = product;

					productChange(orderRow);
				},
				discountChange: function (orderRowIndex: number, discount: number) {
					const state = getAutoDraftState();

					const orderRow = state.order.orderRow[orderRowIndex];
					orderRow.$discount = discount;

					discountChange(orderRow);
				},
				discountPercentChange: function (orderRowIndex: number, discountPercent: number) {
					const state = getAutoDraftState();

					const orderRow = state.order.orderRow[orderRowIndex];
					orderRow.$discountPercent = discountPercent;

					discountPercentChange(orderRow);
				},
				quantityChange: function (orderRowIndex: number, quantity: number) {
					const state = getAutoDraftState();

					const orderRow = state.order.orderRow[orderRowIndex];
					orderRow.quantity = quantity;

					quantityChange(orderRow);
				},
				priceChange: function (orderRowIndex: number, price: number) {
					const state = getAutoDraftState();

					const orderRow = state.order.orderRow[orderRowIndex];
					orderRow.price = price;

					priceChange(orderRow);
				},
				purchaseCostChange: function (orderRowIndex: number, purchaseCost: number) {
					const state = getAutoDraftState();

					const orderRow = state.order.orderRow[orderRowIndex];
					orderRow.purchaseCost = purchaseCost;
				}
			},
			uiElementUtils: {
				updateObjectCustom: (customArray: EditOrderCustomField[]) => {
					const state = getAutoDraftState();

					state.order.custom = customArray;
					actions.updateCustomFieldRequiredness();
				},
				addOrderRow: (productId: number, quantity: number, price: number, listPrice: number) => {
					const state = getState();

					const row = getNewOrderRow();

					if (productId) {
						const product = _.find(state.orderProducts, { id: productId });
						if (product) {
							row.product = product;
						}
					}

					if (quantity) {
						row.quantity = quantity;
					}

					if (price) {
						row.price = price;
					}

					if (listPrice) {
						row.listPrice = listPrice;
					}

					actions.addOrderRow(row);
					productChange(row, !!quantity);
				},
				updateOrderRowPrice: (orderRowIndex: number, price: number) => {
					const state = getAutoDraftState();

					const row = state.order.orderRow[orderRowIndex];
					row.price = price;

					priceChange(row);
				},
				updateOrderRowListPrice: (orderRowIndex: number, listPrice: number) => {
					const state = getAutoDraftState();

					const row = state.order.orderRow[orderRowIndex];
					row.listPrice = listPrice;
					row.$listPrice = listPrice;
					row.$discount = listPrice - row.price;

					priceChange(row);
					discountChange(row);
				},
				updateOrderRowProduct: (orderRowIndex: number, productId: number) => {
					const state = getAutoDraftState();

					const row = state.order.orderRow[orderRowIndex];
					const product = _.find(state.orderProducts, { id: productId });

					if (product) {
						row.product = product;
					}

					productChange(row);
				},
				setOrderRowCustomFieldValue: (orderRowIndex: number, mappedCustom: EditOrderCustomField[]) => {
					const state = getAutoDraftState();

					const row = state.order.orderRow[orderRowIndex];
					row.$mappedCustom = mappedCustom;
					actions.updateCustomFieldRequiredness();
				},
				updateOrderFromApp: (fieldActions: any, order?: EditOrderOrder) => {
					//const state = getAutoDraftState();
					//const updatedOrder = !order || _.isEmpty(order) ? state.order : order;
					//updateOrderFromApp(fieldActions, state.order, updatedOrder);
				}
			},
			setCreateData: (createData: { type: string; description: string; count?: number }) => {
				const state = getAutoDraftState();

				createData.count = _.get(state.createData, 'count', 0) + 1;
				state.createData = createData;
			},
			togglePeriodization: (saveFirst?: boolean) => {
				const state = getAutoDraftState();

				if (!saveFirst) {
					state.isPeriodizing = !state.isPeriodizing;
				} else {
					actions
						.save(true, false, true)
						.then(function () {
							const state = getAutoDraftState();

							state.isPeriodizing = !state.isPeriodizing;
						})
						.catch((err: unknown) => {
							if (err instanceof Error) {
								logError(err);
							}
						});
				}
			},
			savePeriodization: () => {
				actions.togglePeriodization();
			},
			removePeriodization: () => {
				actions.togglePeriodization();
			},
			saveDecisionMakers: (isTitleCategories: boolean) => {
				const state = getState();

				const data = isTitleCategories
					? {
							id: state.order.id,
							titleCategories: getPlainArray(state.order.titleCategories),
							skipTriggers: true
					  }
					: { id: state.order.id, stakeholders: getPlainArray(state.order.stakeholders), skipTriggers: true };

				Order.customer(customerId).save(data, { skipNotification: true });
			},
			updateStakeholders: (stakeholders: StakeHolder[]) => {
				const state = getAutoDraftState();

				state.order.stakeholders = stakeholders;

				if (state.order.id && state.order.salesCoach) {
					actions.saveDecisionMakers(false);
				}
			},
			updateTitleCategories: (titleCategories: TitleCategory[]) => {
				const state = getAutoDraftState();

				state.order.titleCategories = titleCategories;

				if (state.order.id && state.order.salesCoach) {
					actions.saveDecisionMakers(true);
				}
			},
			isPeriodized: () => {
				const state = getState();

				return !!state.order.periodization;
			},
			closeModal: (position: string = 'top') => {
				const state = getAutoDraftState();

				if (state.tabs.isActive('form') && state.OrderForm?.$dirty) {
					state.showInlineAction = position;
				} else {
					state.showInlineAction = 'none';
					reject();
				}
			},
			rejectChanges: () => {
				actions.setFormPrisitine();
				reject();
			},
			saveChanges: () => {
				const state = getAutoDraftState();

				actions.save(false, false, true);
				state.showInlineAction = 'none';
			},
			closeInlineAction: () => {
				const state = getAutoDraftState();
				state.showInlineAction = 'none';
			},
			isOldDate: () => {
				const state = getState();

				return moment(state.order.date).startOf('day') < moment().startOf('day');
			},
			getCloseDateDiff: () => {
				const state = getState();

				let days;
				if (state.isOld) {
					days = moment().diff(state.order.date, 'day');
				} else {
					days = moment(state.order.date).diff(moment().startOf('day'), 'day');
				}

				if (days < 0) {
					// When changing close date you can see a negativ number before the modal closes. This prevents it :)
					return '';
				} else if (days === 1) {
					return $translate(state.isOld ? 'opportunity.dayLate' : 'opportunity.dayLeft', { days });
				}

				return $translate(state.isOld ? 'opportunity.daysLate' : 'opportunity.daysLeft', { days });
			},
			isNotToday: () => {
				const state = getState();

				return (
					moment(state.order.date).startOf('day') > moment().startOf('day') ||
					moment(state.order.date).startOf('day') < moment().startOf('day')
				);
			},
			handleDateChange: (date: Date) => {
				const state = getAutoDraftState();

				state.order.date = date;
				actions.save(true, true);
			},
			updateCustomFieldRequiredness: () => {
				const state = getAutoDraftState();

				if (FeatureHelper.isAvailable(FeatureHelper.Feature.ORDER_CUSTOM_STAGES)) {
					state.order.custom = state.order.custom.map(custom => customFieldMapFn(custom));

					for (const [idx, row] of state.order.orderRow.entries()) {
						row.custom = row.custom.map(custom => customFieldMapFn(custom, true, idx));
					}
				}
			},
			stageSelectChanged: (stage: Stage) => {
				const state = getAutoDraftState();

				state.order.stage = stage;
				state.order.probability = stage.probability;
				state.hideProbability =
					state.order.stage?.probability === 0 ||
					state.order.stage?.probability === 100 ||
					(state.isAvailable.newFields && !state.probabilityActive);

				actions.updateCustomFieldRequiredness();
			},
			saveComment: (comment: string) => {
				const state = getState();

				if (!comment.length) {
					return false;
				}

				// Call getPlainObject so we do not leak a draft object
				const data = {
					user: { id: self.id } as User,
					description: comment,
					client: { id: state.order.client!.id } as Client,
					opportunityId: state.order.id
				};

				Comment.save(data).catch(e => {
					logError(e, 'Could not save comment');
				});

				return true;
			},
			marketingContributionChanged: (value: number) => {
				const state = getAutoDraftState();

				state.order.marketingContribution = value;
			},
			salesCoachChanged: (salesCoach: SalesCoach) => {
				const state = getAutoDraftState();

				state.order.salesCoach = salesCoach;
				state.order.stakeholders = [];
				state.order.titleCategories = [];

				// @ts-expect-error I guess salesCoach is not typed correctly
				if (!state.order.salesCoach?.decisionMakers?.active) {
					state.salesCoachDecisionMaker = {};
					state.decisionMakersEnabled = false;
				} else {
					state.decisionMakersEnabled = true;
					const salesCoach = state.order.salesCoach;
					state.salesCoachDecisionMaker = {
						salesCoachId: salesCoach.id,
						// @ts-expect-error I guess salesCoach is not typed correctly
						hasTitleCategories: salesCoach.decisionMakersPro.typeOfDecisionMakers === 'titleCategories'
					};
				}

				// Should not save when creating an opportunity
				if (state.order.id) {
					// Call getPlainObject so we do not leak a draft object
					const order = getPlainObject(state.order);
					Order.customer(customerId).save(
						{
							id: state.order.id,
							// @ts-expect-error
							order: order,
							skipTriggers: true
						},
						{ skipNotification: true }
					);
				}
			},
			validateForm: () => {
				const state = getAutoDraftState();

				if (!notifyErrorSubmit()) {
					state.saving = false;
					return false;
				}

				return true;
			},
			validateRequiredFields: (stage: Stage) => {
				const state = getState();

				let hasRequiredRowFieldsWithoutValue = false;

				for (const row of state.order.orderRow) {
					const rowCustomFields = getRowCustomFields(row.product, row.custom);
					for (const custom of rowCustomFields) {
						const isRequiredStage = custom.stages
							? _.find(custom.stages, s => s.id === stage.id && s.required)
							: undefined;

						if (
							isRequiredStage &&
							!custom.value &&
							custom.value !== 0 &&
							custom.$hasAccess &&
							custom.visible &&
							custom.editable
						) {
							hasRequiredRowFieldsWithoutValue = true;
							break;
						}
					}
				}

				let requiredFieldsWithoutValue = false;

				if (!hasRequiredRowFieldsWithoutValue) {
					requiredFieldsWithoutValue = _.some(state.order.custom, (custom: EditOrderCustomField) => {
						const isRequiredStage = _.find(custom.stages!, s => s.id === stage.id && s.required);

						return (
							isRequiredStage &&
							!custom.value &&
							custom.value !== 0 &&
							custom.$hasAccess &&
							custom.visible &&
							custom.editable
						);
					});
				}
				if (hasRequiredRowFieldsWithoutValue || requiredFieldsWithoutValue) {
					actions.updateCustomFieldRequiredness();

					NotificationService.addNotification({
						title: 'saveError.order',
						body: 'enter_required_fields',
						style: NotificationService.style.ERROR,
						icon: 'times'
					});

					return false;
				}

				return true;
			},
			selectStage: () => {
				const state = getState();

				function openStageListModal(modalParams: any) {
					return new Promise<Stage>((resolve, reject) => {
						if (FeatureHelper.hasSoftDeployAccess('STAGE_LIST_REACT')) {
							openModal('StageListModal', {
								...modalParams,
								onClose: stage => {
									if (stage?.id) {
										resolve(stage);
									} else {
										reject();
									}
								}
							});
						} else {
							$upModal.open('stageList', modalParams).then(resolve).catch(reject);
						}
					});
				}

				const selectableStages = _.filter(
					state.stages,
					stage => state.order.stage.id === stage.id || stage.$hasAccess
				);
				const data = _.cloneDeep(selectableStages) as Stage[];
				const currentStage = _.find(data, { id: state.order.stage.id });
				if (currentStage) {
					// @ts-expect-error I know, remove this when STAGE_LIST_REACT released
					currentStage.$$selected = true;
				}

				openStageListModal({
					hideHeader: true,
					highlight: true,
					hideControls: true,
					title: 'default.stages',
					columns: [
						{ title: 'default.name', value: 'name' },
						{ title: 'p', value: 'probability' }
					],
					data,
					selected: state.order.stage.id
				})
					.then((stage: Stage) => {
						const state = getAutoDraftState();

						state.order.stage = stage;
						state.order.probability = stage.probability;
						state.hideProbability =
							stage.probability === 0 ||
							stage.probability === 100 ||
							(state.isAvailable.newFields && !state.probabilityActive);

						if (FeatureHelper.isAvailable(FeatureHelper.Feature.ORDER_CUSTOM_STAGES)) {
							const validRequiredFields = actions.validateRequiredFields(stage);

							if (!validRequiredFields) {
								return;
							}
						}

						actions.updateCustomFieldRequiredness();
						actions.save();
					})
					.catch((err: unknown) => {
						if (err instanceof Error) {
							logError(err);
						}
					});
			},
			createActivity: () => {
				const state = getState();

				const options: {
					activity: {
						client: Client | null;
						opportunity?: { id: number; description: string };
					};
				} = {
					activity: {
						client: getPlainObject(state.order.client)
					}
				};

				if (state.order.probability !== 0 && state.order.probability !== 100) {
					options.activity.opportunity = { id: state.order.id, description: state.order.description };
				}

				$upModal.open('editActivity', options);
			},
			createAppointment: () => {
				const state = getState();

				const options: {
					appointment: {
						client: Client | null;
						opportunity?: { id: number };
						sourceType?: string;
						sourceId?: number;
					};
				} = {
					appointment: {
						client: getPlainObject(state.order.client)
					}
				};

				if (state.order.probability !== 0 && state.order.probability !== 100) {
					options.appointment.opportunity = { id: state.order.id };
					options.appointment.sourceType = 'opportunity';
					options.appointment.sourceId = state.order.id;
				}

				$upModal.open('editAppointment', options);
			},
			addRelation: () => {
				const state = getState();

				const params = {
					account: getPlainObject(state.order.client),
					customerId: customerId
				};

				openAccountRelationModal(params)
					.then(function (result) {
						const state = getAutoDraftState();

						if (result?.client) {
							if (state.order.client!.connectedClients) {
								const newConnection = { relatedToClientId: result.client!.id } as ConnectedClient;
								state.order.client!.connectedClients.push(newConnection);
							}

							state.relatedClients.push(result.client);
							actions.relatedAccountChange(result.client);
						}
					})
					.catch((err: unknown) => {
						if (err instanceof Error) {
							logError(err);
						}
					});
			},
			promptRemoveEntry: () => {
				const state = getAutoDraftState();

				state.promptRemoval = !state.promptRemoval;
			},
			deleteOrder: () => {
				const state = getAutoDraftState();

				if (!state.order.id) {
					return;
				}

				state.saving = true;
				const order = getPlainObject(state.order) as unknown as Order;

				Order.customer(customerId)
					.delete(order)
					.then(function () {
						actions.setFormPrisitine();
						reject();
					})
					.catch((err: unknown) => {
						if (err instanceof Error) {
							logError(err);
						}
					})
					.finally(function () {
						const state = getAutoDraftState();

						state.saving = false;
					});
			},
			accountChange: (client: EditOrderClient) => {
				const state = getAutoDraftState();

				state.order.client = client;
				accountChange(false);
			},
			changeAccount: () => {
				const state = getAutoDraftState();

				state.changingAccount = !state.changingAccount;

				if (state.changingAccount) {
					state.initialClientId = state.order.client!.id;
					state.initialClientName = state.order.client!.name;
				} else {
					state.order.client!.name = state.initialClientName;
					state.order.client!.id = state.initialClientId;

					accountChange(true);

					if (meta.contact && !meta.missingContactRights) {
						// @ts-ignore Do not want to change the behaviour from the old modal
						state.order.contact = meta.contact.data;
						// @ts-ignore Do not want to change the behaviour from the old modal
						state.contactPerson = meta.contact.data;
						state.lockedContact = true;
					}
				}
			},
			relatedAccountChange: (client: EditOrderOrder['clientConnection']) => {
				const state = getAutoDraftState();

				state.order.clientConnection = client;

				if (state.order.client && state.order.client.id && state.order.client.id > 0) {
					const clientIds = getClientIds();

					// @ts-expect-error
					const filters = new RequestBuilder();
					const orBuilder = filters.orBuilder();
					orBuilder.next();
					orBuilder.addFilter(Contact.attr.account.attr.id, filters.comparisonTypes.Equals, clientIds);
					orBuilder.next();
					orBuilder.addFilter(Contact.attr.connectedClients, filters.comparisonTypes.Equals, clientIds);
					orBuilder.done();
					filters.addFilter(Contact.attr.active, filters.comparisonTypes.Equals, 1);
					filters.addSort(Contact.attr.name, true);

					Contact.customer(customerId)
						.find(filters.build())
						.then(function (res) {
							const state = getAutoDraftState();

							if (res.data && res.data.length) {
								if (res.metadata.total > 1000) {
									state.useAjaxContactSelect = true;
								} else {
									state.useAjaxContactSelect = false;
								}

								const contacts = groupContacts(res.data);
								state.contacts = contacts;

								if (state.order.contact) {
									const found = _.find(contacts, { id: state.order.contact.id }) as
										| EditOrderContact
										| undefined;

									if (found) {
										state.order.contact = found;
										state.contactPerson = found;
									} else {
										state.order.contact = null;
										state.contactPerson = null;
									}
								}

								setClientOrderRelation();
							}
						})
						.catch(err => {
							if (err instanceof Error) {
								logError(err);
							}
						});
				} else {
					state.useAjaxContactSelect = false;
				}

				state.order.lastSuccess.clientConnection = state.order.clientConnection;
			},
			parentCompanyClick: () => {
				const state = getState();

				// Go to existing parent
				if (state.order.client!.parent) {
					return $state.go('account.dashboard', { id: state.order.client!.parent.id });
				}

				// Check for company in db
				const duns = state.order.client!.soliditet!.profileData.parentCompanyDunsNumber!;
				// @ts-expect-error
				const filters = new RequestBuilder();
				filters.addFilter(Account.attr.dunsNo, filters.comparisonTypes.Equals, duns.toString());
				filters.limit = 1;
				filters.fields = ['id'];

				Account.customer(customerId)
					.find(filters.build())
					.then(function (results) {
						const state = getAutoDraftState();

						// If results
						if (results.data.length) {
							// Save account with found parent and go there
							const data = { id: state.order.client!.id, parent: { id: results.data[0].id } };

							Account.customer(customerId).save(data, {
								skipNotification: true,
								skipErrorNotification: true
							});

							$state.go('account.dashboard', { id: results.data[0].id });
						} else {
							// Ask to buy the account from soliditet
							$upModal
								.open('confirmSoliditetBuyParent', {
									customerId: customerId,
									name: state.order.client!.soliditet!.profileData.parentCompanyName,
									duns: duns
								})
								.then(function (account) {
									const state = getAutoDraftState();

									// Set parent on account after buy
									const data = { id: state.order.client!.id, parent: { id: account.id } };

									Account.customer(customerId).save(data, {
										skipNotification: true,
										skipErrorNotification: true
									});

									// Go to account
									$state.go('account.dashboard', { id: account.id });
								})
								.catch(err => {
									if (err instanceof Error) {
										logError(err);
									}
								});
						}
					})
					.catch(err => {
						if (err instanceof Error) {
							logError(err);
						}
					});
			},
			selectMe: () => {
				const state = getAutoDraftState();

				state.order.user = state.self as unknown as User;
			},
			// Triggered when the order currency is changes
			changeCurrency: (currency: Currency) => {
				const state = getAutoDraftState();

				state.selectedCurrency = currency;

				if (state.selectedCurrency) {
					if (state.priceList) {
						_.each(state.order.orderRow, function (row) {
							productChange(row);
						});
					}
					state.order.currency = state.selectedCurrency.iso;
					state.order.currencyRate = state.selectedCurrency.rate;
					state.convert = state.order.currency !== state.masterCurrency;
				}
			},
			changePriceList: (priceList: PriceList) => {
				const state = getAutoDraftState();

				state.priceList = priceList;
				changePriceList(false, true);
			},
			openProductSearch: () => {
				const state = getState();

				// Call getPlainObject so we do not leak a draft object
				const modalParams = {
					order: getPlainObject(state.order) as unknown as Order,
					addOrderRow: addOrderRowWithProduct
				};

				openModal('ProductSearch', modalParams);
			},
			addOrderRow: (providedRow?: EditOrderOrderRow) => {
				const state = getAutoDraftState();

				const orderRow = providedRow || getNewOrderRow();
				orderRow.priceListId = state.priceList.id;
				const length = state.order.orderRow.push(orderRow);

				// Find prev row
				if (state.order.orderRow[length - 2]) {
					// Set sort id on added row to one more than prev
					orderRow.sortId = state.order.orderRow[length - 2].sortId + 1;
				}
				// : Cannot read properties of undefined (reading 'map')
				orderRow.custom = orderRow.custom.map(custom => customFieldMapFn(custom, true, length - 1));

				actions.setFormDirty();
			},
			deleteOrderRow: (orderRowIndex: number) => {
				const state = getAutoDraftState();

				const orderRow = state.order.orderRow[orderRowIndex];
				_.pull(state.order.orderRow, orderRow);

				if (!state.order.orderRow.length) {
					actions.addOrderRow();
					state.order.orderRow[0].sortId = 1;
				}

				actions.setFormDirty();
			},
			copyOrderRow: (orderRowIndex: number) => {
				const state = getAutoDraftState();

				const orderRow = state.order.orderRow[orderRowIndex];
				const copy = _.cloneDeep(orderRow);
				delete copy.id;
				// @ts-expect-error yeayea it will get a new value on the next row
				delete copy.uuid;
				copy.uuid = getUniqueOrderRowId(copy);
				checkAndSetExcessiveDiscount(copy);
				actions.addOrderRow(copy);
			},
			sortRowUp: (orderRowIndex: number) => {
				const enabled = orderRowIndex > 0;

				if (!enabled) {
					return;
				}

				changeRowOrder(orderRowIndex, orderRowIndex - 1);
			},
			sortRowDown: (orderRowIndex: number) => {
				const state = getState();

				const enabled = orderRowIndex < state.order.orderRow.length - 1;

				if (!enabled) {
					return;
				}

				changeRowOrder(orderRowIndex, orderRowIndex + 1);
			},
			createDocument: function (template: any) {
				const state = getState();

				if (template.uuid) {
					openPreviewPdfModal({
						pdfTemplateResource: {
							resource: pdfTemplateResource,
							uuid: template.uuid,
							orderId: state.order.id,
							type: 'order',
							pdfName: template.name,
							supportedLanguages: template.supportedLanguages,
							templateType: template.type,
							contact: state.order.contact || null
						}
					}).catch(() => {});
				} else {
					const oldModalParams = {
						entityId: state.order.id,
						templateId: template.id,
						type: 'order',
						name: template.name,
						accountId: state.order.client?.id,
						contactId: state.order.contact?.id,
						contact: getPlainObject(state.order?.contact)
					};

					// No need to call getPlainObject here as all fields are taken from oldModalParams that is an plain object
					const newModalParams = {
						isUploadedTemplate: true,
						documentResource: {
							resource: documentResource,
							entityId: oldModalParams.entityId,
							templateId: oldModalParams.templateId,
							type: oldModalParams.type,
							documentName: oldModalParams.name,
							accountId: oldModalParams.accountId,
							contact: oldModalParams.contact,
							contactId: oldModalParams.contactId
						}
					};

					if (!state.OrderForm.$dirty) {
						if (FeatureHelper.hasSoftDeployAccess('PREVIEW_PDF_REACT')) {
							return openModal('PreviewPdfModal', newModalParams);
						} else {
							return $upModal.open('pdfPreview', oldModalParams);
						}
					} else {
						actions.setFormPrisitine();

						actions
							.save(true, false, true)
							.then(function () {
								if (FeatureHelper.hasSoftDeployAccess('PREVIEW_PDF_REACT')) {
									openModal('PreviewPdfModal', newModalParams);
								} else {
									$upModal.open('pdfPreview', oldModalParams);
								}
							})
							.catch((err: unknown) => {
								if (err instanceof Error) {
									logError(err);
								}
							});
					}
				}
			},
			oldDateCondition: (doNotNotifyDate?: boolean, omitClosedOrderCheck?: boolean) => {
				const state = getState();

				return (
					!doNotNotifyDate &&
					!state.isOrder &&
					(omitClosedOrderCheck || state.order.probability === 100 || state.order.probability === 0) &&
					actions.isNotToday()
				);
			},
			recurringProductCondition: (hasRecurringProducts: boolean) => {
				const state = getState();

				return !state.isOrder && state.order.probability === 100 && hasRecurringProducts;
			},
			save: (doNotResolve?: boolean, doNotNotifyDate?: boolean, doValidate?: boolean) => {
				const state = getAutoDraftState();

				if (state.saving) {
					return $q.reject('Already saving');
				}

				state.saving = true;

				if (doValidate) {
					if (!actions.validateForm()) {
						return $q.reject('Invalid form');
					}
				}

				if (!doNotResolve && FeatureHelper.isAvailable(FeatureHelper.Feature.PERIODIZATION)) {
					const haveValidPeriodization = dispatch(checkIfPeriodizationIsValid(state.order));

					if (!haveValidPeriodization) {
						NotificationService.addNotification({
							title: 'saveError.order',
							body: 'order.periodization.error',
							style: NotificationService.style.ERROR,
							icon: 'times'
						});
						state.saving = false;
						actions.setFormDirty();

						return $q.reject('Invalid periodization');
					}
				}

				if (!state.savedThroughWinLossFlow) {
					// If order closeDate is passed we ask user if we should set it to today
					const hasRecurringProducts = actions.hasRecurringProduct();
					const oldCondition = actions.oldDateCondition(doNotNotifyDate);
					const recurringProductCondition = actions.recurringProductCondition(hasRecurringProducts);

					if (oldCondition || recurringProductCondition) {
						const shouldOpenNewModal = actions.wonOpportunityWithSubs();
						const shouldOpenLostOppModal =
							actions.showWinLossButtons() &&
							state.order.stage.probability === 0 &&
							!state.order.lostReason;

						const stage = getPlainObject(state.order.stage);

						return openGenericModal({
							Component: CloseOpportunityModal,
							stage,
							description: state.order.description,
							showToggle: hasRecurringProducts && !shouldOpenNewModal,
							// Angular only
							className: 'CloseOpportunityModal--root no-animate'
						})
							.then(function (params) {
								const state = getAutoDraftState();

								if (params.setToday) {
									state.order.date = new Date();
								}

								return save(doNotResolve).then(function () {
									const state = getState();

									if (!state.saveError) {
										if (shouldOpenLostOppModal) {
											// I am extra cautious not to leak any draft so will call getPlainObject even if the probability that it is needed is very low
											const order = getPlainObject(state.order);

											openModal('LostOpportunityModal', {
												order: order as unknown as Order,
												onSaveLostReason: (lostReason: number, competitorId?: number) => {
													const state = getAutoDraftState();

													state.order.lostReason = lostReason;
													state.order.competitorId = competitorId;

													return save(doNotResolve);
												}
											});
										} else if (shouldOpenNewModal && state.order.stage.probability === 100) {
											openOpportunityWithSubs(state.order.stage);
										} else if (params.createSubscription) {
											if (!FeatureHelper.hasSoftDeployAccess('SUBSCRIPTION_MODAL')) {
												const opts = {
													customerId: customerId,
													orderId: state.order.id,
													createFromOrder: true
												};

												return $upModal.open('editAgreement', opts);
											}

											const order = getPlainObject(state.order) as unknown as Order;
											openModal('CreateSubscription', { order, createdFrom: 'saveOrder' });
										}
									}
								});
							})
							.catch(() => {
								const state = getAutoDraftState();

								state.saving = false;
							});
					}
				}

				if (state.OrderForm.$invalid) {
					state.saving = false;
					return $q.reject('Invalid form');
				}

				return save(doNotResolve);
			},
			uploadFile: () => {
				const state = getState();

				if (FeatureHelper.hasSoftDeployAccess('REACT_UPLOAD_FILE_MODAL')) {
					return openModal('UploadFileModal', { orderId: state.order.id });
				}

				$upModal.open('uploadFile', {
					orderId: state.order.id
				});
			},
			downloadFile: (file: File) => {
				File.download(file.id);
			},
			removeFile: (file: File) => {
				return File.customer(customerId).delete(file);
			},
			emailContact: (event: React.MouseEvent<HTMLAnchorElement>, contact: EditOrderContact) => {
				event.preventDefault();
				event.stopPropagation();

				if (FeatureHelper.hasSoftDeployAccess('NEW_MAIL')) {
					openNewMailWithContact(contact);
				} else {
					$upModal.open('sendEmail', { customerId: customerId, contactId: contact.id, contact: contact });
				}
			},
			contactChange: (contact: EditOrderContact | null) => {
				const state = getAutoDraftState();

				if (contact) {
					state.order.contact = contact;
					state.contactPerson = contact;

					// Get all data for contact and save in variable
					Contact.customer(customerId)
						.get(state.order.contact.id)
						.then(function (res) {
							const state = getAutoDraftState();

							state.contactPerson = res.data as EditOrderContact;
						})
						.catch((err: unknown) => {
							if (err instanceof Error) {
								logError(err);
							}
						});
				} else {
					state.contactPerson = null;
					state.order.contact = null;
				}

				if (state.order.id && state.order.salesCoach) {
					const contact = getPlainObject(state.order.contact);

					Order.customer(customerId).save(
						{
							id: state.order.id,
							contact,
							skipTriggers: true
						},
						{ skipNotification: true }
					);
				}
			},
			userChange: (user: User) => {
				const state = getAutoDraftState();

				state.order.user = user;
				getAvailableSalesCoaches();
			},
			abortContactEdit: () => {
				const state = getAutoDraftState();

				state.editContact = false;
				resetEditContactModel();
			},
			showContactEdit: (edit: boolean = false) => {
				const state = getAutoDraftState();

				if (!actions.canEditContact()) {
					return;
				}

				state.editContact = true;
				state.contactFormIsEdit = edit;

				// Set all models
				if (edit && state.contactPerson) {
					state.editContactModel.id = state.contactPerson.id;
					state.editContactModel.name = state.contactPerson.name;
					state.editContactModel.firstName = state.contactPerson.firstName;
					state.editContactModel.lastName = state.contactPerson.lastName;
					state.editContactModel.title = state.contactPerson.title;
					state.editContactModel.titleCategory = state.contactPerson.titleCategory;
					state.editContactModel.phone = state.contactPerson.phone;
					state.editContactModel.cellPhone = state.contactPerson.cellPhone;
					state.editContactModel.email = state.contactPerson.email;
				} else {
					resetEditContactModel();
				}
			},
			canEditContact: () => {
				const state = getState();

				return (
					!state.contactPerson ||
					state.contactPerson?.userEditable ||
					(isContact(meta.contact)
						? meta.contact.data.id === state.contactPerson.id && meta.contact.data.userEditable
						: false)
				);
			},
			onSetJourneyStepContact: (journeyStep: string) => {
				const state = getState();

				if (!state.contactPerson) {
					return $q.resolve();
				}

				return Contact.customer(customerId)
					.save({
						id: state.contactPerson.id,
						journeyStep: journeyStep
					})
					.then(function (res) {
						const state = getAutoDraftState();

						state.contactPerson!.journeyStep = res.data.journeyStep;
					});
			},
			onSetJourneyStepClient: (journeyStep: string) => {
				const state = getState();

				if (!state.account) {
					return $q.resolve();
				}

				return Contact.customer(customerId)
					.save({
						id: state.account.id,
						journeyStep: journeyStep
					})
					.then(function (res) {
						const state = getAutoDraftState();

						state.account!.journeyStep = res.data.journeyStep;
					});
			},
			contactTitleCategoryChanged: (titleCategory: { tagId: number; value: string; language: string } | null) => {
				const state = getAutoDraftState();

				state.editContactModel.titleCategory = titleCategory;
			},
			contactLastNameChanged: (event: React.ChangeEvent<HTMLInputElement>) => {
				const state = getAutoDraftState();

				state.editContactModel.lastName = event.target.value;
			},
			contactFirstNameChanged: (event: React.ChangeEvent<HTMLInputElement>) => {
				const state = getAutoDraftState();

				state.editContactModel.firstName = event.target.value;
			},
			contactNameChanged: (event: React.ChangeEvent<HTMLInputElement>) => {
				const state = getAutoDraftState();

				state.editContactModel.name = event.target.value;
			},
			contactTitleChanged: (event: React.ChangeEvent<HTMLInputElement>) => {
				const state = getAutoDraftState();

				state.editContactModel.title = event.target.value;
			},
			contactCellPhoneChanged: (event: React.ChangeEvent<HTMLInputElement>) => {
				const state = getAutoDraftState();

				state.editContactModel.cellPhone = event.target.value;
			},
			contactPhoneChanged: (event: React.ChangeEvent<HTMLInputElement>) => {
				const state = getAutoDraftState();

				state.editContactModel.phone = event.target.value;
			},
			contactEmailChanged: (event: React.ChangeEvent<HTMLInputElement>) => {
				const state = getAutoDraftState();

				state.editContactModel.email = event.target.value;
			},
			saveContactOnEnter: (event: React.KeyboardEvent<HTMLInputElement>) => {
				if (event && event.keyCode === 13) {
					event.preventDefault();
					event.stopPropagation();

					actions.contactEditSave();
				}
			},
			createContact: (event: React.MouseEvent<HTMLAnchorElement>) => {
				event.preventDefault();

				const state = getState();

				// if contact har required fields we open real modal, else show quickCreate
				if (state.hasRequiredContactFields) {
					const account = getPlainObject(state.order.client);

					$upModal
						.open('editContact', { account })
						.then(function (addedContact) {
							const state = getAutoDraftState();

							// Set created contact as selected
							state.contacts.push(addedContact);
							state.order.contact = addedContact;
							state.contactPerson = addedContact;
						})
						.catch(err => {
							if (err instanceof Error) {
								logError(err);
							}
						});
				} else {
					actions.showContactEdit();
				}
			},
			createEsign: () => {
				const state = getState();

				const esign = {
					client: state.order.client,
					user: state.order.user,
					opportunity: !state.isOrder
						? { id: state.order.id, description: state.order.description }
						: undefined,
					involved: state.order.contact ? [Esign.newInvolved({ contact: state.order.contact })] : undefined
				};

				const modalParams = getPlainObject({ esign });
				$upModal.open('editEsign', modalParams);
			},
			contactEditSaveDisabled: () => {
				const state = getState();

				return (
					state.editContactLoading ||
					!(
						(!state.isAvailable.newFields && state.editContactModel.name) ||
						(state.isAvailable.newFields &&
							state.editContactModel.firstName &&
							state.editContactModel.lastName)
					) ||
					(state.requiredFieldsContact.Email && !state.editContactModel.email) ||
					(state.requiredFieldsContact.Phone && !state.editContactModel.phone) ||
					(state.requiredFieldsContact.Cellphone && !state.editContactModel.cellPhone) ||
					(state.requiredFieldsContact.Title && !state.editContactModel.title) ||
					(state.requiredFieldsContact.TitleCategory && !state.editContactModel.titleCategory) ||
					!actions.validateEmail(state.editContactModel.email)
				);
			},
			contactEditSave: () => {
				const state = getAutoDraftState();

				if (actions.contactEditSaveDisabled()) {
					return;
				}

				state.editContactLoading = true;

				const contactClientId = state.contactPerson?.client.id || state.order.client!.id;
				const titleCategory = getPlainObject(state.editContactModel.titleCategory);

				const contact = {
					id: state.editContactModel.id || undefined,
					active: !state.contactFormIsEdit || undefined,
					name: state.editContactModel.name,
					firstName: state.editContactModel.firstName,
					lastName: state.editContactModel.lastName,
					title: state.editContactModel.title,
					titleCategory: titleCategory,
					phone: state.editContactModel.phone,
					cellPhone: state.editContactModel.cellPhone,
					email: state.editContactModel.email,
					client: {
						id: contactClientId
					}
				} as unknown as Contact;

				Contact.customer(customerId)
					.save(contact)
					.then(function (res) {
						const state = getAutoDraftState();

						state.contactPerson = res.data;
						state.editContact = false;
						state.editContactLoading = false;

						// Update list of contacts
						if (state.contactFormIsEdit) {
							// find contact in list and update
							const i = _.findIndex(state.contacts, { id: res.data.id });

							if (i !== -1) {
								state.contacts[i] = res.data;
							}
						} else {
							// Add contact to list
							state.contacts.push(res.data);
							state.order.contact = res.data;

							if (state.order.id) {
								const contact = getPlainObject(state.order.contact);

								Order.customer(customerId).save(
									{
										id: state.order.id,
										contact: contact,
										skipTriggers: true
									},
									{ skipNotification: true }
								);
							}
						}
					})
					.catch(function () {
						const state = getAutoDraftState();

						state.editContactLoading = false;
					});
			},
			createCopy: () => {
				const state = getState();

				if (!state.order.id) {
					return;
				}

				const opts = {
					customerId: customerId,
					copy: state.order.id,
					type: 'opportunity'
				};

				if (!state.OrderForm.$dirty) {
					$upModal.open('editOrder', opts);
					resolve();
				} else {
					actions.setFormPrisitine();

					actions
						.save(true, true, true)
						.then(function () {
							$upModal.open('editOrder', opts);
							resolve();
						})
						.catch((err: unknown) => {
							if (err instanceof Error) {
								logError(err);
							}
						});
				}
			},
			eSign: () => {
				const state = getState();

				const esign = getPlainObject({
					esign: {
						client: { id: state.order.client!.id },
						contact: state.order.contact,
						opportunityId: state.order.id
					}
				});

				if (!state.OrderForm.$dirty) {
					$upModal.open('editEsign', esign);
				} else {
					actions.setFormPrisitine();

					actions
						.save(true, true, true)
						.then(function () {
							$upModal.open('editEsign', esign);
						})
						.catch((err: unknown) => {
							if (err instanceof Error) {
								logError(err);
							}
						});
				}
			},
			createSubscription: () => {
				const state = getState();

				if (!state.order.id) {
					return;
				}

				const opts = {
					customerId: customerId,
					orderId: state.order.id,
					createFromOrder: true
				};

				if (!state.OrderForm.$dirty) {
					if (!FeatureHelper.hasSoftDeployAccess('SUBSCRIPTION_MODAL')) {
						$upModal.open('editAgreement', opts);
					} else {
						const order = getPlainObject(state.order) as unknown as Order;
						openModal('CreateSubscription', { order, createdFrom: 'order' });
					}

					resolve();
				} else {
					actions.setFormPrisitine();

					actions
						.save(true, true, true)
						.then(function () {
							if (!FeatureHelper.hasSoftDeployAccess('SUBSCRIPTION_MODAL')) {
								$upModal.open('editAgreement', opts);
							} else {
								const order = getPlainObject(state.order) as unknown as Order;
								openModal('CreateSubscription', { order, createdFrom: 'order' });
							}

							resolve();
						})
						.catch((err: unknown) => {
							if (err instanceof Error) {
								logError(err);
							}
						});
				}
			},
			hasClientRelations: () => {
				const state = getState();

				return state.clientOrderRelation && state.isAvailable.companyRelations ? true : false;
			},
			hasCurrencyPicker: () => {
				const state = getState();

				const activeCurrencies = metadata.customerCurrencies.filter(currency => currency.active);
				return !state.edit && state.hasMultiCurrency && activeCurrencies.length > 1;
			},
			hasRecurringProduct: () => {
				const state = getState();

				if (!state.hasRecurringProducts) {
					return false;
				}

				return _.some(state.order.orderRow, function (row) {
					return row.product?.isRecurring;
				});
			},
			shouldShowButtonOnWonModal: () => {
				const state = getState();

				return !state.skipButtonOnWonModal && actions.hasRecurringProduct();
			},
			wonOpportunityWithSubs: () => {
				const state = getState();

				const hasRecurringProducts = actions.hasRecurringProduct();
				return hasRecurringProducts && state.activeAgreementGroups.length ? true : false;
			},
			intervalChanged: ({ id }: State['recurringIntervals'][0]) => {
				const state = getAutoDraftState();

				const recurringInterval = parseInt(id);
				state.recurringInterval = recurringInterval;
				const oldInterval = state.order.recurringInterval;
				state.order.recurringInterval = state.recurringInterval;

				for (const row of state.order.orderRow) {
					if (FeatureHelper.hasSoftDeployAccess('ORDER_INTERVAL_DISCOUNT')) {
						row.price = adjustPriceIfRecurring(row.price, row.product, oldInterval);
						row.listPrice = adjustPriceIfRecurring(row.listPrice, row.product, oldInterval);
						row.$listPrice = adjustPriceIfRecurring(row.$listPrice, row.product, oldInterval);
						calculateDiscount(row);

						// Was like this and PM didn't want it changed until proper solution with setting, see LINE-7825
						if (row.product.bundle?.length) {
							row.purchaseCost = adjustPriceIfRecurring(row.purchaseCost, row.product, oldInterval);
						}
					} else {
						productChange(row, true);
					}
				}
			},
			updateProbability: (probability: number) => {
				const state = getAutoDraftState();

				state.order.probability = probability;
				// @ts-expect-error IDK
				state.order.stage = state.stages.find((stage: Stage) => stage.probability === probability);
				state.hideProbability =
					state.order.stage?.probability === 0 ||
					state.order.stage?.probability === 100 ||
					(state.isAvailable.newFields && !state.probabilityActive);

				actions.updateCustomFieldRequiredness();

				save(false);
			},
			updateOrder: (partialOrder: Partial<Order>) => {
				const state = getAutoDraftState();

				state.order = Object.assign(state.order, partialOrder) as EditOrderOrder;
				save(true);
			},
			onProjectPlanChange: (projectPlanOptions: ProjectPlanOption[]) => {
				const state = getAutoDraftState();

				state.order.projectPlanOptions = projectPlanOptions;
			},
			toggleSync: async (integration?: { id: number; name: string }) => {
				const state = getAutoDraftState();

				const inteFields: string[] = [];
				const inteDesc: { [field: string]: string } = {};
				const syncDir: { [field: string]: string } = {};

				if (integration) {
					StandardIntegration.data(customerId)
						.run({ type: 'fields/order', integrationId: integration.id })
						.then(
							(res: {
								data: { fields: { upsalesField: string; description: string; direction: string }[] };
							}) => {
								const state = getAutoDraftState();

								if (res.data && res.data.fields) {
									res.data.fields.forEach(field => {
										if (!field?.upsalesField) return;
										if (!inteFields.includes(field.upsalesField)) {
											inteFields.push(field.upsalesField);
											inteDesc[field.upsalesField] = field.description;
											syncDir[field.upsalesField] = field.direction;
										}
									});
								}

								state.highlightedFields = inteFields;
								state.fieldsDescription = inteDesc;
								state.fieldsSyncDir = syncDir;
								state.syncedToApp =
									integration && $translate('integration.onlySyncedToApp', { app: integration.name });
							}
						)
						.catch((err: unknown) => {
							if (err instanceof Error) {
								logError(err, 'Failed to get fields');
							}
						});
				} else {
					state.highlightedFields = inteFields;
					state.fieldsDescription = inteDesc;
					state.fieldsSyncDir = syncDir;
					state.syncedToApp = undefined;
				}
			},
			activateSyncLogs: () => {
				const state = getAutoDraftState();

				state.renderSyncLogs = true;
			},
			onFormSubmit: (event: React.FormEvent<HTMLFormElement>) => {
				const state = getAutoDraftState();

				event.preventDefault(); // We do not want to post to the server

				state.OrderForm.$submitted = true;

				actions.save(false, false, true);
			},
			setFormPrisitine: () => {
				const state = getAutoDraftState();

				state.OrderForm.$dirty = false;
				state.OrderForm.$pristine = true;
			},
			setFormDirty: () => {
				const state = getAutoDraftState();

				state.OrderForm.$dirty = true;
				state.OrderForm.$pristine = false;
			},
			registerFormComponent: (name: string, valid: boolean = true) => {
				const state = getAutoDraftState();

				state.OrderForm[name] = {
					$dirty: false,
					$invalid: !valid,
					$pristine: true,
					$valid: valid
				};

				if (!valid) {
					state.OrderForm.$invalid = true;
					state.OrderForm.$valid = false;
				}
			},
			unregisterFormComponent: (name: string) => {
				const state = getAutoDraftState();

				delete state.OrderForm[name];
			},
			onFormConponentChange: (name: string, valid: boolean, updateDirty: boolean) => {
				const state = getAutoDraftState();

				if (state.OrderForm[name]) {
					state.OrderForm[name].$invalid = !valid;
					state.OrderForm[name].$valid = valid;
					state.OrderForm.$invalid = Object.values(state.OrderForm).some(component =>
						typeof component === 'object' ? component.$invalid : false
					);
					state.OrderForm.$valid = !state.OrderForm.$invalid;

					if (updateDirty) {
						state.OrderForm.$dirty = true;
						state.OrderForm.$pristine = false;
						state.OrderForm[name].$dirty = true;
						state.OrderForm[name].$pristine = false;
					}
				}
			},
			callEditOrderListenersIfChanged: (updatedOrder: EditOrderOrder, previousOrder: EditOrderOrder) => {
				callEditOrderListenersIfChanged(updatedOrder, previousOrder);
			}
		} as const;

		///////////////////// INTERNAL FUNCTIONS /////////////////////

		function isDisabledFieldAppMaster(
			fieldPath: string,
			isDisabled?: boolean,
			rowFieldPath?: string,
			rowSortFieldPath?: string
		) {
			const state = getState();

			const defaultIsDisabled = isDisabled === undefined ? false : isDisabled;
			const formattedFieldPath = fieldPath.replaceAll(' ', '').toLowerCase();
			const fieldDisabled = _.get(state.disabledFields, formattedFieldPath) as boolean | undefined;
			const hasDisabledStateForField = fieldDisabled !== undefined;

			if (hasDisabledStateForField) {
				return fieldDisabled;
			}

			if (!rowFieldPath) {
				return defaultIsDisabled;
			}

			const formattedRowFieldPath = rowFieldPath.replaceAll(' ', '').toLowerCase();
			const formattedRowSortFieldPath = rowSortFieldPath?.replaceAll?.(' ', '')?.toLowerCase() ?? '';
			const rowFieldDisabled = _.get(state.disabledFields, formattedRowFieldPath, undefined);
			const rowSortFieldDisabled = _.get(state.disabledFields, formattedRowSortFieldPath, undefined);

			if (rowFieldDisabled !== undefined) {
				return rowFieldDisabled;
			}

			if (rowSortFieldDisabled !== undefined) {
				return rowSortFieldDisabled;
			}

			return defaultIsDisabled;
		}

		async function getProducts(productIds: number[]) {
			try {
				const { data: products } = await ProductResource.find({ id: productIds, usePriceLists: true });
				return products;
			} catch (err) {
				logError(err, 'Failed to get products');
				return [];
			}
		}

		function getOrderRowWithIdOrSortId(
			existingOrderRow: { id?: number; sortId?: number } = {},
			orderRowToFind: { id?: number; sortId?: number } = {}
		) {
			const bothHasRowId = existingOrderRow.id && orderRowToFind.id;

			if (bothHasRowId) {
				return existingOrderRow.id === orderRowToFind.id;
			}

			return existingOrderRow.sortId === orderRowToFind.sortId;
		}

		async function updateOrderFromApp(
			fieldActions: any,
			orderBefore: Order,
			updatedOrder: Order,
			orderChanges: any
		) {
			try {
				let state = getAutoDraftState();

				const hasCurrencyPicker = actions.hasCurrencyPicker();

				if (!_.isEmpty(fieldActions?.disabled?.order)) {
					if (fieldActions.disabled.save) {
						state.appDisabledSaveButton = true;
						disableSaveButton(fieldActions.disabled.saveTooltip);
					} else {
						state.appDisabledSaveButton = false;
					}

					state.disabledFields = transformFieldsToLowerCase(fieldActions.disabled.order);

					const revertedFields = OnOrderEditHelpers.revertChangesIfAffectedField(
						orderBefore,
						state.order as unknown as Order,
						fieldActions.disabled.order,
						true
					);

					fieldActions.updated = OnOrderEditHelpers.mergeRevertedFieldWithUpdatedFields(
						revertedFields,
						fieldActions.updated
					);
				}

				if (!_.isEmpty(fieldActions?.visible?.order)) {
					state.visibleFields = transformFieldsToLowerCase(fieldActions.visible.order);
					state.visibleFields = OnOrderEditHelpers.omitForcedVisibleFields(
						state.visibleFields
					) as State['visibleFields'];

					const revertedFields = OnOrderEditHelpers.revertChangesIfAffectedField(
						orderBefore,
						state.order as unknown as Order,
						fieldActions.visible.order,
						false
					);

					fieldActions.updated = OnOrderEditHelpers.mergeRevertedFieldWithUpdatedFields(
						revertedFields,
						fieldActions.updated
					);
				}

				if (fieldActions?.updated?.order) {
					const updateOrder = state.hasOnOrderEditPerformanceEnhanced
						? _.cloneDeep(updatedOrder)
						: getPlainObject(state.order);
					const relatedClients = getPlainObject(state.relatedClients);
					const availableSalescoaches = getPlainObject(state.availableSalescoaches);

					const orderAfterUpdate = await OnOrderEditHelpers.updateOrder(
						updateOrder as unknown as Order,
						fieldActions.updated.order,
						relatedClients,
						availableSalescoaches,
						state.hasContributionMargin,
						productCategories
					);

					// Update state to fetch after API call
					state = getAutoDraftState();

					state.order = orderAfterUpdate as unknown as EditOrderOrder;

					/*
						I would have wanted this in the OnOrderEditHelpers.updateOrder but there
						is a lot of calculations done in this controller which overwrites all potential
						changes done in the helper function.
						So it is easier and less impact to handle the currency change here for now.
						But when we port to react, it would be nice if we could make the currency change
						a bit more modular.
					*/
					if (
						hasCurrencyPicker &&
						fieldActions.updated.order?.currency &&
						fieldActions.updated.order.currency !== state.selectedCurrency.iso
					) {
						const availableCurrencies = metadata.customerCurrencies;
						const matchedCurrency = availableCurrencies.find(currency => {
							return currency.iso === fieldActions.updated.order?.currency;
						});

						if (matchedCurrency) {
							actions.changeCurrency(matchedCurrency);
						}
					}

					for (const orderRowUpdate of fieldActions.updated.order?.orderRow ?? []) {
						const updatedOrderRowIndex = state.order.orderRow.findIndex(row => {
							return getOrderRowWithIdOrSortId(row, orderRowUpdate);
						});
						const updatedOrderRow = state.order.orderRow[updatedOrderRowIndex];
						if (!updatedOrderRow) {
							continue;
						}
						if (_.has(orderRowUpdate, 'listPrice')) {
							updatedOrderRow.$listPrice = updatedOrderRow.listPrice;
						}
						if (_.has(orderRowUpdate, 'discount')) {
							discountChange(updatedOrderRow);
						}
						if (_.has(orderRowUpdate, '$discountPercent')) {
							discountPercentChange(updatedOrderRow);
						}
						if (orderRowUpdate.price !== undefined && state.priceList && updatedOrderRow.product) {
							priceChange(updatedOrderRow);
						}
						if (_.has(orderRowUpdate, 'product')) {
							productChange(updatedOrderRow);
						}
						if (_.has(orderRowUpdate, 'bundleRows')) {
							editProductBundleSave(updatedOrderRowIndex, orderRowUpdate);
						}
					}

					// The view is using the recurringInterval at EditOrder and not on EditOrder.order
					// When porting to react, make sure that the recurring interval is read from the order instead
					state.recurringInterval = updatedOrder.recurringInterval!;

					// salesCoach is an array, but this view expects the salesCoach to be the first item in array
					if (Array.isArray(state.order.salesCoach)) {
						state.order.salesCoach = state.order.salesCoach[0] || null;
					}
				}

				if (fieldActions?.added?.order?.orderRow && state.priceList) {
					state.stopOnOrderEditListening = true;

					if (!state.orderProducts) {
						state.orderProducts = [];
					}

					const orderRows = fieldActions.added?.order?.orderRow as EditOrderOrderRow[] | undefined;
					const missingProductIds =
						orderRows?.reduce<number[]>((acc, row) => {
							const productId = row?.product?.id || row?.productId;

							if (!productId) {
								return acc;
							}

							const foundProduct = state.orderProducts.find(product => product.id === productId);

							if (!foundProduct) {
								acc.push(productId);
							}

							return acc;
						}, []) ?? [];

					if (missingProductIds.length) {
						const missingProducts = await getProducts(missingProductIds);

						// Update state to fetch after API call
						state = getAutoDraftState();
						state.orderProducts = [...state.orderProducts, ...missingProducts];
					}

					fieldActions.added?.order?.orderRow.forEach((addedRow: Partial<EditOrderOrderRow> = {}) => {
						const newOrderRow = Order.newRow();

						const orderRowToAdd = {
							...newOrderRow,
							...addedRow,
							custom: newOrderRow.custom
						} as EditOrderOrderRow;

						addedRow.custom?.forEach(addedCustomField => {
							const foundCustomField = orderRowToAdd.custom.find(
								customField => addedCustomField.fieldId === customField.fieldId
							);

							if (foundCustomField) {
								actions.setCustomFieldValue(orderRowToAdd, foundCustomField.id, addedCustomField.value);
							}
						});

						orderRowToAdd.uuid = getUniqueOrderRowId(orderRowToAdd);

						if (orderRowToAdd.custom?.length) {
							for (const custom of orderRowToAdd.custom) {
								if (custom.fieldId && orderRowToAdd.$mappedCustom[custom.fieldId]) {
									orderRowToAdd.$mappedCustom[custom.fieldId].value = custom.value;
								}
							}
						}

						const foundProduct = state.orderProducts.find(
							product => product.id === (orderRowToAdd.product?.id || orderRowToAdd?.productId)
						);

						if (foundProduct) {
							orderRowToAdd.product = foundProduct;
						}

						actions.addOrderRow(orderRowToAdd);

						if (!state.hasOnOrderEditPerformanceEnhanced) {
							const changes = getOrderChanges(state.order as unknown as Order, orderBefore);
							state.lastChangesFromEditListener = JSON.stringify(changes);
						}
					});
				}

				if (state.hasOnOrderEditPerformanceEnhanced) {
					if (!orderChanges) {
						orderChanges = getOrderChanges(state.order as unknown as Order, orderBefore);
					}

					state.lastChangesFromEditListener = JSON.stringify(orderChanges);
				}

				if (fieldActions?.removed?.order?.orderRow) {
					fieldActions.removed.order.orderRow.forEach((removedRow: any) => {
						if (removedRow?.id || removedRow?.sortId) {
							const orderRowIndex = state.order.orderRow.findIndex(row =>
								getOrderRowWithIdOrSortId(row, removedRow)
							);
							if (orderRowIndex > -1) {
								actions.deleteOrderRow(orderRowIndex);
							}
						}
					});
				}
			} catch (err) {
				logError(err, `updateOrderFromApp error ${updatedOrder?.id}`);
			}
		}

		function setupOrderListenTo() {
			const state = getAutoDraftState();

			const listenTo = state.editOrderListeners.reduce<{ fields: string[]; all: boolean }>(
				(acc, integration) => {
					if (acc.all) {
						return acc;
					}

					if (!integration.listenTo) {
						acc.all = true;
						return acc;
					}

					acc.fields = [...acc.fields, ...integration.listenTo];

					return acc;
				},
				{ fields: [], all: false }
			);

			if (!listenTo.all) {
				state.listenTo = listenTo.fields;
			} else {
				state.listenTo = undefined;
			}
		}

		const integrationCalls: Record<
			number,
			{
				currentOrderEditCall?: CancelablePromise<any>;
				previousFieldActions?: string;
				orderBefore?: Order;
			}
		> = {};

		const callEditOrderListeners = _.debounce(async (order: Order, previousOrder?: Order, orderChanges?: any) => {
			let state = getState();

			if (!state.editOrderListeners?.length) {
				return;
			}

			disableSaveButton(state.disabledSaveReasons.pendingIntegrationRequest);

			for (const integration of state.editOrderListeners) {
				try {
					if (!integrationCalls[integration.id]) {
						integrationCalls[integration.id] = {};
					}

					integrationCalls[integration.id].currentOrderEditCall?.cancel();

					const previousOrderForIntegration = integrationCalls[integration.id].orderBefore || previousOrder;

					integrationCalls[integration.id].currentOrderEditCall = makeCancelable(
						StandardIntegration.data(customerId, { params: { hideBodyLog: true } })
							.run({
								data: {
									order,
									previousOrder: previousOrderForIntegration,
									changes: previousOrderForIntegration
										? getOrderChanges(order, previousOrderForIntegration)
										: null
								},
								type: 'orderedit',
								integrationId: integration.id
							})
							.catch(() => {
								enableSaveButton();
								integrationCalls[integration.id].currentOrderEditCall?.cancel();
							})
					);

					integrationCalls[integration.id].orderBefore = { ..._.cloneDeep(order) };

					const fieldActions = (await integrationCalls[integration.id].currentOrderEditCall!.promise).data;

					// Update state to fetch after API call
					state = getState();

					if (state.hasAppFrameworkListenTo && fieldActions.listenTo?.order) {
						integration.listenTo = OnOrderEditHelpers.getKeysAsPaths(
							fieldActions.listenTo.order,
							state.order as unknown as Order,
							true,
							undefined,
							undefined,
							true
						);
					}

					if (state.hasOnOrderEditPerformanceEnhanced) {
						if (JSON.stringify(fieldActions) !== integrationCalls[integration.id]?.previousFieldActions) {
							await updateOrderFromApp(
								fieldActions,
								integrationCalls[integration.id].orderBefore!,
								order,
								orderChanges
							);
						}

						integrationCalls[integration.id].previousFieldActions = JSON.stringify(fieldActions);
					} else {
						await updateOrderFromApp(
							fieldActions,
							integrationCalls[integration.id].orderBefore!,
							order,
							orderChanges
						);

						// Update state to fetch after API call
						state = getState();
					}
				} catch (err) {
					logError(err, `OnOrderEdit error for integration ${integration.id}`);
				}
			}

			enableSaveButton();

			if (state.hasAppFrameworkListenTo) {
				setupOrderListenTo();
			}
		}, 500);

		function callEditOrderListenersIfChanged(_updatedOrder: EditOrderOrder, _previousOrder: EditOrderOrder) {
			const state = getState();

			const updatedOrder = _updatedOrder as unknown as Order;
			const previousOrder = _previousOrder as unknown as Order;

			const orderChanges = getOrderChanges(updatedOrder, previousOrder);

			if (state.listenTo) {
				const shouldRunCallEditOrderListeners = state.listenTo.some(field => {
					if (!orderChanges) {
						return false;
					}

					if (field.includes('orderRow') && orderChanges.orderRow) {
						return orderChanges.orderRow.some((orderRowChange: any) => {
							if (field.includes('custom')) {
								if (!orderRowChange.custom) {
									return false;
								}

								return orderRowChange.custom.some((customFieldChange: any) => {
									const customFieldId = OnOrderEditHelpers.getCustomFieldIdFromPath(field);
									if (!customFieldId) {
										return false;
									}
									return customFieldId === customFieldChange.id;
								});
							} else {
								const attribute = field.split('.').pop();
								return _.has(orderRowChange, attribute!);
							}
						});
					}

					if (field.includes('custom')) {
						if (!orderChanges.custom) {
							return false;
						}

						const customFieldId = OnOrderEditHelpers.getCustomFieldIdFromPath(field);

						return orderChanges.custom.some((customFieldChange: any) => {
							return customFieldId === customFieldChange.id;
						});
					}

					return _.has(orderChanges, field);
				});

				if (!shouldRunCallEditOrderListeners) {
					return;
				}
			}

			if (orderChanges) {
				if (!state.lastChangesFromEditListener) {
					// eslint-disable-next-line no-undef
					callEditOrderListeners(_.cloneDeep(updatedOrder), previousOrder, orderChanges);
				} else {
					// This check is needed to prevent infinite loop, since updates from the callEditOrderListeners will trigger this watcher again
					const changesFromEditListener =
						JSON.stringify(orderChanges || '') === state.lastChangesFromEditListener;

					if (!changesFromEditListener) {
						// eslint-disable-next-line no-undef
						callEditOrderListeners(_.cloneDeep(updatedOrder), previousOrder, orderChanges);
					}
				}
			}
		}

		function disableSaveButton(disabledTooltip: string) {
			const state = getAutoDraftState();

			const tooltipUnchanged = state.saveDisabledTooltip === disabledTooltip;

			if (state.saveDisabled && tooltipUnchanged) {
				return;
			}

			state.saveDisabled = true;
			state.saveDisabledTooltip = disabledTooltip;
		}

		function enableSaveButton() {
			const state = getAutoDraftState();

			if (!state.saveDisabled || state.appDisabledSaveButton) {
				return;
			}

			state.saveDisabled = false;
			state.saveDisabledTooltip = '';
		}

		function changeRowOrder(index: number, newIndex: number) {
			const state = getAutoDraftState();

			const [row] = state.order.orderRow.splice(index, 1);
			state.order.orderRow.splice(newIndex, 0, row);
			state.order.orderRow.forEach((r, i) => (r.sortId = i + 1));
			actions.setFormDirty();
		}

		// Thank you Chat-GPT
		function getOffset(element: Element) {
			const rect = element.getBoundingClientRect();
			const scrollLeft = window.scrollX || document.documentElement.scrollLeft;
			const scrollTop = window.scrollY || document.documentElement.scrollTop;

			return {
				top: rect.top + scrollTop,
				left: rect.left + scrollLeft
			};
		}

		function notifyErrorSubmit() {
			const state = getAutoDraftState();

			state.OrderForm.$submitted = true;

			// Check for errors, prevent submit and set form to dirty so the view can display them
			if (state.OrderForm.$invalid) {
				state.OrderForm.$dirty = true;

				// Scroll to first faulty field
				setTimeout(() => {
					const modal = document.querySelector(`.EditOrder[data-modal-id="${modalId}"]`);

					if (!modal) {
						return;
					}

					const scrollElement = modal.querySelector(`.up-modal-content`);

					if (!scrollElement) {
						return;
					}

					const errorElement = modal.querySelector(`.has-error`);

					if (!errorElement) {
						return;
					}

					const posTop = getOffset(errorElement).top - getOffset(scrollElement).top + scrollElement.scrollTop;
					scrollElement.scrollTo({ top: posTop - 50, behavior: 'smooth' });

					const input = errorElement.querySelector('input');

					if (!input) {
						return;
					}

					input.focus({ preventScroll: true });
				}, 20);

				// Show notification
				NotificationService.addNotification({
					style: NotificationService.style.WARN,
					icon: 'warning',
					title: $translate('default.error'),
					body: $translate('default.youHaveFormErrors')
				});

				return false;
			} else {
				actions.setFormPrisitine();

				return true;
			}
		}

		function getRowCustomFields(rowProduct: Product, rowCustomFields: EditOrderCustomField[]) {
			const productCategoryId = rowProduct?.category?.id;

			if (!productCategoryId || !rowCustomFields.length) {
				return rowCustomFields;
			}

			const productCategory = productCategories.find(pc => pc.id === productCategoryId);

			if (!productCategory?.orderRowFields) {
				return rowCustomFields;
			}

			return rowCustomFields.filter(cf => productCategory.orderRowFields.find(rf => rf.id === cf.id));
		}

		function getUniqueOrderRowId(orderRow: { uuid?: number; id?: number }) {
			return orderRow.uuid ?? orderRow.id ?? getUniqueId();
		}

		function getNewOrderRow() {
			const orderRow = Order.newRow() as EditOrderOrderRow;
			orderRow.uuid = getUniqueOrderRowId(orderRow);
			return orderRow;
		}

		function addOrderRowWithProduct(product: Product) {
			const state = getAutoDraftState();

			let orderRow;
			let isNewRow = false;

			if (state.order.orderRow.length === 1 && !state.order.orderRow[0].product) {
				orderRow = state.order.orderRow[0];
			} else {
				orderRow = getNewOrderRow();
				isNewRow = true;
			}

			orderRow.product = product;
			orderRow.priceListId = state.priceList.id;
			const length = isNewRow ? state.order.orderRow.push(orderRow) : 1;

			// Find prev row
			if (state.order.orderRow[length - 2]) {
				// Set sort id on added row to one more than prev
				state.order.orderRow[length - 1].sortId = state.order.orderRow[length - 2].sortId + 1;
			}

			productChange(orderRow);
			actions.setFormDirty();
		}

		function changePriceList(init: boolean, updatePrice: boolean = true) {
			const state = getAutoDraftState();

			let priceList = state.priceList;

			if (!state.hasPriceLists) {
				priceList = state.priceLists.find(priceList => priceList.isDefault)!;
				state.priceList = priceList;
			}

			if (priceList) {
				state.showPriceListDiscount = priceList.showDiscount && priceList.id !== state.defaultPriceListId;
				state.order.priceListId = priceList.id;

				_.each(state.order.orderRow, function (row) {
					row.priceListId = priceList.id;
					if (!init && state.hasPriceLists && updatePrice) {
						productChange(row, true);
					}
				});
			}
		}

		function getRiskChipProps(order: EditOrderOrder | Order): State['riskChipProps'] {
			const risk = getOrderForecastingRisk(order as Order);

			if (!risk) {
				return null;
			}

			return {
				title: $translate(`forecastingRisk.${risk.type}`),
				chipType: risk.type === ForecastingRiskLevel.High ? 'danger' : 'alert',
				tooltip: risk.tooltip(order as Order),
				riskName: risk.field,
				riskOccured: order.risks[risk.field]!
			};
		}

		function setClientOrderRelationTitle() {
			const state = getAutoDraftState();

			let clientOrderRelationTitle = $translate('default.clientConnection');

			if (
				!state.order.clientConnection ||
				!FeatureHelper.hasSoftDeployAccess('NEW_FIELDS') ||
				!metadata.standardFields?.Order?.ClientOrderRelation?.active
			) {
				state.clientOrderRelationTitle = clientOrderRelationTitle;
				return;
			}

			// When the clientConnection is saved to the opportunity/order, we only have name and id.
			// So we need to lookup the full clientConnection object from the relatedClients array
			const clientConnection = state.relatedClients.find(
				relatedClient => relatedClient.id === state.order.clientConnection!.id
			);

			if (!clientConnection) {
				state.clientOrderRelationTitle = clientOrderRelationTitle;
				return;
			}

			const foundFieldTranslationForRelation = Object.entries(
				state.clientOrderRelationTranslations
			).reduce<null | Translation>((foundTranslation, [, translations = []]) => {
				if (foundTranslation) {
					return foundTranslation;
				}

				const relationMatch = translations.find(
					translation => translation.value === clientConnection.descriptionChildParent
				);

				if (!relationMatch) {
					return null;
				}

				const language = self.language.split('-')[0];

				const foundMatchingLanguageTranslation = translations.find(
					translation => translation.language === language
				);

				if (!foundMatchingLanguageTranslation) {
					return null;
				}

				foundTranslation = foundMatchingLanguageTranslation;

				return foundTranslation;
			}, null);

			if (!foundFieldTranslationForRelation) {
				state.clientOrderRelationTitle = clientOrderRelationTitle;
				return;
			}

			clientOrderRelationTitle += `- ${foundFieldTranslationForRelation.value}`;
			state.clientOrderRelationTitle = clientOrderRelationTitle;
		}

		function setClientOrderRelation() {
			const state = getAutoDraftState();

			// Set clientOrderRelation variable
			const clientRelationField = _.get(
				metadata.standardFields,
				'Order.ClientOrderRelation',
				{}
			) as StandardFieldConfig;

			if (FeatureHelper.hasSoftDeployAccess('NEW_FIELDS') && clientRelationField.active) {
				const [language] = (self.language || '').split('-');

				state.clientOrderRelation =
					_.get(clientRelationField, `fieldNameTranslations[${language}].value`) ||
					$translate(clientRelationField.nameTag);
			} else {
				state.clientOrderRelation = metadata.params.clientOrderRelation;
			}

			setClientOrderRelationTitle();
		}

		function getConnectedClients() {
			const state = getAutoDraftState();

			if (!state.clientOrderRelation || !state.order.client || !state.order.client.id) {
				return;
			}

			state.relatedClients = [];

			accountRelations
				.get(state.account, true)
				.then(function (res: ConnectedClient[]) {
					const state = getAutoDraftState();

					const relatedClients = _.unique(res, 'id');
					state.relatedClients = relatedClients;

					setClientOrderRelation();
				})
				.catch(function () {});
		}

		function resetEditContactModel() {
			const state = getAutoDraftState();

			state.editContactModel.id = null;
			state.editContactModel.name = '';
			state.editContactModel.firstName = '';
			state.editContactModel.lastName = '';
			state.editContactModel.title = '';
			state.editContactModel.titleCategory = {};
			state.editContactModel.phone = '';
			state.editContactModel.cellPhone = '';
			state.editContactModel.email = '';
		}

		function getOrderFormCustomFieldName({ fieldId }: { fieldId: number }, isRow?: boolean, rowIdx?: number) {
			return isRow ? `upCustomRowField_${fieldId}_${rowIdx!}` : `upCustomField_${fieldId}`;
		}

		function customFieldMapFn(custom: EditOrderCustomField, isRow?: boolean, rowIdx?: number) {
			const state = getAutoDraftState();

			if (custom.hasOwnProperty('alwaysRequired') && !custom.alwaysRequired) {
				custom.obligatoryField = false;

				if (isRow) {
					const orderRow = state.order.orderRow[rowIdx!];
					orderRow.$mappedCustom[custom.fieldId] = custom;
				}
			}

			const currentStageRequired = custom.stages?.some(
				stage => stage.id === state.order.stage.id && stage.required
			);

			if (currentStageRequired) {
				custom.alwaysRequired = custom.obligatoryField;
				custom.obligatoryField = true;

				if (isRow) {
					const orderRow = state.order.orderRow[rowIdx!];
					orderRow.$mappedCustom[custom.fieldId] = custom;
				}
			} else if (!custom.obligatoryField) {
				const name = getOrderFormCustomFieldName(custom, isRow, rowIdx);

				if (name) {
					actions.onFormConponentChange(name, true, false);
				}
			}

			return custom;
		}

		function getClientIds() {
			const state = getState();

			const clientIds = getRelatedClientIds(state.order, state.hasSubAccounts);

			clientIds.unshift(state.order.client!.id);

			return clientIds;
		}

		// The typcasts in this function are scuffed, don't have time to fix it
		function groupContacts(contacts: Contact[], includeNoContact: boolean = true) {
			const state = getState();

			const noContactContact = { id: null, name: $translate('default.noContact') } as unknown as Contact;

			if (!state.hasSubAccounts) {
				if (includeNoContact) {
					contacts.unshift(noContactContact);
				}

				return contacts;
			}

			const predicate = (contact: Contact) => contact.client.id === state.order.client?.operationalAccount?.id;
			const [mainContacts, regularContacts] = binaryGroup(contacts, predicate);

			if (includeNoContact) {
				regularContacts.unshift(noContactContact);
			}

			if (mainContacts.length > 0) {
				const mainContactsGroup = {
					name: $translate('default.fromMainAccount'),
					children: mainContacts
				} as unknown as Contact;
				regularContacts.push(mainContactsGroup);
			}

			return regularContacts;
		}

		function fixClientStuff(res: { data: EditOrderClient | Client }, init: boolean) {
			const state = getAutoDraftState();

			state.order.lastSuccess.client = state.order.client;
			state.account = res.data as EditOrderClient;

			if (state.hasAppFrameworkListenTo) {
				state.order.client = state.account;
			}

			const isCopy = !!modalParams.copy;

			if (!isCopy || (isCopy && init)) {
				updatePriceList(init, !isCopy);
			}

			if (state.account.webpage && state.account.webpage.indexOf('http') !== 0) {
				state.account.webpage = 'http://' + state.account.webpage;
			}

			if (state.hasMaxDiscount) {
				state.order.orderRow.forEach(row => {
					row.maxDiscount = getMaxDiscount(row as OrderRow);

					if (isCopy) {
						checkAndSetExcessiveDiscount(row);
					}
				});
			}

			getConnectedClients();

			const clientIds = getClientIds();

			const fields = ['id', 'name', 'email', 'cellPhone', 'phone', 'client', 'title', 'titleCategory'];

			utils.req
				.clientContactsAndRelations(clientIds, fields)
				.then(function (res: { metadata: { total: number; limit: number; offset: number }; data: Contact[] }) {
					const state = getAutoDraftState();

					if (res.metadata.total > 1000) {
						state.useAjaxContactSelect = true;
					} else {
						state.useAjaxContactSelect = false;
					}

					const contacts = groupContacts(res.data);
					state.contacts = contacts;

					// find contact and set it on the order so we get contact email
					if (init && state.order.contact) {
						const found = _.find(contacts, { id: state.order.contact.id }) as EditOrderContact | undefined;

						if (found) {
							state.order.contact = found;
						}
					}

					if (contacts.length && !init) {
						$rootScope.$broadcast(`EditOrder.${modalId}.accountChanged`);
					}
				})
				.catch((err: unknown) => {
					if (err instanceof Error) {
						logError(err);
					}
				});
		}

		function handleInactivePriceList(priceListId: number) {
			const state = getAutoDraftState();

			const priceList = AppService.getPriceLists().find(priceList => priceList.id === priceListId);

			if (!priceList) {
				// Price list somehow doesn't exist so we use the default list
				// This shouldn't happen since you cannot delete a price list
				state.priceList = state.priceLists.find(priceList => priceList.isDefault)!;
			} else {
				if (!priceList.active) {
					priceList.name = priceList.name + ' (' + T('default.inactive') + ')';
				}
				state.priceList = priceList;
			}
		}

		// Use order > client > default price list
		function updatePriceList(init: boolean, updatePrice: boolean = true) {
			const state = getAutoDraftState();

			if (state.priceLists) {
				if (state.order.priceListId && init) {
					const priceList = state.priceLists.find(priceList => priceList.id === state.order.priceListId);
					if (priceList) {
						state.priceList = priceList;
					} else {
						// The price list on the order has been inactivated
						handleInactivePriceList(state.order.priceListId);
					}
				} else if (state.account && state.account.priceListId !== null) {
					const priceList = state.priceLists.find(priceList => priceList.id === state.account.priceListId);

					if (priceList) {
						state.priceList = priceList;
					} else {
						// The price list on the account has been inactivated
						state.priceList = state.priceLists.find(priceList => priceList.isDefault)!;
					}
				} else {
					state.priceList = state.priceLists.find(priceList => priceList.isDefault)!;
				}

				changePriceList(init, updatePrice);
			}
		}

		// Function to get new contacts when the client field changes
		function accountChange(init: boolean) {
			const state = getAutoDraftState();

			const changedBackClient =
				state.order.client && state.order.client.id === state.order.lastSuccess.client?.id;

			if (!init && !changedBackClient) {
				state.order.contact = undefined;
				state.order.clientConnection = undefined;
			}

			state.contacts = [];

			if (state.order.client && state.order.client.id && state.order.client.id > 0) {
				state.fetchingAgreements = true;

				getAllActiveAgreements(state.order.client.id)
					.then(activeAgreements => {
						const state = getAutoDraftState();

						state.activeAgreementGroups = activeAgreements;
					})
					.catch(err => {
						if (err instanceof Error) {
							logError(err, 'Could not fetch agreements');
						}
					})
					.finally(() => {
						const state = getAutoDraftState();

						state.fetchingAgreements = false;
					});

				if (init) {
					fixClientStuff(meta.client, init);
				} else {
					if (!changedBackClient) {
						Account.customer(customerId)
							.get(state.order.client.id)
							.then(res => fixClientStuff(res, init))
							.catch(err => {
								const state = getAutoDraftState();

								logError(err, 'EditOrder.accountChange fetch client failed');

								NotificationService.addNotification({
									title: 'default.error',
									body: 'fetchError.client',
									style: NotificationService.style.ERROR,
									icon: 'times'
								});

								if (!state.order.lastSuccess.client) {
									state.order.client = null;
								} else {
									state.order.client = state.order.lastSuccess.client;
									state.order.clientConnection = state.order.lastSuccess.clientConnection!;

									$rootScope.$broadcast(`EditOrder.${modalId}.accountChanged`);

									accountChange(false);
								}
							});
					}
				}
			} else {
				state.useAjaxContactSelect = false;
			}

			if (state.editContact) {
				actions.abortContactEdit();
			}
		}

		function calcAge() {
			const state = getAutoDraftState();

			if (state.isOrder) {
				state.age = moment(state.order.closeDate).diff(state.order.regDate, 'day');
			} else {
				state.age = moment().diff(state.order.regDate, 'day');
			}

			state.isOld = actions.isOldDate();
		}

		function getAvailableSalesCoaches() {
			const state = getAutoDraftState();

			state.availableSalescoaches.splice(0, state.availableSalescoaches.length);

			for (const salesCoach of state.allSalescoaches) {
				const salesCoachUsers = salesCoach?.users;
				const salesCoachRoles = salesCoach?.roles;
				// If both lists are empty then by default it is available for all
				if (salesCoachUsers?.length === 0 && salesCoachRoles?.length === 0) {
					state.availableSalescoaches.push(salesCoach);
				} else {
					const includesUser = salesCoachUsers?.includes(state.order.user.id);
					const includesRole = state.order.user.role?.id
						? salesCoachRoles?.includes(state.order.user.role.id)
						: false;

					if (includesUser || includesRole) {
						state.availableSalescoaches.push(salesCoach);
					}
				}
			}

			state.hasSalesCoach = !!(state.availableSalescoaches.length && !state.order.closeDate);

			if (state.availableSalescoaches.length === 0) {
				if (state.order.salesCoach) {
					state.order.salesCoach = null;
				}
			} else if (state.availableSalescoaches.length === 1) {
				state.order.salesCoach = state.availableSalescoaches[0];
			} else if (state.order.salesCoach) {
				const currentCoach = state.availableSalescoaches.find(coach => coach.id === state.order.salesCoach!.id);
				if (!currentCoach) {
					state.order.salesCoach = null;
				}
			}
		}

		// if you remove this function, you need to add product.isRecurring to order index (and obv. fix product fields)
		function fixProductFields(row: EditOrderOrderRow) {
			const state = getAutoDraftState();

			if (row.product) {
				const product = _.find(state.orderProducts, { id: row.product.id });

				if (product) {
					product.custom = _.map(product.custom, function (c) {
						const field = _.find(meta.productCustomFields, { id: c.fieldId });

						if (field) {
							// @ts-expect-error Not going to bother with this typings
							c.datatype = field.datatype;
						}

						return c;
					});

					row.product = product;
				}
			}

			return row;
		}

		function save(doNotResolve?: boolean) {
			const state = getAutoDraftState();

			state.saving = true;
			state.saveError = null;
			calcAge();

			// Remove empty rows
			state.order.orderRow = _.filter(state.order.orderRow, function (row) {
				return row.product && row.product.id;
			});

			const order = _.cloneDeep(getPlainObject(state.order)) as unknown as Order;

			// @ts-expect-error Not going to bother with this typings
			order.account = order.client;
			order.value = actions.calc.totalNet();
			// @ts-expect-error Not going to bother with this typings
			order.date = moment(order.date).format('YYYY-MM-DD');
			order.orderRow = _.sortBy(order.orderRow, 'sortId');

			let sortId = 1;

			// remove empty orderRows(no product)
			_.remove(order.orderRow, function (row) {
				// Remove rows with missing product
				if (!row.product) {
					return true;
				}

				// @ts-expect-error Not going to bother with this typings
				row.custom = MapHelpers.mapCustom(row.$mappedCustom);
				row.sortId = sortId++;
				row.price = row.price / order.currencyRate;
				// @ts-expect-error Not going to bother with this typings
				row.listPrice = row.$listPrice / order.currencyRate;
				row.purchaseCost = row.purchaseCost / order.currencyRate;

				// Set back quantity to 1 for tiered isTotalPrice products and keep "real quantity" in tierQuantity
				if (isTieredOrderRow(row)) {
					row.tierQuantity = row.quantity;

					if (isTieredTotalOrderRow(row)) {
						row.quantity = 1;
					}
				}

				return false;
			});

			if (!order.description || order.description === '') {
				order.description = '<<' + $translate('default.noDescription') + '>>';
			}

			if (state.lighterRowsOnOrderSave) {
				// @ts-expect-error Not going to bother with this typings
				order.orderRow = order?.orderRow?.map(row => ({ ...row, product: { id: row.product.id } }));
			}

			state.orderValue = order.value;

			// No need to call getPlainObject here as order is cloned with _.cloneDeep above
			return Order.customer(customerId)
				.save(order)
				.then(function (response) {
					const state = getAutoDraftState();

					// Go to overview tab
					state.edit = true;
					state.order.id = response.data.id;
					state.order.annualValue = response.data.annualValue;
					state.order.monthlyValue = response.data.monthlyValue;
					state.isOrder = response.data.probability === 0 || response.data.probability === 100;

					if (state.order.$$activityId) {
						const activity = {
							id: state.order.$$activityId,
							opportunity: { id: state.order.id }
						};

						Activity.customer(customerId).save(activity, { skipNotification: true });
					}
					if (state.order.$$appointmentId) {
						const appointment = {
							id: state.order.$$appointmentId,
							opportunity: { id: state.order.id }
						};

						Appointment.customer(customerId).save(appointment, { skipNotification: true });
					}

					if (state.OrderForm) {
						actions.setFormPrisitine();
					}

					// Why can we not just do state.order.orderRow = response.data.orderRow;
					const responseOrderRows = _.sortBy(response.data.orderRow || [], 'sortId');

					// TODO: I think this looks super wierd and may lead to bugs
					state.order.orderRow = state.order.orderRow.map((row, index) => {
						if (responseOrderRows[index]) {
							row.uuid = responseOrderRows[index].id!;
							row.id = responseOrderRows[index].id!;
						}
						return row;
					});

					if (!doNotResolve) {
						resolve(response.data);
					}

					modalParams.afterSave?.(response.data);

					state.saving = false;

					calcAge();

					state.riskChipProps = getRiskChipProps(response.data);
				})
				.catch((e: unknown) => {
					const state = getAutoDraftState();

					if (state.quickCreate) {
						delete state.quickCreate;
					}

					state.saving = false;
					state.saveError = e;
				});
		}

		function openOpportunityWithSubs(preSelectedStage: Stage) {
			const state = getState();

			const order = getPlainObject(state.order) as unknown as Order;
			const activeAgreementGroups = getPlainArray(state.activeAgreementGroups);

			const props = {
				order,
				client: order.client,
				currentStage: order.stage.name,
				preSelectedStage: preSelectedStage,
				activeAgreementGroups
			};

			openModal('WonOpportunityWithSubs', {
				...props,
				openWonModalFollowedByDiffOrder: () => {
					const state = getAutoDraftState();

					state.skipButtonOnWonModal = true;
					state.visibleWon = true;

					setTimeout(() => {
						const state = getState();
						$upModal.open('editOrder', { id: state.order.id });
					}, 2500);
				},
				saveWithStageAndOpenWonModal: async (stage: Stage) => {
					const preSaveState = getAutoDraftState();

					preSaveState.skipButtonOnWonModal = true;
					preSaveState.order.stage = stage;
					preSaveState.order.probability = stage.probability;

					await actions.save(true, true, true);

					const postSaveState = getAutoDraftState();

					postSaveState.visibleWon = true;
				}
			});
		}

		function editProductBundleSave(orderRowIndex: number, updatedOrderRow: EditOrderOrderRow) {
			const state = getAutoDraftState();

			const orderRow = state.order.orderRow[orderRowIndex];
			Object.assign(orderRow, updatedOrderRow);

			orderRow.$listPrice = orderRow.listPrice;
			orderRow.$discount = orderRow.discount;
			orderRow.$discountRaw = orderRow.discount;
		}

		function hasExcessiveDiscount(orderRow: EditOrderOrderRow) {
			const percentage =
				orderRow.$discountPercent ??
				// @ts-expect-error isNaN works with strings to... isNaN('10') === false
				(isNaN(orderRow.$percentPlaceholder) ? 0 : Number(orderRow.$percentPlaceholder)) ??
				0;

			return (
				orderRow.maxDiscount !== null && orderRow.maxDiscount !== undefined && percentage > orderRow.maxDiscount
			);
		}

		function checkAndSetExcessiveDiscount(orderRow: EditOrderOrderRow) {
			orderRow.hasExcessiveDiscount = hasExcessiveDiscount(orderRow);

			if (self.administrator) {
				actions.onFormConponentChange('excessiveDiscount', true, false);
			} else {
				const valid = !orderRow.hasExcessiveDiscount;
				actions.onFormConponentChange('excessiveDiscount', valid, false);
			}
		}

		// This one is only used in this file, no real point in exposing it on the actions object
		function adjustPriceIfRecurring(price: number, product: Product, oldInterval: number | undefined = undefined) {
			const state = getState();

			const productInterval = product.recurringInterval ?? getRecurringProductDefaultInterval(metadata);
			const hasRecurringInterval =
				product.isRecurring && productInterval && state.order.recurringInterval && state.hasRecurringInterval;

			if (!hasRecurringInterval) {
				return price;
			}

			return price * (state.order.recurringInterval / (oldInterval ?? productInterval));
		}

		function calculateDiscount(orderRow: EditOrderOrderRow) {
			const state = getState();

			const percentFocused = orderRow.$discountPercent !== undefined && !isNaN(orderRow.$discountPercent);
			const percent = percentFocused
				? orderRow.$discountPercent
				: parseFloat((100 - (orderRow.price / orderRow.$listPrice) * 100).toFixed(state.numOfDecimalsPrice));

			// @ts-expect-error isNaN works with strings to... isNaN('10') === false
			if (isNaN(percent)) {
				return;
			}

			const quantity = isTieredTotalOrderRow(orderRow) ? 1 : orderRow.quantity;

			if (orderRow.price > orderRow.$listPrice || orderRow.$listPrice === 0) {
				orderRow.$discount = undefined;
				orderRow.$discountRaw = 0;
				orderRow.$percentPlaceholder = 0;
			} else {
				if (percentFocused) {
					orderRow.$discountRaw = (orderRow.$listPrice * quantity * (percent! / 100)).toFixed(
						state.numOfDecimalsPrice
					);
					orderRow.$discount = undefined;
				} else {
					orderRow.$discountRaw = ((orderRow.$listPrice - orderRow.price) * quantity).toFixed(
						state.numOfDecimalsPrice
					);
					// @ts-expect-error $discountRaw !== 0 is always true as we set it to the result of toFixed above, but I will keep it as the angular code looked like that
					orderRow.$percentPlaceholder = orderRow.$discountRaw !== 0 ? percent : 0;
					orderRow.$discount = parseFloat(orderRow.$discountRaw);
				}
			}

			checkAndSetExcessiveDiscount(orderRow);
		}

		function productCategoryMagic(orderRow: EditOrderOrderRow) {
			const state = getAutoDraftState();

			const productCategory = productCategories.find(
				productCategory => productCategory.id === orderRow.product?.category?.id
			);

			if (state.hasProductCategoryCustomFieldVisibility) {
				for (const customField of orderRow.custom) {
					const isVisible = productCategory?.orderRowFields?.length
						? productCategory.orderRowFields.some(({ id }) => id === customField.id)
						: true;

					if (!isVisible) {
						actions.setCustomFieldValue(orderRow, customField.id, '');
					}

					customField.isHiddenByProductCategory = !isVisible;
				}
			}

			const shouldCalculateValues =
				state.hasCalculatingFields &&
				state.hasProductCategoryCustomFieldVisibility &&
				state.hasOrderRowCalculatingValues &&
				productCategory?.calculatingField?.id;

			orderRow.calculatingField = null;

			if (shouldCalculateValues) {
				const calculatingField = _.find(state.orderRowCustomFields, {
					id: productCategory.calculatingField!.id
				});

				if (calculatingField) {
					const isValidCalculatingField =
						calculatingField.formula && calculateField.isValidAsValue(calculatingField.formula);

					if (isValidCalculatingField) {
						orderRow.calculatingField = calculatingField;
						orderRow.$listPrice = calculateField.calculate(calculatingField.formula, orderRow);

						if (orderRow.$discount) {
							discountChange(orderRow);
						} else {
							if (!orderRow.$discountPercent) {
								orderRow.$discountPercent = 0;
							}
							discountPercentChange(orderRow);
						}
					}
				}
			}
		}

		function updateProjectPlanOptions() {
			const state = getAutoDraftState();

			if (state.hasProjectPlanFeature) {
				// This should be run in some product changed function and not on every change to the order ...
				state.productsWithProjectPlanTemplates =
					state.order.orderRow
						?.filter(orderRow => orderRow.product?.projectPlanTemplate)
						?.map(orderRow => orderRow.product) ?? [];

				state.order.projectPlanOptions =
					state.order.projectPlanOptions?.filter(
						({ projectPlanTemplateId }) =>
							state.order.orderRow?.some(
								orderRow => orderRow.product?.projectPlanTemplate?.id === projectPlanTemplateId
							) ?? false
					) ?? [];
			}
		}

		function productChange(orderRow: EditOrderOrderRow, keepQuantity?: boolean) {
			const state = getAutoDraftState();

			productCategoryMagic(orderRow);

			const priceList = state.priceList;
			const selectedCurrency = state.selectedCurrency;
			orderRow.priceListId = priceList.id;

			if (!orderRow.product) {
				return;
			}

			if (orderRow.product.bundle?.length) {
				// Reset discount when chaning product or price list
				orderRow.discountPercent = 0;
				orderRow.discount = 0;
				orderRow.bundleFixedPrice = orderRow.product.bundleFixedPrice;

				const bundleOrderRow = resetAndGetBundleOrderRow(
					// At some point we should unify the typings
					orderRow as OrderRow,
					selectedCurrency.iso,
					state.order.recurringInterval,
					keepQuantity ? orderRow.quantity : undefined,
					true
				);

				// This is how angular works
				Object.assign(orderRow, bundleOrderRow);

				orderRow.$discount = orderRow.discount;
				orderRow.$listPrice = orderRow.listPrice;
				orderRow.category = orderRow.product.category;
			} else {
				orderRow.bundleRows = [];

				// Set listprice here and overwrite if multicurrency and selected prod has multi values
				orderRow.listPrice = orderRow.product.listPrice;
				orderRow.$listPrice = orderRow.product.listPrice;
				orderRow.category = orderRow.product.category;

				if (!keepQuantity) {
					orderRow.quantity = orderRow.tierQuantity = orderRow.oldQuantity = 1;
				}
				orderRow.$discount = 0;

				// Check if we have a matching tier and set price
				const tierObj = getTierFromOrderRow(orderRow);
				const priceObj = _.find(orderRow.product.currencies, {
					currency: selectedCurrency.iso,
					priceListId: priceList.id
				});

				const fallbackValue = selectedCurrency.masterCurrency ? orderRow.product.purchaseCost : 0;
				orderRow.purchaseCost = priceObj?.purchaseCost || fallbackValue;

				if (priceObj) {
					const listPrice = priceObj.price;
					orderRow.$listPrice = orderRow.listPrice = listPrice;

					if (state.showPriceListDiscount) {
						orderRow.price = listPrice;
						const defaultPriceObj = orderRow.product.currencies.find(
							currency =>
								currency.currency === selectedCurrency.iso &&
								currency.priceListId === state.defaultPriceListId
						);
						if (defaultPriceObj && defaultPriceObj.price > listPrice) {
							orderRow.$listPrice = orderRow.listPrice = defaultPriceObj.price;
						}
					}
				} else if (
					!tierObj &&
					(orderRow.product.isMultiCurrency || hasMultiCurrency) &&
					!selectedCurrency.masterCurrency
				) {
					orderRow.$listPrice = orderRow.listPrice = 0;
				}

				if (tierObj) {
					const listPrice = getTierPriceByCurrency(tierObj.tier, selectedCurrency.iso);
					orderRow.$listPrice = orderRow.listPrice = listPrice;
					orderRow.price = listPrice;

					if (state.showPriceListDiscount) {
						const defaultListTierObj = getTierFromOrderRow(
							orderRow,
							state.defaultPriceListId
						) as TierResponse;
						const defaultPriceObj = _.find(defaultListTierObj!.tier.currencies, {
							currency: selectedCurrency.iso
						});

						if (defaultPriceObj && defaultPriceObj.value > listPrice) {
							orderRow.$listPrice = orderRow.listPrice = defaultPriceObj.value;
						}
					}
					if (tierObj.tier.isTotalPrice) {
						orderRow.tierQuantity = orderRow.quantity;
					}
				}

				orderRow.price = adjustPriceIfRecurring(orderRow.price, orderRow.product);
				orderRow.listPrice = adjustPriceIfRecurring(orderRow.listPrice, orderRow.product);
				orderRow.$listPrice = adjustPriceIfRecurring(orderRow.$listPrice, orderRow.product);
			}

			orderRow = fixProductFields(orderRow);

			if (state.showPriceListDiscount) {
				orderRow.$discountPercent = undefined;
				calculateDiscount(orderRow);
			}

			// At some point we should unify the typings
			orderRow.maxDiscount = getMaxDiscount(orderRow as OrderRow);
			discountChange(orderRow);

			updateProjectPlanOptions();

			actions.setFormDirty();
		}

		function discountChange(orderRow: EditOrderOrderRow) {
			const state = getAutoDraftState();

			if (orderRow.$discount !== undefined && !isNaN(orderRow.$discount)) {
				orderRow.$discountPercent = undefined;
				// @ts-expect-error parseFloat returns the number if you pass a number
				orderRow.$discountRaw = parseFloat(orderRow.$discount);
				let quantity = orderRow.quantity;

				if (orderRow.$discount !== 0 && orderRow.$listPrice === 0) {
					orderRow.$percentPlaceholder = '∞';
				} else {
					if (isTieredTotalOrderRow(orderRow)) {
						quantity = 1;
					}

					orderRow.$percentPlaceholder = (
						(orderRow.$discountRaw / (orderRow.$listPrice * quantity)) *
						100
					).toFixed(state.numOfDecimalsPrice);

					// @ts-expect-error isNaN works with strings to... isNaN('10') === false
					if (isNaN(orderRow.$percentPlaceholder)) {
						orderRow.$percentPlaceholder = 0;
					}
				}

				// calc price
				orderRow.price = orderRow.$listPrice - orderRow.$discountRaw / quantity;
				orderRow.listPrice = orderRow.$listPrice;

				if (isNaN(orderRow.price)) {
					orderRow.price = 0;
				}

				if (orderRow.$discount < 0) {
					orderRow.$discount = orderRow.$discountRaw = orderRow.$percentPlaceholder = 0;
				}

				checkAndSetExcessiveDiscount(orderRow);

				// Used by order context (need for bundle summaries)
				orderRow.orderRowTotal = (orderRow.price ?? 0) * (orderRow.quantity ?? 0);

				if (orderRow.product?.isRecurring) {
					orderRow.recurringValue = getRecurringValue(orderRow.orderRowTotal, state.order.recurringInterval);
				}
			}
		}

		function discountPercentChange(orderRow: EditOrderOrderRow) {
			const state = getAutoDraftState();

			if (orderRow.$discountPercent !== undefined && !isNaN(orderRow.$discountPercent)) {
				orderRow.$discount = undefined;

				let quantity = orderRow.quantity;
				if (isTieredTotalOrderRow(orderRow)) {
					quantity = 1;
				}

				orderRow.$discountRaw = parseFloat(
					((orderRow.$discountPercent / 100) * orderRow.$listPrice * quantity).toFixed(
						state.numOfDecimalsPrice
					)
				);

				if (isNaN(orderRow.$discountRaw)) {
					orderRow.$discountRaw = 0;
				}

				// calc price
				orderRow.price = orderRow.$listPrice - orderRow.$discountRaw / quantity;
				orderRow.listPrice = orderRow.$listPrice;

				if (isNaN(orderRow.price) || orderRow.$discountPercent === 100) {
					orderRow.price = 0;
				}

				checkAndSetExcessiveDiscount(orderRow);

				// Used by order context (need for bundle summaries)
				orderRow.orderRowTotal = (orderRow.price ?? 0) * (orderRow.quantity ?? 0);

				if (orderRow.product?.isRecurring) {
					orderRow.recurringValue = getRecurringValue(orderRow.orderRowTotal, state.order.recurringInterval);
				}
			}
		}

		function quantityChange(orderRow: EditOrderOrderRow) {
			const state = getAutoDraftState();

			let quantity = orderRow.quantity;

			function isDiscountCreatedByPriceList() {
				if (!orderRow.oldQuantity) {
					orderRow.oldQuantity = quantity;
				}

				const oldTierObj = getTierFromOrderRow({
					...orderRow,
					tierQuantity: orderRow.oldQuantity,
					quantity: orderRow.oldQuantity
				});

				let oldTierPrice = oldTierObj
					? getTierPriceByCurrency(oldTierObj.tier, state.selectedCurrency.iso)
					: undefined;

				oldTierPrice = adjustPriceIfRecurring(oldTierPrice, orderRow.product);

				// @ts-expect-error parseFloat returns the number if you pass a number
				return parseFloat(orderRow.price).toFixed(1) === oldTierPrice.toFixed(1);
			}

			// Check if we have a matching tier and update price
			const tierObj = getTierFromOrderRow({ ...orderRow, tierQuantity: quantity });
			let listPrice, hasDiscount, discountCreatedByPriceList;

			if (tierObj) {
				listPrice = getTierPriceByCurrency(tierObj.tier, state.selectedCurrency.iso);
				listPrice = adjustPriceIfRecurring(listPrice, orderRow.product);

				if (state.showPriceListDiscount) {
					discountCreatedByPriceList = isDiscountCreatedByPriceList();
				}

				// @ts-expect-error parseFloat returns the number if you pass a number
				hasDiscount = parseFloat(orderRow.$discountRaw) !== 0 && !discountCreatedByPriceList;

				if (!hasDiscount) {
					orderRow.price = listPrice;
				}
				orderRow.listPrice = listPrice;
				orderRow.$listPrice = listPrice;

				if (state.showPriceListDiscount) {
					if (!hasDiscount) {
						orderRow.price = listPrice;
					}

					const defaultListTierObj = getTierFromOrderRow(
						{ ...orderRow, tierQuantity: orderRow.quantity },
						state.defaultPriceListId
					) as TierResponse;

					let defaultListPrice = getTierPriceByCurrency(defaultListTierObj.tier, state.selectedCurrency.iso);

					defaultListPrice = adjustPriceIfRecurring(defaultListPrice, orderRow.product);

					if (defaultListPrice > listPrice) {
						if (!hasDiscount) {
							orderRow.price = listPrice;
						}
						orderRow.$listPrice = orderRow.listPrice = defaultListPrice;

						listPrice = defaultListPrice;
					}
				}

				if (isTieredTotalOrderRow({ ...orderRow, tierQuantity: quantity })) {
					quantity = 1;
					orderRow.tierQuantity = orderRow.quantity;
				}

				if (isNaN(orderRow.price)) {
					orderRow.price = 0;
				}

				// At some point we should unify the typings
				orderRow.maxDiscount = getMaxDiscount(orderRow as OrderRow);
			}

			if (hasDiscount) {
				// @ts-expect-error parseFloat returns the number if you pass a number
				orderRow.price = listPrice - parseFloat(orderRow.$discountRaw) / orderRow.oldQuantity!;
			}

			calculateDiscount(orderRow);

			if (tierObj) {
				// @ts-expect-error I will ignore this error as "3" / 2 = 1.5, and this is how the Angular implementation did it, will not change it for now
				orderRow.price = listPrice - orderRow.$discountRaw / (quantity || 1);
				orderRow.oldQuantity = quantity;
			}

			// Used by order context (need for bundle summaries)
			orderRow.orderRowTotal = (orderRow.price ?? 0) * (quantity ?? 0);
			if (orderRow.product?.isRecurring) {
				orderRow.recurringValue = getRecurringValue(orderRow.orderRowTotal, state.order.recurringInterval);
			}
		}

		function priceChange(orderRow: EditOrderOrderRow) {
			const state = getAutoDraftState();

			function getProductPrice() {
				const priceListId = state.showPriceListDiscount ? state.defaultPriceListId : state.priceList.id;

				const tierObj = getTierFromOrderRow(
					{ ...orderRow, tierQuantity: orderRow.quantity },
					priceListId
				) as TierResponse;

				if (tierObj) {
					return getTierPriceByCurrency(tierObj.tier, state.selectedCurrency.iso);
				} else {
					const fallback =
						state.selectedCurrency.masterCurrency && state.priceList.isDefault
							? orderRow.product.listPrice
							: 0;

					const currencyObj = _.find(orderRow.product.currencies, {
						currency: state.selectedCurrency.iso,
						priceListId: priceListId
					});
					return currencyObj?.price || fallback;
				}
			}

			orderRow.$priceListId = state.priceList.id;
			orderRow.$discountPercent = undefined;

			if (getProductPrice() === 0) {
				orderRow.$listPrice = orderRow.price;
				orderRow.$discount = 0;
				orderRow.$discountRaw = 0;
				orderRow.$percentPlaceholder = 0;
			} else {
				calculateDiscount(orderRow);
			}

			// Used by order context (need for bundle summaries)
			orderRow.orderRowTotal = (orderRow.price ?? 0) * (orderRow.quantity ?? 0);
			if (orderRow.product?.isRecurring) {
				orderRow.recurringValue = getRecurringValue(orderRow.orderRowTotal, state.order.recurringInterval);
			}
		}

		function getLinks() {
			const state = getAutoDraftState();

			state.links = [];

			if (!state.order || !state.order.id) {
				return;
			}

			state.loadingLinks = true;

			Links.customer(customerId)
				.get('order', state.order.id)
				.then(function (response) {
					const state = getAutoDraftState();

					state.links = response.data;
					state.loadingLinks = false;
				})
				.catch(function (err: unknown) {
					const state = getAutoDraftState();

					state.loadingLinks = false;
					state.linksErr = err;
				});
		}

		function initStages(type: 'order' | 'opportunity') {
			const state = getAutoDraftState();

			if (type === 'order') {
				state.stageType = 'order';
			} else {
				state.stageType = 'all';
				if (state.order && state.order.id && state.order.probability === 0) {
					state.stageType = 'lost';
				}
			}

			const stages = AppService.getStages(state.stageType);
			state.stages = _.sortBy(stages, stage => {
				if (stage.probability === 0) {
					return 101; // Any number greater than 100 to make sure they end up last.
				}
				return stage.probability;
			});
		}

		function quickCreate() {
			const state = getAutoDraftState();

			// set active tab
			state.activeTab = state.tabs.form;
			// we only run this if we have a quickCreate object so the ! should be safe
			state.order = modalParams.quickCreate!;

			if (!state.order.client) {
				state.order.client = state.order.account;
				state.order.lastSuccess.client = state.order.client;
			}

			// find contact and set it on the order so we get contact email
			if (state.order.contact && isContacts(meta.contacts)) {
				const found = _.find(meta.contacts.data, { id: state.order.contact.id });

				if (found) {
					state.order.contact = found;
				}
			}
		}

		///////////////////// INITIALIZE STATE /////////////////////

		const initialState = getAutoDraftState();

		initialState.OrderForm = {
			$dirty: false,
			$invalid: false,
			$pristine: true,
			$submitted: false,
			$valid: true
		} as Form;

		initialState.OrderForm.excessiveDiscount = {
			$dirty: false,
			$invalid: false,
			$pristine: true,
			$valid: true
		};

		initialState.customerId = customerId;
		initialState.showInlineAction = 'none';
		initialState.visibleWon = false;
		initialState.visibleLost = false;
		initialState.savedThroughWinLossFlow = false;
		initialState.skipButtonOnWonModal = false;
		initialState.fetchingAgreements = false;
		initialState.isSupportUser = self?.support && !self?.crm;
		initialState.disabledSaveReasons = {
			pendingIntegrationRequest: $translate('order.saveDisabled.pendingIntegrationRequest')
		};
		initialState.disabledFields = {
			order: {}
		};
		initialState.visibleFields = {
			order: {}
		};
		initialState.lighterRowsOnOrderSave = FeatureHelper.hasSoftDeployAccess('LIGHTER_ROWS_ON_ORDER_SAVE');
		initialState.hasMarketContribution = FeatureHelper.hasSoftDeployAccess('ORDER_MARKETING_CONTRIBUTION');
		initialState.hasSubAccounts = FeatureHelper.hasSoftDeployAccess('SUB_ACCOUNTS');
		initialState.hasTodo = FeatureHelper.hasSoftDeployAccess('TODO_LIST');
		initialState.hasPriceLists =
			FeatureHelper.hasSoftDeployAccess(FeatureHelper.Feature.PRICE_LISTS) &&
			FeatureHelper.isAvailable(FeatureHelper.Feature.PRICE_LISTS);
		initialState.hasProductBundles =
			FeatureHelper.hasSoftDeployAccess('PRODUCT_BUNDLE') &&
			FeatureHelper.isAvailable(FeatureHelper.Feature.PRODUCT_BUNDLES);
		// @ts-expect-error Well either client is not a valid value or the typings of HistoryLogType are wrong
		initialState.notesFilterOrder = ['opportunity', 'client'];
		initialState.hasSupportAccess =
			FeatureHelper.hasSoftDeployAccess('SUPPORT_SERVICE_V2') &&
			FeatureHelper.isAvailable(FeatureHelper.Feature.CUSTOMER_SUPPORT);
		initialState.editable = true;
		initialState.saving = false;
		initialState.editContact = false;
		initialState.editContactModel = {};
		initialState.contactFormIsEdit = false;
		initialState.relatedClients = [];
		initialState.probabilityActive = false;
		initialState.hideProbability = true;
		initialState.hasAccountPlan = FeatureHelper.hasSoftDeployAccess('ACCOUNT_PLAN');
		initialState.hasPeriodization = FeatureHelper.isAvailable(FeatureHelper.Feature.PERIODIZATION);
		initialState.hasStakeholders = FeatureHelper.isAvailable(FeatureHelper.Feature.STAKEHOLDERS);
		initialState.hasSalesProcessFeature = FeatureHelper.isAvailable(FeatureHelper.Feature.SALES_PROCESS);
		initialState.hasSalesProcessProFeature = FeatureHelper.isAvailable(FeatureHelper.Feature.SALES_PROCESS_PRO);
		initialState.salesCoachDecisionMaker = {};
		initialState.newTitleCategories = [];
		initialState.decisionMakersEnabled = false;
		initialState.oldStakeholdersEnabled = false;
		initialState.hasSalesCoach = false;
		initialState.hasEsignActivated = integrations.length > 0 ? true : false;
		initialState.hasOrderCustomStages = FeatureHelper.isAvailable(FeatureHelper.Feature.ORDER_CUSTOM_STAGES);
		initialState.isPeriodizing = modalParams.showPeriodizing ? true : false;
		initialState.hasProjectPlanFeature = FeatureHelper.isAvailable(FeatureHelper.Feature.PROJECT_PLAN);
		initialState.productsWithProjectPlanTemplates = [];
		initialState.hasProductSearch =
			FeatureHelper.hasSoftDeployAccess('PRODUCT_SEARCH') &&
			FeatureHelper.isAvailable(FeatureHelper.Feature.PRODUCT_SEARCH);
		initialState.hasActivitiesAndAppointments = FeatureHelper.isAvailable(
			FeatureHelper.Feature.ACTIVITIES_AND_APPOINTMENTS
		);
		initialState.hasAppFrameworkOnOrderEdit = FeatureHelper.hasSoftDeployAccess('APP_FRAMEWORK_ON_ORDER_EDIT');
		initialState.hasOnOrderEditPerformanceEnhanced = FeatureHelper.hasSoftDeployAccess(
			'ON_ORDER_EDIT_PERFORMANCE_ENHANCED'
		);
		initialState.hasFormulaVisible = FeatureHelper.hasSoftDeployAccess('FORMULA_VISIBLE');

		initialState.editOrderListeners =
			initialState.hasAppFrameworkOnOrderEdit && metadata.integrations.inits.after_order_edit
				? metadata.integrations.inits.after_order_edit
				: [];

		initialState.copyButtonVisible =
			!FeatureHelper.hasSoftDeployAccess('HIDE_COPY_ORDER_BUTTON') ||
			metadata.standardFields?.Order.ShowCopyButton?.active;

		if (FeatureHelper.hasSoftDeployAccess('INPUT_DEBOUNCE_SETTING')) {
			const appsWithInputDebounce = metadata.integrations.active.filter(app => !!app.inputDebounceInMs);

			if (appsWithInputDebounce.length > 0) {
				//use max value for now but since you can have different values for different integrations
				//but maybe you need a input debounce for each integration in the future instead of just one
				const debounceValues = appsWithInputDebounce
					.filter(app => app.inputDebounceInMs !== null)
					.map(app => app.inputDebounceInMs) as number[];
				initialState.inputDebounceSetting = Math.max(...debounceValues);
			}
		}

		initialState.customerHasNewFields = FeatureHelper.hasSoftDeployAccess(FeatureHelper.Feature.NEW_FIELDS);
		initialState.links = [];
		initialState.apps = metadata.integrations.active;
		// Tabs, this should be reworked IMO
		initialState.tabs = {
			dashboard: 'dashboard',
			form: 'form',
			files: 'files',
			apps: 'apps',
			projects: 'projects',
			notes: 'notes',
			isActive: function (tab: 'dashboard' | 'form' | 'files' | 'apps' | 'projects' | 'notes') {
				const state = getState();
				return tab === state.activeTab;
			},
			setActive: function (tab: 'dashboard' | 'form' | 'files' | 'apps' | 'projects' | 'notes') {
				const state = getAutoDraftState();
				state.activeTab = tab;
			}
		};
		initialState.saveDisabledTooltip = '';
		initialState.requiredFields = metadata.requiredFields.Order;
		initialState.requiredFieldsContact = metadata.requiredFields.Contact;
		initialState.hasAppFrameworkListenTo = FeatureHelper.hasSoftDeployAccess('APP_FRAMEWORK_LISTEN_TO');
		initialState.hasAppValidationWithOrderEdit = FeatureHelper.hasSoftDeployAccess(
			'APP_VALIDATION_WITH_ORDER_EDIT'
		);
		initialState.hasSoftDeployContactTitleCategoryAccess = FeatureHelper.hasSoftDeployAccess('NEW_FIELDS');
		initialState.hasSoftDeployUseSubscriptionInterval =
			FeatureHelper.hasSoftDeployAccess('USE_SUBSCRIPTION_INTERVAL');
		initialState.hasNotes = FeatureHelper.hasSoftDeployAccess('NOTES');
		initialState.hasCalculatingFields = FeatureHelper.isAvailable('CALCULATING_FIELDS');
		initialState.hasProductCategoryCustomFieldVisibility = FeatureHelper.isAvailable(
			FeatureHelper.Feature.ROW_CUSTOM_VISIBILITY
		);
		initialState.hasOrderRowCalculatingValues = FeatureHelper.isAvailable(
			FeatureHelper.Feature.CALCULATING_ROW_VALUE
		);

		const cmWithrrOption = getCMWithRROption(metadata);

		const hasRecurringOrder = FeatureHelper.isAvailable(FeatureHelper.Feature.RECURRING_ORDER);
		const hasRecurringSalesModel = metadata.params.SalesModel === 'rr';
		initialState.hasRecurringInterval = (hasRecurringOrder && hasRecurringSalesModel) || !!cmWithrrOption;
		initialState.salesModelOption = cmWithrrOption ?? metadata.params.SalesModelOption;

		const defaultInterval = metadata.params.SubscriptionDefaultInterval || 1;
		const hasInterval = initialState.hasSoftDeployUseSubscriptionInterval;
		const theInterval = hasInterval ? defaultInterval : initialState.salesModelOption === 'mrr' ? 1 : 12;
		initialState.defaultInterval = initialState.hasRecurringInterval ? theInterval : 0;

		initialState.hasMaxDiscount =
			FeatureHelper.hasSoftDeployAccess('MAX_DISCOUNT') &&
			FeatureHelper.isAvailable(FeatureHelper.Feature.MAX_DISCOUNT);

		const PE_ID = 105;

		initialState.apps = metadata.integrations.active.filter(app => {
			if (app.id === PE_ID) {
				// Hide PE app for opportunities
				return app.inits.includes('app_status_order') && meta.order.data.probability === 100;
			} else {
				return app.inits.includes('app_status_order');
			}
		});

		initialState.showAppsSyncLogs = initialState.apps && initialState.apps.length > 0;
		initialState.renderSyncLogs = false;
		initialState.clientOrderRelationTranslations = meta.clientOrderRelationTranslations ?? {};

		initialState.changeAccountText = `${$translate('change')} ${$translate('account').toLowerCase()}`;
		initialState.type = meta.type;

		initialState.hasBothRRAndCM = hasRRWithCM(metadata) || !!cmWithrrOption;

		initialState.hasContributionMargin = metadata.params.UseContributionMargin || initialState.hasBothRRAndCM;
		initialState.activityUpcoming = [];
		initialState.activityHistory = [];
		initialState.orderProducts = meta.products;

		// Files
		initialState.hasFiles = FeatureHelper.isAvailable(FeatureHelper.Feature.DOCUMENTS);
		initialState.hasEsign = FeatureHelper.isAvailable(FeatureHelper.Feature.ESIGN);
		initialState.files = meta.files.data;

		initialState.isAvailable = {
			orderRowCustom: FeatureHelper.isAvailable(FeatureHelper.Feature.ORDER_ROW_CUSTOM),
			companyRelations: FeatureHelper.isAvailable(FeatureHelper.Feature.COMPANY_RELATIONS),
			newFields: FeatureHelper.hasSoftDeployAccess(FeatureHelper.Feature.NEW_FIELDS)
		};

		// FieldSync
		initialState.highlightedFields = [];
		initialState.fieldsDescription = {};
		initialState.fieldsSyncDir = {};
		initialState.syncedToApp = '';
		initialState.appsWithFieldSync = metadata.integrations.active.filter(app =>
			app.inits.includes('show_field_sync')
		);

		// set the order
		initialState.order = meta.order.data;
		initialState.order.lastSuccess = {};
		initialState.order.projectPlanOptions = initialState.order.projectPlanOptions ?? [];
		initialState.order.$$activityId = modalParams.activityId;
		initialState.order.$$appointmentId = modalParams.appointmentId;

		if (!initialState.order.id && modalParams.notes) {
			initialState.order.notes = modalParams.notes;
		}

		initialState.recurringInterval = initialState.order.recurringInterval
			? initialState.order.recurringInterval
			: initialState.defaultInterval;
		initialState.order.recurringInterval = initialState.recurringInterval;

		initialState.hasRecurringProducts =
			metadata.params.AgreementEnabled && FeatureHelper.isAvailable(FeatureHelper.Feature.RECURRING_ORDER);

		// ClientParam for number of decimals on price
		initialState.numOfDecimalsPrice = metadata.params.OrderedProductPriceDecimals || 2;

		if (initialState.hasProjectPlanFeature) {
			initialState.productsWithProjectPlanTemplates =
				initialState.order.orderRow
					?.filter(orderRow => orderRow.product?.projectPlanTemplate)
					?.map(orderRow => orderRow.product) ?? [];
		}

		// set edit/create
		initialState.edit = !!initialState.order.id;
		initialState.quickCreate = !!modalParams.quickCreate;

		// Self
		initialState.self = self;

		// Master currency
		initialState.masterCurrency = meta.masterCurrency;

		// Available contacts
		if (isContacts(meta.contacts)) {
			initialState.contacts = _.sortBy(meta.contacts.data, 'name');
		} else {
			initialState.contacts = [];
		}

		// Customfields
		initialState.customFields = meta.customFields;
		initialState.canShowFormGroups = FeatureHelper.isAvailable(FeatureHelper.Feature.FORM_GROUPS);
		if (initialState.canShowFormGroups) {
			initialState.formGroups = Array.from(
				initialState.customFields
					.sort((a, b) => a.sortId - b.sortId)
					.reduce((previous, field) => {
						previous.add(field.formGroup);
						return previous;
					}, new Set<string | null>())
			);
		} else {
			initialState.formGroups = [null];
		}
		initialState.hasUpdateCustomFieldsOnBlur = FeatureHelper.hasSoftDeployAccess('UPDATE_CUSTOM_FIELDS_ON_BLUR');

		initialState.customFieldsLength = _.filter(meta.customFields, function (f) {
			return f.$hasAccess && (f.editable || f.visible);
		}).length;

		initialState.orderRowCustomFields = meta.orderrowCustomFields;
		initialState.orderRowCustomFieldsLength = _.filter(meta.orderrowCustomFields, function (f) {
			return f.$hasAccess && (f.editable || f.visible);
		}).length;

		if (meta.priceLists) {
			initialState.priceLists = meta.priceLists;
			initialState.defaultPriceListId = meta.priceLists.find(priceList => priceList.isDefault)!.id;
		}

		if (meta.salesCoaches) {
			const salesCoaches = meta.salesCoaches.data;
			if (salesCoaches?.length > 0) {
				initialState.hasSalesCoach = true;
			}
			if (salesCoaches?.length === 1 && !initialState.order.salesCoach) {
				initialState.order.salesCoach = salesCoaches[0];
			}

			if (
				salesCoaches?.length === 1 &&
				// @ts-expect-error It seems like salesCoach is an array from the API and used as an object here, IDK how to type it correctly
				initialState.order.salesCoach?.length === 0 &&
				initialState.edit &&
				initialState.order.probability !== 100 &&
				initialState.order.probability !== 0
			) {
				initialState.order.salesCoach = salesCoaches[0];
				Order.customer(customerId).save(
					{
						id: initialState.order.id,
						// @ts-expect-error It seems like salesCoach is an array from the API and used as an object here, IDK how to type it correctly
						salesCoach: salesCoaches[0],
						skipTriggers: true
					},
					{ skipNotification: true }
				);
			}
		}

		if (initialState.order.salesCoach && Array.isArray(initialState.order.salesCoach)) {
			initialState.order.salesCoach = initialState.order.salesCoach[0];
		}

		const hasSpeedEditOrderInit = FeatureHelper.hasSoftDeployAccess('SPEED_EDIT_ORDER_INIT');

		// if this is an edit
		if (initialState.edit) {
			if (!hasSpeedEditOrderInit) {
				initialState.totalSales = meta.salesReport.data.value;
			}

			initialState.activityUpcoming = [];
			initialState.activityHistory = [];

			// Order activities
			// eslint-disable-next-line you-dont-need-lodash-underscore/each
			_.each(meta.activities.data, activity => {
				var event = EventService.create.Activity(activity);
				event.date = activity.date;

				if (
					(!activity.isAppointment && activity.closeDate) ||
					(activity.isAppointment && new Date(activity.date!).getTime() < new Date().getTime())
				) {
					initialState.activityHistory.push(event as AppointmentEvent | ActivityEvent);
				} else if (activity.isAppointment || (!activity.isAppointment && activity.closeDate === null)) {
					initialState.activityUpcoming.push(event as AppointmentEvent | ActivityEvent);
				}
			});

			initialState.orderActivities = meta.activities.data;

			// Set editable
			initialState.editable = initialState.order.userEditable;

			// if the modal got an initialView we try to use it
			if (modalParams.initView && initialState.tabs[modalParams.initView]) {
				// set active tab
				initialState.activeTab = initialState.tabs[modalParams.initView];
			} else {
				// Default to form
				initialState.activeTab = initialState.tabs.form;
			}

			// set currency
			initialState.selectedCurrency = _.find(metadata.customerCurrencies, { iso: meta.order.data.currency })!;

			// is order
			initialState.isOrder = initialState.order.probability === 100 || initialState.order.probability === 0;

			calcAge();

			initStages(initialState.isOrder ? 'order' : 'opportunity');

			if (modalParams.stage) {
				initialState.order.stage = modalParams.stage;
				initialState.order.probability = modalParams.stage.probability;
			}
		} else if (initialState.quickCreate) {
			initialState.activeTab = initialState.tabs.form;

			quickCreate();
			initStages(initialState.type);
		} else {
			// set active tab
			initialState.activeTab = initialState.tabs.form;

			initStages(initialState.type);

			// set default stage
			if (!initialState.order.stage) {
				if (metadata.params.defaultStageId) {
					// Select default stage if we have one and we can find it else select first
					initialState.order.stage =
						_.find(initialState.stages, { id: metadata.params.defaultStageId }) || initialState.stages[0];
				} else {
					// Select first
					initialState.order.stage = initialState.stages[0];
				}
			}
			initialState.order.probability = initialState.order.stage.probability;
		}

		initialState.allSalescoaches = meta.salesCoaches.data;
		initialState.availableSalescoaches = [];

		getAvailableSalesCoaches();

		if (initialState.order.salesCoach?.id) {
			SalesCoachResource.get(initialState.order.salesCoach.id)
				.then(({ data }: { data: any }) => {
					const state = getAutoDraftState();

					if (data.decisionMakers.active) {
						state.salesCoachDecisionMaker = {
							salesCoachId: data.id,
							hasTitleCategories: data.decisionMakersPro?.typeOfDecisionMakers === 'titleCategories'
						};
						state.decisionMakersEnabled = true;
					}
				})
				.catch((e: unknown) => logError(e, 'Failed to get SalesCoach'));
		}

		initialState.lockedActivity = false;

		var order = meta.order.data;

		if (meta?.order?.data?.id) {
			// @ts-expect-error This is super wierd, and I think it is some legacy stuff, but I will keep it as the Angular code did this
			order.contacts = order?.contacts?.[0];
			// @ts-expect-error This is super wierd, and I think it is some legacy stuff, but I will keep it as the Angular code did this
			initialState.contactPerson = order.contacts;

			initialState.lockedActivity = !!order.closeDate;

			if (initialState.contactPerson) {
				initialState.contactPerson.manualOptins = _.filter(initialState.contactPerson.optins!, function (o) {
					return o.type === 'manual';
				});
			}
		}

		if (initialState.order.orderRow) {
			let sortId = 0;

			initialState.order.orderRow.forEach(row => {
				row.uuid = getUniqueOrderRowId(row);

				sortId++;
				row.$listPrice = row.listPrice * initialState.order.currencyRate;

				row.$discount = parseFloat(
					((row.listPrice - row.price) * row.quantity * initialState.order.currencyRate).toFixed(
						initialState.numOfDecimalsPrice
					)
				);

				discountChange(row);

				if (row.sortId === undefined) {
					row.sortId = sortId;
				}

				// Set purchaseCost
				row.purchaseCost = (row.purchaseCost === null ? 0 : row.purchaseCost) * initialState.order.currencyRate;

				// fixes custom fields and adds properties that aren't mapped in the order request. e.g isRecurring
				row = fixProductFields(row);

				row.bundleRows?.forEach((bundleRow: any) => {
					const product = _.find(initialState.orderProducts, { id: bundleRow.productId });
					bundleRow.product = product ?? {};
					const bundle = row.product?.bundle?.find((b: any) => b.product?.id === bundleRow.product.id);
					bundleRow.adjustable = !!bundle?.adjustable;
					bundleRow.bundleRowPrice = bundleRow.price;
					bundleRow.bundleRowCost = bundleRow.purchaseCost;
				});

				// "fix quantity" for tiered products
				// if a product is tiered we set quantity = tierQuantity so that the editor will work
				// Before save we set tierQuantity = quantity and quantity = 1 so that backend calculations and integrations will work
				if (isTieredOrderRow(row)) {
					row.quantity = row.tierQuantity;
				}

				productCategoryMagic(row);
			});

			// Sort by sortId on init
			initialState.order.orderRow = _.sortBy(initialState.order.orderRow, 'sortId');
		}

		if (!initialState.order.orderRow) {
			initialState.order.orderRow = [];
		}

		// This is loaded by accountChange that is run in init
		initialState.activeAgreementGroups = [];

		// Set order client if we got one and lock account
		if (meta.client && meta.client.data) {
			initialState.order.client = meta.client.data;
			initialState.order.lastSuccess.client = initialState.order.client;
			initialState.lockedAccount = true;
		}

		initialState.missingContactRights = meta.missingContactRights;

		// Set order contact if we have one and lock contact
		if (isContact(meta.contact) && !meta.missingContactRights) {
			initialState.order.contact = meta.contact.data;
			initialState.contactPerson = meta.contact.data;
			initialState.lockedContact = true;
		}

		initialState.hasMultiCurrency = metadata.params.MultiCurrency;

		// Set selected currency
		initialState.selectedCurrency = _.find(metadata.customerCurrencies, { iso: meta.order.data.currency })!;

		// Set up some rights
		if (meta.type === 'opportunity') {
			initialState.ownRightsId =
				initialState.self.createRights.Opportunity === 'OWN' ? initialState.self.id : undefined;
		} else {
			initialState.ownRightsId =
				initialState.self.createRights.Order === 'OWN' ? initialState.self.id : undefined;
		}

		setClientOrderRelation();

		// Trigger account change on init
		accountChange(true);

		initialState.convert = initialState.order.currency !== initialState.masterCurrency;

		// Use discount or not
		initialState.useDiscount = metadata.params.UseDiscount;

		initialState.isOld = actions.isOldDate();

		// Links
		getLinks();

		// Check if contact has required fields
		let defaultRequiredFields = false;

		_.each(metadata.requiredFields.Contact, function (required, field) {
			if (field !== 'Email' && required) {
				defaultRequiredFields = true;
			}
		});

		const customRequiredFields = _.filter(meta.contactCustomFields, 'obligatoryField').length > 0;
		initialState.hasRequiredContactFields = defaultRequiredFields || customRequiredFields;

		// SI ui-elements
		if (metadata.integrations && metadata.integrations.uiElements) {
			initialState.uiElements = metadata.integrations.uiElements.editOrder;
		}

		actions.updateCustomFieldRequiredness();

		initialState.standardFields = metadata.standardFields.Client;

		setTimeout(function () {
			tippy('#notes', {
				animation: 'fade',
				arrow: true,
				hideOnClick: false,
				interactive: true,
				maxWidth: '230px',
				placement: 'left',
				size: 'large',
				theme: 'standard-field',
				trigger: 'focus',
				zIndex: 11000
			});
		});

		initialState.recurringIntervals = recurringIntervals;

		initialState.documentTemplates = _.sortBy(AppService.getDocumentTemplates('order'), 'name').reverse();
		initialState.hasDocumentTemplates = initialState.documentTemplates.length;

		updateProjectPlanOptions();

		//Binding standard templates to old ui props
		pdfTemplateResource
			.find()
			.then(response => {
				const state = getAutoDraftState();

				const activeDocuments: any[] = [];
				response.data.forEach((element: any) => {
					if (
						(element.type === 'standard' && element.active) ||
						(element.type === 'custom' && element.templateData.published === 'true')
					) {
						const documentDetails = {
							id: element.id,
							name: element.templateData.template_name,
							uuid: element.uuid,
							roles: element.roles,
							supportedLanguages: element.templateData.template_details.language,
							type: element.type
						};

						activeDocuments.push(documentDetails);
					}
				});

				state.documentTemplates = _.sortBy([...state.documentTemplates, ...activeDocuments], 'name').reverse();
			})
			.catch((error: unknown) => {
				if (error instanceof Error) {
					logError(error, 'Failed to get document templates');
				}
			})
			.finally(() => {
				const state = getAutoDraftState();

				state.hasDocumentTemplates = state.documentTemplates?.length;
			});

		const integrationOrder = getPlainObject(initialState.order) as unknown as Order;
		callEditOrderListeners(integrationOrder);

		if (modalParams.showPeriodizing) {
			initialState.isPeriodizing = true;
		}

		initialState.probabilityActive = metadata.standardFields.Order?.Probability?.active;
		const isOrderStage =
			initialState.order.stage?.probability === 0 || initialState.order.stage?.probability === 100;
		initialState.hideProbability =
			isOrderStage || (initialState.isAvailable.newFields && !initialState.probabilityActive);

		initialState.riskChipProps = getRiskChipProps(initialState.order);

		const speedTracker = getSpeedTracker('EditOrder');
		speedTracker?.publishResults([
			{ type: 'pageLoad', selector: '.TimelineCard' },
			{ type: 'interactable', selector: '.fake-product-select' }
		]);

		initialState.controllerDone = true;

		return { actions, selectConfigs };
	}, []);
};

export type Actions = ReturnType<typeof useEditOrderLogic>['actions'];
export type SelectConfigs = ReturnType<typeof useEditOrderLogic>['selectConfigs'];

export default useEditOrderLogic;
