import { MultiSelectContext } from 'App/components/MultiselectProvider/MultiselectProvider';
import openResetLeadMulti from 'App/components/ResetLeadMulti/ResetLeadMulti';
import RequestBuilder from 'Resources/RequestBuilder';

export default function resetScore(rb: RequestBuilder, entity: string, multiselect: MultiSelectContext, extraParams?: object) {
	const opts = {
		customerId: Tools.AppService.getCustomerId(),
		filters: rb,
		extraParams,
		entity,
		hideDate: false,
		multiselect: multiselect
	};
	return openResetLeadMulti(opts);
}
