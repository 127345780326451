import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import BemClass from '@upsales/components/Utils/bemClass';

import { ModalHeader, ModalContent, ModalControls, Button } from '@upsales/components';

import { cancelEdit, getFieldValues } from 'Store/reducers/TranslateFieldReducer';

import t from 'Components/Helpers/translate';
import TranslateField from './TranslateField';
import './TranslateFieldModal.scss';
import { asyncModalAdapter, setupComponentCompatibility } from 'App/helpers/angularPortingHelpers';

const mapStateToProps = () => ({});

const mapDispatchToProps = {
	cancelEdit,
	getFieldValues
};

class TranslateFieldModal extends React.Component {
	constructor(props) {
		super(props);

		this.lang = {
			addValues: t('admin.translateField.addValues'),
			close: t('close'),
			edit: t(`admin.translateField.edit.${props.fieldName}`)
		};

		this.classes = new BemClass('TranslateFieldModal');
	}

	componentDidUpdate() {
		if (this.props.reloadModalPosition) {
			this.props.reloadModalPosition();
		}
	}

	render() {
		const { fieldName, cancelEdit, resolve, editMode } = this.props;
		const title = editMode ? this.lang.edit : this.lang.addValues;

		return (
			<div className={this.classes.b()}>
				<ModalHeader
					title={title}
					onClose={() => {
						cancelEdit();
						resolve();
					}}
				/>
				<ModalContent className="padding-bottom">
					<TranslateField
						fieldName={fieldName}
						reloadModalPosition={this.props.reloadModalPosition}
						defaultLang={this.props.defaultLang}
						editMode={this.props.editMode}
					/>
				</ModalContent>
				<ModalControls>
					<Button
						className={this.classes.elem('cancelButton')}
						color="bright-blue"
						type="link"
						onClick={() => {
							cancelEdit();
							resolve();
						}}
					>
						{this.lang.close}
					</Button>
				</ModalControls>
			</div>
		);
	}
}

TranslateFieldModal.propTypes = {
	fieldName: PropTypes.string.isRequired,
	reloadModalPosition: PropTypes.func,
	resolve: PropTypes.func.isRequired,
	cancelEdit: PropTypes.func.isRequired,
	defaultLang: PropTypes.string,
	editMode: PropTypes.bool,
	getFieldValues: PropTypes.func.isRequired
};

const Component = connect(mapStateToProps, mapDispatchToProps)(TranslateFieldModal);

export const TranslateFieldsModal = setupComponentCompatibility(Component, {
	modalName: 'TranslateField',
	modalSize: "xl",
	modalParamsMapper: $modalParams => $modalParams
});

export const openTranslateFieldModal = asyncModalAdapter({
	upModalName: 'translateFields',
	openModalName: 'TranslateFieldsModal',
	rejectOnEmpty: false,
	featureFlag: 'REACT_TRANSLATE_FIELD'
});

export const detached = TranslateFieldModal;

window.TranslateFieldModal = Component;

export default Component;
