import { Modal } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import getAngularModule from 'App/babel/angularHelpers/getAngularModule';
import { asyncModalAdapter } from 'App/helpers/angularPortingHelpers';
import Resource from 'Resources/Resource';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './ValidDomainModal.scss';
import { makeCancelable } from 'Helpers/promise';

type Domain = {
	name: string;
	topLevel: string | null;
};

const ValidDomainModal = ({ className, close }: any) => {
	const classes = new BemClass('ValidDomainModal', className);
	const { t } = useTranslation();
	const BusinessEmail = useMemo(() => getAngularModule('BusinessEmail'), []) as Resource;
	const [loading, setLoading] = useState(true);
	const [saving, setSaving] = useState(false);
	const [hasError, setHasError] = useState(false);
	const [error, setError] = useState('');
	const [domains, setDomains] = useState<Domain[]>([]);

	useEffect(() => {
		const { cancel, promise } = makeCancelable(BusinessEmail.get());

		promise
			.then(function (res) {
				var mappedDomains = [];
				const DEFAULT_INVALID_DOMAINS = getAngularModule('DEFAULT_INVALID_DOMAINS');

				mappedDomains = res.data.value.map(function (domain: Domain) {
					if (!domain.hasOwnProperty('topLevel')) {
						(domain as Domain).topLevel = null;
					}
					return domain;
				});

				setDomains(mappedDomains.length ? mappedDomains : DEFAULT_INVALID_DOMAINS);
				setLoading(false);
			})
			.catch(() => {
				setLoading(false);
				setHasError(true);
			});

		return () => {
			cancel();
		};
	}, []);

	const addDomain = function (data: Domain) {
		const dom = domains.find(dd => {
			if (dd.topLevel === null) {
				return dd.name.toLowerCase() === data.name.toLowerCase() && dd.topLevel === data.topLevel;
			} else if (data.topLevel === null) {
				return dd.name.toLowerCase() === data.name.toLowerCase() && dd.topLevel === data.topLevel;
			} else {
				return (
					dd.name.toLowerCase() === data.name.toLowerCase() &&
					dd.topLevel.toLowerCase() === data.topLevel.toLowerCase()
				);
			}
		});

		if (dom) {
			setHasError(true);
			setError(t('form.validDomain.couldntAdd'));

			setTimeout(function () {
				setHasError(false);
				setError('');
			}, 6000);
		} else {
			const updatedDomains = [data, ...domains];
			setDomains(updatedDomains);
			setSaving(true);

			return BusinessEmail.save({ value: updatedDomains }).finally(() => {
				setSaving(false);
			});
		}
	};

	const deleteRow = function (domain: Domain) {
		var domIndex = domains.findIndex(function (dd) {
			return dd.name === domain.name && dd.topLevel === domain.topLevel;
		});

		if (domIndex !== -1) {
			const updatedDomains = [...domains];
			updatedDomains.splice(domIndex, 1);

			setDomains(updatedDomains);
			setSaving(true);

			return BusinessEmail.save({ value: updatedDomains }).finally(() => {
				setSaving(false);
			});
		}
	};

	const save = async () => {
		setSaving(true);

		await BusinessEmail.save({ value: domains });
		close();
	};

	return (
		<Modal className={classes.b()}>
			<ReactTemplates.form.validDomainModal
				loading={loading}
				saving={saving}
				hasError={hasError}
				error={error}
				domains={domains}
				addDomain={addDomain}
				deleteRow={deleteRow}
				onChange={setDomains}
				save={save}
				closeModal={close}
			/>
		</Modal>
	);
};

export const openValidDomainModal = asyncModalAdapter({
	featureFlag: 'VALID_DOMAINS_REACT',
	openModalName: 'ValidDomainModal',
	upModalName: 'validDomains',
	rejectOnEmpty: false,
	rejectOnEvent: false
});

export default ValidDomainModal;
