import React from 'react';
import { ModalProps } from '../Modals/Modals';
import RequestBuilder from 'Resources/RequestBuilder';
import { Block, Checkbox, Label, Modal, ModalContent, ModalControls, ModalHeader, Row } from '@upsales/components';
import { useTranslation } from 'Components/Helpers/translate';
import { asyncModalAdapter } from 'App/helpers/angularPortingHelpers';
import { PrimaryButton, ThirdButton } from '@upsales/components/Buttons';
import NotificationService from 'App/babel/NotificationService';
import { MultiSelectContext } from '../MultiselectProvider/MultiselectProvider';

type Props = ModalProps<string | undefined> & {
	entity: string;
	filters: RequestBuilder;
	extraParams: {} | undefined;
	customerId: number;
	multiselect: MultiSelectContext;
};

export const ResetLeadMulti = (props: Props) => {
	const { t } = useTranslation();

	const [resetting, setResetting] = React.useState<boolean>(false);
	const [rejectList, setRejectList] = React.useState<boolean>(false);

	function closeAfterSave() {
		// we call close with a non-undefined value to force the list view to refresh
		// see the <runMultiAction> function in <ui/app/components/ListView/ListView.tsx>
		props.close('');
		props.multiselect.selectNone();
	}

	function reset() {
		setResetting(true);

		const method = props.entity === 'Client' ? 'updateAccount' : 'updateContact';
		const properties = [];

		if (rejectList) {
			properties.push({ name: 'ResetScore', value: ['AddToRejectlist'] });
		} else {
			properties.push({ name: 'ResetScore', value: true });
		}

		const filters = props.filters;

		if (props.multiselect.selected) {
			filters.addFilter({ field: 'id' }, filters.comparisonTypes.Equals, props.multiselect.selected);
		}

		Tools.MultiActions.customer(props.customerId)
			[method](props.entity, props.multiselect.selected, properties, props.filters.build(), props.extraParams)
			.then(function () {
				// If we selected less than 20, then wait for index
				if (props.multiselect.selected < 20) {
					setTimeout(function () {
						NotificationService.add({
							style: NotificationService.style.SUCCESS,
							icon: 'check',
							title: 'default.saved',
							body: 'saved.accounts'
						});
						Tools.$rootScope.$broadcast('listView.refresh');
						closeAfterSave();
					}, 2000);
				} else {
					// Else a job will be created
					NotificationService.add({
						style: NotificationService.style.SUCCESS,
						icon: 'check',
						title: 'default.saved',
						body: 'updateQued.accounts'
					});
					closeAfterSave();
				}
			})
			.catch(function () {
				setResetting(false);
				NotificationService.add({
					style: NotificationService.style.ERROR,
					icon: 'times',
					title: 'default.error',
					body: 'saveError.accounts'
				});
				closeAfterSave();
			});
	}

	return (
		<Modal className={props.className}>
			<ModalHeader title={t('default.resetScore')} onClose={props.close} />
			<ModalContent>
				<Block space="mbl">
					<Row>
						<div>{t(props.entity === 'Client' ? 'confirm.resetScores' : 'confirm.resetScoreContacts')}</div>
					</Row>
				</Block>
				<Row>
					<Checkbox space="mrl" checked={rejectList} onChange={value => setRejectList(value)} />
					<Label>{t('market.addToRejectList')}</Label>
				</Row>
			</ModalContent>
			<ModalControls>
				<PrimaryButton onClick={reset} disabled={resetting}>
					{resetting ? (
						<>
							<b className="fa fa-refresh fa-spin"></b> {t('default.resetting')}
						</>
					) : (
						t('default.reset')
					)}
				</PrimaryButton>
				<ThirdButton onClick={() => props.close()} disabled={resetting}>
					{t('default.abort')}
				</ThirdButton>
			</ModalControls>
		</Modal>
	);
};

const openResetLeadMulti = asyncModalAdapter({
	upModalName: 'LeadResetScore',
	openModalName: 'ResetLeadMulti',
	featureFlag: 'REACT_RESET_LEAD_MULTI'
});

export default openResetLeadMulti;
