import Appointment from 'App/resources/Model/Appointment';
import AppointmentModal from '../AppointmentModal';
import BemClass from '@upsales/components/Utils/bemClass';
import React, { useEffect, useRef } from 'react';
import getInitialState from './getInitialState';
import useActions from './useActions';
import useAngularLikeHeight from './useAngularLikeHeight';
import { Modal } from '@upsales/components';
import { useDataStore } from 'App/components/hooks';
import type { Meta } from './useMeta';
// @ts-expect-error, idfkw but this gives errors
import tippy from 'tippy.js';

import './EditAppointment.scss';

export type Props = {
	resolve: (result?: Appointment) => void;
	reject: (error?: unknown) => void;
	meta: Meta;
	className: string;
};

const setupInitialTooltip = () => {
	return setTimeout(() => {
		const initialTooltip = {
			animation: 'fade',
			arrow: true,
			hideOnClick: false,
			interactive: true,
			maxWidth: '230px',
			placement: 'left',
			size: 'large',
			theme: 'standard-field',
			trigger: 'focus',
			zIndex: 11000
		};

		tippy('#notes-area', Object.assign(initialTooltip, { offset: '-335, 0' }));
	}, 200);
};

const noCloneFields = { options: true } as const;
const heightResizeSelectors = ['.appointment-sidebar', '.AppointmentTabs', '.appointment-notes'];

const EditAppointment = ({ className, resolve, reject, meta }: Props) => {
	const classNames = new BemClass(
		'EditAppointment',
		`${className} up-modal notranslate form default dynamic compiled has-controls`
	);
	const modalRef = useRef<HTMLDivElement>(null);
	const { height, calculateHeight } = useAngularLikeHeight(modalRef, heightResizeSelectors);
	const { store, state } = useDataStore(() => getInitialState(meta), noCloneFields);
	const actions = useActions(store, resolve, reject, calculateHeight);

	// Some setup that was done in the init function of appointmentCtrl.js
	useEffect(() => {
		const unsubscribeFunction = Tools.$rootScope.$on('opportunity.added', (_, opportunity) => {
			const { appointment, options } = store.pluck('appointment', 'options');
			if (appointment.client?.id === opportunity?.client?.id) {
				const updatedOptions = { ...options, opportunities: [...options.opportunities, opportunity] };
				store.set('options', updatedOptions);
			}
		});

		Tools.ScriptService.appointment.init(state.appointment);

		const tooltipTimeoutID = setupInitialTooltip();

		// Account changed loads participants to so no need to call that function if we have a client
		if (state.appointment.client) {
			actions.accountChange(state.appointment.client, true);
		} else {
			actions.setParticipantData(true);
		}
		if (state.appointment.opportunity) {
			actions.extendOpportunity(state.appointment.opportunity);
		}
		actions.updateAppointmentLength(false, true);
		actions.getAppointments();
		actions.getContacts();
		actions.getLinks();

		return () => {
			unsubscribeFunction();
			clearTimeout(tooltipTimeoutID);
		};
	}, []);

	return (
		<Modal className={classNames.b()} ref={modalRef} style={{ ['--height' as any]: height }}>
			<AppointmentModal {...state} actions={actions} />
		</Modal>
	);
};

export default EditAppointment;
