import { Dispatch } from 'react';
import Actions from './Actions';

export type State = {
	turnoverGroup: string;
	description: string;
	loading: boolean;
};

export const getInitialState = (description: string): State => ({
	turnoverGroup: '',
	description,
	loading: false
});

export const setTurnoverGroup = (dispatch: Dispatch<any>, state: State) => (turnoverGroup: string) => {
	dispatch({ type: Actions.SET_TURNOVER_GROUP, turnoverGroup });
};

export const setDescription = (dispatch: Dispatch<any>, state: State) => (description: string) => {
	dispatch({ type: Actions.SET_DESCRIPTION, description });
};
