import React, { useEffect, useRef, useImperativeHandle } from 'react';
import useEditOrderContext from '../hooks/useEditOrderContext';
import { DateInput as LibraryDateInput } from '@upsales/components';

import type { ComponentProps } from 'react';

type Props = ComponentProps<typeof LibraryDateInput> & { name?: string; required?: boolean; autoComplete?: string };

const DateInput = React.forwardRef<HTMLInputElement, Props>(
	({ name, required, onChange: _onChange, value, autoComplete, ...props }: Props, forwardRef) => {
		const { registerFormComponent, unregisterFormComponent, onFormConponentChange } = useEditOrderContext();
		const ref = useRef<HTMLInputElement | null>(null);
		const lastCommittedValueRef = useRef<Date | null | undefined>(value);

		useImperativeHandle(forwardRef, () => ref.current!);

		useEffect(() => {
			if (name) {
				const valid = required ? !!value : true;
				registerFormComponent(name, valid);
				return () => unregisterFormComponent(name);
			}
			// Will not add name to the dependency array because then I need something like renameControl
		}, []);

		useEffect(() => {
			if (value !== lastCommittedValueRef.current) {
				if (name) {
					const valid = required ? !!value : true;
					onFormConponentChange(name, valid, false);
				}
			}
		}, [value]);

		function onChange(event: { target: { value: Date } }) {
			lastCommittedValueRef.current = event.target.value;

			_onChange?.(event);

			if (name) {
				const value = event.target.value;
				const valid = required ? !!value : true;
				onFormConponentChange(name, valid, true);
			}
		}

		return (
			<LibraryDateInput
				{...props}
				value={value}
				onChange={onChange}
				inputRef={element => (ref.current = element)}
			/>
		);
	}
);

export default DateInput;
