import Appointment from 'App/resources/Model/Appointment';
import AppointmentOutcome from 'App/resources/Model/AppointmentOutcome';
import Client from 'App/resources/Model/Client';
import Contact from 'App/resources/Model/Contact';
import NotificationService from 'App/babel/NotificationService';
import RequestBuilder, { comparisonTypes } from 'Resources/RequestBuilder';
import getAngularModule from 'App/babel/angularHelpers/getAngularModule';
import logError from 'App/babel/helpers/logError';
import { PromiseProps, generateDeferredPromise, makeCancelable } from 'App/babel/helpers/promise';
import { merge, cloneDeep } from 'lodash';
import { useAccountSelf, useUsers, useDocumentTemplates } from 'App/components/hooks/appHooks';
import { useEffect, useState } from 'react';
import type { ModalParams } from './index';

export type Meta = {
	account: { data: Client | null };
	appointment: { data: Appointment };
	contacts: { data: Contact[] | null };
	documentTemplates: { data: ReturnType<typeof useDocumentTemplates> };
	preSelectedOutcome?: AppointmentOutcome;
	users: { data: (ReturnType<typeof useUsers>[0] | Appointment['users'][0])[] };
};

// This function should be a 1 = 1 copy of ui/app/upsales/domain/appointment/meta/editAppointment.js in functionality
const useEditAccountMeta = (
	{ id, appointment, preSelectedOutcome }: ModalParams,
	reject: (error?: unknown) => void
) => {
	const [meta, setMeta] = useState<Meta | null>(null);

	const users = useUsers('active');
	const documentTemplates = useDocumentTemplates('activity');
	const customerId = useAccountSelf()!.client.id;

	useEffect(() => {
		const Appointment = getAngularModule('Appointment');

		const accountDefer = generateDeferredPromise<{ data: null | Client }>();
		const contactDefer = generateDeferredPromise<{ data: null | Contact[] }>();

		// I am addin the cloneDeeps here because I have no idea what the EditAppointment modal does with its props
		const promises: { [key: string]: Promise<any> | any } = {
			users: { data: cloneDeep(users) },
			documentTemplates: { data: cloneDeep(documentTemplates) },
			account: accountDefer.promise,
			contacts: contactDefer.promise
		};

		// if we have some preset data
		if (id) {
			promises.appointment = Appointment.customer(customerId)
				.get(id)
				.catch(err => logError(err, 'Error getting appointment'));

			accountDefer.resolve({ data: null });
			contactDefer.resolve({ data: null });
		} else {
			const Account = getAngularModule('Account');
			const Contact = getAngularModule('Contact');

			const newAppointment = Appointment.new();
			if (appointment) {
				newAppointment.data = merge(newAppointment.data, appointment);
			}
			promises.appointment = newAppointment;

			let noAccount = true;

			// If the appointment has contact or account
			if (typeof newAppointment.data.client === 'object') {
				noAccount = false;

				Account.customer(customerId)
					.get(newAppointment.data.client!.id)
					.then(accountDefer.resolve)
					.catch(accountDefer.reject);
			}

			if (Array.isArray(newAppointment.data.contacts) && newAppointment.data.contacts.length) {
				const ids = newAppointment.data.contacts.map((contact: any) => contact.id);
				const contactFilter = new RequestBuilder();
				contactFilter.addFilter(Contact.attr.id, comparisonTypes.Equals, ids);

				Contact.find(contactFilter.build())
					.then(contacts => {
						// Get contact account
						if (noAccount && contacts.data && contacts.data.length) {
							noAccount = false;

							Account.customer(customerId)
								.get(contacts.data[0].client.id)
								.then(accountDefer.resolve)
								.catch(accountDefer.reject);
						}

						contactDefer.resolve({ data: contacts.data });
					})
					.catch(function (err) {
						contactDefer.reject(err);
					});
			} else {
				if (noAccount) {
					accountDefer.resolve({ data: null });
				}
				contactDefer.resolve({ data: null });
			}
		}

		const { promise, cancel } = makeCancelable(PromiseProps(promises));

		promise
			.then(_results => {
				const results = _results as Meta;

				// Fix for when a user are selected and no longer exist
				const users = results?.appointment?.data?.users;

				if (Array.isArray(users)) {
					for (const user of users) {
						const shouldAdd = user && !results.users.data.some(({ id }: { id: number }) => id === user.id);

						if (shouldAdd) {
							results.users.data.push(user);
						}
					}
				}
				if (results.account?.data) {
					results.appointment.data.client = results.account.data;
				}
				if (results.contacts?.data) {
					results.appointment.data.contacts = results.contacts.data;
				}
				if (preSelectedOutcome) {
					results.preSelectedOutcome = preSelectedOutcome;
				}

				setMeta(results);
			})
			.catch(err => {
				if (err !== 'abort') {
					NotificationService.add({
						title: 'default.error',
						body: err && err.status === 404 ? 'errorNotFound.appointment' : 'openError.appointment',
						style: 'error',
						icon: 'times'
					});
				}

				/**
				 * ui/app/upsales/domain/appointment/meta/editAppointment.js rejects with error,
				 * but $upModal ignores errors from the resolve object, so reject with void here to get the same behaviour.
				 */
				reject();
			});

		return () => cancel();
	}, []);

	return meta;
};

export default useEditAccountMeta;
