import React, { useEffect, useState } from 'react';
import Client from 'App/resources/Model/Client';
import T from 'Components/Helpers/translate';
import ButtonSelect from '@upsales/components/ButtonSelect/ButtonSelect';
import NotesFilterButton from 'App/components/NotesFilter/NotesFilterButton';
import ActivityHistoryLog from '../ActivityHistoryLog/ActivityHistoryLog';
import ClientHistoryLog from '../ClientHistoryLog/ClientHistoryLog';
import ContactHistoryLog from '../ContactHistoryLog/ContactHistoryLog';
import OpportunityHistoryLog from '../OpportunityHistoryLog/OpportunityHistoryLog';
import AppointmentHistoryLog from '../AppointmentHistoryLog/AppointmentHistoryLog';
import Contact from 'App/resources/Model/Contact';
import Activity from 'App/resources/Model/Activity';
import Opportunity from 'App/resources/Model/Opportunity';
import Appointment from 'App/resources/Model/Appointment';
import useSelector from 'App/components/hooks/useSelector/useSelector';

export type HistoryLogType = 'activity' | 'company' | 'contact' | 'opportunity' | 'appointment';

export type HistoryLogRenderedBy = {
	type: string;
	id: number;
};

type FilterButtonsProps = {
	historyLogType: HistoryLogType;
	setHistoryLogType: (type: HistoryLogType) => void;
	entityIds: { [k in HistoryLogType]?: { id: number } };
	buttonOrder?: HistoryLogType[];
};

const HistoryLogSelector = ({ historyLogType, setHistoryLogType, entityIds, buttonOrder }: FilterButtonsProps) => {
	const options = [];
	if (entityIds.activity?.id) {
		options.push({
			tooltip: T('todo.phoneCall'),
			icon: 'phone',
			value: 'activity'
		});
	}
	if (entityIds.company?.id) {
		options.push({
			tooltip: T('default.client'),
			icon: 'home',
			value: 'company'
		});
	}
	if (entityIds.contact?.id) {
		options.push({
			tooltip: T('contact'),
			icon: 'user',
			value: 'contact'
		});
	}
	if (entityIds.opportunity?.id) {
		options.push({
			tooltip: T('default.opportunity'),
			icon: 'opportunity',
			value: 'opportunity'
		});
	}
	if (entityIds.appointment?.id) {
		options.push({
			tooltip: T('default.appointment'),
			icon: 'calendar',
			value: 'appointment'
		});
	}

	if (buttonOrder?.length) {
		options.sort((a, b) => buttonOrder.indexOf(a.value) - buttonOrder.indexOf(b.value));
	}

	return (
		<>
			<NotesFilterButton />
			<ButtonSelect size="sm" value={historyLogType} onChange={setHistoryLogType} options={options} />
		</>
	);
};

type Props = {
	activity?: Activity;
	client?: Client;
	contact?: Contact;
	opportunity?: Opportunity;
	appointment?: Appointment;
	renderedBy: HistoryLogRenderedBy;
	buttonOrder?: HistoryLogType[];
};

const ModalHistoryLog = ({ activity, client, contact, opportunity, appointment, buttonOrder, renderedBy }: Props) => {
	const showLeading = Tools.FeatureHelper.hasSoftDeployAccess('TODO_LIST');
	const notesFilterActive = useSelector(state => state.NotesFilterActive);

	const [historyLogType, setHistoryLogType] = useState<HistoryLogType>(renderedBy.type as HistoryLogType);

	const genericProps = {
		notesFilterActive,
		renderedBy,
		showLeading,
		selectButtons: (
			<HistoryLogSelector
				historyLogType={historyLogType}
				setHistoryLogType={setHistoryLogType}
				entityIds={{ activity, company: client, contact, opportunity, appointment }}
				buttonOrder={buttonOrder}
			/>
		)
	} as const;

	const historyLogComponentMap = {
		activity: activity?.id ? () => <ActivityHistoryLog activity={activity} {...genericProps} /> : undefined,
		company: client?.id ? () => <ClientHistoryLog client={client} {...genericProps} /> : undefined,
		contact: contact?.id
			? () => <ContactHistoryLog client={contact.client} contact={contact} {...genericProps} />
			: undefined,
		opportunity: opportunity?.id
			? () => <OpportunityHistoryLog opportunity={opportunity} {...genericProps} />
			: undefined,
		appointment: appointment?.id
			? () => <AppointmentHistoryLog appointment={appointment} {...genericProps} />
			: undefined
	};

	useEffect(() => {
		if (!historyLogComponentMap[historyLogType]) {
			setHistoryLogType(renderedBy.type as HistoryLogType);
		}
	}, [activity, client, contact, opportunity, appointment]);

	return (
		<div className="ModalHistoryLog" key={`${renderedBy.type}${renderedBy.id}`}>
			{historyLogComponentMap[historyLogType]?.()}
		</div>
	);
};

export default ModalHistoryLog;
