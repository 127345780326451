import FieldTranslation from 'App/babel/resources/FieldTranslations';
import React from 'react';
import jQuery from 'jquery';

const formatData = function (data) {
	data.sort(function (a, b) {
		const valueA = a.value.toLowerCase();
		const valueB = b.value.toLowerCase();
		if (valueA < valueB) return -1;
		if (valueA > valueB) return 1;
		return 0;
	});
	
	return data;
};

class UpFieldTranslation extends React.Component {
	componentDidMount() {
		const opts = {
			resource: FieldTranslation,
			required: this.props.required || false,
			multiple: this.props.multiple || false,
			ajax: true,
			asIds: this.props.asIds || false,
			idAttr: { field: 'tagId' },
			titleAttr: { field: 'value' },
			sorting: { field: { field: 'value' }, ascending: true },
			placeholder: this.props.placeholder || '',
			onChange: this.props.onChange,
			formatData,
			filters: (rb) => {
				rb.addFilter({ field: 'type' }, rb.comparisonTypes.Equals, this.props.fieldType);
			},
		};

		const input = jQuery(this._input);

		ReactTemplates.TOOLS.selectHelper.getSelect2Options(this, opts, input, (options) => {
			input.select2(options);

			if (this.props.autoFocus) {
				input.select2('focus');
			}
	
			if (this.props.autoOpen) {
				input.select2('open');
			}
		});
	}

	componentDidUpdate() {
		return ReactTemplates.TOOLS.selectHelper.updateValue.call(this);
	}

	componentWillUnmount() {
		const input = jQuery(this._input);
		input.select2('destroy');
	}

	render() {
		return ReactTemplates.TOOLS.selectHelper.getInputComponent(this);
	}
}

export default UpFieldTranslation;
