import React, { ChangeEvent, KeyboardEvent, useState } from 'react';
import {
	Block,
	Button,
	Flex,
	Icon,
	Input,
	Text,
	Table,
	TableHeader,
	TableRow,
	TableColumn,
	Tooltip,
	ClickableItem,
	EllipsisTooltip
} from '@upsales/components';
import ValidationService from 'Services/ValidationService';
import './IntegrationArrayField.scss';
import BemClass from '@upsales/components/Utils/bemClass';

type InputState = 'error' | undefined;

interface IntegrationArrayFieldProps {
	type: 'EmailDomainArray' | 'EmailArray';
	value: string[];
	onChange: (values: string[]) => void;
	field: {
		label?: string;
		description?: string;
		placeHolder?: string;
		inputText?: string;
		addButtonText?: string;
		deleteToolTip?: string;
		tableHeaderName?: string;
	};
}

const IntegrationArrayField: React.FC<IntegrationArrayFieldProps> = ({ type, value, onChange, field }) => {
	const classes = new BemClass('IntegrationArrayField');

	const [newValue, setNewValue] = useState('');
	const [sort, setSort] = useState({ field: 'name', asc: true });

	const hasValidDomain = (domain: string): boolean => {
		if (!domain) return false;
		domain = domain.toLowerCase().trim();
		const domainRegex = /^[a-z0-9.-]+$/;
		if (!domainRegex.test(domain)) {
			return false;
		}
		const existingDomains = value || [];
		return !existingDomains.includes(domain);
	};

	const hasValidEmail = (email: string): boolean => {
		return ValidationService.validEmail(email);
	};

	const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
		const inputValue = e.target.value.toLowerCase().trim();
		setNewValue(inputValue);
	};

	const addValue = () => {
		const newValues = [...(value || []), newValue];
		setNewValue('');
		onChange(newValues);
	};

	const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
		const isValid = type === 'EmailDomainArray' ? hasValidDomain(newValue) : hasValidEmail(newValue);
		if (e.key === 'Enter' && isValid) {
			addValue();
		}
	};

	const removeValue = (itemToRemove: string) => {
		const newValues = value.filter(item => item !== itemToRemove);
		onChange(newValues);
	};

	const isEmailDomain = type === 'EmailDomainArray';
	const validator = isEmailDomain ? hasValidDomain : hasValidEmail;

	return (
		<Block className={classes.b()}>
			<Block className={classes.elem('inner').b()}>
				{field.label ? (
					<label className={classes.elem('array-field-section-title').b()}>{field.label}</label>
				) : null}
				{field.description ? (
					<Block className={classes.elem('description-block').b()}>
						{field.description.split('\n').map((item, i) => (
							<Text
								key={i}
								className={classes
									.elem('description-text')
									.mod({ first: i === 0 })
									.b()}
								color="grey-11"
							>
								{item}
							</Text>
						))}
					</Block>
				) : null}

				<Block>
					{field.inputText ? (
						<Text bold className={classes.elem('input-text').b()}>
							{field.inputText}
						</Text>
					) : null}
					<Flex gap={'u2'}>
						<Input
							className={classes.elem('input').b()}
							type={isEmailDomain ? 'text' : 'email'}
							placeholder={field.placeHolder ? field.placeHolder : ''}
							value={newValue}
							state={newValue ? (validator(newValue) ? undefined : ('error' as InputState)) : undefined}
							onChange={handleInputChange}
							required
							onKeyDown={handleKeyDown}
						/>
						<Button disabled={!newValue || !validator(newValue)} onClick={addValue}>
							<Icon name="plus" />
							{` ${field.addButtonText ?? 'Add'}`}
						</Button>
					</Flex>
				</Block>
			</Block>
			{value && value.length > 0 ? (
				<Table>
					<TableHeader
						columns={[{ title: `${field.tableHeaderName ?? 'Name'}`, sort: 'name' }]}
						onSortChange={sort => {
							setSort({
								field: sort.field,
								asc: sort.asc
							});
						}}
						sorting={sort.field}
						asc={sort.asc}
					/>
					{value
						? [...value]
								.sort((a, b) => {
									const comparison = a.localeCompare(b);
									return sort.asc ? comparison : -comparison;
								})
								.map(item => (
									<TableRow key={item}>
										<TableColumn>
											<Flex justifyContent="space-between" alignItems="center" gap="u2">
												<EllipsisTooltip title={item}>
													<Text>{item}</Text>
												</EllipsisTooltip>
												<Tooltip position="left" title={`${field.deleteToolTip ?? 'Delete'}`}>
													<ClickableItem
														size="sm"
														icon="trash-o"
														onClick={() => removeValue(item)}
													/>
												</Tooltip>
											</Flex>
										</TableColumn>
									</TableRow>
								))
						: null}
				</Table>
			) : null}
		</Block>
	);
};
export default IntegrationArrayField;