import RequestBuilder from 'Resources/RequestBuilder';
import type { MultiSelect } from 'App/components/ListView/ListViewRenderHelpers';
import { openMultiUpdateClientModal } from 'App/components/MultiActionModal/MultiUpdateClient/openMultiUpdateClientModal';

export default function updateCompany(
	rb: RequestBuilder,
	entity: string,
	extraParams?: object,
	multiSelect?: MultiSelect
) {
	const opts = {
		customerId: Tools.AppService.getCustomerId(),
		filters: rb,
		extraParams,
		entity,
		multiSelect
	};

	return openMultiUpdateClientModal(opts);
}
