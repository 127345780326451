import React, { Fragment } from 'react';
import {
	Block,
	Button,
	Column,
	Help,
	Icon,
	Input,
	Label,
	Modal,
	ModalContent,
	ModalControls,
	ModalHeader,
	Row,
	Text,
	Textarea,
	Title,
	Toggle,
	Tooltip
} from '@upsales/components';
import { connect } from 'react-redux';
import BemClass from '@upsales/components/Utils/bemClass';
import InlineAction from 'Components/Dialogs/InlineAction/InlineAction';
import T from 'Components/Helpers/translate';
import { makeCancelable } from 'App/babel/helpers/promise';
import logError from 'App/babel/helpers/logError';
import Client, { ClientAddress, ClientAddressType } from 'App/resources/Model/Client';
import ClientResource from 'App/resources/Client';
import './EditClient.scss';
import PhoneInput, { getFormatedPhoneNumber } from 'Components/Inputs/PhoneInput';
import FormObserver, { FormObserverOnFieldChange } from '../FormObserver';
import UpSelect from 'Components/Inputs/UpSelect';
import { type BasicUserWithPermissions } from 'App/resources/Model/User';
import { StandardFieldConfig } from 'App/resources/AllIWant';
import Category, { CategoryType } from 'App/resources/Model/Category';
import Project from 'App/resources/Model/Project';
import { JourneySelect, PriceListSelect, ProjectSelect } from './EditClientSelects';
import ClientSelect from './../ClientSelect';
import { Select2Event } from './../AjaxSelect';
import { RootState } from 'Store/index';
import openModal from 'App/services/Modal';
import { Options as DeactivationOptions } from 'Components/Account/AccountDeactivationModal';
import EditClientSkeleton from './EditClientSkeleton';
import insertSignatureOnRef from 'App/helpers/insertSignatureOnRef';
import EditClientAddress, { EditClientAddressTabs } from './EditClientAddress';
import StandardFieldInput from 'Components/Inputs/standardFieldInput';
import ValidationModel from '../FormObserver/ValidationModel';
import { InputProps, mapCustomValuesToArray, mapCustomValuesToObject } from '../FormObserver/FormObserver';
import CustomField from 'App/resources/Model/CustomField';
import getEditClientValidationModel from './getEditClientValidationModel';
import { AppState } from 'Store/reducers/AppReducer';
import CustomFields from '../CustomFields/CustomFields';
import { getCategoriesFromState } from 'Store/selectors/AppSelectors';
import ClientContactSelect from 'Components/ClientContactSelect/ClientContactSelect';

const mapStateToProps = ({ App }: RootState) => {
	const { metadata, userMap, categoryTypes, customFields, self } = App;

	const canDeactivate = Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.INACTIVE_COMPANIES);
	const hasJourney = Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.JOURNEY_STATUS);
	const hasPriceLists =
		Tools.FeatureHelper.hasSoftDeployAccess(Tools.FeatureHelper.Feature.PRICE_LISTS) &&
		Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.PRICE_LISTS);
	const hasNewFields = Tools.FeatureHelper.hasSoftDeployAccess('NEW_FIELDS');
	const hasFeatureAnyAccountmanager = Tools.FeatureHelper.hasSoftDeployAccess('ANY_ACCOUNTMANAGER');

	const accountCategories = getCategoriesFromState(App, 'account', undefined, true);

	return {
		canDeactivate,
		requiredFields: metadata?.requiredFields.Client ?? {},
		users: hasFeatureAnyAccountmanager ? userMap.active : userMap.client,
		teamAccountManager: metadata?.params.teamAccountManager ?? false,
		standardFields: metadata?.standardFields.Client ?? {},
		categories: accountCategories,
		categoryTypes: categoryTypes.account,
		hasNewFields,
		customFields: customFields.account.filter(f => f.$hasAccess && (f.editable || f.visible)),
		hasJourney,
		hasPriceLists,
		self
	};
};

enum ClientKeys {
	id,
	name,
	active,
	webpage,
	phone,
	phoneCountryCode,
	users,
	categories,
	projects,
	parent,
	journeyStep,
	addresses,
	notes,
	userEditable,
	fax,
	status,
	orgNo,
	registrationDate,
	sniCode,
	companyForm,
	creditRating,
	turnover,
	profit,
	noEmployees,
	facebook,
	twitter,
	linkedin,
	sicCode,
	naicsCode,
	ukSicCode,
	naceCode,
	cfar,
	about,
	custom,
	priceListId
}

type MappedCustomFields = { [key: string]: string | null };

type PartialClient = Pick<Client, keyof typeof ClientKeys> & {
	addresses: { [key in ClientAddressType]: ClientAddress | null };
	custom: MappedCustomFields;
};

type MappedPartialClient = Omit<Pick<Client, keyof typeof ClientKeys>, 'addresses' | 'custom'> & {
	addresses: { [key in ClientAddressType]: ClientAddress | null };
	custom: MappedCustomFields;
};

function filterKeysFromType<T>(K: any, data: { [k: string]: any }): T {
	const keys = Object.keys(K).filter(key => isNaN(Number(key)));
	return (keys as (keyof typeof K)[]).reduce((res, key) => {
		res[key.toString()] = data[key.toString()];
		return res;
	}, {} as { [k: string]: any }) as T;
}

const fetchClient = async (id: number): Promise<PartialClient> => {
	const { data } = (await ClientResource.get(id)) as { data: Client };
	return filterKeysFromType<PartialClient>(ClientKeys, data);
};

type GroupedStandardFields = { social: string[]; standard: string[] };

const getGroupedStandardFields = (fields: { [key: string]: StandardFieldConfig }) => {
	const res: GroupedStandardFields = { social: [], standard: [] };
	res.social = Object.keys(fields).filter(key => {
		return fields[key].group === 'social' && fields[key].active;
	});
	res.standard = Object.keys(fields).filter(key => {
		// I wish you could look at editable here but all fields seem to have false... So exclude on name ('About') instead :(
		return (
			fields[key].group === 'standard' &&
			fields[key].canHide &&
			fields[key].active &&
			fields[key].name !== 'About'
		);
	});

	return res;
};

type SaveClientRequestProps = { moveOrdersToStageId?: number; inactivateContacts?: boolean };
type SaveClientRequestOpts = { params?: SaveClientRequestProps };

const saveClient = (data: Partial<Client> & { id: number }, requestProps: SaveClientRequestOpts) => {
	return makeCancelable<{ data: Client }>(
		Tools.ScriptService.account.save(data).then(() => ClientResource.save(data, requestProps))
	);
};

const getDeactivationOptions = (client: MappedPartialClient) => {
	return makeCancelable(
		new Promise<[Client, SaveClientRequestProps] | []>(resolve => {
			openModal('AccountDeactivationModal', {
				company: client,
				onClose: (options?: DeactivationOptions) => {
					if (options) {
						const clientProps = {
							deactivationReason: options.reason
						} as Client;

						if (options.disqualify) {
							clientProps.journeyStep = 'disqualified';
						}
						const requestProps: SaveClientRequestProps = {};
						if (options.moveOrdersToStageId) {
							requestProps.moveOrdersToStageId = options.moveOrdersToStageId;
						}
						if (options.inactivateContacts) {
							requestProps.inactivateContacts = true;
						}
						resolve([clientProps, requestProps]);
					} else {
						resolve([]);
					}
				}
			});
		})
	);
};

type EditClientProps = {
	id?: number;
	className?: string;
	close: (client?: Client) => void;
	prefillData?: Partial<PartialClient>;
	canDeactivate: boolean;
	requiredFields: ReturnType<typeof mapStateToProps>['requiredFields'];
	users: BasicUserWithPermissions[];
	categories: Category[];
	teamAccountManager: boolean;
	standardFields: { [key: string]: StandardFieldConfig };
	hasJourney: boolean;
	hasPriceLists: boolean;
	hasNewFields: boolean;
	categoryTypes: CategoryType[];
	customFields: CustomField[];
	self: AppState['self'];
	noRedirect?: boolean;
};

type EditClientState = {
	loading: boolean;
	saving: boolean;
	showInlineAction: null | 'top' | 'bottom';
	client: MappedPartialClient;
	categoriesByType: { [id: number]: Category[] };
	selectedAddressType: null | ClientAddress['type'];
	groupedStandardFields: GroupedStandardFields;
	sortedCustomFields: CustomField[];
};

const getAddressesObj = (addresses: Client['addresses'], requiredFields: EditClientProps['requiredFields']) =>
	addresses.reduce(
		(res, a) => {
			res[a.type] = a;
			return res;
		},
		{
			Mail: requiredFields.Adress ? ClientResource.newAddress('Mail') : null,
			Visit: requiredFields.VisitAddress ? ClientResource.newAddress('Visit') : null,
			Invoice: requiredFields.InvoiceAddress ? ClientResource.newAddress('Invoice') : null,
			Delivery: requiredFields.DeliveryAddress ? ClientResource.newAddress('Delivery') : null,
			Other: requiredFields.OtherAddress ? ClientResource.newAddress('Other') : null
		} as MappedPartialClient['addresses']
	);

const getSortedCustomFields = (customFields: Readonly<CustomField[]>) =>
	[...customFields].sort((a, b) => a.sortId - b.sortId);

const getAddressTypeFromModelName = (name: string) => {
	if (name.startsWith('addresses.')) {
		return name.split('.')[1] as ClientAddress['type'];
	}
	return null;
};

class EditClient extends React.Component<EditClientProps, EditClientState> {
	private fetchClientPromise?: {
		promise: Promise<any>;
		cancel(): void;
	};
	private savePromise: null | ReturnType<typeof saveClient> = null;
	private deactivationPromise?: ReturnType<typeof getDeactivationOptions>;
	private _notesRef: HTMLTextAreaElement | null = null;
	private hasUnifiedClientContactSelect = false;
	private validationModel: { [field: string]: ValidationModel };
	constructor(props: EditClientProps) {
		super(props);
		const categoriesByType = this.props.categoryTypes.reduce<EditClientState['categoriesByType']>(
			(res, type) => {
				res[type.id] = [];
				return res;
			},
			{ 0: [] }
		);
		this.props.categories.forEach(type => {
			categoriesByType[type.categoryType || 0].push(type);
		});
		this.hasUnifiedClientContactSelect = Tools.FeatureHelper.hasSoftDeployAccess('UNIFIED_CLIENT_CONTACT_SELECTOR');
		const newClient = ClientResource.new();

		const state: EditClientState = {
			loading: true,
			saving: false,
			showInlineAction: null,
			client: filterKeysFromType<MappedPartialClient>(ClientKeys, newClient),
			categoriesByType,
			selectedAddressType: null,
			groupedStandardFields: getGroupedStandardFields(props.standardFields),
			sortedCustomFields: []
		};

		this.validationModel = getEditClientValidationModel(
			props.requiredFields,
			props.standardFields,
			props.hasNewFields,
			props.customFields
		);

		if (props.id) {
			this.fetchClientPromise = makeCancelable(fetchClient(props.id));
			this.fetchClientPromise.promise
				.then(client => {
					const addresses = getAddressesObj(client.addresses, props.requiredFields);
					const custom = mapCustomValuesToObject(client.custom, props.customFields);
					this.setState({
						client: { ...client, addresses, custom },
						loading: false,
						selectedAddressType: Object.values(addresses).filter(a => a)[0]?.type ?? null,
						sortedCustomFields: getSortedCustomFields(props.customFields)
					});
				})
				.catch((error: Error) => {
					logError(error, 'Failed to load editClient');
				});
		} else {
			state.client.users = props.self ? [props.self] : [];
			if (props.prefillData) {
				state.client = { ...state.client, ...props.prefillData };
				state.client.phone = getFormatedPhoneNumber(state.client.phone);
			}

			state.loading = false;
			state.client.addresses = getAddressesObj(newClient.addresses, props.requiredFields);
			state.client.custom = mapCustomValuesToObject(newClient.custom, props.customFields, true);
			state.selectedAddressType = Object.values(state.client.addresses).filter(a => a)[0]?.type ?? null;
			state.sortedCustomFields = getSortedCustomFields(props.customFields);
		}

		this.state = state;
	}

	componentWillUnmount() {
		this.fetchClientPromise?.cancel();
		this.savePromise?.cancel();
		this.deactivationPromise?.cancel();
	}

	save = async (values: MappedPartialClient, initialValues: MappedPartialClient) => {
		if (!this.state.client.userEditable) {
			return;
		}
		let clientToSave: Partial<Client> & { id: number } = {
			...values,
			addresses: Object.values(values.addresses).filter(a => a) as ClientAddress[],
			custom: mapCustomValuesToArray(values.custom)
		};
		let reqProps: SaveClientRequestOpts = {};
		if (!values.active && values.active !== initialValues.active) {
			this.deactivationPromise = getDeactivationOptions(values);
			const [clientProps, requestProps] = await this.deactivationPromise.promise;
			if (!clientProps || !requestProps) {
				return;
			}
			clientToSave = { ...clientToSave, ...clientProps };
			reqProps = { params: requestProps };
		}
		this.setState({ saving: true });
		this.savePromise = saveClient(clientToSave, reqProps);
		this.savePromise.promise
			.then(({ data }) => {
				this.setState({ saving: false });
				this.props.close(data);
				if (!this.props.id && !this.props.noRedirect) {
					Tools.$state.go('account.dashboard', { id: data.id });
				}
			})
			.catch(e => {
				this.setState({ saving: false });
				logError(e, 'Failed to save client');
			});
	};

	onClose = (showInlineAction: 'top' | 'bottom', dirty: boolean) => {
		if (!dirty) {
			this.props.close();
			return;
		}
		this.setState({ showInlineAction });
	};

	rejectChanges = () => {
		this.setState({ showInlineAction: null });
		this.props.close();
	};

	setClientKeySelectMulti = <T extends BasicUserWithPermissions | Category | Project>(
		key: 'categories' | 'users' | 'projects',
		e: Select2Event<T>,
		onFormChange: FormObserverOnFieldChange
	) => {
		let list = [...this.state.client[key]];
		if (e.target.added) {
			list.push(e.target.added);
		}
		if (e.target.removed) {
			list = list.filter(item => item.id !== e.target.removed?.id);
		}
		onFormChange(key, list);
	};

	getCategoriesCol = (
		classes: BemClass,
		categoryTypeId: number,
		categories: Category[],
		onFormChange: FormObserverOnFieldChange,
		space?: boolean
	) => {
		if (!this.state.categoriesByType[categoryTypeId].length) {
			return null;
		}
		const selectedCategories = categories.filter(type => {
			const foundType = this.props.categories.find(t => t.id === type.id);
			return foundType?.categoryType === categoryTypeId;
		});

		const isDefault = categoryTypeId === 0;

		const type = isDefault ? null : this.props.categoryTypes.find(c => c.id === categoryTypeId);

		return (
			<Column className={classes.elem('half-col').b()}>
				<Block space={space ? 'mtxl' : ''}>
					<Label>{isDefault ? T('default.categories') : type?.name}</Label>
					<UpSelect
						onChange={(e: Select2Event<Category>) =>
							this.setClientKeySelectMulti<Category>('categories', e, onFormChange)
						}
						defaultValue={selectedCategories}
						options={{}}
						data={this.state.categoriesByType[categoryTypeId]}
						multiple
						storeIdInState
					/>
				</Block>
			</Column>
		);
	};

	addAddress = (type: keyof typeof ClientAddressType, onFormChange: FormObserverOnFieldChange) => {
		onFormChange('addresses', {
			...this.state.client.addresses,
			[type]: ClientResource.newAddress(type)
		});
		this.setState({ selectedAddressType: type });
	};

	removeAddress = (type: keyof typeof ClientAddressType, onFormChange: FormObserverOnFieldChange) => {
		const newAddresses: EditClientState['client']['addresses'] = { ...this.state.client.addresses, [type]: null };
		this.setState({ selectedAddressType: Object.values(newAddresses).filter(a => a)[0]?.type ?? null });
		onFormChange('addresses', newAddresses);
	};

	renderStandardFields = (
		classes: BemClass,
		values: { [k: string]: any },
		inputProps: { [k: string]: InputProps<any> },
		fieldNames: string[],
		onFormChange: FormObserverOnFieldChange
	) => {
		return (
			<Row>
				{fieldNames.map((name, i) => {
					const field = this.props.standardFields[name];
					return (
						<Fragment key={name}>
							<Column className={classes.elem('half-col').b()}>
								<Block space="mbxl">
									<Label required={field.required}>{T(field.nameTag)}</Label>
									<StandardFieldInput
										name={inputProps[field.field].name}
										state={inputProps[field.field].state}
										config={field}
										value={values[field.field] ?? ''}
										closeOnSelect={true}
										onChange={(val: any) => onFormChange(field.field, val)}
									/>
								</Block>
							</Column>
							{i % 2 ? null : <Column className={classes.elem('spacer').b()} />}
						</Fragment>
					);
				})}
			</Row>
		);
	};

	render() {
		const {
			id,
			className,
			canDeactivate,
			requiredFields,
			teamAccountManager,
			users,
			hasNewFields,
			hasJourney,
			hasPriceLists,
			categoryTypes,
			standardFields
		} = this.props;
		const {
			showInlineAction,
			saving,
			client,
			loading,
			categoriesByType,
			selectedAddressType,
			groupedStandardFields
		} = this.state;
		const classes = new BemClass('EditClient', className);
		const selectedAddress = selectedAddressType ? client.addresses[selectedAddressType] : null;

		return (
			<Modal className={classes.b()} size="lg">
				{!loading ? (
					<FormObserver<MappedPartialClient>
						onSubmit={this.save}
						model={this.validationModel}
						initialValues={client}
						onChange={client => this.setState({ client })}
						validateOnMount
						onError={({ firstError }) => {
							const faultyAddress = getAddressTypeFromModelName(firstError);
							if (
								faultyAddress &&
								faultyAddress !== selectedAddressType &&
								client.addresses[faultyAddress]
							) {
								this.setState({ selectedAddressType: faultyAddress });
							}
						}}
						useDebounce
					>
						{({ onFormChange, dirty, values, inputProps, submit }) => (
							<Fragment>
								<ModalHeader
									title={`${T(id ? 'default.edit' : 'default.create')} ${T(
										'default.account'
									).toLowerCase()}`}
									icon={id ? 'edit' : 'home'}
									onClose={() => this.onClose('top', dirty)}
								>
									{showInlineAction === 'top' ? (
										<InlineAction
											toggleInlineAction={() => this.setState({ showInlineAction: null })}
											onReject={this.rejectChanges}
											onConfirm={submit}
											showTop
										/>
									) : null}
								</ModalHeader>
								<ModalContent>
									<Block space="mbxl">
										<Label required>{T('default.name')}</Label>
										<Input {...inputProps.name} />
									</Block>

									<Block space="mbxl">
										<Row>
											<Column>
												<Label required={inputProps.phone.required}>{T('default.phone')}</Label>
												<PhoneInput
													name={inputProps.phone.name}
													state={inputProps.phone.state}
													phone={inputProps.phone.value}
													countryCode={inputProps.phoneCountryCode.value}
													required={inputProps.phone.required}
													icon="phone"
													isNew={!id}
													onChange={(number: string, countryCode: string) => {
														onFormChange('phoneCountryCode', countryCode);
														onFormChange('phone', number);
													}}
												/>
											</Column>
											<Column className={classes.elem('spacer').b()} />
											<Column>
												<Label required={inputProps.webpage.required}>
													{T('default.webpage')}
												</Label>
												<Input
													{...inputProps.webpage}
													value={inputProps.webpage.value ?? undefined}
													icon="globe"
												/>
											</Column>
										</Row>
									</Block>
									{inputProps.fax && standardFields.Fax.active ? (
										<Block space="mbxl">
											<Row>
												<Column>
													<Label required={inputProps.fax.required}>{T('default.fax')}</Label>
													<Input
														{...inputProps.fax}
														value={inputProps.fax.value ?? undefined}
														icon="fax"
													/>
												</Column>
												<Column className={classes.elem('spacer').b()} />
												<Column />
											</Row>
										</Block>
									) : null}
									<Block space="mbxl">
										<Row>
											<Column>
												<Label required={inputProps.users.required}>
													{T('default.accountManager')}
												</Label>
												<UpSelect
													icon="user"
													state={inputProps.users.state}
													name={inputProps.users.name}
													onChange={(e: Select2Event<BasicUserWithPermissions>) =>
														teamAccountManager
															? this.setClientKeySelectMulti<BasicUserWithPermissions>(
																	'users',
																	e,
																	onFormChange
															  )
															: onFormChange(
																	'users',
																	e.target.added ? [e.target.added] : []
															  )
													}
													defaultValue={teamAccountManager ? values.users : values.users[0]}
													options={{}}
													data={users}
													multiple={teamAccountManager}
													required={inputProps.users.required}
													placeholder={`${T('default.select')} ${T(
														'default.user'
													).toLowerCase()}`}
												/>
											</Column>
											<Column className={classes.elem('spacer').b()} />
											<Column>
												<Label required={inputProps.parent.required}>
													{T('default.parentCompany')}
												</Label>
												{!this.hasUnifiedClientContactSelect ? (
													<ClientSelect
														{...inputProps.parent}
														onChange={e => onFormChange('parent', e.target.added ?? null)}
														key="client-select"
														icon="sitemap"
														onlyActive={canDeactivate}
													/>
												) : (
													<ClientContactSelect
														{...inputProps.parent}
														mode="clients"
														selectedSkin="classic"
														onChange={({ client }) =>
															onFormChange('parent', client ?? null)
														}
														onlyActive={canDeactivate}
													/>
												)}
											</Column>
										</Row>
									</Block>
									<Block space={categoryTypes.length ? '' : 'mbxl'}>
										<Row>
											{this.getCategoriesCol(classes, 0, values.categories, onFormChange)}
											{categoriesByType[0].length ? (
												<Column className={classes.elem('spacer').b()} />
											) : null}
											<Column className={classes.elem('half-col').b()}>
												<Label required={inputProps.projects.required}>
													{T('default.campaigns')}
												</Label>
												<ProjectSelect
													{...inputProps.projects}
													key="project-select"
													multiple
													onChange={e =>
														this.setClientKeySelectMulti<Project>(
															'projects',
															e,
															onFormChange
														)
													}
												/>
											</Column>
										</Row>
									</Block>
									{categoryTypes.length ? (
										<Block space="mbxl">
											<Row>
												{categoryTypes.map(({ id }, i) => (
													<Fragment key={id}>
														{this.getCategoriesCol(
															classes,
															id,
															values.categories,
															onFormChange,
															true
														)}
														{i % 2 ? null : (
															<Column className={classes.elem('spacer').b()} />
														)}
													</Fragment>
												))}
											</Row>
										</Block>
									) : null}
									{hasJourney || hasPriceLists ? (
										<Block space="mbxl">
											<Row>
												{hasJourney ? (
													<>
														<Column className={classes.elem('half-col').b()}>
															<Label>{T('default.journeyStep')}</Label>
															<JourneySelect
																{...inputProps.journeyStep}
																onChange={val =>
																	onFormChange('journeyStep', val?.value)
																}
																required
															/>
														</Column>
														<Column className={classes.elem('spacer').b()} />
													</>
												) : null}
												{hasPriceLists ? (
													<Column className={classes.elem('half-col').b()}>
														<Label>{T('default.priceList')}</Label>
														<PriceListSelect
															defaultId={inputProps.priceListId?.value ?? null}
															onChange={val => onFormChange('priceListId', val)}
														/>
													</Column>
												) : null}
											</Row>
										</Block>
									) : null}
									<Block space="mbl">
										<EditClientAddressTabs
											selected={selectedAddress?.type ?? null}
											onChange={a => this.setState({ selectedAddressType: a })}
											addresses={
												Object.values(values.addresses).filter(a => a) as ClientAddress[]
											}
											onAdd={type => this.addAddress(type, onFormChange)}
										/>
									</Block>
									{selectedAddress ? (
										<Block space="mbxl">
											<EditClientAddress
												type={selectedAddress.type}
												key={selectedAddress.type}
												address={selectedAddress}
												onRemove={
													!requiredFields[
														selectedAddress.type === 'Mail'
															? 'Adress'
															: `${selectedAddress.type}Address`
													]
														? type => this.removeAddress(type, onFormChange)
														: null
												}
												inputProps={inputProps}
											/>
										</Block>
									) : null}

									{hasNewFields && groupedStandardFields.standard.length ? (
										<Block space="mbxl">
											<Block space="mbl">
												<Title>{T('default.clientField')}</Title>
											</Block>
											{this.renderStandardFields(
												classes,
												values,
												inputProps,
												groupedStandardFields.standard,
												onFormChange
											)}
										</Block>
									) : null}

									{hasNewFields && groupedStandardFields.social.length ? (
										<Block space="mbxl">
											<Block space="mbl">
												<Title>{T('visitor.type.social')}</Title>
											</Block>
											{this.renderStandardFields(
												classes,
												values,
												inputProps,
												groupedStandardFields.social,
												onFormChange
											)}
										</Block>
									) : null}

									<CustomFields
										type="account"
										inputProps={inputProps}
										onChange={(id: number, value: string) =>
											onFormChange(`custom.Custom_${id}`, value)
										}
										showFormGroupName={true}
									></CustomFields>

									<Block>
										<Block space="mbl">
											<Row>
												<Column>
													<Title>
														{T('default.notes')}
														{inputProps.notes.required ? (
															<Title
																className={classes.elem('required-title').b()}
																color="red"
															>
																{'*'}
															</Title>
														) : null}
													</Title>
												</Column>
												<Column align="right">
													<Button
														color="super-light-green"
														size="sm"
														onClick={() =>
															this._notesRef
																? insertSignatureOnRef(this._notesRef, n =>
																		onFormChange('notes', n)
																  )
																: null
														}
													>
														<Icon name="pencil" space="mrs" />
														{T('default.insertSign')}
													</Button>
												</Column>
											</Row>
										</Block>
										<Tooltip title={this.props.standardFields.Notes.tooltip || ''}>
											<Textarea
												{...inputProps.notes}
												value={values.notes || ''}
												textareaRef={r => (this._notesRef = r)}
											/>
										</Tooltip>
									</Block>
								</ModalContent>
								<ModalControls>
									<Row>
										{canDeactivate ? (
											<Column className={classes.elem('active-toggle').b()}>
												<Toggle
													color="medium-green"
													checked={!!client.active}
													onChange={val => onFormChange('active', val ? 1 : 0)}
													space="mrs"
												/>
												<Text>{T('account.isActive')}</Text>
												<Help articleId={991} sidebar />
											</Column>
										) : null}
										<Column align="right">
											{client.userEditable ? (
												<Button submit loading={saving}>
													{T('default.save')}
												</Button>
											) : null}
											<Button
												color="grey"
												type="link"
												onClick={() => this.onClose('bottom', dirty)}
											>
												{T('default.abort')}
											</Button>
											{showInlineAction === 'bottom' ? (
												<InlineAction
													toggleInlineAction={() => this.setState({ showInlineAction: null })}
													onReject={this.rejectChanges}
													onConfirm={submit}
												/>
											) : null}
										</Column>
									</Row>
								</ModalControls>
							</Fragment>
						)}
					</FormObserver>
				) : (
					<EditClientSkeleton
						id={id}
						onClose={() => this.onClose('top', false)}
						classes={classes}
						categoriesByType={categoriesByType}
						categoryTypes={categoryTypes}
						hasJourney={hasJourney}
						hasPriceLists={hasPriceLists}
						hasNewFields={hasNewFields}
						groupedStandardFields={groupedStandardFields}
					/>
				)}
			</Modal>
		);
	}
}

export const detached = EditClient;

export default connect(mapStateToProps)(EditClient);
