import { FieldTranslationsProvider } from 'App/components/FieldTranslations/FieldTranslationsContext';
import { ChildAgreement } from 'App/components/EditSubscription/Context/SubscriptionGroupState';
import Agreement, { AgreementMetadata, Child } from 'App/resources/Model/Agreement';
import { Avatar, Text, ButtonSelect, Help, Link, Flex } from '@upsales/components';
import TooltipOverflowText from 'App/components/Text/TooltipOverflowText';
import { SlideFade, Fade } from '@upsales/components/animations';
import openAgreement from 'App/helpers/openSubscriptionHelper';
import { CurrencyFormat } from 'App/babel/utils/numberFormat';
import { ClientIdName } from 'App/resources/Model/Client';
import { DocumentTemplate } from 'App/resources/AllIWant';
import BemClass from '@upsales/components/Utils/bemClass';
import { OldOrNewAgreement } from '../SubscriptionCards';
import OrderRow from 'App/resources/Model/OrderRow';
import UpdateSplitSubs from './UpdateSplitSubs';
import Order from 'App/resources/Model/Order';
import T from 'Components/Helpers/translate';
import openModal from 'App/services/Modal';
import CardChildRow from '../CardChildRow';
import { useSelector } from 'react-redux';
import React, { useState } from 'react';
import CardActions from './CardActions';
import { RootState } from 'Store/index';
import moment from 'moment';

import './CardParent.scss';
import { useSoftDeployAccess } from 'App/components/hooks';

const createDocument = (documentTemplates: DocumentTemplate[], agreementId: number, clientId: number) => {
	if (Tools.FeatureHelper.hasSoftDeployAccess('CREATE_DOCUMENT_REACT')) {
		openModal('CreateDocumentModal', {
			type: 'agreement',
			id: agreementId,
			templates: documentTemplates,
			accountId: clientId
		});
	} else {
		Tools.$upModal.open('createDocument', {
			type: 'agreement',
			id: agreementId,
			templates: documentTemplates,
			accountId: clientId
		});
	}
};

type UpdateSubscriptionProps = {
	agreementGroup: OldOrNewAgreement;
	orderToAdd?: Order;
	onSave?: () => void;
	dontWait?: boolean;
	updateUpcoming?: Set<number>;
};

const updateSubscription = (props: UpdateSubscriptionProps) => {
	const { agreementGroup, dontWait, orderToAdd, onSave, updateUpcoming = new Set() } = props;

	openModal('EditSubscription', {
		agreementId: agreementGroup.currentAgreement.id,
		agreement: agreementGroup.id ? undefined : agreementGroup.currentAgreement,
		agreementGroupId: agreementGroup.id,
		groupState: agreementGroup.state,
		updateUpcoming,
		orderToAdd,
		dontWait,
		onSave
	});
};

export type ChildType = ChildAgreement & {
	child: true;
	valueChange: number;
	orderRow: OrderRow[];
	childIndexNr: number;
	isPassed?: boolean;
	isFirstAgreement?: boolean;
	parentEndDate?: Date | null;
	metadata: Pick<
		AgreementMetadata,
		'agreementStartdate' | 'agreementEnddate' | 'agreementIntervalPeriod' | 'agreementRenewalDate'
	>;
	lastAgreement: boolean;
	firstChild: boolean;
} & Pick<Agreement, 'currency' | 'currencyRate'>;

export type AgreementWithValueChange = Agreement & { valueChange?: number };

type Props = {
	agreementGroup: OldOrNewAgreement;
	client?: ClientIdName;
	hasMRR: boolean;
	documentTemplates: DocumentTemplate[];
	isInactive: boolean;
	passedAgreements: (AgreementWithValueChange | ChildType)[];
	upcomingAgreements: (AgreementWithValueChange | ChildType)[];
	willUpdateAgreement?: boolean;
	orderToAdd?: Order;
	onSave?: (agreementGroup?: OldOrNewAgreement) => void;
	showClientName?: boolean;
	showContactLink: boolean;
	upcomingParents: AgreementWithValueChange[];
	isGroupBySubAccount?: boolean;
};

const CARD_HEIGHT = 50;

const CardParent = ({
	onSave: onSaveProp,
	client,
	hasMRR,
	orderToAdd,
	isInactive,
	agreementGroup,
	upcomingParents,
	showContactLink,
	passedAgreements,
	upcomingAgreements,
	documentTemplates,
	willUpdateAgreement,
	showClientName = false,
	isGroupBySubAccount = false
}: Props) => {
	const classNames = new BemClass('CardParent');
	const { customerId } = useSelector(({ App }: RootState) => App);
	const [open, setOpen] = useState(false);
	const [showUpdateContent, setShowUpdateContent] = useState(false);
	const [showPassed, setShowPassed] = useState(!upcomingAgreements.length);
	const dontWait = useSoftDeployAccess('DONT_WAIT_SUBSCRIPTIONS');

	const { currentAgreement, agreements } = agreementGroup;
	if (!currentAgreement || !agreements.length) {
		return null;
	}

	const numberOfAgreements = showPassed ? passedAgreements.length : upcomingAgreements.length;
	const animateMaxHeight = Math.max(CARD_HEIGHT * (numberOfAgreements + 1), 100);

	const currencyFormat = new CurrencyFormat(currentAgreement.currency);
	const yearlyValue = currentAgreement.yearlyValue ?? 0;

	let currentChild: Child | undefined;
	if (currentAgreement.children?.length) {
		currentChild = currentAgreement.children[0];

		currentChild =
			[...currentAgreement.children].reverse().find(child => moment().isSameOrAfter(moment(child.startDate))) ??
			currentChild;
	}

	const value = currencyFormat.short(yearlyValue / (hasMRR ? 12 : 1)) + ` (${hasMRR ? 'MRR' : 'ARR'})`;

	const [firstAgreement] = agreements;
	const [nextAgrement] = upcomingAgreements;
	const startDateString = moment(firstAgreement.metadata?.agreementStartdate).format('L');

	const lastAgreement = agreements[agreements.length - 1];
	const endDate = lastAgreement?.metadata?.agreementEnddate ? moment(lastAgreement.metadata.agreementEnddate) : null;

	const renewalDate = nextAgrement?.metadata?.agreementStartdate
		? moment(nextAgrement.metadata.agreementStartdate)
		: currentAgreement.metadata?.agreementRenewalDate
		? moment(currentAgreement.metadata?.agreementRenewalDate)
		: null;

	let secondDateTitle = endDate ? T('default.endDate') : T('agreement.renewalDate');
	if (!endDate && !renewalDate) {
		secondDateTitle = T('agreement.periodLength.untilFurtherNotice');
	}

	const nextAgreementOrder = agreements.find(
		agreement =>
			agreement.metadata?.willCreateMoreOrders &&
			(!endDate || endDate.isAfter(agreement.metadata.agreementNextOrderDate))
	);
	let nextOrderDateString = T('agreement.noNextOrderDate');
	if (nextAgreementOrder) {
		const nextOrderDate = moment(nextAgreementOrder.metadata?.agreementNextOrderDate);
		nextOrderDateString = nextOrderDate.format('L');
	}

	const contact = currentAgreement.contact;
	const none = T('default.none');

	const userName = currentAgreement.user?.name;
	const nameArray = userName ? userName.split(' ') : [];
	let userNameShort = T('default.unknown');

	if (nameArray.length === 1) {
		userNameShort = nameArray[0];
	} else if (nameArray.length > 1) {
		const [first] = nameArray;
		const last = nameArray[nameArray.length - 1];
		userNameShort = `${first} ${last.substring(0, 1)}`;
	}

	const options = [
		{ value: true, title: T('subscription.card.passedChanges') },
		{ value: false, title: T('subscription.card.upcomingChanges') }
	];

	const hasDropDown = passedAgreements.length + upcomingAgreements.length > 0;
	const hideActions = !!orderToAdd;

	const onSave = () => onSaveProp?.(agreementGroup);

	const onUpdateAgreementClick = () => {
		if (upcomingAgreements.length) {
			setShowUpdateContent(prev => !prev);
		} else {
			updateSubscription({ dontWait, agreementGroup, orderToAdd, onSave });
		}
	};

	const renderEmptyRow = (text: string) => {
		return (
			<Text italic color="grey-11" className={classNames.elem('emptyRow').b()}>
				{text}
			</Text>
		);
	};

	const renderAgreement = (
		childAgreement: AgreementWithValueChange,
		isPassed?: boolean,
		isFirstAgreement?: boolean,
		isLastAgreement?: boolean
	) => {
		return (
			<CardChildRow
				key={childAgreement.id}
				hasMRR={hasMRR}
				isPassed={isPassed}
				isFirstAgreement={isFirstAgreement}
				isLastAgreement={isLastAgreement}
				currentYearlyValue={currentAgreement.yearlyValue ?? 0}
				valueChange={childAgreement.valueChange}
				hideActions={hideActions}
				onDocumentClick={
					documentTemplates.length
						? () => createDocument(documentTemplates, childAgreement.id, childAgreement.client.id)
						: undefined
				}
				childAgreement={childAgreement}
				editSubscription={() => openAgreement({ dontWait, agreementGroup, agreementId: childAgreement.id })}
			/>
		);
	};

	const renderChild = (child: ChildType) => {
		if (!child) return;

		const parent = agreementGroup.agreements.find(a => a.id === child.parentId);
		if (!parent) return;

		return (
			<CardChildRow
				key={'child' + child.id}
				hasMRR={hasMRR}
				isPassed={child.isPassed ?? false}
				firstChild={child.firstChild}
				isFirstAgreement={child.isFirstAgreement ?? false}
				isLastAgreement={child.lastAgreement}
				valueChange={child.valueChange}
				hideActions={hideActions}
				onDocumentClick={
					documentTemplates.length
						? () => createDocument(documentTemplates, parent.id, parent.client.id)
						: undefined
				}
				childAgreement={child}
				editSubscription={() =>
					openAgreement({
						dontWait,
						agreementGroup,
						agreementId: parent.id,
						childIndexNr: child.childIndexNr
					})
				}
			/>
		);
	};

	const renderPassedChildren = () => {
		if (!passedAgreements.length) {
			return renderEmptyRow(T('subscription.card.emptyPassedRowText'));
		}
		const rows: (JSX.Element | undefined)[] = [];

		passedAgreements.forEach((agreement, i) => {
			if ((agreement as ChildType).child) {
				rows.push(renderChild(agreement as ChildType));
			} else {
				rows.push(renderAgreement(agreement as AgreementWithValueChange, true, i === 0, false));
			}
		});
		return rows;
	};

	const renderFutureChildren = () => {
		if (!upcomingAgreements.length) {
			return renderEmptyRow(T('subscription.card.emptyUpcomingRowText'));
		}

		const rows: (JSX.Element | undefined)[] = [];

		upcomingAgreements.forEach((agreement, i) => {
			if ((agreement as ChildType).child) {
				rows.push(renderChild(agreement as ChildType));
			} else {
				rows.push(
					renderAgreement(
						agreement as AgreementWithValueChange,
						false,
						false,
						i === upcomingAgreements.length - 1
					)
				);
			}
		});

		return rows;
	};

	const renderContact = () => {
		if (!contact) {
			return (
				<Text color={'grey-6'} italic>
					{none}
				</Text>
			);
		}
		if (showContactLink) {
			return (
				<Link
					className="text-ellipsis"
					onClick={e => e.stopPropagation()}
					href={Tools.$state.href('contact.dashboard', {
						id: contact.id,
						customerId
					})}
				>
					{contact.name}
				</Link>
			);
		}
		return <Text className="text-ellipsis">{contact.name}</Text>;
	};

	const onRowClick = willUpdateAgreement ? onUpdateAgreementClick : openAgreement;

	let currentChildIndex: number | undefined;
	if (currentChild && currentAgreement.children) {
		currentChildIndex = currentAgreement.children.findIndex(c => c.id === currentChild?.id);
	}
	const operationalAccountName = currentAgreement.client.operationalAccount?.name;

	return (
		<div
			key={currentAgreement.id}
			className={classNames
				.mod({ isInactive, closed: !open && !showUpdateContent, hideActions, showClientName })
				.b()}
		>
			{showClientName ? (
				<Flex className={classNames.elem('clientName').b()} alignItems="center" space="pll">
					<Text color="grey-11" size="sm" bold>
						{!isGroupBySubAccount && operationalAccountName
							? operationalAccountName
							: currentAgreement.client.name}
					</Text>
				</Flex>
			) : null}
			{!showUpdateContent ? (
				<div>
					<div
						className={classNames.elem('row').b()}
						onClick={() =>
							onRowClick({
								dontWait,
								agreementId: currentAgreement.id,
								agreementGroup,
								childIndexNr: currentChildIndex
							})
						}
					>
						<div className={classNames.elem('sales-description').b()}>
							<Text bold>{value}</Text>
							<TooltipOverflowText
								tooltipProps={{ title: currentAgreement.metadata?.agreementDescription }}
								size="sm"
								color="grey-11"
							>
								{currentAgreement.metadata?.agreementDescription}
							</TooltipOverflowText>
						</div>
						<div className={classNames.elem('start-date').b()}>
							<Text size="sm" color="grey-11">
								{T('default.startDate')}
							</Text>
							<Text>{startDateString}</Text>
						</div>
						<div className={classNames.elem('end-or-renewal').b()}>
							<Text size="sm" color="grey-11">
								{secondDateTitle}
							</Text>
							<Text color={endDate && !isInactive ? 'red' : 'black'}>
								{endDate || renewalDate ? (endDate || renewalDate)?.format('L') : '-'}
							</Text>
						</div>
						<div>
							<Text size="sm" color="grey-11">
								{T('agreement.nextOrderDate')}
							</Text>
							<Text>{nextOrderDateString}</Text>
						</div>
						<div className={classNames.elem('user').b()}>
							<Avatar initials={currentAgreement.user?.name ?? ''} size="sm" />
							<div>
								<Text size="sm" color="grey-11">
									{T('default.user')}
								</Text>
								<Text>{userNameShort}</Text>
							</div>
						</div>
						<div className={classNames.elem('contact').b()}>
							<Text size="sm" color="grey-11">
								{T('default.contact')}
							</Text>
							{renderContact()}
						</div>
						{client ? (
							<FieldTranslationsProvider types={['clientorderrelation']}>
								<CardActions
									agreementGroup={agreementGroup}
									documentTemplates={documentTemplates}
									hasMRR={hasMRR}
									hideActions={hideActions}
									open={open}
									setOpen={setOpen}
									createDocument={createDocument}
									hasDropDown={hasDropDown}
									client={client}
								/>
							</FieldTranslationsProvider>
						) : null}
					</div>
					<SlideFade visible={hasDropDown && open} height maxHeight={animateMaxHeight}>
						<div>
							<div className={classNames.elem('buttonGroup').b()}>
								<ButtonSelect onChange={setShowPassed} value={showPassed} options={options} />
								<Help articleId={1432} sidebar />
							</div>
							{showPassed ? renderPassedChildren() : renderFutureChildren()}
						</div>
					</SlideFade>
				</div>
			) : null}
			<Fade visible={showUpdateContent} height maxHeight={150 + upcomingAgreements.length * 62}>
				<div>
					<UpdateSplitSubs
						updateSubscription={updateUpcoming =>
							updateSubscription({ dontWait, agreementGroup, orderToAdd, onSave, updateUpcoming })
						}
						close={() => setShowUpdateContent(false)}
						upcomingParents={upcomingParents}
						upcomingStartDate={upcomingParents[0]?.metadata.agreementStartdate}
					/>
				</div>
			</Fade>
		</div>
	);
};

export default CardParent;
