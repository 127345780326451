import React from 'react';
import PropTypes from 'prop-types';
import {
	Tab,
	Tabs,
	ColorSwitcher,
	ModalHeader,
	Button,
	ModalControls,
	Title,
	Link,
	Input,
	ModalContent,
	Label,
	Row,
	Icon,
	Column,
	Tooltip,
	Progress,
	Flex,
	Block,
	StateFrame
} from '@upsales/components';
import Calculation from './Calculation';
import getAngularModule from '../../../angularHelpers/getAngularModule';
import './EditCustomField.scss';
import bemClass from '@upsales/components/Utils/bemClass';
import CreatableSelect from 'react-select/lib/Creatable';
import { getProjectPlanTypesFromState, getTicketTypesFromState } from 'Store/selectors/AppSelectors';
import RoleSelect from 'Components/RoleSelect';
import store from 'Store/index';
import { t } from 'i18next';
import { asyncModalAdapter, setupComponentCompatibility } from 'App/helpers/angularPortingHelpers';

const MAX_SELECT_LENGTH = 64385;

export default class EditCustomField extends React.Component {
	constructor(props) {
		super(props);
		this.state = this.getInitialState();

		const FeatureHelper = getAngularModule('FeatureHelper');
		this.hasOrderCustomStages =
			(this.props.type === 'order' || this.props.type === 'orderrow') &&
			FeatureHelper.isAvailable(FeatureHelper.Feature.ORDER_CUSTOM_STAGES);

		this.hasProductCustomFieldCategories =
			this.props.type === 'product' &&
			FeatureHelper.hasSoftDeployAccess(FeatureHelper.Feature.PRODUCT_CUSTOM_FIELD_CATEGORY) &&
			FeatureHelper.isAvailable(FeatureHelper.Feature.PRODUCT_CUSTOM_FIELD_CATEGORY);

		if (this.hasProductCustomFieldCategories) {
			this.allProductCategories = Tools.AppService.getProductCategories();
		}

		this.hasTicketCustomFieldType = this.props.type === 'ticket';
		this.hasProjectPlanCustomFieldType =
			this.props.type === 'projectPlan' && FeatureHelper.hasSoftDeployAccess('PROJECT_PLAN_NEW_FIELDS');
		const app = store.getState().App;
		this.canRestrictToTypes = this.hasTicketCustomFieldType || this.hasProjectPlanCustomFieldType;
		if (this.hasTicketCustomFieldType) {
			this.allTypes = getTicketTypesFromState(app);
		} else if (this.hasProjectPlanCustomFieldType) {
			this.allTypes = getProjectPlanTypesFromState(app);
		}

		this.hasRoleFeature = FeatureHelper.isAvailable(FeatureHelper.Feature.USER_PERMISSIONS_ADVANCED);
		this.hasFormGroups =
			Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.FORM_GROUPS) &&
			(this.props.type === 'order' || this.props.type === 'contact' || this.props.type === 'account');

		this.hasNewFields = FeatureHelper.hasSoftDeployAccess('NEW_FIELDS');

		if (this.state.field.id) {
			this.state.selectedTab = this.state.tabs[1].id;
		}

		this.hasTreeSelect = Tools.FeatureHelper.hasSoftDeployAccess('TREE_SELECT');
		this.hasFormulaVisible = Tools.FeatureHelper.hasSoftDeployAccess('FORMULA_VISIBLE');

		const t = Tools.$translate;

		this.lang = {
			title: t('admin.fields.title'),
			field: t('admin.field'),
			secondTabTitle: t(this.props.title),
			footerText: t('admin.fields.footerText'),
			save: t('default.save'),
			next: t('default.next'),
			abort: t('default.abort'),
			edit: t('default.edit'),
			new: t('default.new2'),
			usersCanSelectMultipleUsers: t('admin.fields.usersCanSelectMultipleUsers'),
			alternativesToChooseFrom: t('admin.fields.alternativesToChooseFrom'),
			customFieldName: t('admin.customfieldName'),
			permission: t('default.permissionToRole'),
			formRequired: t('form.required'),
			preDefinedField_description: t('admin.fields.bisnodeAliasText'),
			preDefinedField_elevio_linkText: t('admin.fields.typeElevioText'),
			alwaysRequired: t('admin.fields.alwaysRequired'),
			requiredOnStages: t('admin.fields.requiredOnStages'),
			oneOptionPerRow: t('admin.fields.oneItemPerRow'),
			clickHere: t('default.clickHere'),
			requires: t('apps.requires'),
			visible: t('default.visible'),
			editable: t('default.editable'),
			searchable: t('default.searchable'),
			upsalesPlus: t('admin.upsalesVersion.plus'),
			stageSelectPlaceholder: t('filter.history.selectStage'),
			roleSelectPlaceholder: t('admin.looker.chooseRoles'),
			disabledEditableToggleTooltip: t('admin.fields.disabledEditableToggleTooltip'),
			formGroup: t('admin.fields.formGroup'),
			defaultFormGroup: t('default.otherInfo'),
			formGroupCreateNewLabel: t('admin.fields.formGroupCreateNewLabel'),
			formGroupSelectPlaceholder: t('admin.fields.formGroupSelectPlaceholder'),
			connectToProductCategory: t('admin.fields.connectToProductCategory'),
			productCategoryPlaceHolder: t('default.selectProductCategory'),
			chooseTypes: t('admin.fields.chooseTypes'),
			typePlaceHolder: t('default.permissionToType'),
			required: t('default.required'),
			in: t('default.in'),
			integrationFieldWarningTitle: t('admin.fields.integrationFieldWarningTitle')
		};
	}

	getInitialState = () => {
		let sortId;

		if (this.props.field === null) {
			sortId = _.reduce(
				this.props.customFields,
				(maxSortId, cf) => {
					if (cf.sortId > maxSortId) return cf.sortId;
					return maxSortId;
				},
				-1
			);
		} else {
			sortId = this.props.field.sortId;
		}

		const field = _.pick(this.props.field, [
			'icon',
			'name',
			'id',
			'datatype',
			'sortId',
			'default',
			'formula',
			'obligatoryField',
			'roles',
			'stages',
			'visible',
			'formulaVisible',
			'editable',
			'searchable',
			'dropdownDefault',
			'formGroup',
			'categories',
			'types',
			'integrationDependency'
		]);

		this.defaultField = {
			icon: 'A',
			name: '',
			id: null,
			sortId: sortId,
			default: '',
			datatype: 'String',
			obligatoryField: false,
			visible: 1,
			formulaVisible: true,
			editable: 1,
			searchable: 0,
			dropdownDefault: [],
			categories: [],
			types: [],
			integrationDependency: null
		};

		const t = Tools.$translate;
		const tabs = [
			{
				key: 'Field Type',
				id: 'Field Type',
				text: t('default.fieldType'),
				noFlex: true
			},
			{
				key: 'Field Options',
				id: 'Field Options',
				text: t('default.fieldOptions'),
				noFlex: true
			}
		];

		const datatypes = [
			{ type: 'String', icon: <Icon name="font" /> },
			{ type: 'Date', icon: <Icon name="calendar" /> },
			{ type: 'Integer', icon: '42', class: 'number' },
			{ type: 'Boolean', icon: <Icon name="toggle" />, classMod: { yn: true } },
			{ type: 'Percent', icon: <Icon name="percent" /> },
			{ type: 'Time', icon: <Icon name="clock-o" /> },
			{ type: 'Currency', icon: <Icon name="usd" /> },
			{ type: 'Select', icon: <Icon name="list-ul" /> },
			{ type: 'Email', icon: <i className="fa fa-at" /> },
			{ type: 'Link', icon: <Icon name="link" /> },
			{ type: 'Text', icon: <Icon name="pencil" /> },
			{ type: 'Discount', icon: '25%', classMod: { number: true } },
			{ type: 'User', icon: <Icon name="user" /> }
		];

		if (Tools.FeatureHelper.hasSoftDeployAccess('CLICKABLE_PHONE_LINK')) {
			datatypes.push({ type: 'Phone', icon: <Icon name="phone" /> });
		}

		const hasCalculatingFields = Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.CALCULATING_FIELDS);

		if (hasCalculatingFields && ['order', 'orderrow'].includes(this.props.type)) {
			datatypes.push({ type: 'Calculation', icon: <Icon name="calculator" /> });
		}

		const datatype = _.find(datatypes, { type: field.datatype });
		field.icon = datatype ? datatype.icon : field.icon;

		const preDefinedFieldsData =
			this.props.type === 'account' && !this.hasNewFields
				? this.generatePREDEFINEDdata(this.props.customFields, this.defaultField)
				: null;

		const _field = _.assign({}, this.defaultField, field);

		return {
			field: _field,
			saveDisabled: false,
			selectedTab: tabs[0].key,
			tabs: tabs,
			submitted: false,
			datatypes,
			preDefinedField: null,
			preDefinedFieldsData: preDefinedFieldsData
		};
	};

	setRef = (name, ref) => {
		this[name] = ref;
	};

	usersUpdate = () => {
		const field = _.cloneDeep(this.state.field);
		field.canSelectMultipleUsers = field.datatype === 'Users';

		this.setState({
			field: field
		});
	};

	handleCanSelectMultipleChange = checked => {
		const field = _.cloneDeep(this.state.field);
		field.canSelectMultipleUsers = Number(checked);
		this.setState({
			field: field
		});
	};

	generatePREDEFINEDdata = (cfs, def) => {
		const aliases = [
			'TURNOVER',
			'NO_EMPLOYEES_SITE',
			'NO_EMPLOYEES_COMPANY',
			'PRIMARY_LINE_OF_BUSINESS',
			'RATING',
			'LEGALFORM',
			'ORG_NO',
			'COMPANY_TYPE'
		];

		const language = (Tools.AppService.getAccountSelf().language || '').replace('-', '_');
		const sniCodeOptions = this.props.SniCodes.get(language);

		return _.filter(aliases, alias => {
			return _.find(cfs, { alias: alias }) === undefined;
		}).map(alias => {
			let opts;

			switch (alias) {
				case 'TURNOVER':
					opts = {
						name: Tools.$translate('default.turnover'),
						datatype: 'Currency',
						alias: 'TURNOVER'
					};
					break;
				case 'NO_EMPLOYEES_SITE':
					opts = {
						name: Tools.$translate('default.numOfEmployees'),
						default: [
							'0',
							'1-4',
							'5-9',
							'10-19',
							'20-49',
							'50-99',
							'100-199',
							'200-499',
							'500-999',
							'1000-1999',
							'2000-2999',
							'3000-3999',
							'4000-4999',
							'5000-9999',
							'10000-'
						],
						datatype: 'Select',
						alias: 'NO_EMPLOYEES_SITE'
					};
					break;
				case 'NO_EMPLOYEES_COMPANY':
					opts = {
						name:
							Tools.$translate('default.numOfEmployees') +
							' (' +
							Tools.$translate('default.client') +
							')',
						default: [
							'0',
							'1-4',
							'5-9',
							'10-19',
							'20-49',
							'50-99',
							'100-199',
							'200-499',
							'500-999',
							'1000-1999',
							'2000-2999',
							'3000-3999',
							'4000-4999',
							'5000-9999',
							'10000-'
						],
						datatype: 'String',
						alias: 'NO_EMPLOYEES_COMPANY'
					};
					break;
				case 'PRIMARY_LINE_OF_BUSINESS':
					opts = {
						name: Tools.$translate('default.companyBranch'),
						datatype: 'Select',
						alias: 'PRIMARY_LINE_OF_BUSINESS',
						default: _.map(sniCodeOptions, (name, code) => {
							return code + ' ' + name;
						})
					};
					break;
				case 'RATING':
					var options = [
						'admin.customfieldCreditRatingAAA',
						'admin.customfieldCreditRatingAA',
						'admin.customfieldCreditRatingA',
						'admin.customfieldCreditRatingNewCompany',
						'admin.customfieldCreditRatingB',
						'admin.customfieldCreditRatingC',
						'admin.customfieldCreditRatingUnknown'
					].map(option => {
						return Tools.$translate(option);
					});

					opts = {
						name: Tools.$translate('default.creditRating'),
						default: options,
						datatype: 'Select',
						alias: 'RATING'
					};

					break;

				case 'LEGALFORM':
					opts = {
						name: Tools.$translate('soliditet.corporateForm'),
						alias: 'LEGALFORM'
					};
					break;
				case 'ORG_NO':
					opts = {
						name: Tools.$translate('default.orgNumber'),
						alias: 'ORG_NO'
					};
					break;
				case 'COMPANY_TYPE':
					opts = {
						name: Tools.$translate('soliditet.companyType'),
						default: ['soliditet.headquarters', 'soliditet.establishments'].map(option => {
							return Tools.$translate(option);
						}),
						datatype: 'Select',
						alias: 'COMPANY_TYPE'
					};
					break;
			}

			return _.assign({}, def, opts);
		});
	};

	predefinedChanged = event => {
		const config = _.find(this.state.preDefinedFieldsData, { alias: event.target.value });

		this.setState({
			field: _.cloneDeep(config ? config : this.defaultField),
			selectedTab: this.state.tabs[1].key
		});
	};

	typeChange = (type, icon) => {
		const field = _.cloneDeep(this.state.field);

		field.datatype = type;
		field.icon = icon;
		field.default = '';
		field.dropdownDefault = [];
		field.name = this.state.field.name || '';

		this.setState({
			field: field,
			selectedTab: this.state.tabs[1].key
		});
	};

	selectUpdate = values => {
		const field = _.cloneDeep(this.state.field);
		field.default = values.split('\n');

		this.setState({
			field: field
		});
	};

	initAce = setValue => {
		const self = this;

		if (document.getElementById('editor')) {
			this._ace = ace.edit('editor');
			this._ace.renderer.setShowGutter(true);
			this._ace.renderer.setPadding(3);

			if (setValue) {
				if (
					Array.isArray(this.state.field.default) &&
					this._ace.getValue() !== this.state.field.default.join('\n')
				) {
					this._ace.setValue(this.state.field.default.join('\n'));
				}
				this._ace.clearSelection();
			}

			this._ace.getSession().on('change', () => {
				if (self.timeout) {
					clearTimeout(self.timeout);
					self.timeout = null;
				}

				self.timeout = setTimeout(() => {
					if (this._ace) {
						self.selectUpdate(this._ace.getValue());
					}
				}, 200);
			});
		} else {
			this.setState({
				field: { ...this.state.field }
			});
		}
	};

	onMountAndUpdate = () => {
		if ((this.state.field.alias && this.state.field.alias.length) || this.state.field.default) {
			if (this.state.field.datatype === 'Select') {
				this.initAce(true);
			}
			if (this.state.field.datatype === 'User' || this.state.field.datatype === 'Users') {
				this.usersUpdate();
			}
		} else if (this.state.field.datatype === 'Select') {
			this.initAce(false);
		}

		this.setSaveDisabled();

		setTimeout(() => this.props.reloadModalPosition(), 50);
	};

	componentWillUnmount() {
		if (this._ace) {
			this._ace.destroy();
		}
	}

	componentDidMount = () => {
		this.onMountAndUpdate();
	};

	shouldComponentUpdate = (nextProps, nextState) => {
		return (
			JSON.stringify(this.state.field) !== JSON.stringify(nextState.field) ||
			this.state.selectedTab !== nextState.selectedTab ||
			this.state.submitted !== nextState.submitted ||
			this.state.saveDisabled !== nextState.saveDisabled
		);
	};

	componentDidUpdate = () => {
		this.onMountAndUpdate();
	};

	generateTypes = classes => {
		const self = this;

		const rows = _.map(this.state.datatypes, dt => {
			return (
				<td
					key={'td-type-select-' + dt.type}
					className={classes
						.elem('type-select')
						.mod({ active: self.state.field.datatype === dt.type })
						.b()}
					onClick={self.typeChange.bind(self, dt.type, dt.icon)}
				>
					<div className={classes.elem('type-wrapper').b()}>
						<div className={classes.elem('type-select-icon').mod(dt.classMod).b()}>{dt.icon}</div>
						<div className={classes.elem('type-select-text').b()}>
							{Tools.$translate('admin.customfieldType.' + dt.type)}
						</div>
					</div>
				</td>
			);
		});

		const ROW_LENGTH = 5;

		const _rows = (
			<tbody>
				<tr>{rows.slice(0, ROW_LENGTH)}</tr>
				<tr>{rows.slice(ROW_LENGTH, ROW_LENGTH * 2)}</tr>
				<tr>{rows.slice(ROW_LENGTH * 2, rows.length)}</tr>
			</tbody>
		);

		return (
			<div className={classes.elem('type-select-container').b()}>
				{this.state.preDefinedField !== null && (
					<div
						style={{
							cursor: 'no-drop',
							position: 'absolute',
							left: 0,
							bottom: 0,
							right: 0,
							top: 0,
							backgroundColor: 'rgba(0,0,0,0.075)'
						}}
					/>
				)}
				<table
					style={{
						width: '100%',
						borderCollapse: 'separate'
					}}
				>
					{_rows}
				</table>
			</div>
		);
	};

	nameChange = e => {
		const field = _.cloneDeep(this.state.field);
		field.name = e.target.value;

		this.setState({
			field: field,
			submitted: !field.name
		});
	};

	rolesChange = roles => {
		const field = _.cloneDeep(this.state.field);
		field.roles = roles;

		this.setState({
			field: field
		});
	};

	stagesChange = stages => {
		const field = _.cloneDeep(this.state.field);
		field.stages = _.map(stages, function (stage) {
			stage.required = true;
			return stage;
		});

		this.setState({
			field: field
		});
	};

	formGroupChange = formGroup => {
		const field = _.cloneDeep(this.state.field);
		field.formGroup = formGroup;

		this.setState({
			field: field
		});
	};

	getFormGroupData = () => {
		return [
			{ value: null, label: this.lang.defaultFormGroup },
			...Array.from(
				Tools.AppService.getCustomFields(this.props.type).reduce((previous, field) => {
					if (field.formGroup) {
						previous.add(field.formGroup);
					}
					return previous;
				}, new Set())
			).map(group => ({ value: group, label: group }))
		];
	};

	onProductCategoryChange = e => {
		let updatedCategories;

		if (e.target.added) {
			updatedCategories = [...this.state.field.categories, e.target.added];
		} else {
			updatedCategories = this.state.field.categories.filter(category => category.id !== e.target.removed.id);
		}

		this.setState({
			field: { ...this.state.field, categories: updatedCategories }
		});
	};

	onTypeChange = e => {
		let updatedTypes;

		if (e.target.added) {
			updatedTypes = [...this.state.field.types, e.target.added];
		} else {
			updatedTypes = this.state.field.types.filter(type => type.id !== e.target.removed.id);
		}

		this.setState({
			field: { ...this.state.field, types: updatedTypes }
		});
	};

	requiredFieldChange = required => {
		const field = _.cloneDeep(this.state.field);
		field.obligatoryField = required ? 1 : 0;
		field.stages = [];

		if (required) {
			field.visible = 1;
			field.editable = 1;
		}

		this.setState({
			field: field
		});
	};

	editableFieldChange = editable => {
		const field = _.cloneDeep(this.state.field);

		field.editable = editable ? 1 : 0;
		if (!editable) {
			field.obligatoryField = 0;
		}

		this.setState({
			field: field
		});
	};

	searchableFieldChange = searchable => {
		const field = _.cloneDeep(this.state.field);

		field.searchable = searchable ? 1 : 0;

		this.setState({
			field: field
		});
	};

	visibleFieldChange = visible => {
		const field = _.cloneDeep(this.state.field);

		field.visible = visible ? 1 : 0;
		if (!visible) {
			field.obligatoryField = 0;
			field.editable = 0;
		}

		if (field.datatype === 'Calculation') {
			field.editable = field.visible;
		}

		this.setState({
			field: field
		});
	};

	showFormulaToggleChange = showFormula => {
		const field = _.cloneDeep(this.state.field);

		field.formulaVisible = showFormula;

		this.setState({
			field: field
		});
	};

	preFilledValueChange = event => {
		const value = event.target.value;
		const field = _.cloneDeep(this.state.field);

		if (!value) {
			field.dropdownDefault = [];
		} else {
			field.dropdownDefault = [value];
		}

		this.setState({
			field: field
		});
	};

	validate = () => {
		const field = _.cloneDeep(this.state.field);
		delete field.icon;

		if (field.hasOwnProperty('canSelectMultipleUsers')) {
			field.datatype = field.canSelectMultipleUsers ? 'Users' : 'User';
		}

		if (!field.dropdownDefault?.length) {
			field.dropdownDefault = null;
		}

		if (field.name) {
			this.props.confirm(
				field,
				this.props.type,
				...(this.props.reject && this.props.resolve ? [this.props.reject, this.props.resolve] : [])
			);
		} else {
			this.setState({
				submitted: true
			});
		}
	};

	tabsOnchange = value => {
		this.setState({
			selectedTab: value
		});
	};

	renderTabs = (classes, selectedTab, tabs) => {
		if (this.state.field.id) {
			return null;
		}

		return (
			<Tabs selected={selectedTab} onChange={this.tabsOnchange} className={classes.elem('tabs').b()}>
				{tabs &&
					tabs.map((tab, index) => {
						return (
							<Tab key={tab.id} {...tab}>
								<ColorSwitcher>{index + 1}</ColorSwitcher>
								{tab.text}
							</Tab>
						);
					})}
			</Tabs>
		);
	};

	next = () => {
		this.setState({
			selectedTab: this.state.tabs[1].key
		});
	};

	isSelectField = () => {
		return this.state.field && this.state.field.datatype && this.state.field.datatype === 'Select';
	};

	alternativesTextTooLong = () => this.state.field.default.toString().length > MAX_SELECT_LENGTH;

	setSaveDisabled = () => {
		if (this.state.field.datatype !== 'Calculation') {
			if (
				this.state.selectedTab !== 'Field Type' &&
				((this.isSelectField() && this.alternativesTextTooLong()) ||
					!this.state.field.name ||
					this.state.field.name === '')
			) {
				this.setState({
					saveDisabled: true
				});
			} else {
				this.setState({
					saveDisabled: false
				});
			}
		}
	};

	renderSelectTooltip = () => {
		const hasFieldName = this.state.field.name && this.state.field.name !== '';
		const errors = [];
		if (!hasFieldName) {
			errors.push(`${this.lang.customFieldName} ${this.lang.required.toLowerCase()}`);
		}
		if (this.alternativesTextTooLong()) {
			errors.push(
				`${t('admin.fields.overCharacterLimitShort')} ${this.lang.in.toLowerCase()} ${
					this.lang.alternativesToChooseFrom
				}`
			);
		}
		return errors.join('\n');
	};

	render() {
		const hasCustomContactSearch = Tools.FeatureHelper.hasSoftDeployAccess('CLIENT_CONTACT_CUSTOM_FIELD_SEARCH');
		const validEntities = ['activity', 'appointment', 'account', 'order', 'orderrow', 'product'];
		if (hasCustomContactSearch) {
			validEntities.push('contact');
		}

		const classes = new bemClass('EditCustomField');
		const { selectedTab, tabs, field, submitted } = this.state;

		const roleselect = (
			<div>
				{this.hasTreeSelect && this.hasRoleFeature ? (
					<RoleSelect
						positionRelative
						reloadModalPosition={this.props.reloadModalPosition}
						roles={this.props.roles}
						selectedRoles={this.state.field.roles}
						onChange={this.rolesChange}
					/>
				) : (
					<ReactTemplates.INPUTS.upRoles
						multiple={true}
						disabled={!this.hasRoleFeature}
						key={'select-not-active'}
						className="form-control multi-comparitors"
						onChange={this.rolesChange}
						data={this.props.roles}
						value={this.state.field.roles}
						placeholder={this.lang.roleSelectPlaceholder}
					/>
				)}
			</div>
		);

		let title;
		if (tabs[0].id === selectedTab) {
			title = this.lang.title;
		} else {
			title = `${this.state.field.id ? this.lang.edit : this.lang.new} ${Tools.$translate(
				'admin.customfieldType.' + field.datatype
			).toLowerCase()}`;
		}

		const isValidEntity = validEntities.indexOf(this.props.type) >= 0;
		const isInvalidType = ['Calculation', 'Boolean'].indexOf(this.state.field.datatype) >= 0;
		const isSearchable = isValidEntity && !isInvalidType;

		return (
			<div className={classes.b()}>
				<ModalHeader color="green">
					<Title className="ModalHeader__title" size="md">
						{tabs[1].id === selectedTab ? <div className="ModalHeader__icon">{field.icon}</div> : null}
						{title}
					</Title>
					{this.renderTabs(classes, selectedTab, tabs)}
				</ModalHeader>
				<ModalContent className="ModalContent--no-padding">
					{selectedTab === tabs[0].id && !this.state.field.id ? (
						<div>
							{this.props.type === 'account' && !this.hasNewFields ? (
								<div className={classes.elem('pre-defined-wrap').b()}>
									<p>{this.lang.preDefinedField_description}</p>
									<ReactTemplates.INPUTS.upSelect
										key={'select-preDefined'}
										allowClear={true}
										className={classes.elem('predefined-select').b()}
										placeholder={this.lang.clickHere}
										data={this.state.preDefinedFieldsData}
										minimumResultsForSearch={-1}
										onChange={this.predefinedChanged}
										matcher={function (term, text, field) {
											return field.name.toUpperCase().indexOf(term.toUpperCase()) >= 0;
										}}
										formatSelection={function (field, container, escape) {
											return escape(field.name);
										}}
										formatResult={function (field, container, query, escape) {
											return escape(field.name);
										}}
										getId={function (field) {
											return field.alias;
										}}
									/>
								</div>
							) : null}
							{this.generateTypes(classes)}
						</div>
					) : null}
					{selectedTab === tabs[1].id ? (
						<div className={classes.elem('form').b()}>
							{this.state.field.integrationDependency?.integration ? (
								<StateFrame
									icon="warning"
									state="warning"
									subtitle={t('admin.fields.integrationFieldWarningSubtitle', {
										integration: this.state.field.integrationDependency?.integration
									})}
									title={this.lang.integrationFieldWarningTitle}
									space="mbl"
								/>
							) : null}
							<Row>
								<Column>
									<Label required={true}>{this.lang.customFieldName}</Label>
									<Input
										required={true}
										value={this.state.field.name}
										onChange={this.nameChange}
										state={submitted ? 'error' : null}
										placeholder={this.lang.customFieldName}
										type="text"
										maxLength="128"
									/>

									{this.state.field.datatype === 'Select' && (
										<div
											key="permission-setting"
											style={{
												marginTop: '10px'
											}}
										>
											<Label>{this.lang.permission}</Label>
											{this.hasTreeSelect && this.hasRoleFeature ? (
												<RoleSelect
													positionRelative
													reloadModalPosition={this.props.reloadModalPosition}
													roles={this.props.roles}
													selectedRoles={this.state.field.roles}
													onChange={this.rolesChange}
												/>
											) : (
												<ReactTemplates.INPUTS.upRoles
													multiple={true}
													disabled={!this.hasRoleFeature}
													key={'select-active'}
													ref={this.setRef.bind(this, '_selectRole')}
													className="form-control multi-comparitors"
													onChange={this.rolesChange}
													data={this.props.roles}
													value={this.state.field.roles}
													placeholder={this.lang.permission}
												/>
											)}
										</div>
									)}

									{this.state.field.datatype === 'Select' && (
										<div style={{ marginTop: '10px' }}>
											<Label>{Tools.$translate('admin.fields.prefilledValues')}</Label>
											<ReactTemplates.INPUTS.upSelect
												key={(this.state.field.default || '').toString()}
												placeholder={Tools.$translate('admin.fields.prefilledValues')}
												formatSelection={(o, container, escape) => {
													return escape(o);
												}}
												formatResult={(o, container, query, escape) => {
													return escape(o);
												}}
												getId={value => {
													return value;
												}}
												matcher={(term, text, field) => {
													return field.toLowerCase().indexOf(term.toLowerCase()) >= 0;
												}}
												className="form-control"
												multiple={false}
												data={
													Array.isArray(this.state.field.default)
														? this.state.field.default
														: []
												}
												onChange={this.preFilledValueChange}
												defaultValue={
													Array.isArray(this.state.field.dropdownDefault) &&
													this.state.field.dropdownDefault.length
														? this.state.field.dropdownDefault[0]
														: ''
												}
											/>
										</div>
									)}

									{this.hasProductCustomFieldCategories && (
										<div className={classes.elem('product-category-select').b()}>
											<Label>{this.lang.connectToProductCategory}</Label>
											<ReactTemplates.INPUTS.upSelect
												className="form-control"
												placeholder={this.lang.productCategoryPlaceHolder}
												multiple
												data={this.allProductCategories}
												onChange={this.onProductCategoryChange}
												defaultValue={this.state.field.categories}
											/>
										</div>
									)}

									{this.canRestrictToTypes ? (
										<div className={classes.elem('product-category-select').b()}>
											<Label>{this.lang.typePlaceHolder}</Label>
											<ReactTemplates.INPUTS.upSelect
												className="form-control"
												placeholder={this.lang.chooseTypes}
												multiple
												data={this.allTypes}
												onChange={this.onTypeChange}
												defaultValue={this.state.field.types}
											/>
										</div>
									) : null}

									<div>
										<div className={classes.elem('toggle-wrap')}>
											<div key="visibleField-toggle">
												<ReactTemplates.upFilters.components.toggle
													checked={this.state.field.visible}
													className="toggle-green"
													onChange={this.visibleFieldChange}
												/>
												<Label>{this.lang.visible}</Label>
											</div>

											{this.state.field.datatype !== 'Calculation' && (
												<div key="editableField-toggle">
													<Tooltip
														title={this.lang.disabledEditableToggleTooltip}
														disabled={!!this.state.field.visible}
													>
														<ReactTemplates.upFilters.components.toggle
															checked={this.state.field.editable}
															className="toggle-green"
															onChange={this.editableFieldChange}
															disabled={!this.state.field.visible}
														/>
														<Label>{this.lang.editable}</Label>
													</Tooltip>
												</div>
											)}

											{isSearchable && (
												<div key="searchableField-toggle">
													<ReactTemplates.upFilters.components.toggle
														checked={this.state.field.searchable}
														className="toggle-green"
														onChange={this.searchableFieldChange}
													/>
													<Label>{this.lang.searchable}</Label>
												</div>
											)}

											{this.state.field.datatype !== 'Calculation' && (
												<div key="requiredField-toggle">
													<ReactTemplates.upFilters.components.toggle
														checked={this.state.field.obligatoryField}
														className="toggle-green"
														onChange={this.requiredFieldChange}
													/>
													<Label>
														{this.hasOrderCustomStages
															? this.lang.alwaysRequired
															: this.lang.formRequired}
													</Label>
												</div>
											)}

											{(this.state.field.datatype === 'Users' ||
												this.state.field.datatype === 'User') && (
												<div key="canSelectMultipleUsers-toggle" style={{ marginTop: 10 }}>
													<ReactTemplates.upFilters.components.toggle
														checked={this.state.field.datatype === 'Users' ? true : false}
														className="toggle-green multi-select"
														onChange={this.handleCanSelectMultipleChange}
													/>
													<Label>{this.lang.usersCanSelectMultipleUsers}</Label>
												</div>
											)}
										</div>

										{this.state.field.datatype === 'Select' && this.hasOrderCustomStages && (
											<div style={{ marginTop: 10 }}>
												<Label>{this.lang.requiredOnStages}</Label>
												<ReactTemplates.INPUTS.upStages
													multiple={true}
													disabled={this.state.field.obligatoryField}
													key={'select-not-active'}
													className="form-control multi-comparitors"
													onChange={this.stagesChange}
													data={this.props.stages}
													value={this.state.field.stages}
													placeholder={this.lang.stageSelectPlaceholder}
												/>
											</div>
										)}
										{this.hasFormGroups ? (
											<div className="form-group-container" style={{ marginTop: 10 }}>
												<Label>{this.lang.formGroup}</Label>
												<CreatableSelect
													className="form-group-select"
													menuPosition={'fixed'}
													styles={{ menuPortal: base => ({ ...base, zIndex: 1006 }) }}
													placeholder={this.lang.formGroupSelectPlaceholder}
													defaultValue={
														this.state.field.formGroup
															? {
																	value: this.state.field.formGroup,
																	label: this.state.field.formGroup
															  }
															: {
																	value: null,
																	label: this.lang.defaultFormGroup
															  }
													}
													isValidNewOption={label =>
														label.trim().length !== 0 && label.length < 40
													}
													onChange={data => (data ? this.formGroupChange(data.value) : null)}
													options={this.getFormGroupData()}
													formatCreateLabel={text =>
														`${this.lang.formGroupCreateNewLabel} ${text}`
													}
												></CreatableSelect>
											</div>
										) : null}
									</div>
								</Column>
								<Block space="prl" />

								{this.state.field.datatype === 'Select' && (
									<Column>
										<Flex justifyContent="space-between">
											<Label>{this.lang.alternativesToChooseFrom}</Label>
											<Tooltip
												title={t('admin.fields.overCharacterLimit', {
													progress: field.default.toString().length,
													max: MAX_SELECT_LENGTH
												})}
											>
												<Progress
													percentage={
														(field.default.toString().length / MAX_SELECT_LENGTH) * 100
													}
													hideText
													state={
														field.default.toString().length > MAX_SELECT_LENGTH
															? 'error'
															: null
													}
												/>
											</Tooltip>
										</Flex>
										<div
											className={
												field.default.toString().length > MAX_SELECT_LENGTH
													? 'editor-error'
													: ''
											}
										>
											{ReactTemplates.TOOLS.withTooltip(
												<div id="editor" />,
												field.default.toString().length > MAX_SELECT_LENGTH
													? t('admin.fields.overCharacterLimit', {
															progress: field.default.toString().length,
															max: MAX_SELECT_LENGTH
													  })
													: this.lang.oneOptionPerRow,
												{
													placement: 'top',
													key: 'editor-tool-tip'
												}
											)}
										</div>
									</Column>
								)}

								{this.state.field.datatype !== 'Select' && (
									<Column>
										<Label>{this.lang.permission}</Label>
										{!this.hasRoleFeature
											? ReactTemplates.TOOLS.withTooltip(
													roleselect,
													this.lang.requires + ' ' + this.lang.upsalesPlus,
													{ placement: 'top', key: 'upRoles-tool-tip' }
											  )
											: roleselect}

										{this.state.field.datatype !== 'Calculation' && this.hasOrderCustomStages && (
											<div style={{ marginTop: 10 }}>
												<Label>{this.lang.requiredOnStages}</Label>
												<ReactTemplates.INPUTS.upStages
													multiple={true}
													disabled={this.state.field.obligatoryField}
													key={'select-not-active'}
													className="form-control multi-comparitors"
													onChange={this.stagesChange}
													data={this.props.stages}
													value={this.state.field.stages}
													placeholder={this.lang.stageSelectPlaceholder}
												/>
											</div>
										)}
									</Column>
								)}
							</Row>
							{this.state.field.datatype === 'Calculation' && (
								<Calculation
									formula={this.state.field.formula}
									showFormula={this.state.field.formulaVisible}
									onChange={(formula, isValid) => {
										this.setState({
											field: { ...this.state.field, formula },
											saveDisabled: !isValid
										});
									}}
									toggleVisibleFormula={this.showFormulaToggleChange}
									dataType={this.props.type}
								/>
							)}
						</div>
					) : null}
				</ModalContent>
				<ModalControls>
					<div className={classes.elem('controls-link').b()}>
						<Link>
							<ReactTemplates.elevio sidebar={true} articleId={673} text={this.lang.footerText} />
						</Link>
					</div>

					<Tooltip title={this.isSelectField() ? this.renderSelectTooltip() : ''}>
						<Button
							submit={true}
							shadow="none"
							disabled={this.state.saveDisabled}
							loading={this.props.saving ? true : false}
							onClick={selectedTab === tabs[0].id ? this.next : this.validate}
						>
							{selectedTab === tabs[0].id ? this.lang.next : this.lang.save}
							{this.props.saving ? <span className="fa fa-refresh" /> : null}
						</Button>
					</Tooltip>
					<Button
						shadow="none"
						type="link"
						color="grey"
						onClick={() => {
							this.props.reject?.();
							this.props.close?.(); // TODO: Remove during clean-up and simplify onClick
						}}
					>
						{this.lang.abort}
					</Button>
				</ModalControls>
			</div>
		);
	}
}

EditCustomField.propTypes = {
	type: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	field: PropTypes.object.isRequired,
	customFields: PropTypes.array.isRequired,
	roles: PropTypes.array.isRequired,
	close: PropTypes.func, // TODO: Remove during clean-up
	reject: PropTypes.func,
	resolve: PropTypes.func,
	confirm: PropTypes.func.isRequired,
	files: PropTypes.array,
	SniCodes: PropTypes.object.isRequired,
	newFiles: PropTypes.array,
	onDrop: PropTypes.func,
	saving: PropTypes.bool,
	onRemove: PropTypes.func,
	reloadModalPosition: PropTypes.func,
	stages: PropTypes.array
};

window.EditCustomField = EditCustomField;

export const EditCustomFieldModal = setupComponentCompatibility(EditCustomField, {
	modalParamsMapper: $modalParams => {
		var props = {
			onClose: $modalParams.onClose,
			type: $modalParams.type,
			title: $modalParams.title,
			field: $modalParams.field,
			customFields: $modalParams.customFields,
			roles: Tools.AppService.getRoles(),
			confirm: function (field, type, reject, resolve) {
				var customerId = Tools.AppService.getCustomerId();
				Tools.CustomField.customer(customerId)
					.setType(type)
					.save(field)
					.then(function (res) {
						if (res.error) {
							reject(res.error);
						} else {
							const customFields = $modalParams.customFields;

							if (field.id) {
								const index = customFields.findIndex(f => f.id === field.id);
								customFields[index] = res.data;
							} else {
								customFields.push(res.data);
							}
							Tools.AppService.setCustomFields(type, customFields);
							resolve(res.data);
						}
					})
					.catch(function (err) {
						reject(err);
					});
			},
			SniCodes: Tools.SniCodes
		};
		return props;
	},
	modalSize: 'xl'
});

export const openEditCustomFieldModal = asyncModalAdapter({
	featureFlag: 'REACT_EDIT_CUSTOM_FIELDS',
	upModalName: 'editCustomFields',
	openModalName: 'EditCustomFieldModal',
	rejectOnEmpty: false,
	debug: false
});
