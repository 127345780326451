import { Column, Widget } from 'App/components/ExportDataReact/types';
import { BuildFilters } from './RequestBuilder';
import Resource from './Resource';

export type ExportSaveType = {
	entity: string;
	columns: Partial<Column>[];
	filters: BuildFilters;
	includes?: string[];
	name?: string;
	exportOrderRows?: boolean;
	widget?: Widget;
};

class Export extends Resource {
	constructor() {
		super('/function/export');
	}

	async save(data: ExportSaveType, opts = {}) {
		return super.save(data, opts);
	}

	async get() {
		throw Error('Export.get() not allowed');
	}

	async delete() {
		throw Error('Export.delete() not allowed');
	}

	async find() {
		throw Error('Export.find() not allowed');
	}
}

const ExportResource = new Export();

export default ExportResource;
