import React, { useEffect, useRef, useState } from 'react';
import T from 'Components/Helpers/translate';
import {
	ResourceSelect,
	ResourceIdSelect,
	ResourceMultiSelect,
	ResourceIdSelectPropsSingle
} from 'App/components/ResourceSelect';
import logError from 'App/babel/helpers/logError';
import ProjectResource from 'App/resources/Project';
import ToggleSelect from '../ToggleSelect';
import RequestBuilder, { comparisonTypes } from 'Resources/RequestBuilder';
import CallListResource from 'Resources/CallList';
import Project, { CallList } from 'App/resources/Model/Project';
import { Row, Text, Tooltip } from '@upsales/components';
import Icon from '@upsales/components/Icon';
import { SelectItem } from '@upsales/components/Utils/selectHelpers';
import { OptionalQueryElement } from 'App/helpers/PopupPortalAnchors';
import { CancelablePromise } from '@upsales/components/Utils/CancelablePromise';
import { makeCancelable } from '@upsales/components/Utils/CancelablePromise';

type CampaignObject = Pick<CallList, 'users' | 'id' | 'name'> & { isShared?: boolean };
export type CampaignSelectItem = Partial<Omit<CampaignObject, 'id'>> & SelectItem;

const toSelectItem = (campaign: CampaignObject): CampaignSelectItem => ({
	...campaign,
	id: campaign.id,
	title: campaign.name
});

const createCampaign = async (name: string): Promise<CampaignSelectItem | null> => {
	try {
		const { data: savedCampaign } = await ProjectResource.save({
			name,
			users: [{ id: Tools.AppService.getSelf().id }]
		});
		return toSelectItem(savedCampaign);
	} catch (error) {
		logError(error, 'Failed to create campaign');
		return null;
	}
};

const createCallList = async (name: string): Promise<CampaignSelectItem | null> => {
	try {
		const { data: savedCallList } = await CallListResource.save({
			name,
			users: [{ id: Tools.AppService.getSelf().id }]
		});
		return toSelectItem(savedCallList);
	} catch (error) {
		logError(error, 'Failed to create call list');
		return null;
	}
};

type Props = Omit<React.ComponentProps<typeof ToggleSelect<SelectItem>>, 'children' | 'lang' | 'onCreateNew'> & {
	anchor: OptionalQueryElement;
	isCallList?: boolean;
};

type PropsId = Omit<Props, 'value' | 'onChange'> & Pick<ResourceIdSelectPropsSingle, 'value' | 'onChange'>;

const getLang = ({ isCallList }: Pick<Props, 'isCallList'>) => ({
	addTo: isCallList ? T('default.addToCallList') : T('default.addToCampaign'),
	placeIn: T('todo.placePhonecallsInList'),
	createNewObject: isCallList
		? T('phonecall.relationFields.createCallList')
		: T('phonecall.relationFields.createCampaign'),
	nameNewObject: T('callList.nameYourList'),
	saveNewObject: isCallList ? T('callList.saveList') : T('campaign.save'),
	placeholder: isCallList ? T('callList.select') : T('select_campaign')
});

const modifyRb = (rb: RequestBuilder, isCallList: Props['isCallList']) => {
	rb.addFilter(ProjectResource.attr.active, comparisonTypes.Equals, true);
	if (isCallList) {
		rb.fields = ['id', 'name', 'users'];
	}
};

const renderItem = (item: SelectItem & Partial<Pick<CampaignObject, 'users'>>) => (
	<Row>
		<Text color="inherit">{item.title}</Text>
		{(item.users?.length ?? 0) > 1 ? (
			<Tooltip title={T('callList.shared')}>
				<Icon name="users" color="grey-11" space="pls" />
			</Tooltip>
		) : null}
	</Row>
);

const getSelectProps = (props: Pick<Props, 'isCallList' | 'anchor' | 'required'>, placeholder: string) => ({
	resource: props.isCallList ? CallListResource : ProjectResource,
	anchor: props.anchor,
	required: props.required,
	placeholder: placeholder,
	modifyRb: (rb: RequestBuilder) => modifyRb(rb, props.isCallList),
	renderItem: renderItem
});

const CampaignSelect = (props: Props) => {
	const { anchor, isCallList = false, ...toggleSelectProps } = props;

	const lang = getLang(props);

	const hasSharedCallLists = isCallList && Tools.FeatureHelper.hasSoftDeployAccess('SHARED_CALL_LISTS');
	const ri = hasSharedCallLists ? renderItem : undefined;

	return (
		<ToggleSelect<SelectItem>
			lang={lang}
			onCreateNew={isCallList ? createCallList : createCampaign}
			{...toggleSelectProps}
		>
			<ResourceSelect
				{...getSelectProps(props, lang.placeholder)}
				renderItem={ri}
				value={props.value}
				onChange={v => {
					if (v) props.onChange(v);
				}}
			/>
		</ToggleSelect>
	);
};

export const CampaignIdSelect = (props: PropsId) => {
	const { anchor, isCallList = false, ...toggleSelectProps } = props;
	// We cant rely on the api to return the newly created item (index is slow) directly after create. We need to add this item to a separate array.
	const [extraOptions, setExtraOptions] = useState<SelectItem[]>([]);
	const createPromise = useRef<CancelablePromise<Project | CallList | null>>();

	// Cancel create promise on unmount
	useEffect(() => () => createPromise.current?.cancel(), []);

	const lang = getLang(props);

	const hasSharedCallLists = isCallList && Tools.FeatureHelper.hasSoftDeployAccess('SHARED_CALL_LISTS');
	const ri = hasSharedCallLists ? renderItem : undefined;

	const create = async (name: string) => {
		const promise = makeCancelable(isCallList ? createCallList(name) : createCampaign(name));

		const item = await promise.promise;

		if (item) {
			// Add item to array of extraOptions to pass to the select.
			// This is because in some cases the newly created item is not yet available in the index, so we can not rely on the internal re-fetch of the select
			setExtraOptions([...extraOptions, item]);
		}

		return item?.id ?? null;
	};

	return (
		<ToggleSelect lang={lang} onCreateNew={create} {...toggleSelectProps}>
			<ResourceIdSelect
				{...getSelectProps(props, lang.placeholder)}
				extraOptions={extraOptions}
				renderItem={ri}
				value={props.value}
				onChange={props.onChange}
			/>
		</ToggleSelect>
	);
};

type PropsMulti = Omit<Props, 'value' | 'onChange'> & PickProps<typeof ResourceMultiSelect, 'value' | 'onChange'>;

//Should be removed because not used anywhere? use upCampaigns instead

export const CampaignMultiSelect = (props: PropsMulti) => {
	const { anchor, isCallList = false, onChange: toggleOnChange, ...toggleSelectProps } = props;
	const createPromise = useRef<CancelablePromise<Project | CallList | null>>();

	useEffect(() => () => createPromise.current?.cancel(), []);

	const lang = getLang(props);

	const hasSharedCallLists = isCallList && Tools.FeatureHelper.hasSoftDeployAccess('SHARED_CALL_LISTS');
	const ri = hasSharedCallLists ? renderItem : undefined;

	const create = async (name: string) => {
		const promise = makeCancelable(isCallList ? createCallList(name) : createCampaign(name));

		const item = await promise.promise;
		return item ? [...props.value, item] : null;
	};

	return (
		<ToggleSelect<SelectItem[]>
			lang={lang}
			onCreateNew={create}
			onChange={value => toggleOnChange(value ?? [])}
			{...toggleSelectProps}
		>
			<ResourceMultiSelect
				{...getSelectProps(props, lang.placeholder)}
				renderItem={ri}
				value={props.value}
				onChange={props.onChange}
			/>
		</ToggleSelect>
	);
};

export default CampaignSelect;
