import React, { useCallback, useEffect, useState } from 'react';

import moment from 'moment';
import {
	Flex,
	Link,
	Modal,
	ModalContent,
	ModalControls,
	ModalHeader,
	NumberInput,
	Select,
	Text,
	Textarea,
	TimeInput
} from '@upsales/components';
import { SelectItem } from '@upsales/components/Utils/selectHelpers';
import BemClass from '@upsales/components/Utils/bemClass';

import { mapProperties, parseCustomFields, parseProperties } from 'Services/ActionProperties';
import { MultiSelectContext } from 'App/components/MultiselectProvider/MultiselectProvider';
import { prepTriggerCategories } from '../MultiUpdateActivity/MultiUpdateActivity';
import { defaultFilterGetter } from 'App/helpers/multiActionRunnerHelpers';
import { getNewStartEndTime, parseDatePropToState } from '../MultiHelpers';
import { CustomFieldWithValue } from 'App/resources/Model/CustomField';
import { asyncModalAdapter } from 'App/helpers/angularPortingHelpers';
import { useAppointmentTypes } from 'App/components/hooks/appHooks';
import { ResourceIdSelect } from 'App/components/ResourceSelect';
import { PropertyArray, PropertyMap } from '../MultiActionModal';
import { formatDateType, prepDates } from 'Helpers/multiModals';
import { useTranslation } from 'Components/Helpers/translate';
import LabeledInput from 'App/components/Inputs/LabeledInput';
import { ModalProps } from 'App/components/Modals/Modals';
import { ThirdButton } from '@upsales/components/Buttons';
import RequestBuilder from 'Resources/RequestBuilder';
import TextInput from 'Components/Inputs/TextInput';
import ProjectResource from 'App/resources/Project';
import { useSelector } from '../../hooks';

import './MultiUpdateAppointment.scss';

type Props = {
	isTrigger?: boolean;
	entity: string;
	tagEntity: string;
	filters: any;
	properties: PropertyArray;
	multiSelect: MultiSelectContext;
	onSave?: (modalParams: PropertyArray) => Promise<void>;
} & ModalProps<PropertyArray>;

const MultiUpdateAppointment = ({
	isTrigger = false,
	multiSelect,
	onSave,
	className,
	close,
	...modalParams
}: Props) => {
	const { t } = useTranslation();
	const { entity, tagEntity } = modalParams;
	const { dateTags, dateTypes, customDateTypes: cdt } = prepDates(entity || tagEntity);

	const triggerDateGroups = prepTriggerCategories(dateTypes);
	const [saving, setSaving] = useState(false);
	const anchorRef: React.MutableRefObject<HTMLDivElement | null> = React.useRef<HTMLDivElement>(null);

	const [standardProprties, customProperties] = modalParams.properties?.reduce(
		(acc: PropertyArray[], prop) => {
			if (!prop.name.includes('custom')) {
				acc[0].push(prop);
			} else {
				acc[1].push(prop);
			}
			return acc;
		},
		[[], []]
	) || [[], []];

	const [properties, setProperties] = useState<PropertyMap>(
		parseProperties(standardProprties) || {
			User: Tools.AppService.getSelf().id
		}
	);

	const { customerId, customFields: startingFields } = useSelector(({ App }) => ({
		customerId: App.customerId,
		customFields: App.customFields.appointment
	}));

	const getInitialDateState = (dateString?: string) => {
		if (!dateString) {
			return { selectItem: null, offset: 0 };
		}
		return parseDatePropToState(dateString, triggerDateGroups);
	};

	const [triggerStartDate, setTriggerStartDate] = useState<{
		selectItem: SelectItem | null;
		offset: number;
	}>(getInitialDateState(properties.StartDate as string));
	const [triggerEndDate, setTriggerEndDate] = useState<{
		selectItem: SelectItem | null;
		offset: number;
	}>(getInitialDateState(properties.EndDate as string));

	const customDateTypes = formatDateType(cdt) as { [key: string]: any };

	const newFields = structuredClone(startingFields)
		.filter(field => field.editable)
		.map(field => {
			if (customDateTypes && field.datatype === 'Date') {
				(field as any).customDateSelect = true;
			}
			return field;
		});

	const [initialCustomFields, initialAvailableFileds] = parseCustomFields(
		newFields,
		parseProperties(customProperties),
		'Appointment',
		dateTags
	);

	const [customFields, setCustomFields] = useState<CustomFieldWithValue[]>(initialCustomFields || []);
	const [availableFields, setAvailableFields] = useState<CustomFieldWithValue[]>(initialAvailableFileds || []);

	const classes = new BemClass('MultiUpdateAppointment', className);

	const save = async () => {
		setSaving(true);

		const propArray: PropertyArray = [];

		Tools.ActionProperties.mapCustomFields(propArray, customFields, 'Appointment');
		propArray.push(...mapProperties(properties));

		if (triggerStartDate.selectItem?.id) {
			propArray.push({
				name: 'StartDate',
				value: '{{' + triggerStartDate.selectItem.id + ':' + (triggerStartDate.offset || 0) + '}}'
			});
		}
		if (triggerEndDate.selectItem?.id) {
			propArray.push({
				name: 'EndDate',
				value: '{{' + triggerEndDate.selectItem.id + ':' + (triggerEndDate.offset || 0) + '}}'
			});
		}

		if (onSave) {
			return onSave(propArray)
				.then(() => {
					close();
				})
				.finally(() => {
					setSaving(false);
				});
		}

		if (!modalParams.filters || !propArray.length) {
			return close(propArray);
		}

		const filters = defaultFilterGetter(modalParams.filters ?? new RequestBuilder(), multiSelect);

		const savePromise = Tools.MultiActions.customer(customerId).updateAppointment(
			multiSelect.selectedIds.length,
			propArray,
			filters.build()
		);

		return savePromise
			.then(() => {
				multiSelect.selectNone();
				close();
			})
			.finally(() => {
				setSaving(false);
			});
	};

	const handleCustomFieldChange = (value: {
		id: CustomFieldWithValue['id'];
		title: CustomFieldWithValue['name'];
	}) => {
		const selectedField = availableFields.find(field => field.id === value.id);
		if (selectedField) {
			setCustomFields(() => [...customFields, selectedField]);
		}

		setAvailableFields(prev => prev.filter(field => field.id !== value.id));
	};

	const removeCustomField = (field: CustomFieldWithValue) => {
		if (customDateTypes && field.datatype === 'Date') {
			(field as any).customDateSelect = true;
		}
		setCustomFields(customFields.filter(f => f.id !== field.id));
		setAvailableFields([...availableFields, field]);
	};

	const handleCustomFieldValueChange = (field: CustomFieldWithValue, value: any) => {
		setCustomFields(
			customFields.map(f => {
				if (f.id === field.id) {
					let newValue = value;
					if (field.datatype === 'Date' && value?.tag === 'RegularDate') {
						(field as any).customDateSelect = false;
						newValue = '';
					}

					return { ...f, value: newValue };
				}
				return f;
			})
		);
	};

	const showErrorMessage = (form: any) => {
		return false;
	};

	const createCampaign = async () => {
		return Tools.$upModal
			.open('editCampaign', { customerId: customerId, noRedirect: true })
			.then(res => setProperties({ ...properties, Project: res.id }));
	};

	const appointmentOutcomes = [
		{ id: 'planned', title: t('appointment.outcome.planned') },
		{ id: 'completed', title: t('appointment.outcome.completed') },
		{ id: 'notCompleted', title: t('appointment.outcome.notCompleted') }
	];
	const appointmentTypesOptions = (useAppointmentTypes() || []).map(e => ({
		...e,
		title: e.name
	}));

	const onTimeChange = useCallback(
		(time: string, timeIn: 'StartTime' | 'EndTime') => {
			setProperties((prev: PropertyMap) => ({
				...prev,
				...getNewStartEndTime(time, timeIn, properties.StartTime as Date, properties.EndTime as Date)
			}));
		},
		[properties.StartTime, properties.EndTime]
	);

	useEffect(() => {
		anchorRef.current = document.querySelector('.Modals');
	}, []);

	return (
		<Modal className={classes.b()} size="lg">
			<ModalHeader
				title={`${t('default.update')} ${t('default.appointments').toLowerCase()}`}
				onClose={() => close()}
				icon="edit"
			/>
			<ModalContent>
				{isTrigger ? (
					<LabeledInput title={t('default.description')} classes="col-span2">
						<TextInput
							value={properties.Description}
							defaultValue={properties.Description ?? ''}
							onChange={(value: string) => {
								setProperties({ ...properties, Description: value });
							}}
						/>
					</LabeledInput>
				) : null}
				<div className="row">
					<div className="col-md-6">
						<LabeledInput
							title={t('default.add') + ' ' + t('default.campaigns')}
							topRight={
								<Link
									nostyle={true}
									className={classes.elem('create-campaign').b()}
									onClick={createCampaign}
								>
									{t('default.create')} {t('default.new').toLowerCase()}
								</Link>
							}
						>
							<ResourceIdSelect
								resource={ProjectResource}
								value={properties.Project ?? null}
								onChange={v => setProperties({ ...properties, Project: v })}
								anchor={anchorRef.current}
							/>
						</LabeledInput>
					</div>
					<div className="col-md-6">
						<LabeledInput title={t('default.appointmentType')}>
							<Select
								options={appointmentTypesOptions}
								value={
									appointmentTypesOptions.find(a => a.id === Number(properties.AppointmentType)) ??
									null
								}
								placeholder={`${t('default.select')} ${t('default.appointmentType').toLowerCase()}`}
								onChange={value => {
									setProperties({ ...properties, AppointmentType: value?.id ?? null });
								}}
								anchor={anchorRef.current}
								onClear={() => {
									setProperties({ ...properties, AppointmentType: null });
								}}
							/>
						</LabeledInput>
					</div>
				</div>
				<div className="row">
					<div className="col-md-6">
						<LabeledInput title={t('default.appointmentOutcome')}>
							<Select
								options={appointmentOutcomes}
								value={appointmentOutcomes.find(a => a.id === properties.Outcome) ?? null}
								placeholder={`${t('default.select')} ${t('default.appointmentOutcome').toLowerCase()}`}
								onChange={value => {
									setProperties({ ...properties, Outcome: value?.id ?? null });
								}}
								anchor={anchorRef.current}
								onClear={() => {
									setProperties({ ...properties, Outcome: null });
								}}
							/>
						</LabeledInput>
					</div>
				</div>
				{isTrigger ? (
					<>
						<div className="row">
							<div className="col-md-6">
								<LabeledInput title={t('default.startDate')}>
									<Select
										options={triggerDateGroups}
										optionHeaderType="disabled"
										value={triggerStartDate.selectItem}
										onChange={item => {
											setTriggerStartDate(prev => ({ ...prev, selectItem: item ?? null }));
										}}
										placeholder={`${t('default.select')} ${t('default.date').toLowerCase()}`}
										anchor={anchorRef.current}
									/>
								</LabeledInput>
							</div>
							<div className="col-md-3">
								<LabeledInput title={t('automationAction.DateOffset')}>
									<NumberInput
										value={triggerStartDate.offset}
										onChange={offset => {
											setTriggerStartDate(prev => ({ ...prev, offset: offset ?? 0 }));
										}}
									/>
								</LabeledInput>
							</div>
							<div className="col-md-3">
								<LabeledInput title={t('default.startTime')}>
									<TimeInput
										value={
											properties.StartTime ? moment(properties.StartTime).format('HH:mm') : null
										}
										onChange={time => {
											if (time.target.value) {
												onTimeChange(time.target.value, 'StartTime');
											}
										}}
									/>
								</LabeledInput>
							</div>
						</div>
						<div className="row">
							<div className="col-md-6">
								<LabeledInput title={t('default.endDate')}>
									<Select
										options={triggerDateGroups}
										optionHeaderType="disabled"
										value={triggerEndDate.selectItem}
										onChange={item => {
											setTriggerEndDate(prev => ({ ...prev, selectItem: item ?? null }));
										}}
										placeholder={`${t('default.select')} ${t('default.date').toLowerCase()}`}
										anchor={anchorRef.current}
									/>
								</LabeledInput>
							</div>
							<div className="col-md-3">
								<LabeledInput title={t('automationAction.DateOffset')}>
									<NumberInput
										value={triggerEndDate.offset}
										onChange={offset => {
											setTriggerEndDate(prev => ({ ...prev, offset: offset ?? 0 }));
										}}
									/>
								</LabeledInput>
							</div>
							<div className="col-md-3">
								<LabeledInput title={t('default.endTime')}>
									<TimeInput
										value={properties.EndTime ? moment(properties.EndTime).format('HH:mm') : null}
										onChange={time => {
											if (time.target.value) {
												onTimeChange(time.target.value, 'EndTime');
											}
										}}
									/>
								</LabeledInput>
							</div>
						</div>
						<div className="row">
							<div className="col-md-12">
								<LabeledInput title={t('default.notes')}>
									<Textarea
										value={properties.Notes ?? ''}
										onChange={e => {
											setProperties({ ...properties, Notes: e.target?.value ?? null });
										}}
									/>
								</LabeledInput>
							</div>
						</div>
					</>
				) : null}
				{customFields.length || availableFields.length ? (
					<legend>
						<span>{t('default.otherInfo')}</span>
					</legend>
				) : null}
				{customFields.length ? (
					<div className="row">
						{customFields.map((field, index) => (
							<div className="col-md-6" key={field.id}>
								<div className="form-group">
									<Flex justifyContent="space-between">
										<Text className={classes.elem('custom-title').b()} bold>
											{field.name}
											<Text
												size="lg"
												color="red"
												bold={true}
												style={{ display: field.obligatoryField ? 'inline' : 'none' }}
											>
												&bull;
											</Text>
										</Text>
										<ThirdButton
											className={classes.elem('remove-custom').b()}
											icon={'trash'}
											onClick={() => removeCustomField(field)}
											size="sm"
											color="blue"
										/>
									</Flex>
									{customDateTypes && field.datatype === 'Date' && (field as any).customDateSelect ? (
										<ReactTemplates.INPUTS.upSelect
											className={classes.elem('custom-field-input', 'form-control').b()}
											getId={customDateTypes.id}
											options={{ data: customDateTypes.data }}
											formatResult={customDateTypes.formatResult}
											formatSelection={customDateTypes.formatSelection}
											matcher={customDateTypes.matcher}
											onChange={value => handleCustomFieldValueChange(field, value.target.added)}
											defaultValue={field.value as any}
										/>
									) : (
										<ReactTemplates.INPUTS.customFieldInput
											field={field}
											name={field.name}
											className={classes.elem('custom-field-input').b()}
											multiple
											onChange={(value: string) => handleCustomFieldValueChange(field, value)}
											usenewdate
											useNewTime
											useNumberInput
											anchor={anchorRef.current}
										/>
									)}
								</div>
							</div>
						))}
					</div>
				) : null}

				{availableFields.length ? (
					<div className="row">
						<div className="col-md-6">
							<Select
								data-testid="Input_field"
								anchor={anchorRef.current}
								options={availableFields.map(field => ({
									id: field.id,
									title: field.name
								}))}
								value={null}
								onChange={handleCustomFieldChange}
								disabled={!availableFields.length}
								className={classes.elem('custom-field-select').b()}
								placeholder={t('default.add') + ' ' + t('default.field').toLowerCase()}
							/>
						</div>
					</div>
				) : null}
			</ModalContent>
			<ModalControls>
				{showErrorMessage('myForm') ? (
					<p className="text-danger pull-left">
						<b>{t('default.youHaveFormErrors')}</b>
					</p>
				) : null}
				<button type="submit" className="btn up-btn btn-green no-shadow" disabled={saving} onClick={save}>
					{t(saving ? 'default.saving' : 'default.save')} {t('admin.action').toLocaleLowerCase()}{' '}
					{saving ? <span className={`fa fa-refresh ${saving ? 'fa-spin' : ''}`}></span> : null}
				</button>
				<button className="btn up-btn btn-grey btn-link" onClick={() => close()}>
					{t('default.abort')}
				</button>
			</ModalControls>
		</Modal>
	);
};

export default MultiUpdateAppointment;

export const openMultiUpdateAppointmentModal = asyncModalAdapter({
	upModalName: 'updateAppointmentMulti',
	openModalName: 'MultiUpdateAppointmentModal',
	featureFlag: 'REACT_MULTI_UPDATE_APPOINTMENTS_MODAL'
});
