import { SelectItem } from '@upsales/components/Utils/selectHelpers';
import moment from 'moment';
import { Moment } from 'moment';

// NOTE, if StartDate is already set and EndDate is set to 01:00 or earlier, Start date will tick over to the day before.
// this will make the time adjusting logic not work as expected.
// This is a side effect from porting the angular behaviour one to one.
export const getNewStartEndTime = (
	time: string,
	timeIn: 'StartTime' | 'EndTime',
	oldStartTime?: Date,
	oldEndTime?: Date,
	defaultLength = 60
) => {
	const oldTimes = { StartTime: oldStartTime, EndTime: oldEndTime };

	let timeInMoment: Moment;
	if (oldTimes[timeIn]) {
		const [hours, minutes] = time.split(':');
		timeInMoment = moment(oldTimes[timeIn]).set({
			hour: Number(hours),
			minute: Number(minutes),
			second: 0
		});
	} else {
		timeInMoment = moment(time, 'HH:mm');
	}
	const timeInMomentDate = timeInMoment.clone().toDate();

	if (oldTimes.EndTime && timeInMomentDate > oldTimes.EndTime && timeIn === 'StartTime') {
		return {
			StartTime: timeInMomentDate,
			EndTime: timeInMoment.clone().add(defaultLength, 'minutes').toDate()
		};
	}

	if (oldTimes.EndTime && timeInMomentDate < oldTimes.EndTime && timeIn === 'StartTime') {
		return {
			StartTime: timeInMoment.clone().toDate(),
			EndTime: oldTimes.EndTime
		};
	}

	if (!!oldTimes.StartTime && oldTimes.StartTime > timeInMomentDate && timeIn === 'EndTime') {
		return {
			StartTime: timeInMoment.clone().subtract(defaultLength, 'minutes').toDate(),
			EndTime: timeInMomentDate
		};
	}

	if (!!oldTimes.StartTime && oldTimes.StartTime < timeInMomentDate && timeIn === 'EndTime') {
		return {
			StartTime: oldTimes.StartTime,
			EndTime: timeInMoment.clone().toDate()
		};
	}
	return {
		[timeIn]: timeInMomentDate
	};
};

export const parseDatePropToState = (dateString: string, triggerDateGroups: SelectItem[]) => {
	const [initialDate, initialOffset] = dateString.replaceAll('{', '').replaceAll('}', '').split(':') || [null, 0];
	return {
		selectItem:
			(triggerDateGroups.flatMap(g => g.children).find(item => item.id === initialDate) as SelectItem) || null,
		offset: Number(initialOffset)
	};
};
