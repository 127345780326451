import RequestBuilder from 'Resources/RequestBuilder';
import getAngularModule from 'App/babel/angularHelpers/getAngularModule';
import AppService from 'App/legacy/AppService';
import { MultiSelectContext } from 'App/components/MultiselectProvider/MultiselectProvider';

const restoreTickets = (
	rb: RequestBuilder,
	entity: string,
	multiSelectContext: MultiSelectContext,
	extraParams?: object
) => {
	const properties = [{ name: 'isArchived', value: false }];
	const MultiActions = getAngularModule('MultiActions');
	const customerId = AppService.getCustomerId();
	return MultiActions.customer(customerId).updateTicket(multiSelectContext.selected, properties, rb.build());
};

export default restoreTickets;
