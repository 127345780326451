import React from 'react';
import PropTypes from 'prop-types';
import { Block, Button, Icon, Card, Row, Column, CardHeader, Text, Tooltip, Loader } from '@upsales/components';
import TodoResource from 'Resources/Todo';
import ActivityResource from 'Resources/Activity';
import { makeCancelable } from 'App/babel/helpers/promise';
import logError from 'App/babel/helpers/logError';
import bemClass from '@upsales/components/Utils/bemClass';
import T from 'Components/Helpers/translate';
import OpportunityEditPhonecallRow from './Rows/OpportunityEditPhonecallRow';
import OpportunityEditTodoRow from './Rows/OpportunityEditTodoRow';
import OpportunityTodoRow from './Rows/OpportunityTodoRow';
import { replaceItem, removeItem } from 'App/babel/store/helpers/array';
import './OpportunityTodos.scss';
import { Fade } from 'App/components/animations';
import moment from 'moment';
import { openEditAppointment } from 'Components/Modals/Appointment/EditAppointment';

const EditContentMap = {
	todo: OpportunityEditTodoRow,
	phonecall: OpportunityEditPhonecallRow
};

class OpportunityTodos extends React.PureComponent {
	static propTypes = {
		opportunity: PropTypes.shape({
			id: PropTypes.number.isRequired,
			probability: PropTypes.number.isRequired,
			client: PropTypes.shape({
				id: PropTypes.number.isRequired
			}),
			project: PropTypes.shape({
				id: PropTypes.number.isRequired,
				name: PropTypes.string.isRequired
			})
		})
	};

	constructor(p) {
		super(p);
		this.todoId = Tools.AppService.getTodoTypes().TODO.id;
		this.phonecallId = Tools.AppService.getTodoTypes().PHONE_CALL.id;

		this.state = {
			todos: [],
			loading: true,
			error: false,
			createData: p.createData,
			saving: false
		};

		this.listeners = [
			Tools.$rootScope.$on('activity.added', this.getTodos),
			Tools.$rootScope.$on('activity.updated', this.getTodos),
			Tools.$rootScope.$on('activity.deleted', () => {
				setTimeout(this.getTodos, 1000);
			}),
			Tools.$rootScope.$on('appointment.added', this.getTodos),
			Tools.$rootScope.$on('appointment.updated', this.getTodos),
			Tools.$rootScope.$on('appointment.deleted', () => {
				setTimeout(this.getTodos, 1000);
			})
		];
	}
	// eslint-disable-next-line camelcase
	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.createData?.count !== this.props.createData?.count) {
			this.setState({ createData: nextProps.createData });
		}
	}

	setVisibleTodos = () => {
		this.setState({ loading: true });
		const getDateFilter = todo => {
			if (todo.type === 'appointment') {
				return moment(todo.date).isAfter(moment());
			} else {
				return !todo.closeDate;
			}
		};
		const { children } = this.state;
		//filter out closed parents where all children also are closed
		const parents = this.state.todos.filter(p => {
			return (
				getDateFilter(p) ||
				children.findIndex(
					c => (c.parentActivityId === p.id || c.parentAppointmentId === p.id) && getDateFilter(c)
				) !== -1
			);
		});

		this.setState({ todos: parents, loading: false });
	};

	getChildren = () => {
		const activityIds = this.state.todos.map(t => t.id);
		this.dataPromise = makeCancelable(
			Promise.all(
				activityIds.length
					? [
							TodoResource.find({ parentActivityId: activityIds }),
							TodoResource.find({ parentAppointmentId: activityIds })
					  ]
					: [Promise.resolve({ data: [] }), Promise.resolve({ data: [] })]
			)
		);
		this.dataPromise.promise
			.then(data => {
				//todo shouldnt be in children if its directly related to opportunity
				const allChildren = data.map(d => d.data).flat();

				this.setState(
					{ children: allChildren.filter(d => this.state.todos.findIndex(t => t.id === d.id) === -1) },
					this.setVisibleTodos
				);
			})
			.catch(err => {
				logError(err, 'error getting children todos');
			});
	};
	getTodos = () => {
		if (this.dataPromise) {
			this.dataPromise.cancel();
		}
		this.setState({ loading: true });
		this.dataPromise = makeCancelable(TodoResource.find({ opportunityId: this.props.opportunity.id }));

		this.dataPromise.promise
			.then(({ data }) => {
				this.setState({ todos: data }, this.getChildren);
			})
			.catch(err => {
				logError(err, 'Failed to get opportunity todos');
				this.setState({ loading: false, error: true });
			});
	};

	componentDidMount() {
		this.getTodos();
	}

	componentWillUnmount() {
		if (this.dataPromise) {
			this.dataPromise.cancel();
		}
		if (this.savePromise) {
			this.savePromise.cancel();
		}
		if (this.deletePromise) {
			this.deletePromise.cancel();
		}
		if (this.togglePromise) {
			this.togglePromise.cancel();
		}
		this.listeners.forEach(listener => listener());
	}

	openCreate = type => {
		const { opportunity } = this.props;
		if (type === 'appointment') {
			openEditAppointment({
				appointment: {
					client: opportunity.client,
					opportunity: { id: opportunity.id },
					sourceType: 'opportunity',
					sourceId: opportunity.id,
					project: opportunity.project
				}
			});
			return;
		}
		this.setState({
			createData: {
				type,
				description: ''
			}
		});
	};

	openEdit = createData => {
		if (createData.type === 'appointment') {
			openEditAppointment({ id: createData.id });
			return;
		}

		this.setState({
			createData
		});
	};

	save = () => {
		const { createData } = this.state;
		this.setState({ saving: true });
		let data;
		if (createData.id) {
			data = {
				id: createData.id,
				priority: createData.priority,
				date:
					createData.time && createData.date
						? createData.date
						: createData.date
						? moment(createData.date).format('YYYY-MM-DD')
						: null
			};
		} else {
			data = {
				opportunity: { id: this.props.opportunity.id },
				client: { id: this.props.opportunity.client.id }
			};
			if (createData.type !== 'todo') {
				data.project = this.props.opportunity.project;
			}
		}

		data.users = createData.users?.length ? createData.users : [{ id: Tools.AppService.getSelf().id }];

		data.description = createData.description;
		data.contacts = createData.contacts;

		let resource;
		if (createData.type === 'appointment') {
			resource = Tools.Appointment.customer(Tools.AppService.getCustomerId());
			// What appointmenttype??
		} else {
			resource = ActivityResource;
			if (!createData.id) {
				data.activityType = { id: createData.type === 'todo' ? this.todoId : this.phonecallId };
			}

			if (createData.priority) {
				data.priority = createData.priority;
			}
			if (createData.date) {
				data.date = createData.time ? createData.date : moment(createData.date).format('YYYY-MM-DD');
			}
		}

		this.savePromise = makeCancelable(resource.save(data));
		this.savePromise.promise
			.then(({ data }) => {
				let todos;
				// Push or replace
				if (createData.id) {
					const i = this.state.todos.findIndex(todo => todo.id === createData.id);
					todos = replaceItem(this.state.todos, i, createData);
				} else {
					todos = [
						...this.state.todos,
						{
							...createData,
							id: data.id,
							userEditable: data.userEditable,
							userRemovable: data.userRemovable
						}
					];
				}
				this.setState({
					saving: false,
					createData: null,
					todos
				});
			})
			.catch(e => {
				logError(e, 'Failed to save OpportunityTodo');
				this.setState({
					saving: false
				});
			});
	};

	deleteTodo = todo => {
		let resourceDelete;
		if (todo.type === 'appointment') {
			resourceDelete = Tools.Appointment.customer(Tools.AppService.getCustomerId()).delete(todo);
		} else {
			resourceDelete = ActivityResource.delete(todo.id);
		}
		this.deletePromise = makeCancelable(resourceDelete);
		this.deletePromise.promise
			.then(() => {
				// Remove it
				const i = this.state.todos.findIndex(t => t.id === todo.id);
				this.setState({
					todos: removeItem(this.state.todos, i)
				});
			})
			.catch(e => {
				logError(e, 'Failed to delete OpportunityTodo');
			});
	};

	toggleTodoDone = (id, done) => {
		const closeDate = done ? new Date() : null;
		const i = this.state.todos.findIndex(todo => todo.id === id);
		let childIndex;

		const isChildToggle = i === -1;
		if (isChildToggle) {
			childIndex = this.state.children.findIndex(todo => todo.id === id);
			this.setState({
				children: replaceItem(this.state.children, childIndex, {
					...this.state.children[childIndex],
					closeDate
				})
			});
		} else {
			this.setState({ todos: replaceItem(this.state.todos, i, { ...this.state.todos[i], closeDate }) });
		}
		this.togglePromise = makeCancelable(ActivityResource.save({ id, closeDate }));
		this.togglePromise.promise
			.then(() => {
				// Queue removal if parent closed and all its children closed
				setTimeout(this.setVisibleTodos, 3000);
			})
			.catch(e => {
				logError(e, 'Failed to delete OpportunityTodo');
			});
	};

	renderButton = (icon, title, type) => {
		const disabled = (this.state.createData && this.state.createData.type === type) || this.state.loading;
		return (
			<Button
				type={disabled ? undefined : 'lined'}
				color={disabled ? 'light-grey' : 'green'}
				disabled={disabled}
				onClick={() => this.openCreate(type)}
			>
				<Icon name={icon} space="mrs" />
				{title}
			</Button>
		);
	};

	renderEdit = classes => {
		const { createData, saving } = this.state;
		const { opportunity } = this.props;
		const EditContent = createData ? EditContentMap[createData.type] : null;
		if (!createData) {
			return null;
		}

		return (
			<Block
				id={'edit-row-' + createData.type}
				key="edit"
				space="prl"
				className={classes
					.elem('edit-row')
					.mod({ large: createData.type === 'phonecall' })
					.b()}
			>
				<form
					onSubmit={e => {
						e.preventDefault();
						e.stopPropagation();
						this.save();
					}}
				>
					<Row id="edit-row" className={classes.elem('edit-row-content').b()}>
						<EditContent
							id="edit-content"
							todo={createData}
							onChange={todo => {
								this.setState({ createData: todo });
							}}
							clientId={opportunity.client?.id}
							autofocus
							selectUser={this.props.selectUser}
						/>
						<Column align="right" className={classes.elem('edit-row-icons').b()}>
							<Fade speed="slow">
								<Tooltip title={T('default.save')} position="top" distance={24}>
									<Button
										id="submit-btn"
										size="sm"
										color="green"
										gradient={!!createData.description}
										disabled={!createData.description}
										loading={saving}
										submit={true}
									>
										<Icon name="check"></Icon>
									</Button>
								</Tooltip>
							</Fade>

							<Tooltip title={T('default.cancel')} position="top" distance={24}>
								<Button
									size="sm"
									type="link"
									color="grey"
									onClick={() => this.setState({ createData: null })}
								>
									<Icon name="times"></Icon>
								</Button>
							</Tooltip>
						</Column>
					</Row>
				</form>
			</Block>
		);
	};

	render() {
		const classes = new bemClass('OpportunityTodos');
		const { todos, createData, error } = this.state;
		const { opportunity } = this.props;
		const isClosed = opportunity.probability === 0 || opportunity.probability === 100;
		let cardTitle = isClosed ? T('todo.nextStepClosed') : T('todo.nextStepForward');
		if (todos.length) {
			cardTitle = T('todo.nextStep');
		}

		return (
			<Block className={classes.b()}>
				<Card
					className={classes.elem('card').b()}
					color={createData || todos.length ? 'white' : 'super-light-yellow'}
				>
					<CardHeader icon={todos.length ? undefined : 'bell-active'} title={cardTitle}>
						{this.renderButton('todo', T('default.todo'), 'todo')}
						{this.renderButton('phone', T('default.phonecall'), 'phonecall')}
						{this.renderButton('calendar', T('default.appointment'), 'appointment')}
					</CardHeader>

					<div>
						{this.state.loading || this.state.saving ? (
							<Loader />
						) : (
							todos.map(todo => {
								if (createData && createData.id === todo.id) {
									return this.renderEdit(classes);
								} else {
									return (
										<OpportunityTodoRow
											key={todo.id}
											todo={todo}
											onEdit={() => this.openEdit(todo)}
											onDelete={() => this.deleteTodo(todo)}
											onCheckToggle={this.toggleTodoDone}
											children={this.state.children?.filter(
												t =>
													(t.parentActivityId === todo.id ||
														t.parentAppointmentId === todo.id) &&
													todos.findIndex(parent => parent.id === t.id) === -1
											)}
										/>
									);
								}
							})
						)}
						{createData && !createData.id ? this.renderEdit(classes) : null}
						{error ? (
							<Block className={classes.elem('error').b()}>
								<Row align="center">
									<Text>{T('todo.failedToLoad')}</Text>
								</Row>
							</Block>
						) : null}
					</div>
				</Card>
			</Block>
		);
	}
}

export default OpportunityTodos;
