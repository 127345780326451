import React, { useEffect, useRef, useMemo } from 'react';
import useEditOrderContext from '../hooks/useEditOrderContext';

import type { EditOrderCustomField } from '../types';

type DefaultValue = EditOrderCustomField['value'];

type Props<T> = {
	disabled?: boolean;
	entity: string;
	field: Omit<EditOrderCustomField, 'value'> & { value?: T };
	inputDebounceSetting?: number | undefined;
	multiple?: boolean;
	name?: string;
	onChange: (fieldId: number, value: T) => void;
	placeholder?: string;
	tabIndex?: number;
	updateOnBlur?: boolean;
	useExternalDisabled?: boolean;
	useNewDate?: boolean;
	useNewTime?: boolean;
	useNumberInput?: boolean;
};

function CustomFieldInput<T = DefaultValue>({
	disabled = false,
	entity,
	field,
	inputDebounceSetting,
	multiple = false,
	name,
	onChange,
	placeholder,
	tabIndex,
	updateOnBlur,
	useExternalDisabled = false,
	useNewDate = false,
	useNewTime = false,
	useNumberInput = false
}: Props<T>) {
	const { registerFormComponent, unregisterFormComponent, onFormConponentChange } = useEditOrderContext();
	const lastCommittedValueRef = useRef<T | undefined>(field.value);

	function isValid(value: T | undefined) {
		if (!field.obligatoryField) {
			return true;
		} else if (Array.isArray(value) && value.length === 0) {
			return false;
		} else if (!value && value !== 0) {
			return false;
		} else {
			return true;
		}
	}

	useEffect(() => {
		if (name) {
			const valid = isValid(field.value);
			registerFormComponent(name, valid);
			return () => unregisterFormComponent(name);
		}
		// Will not add name to the dependency array because then I need something like renameControl
	}, []);

	useEffect(() => {
		if (field.value !== lastCommittedValueRef.current) {
			if (name) {
				const valid = isValid(field.value);
				onFormConponentChange(name, valid, false);
			}
		}
	}, [field.value]);

	// The Angular passed _.clone(field) to the input component, so asume that the component will mutate the field
	const fieldCopy = useMemo(() => ({ ...field }), [field]);

	function valueChange(value: T) {
		lastCommittedValueRef.current = value;

		onChange?.(field.id, value);

		if (name) {
			const valid = isValid(value);
			onFormConponentChange(name, valid, true);
		}
	}

	return (
		<ReactTemplates.customFieldInput
			disabled={disabled}
			entity={entity}
			field={fieldCopy}
			multiple={multiple}
			name={name}
			placeholder={placeholder ?? null}
			tabindex={tabIndex}
			inputDebounceSetting={inputDebounceSetting}
			updateOnBlur={updateOnBlur}
			useNewTime={useNewTime}
			useNumberInput={useNumberInput}
			usenewdate={useNewDate}
			valueChange={valueChange}
			useExternalDisabled={useExternalDisabled}
		/>
	);
}

export default CustomFieldInput;
