import $ from 'jquery';
import Avatar from 'Components/Avatar';
import React, { useCallback, useRef, useMemo } from 'react';
import Select from './Select';
import { BasicUserWithPermissions as User } from 'App/resources/Model/User';
import { useTranslation } from 'react-i18next';

type Props = {
	className: string;
	onChange: (user: User) => void;
	user: User;
	config: any;
	disabled?: boolean;
};

// There is no point to not add jquery in this file aslong as we use select2
const UserSelect = ({ className, user, config, disabled, onChange }: Props) => {
	const { t } = useTranslation();
	const ref = useRef<HTMLDivElement>(null);

	const onClick = useCallback(event => {
		event.preventDefault();
		event.stopPropagation();

		if (!disabled && ref.current) {
			const div = $(ref.current);
			const input = div.find('input');

			if (input) {
				div.show();
				input.select2('open');
			}
		}
	}, []);

	const eventListeners = useMemo(
		() => ({
			'select2-close': () => {
				if (ref.current) {
					const div = $(ref.current);
					div.hide();
				}
			}
		}),
		[]
	);

	return (
		<div className={className} onClick={onClick}>
			{/* Scuffed component, needs the key to remount if you change user... */}
			<Avatar key={user?.id} user={user} size={30} />
			<span className="title">{t('default.salesRep')}</span>
			<span className="user-name ellipsis">{user.name}</span>
			<b className="fa fa-caret-down pull-right"></b>
			{/* HIDDEN USER SELECT */}
			<div id="hidden-user-select-order" className="hidden-user-select" ref={ref}>
				<Select<User>
					className="form-control"
					disabled={disabled}
					eventListeners={eventListeners}
					id="user-select-order"
					name="user"
					onChange={onChange}
					options={config}
					placeholder={`${t('default.select')} ${t('default.user').toLowerCase()}`}
					required={true}
					value={user}
				/>
			</div>
		</div>
	);
};

export default UserSelect;
