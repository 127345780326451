import React from 'react';
import useDropdownToggle from '../hooks/useDropdownToggle';
import { useTranslation } from 'react-i18next';
// @ts-ignore
import classNames from 'classnames';

type Props = {
	showWinLossButtons: boolean;
	disabled: boolean;
	saveAndContinue: () => void;
};

const SaveButtonDropdown = ({ showWinLossButtons, disabled, saveAndContinue }: Props) => {
	const { t } = useTranslation();
	const { dropdownOpen, toggleDropdown } = useDropdownToggle();

	return (
		<div
			className={classNames('dropdown dropup btn-group dropdown-button', {
				open: dropdownOpen,
				'dropdown-button--white': showWinLossButtons,
				'dropdown-button--blue': !showWinLossButtons
			})}
		>
			<button
				type="button"
				className={classNames('btn up-btn btn-green no-shadow btn-primary-action dropdown-toggle', {
					'btn-link no-border': showWinLossButtons
				})}
				disabled={disabled}
				onClick={toggleDropdown}
			>
				<b className="fa fa-caret-up"></b>
			</button>
			<ul
				className={classNames('dropdown-menu limited', {
					'dropdown-menu--white': showWinLossButtons
				})}
			>
				<li>
					<a className="save-button" onClick={saveAndContinue}>
						{t('default.saveAndContinue')}
					</a>
				</li>
			</ul>
		</div>
	);
};

export default SaveButtonDropdown;
