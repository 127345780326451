import React from 'react';
import PlanPhonecallsDrawer, { Properties } from '../PlanPhonecallsDrawer';
import T from 'Components/Helpers/translate';
import { TagEntity } from 'App/helpers/actionHelpers';

type Props = {
	onSave: (properties: Properties) => Promise<any> | void;
	close: () => void;
	className?: string;
	properties?: Properties;
	triggerEntity?: TagEntity;
};

class PlanPhonecallsTriggerAction extends React.PureComponent<Props> {
	render() {
		const { close, onSave, triggerEntity, properties, className } = this.props;
		return (
			<PlanPhonecallsDrawer
				assignOneUser
				triggerEntity={triggerEntity}
				triggerAction
				onSave={p => {
					const modifiedProperties = p.filter(item => {
						if (item.name === 'Priority') {
							return item.value === 3;
						}
						return true;
					});

					return onSave(modifiedProperties);
				}}
				saveBtnLabel={T('todo.savePhonecall')}
				close={close}
				className={className}
				properties={properties}
			/>
		);
	}
}

export default PlanPhonecallsTriggerAction;
