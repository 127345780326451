import Contact from 'App/resources/Model/Contact';
import openAddToSegmentModal from 'Components/addToSegmentModal/addToSegmentModal';
import ContactResource from 'Resources/Contact';
import RequestBuilder from 'Resources/RequestBuilder';

export default async function addContactsToSegment(
	requestBuilder: RequestBuilder,
	allSelected: boolean,
	selectedIds: number[]
) {
	let selected;
	if (!allSelected) {
		selected = selectedIds;
	} else {
		requestBuilder.limit = 100;
		requestBuilder.fields = ['id'];
		const contacts = await ContactResource.find(requestBuilder.build());
		selected = contacts.data.map((contact: Contact) => contact.id);
	}

	return openAddToSegmentModal({ selected });
}
