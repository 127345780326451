import { asyncModalAdapter } from 'App/helpers/angularPortingHelpers';

export const openMultiUpdateContact = asyncModalAdapter({
	featureFlag: 'UPDATE_CONTACT_MULTI_REACT',
	openModalName: 'MultiUpdateContact',
	upModalName: 'UpdateContactMulti',
	rejectOnEvent: true
});

export const openUpdateContactAction = asyncModalAdapter({
	featureFlag: 'UPDATE_CONTACT_ACTION_REACT',
	openModalName: 'MultiUpdateContact',
	upModalName: 'UpdateContactAction',
	rejectOnEvent: true
});
