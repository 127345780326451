import buildTitle from '../AppointmentController/buildTitle';
import _ from 'lodash';
import type { Meta } from './useMeta';

const fixContactsOnLoad = (appointment: any) => {
	if (appointment.contacts?.length) {
		appointment.contacts = appointment.contacts.map((contact: any) => {
			contact.$id = `contact-${contact.id}`;
			return contact;
		});
	}

	return appointment;
};

const getInvitedContacts = (appointment: any) => {
	const invitedContacts: any = {};
	if (appointment.contacts?.length) {
		appointment.contacts.forEach((contact: any) => {
			if (contact.isInvited) {
				invitedContacts[contact.id] = 1;
			}
		});
	}
	return invitedContacts;
};

const getInvitedEmailAttendees = (appointment: any) => {
	const invitedEmailAttendees: any = {};
	if (appointment.emailAttendees?.length) {
		appointment.emailAttendees.forEach((attendee: any) => {
			if (attendee.isInvited) {
				invitedEmailAttendees[attendee.email] = 1;
			}
		});
	}
	return invitedEmailAttendees;
};

const setActiveUsers = (meta: any) => {
	const users = meta.users.data.map((user: any) => {
		user.$id = `user-${user.id}`;
		return user;
	});

	return users.filter((user: any) => user.active === 1);
};

function getInitialState(meta: Meta) {
	const utils = Tools.utils;
	const metadata = Tools.AppService.getMetadata();
	const customerId = Tools.AppService.getCustomerId();
	const hasOpportunities = Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.PIPELINE);
	const hasDocuments = Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.DOCUMENTS);
	const activityTypes = Tools.AppService.getActivityTypes('appointment', true);

	const uiElements = metadata.integrations?.uiElements?.editAppointment;
	const hasDocumentTemplates = !!meta.documentTemplates.data?.length;
	const documentTemplates = meta.documentTemplates.data ?? [];

	const appointment = fixContactsOnLoad(meta.appointment.data);
	const invitedContacts = getInvitedContacts(appointment);
	const invitedEmailAttendees = getInvitedEmailAttendees(appointment);

	if (!appointment.id) {
		appointment.userEditable = true;
	}

	const existingAppointment = {
		..._.cloneDeep(appointment),
		contacts: [],
		emailAttendees: [],
		users: []
	};

	const originalOutcome = appointment.outcome;
	if (meta.preSelectedOutcome) {
		appointment.outcome = meta.preSelectedOutcome;
	}

	let participants = appointment.users ?? [];
	if (!Array.isArray(participants)) {
		participants = [participants];
	}
	if (Array.isArray(appointment.contacts)) {
		participants = participants.concat(appointment.contacts);
	}

	// users
	if (appointment.users) {
		if (!Array.isArray(appointment.users)) {
			appointment.users = [appointment.users];
		}

		existingAppointment.users = [...appointment.users];
	}

	let contactOpened = null;

	// contacts
	if (appointment.contacts) {
		if (!Array.isArray(appointment.contacts)) {
			appointment.contacts = [appointment.contacts];
		}
		existingAppointment.contacts = [...appointment.contacts];

		if (appointment.contacts.length) {
			contactOpened = appointment.contacts[0].id;
		}
	}

	// emailAttendees
	if (appointment.emailAttendees) {
		if (!Array.isArray(appointment.emailAttendees)) {
			appointment.emailAttendees = [appointment.emailAttendees];
		}

		existingAppointment.emailAttendees = [...appointment.emailAttendees];
	}

	const initialState = {
		utils,
		customerId,
		isDirty: false,
		users: setActiveUsers(meta),
		preSelectedOutcome: meta.preSelectedOutcome,
		account: {},
		contacts: [],
		emailAttendees: [],
		appointment,
		existingAppointment,
		invitedContacts,
		invitedEmailAttendees,
		originalOutcome,
		participants: [],
		options: {
			contacts: [],
			companies: [],
			campaigns: [],
			opportunities: [],
			appointmentTypes: [],
			outcomes: [
				{ id: 'planned', description: Tools.$translate('appointment.outcome.planned') },
				{ id: 'completed', description: Tools.$translate('appointment.outcome.completed') },
				{ id: 'notCompleted', description: Tools.$translate('appointment.outcome.notCompleted') }
			]
		},
		time: appointment.date,
		endTime: appointment.endDate,
		dateAvailability: appointment.date,
		saving: false,
		savingType: '',
		editable: true,
		outcomeAnswered: false,
		hideOutcome: false,
		currentAppointmentLength: 60,
		participantSelect: { data: [] },
		calendarAppointments: [],
		title: buildTitle({ store: null, Tools }),
		hasDocumentTemplates,
		documentTemplates,
		isAvailable: {
			opportunity: hasOpportunities,
			document: hasDocuments
		},
		fieldErrors: {
			Description: false,
			Project: false,
			Notes: false,
			Startdate: false,
			Enddate: false,
			Type: false
		},
		loadingContacts: true,
		loadingUsers: true,
		promptRemoval: false,
		calendarIsOpenOnFirstLoad: appointment.id ? false : true,
		contactOpened,
		dateDirty: {
			isInvalid: false,
			storeKey: null
		},
		timeDirty: {
			isInvalid: false,
			storeKey: null
		},
		links: [],
		loadinglinks: true,
		requiredFields: metadata.requiredFields.Appointment,
		standardFields: metadata.standardFields.Appointment,
		activityTypes,
		uiElements
	};

	return initialState;
}

export default getInitialState;
