import _ from 'lodash';
import getAngularModule from 'App/babel/angularHelpers/getAngularModule';
import logError from 'App/babel/helpers/logError';
import moment from 'moment';
import { useEffect } from 'react';

import type Activity from 'App/resources/Model/Activity';
import type Appointment from 'App/resources/Model/Appointment';
import type { Actions } from './useEditOrderLogic';
import type { AppointmentEvent, ActivityEvent, Resolve, Reject, EditOrderDraftableStateStore } from '../types';

const useEventListeners = (store: EditOrderDraftableStateStore, actions: Actions, resolve: Resolve, reject: Reject) => {
	useEffect(() => {
		const EventService = getAngularModule('EventService');
		const $rootScope = getAngularModule('$rootScope');

		function activityAppointmentEvent(e: { name: string }, added: Activity | Appointment) {
			const state = store.getAutoDraftState();

			if (added.opportunity && added.opportunity.id === state.order.id) {
				const event = EventService.create.Activity(added) as AppointmentEvent | ActivityEvent;
				event.date = added.date;

				// If this was an update we find and remove the old event first
				let foundEvent;

				if (e.name === 'activity.updated' || e.name === 'appointment.updated') {
					// Look for open activity that matches
					foundEvent = _.find(state.activityUpcoming, { entityId: added.id });

					// Look some more
					if (foundEvent) {
						_.remove(state.activityUpcoming, foundEvent);
					} else {
						foundEvent = _.find(state.activityHistory, { entityId: added.id });
						if (foundEvent) {
							_.remove(state.activityHistory, foundEvent);
						}
					}
				}

				// Then we add it to the correct list
				if (!added.isAppointment && added.closeDate === null) {
					state.activityUpcoming.push(event);
				} else if (!added.isAppointment && added.closeDate !== null) {
					state.activityHistory.push(event);
				} else if (added.isAppointment && moment(added.date) >= moment()) {
					state.activityUpcoming.push(event);
				} else if (added.isAppointment && moment(added.date) < moment()) {
					state.activityHistory.push(event);
				}

				// If we save a sales process with title categories then we send the contact to the decision makers sidebar
				if (
					state.decisionMakersEnabled &&
					// @ts-expect-error For someone in the future to fix, dont have time
					state.salesCoachDecisionMaker?.hasTitleCategories &&
					added.contacts.length > 0 &&
					(added.date === null || moment(added.date) >= moment())
				) {
					let addedTitleCategories = added.contacts.map(contact => {
						return {
							contactId: contact.id,
							// @ts-expect-error For someone in the future to fix, dont have time
							tagId: contact.titleCategory || null,
							contact: { id: contact.id, name: contact.name }
						};
					});
					addedTitleCategories = addedTitleCategories.filter(
						addedTitleCategory =>
							addedTitleCategory.tagId &&
							!state.order.titleCategories.find(
								existingTitleCategory =>
									// @ts-expect-error For someone in the future to fix, dont have time
									existingTitleCategory.contactId === addedTitleCategory.contactId
							)
					);
					if (addedTitleCategories.length > 0) {
						const newTitleCategories = [...state.order.titleCategories, ...addedTitleCategories];
						// @ts-expect-error For someone in the future to fix, dont have time
						state.order.titleCategories = newTitleCategories; // Update order
						// @ts-expect-error For someone in the future to fix, dont have time
						state.newTitleCategories = newTitleCategories; // Update decision maker sidebar

						actions.saveDecisionMakers(true);
					}
				}
			}
		}

		const unregisterCloseOpportunity = $rootScope.$on('opportunity:close', () => {
			const state = store.getState();

			if (!state.OrderForm) {
				return;
			}

			if (!state.OrderForm.$dirty) {
				unregisterCloseOpportunity();

				setTimeout(() => {
					$rootScope.$broadcast('opportunity:close:done');
					resolve();
				}, 100);
			} else {
				actions.setFormPrisitine();

				actions
					.save(true, true, true)
					.then(function () {
						unregisterCloseOpportunity();

						$rootScope.$broadcast('opportunity:close:done');
						resolve();
					})
					.catch((err: unknown) => {
						if (err instanceof Error) {
							logError(err);
						}
					});
			}
		});

		const unsubscribeHandlers = [
			$rootScope.$on('file.uploaded', function (e, file) {
				const state = store.getAutoDraftState();

				if (file.entity === 'Order' && file.entityId === state.order.id) {
					state.files.push(file);
				}
			}),
			$rootScope.$on('file.deleted', function (e, deletedFile) {
				const state = store.getAutoDraftState();

				_.remove(state.files, { id: deletedFile.id });
			}),
			$rootScope.$on('activity.added', activityAppointmentEvent),
			$rootScope.$on('activity.updated', activityAppointmentEvent),
			$rootScope.$on('appointment.added', activityAppointmentEvent),
			$rootScope.$on('appointment.updated', activityAppointmentEvent),
			unregisterCloseOpportunity
		];

		return () => unsubscribeHandlers.forEach(unsubscribeHandler => unsubscribeHandler());
	}, []);
};

export default useEventListeners;
