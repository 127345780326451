import React, { useEffect, useState } from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { waitForAngular } from 'App/AngularApp';
import Page from 'App/pages/Page/Page';

type PortedRouteConfig = {
	angularState: string;
	featureFlag: string;
};

const portedRoutes = [
	{
		angularState: 'looker',
		featureFlag: 'PORTED_ROUTE_LOOKER'
	} as const,
	{
		angularState: 'mailCampaign',
		featureFlag: 'REACT_MAIL_CAMPAIGN'
	} as const,
	{
		angularState: 'mailCampaign.dashboard',
		featureFlag: 'REACT_MAIL_CAMPAIGN'
	} as const,
	{
		angularState: 'mailCampaign.visits',
		featureFlag: 'REACT_MAIL_CAMPAIGN'
	} as const,
	{
		angularState: 'mailCampaign.submits',
		featureFlag: 'REACT_MAIL_CAMPAIGN'
	} as const,
	{
		angularState: 'mailCampaign.recipients',
		featureFlag: 'REACT_MAIL_CAMPAIGN'
	} as const,
	{
		angularState: 'administration.domains',
		featureFlag: 'PORT_ADMIN_DOMAINS'
	} as const,
	{
		angularState: 'manageAccount',
		featureFlag: 'PORTED_ROUTE_MANAGE_ACCOUNT'
	} as const,
	{
		angularState: 'flow',
		featureFlag: 'PORT_EDIT_FLOW'
	} as const,
	{
		angularState: 'editFlow',
		featureFlag: 'PORT_FLOW_EDITOR'
	} as const,
	{
		angularState: 'editFlowStandalone',
		featureFlag: 'PORT_FLOW_EDITOR'
	} as const,
	{
		angularState: 'whoCalled',
		featureFlag: 'PORTED_ROUTE_WHO_CALLED'
	} as const,
	{
		angularState: 'matcher',
		featureFlag: 'REACT_ROUTE_MATCHER'
	} as const,
	{
		angularState: 'administration.automations',
		featureFlag: 'PORT_ADMIN_AUTOMATIONS'
	} as const,
	{
		angularState: 'administration.automation',
		featureFlag: 'PORT_ADMIN_AUTOMATION'
	} as const,
	{
		angularState: 'pricelists',
		featureFlag: 'PORTED_ROUTE_PRICELISTS'
	} as const,
	{
		angularState: 'landingpageEditor',
		featureFlag: 'PORTED_LANDINGPAGE_EDITOR_ROUTE_AND_MODAL'
	} as const,
	{
		angularState: 'administration.dashboard',
		featureFlag: 'REACT_ADMIN_DASHBOARD'
	} as const,
	{
		angularState: 'administration.loginStats',
		featureFlag: 'PORT_LOGIN_STATS'
	} as const,
	{
		angularState: 'administration.defaultMailSignature',
		featureFlag: 'REACT_ADMIN_EMAIL_SIGNATURES'
	} as const,
	{
		angularState: 'administration.pipelinegoals',
		featureFlag: 'PORTED_ROUTE_PIPELINEGOALS'
	} as const,
	{
		angularState: 'administration.journeyStatuses',
		featureFlag: 'PORT_ADMIN_JOURNEY_STATUSES'
	} as const,
	{
		angularState: 'administration.salesprocesses',
		featureFlag: 'PORT_ADMIN_SALES_PROCESSES'
	} as const,
	{
		angularState: 'administration.notificationCenter',
		featureFlag: 'REACT_ADMIN_NOTIFICATIONS'
	} as const,
	{
		angularState: 'administration.triggers',
		featureFlag: 'REACT_ADMIN_TRIGGERS'
	} as const,
	{
		angularState: 'administration.appointmentAvailability',
		featureFlag: 'PORTED_ROUTE_APPOINTMENTAVAILABILITY'
	} as const,
	{
		angularState: 'administration.journeyEditor',
		featureFlag: 'PORT_ADMIN_JOURNEY_EDITOR'
	} as const,
	{
		angularState: 'administration.mailsignature',
		featureFlag: 'REACT_ADMIN_MAILSIGNATURE'
	} as const,
	{
		angularState: 'administration.prospectingSignals',
		featureFlag: 'REACT_PROSPECTING_SIGNALS'
	} as const,
	{
		angularState: 'administration.integrations',
		featureFlag: 'REACT_ADMIN_INTEGRATIONS'
	} as const,
	{
		angularState: 'administration.salesboardCard',
		featureFlag: 'PORTED_ROUTE_SALESBOARDCARD'
	} as const,
	{
		angularState: 'administration.profile',
		featureFlag: 'REACT_ADMIN_PROFILE'
	} as const,
	{
		angularState: 'administration.anonymization',
		featureFlag: 'REACT_ADMIN_ANONYMIZATION'
	} as const,
	{
		angularState: 'administration.fields',
		featureFlag: 'PORTED_ROUTE_ADMIN_FIELDS'
	} as const,
	{
		angularState: 'administration.activityTypes',
		featureFlag: 'REACT_ADMIN_ACTIVITY_TYPES'
	} as const,
	{
		angularState: 'administration.completedfirstappointmentgoals',
		featureFlag: 'REACT_ADMIN_COMPLETED_FIRST_APPOINTMENT_GOALS'
	} as const,
	{
		angularState: 'administration.scripts',
		featureFlag: 'REACT_ADMIN_SCRIPTS'
	} as const,
	{
		angularState: 'administration.script',
		featureFlag: 'REACT_ADMIN_SCRIPTS'
	} as const,
	{
		angularState: 'administration.orderstages',
		featureFlag: 'PORTED_ROUTE_ADMIN_ORDERSTAGES'
	} as const,
	{
		angularState: 'administration.integration',
		featureFlag: 'REACT_ADMIN_CONFIGURE_INTEGRATION'
	} as const,
	{
		angularState: 'administration.looker',
		featureFlag: 'PORTED_ROUTE_ADMIN_LOOKER'
	} as const,
	{
		angularState: 'administration.products',
		featureFlag: 'REACT_ADMIN_PRODUCTS'
	} as const,
	{
		angularState: 'administration.agreement',
		featureFlag: 'REACT_ADMIN_AGREEMENT'
	} as const,
	{
		angularState: 'administration.apiKeys',
		featureFlag: 'REACT_ADMIN_API_KEYS'
	} as const,
	{
		angularState: 'administration.documentTemplates',
		featureFlag: 'REACT_ADMIN_DOCUMENT_TEMPLATES'
	} as const,
	{
		angularState: 'administration.sharedviews',
		featureFlag: 'REACT_ADMIN_SHARED_VIEWS'
	} as const,
	{
		angularState: 'administration.mailSettings',
		featureFlag: 'PORT_ADMIN_MAIL_SETTINGS'
	} as const,
	{
		angularState: 'administration.activeInvites',
		featureFlag: 'REACT_ADMIN_ACTIVE_INVITES'
	} as const,
	{
		angularState: 'administration.2fa',
		featureFlag: 'REACT_ADMIN_2FA'
	} as const,
	{
		angularState: 'administration.phonecallgoals',
		featureFlag: 'REACT_ADMIN_PHONECALL_GOALS'
	} as const,
	{
		angularState: 'administration.optIn',
		featureFlag: 'REACT_ADMIN_OPTIN'
	} as const,
	{
		angularState: 'administration.marketRejectlist',
		featureFlag: 'REACT_ADMIN_MARKET_REJECT_LIST'
	} as const,
	{
		angularState: 'administration.bookedfirstappointmentgoals',
		featureFlag: 'REACT_ADMIN_BOOKED_FIRST_APPOINTMENT'
	} as const,
	{
		angularState: 'administration.editIntegration',
		featureFlag: 'REACT_ADMIN_EDIT_INTEGRATION'
	} as const,
	{
		angularState: 'administration.visitSettings',
		featureFlag: 'PORT_ADMIN_VISIT_SETTINGS'
	} as const,
	{
		angularState: 'administration.usersAndRoles',
		featureFlag: 'REACT_ADMIN_USERS_AND_ROLES'
	} as const,
	{
		angularState: 'administration.optOut',
		featureFlag: 'REACT_ADMIN_OPTOUT'
	} as const,
	{
		angularState: 'administration.currencies',
		featureFlag: 'PORTED_ROUTE_ADMIN_CURRENCIES'
	} as const,
	{
		angularState: 'administration.userdefinedobjects',
		featureFlag: 'REACT_ADMIN_USER_DEFINED_OBJECTS'
	} as const,
	{
		angularState: 'administration.reminders',
		featureFlag: 'REACT_ADMIN_REMINDERS'
	} as const,
	{
		angularState: 'administration.brand',
		featureFlag: 'REACT_ADMIN_BRAND'
	} as const,
	{
		angularState: 'administration.oneoffquota',
		featureFlag: 'REACT_ADMIN_GENERIC_QUOTA'
	} as const,
	{
		angularState: 'administration.subscriptionquota',
		featureFlag: 'REACT_ADMIN_GENERIC_QUOTA'
	} as const,
	{
		angularState: 'administration.salesmarginquota',
		featureFlag: 'REACT_ADMIN_GENERIC_QUOTA'
	} as const,
	{
		angularState: 'administration.salesquota',
		featureFlag: 'PORTED_ROUTE_ADMIN_SALES_QUOTA'
	} as const,
	{
		angularState: 'administration.salesboards',
		featureFlag: 'REACT_ADMIN_SALESBOARDS'
	} as const,
	{
		angularState: 'administration.sharedAppointmentAvailabilitySettings',
		featureFlag: 'REACT_EASY_BOOKING_PRO_SETTINGS'
	} as const,
	{
		angularState: 'administration.activityquota',
		featureFlag: 'PORTED_ROUTE_ADMIN_ACTIVITY_QUOTA'
	} as const,
	{
		angularState: 'administration.accountsettings',
		featureFlag: 'REACT_ADMIN_ACCOUNT_SETTINGS'
	} as const,
	{
		angularState: 'administration.trigger',
		featureFlag: 'REACT_ADMIN_TRIGGERS'
	} as const,
	{
		angularState: 'administration.appointmentEditor',
		featureFlag: 'PORTED_ROUTE_ADMIN_APPOINTMENT_EDITOR'
	} as const,
	{
		angularState: 'administration.deleteLog',
		featureFlag: 'PORTED_ROUTE_DELETE_LOGS'
	} as const,
	{
		angularState: 'administration.salesboard',
		featureFlag: 'REACT_ADMIN_SALESBOARD'
	} as const,
	{
		angularState: 'administration.completedappointmentgoals',
		featureFlag: 'REACT_ADMIN_COMPLETED_APPOINTMENT_GOALS'
	} as const,
	{
		angularState: 'administration.dynamiclinks',
		featureFlag: 'PORTED_ROUTE_DYNAMIC_LINKS'
	}
] satisfies PortedRouteConfig[];

const getFeatureFlag = (angularState: string) => {
	const portedRoute = portedRoutes.find(route => route.angularState === angularState);
	return portedRoute?.featureFlag || '';
};

export const setupPortedAngularStates = () => {
	for (const route of portedRoutes) {
		if (Tools.FeatureHelper.hasSoftDeployAccess(route.featureFlag)) {
			// Haxxing to make angular state not do anything
			// Resolves cannot be disabled like this - need to be handled separately
			const oldState = Tools.$state.get(route.angularState) as any;

			delete oldState.controller;
			delete oldState.controllerProvider;
			delete oldState.templateProvider;
			delete oldState.templateUrl;

			oldState.template = '<div></div>';
		}
	}
};

export const checkPortedResolve = async <TReturn extends any>(angularState: string, resolveFn: () => TReturn) => {
	const flag = getFeatureFlag(angularState);
	await waitForAngular();
	return Tools.AppService.loadedPromise.then(() => {
		if (Tools.FeatureHelper.hasSoftDeployAccess(flag)) {
			return;
		}
		return resolveFn();
	});
};

export const reactRouteCompatibility = (
	Component: React.ComponentType<any>,
	{
		featureFlag,
		// probably needs to be more advanced idk it'll do for now
		wrapInPage = true,
		pageProps = {},
		onMount,
		getKey
	}: {
		featureFlag: string;
		wrapInPage?: boolean;
		pageProps?: { [key: string]: any };
		/**
		 * Use to include side effects or data from resolve functions
		 * @return {object} Object with props to pass to the component
		 */
		onMount?: ({ params, query }: { params: any; query: URLSearchParams }) => object | void;
		getKey?: ({ params, query }: { params: any; query: URLSearchParams }) => string;
	}
) => {
	return (props: any) => {
		const hasFeatureFlag = Tools.FeatureHelper.hasSoftDeployAccess(featureFlag);
		const [resolvedProps, setResolvedProps] = useState<any>(null);
		const [key, setKey] = useState<string | undefined>(undefined);

		const { params } = useRouteMatch();
		const { search } = useLocation();

		const query = new URLSearchParams(search);

		useEffect(() => {
			if (hasFeatureFlag && onMount) {
				const resolved = onMount({ params, query });
				setResolvedProps(resolved ?? {});
			}
			if (getKey) {
				setKey(getKey({ params, query }));
			}
		}, [params, search]);

		// No feature flag, no render.
		if (!hasFeatureFlag) {
			return null;
		}

		if (onMount && !resolvedProps) {
			return null;
		}

		return wrapInPage ? (
			<Page {...pageProps}>
				<Component key={key} {...props} {...resolvedProps} />
			</Page>
		) : (
			<Component key={key} {...props} {...resolvedProps} />
		);
	};
};
