import './EditCampaign.scss';
import { asyncModalAdapter } from 'App/helpers/angularPortingHelpers';
import React, { useEffect, useState, useRef, useMemo } from 'react';
import CustomFieldMapper from 'Services/CustomFieldMapper';
import UserRoleList from 'Components/Inputs/UserRoleList';
import T from 'Components/Helpers/translate';
import CallListResource from 'Resources/CallList';
import type Project from 'App/resources/Model/Project';
import { ModalProps } from 'App/components/Modals/Modals';
import logError from 'Helpers/logError';
import { DateInput, Checkbox, ModalHeader, ModalContent, ModalControls, Modal } from '@upsales/components';
import InlineAction from 'Components/Dialogs/InlineAction/InlineAction';
import NotesWithSignature from 'Components/Inputs/NotesWithSignature';
import _ from 'lodash';
import moment from 'moment';
import { CustomFieldWithValue } from 'App/resources/Model/CustomField';
import BemClass from '@upsales/components/Utils/bemClass';
import { makeCancelable } from 'Helpers/promise';
import { MappedCustomFields } from '../FormObserver/FormObserver';
import { useSelector } from 'App/components/hooks';

type MappedProject = PartialRequired<
	Omit<Project, 'custom'> & { custom: CustomFieldWithValue[]; $mappedCustom?: MappedCustomFields },
	'active' | 'users' | 'startDate' | 'name' | 'custom'
>;

type EditCampaignProps = {
	id?: number;
	isCallList?: boolean;
	campaign?: MappedProject;
	noRedirect?: boolean;
};

const EditCampaign = ({ close, className, ...props }: ModalProps & EditCampaignProps) => {
	const classes = new BemClass('EditCampaign', className);
	const hasSharedCallLists = Tools.FeatureHelper.hasSoftDeployAccess('SHARED_CALL_LISTS');
	const customerId = useSelector(({ App }) => App.customerId);
	const self = useSelector(({ App }) => App.self);

	const formRef = useRef<HTMLFormElement>(null);
	const campaignNameRef = useRef<HTMLInputElement>(null);
	const [campaign, setCampaign] = useState<MappedProject>({} as MappedProject);
	const [initialFrom, setInitialFrom] = useState<MappedProject>({} as MappedProject);
	const [saving, setSaving] = useState(false);
	const [initializingCampaign, setInitializingCampaign] = useState(true);
	const [showInlineAction, setShowInlineAction] = useState<'top' | 'bottom' | 'none'>('none');

	const activeState = useMemo(
		() => (initializingCampaign ? false : initialFrom.id ? campaign.active : true),
		[initializingCampaign]
	);

	const isCallList = props.isCallList || campaign.isCallList;
	const customFieldsLength = campaign.custom
		? _.filter(campaign.custom, f => {
				return f.$hasAccess && (f.editable || f.visible);
		  }).length
		: 0;
	const editable = campaign.id ? campaign.userEditable : true;

	const save = () => {
		if (!editable) {
			return;
		}
		const campaignCopy = structuredClone(campaign);
		if (isCallList) {
			campaignCopy.isCallList = true;
		}
		const saveFunc = isCallList
			? (data: MappedProject) => CallListResource.save(data)
			: Tools.Campaign.customer(customerId).save;

		setSaving(true);
		setCampaign(campaignCopy);
		return Tools.ScriptService.project
			.save(campaignCopy)
			.then(() => {
				return saveFunc(campaignCopy);
			})
			.then(response => {
				if (!campaignCopy.id && !props.noRedirect) {
					// Go to account
					setTimeout(() => {
						const id = response.data.id;
						if (isCallList) {
							// eslint-disable-next-line promise/catch-or-return
							Tools.$state.go('campaign.activities', { id }).then(() => close(response.data));
							return;
						}
						// eslint-disable-next-line promise/catch-or-return
						Tools.$state.go('campaign.dashboard', { id }).then(() => close(response.data));
					}, 2000);
				} else {
					close(response.data);
				}
			})
			.catch(e => {
				setSaving(false);
				logError(e, 'Could not save campaign');
				Tools.NotificationService.addNotification({
					style: Tools.NotificationService.style.ERROR,
					icon: 'times',
					title: 'default.error',
					body: 'saveError.campaign'
				});
			});
	};

	const startDateChange = (startDate: Date) => {
		const campaignCopy = { ...campaign };
		campaignCopy.startDate = startDate;

		var momentStart = moment(campaignCopy.startDate);
		if (campaignCopy.endDate && momentStart.isAfter(campaignCopy.endDate)) {
			campaignCopy.endDate = momentStart.toDate();
		}
		setCampaign(campaignCopy);
	};

	const endDateChange = (endDate: Date) => {
		const campaignCopy = { ...campaign };
		campaignCopy.endDate = endDate;
		if (endDate) {
			const momentEnd = moment(endDate);
			if (momentEnd.isBefore(campaign.startDate)) {
				campaignCopy.startDate = momentEnd.toDate();
			}
		}
		setCampaign(campaignCopy);
	};

	const closeModal = (position: 'top' | 'bottom') => {
		const campaignCopy = { ...campaign };
		const initialFromCopy = { ...initialFrom };
		if (campaignCopy.id) {
			initialFromCopy.active = !!activeState;
			campaignCopy.active = !!activeState;
			setCampaign(campaignCopy);
			setInitialFrom(initialFromCopy);
		}
		const ignores = ['regDate', 'modDate', '$mappedCustom'];
		const omitKeys = (obj: Record<string, any>) =>
			Object.keys(structuredClone(obj)).reduce<Record<string, any>>((memo, key) => {
				if (!ignores.includes(key)) {
					memo[key] = obj[key];
				}
				return memo;
			}, {});
		if (
			!_.isEqual(
				{
					...omitKeys(initialFromCopy),
					startDate: initialFromCopy.startDate
						? moment(initialFromCopy.startDate).format('YYYY-MM-DD')
						: null,
					endDate: initialFromCopy.endDate ? moment(initialFromCopy.endDate).format('YYYY-MM-DD') : null,
					custom: initialFromCopy.custom?.map(c => ({ value: c.value ?? null }))
				},
				{
					...omitKeys(campaignCopy),
					startDate: campaignCopy.startDate ? moment(campaignCopy.startDate).format('YYYY-MM-DD') : null,
					endDate: campaignCopy.endDate ? moment(campaignCopy.endDate).format('YYYY-MM-DD') : null,
					custom: campaignCopy.custom?.map?.(c => ({ value: c.value ?? null }))
				}
			)
		) {
			setShowInlineAction(position);
		} else {
			setShowInlineAction('none');
			close();
		}
	};

	const rejectChanges = () => {
		close();
	};

	const closeInlineAction = () => {
		setShowInlineAction('none');
	};

	const customFieldValueChange = (field: CustomFieldWithValue, value: CustomFieldWithValue['value']) => {
		setCampaign(campaign => {
			const customFields = structuredClone(campaign.custom);
			const item = customFields.find(f => f.id === field.id);
			if (item) {
				item.value = value;
			}
			return { ...campaign, custom: customFields };
		});
	};

	const init = (campaignData: MappedProject) => {
		const campaignObj: MappedProject = { ...(campaignData || {}) };

		if (campaignObj.$mappedCustom) {
			campaignObj.custom = Object.values(campaignObj.$mappedCustom) as unknown as CustomFieldWithValue[];
		}
		const edit = !!campaignData.id;

		// if edit
		if (edit) {
			// init dates
			if (campaignObj.startDate) {
				campaignObj.startDate = new Date(campaignObj.startDate);
			}
			if (campaignObj.endDate) {
				campaignObj.endDate = new Date(campaignObj.endDate);
			}
		} else {
			// if new
			campaignObj.startDate = new Date();
			campaignObj.active = true;
			campaignObj.users = [self as unknown as Project['users'][0]];
		}

		if (!campaignObj.custom) {
			campaignObj.custom = [];
		}

		// same as startDateChange but we mutate the obj here and update state at end of init
		const momentStart = moment(campaignObj.startDate);
		if (campaignObj.endDate && momentStart.isAfter(campaignObj.endDate)) {
			campaignObj.endDate = momentStart.toDate();
		}

		// set custom field default values
		campaignObj.custom.forEach(custom => {
			if (!edit) {
				if (custom.datatype === 'Select') {
					custom.value = (
						custom.dropdownDefault ? custom.dropdownDefault : ''
					) as CustomFieldWithValue['value'];
				} else if (custom['default'] !== null && custom['default'] !== undefined && custom['default'] !== '') {
					custom.value = custom['default'] as CustomFieldWithValue['value'];
				}
			}
			if (custom.datatype === 'Integer') {
				custom.value = parseInt(custom.value as unknown as string) as unknown as CustomFieldWithValue['value'];
			}
		});
		setCampaign(campaignObj);
		setInitialFrom(_.cloneDeep(campaignObj));
		setInitializingCampaign(false);
	};

	useEffect(() => {
		const customFields = Tools.AppService.getCustomFields('project');
		let campaignPromise: Promise<{ data: any }> = Promise.resolve({
			data: {}
		});
		if (props.id) {
			campaignPromise = Tools.Campaign.customer(customerId).get(props.id);
		} else if (props.campaign) {
			campaignPromise = Promise.resolve({ data: structuredClone(props.campaign) });
		}
		const cancelable = makeCancelable(campaignPromise);
		cancelable.promise
			.then(response => {
				response.data.custom = CustomFieldMapper.parse(customFields, response.data.custom);
				init(response.data);
			})
			.catch(e => {
				logError(e, 'Error loading campaign');
				Tools.NotificationService.addNotification({
					title: 'default.error',
					body: 'openError.campaign',
					style: 'error',
					icon: 'times'
				});
				close();
			});
		return () => {
			cancelable.cancel();
		};
	}, []);

	useEffect(() => {
		if (!initializingCampaign) {
			setTimeout(() => {
				campaignNameRef.current?.focus();
			}, 200);
		}
	}, [initializingCampaign]);

	const inlineActionComponent = (
		<InlineAction
			toggleInlineAction={closeInlineAction}
			onReject={rejectChanges}
			saveDisabled={saving}
			cancelDisabled={saving}
			onConfirm={() => null} // will be handled automatically by onSubmit after submit button is pressed
			formId="CampaignForm"
			showTop={showInlineAction === 'top'}
		/>
	);

	if (initializingCampaign) {
		return null;
	}

	return (
		<Modal className={classes.b()}>
			<ModalHeader
				title={
					<h2>
						<i className={`fa fa-edit ${!campaign.id ? 'fa-add' : ''}`}></i>{' '}
						{T(campaign.id ? 'default.edit' : 'default.add')}{' '}
						{T(isCallList ? 'default.callList' : 'default.campaign').toLowerCase()}
					</h2>
				}
				onClose={() => {
					if (saving) {
						return;
					}
					closeModal('top');
				}}
			/>
			{showInlineAction === 'top' ? inlineActionComponent : null}
			<ModalContent>
				<form
					ref={formRef}
					autoComplete="off"
					name="CampaignForm"
					id="CampaignForm"
					onSubmit={e => {
						e.preventDefault();
						save();
					}}
					scroll-in=".up-modal-content"
				>
					<div className="row">
						<div className="col-md-6">
							{/* description/name */}
							<div className="form-group">
								<label htmlFor="campaignName">
									{T('default.description')}
									<b className="text-danger">*</b>
								</label>
								<input
									ref={campaignNameRef}
									id="campaignName"
									title={T('default.description')}
									autoComplete="campaign-name"
									type="text"
									className="form-control up-input"
									name="campaignName"
									onChange={e => setCampaign({ ...campaign, name: e?.target.value })}
									maxLength={50}
									value={campaign.name}
									required
									disabled={saving}
								/>
							</div>
						</div>
						{/* quota */}
						{!isCallList ? (
							<div className="col-md-6">
								<div className="form-group">
									<label htmlFor="campaignQuota">{T('campaign.quota')}</label>
									<input
										type="number"
										autoComplete="campaign-quota"
										id="campaignQuota"
										name="campaignQuota"
										title={T('campaign.quota')}
										onChange={e =>
											setCampaign({
												...campaign,
												quota: e?.target.value ? parseInt(e.target.value) : 0
											})
										}
										className="form-control"
										value={campaign.quota}
										disabled={saving}
									/>
								</div>
							</div>
						) : null}
					</div>

					{!isCallList ? (
						<div className="row">
							{/* startDate */}
							<div className="col-md-6">
								<div>
									<div className="date-icon">
										<label htmlFor="startDate">
											{T('default.startDate')}
											<b className="text-danger">*</b>
										</label>
										<DateInput
											id="startDate"
											value={new Date(campaign.startDate)}
											disabled={saving}
											onChange={e => {
												if (e.target.value) {
													startDateChange(e.target.value);
												}
											}}
											closeOnSelect
										/>
									</div>
								</div>
							</div>

							{/* endDate */}
							<div className="col-md-6">
								<label>{T('default.endDate')}</label>
								<div className="date-icon">
									<DateInput
										value={campaign.endDate ? new Date(campaign.endDate) : null}
										onFocus={e => {
											if (e.target.value) {
												endDateChange(e.target.value as unknown as Date);
											}
										}}
										min={moment(campaign.startDate).startOf('day').toDate()}
										disabled={saving}
										onChange={e => {
											if (e.target.value) {
												endDateChange(e.target.value);
											}
										}}
										closeOnSelect
									/>
								</div>
							</div>
						</div>
					) : null}
					<div className="form-group" />
					{/* campaign active */}
					<div className="row form-group">
						{!isCallList ? (
							<div className="col-md-12">
								<div>
									<label htmlFor="active_checkbox">{T('default.active')}</label>
									<Checkbox
										id="active_checkbox"
										size="sm"
										className="checkbox-fix"
										checked={campaign.active}
										onChange={(checked: boolean) => setCampaign({ ...campaign, active: checked })}
										disabled={saving}
									/>
								</div>
							</div>
						) : null}
					</div>

					{/* notes */}
					{!isCallList ? (
						<fieldset>
							<legend>
								<span>{T('default.notes')}</span>
							</legend>
							<div className="row">
								<div className="col-md-12">
									<NotesWithSignature
										onChange={notes => setCampaign({ ...campaign, notes })}
										value={campaign.notes || ''}
									/>
								</div>
							</div>
						</fieldset>
					) : null}

					{/* users */}
					{!isCallList || hasSharedCallLists ? (
						<fieldset>
							<legend>
								<span>{T('default.users')}</span>
							</legend>
							<UserRoleList
								usersOnly
								assignOneUser={false}
								asIds={false}
								selectedUsersRoles={campaign.users}
								setSelectedUsersRoles={selectedUsers =>
									setCampaign({ ...campaign, users: selectedUsers })
								}
								toggleUserRole={toggledUser => {
									const users = campaign.users.some(user => user.id === toggledUser.id)
										? campaign.users.filter(user => user.id !== toggledUser.id)
										: campaign.users.concat(toggledUser);
									setCampaign({ ...campaign, users: users });
								}}
							/>
						</fieldset>
					) : null}

					{/* custom fields */}
					{customFieldsLength ? (
						<fieldset>
							<legend>
								<span>{T('default.otherInfo')}</span>
							</legend>
							<div className="row">
								{[...campaign.custom]
									.sort((a, b) => (a.sortId && b.sortId ? a.sortId - b.sortId : -1))
									.map(field =>
										field.$hasAccess && field.editable ? (
											<div
												className={`col-md-6 ${classes.elem('customFieldContainer').b()}`}
												key={`field_${field.id}`}
											>
												<div className="form-group">
													<label htmlFor="exampleInputFile">
														{field.name}{' '}
														{field.obligatoryField ? (
															<b className="text-danger">*</b>
														) : null}
													</label>
													<div className="input">
														<ReactTemplates.INPUTS.customFieldInput
															className="CustomFieldInput"
															field={field}
															name={field.id + '_' + field.name}
															onChange={value => customFieldValueChange(field, value)}
															// without type prop input type is hidden and html form wont auto validate these fields so added this
															type={
																field.datatype === 'Users' ||
																field.datatype === 'User' ||
																field.datatype === 'Select'
																	? 'text'
																	: undefined
															}
														/>
													</div>
												</div>
											</div>
										) : null
									)}
							</div>
						</fieldset>
					) : null}
				</form>
			</ModalContent>
			<ModalControls>
				{editable ? (
					<button
						form="CampaignForm"
						type="submit"
						className="btn up-btn btn-bright-blue no-shadow main-action btn-primary-action"
						disabled={saving}
					>
						{T(saving ? 'default.saving' : 'default.save')}
						{saving ? <span className={`fa fa-refresh ${saving ? 'fa-spin' : ''}`}></span> : null}
					</button>
				) : null}
				<button type="button" className="btn up-btn btn-grey btn-link" onClick={() => closeModal('bottom')}>
					{T('default.abort')}
				</button>
				{showInlineAction === 'bottom' ? inlineActionComponent : null}
			</ModalControls>
		</Modal>
	);
};

export const openEditCampaignModal = asyncModalAdapter({
	featureFlag: 'EDIT_CAMPAIGN_REACT',
	upModalName: 'editCampaign',
	openModalName: 'EditCampaign'
});

export default EditCampaign;
