/*
 * ListViewTable
 *
 * Highly customizable table for rendering entity tables.
 * Renders the table for ListView, Advanced search and other generic entity tables across the app.
 * This shiuld not do any data fetching or saving of anything. It should only render the table and trigger callbacks when something happens.
 *
 */
import { Button, Icon, Table, TableHeader, TableHeaderColumn, TableRow, Tooltip } from '@upsales/components';
import React, { Fragment } from 'react';
import { ListViewMultiSelectHeaderColumn, renderDefaultNoData } from '../ListViewRenderHelpers';
import T from 'Components/Helpers/translate';
import { Attr, DisplayType } from 'App/babel/attributes/Attribute';
import BemClass from '@upsales/components/Utils/bemClass';
import {
	getCFFieldIdFromFilterName,
	getCategoryTypeIdFromFilterName,
	isCustom,
	isCustomCategory
} from 'App/helpers/filterHelper';
import './ListViewTable.scss';
import { Sort } from 'Resources/RequestBuilder';
import { getCustomFieldsByType, listTypeToCategoryEntity, listTypeToStandardFieldsEntity } from '../ListView';
import { MultiAction } from 'App/components/ListViewActions';
import useSelector from 'App/components/hooks/useSelector/useSelector';
import CustomField from 'App/resources/Model/CustomField';
import Category, { CategoryType } from 'App/resources/Model/Category';
import { filterActiveAttributes } from 'App/babel/attributes/attributeHelpers';
import { StandardFieldConfig } from 'App/resources/AllIWant';
import { MultiSelectContext, useMultiselect } from 'App/components/MultiselectProvider/MultiselectProvider';
import { openEditColumns } from 'App/components/EditColumnsModal/EditColumnsModal';
import { GROWTH_ATTRIBUTES } from 'App/babel/attributes/Client';
import { useSoftDeployAccess } from 'App/components/hooks';
import { RootState } from 'Store/index';

type ProvidedBase<T> = {
	columns: string[];
	customFields: CustomField[];
	categoryTypes: CategoryType[];
	categories: Category[];
	renderToolsColumn: boolean;
	attributes: Record<string, Attr>;
	multiselect: MultiSelectContext;
	getTableData: () => ReadonlyArray<T>;
	setTableData: (data: ReadonlyArray<T>) => void;
	itemIdentifier: keyof T;
	tableData: ReadonlyArray<T>;
};

export type ListViewTableProvided<T, Provided = {}> = Provided & ProvidedBase<T>;

const getSelectableColumns = (
	attr: Record<string, Attr>,
	standardFields: Record<string, StandardFieldConfig>,
	categoryTypes: CategoryType[]
) => {
	const attributes = filterActiveAttributes(attr, standardFields);

	const selectables = Object.keys(attributes).reduce((res, key) => {
		const a = attributes?.[key];
		if (a?.selectableColumn) {
			res.push({ field: a.field, key, title: a.title, locked: a.locked });
		}
		return res;
	}, [] as { field: string; key: string; title: string; locked?: boolean }[]);
	if (categoryTypes) {
		for (const categoryType of categoryTypes) {
			selectables.push({
				field: 'Category_' + categoryType.id,
				key: 'Category_' + categoryType.id,
				title: categoryType.name
			});
		}
	}

	return selectables;
};

type Props<T, Provided = {}> = {
	// Indicates that the table is loading
	loading?: boolean;
	// The list of data to render
	data: ReadonlyArray<T>;
	// Renders when the table is empty
	renderNoData?: () => React.ReactNode;
	// Render each table row
	renderTableRow: (item: T, provided: ListViewTableProvided<T, Provided>) => React.ReactElement<typeof TableRow>;
	// The item key that is used as the primary identifier (will be id for 99% of cases)
	itemIdentifier?: keyof T;
	// Format the message shown when there is no data
	formatNoData?: () => string;
	// Whenever the column selector should open
	onEditColumns?: () => void;
	// Whenever the built in column editor changes the columns
	onColumnsChange?: (columns: string[]) => void;
	// Weather or not to render the tools column
	renderToolsColumn?: boolean;
	// The columns to render
	columns: string[];
	// The Attributes of the entity to render
	attributes: Record<string, Attr>;
	// The total number of items in the list
	total: number;
	// The table sorting
	sorting?: Sort[];
	// The table sorting callback
	onSortChange?: (sort: { field: string; asc: boolean }) => void;
	// This is the object that is passed down to the render functions
	provided?: Provided;
	// listType is used to get the correct customfields
	listType?: string;
	// Whenever the data should update this is triggered
	onDataChange?: (data: ReadonlyArray<T>) => void;
	// Run a multi action
	runMultiAction?: (action: MultiAction) => void;
	// Whether or not the table can sort custom fields
	canSortCustomFields?: boolean;
};

function ListViewTable<T, Provided = {}>({
	loading = false,
	data,
	total,
	formatNoData = () => T('default.noResults'),
	renderNoData = renderDefaultNoData(formatNoData),
	renderTableRow,
	itemIdentifier = 'id' as keyof T,
	renderToolsColumn = false,
	onEditColumns,
	onColumnsChange,
	columns,
	attributes,
	sorting = [],
	onSortChange = () => {},
	runMultiAction = () => {},
	provided = {} as Provided,
	listType,
	onDataChange = () => {},
	canSortCustomFields = true
}: Props<T, Provided>) {
	const classes = new BemClass('ListViewTable');
	const multiselect = useMultiselect();
	const hasGrowthOnTheFly = useSoftDeployAccess('GROWTH_ON_THE_FLY');
	const { hasFullReportAccess } = useSelector(({ App }: RootState) => App);

	const {
		customFields,
		categoryTypes,
		categories,
		standardFields = {}
	} = useSelector(({ App }) => {
		const catType = listType
			? listTypeToCategoryEntity[listType as keyof typeof listTypeToCategoryEntity] || listType
			: null;

		let standardFieldsEntity;
		if (listType) {
			const entity =
				listTypeToStandardFieldsEntity[listType as keyof typeof listTypeToStandardFieldsEntity] ?? listType;
			standardFieldsEntity = entity[0].toUpperCase() + entity.substring(1);
		}
		const customFields = getCustomFieldsByType(listType, App.customFields);

		return {
			customFields: customFields,
			categoryTypes: catType && App.categoryTypes[catType] ? App.categoryTypes[catType] : [],
			categories: catType && App.categories[catType] ? App.categories[catType] : [],
			standardFields: standardFieldsEntity ? App.metadata?.standardFields[standardFieldsEntity] : {}
		};
	});

	const multiSelectAll = (justPage = false) => {
		if (justPage) {
			return multiselect.setSelectedIds(data.map(item => (item as any)[itemIdentifier] as number));
		} else {
			return multiselect.selectAllResult();
		}
	};

	const columnElements = columns.map(col => {
		const attr = attributes?.[col];

		if (hasGrowthOnTheFly && !hasFullReportAccess) {
			if (GROWTH_ATTRIBUTES.includes(col)) {
				attr.sortable = false;
			}
		}

		let title: string = '';
		let sort = attr?.sortable || undefined;

		const numericTypes = [
			DisplayType.Number,
			DisplayType.Currency,
			DisplayType.Percent,
			DisplayType.Date,
			DisplayType.Phone,
			DisplayType.DateTime
		];

		let sortType =
			attr?.type === 'Number' || (attr?.displayType && numericTypes.includes(attr.displayType))
				? 'numeric'
				: 'alpha';

		if (isCustom(col)) {
			const fieldId = getCFFieldIdFromFilterName(col);
			const cf = customFields.find(cf => {
				if (col.indexOf('CustomAgreement_') === -1) {
					return cf.id === fieldId && cf.nameType !== 'Agreement';
				}
				return cf.id === fieldId && cf.nameType === 'Agreement';
			});
			if (cf) {
				switch (cf.datatype) {
					case 'Date':
						col += '.valueDate';
						sortType = 'numeric';
						break;
					case 'Time':
						col += '.valueTime';
						sortType = 'numeric';
						break;
					case 'Boolean':
						col += '.valueBoolean';
						break;
					case 'Integer':
					case 'Calculation':
						col += '.valueInteger';
						sortType = 'numeric';
						break;
					case 'Currency':
					case 'Percent':
					case 'Discount':
						col += '.valueDouble';
						sortType = 'numeric';
						break;
				}
			}

			if (canSortCustomFields) {
				sort = col;
			}
			title = cf?.name || '';
		} else if (isCustomCategory(col)) {
			const categoryTypeId = getCategoryTypeIdFromFilterName(col);
			const categoryType = categoryTypes?.find(categoryType => categoryType.id === categoryTypeId);
			// Sort is not implemented in backend for Category_ like it is for Custom_
			sort = undefined;
			title = categoryType?.name || '';
		} else {
			title = attr ? T(attr.title) : col;
		}
		if (attr?.icon) {
			return (
				<TableHeaderColumn
					key={col}
					sorting={sorting[0]?.attribute}
					asc={sorting[0]?.ascending}
					column={{
						title: (
							<Tooltip title={T(title)}>
								<Icon name={attr?.icon || ''} />
							</Tooltip>
						),
						sort,
						sortType
					}}
					onSortChange={onSortChange}
				/>
			);
		}

		return (
			<TableHeaderColumn
				key={col}
				sorting={sorting[0]?.attribute}
				asc={sorting[0]?.ascending}
				column={{
					title: attr?.tooltip ? (
						<Tooltip title={T(attr.tooltip)}>
							<span>{title}</span>
						</Tooltip>
					) : (
						title
					),
					sort,
					sortType,
					elevioId: attr?.elevioId || undefined
				}}
				onSortChange={onSortChange}
			/>
		);
	});
	if (renderToolsColumn) {
		// Use built in column selector or external. If external is allways the same wes can remove them later.
		const doEditColumns =
			onEditColumns ||
			(() => {
				// eslint-disable-next-line promise/catch-or-return
				openEditColumns({
					attributes,
					columns,
					selectables: getSelectableColumns(attributes, standardFields, categoryTypes),
					customfields: customFields,
					tableType: listType || ''
				}).then((columns: string[]) => {
					onColumnsChange?.(columns);
				});
			});

		const exportAction = multiselect.multiActions.find(ma => ma.id === 'export');
		const exportAllResult = async () => {
			if (exportAction && !exportAction.disabled) {
				// Select all result, then wait for it to set... This is kindof a hack but it works
				await multiSelectAll(data.length === total);
				runMultiAction(exportAction);
			}
		};

		const hasExport = !loading && !!total && !!exportAction;
		const canExport = !exportAction?.disabled;
		columnElements?.push(
			<TableHeaderColumn key="tools" className={classes.elem('tools-header').b()}>
				{hasExport ? (
					<Tooltip title={T(canExport ? 'default.export' : 'default.noExportRights')} position="left">
						{/* TODO, call action instead */}
						<Button disabled={!canExport} type="link" size="xs" onClick={() => exportAllResult()}>
							<Icon name="file-excel-o" />
						</Button>
					</Tooltip>
				) : null}
				<Tooltip title={T('filters.editColumns')} position="left">
					<Button onClick={() => doEditColumns()} size="xs" type="link">
						<Icon name="cog" />
					</Button>
				</Tooltip>
			</TableHeaderColumn>
		);
	}

	if (multiselect.active) {
		columnElements?.unshift(
			<ListViewMultiSelectHeaderColumn
				key="multi"
				// TODO: should be passed as easier to use if you have custom table header
				className={classes.elem('checkbox-header-col').b()}
				selectAllPage={() => multiSelectAll(true)}
			/>
		);
	}

	return (
		<Fragment>
			<Table loading={loading}>
				{data.length ? <TableHeader color="white">{columnElements}</TableHeader> : renderNoData?.()}
				{data
					? data.map(item => {
							return renderTableRow(item, {
								...provided,
								tableData: data,
								getTableData: () => data,
								setTableData: onDataChange,
								renderToolsColumn,
								categoryTypes,
								categories,
								itemIdentifier,
								customFields,
								columns,
								attributes,
								multiselect
							});
					  })
					: null}
			</Table>
		</Fragment>
	);
}

export default ListViewTable;
