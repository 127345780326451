import React from 'react';
import { Tooltip, Link } from '@upsales/components';

export default opts => {
	if (!opts.Tools.$translate) {
		return [];
	}

	let title = [<span key="modal-header-new1">{opts.Tools.$translate('default.new2')}</span>];
	if (
		(opts && opts.store && opts.store.appointment && opts.store.appointment.id) ||
		(opts && opts.appointment && opts.appointment.id)
	) {
		title = [<span key="modal-header-edit1">{opts.Tools.$translate('default.edit')}</span>];
	}

	title.push(
		<span key="modal-header-main-appointment">
			{' ' + opts.Tools.$translate('default.appointment').toLowerCase() + ' '}
		</span>
	);

	if (opts.store && opts.store.appointment && opts.store.appointment.client) {
		title.push(
			<span key="modal-header-at-store">{opts.Tools.$translate('default.with').toLowerCase() + ' '}</span>
		);
		title.push(
			<Tooltip
				key="modal-header-go-to-client-store"
				title={Tools.$translate('default.goToAccount')}
				position="left"
			>
				<Link
					onClick={evt => {
						evt.preventDefault();
						opts.actions.link(
							Tools.$state.href('account.dashboard', {
								id: opts.appointment.client.id,
								customerId: Tools.AppService.getCustomerId()
							})
						);
					}}
				>
					{opts.store.appointment.client.name || opts.Tools.$translate('default.noName')}
				</Link>
			</Tooltip>
		);
	} else if (opts && opts.appointment && opts.appointment.client) {
		title.push(<span key="modal-header-at">{opts.Tools.$translate('default.with').toLowerCase() + ' '}</span>);
		title.push(
			<Tooltip key="modal-header-go-to-client" title={Tools.$translate('default.goToAccount')} position="left">
				<Link
					title={Tools.$translate('default.goToAccount')}
					onClick={evt => {
						evt.preventDefault();
						opts.actions.link(
							Tools.$state.href('account.dashboard', {
								id: opts.appointment.client.id,
								customerId: Tools.AppService.getCustomerId()
							})
						);
					}}
				>
					{opts.appointment.client.name || opts.Tools.$translate('default.noName')}
				</Link>
			</Tooltip>
		);
	}

	return title;
};
