import React, { useState, useEffect } from 'react';
import BemClass from '@upsales/components/Utils/bemClass';
import Editor from '@draft-js-plugins/editor';
import Comment from 'App/resources/Model/Comment';
import { Column, Icon, Row, Tooltip, Link, IconName } from '@upsales/components';
import './NoteRow.scss';
import { LEAD_TYPES, OUTCOME_TYPES } from 'App/babel/enum/activityOutcome';
import { outcomeTypes as appoOutcomeTypes } from 'Components/Modals/Appointment/AppointmentOutcomes/Helpers';
import CommentResource from 'Resources/Comment';
import InlineConfirm from 'Components/Dialogs/InlineConfirm';
import T from 'Components/Helpers/translate';
import FakeToolTip from 'Components/Tooltip/FakeTooltip';
import logError from 'App/babel/helpers/logError';
import MentionsInput from 'Components/Mentions/MentionsInput';
import { useDebounce } from 'Components/Helpers/Debounce';

const isDraftEditor = (ref: HTMLTextAreaElement | Editor): ref is Editor => {
	return (ref as Editor).editor !== undefined;
};

const getIconProps = (comment?: Partial<Comment>) => {
	let icon: IconName | undefined,
		color = '';
	switch (comment?.outcomeType) {
		case OUTCOME_TYPES.ANSWER:
			icon = 'phone-success';
			color = 'bright-green';
			break;
		case LEAD_TYPES.NO_LEAD:
		case OUTCOME_TYPES.NO_ANSWER:
			icon = 'phone-fail';
			color = 'red';
			break;
		case OUTCOME_TYPES.POSTPONE:
			icon = 'calendar-arrow-right-o';
			color = 'grey-10';
			break;
		case appoOutcomeTypes.NOTCOMPLETED:
			icon = 'calendar-times-o';
			color = 'red';
			break;
		case appoOutcomeTypes.COMPLETED:
			icon = 'calendar-check-o';
			color = 'medium-green';
			break;
		default:
			icon = 'comment';
			color = 'grey-8';
	}
	return { icon, color };
};
interface NoteRowProps {
	text?: string;
	iconProps?: { icon: IconName; color: string };
	hideEdit?: boolean;
	comment?: Partial<Comment> & Pick<Comment, 'id'>;
	hideIcon?: boolean;
	onDelete?: () => void;
}
const NoteRow = ({
	comment,
	text = comment?.description,
	iconProps = getIconProps(comment),
	hideEdit = false,
	hideIcon = false,
	onDelete
}: NoteRowProps) => {
	const classes = new BemClass('NoteRow');
	const [editing, setEditing] = useState(false);
	const [editVal, setEditVal] = useState(text || '');
	const [deleted, setDeleted] = useState(false);
	const [showExpand, setShowExpand] = useState(false);
	const [fullsize, setFullsize] = useState(false);
	const textareaRef = React.useRef<HTMLTextAreaElement | Editor | null>(null);
	const toolTipRef = React.useRef<HTMLDivElement | null>(null);

	const updateOverflow = useDebounce(() => {
		const element =
			textareaRef.current && isDraftEditor(textareaRef.current)
				? textareaRef.current.editor?.editorContainer
				: textareaRef.current;
		if (element) {
			const shouldShow = (element.scrollHeight ?? 0) > 106;
			if (shouldShow !== showExpand) {
				setShowExpand(shouldShow);
			}
			if (toolTipRef?.current && element) {
				toolTipRef.current.style.top = `${element.scrollHeight + 8}px`;
			}
		}
	}, 100);

	const deleteCommentFunc = async (commentId: number) => {
		CommentResource.delete(commentId)
			.then(() => {
				setDeleted(true);
				onDelete?.();
			})
			.catch(e => logError(e, 'Could not delete comment'));
	};

	useEffect(() => {
		window.addEventListener('resize', updateOverflow, true);
		return () => {
			window.removeEventListener('resize', updateOverflow);
		};
	}, []);

	const editAndFocus = () => {
		if (comment?.userEditable) {
			setFullsize(true);
			setEditing(true);
			setTimeout(() => {
				if (textareaRef.current) {
					if (isDraftEditor(textareaRef.current)) {
						textareaRef.current.focus();
					} else {
						textareaRef.current.focus();
						textareaRef.current.selectionStart = editVal.length;
						textareaRef.current.selectionEnd = editVal.length;
					}
				}
			}, 300);
		}
	};

	if (deleted) {
		return null;
	}
	const createCommentIfItExists = () => {
		if (comment) {
			CommentResource.save({ id: comment.id, description: editVal.replace(/\n\n/g, '\n') });
			updateOverflow();
		}
		setEditing(false);
	};
	const renderInput = () => {
		const editorClassName = classes
			.elem('textarea')
			.mod({ notFullsize: !fullsize && !editing })
			.b();
		return (
			<MentionsInput
				className={editorClassName}
				value={editVal}
				onChange={setEditVal}
				onEnter={createCommentIfItExists}
				editorProps={{ readOnly: !editing }}
				editorRef={r => {
					textareaRef.current = r;
					if (r) {
						updateOverflow();
					}
				}}
			/>
		);
	};

	return (
		<Row className={classes.b()} align="center">
			{!hideIcon ? (
				<Column className={classes.elem('icon-col').b()} fixedWidth={30}>
					<Icon name={iconProps.icon} color={iconProps.color || 'inherit'} space="mrm" />
				</Column>
			) : null}
			<Column className={classes.elem('text-col').b()}>
				{renderInput()}
				{!editing && showExpand ? (
					<div
						className={classes.elem('showMore').b()}
						onClick={e => {
							e.stopPropagation();
							setFullsize(!fullsize);
						}}
					>
						<Link>{fullsize ? T('default.showLess') : T('default.showMore')}</Link>
						<Icon color="bright-blue" name={fullsize ? 'chevron-up' : 'chevron-down'} space="pts pls" />
					</div>
				) : null}
				{editing && editVal.length > 0 ? (
					<FakeToolTip
						tooltipRef={r => {
							if (!toolTipRef.current) {
								toolTipRef.current = r;
							}
						}}
						title={T('comment.tooltip')}
					/>
				) : null}
			</Column>
			<Column fixedWidth={40} className={classes.elem('edit-col').b()}>
				{hideEdit || editing || !comment ? null : (
					<React.Fragment>
						<Tooltip title={comment.userEditable ? T('default.clickToEdit') : T('noEditRights.comment')}>
							<Icon key={editVal} name="edit" color="grey-8" onClick={editAndFocus} />
						</Tooltip>

						<InlineConfirm
							entity="default.comment"
							tooltip={comment.userRemovable ? T('comment.deleteComment') : T('noDeleteRights.comment')}
							tooltipPosition="top"
							tooltipDistance={15}
							show={comment.userRemovable}
							onConfirm={async () => {
								await deleteCommentFunc(comment.id);
							}}
							keepTabPosition
						>
							<Icon name="trash" color="grey-8" />
						</InlineConfirm>
					</React.Fragment>
				)}
			</Column>
		</Row>
	);
};
export default NoteRow;
