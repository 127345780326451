import React, { useMemo } from 'react';
import {
	StaticSelect,
	StaticMultiSelect,
	type StaticSelectConsumerProps,
	type StaticMultiSelectConsumerProps
} from '../StaticSelect';
import { BasicUserWithPermissions } from 'App/resources/Model/User';
import { getActiveUsers } from 'Store/selectors/AppSelectors';
import { useTranslation } from 'Components/Helpers/translate';

export const useUserOptions = () => {
	const users = getActiveUsers();
	return useMemo(() => {
		return users.map(user => ({
			...user,
			title: user.name
		}));
	}, []);
};

const useSharedProps = () => {
	const { t } = useTranslation();
	const options = useUserOptions();
	const placeholder = `${t('default.select')} ${t('default.user').toLowerCase()}`;
	return { placeholder, options };
};

export const StaticUserSelect = (props: StaticSelectConsumerProps<PartialPick<BasicUserWithPermissions, 'id'>>) => {
	const sharedProps = useSharedProps();
	return <StaticSelect {...sharedProps} {...props} />;
};

export const StaticUserMultiSelect = (
	props: StaticMultiSelectConsumerProps<PartialPick<BasicUserWithPermissions, 'id'>>
) => {
	const sharedProps = useSharedProps();
	return <StaticMultiSelect {...sharedProps} {...props} />;
};
