import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { Row, Link, Text, Block, Column } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';

import T from 'Components/Helpers/translate';
import { Project } from 'App/resources/Project';
import Activity from 'App/resources/Model/Activity';
import PlanPhonecallsDrawerCallListSelect from 'Components/PlanPhonecallsDrawer/PlanPhonecallsDrawerCallListSelect';
import User from 'App/resources/Model/User';
import { getViews } from 'App/babel/store/actions/TodoActions';
import { openEditCampaignModal } from 'App/components/EditCampaign/EditCampaign';

const mapDispatchToProps = {
	getViews
};

export type PhonecallChange = Partial<{
	user: User;
	callListId: number | null;
	project: Project | null;
}>;

type Props = {
	phonecall: Activity;
	savePhonecall: (change: PhonecallChange) => Promise<void>;
	getViews: () => void;
	anchor: HTMLDivElement | null;
	disabled?: boolean;
};

const PhonecallRelationFields = ({ phonecall, savePhonecall, getViews, anchor, disabled = false }: Props) => {
	const classes = new BemClass('PhonecallRelationFields');

	const onCallListChange = async (callListId: number | null) => {
		await savePhonecall({ callListId });
		getViews();
	};

	const onCampaignChange = (campaign: Project | null) => {
		savePhonecall({ project: campaign });
	};

	const createCampaign = () => {
		// eslint-disable-next-line promise/catch-or-return
		openEditCampaignModal({ customerId: Tools.AppService.getCustomerId(), noRedirect: true }).then(
			(campaign: Project) => onCampaignChange(campaign)
		);
	};

	const standardFields = useMemo(() => Tools.AppService.getMetadata().standardFields.Activity, []);

	return (
		<Block space="mtl" className={classes.b()}>
			<PlanPhonecallsDrawerCallListSelect
				alwaysShowInput
				onChange={onCallListChange}
				value={phonecall.callList?.id}
				key="PhonecallRelationFields-calllist-select"
				title={T('phonecall.relationFields.callList')}
				disabled={disabled}
				anchor={anchor}
			/>

			{standardFields.Project.active ? (
				<>
					<Row>
						<Column>
							<Text bold={true}>
								{T('phonecall.relationFields.campaign')}{' '}
								{standardFields.Project.required ? <b className="text-danger">{'*'}</b> : null}
							</Text>
						</Column>
						{disabled ? null : (
							<Column align="right">
								<Link onClick={createCampaign}>{T('phonecall.relationFields.createCampaign')}</Link>
							</Column>
						)}
					</Row>
					<ReactTemplates.INPUTS.upCampaigns
						disabled={disabled}
						required={standardFields.Project.required}
						name="campaigns"
						value={phonecall.project}
						placeholder={T('select_campaign')}
						onChange={onCampaignChange}
						key="PhonecallRelationFields-campaigns-select"
					/>
				</>
			) : null}
		</Block>
	);
};

export const detached = PhonecallRelationFields;
export default connect(() => ({}), mapDispatchToProps)(PhonecallRelationFields);
