const { useState, useCallback, useEffect } = require('react');

// TODO: Replace this with Ottos when his code is in the parent branch
const useDropdownToggle = () => {
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const closeDropdownEvent = useCallback((event: { preventDefault: Function }) => {
		event.preventDefault();
		setDropdownOpen(false);
		document.removeEventListener('mouseup', closeDropdownEvent);
	}, []);
	const closeDropdown = function () {
		closeDropdownEvent({ preventDefault: () => {} });
	};
	const openDropdown = function () {
		setDropdownOpen(true);
		document.addEventListener('mouseup', closeDropdownEvent);
	};
	const toggleDropdown = dropdownOpen ? closeDropdown : openDropdown;
	useEffect(
		() => () => {
			document.removeEventListener('mouseup', closeDropdownEvent);
		},
		[]
	);
	return { dropdownOpen, toggleDropdown };
};

export default useDropdownToggle;
