import React, { useRef, useImperativeHandle, useEffect } from 'react';
import useInputController, { options } from '../hooks/useInputController';

import type { InputHTMLAttributes } from 'react';
import type { ModifiedOnChange } from '../types';
import type { Options } from '../hooks/useInputController';

type Props = Omit<InputHTMLAttributes<HTMLInputElement>, 'type' | 'value' | 'onChange'> & {
	decimals?: number;
	type: 'number';
	value: number | string;
	onChange: ModifiedOnChange<HTMLInputElement, string | number>;
	controllerOptions?: Partial<Options<number | string, number>>;
};

const PurchaseCostInput = React.forwardRef<HTMLInputElement, Props>(
	({ decimals = 2, onKeyPress: _onKeyPress, onBlur: _onBlur, controllerOptions, ...props }, forwardRef) => {
		const ref = useRef<HTMLInputElement>(null);

		useImperativeHandle(forwardRef, () => ref.current!);

		// I DONT FUCKING KNOW WHY, but event.target.checkValidity() is true in the React onBlur event handler and false in the native event handler. Hint: It should be false!!!!!!!!!!!!!!!
		useEffect(() => {
			function listerner(event: Event) {
				// Yea yea not the same, but we will only use stuff that exists on both
				_onBlur?.(event as unknown as React.FocusEvent<HTMLInputElement>);
			}

			if (_onBlur) {
				ref.current?.addEventListener('blur', listerner);
				return () => ref.current?.removeEventListener('blur', listerner);
			}
		}, [_onBlur]);

		const instanceOptions = controllerOptions
			? { ...options.number, ...controllerOptions }
			: (options.number as Options<number | string, number>);

		instanceOptions.formatters.push(function PurchaseCostInputFormatter(value) {
			return parseFloat(parseFloat(value).toFixed(decimals));
		});

		function onKeyPress(event: React.KeyboardEvent<HTMLInputElement>) {
			if (event.keyCode === 32) {
				event.preventDefault();
			}

			_onKeyPress?.(event);
		}

		const controlledProps = useInputController<HTMLInputElement, number | string, number>(props, instanceOptions);

		return <input {...controlledProps} onKeyPress={onKeyPress} ref={ref} />;
	}
);

export default PurchaseCostInput;
