import { cloneDeep, get, set } from 'lodash';
import { useMemo, useRef, useState } from 'react';

/** @deprecated Only used for helping us port Angular routes in a timely manner.
 *
 * The first version of this hook I made used useState to store the state,
 * but that do not work with the following scenario:
 *     Initial state of the store is { 'key', 'someValue' }
 *     Then in some function you have the following flow:
 *     store.get('key'); // 'someValue'
 *     store.set('key', 'someNewValue');
 *     store.get('key'); // you expect 'someNewValue' but would get 'someValue' as react state updates is asynchronous.
 *
 * And as I do not know how DataStore is used in the different routes we are porting I opted to use useRef instead.
 * It also made it easier to wrap the thing in useMemo and still have get/getStore has access to the current state.
 */
function useDataStore<InitialState extends object>(
	initialState: InitialState | (() => InitialState),
	noCloneFields: { [key: string]: boolean } = {}
) {
	const stateRef = useRef<InitialState>(typeof initialState === 'function' ? initialState() : initialState);
	const [, queueRender] = useState(0);

	// setState is stable, so we can use it inside the memoized object.
	const store = useMemo(
		() => ({
			setStore: (partialNewState: Partial<InitialState>, callback?: Function) => {
				if (callback) {
					console.warn('callback is not supported in useDataStore');
				}

				stateRef.current = { ...stateRef.current, ...partialNewState };
				queueRender(value => value + 1);
			},
			set: (path: string, value: any, callback?: Function) => {
				if (callback) {
					console.warn('callback is not supported in useDataStore');
				}

				// This could mutate the oldState object as lodash get/set allows for setting deep values, but that was the old behaviour so I will not change it.
				const shallowCopy = { ...stateRef.current };
				set(shallowCopy, path, value);
				stateRef.current = shallowCopy;
				queueRender(value => value + 1);
			},
			getStore: () => {
				console.warn('This is very slow on large objects.');
				return cloneDeep(stateRef.current);
			},
			get: (path: string) => {
				const value = get(stateRef.current, path);
				return noCloneFields.hasOwnProperty(path) ? value : cloneDeep(value);
			},
			pluck: (...args: string[]) => {
				return args.reduce<{ [key: string]: any }>((result, path: string) => {
					result[path] = store.get(path);
					return result;
				}, {});
			},
			push: (path: string, data: any, callback?: Function) => {
				if (callback) {
					console.warn('callback is not supported in useDataStore');
				}

				const array = store.get(path);

				if (!Array.isArray(array)) {
					throw new Error('Item in store at path: "' + path + '" is not an array.');
				}

				array.push(data);
				store.set(path, array);
			},
			splice: (path: string, index: number, count: number, add: any, callback?: Function) => {
				if (callback) {
					console.warn('callback is not supported in useDataStore');
				}

				const array = store.get(path);

				if (!Array.isArray(array)) {
					throw new Error('Item in store at path: "' + path + '" is not an array.');
				}

				// I assume noone wants to add undefined.
				if (add !== undefined) {
					array.splice(index, count, add);
				} else {
					array.splice(index, count);
				}

				store.set(path, array);
			}
		}),
		[]
	);

	return { store, state: stateRef.current };
}

export type DataStore<InitialState extends object> = ReturnType<typeof useDataStore<InitialState>>;

export default useDataStore;
