import Resource from './Resource';
import Attributes from 'Attributes/StandardIntegrationAttributes';
import AllIWantDataCache from 'App/helpers/allIWantDataCache';

class StandardIntegrations extends Resource {
	constructor() {
		super('standardIntegration', Attributes);
	}

	async save(data) {
		const result = await super.save(data);
		AllIWantDataCache.clearData();
		return result;
	}

	log(customerId) {
		var instance = {};
		instance.get = async id => {
			return this._getRequest('', {
				_url: `/${customerId}/integrationLog/`,
				params: { id: id }
			}).then(res => {
				return res.data;
			});
		};
		return instance;
	}

	new() {
		return {
			name: '',
			supportEmail: '',
			appColor: '#ffffff',
			standardIntegrationInit: [],
			standardIntegrationTag: [],
			configJson: JSON.stringify(
				{
					fields: { account: [], user: [] },
					uiElements: {},
					requirements: []
				},
				null,
				4
			),
			category: []
		};
	}
}

const resource = new StandardIntegrations();
window.Tools.StandardIntegrations = resource;
export default resource;
