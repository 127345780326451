import ActivityExport from 'App/enum/exportFields/activity';
import AppointmentExport from 'App/enum/exportFields/appointment';
import OrderExport from 'App/enum/exportFields/order';
import ClientExport from 'App/enum/exportFields/client';
import GrowthExport from 'App/enum/exportFields/growth';
import ContactExport from 'App/enum/exportFields/contact';
import AgreementExport from 'App/enum/exportFields/agreement';
import FormSubmitExport from 'App/enum/exportFields/formSubmit';
import MailExport from 'App/enum/exportFields/mail';
import ProductExport from 'App/enum/exportFields/product';
import ProjectExport from 'App/enum/exportFields/project';
import LeadExport from 'App/enum/exportFields/lead';
import VisitExport from 'App/enum/exportFields/visit';
import type { ExportTemplate } from 'App/upsales/common/components/react/templateHeaderSelector/templateHeaderSelector';
import type { AllIWantData } from 'App/resources/AllIWant';
import type { CategoryType } from 'App/resources/Model/Category';
import CustomField from 'App/resources/Model/CustomField';
import { ExportField } from 'App/pages/ListUserDefinedObjects/multiActions';

export const getNewTemplate = (id: number, name: string, columns: ExportTemplate['columns']) => {
	return {
		id,
		name,
		columns
	};
};

export const getReducedColumnInfo = (columns: ExportTemplate['columns'] = []) => {
	return columns.map((c, index) => ({
		key: c.key,
		entity: c.entity,
		innerEntity: c.innerEntity,
		sorting: index
	}));
};

export type EntityToCustomFields = {
	Activity?: AllIWantData['customFields']['activity'];
	Appointment?: AllIWantData['customFields']['appointment'];
	Contact?: AllIWantData['customFields']['contact'];
	Client?: AllIWantData['customFields']['account'];
	Order?: {
		Order: AllIWantData['customFields']['order'];
		OrderRow: AllIWantData['customFields']['orderrow'];
	};
	Agreement?: {
		Agreement: AllIWantData['customFields']['agreement'];
		Order: AllIWantData['customFields']['order'];
		OrderRow: AllIWantData['customFields']['orderrow'];
	};
	Product?: AllIWantData['customFields']['product'];
	Project?: AllIWantData['customFields']['project'];
} & {
	[key: string]:
		| CustomField[]
		| {
				[key: string]: CustomField[];
		  };
};

export function getCustomfieldsByType(type: string, customFieldMap: AllIWantData['customFields']) {
	const obj: EntityToCustomFields = {};

	switch (type) {
		case 'appointment':
		case 'activity':
			if (type === 'activity') {
				obj.Activity = customFieldMap['activity'];
			} else {
				obj.Appointment = customFieldMap['appointment'];
			}
			obj.Contact = customFieldMap['contact'];
			obj.Client = customFieldMap['account'];
			break;
		case 'opportunity':
		case 'order':
			obj.Order = {
				Order: customFieldMap['order'],
				OrderRow: customFieldMap['orderrow']
			};
			obj.Client = customFieldMap['account'];
			obj.Contact = customFieldMap['contact'];
			break;
		case 'accounts':
		case 'client':
			obj.Client = customFieldMap['account'];
			break;
		case 'contact':
			obj.Contact = customFieldMap['contact'];
			obj.Client = customFieldMap['account'];
			break;
		case 'agreement':
			obj.Agreement = {
				Agreement: customFieldMap['agreement'],
				Order: customFieldMap['order'],
				OrderRow: customFieldMap['orderrow']
			};
			obj.Client = customFieldMap['account'];
			obj.Contact = customFieldMap['contact'];
			break;
		case 'visit':
		case 'mail':
			obj.Contact = customFieldMap['contact'];
			obj.Client = customFieldMap['account'];
			break;
		case 'product':
			obj.Product = customFieldMap['product'];
			break;
		case 'project':
			obj.Project = customFieldMap['project'];
			break;
		case 'formsubmit':
			obj.Contact = customFieldMap['contact'];
			obj.Client = customFieldMap['account'];
			break;
	}

	return obj;
}

export type EntityToExportTypes = {
	Activity?: typeof ActivityExport;
	Appointment?: typeof AppointmentExport;
	Client?: typeof ClientExport | ReturnType<typeof getExportFieldsWithCategories>;
	Contact?: typeof ContactExport | ReturnType<typeof getExportFieldsWithCategories>;
	Order?: typeof OrderExport;
	Agreement?: typeof AgreementExport;
	Mail?: typeof MailExport;
	Product?: typeof ProductExport;
	Project?: typeof ProjectExport;
	Lead?: typeof LeadExport;
	FormSubmit?: typeof FormSubmitExport;
	Visit?: typeof VisitExport;
} & {
	[key: string]: { [key: string]: ExportField };
};

export function getSelectablesByType(type: string, categoryTypeMap: { [key: string]: CategoryType[] }) {
	const obj: EntityToExportTypes = {};

	switch (type) {
		case 'appointment':
		case 'activity':
			if (type === 'activity') {
				obj.Activity = ActivityExport;
			} else {
				obj.Appointment = AppointmentExport;
			}
			obj.Client = ClientExport;
			obj.Contact = ContactExport;
			break;
		case 'opportunity':
		case 'order':
			obj.Order = OrderExport;
			obj.Client = ClientExport;
			obj.Contact = ContactExport;
			break;
		case 'accounts':
			obj.Client = getExportFieldsWithCategories(GrowthExport, categoryTypeMap['account']);
			break;
		case 'client':
			obj.Client = getExportFieldsWithCategories(ClientExport, categoryTypeMap['account']);
			break;
		case 'contact':
			obj.Client = getExportFieldsWithCategories(ClientExport, categoryTypeMap['account']);
			obj.Contact = getExportFieldsWithCategories(ContactExport, categoryTypeMap['contact']);
			break;
		case 'agreement':
			obj.Agreement = AgreementExport;
			obj.Client = ClientExport;
			obj.Contact = ContactExport;
			break;
		case 'mail':
			obj.Mail = MailExport;
			obj.Contact = ContactExport;
			obj.Client = ClientExport;
			break;
		case 'product':
			obj.Product = ProductExport;
			break;
		case 'project':
			obj.Project = ProjectExport;
			break;
		case 'lead':
			obj.Lead = LeadExport;
			break;
		case 'formsubmit':
			obj.FormSubmit = FormSubmitExport;
			obj.Contact = ContactExport;
			obj.Client = ClientExport;
			break;
		case 'visit':
			obj.Contact = ContactExport;
			obj.Visit = VisitExport;
			obj.Client = ClientExport;
			break;
	}

	return obj;
}

function getExportFieldsWithCategories(
	exportField: EntityToExportTypes[keyof EntityToExportTypes],
	categoryTypes: CategoryType[]
) {
	const customCategories = categoryTypes.reduce(
		(res: { [key: string]: { title: string; field: string } }, category) => {
			const key = `Category_${category.id}`;
			res[key] = {
				title: category.name,
				field: key
			};
			return res;
		},
		{}
	);
	return { ...customCategories, ...exportField };
}

export function getDefaultColumnsByType(type: string) {
	switch (type) {
		case 'appointment':
		case 'activity':
			return [];
		case 'opportunity':
		case 'order':
			return ['date', 'value', 'description', 'user'];
	}
}
