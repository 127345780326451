import { useEffect, useState, useCallback, RefObject, DependencyList } from 'react';

/**
 * Not sure how to do this, but this seems to work.
 * Does it look kinda janky? Yea, but so did the angular version too. So I guess we achieved our goal!
 */
const useAngularLikeHeight = (
	elementRef: RefObject<HTMLDivElement>,
	selectors: string[] = [],
	dependencyList: DependencyList = [],
	options: { initialHeight?: string } = {}
) => {
	const [height, setHeight] = useState<string>(options.initialHeight ?? 'max-content');

	const calculateHeight = useCallback(() => {
		if (elementRef.current) {
			const currentHeight = elementRef.current.style.getPropertyValue('--height'); // Get current pixel height
			elementRef.current.style.setProperty('--height', 'max-content'); // Give it its childrens height
			const newHeight = `${elementRef.current.offsetHeight}px`; // Meassure children height
			elementRef.current.style.setProperty('--height', currentHeight); // Reset it to current height so that the transition works
			setTimeout(() => {
				// Give the machine some time to think?
				setHeight(newHeight); // Set the new height
			}, 0);
		}
	}, [elementRef]);

	useEffect(() => {
		setHeight(`${elementRef.current?.offsetHeight ?? 0}px`);

		let calculateHeightTimeoutID: null | NodeJS.Timeout = null;

		const calculateHeightDebounced = () => {
			if (calculateHeightTimeoutID) {
				clearTimeout(calculateHeightTimeoutID);
			}
			// Kinda need a 300ms delay here because alot of transitions are running with that, and we do not want to measure the hight mid transition
			calculateHeightTimeoutID = setTimeout(calculateHeight, 300);
		};

		const observer = new MutationObserver(calculateHeightDebounced);
		for (const selector of selectors) {
			const element = elementRef.current?.querySelector(selector);
			if (element) {
				observer.observe(element, { childList: true, subtree: true });
			}
		}

		window.addEventListener('resize', calculateHeightDebounced, { passive: true });

		return () => {
			window.removeEventListener('resize', calculateHeightDebounced);
			observer.disconnect();
			if (calculateHeightTimeoutID) {
				clearTimeout(calculateHeightTimeoutID);
			}
		};
	}, [elementRef, ...dependencyList]);

	return { height, calculateHeight };
};

export default useAngularLikeHeight;
