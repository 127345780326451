import Resource from 'Resources/Resource';

class MarketRejectList extends Resource {
	constructor() {
		super('marketRejectlist');
	}
}

const resource = new MarketRejectList();

export default resource;
