import { Block, Card, Button, Icon, Label, Input, OutsideClick, Loader, Text } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import React, { useEffect, useMemo, useState } from 'react';
import T from 'Components/Helpers/translate';
import './RelationSelect.scss';
import { SlideFade } from 'App/components/animations';
import Client from 'App/resources/Model/Client';
import Contact from 'App/resources/Model/Contact';
import logError from 'App/babel/helpers/logError';
import Relation, { AvailableRelationConfig } from 'App/resources/Model/EntityRelation';
import { fetch, getRelations } from './helpers/fetchHelpers';
import RelationRow from './RelationRow';
import mapRelations, {
	activityRelationMapper,
	opportunityRelationMapper,
	appointmentRelationMapper,
	projectPlanRelationMapper,
	ticketRelationMapper,
	agreementRelationMapper
} from './helpers/relationMappers';
import RelatedRelations from './RelatedRelations';
import moment from 'moment';
import SelectedRelations from './SelectedRelations';
import Activity from 'App/resources/Model/Activity';
import Appointment from 'App/resources/Model/Appointment';
import Order from 'App/resources/Model/Order';
import ProjectPlan from 'App/resources/Model/ProjectPlan';
import { useFeatureAvailable, Feature, useSoftDeployAccess } from 'App/components/hooks/featureHelper';
import Ticket from 'App/resources/Model/Ticket';
import Agreement from 'App/resources/Model/Agreement';

export type OnChangeValue = {
	[key: string]: Relation['id'] | null | undefined;
};

type Props = Omit<React.ComponentProps<typeof Block>, 'onChange'> & {
	onChange: (value: OnChangeValue) => void;
	onClose?: () => void;
	userId: number;
	client?: Pick<Client, 'id' | 'name'> | null;
	contact?: Pick<Contact, 'id' | 'name'> | null;
	opportunityId?: number | null;
	appointmentId?: number | null;
	activityId?: number | null;
	projectPlanId?: number | null;
	ticketId?: number | null;
	agreementId?: number | null;
	disabled?: boolean;
	inputRef?: (r: HTMLInputElement) => void;
	onInputBlur?: () => void;
	label?: string;
	autofocus?: boolean;
	autofocusDelay?: number;
	availableEntities?: AvailableRelationConfig;
	multiple?: boolean;
	disableOutsideClick?: boolean;
	openedFromDropdown?: boolean;
	onFetchDone?: () => void;
	hideRelated?: boolean;
	filterOutRelations?: Pick<Relation, 'type' | 'id'>[];
	activity?: Activity;
	appointment?: Appointment;
	opportunity?: Order;
	projectPlan?: ProjectPlan;
	ticket?: Ticket;
	agreement?: Agreement;
	staticRelations?: Partial<AvailableRelationConfig>;
	showNoRelationsFound?: boolean;
};

const RelationSelect = ({
	client,
	contact,
	userId,
	onChange,
	onClose = () => {},
	opportunityId,
	appointmentId,
	activityId,
	projectPlanId,
	ticketId,
	agreementId,
	disabled = false,
	inputRef,
	onInputBlur,
	label,
	availableEntities = {
		order: false,
		opportunity: true,
		activity: true,
		appointment: true,
		projectPlan: true,
		ticket: true,
		agreement: true
	},
	multiple = true,
	autofocus = false,
	autofocusDelay = 0,
	disableOutsideClick = false,
	openedFromDropdown = false,
	onFetchDone,
	hideRelated,
	filterOutRelations = [],
	activity: initialActivity,
	appointment: initialAppointment,
	opportunity: initialOpportunity,
	ticket: initialTicket,
	projectPlan: initialProjectPlan,
	agreement: initalAgreement,
	staticRelations,
	showNoRelationsFound = false,
	...props
}: Props) => {
	const classes = new BemClass('RelationSelect');
	const [relations, setRelations] = useState<Relation[]>([]);
	const [open, setOpen] = useState(false);
	const [isFirstFetch, setIsFirstFetch] = useState(true);
	const [searching, setSearching] = useState(false);
	const mainRef = React.useRef<HTMLDivElement>();
	const [search, setSearch] = useState('');
	const hasSelectedValues = [appointmentId, opportunityId, activityId, projectPlanId, ticketId, agreementId].some(
		entity => !!entity
	);
	const hasProjectPlanCallsAndAppointments = useSoftDeployAccess('PROJECT_PLAN_CALLS_AND_APPOINTMENTS');
	const hasProjectPlanAccess = useSoftDeployAccess('PROJECT_PLAN');
	const hasProjectPlanFeature = useFeatureAvailable(Feature.PROJECT_PLAN);
	const hasProjectPlan = hasProjectPlanAccess || hasProjectPlanFeature;

	const [selectedOpportunity, setSelectedOpportunity] = useState<Relation | null>(null);
	const [selectedAppointment, setSelectedAppointment] = useState<Relation | null>(null);
	const [selectedActivity, setSelectedActivity] = useState<Relation | null>(null);
	const [selectedProjectPlan, setSelectedProjectPlan] = useState<Relation | null>(null);
	const [selectedTicket, setSelectedTicket] = useState<Relation | null>(null);
	const [selectedAgreement, setSelectedAgreement] = useState<Relation | null>(null);

	const existingRelations = useMemo(
		() => ({
			opportunityId,
			appointmentId,
			activityId,
			projectPlanId,
			ticketId,
			agreementId
		}),
		[]
	);

	// Reset certain selected relations when userId changes, don't blindly add more relations to this (Jira issue: IM-1500)
	useEffect(() => {
		if (!selectedActivity && !selectedAppointment && !selectedOpportunity) return;
		const value: OnChangeValue = {
			opportunityId: null,
			appointmentId: null,
			activityId: null
		};
		onChange(value);
		setSelectedOpportunity(null);
		setSelectedAppointment(null);
		setSelectedActivity(null);
	}, [userId]);

	useEffect(() => {
		if (initialOpportunity) {
			setSelectedOpportunity(opportunityRelationMapper(initialOpportunity, contact));
		} else {
			setSelectedOpportunity(relations.find(rel => rel.id === opportunityId) ?? null);
		}
	}, [opportunityId]);

	useEffect(() => {
		if (initialAppointment) {
			setSelectedAppointment(appointmentRelationMapper(initialAppointment, userId, contact));
		} else {
			setSelectedAppointment(relations.find(rel => rel.id === appointmentId) ?? null);
		}
	}, [appointmentId]);

	useEffect(() => {
		if (initialActivity) {
			setSelectedActivity(activityRelationMapper(initialActivity, userId, contact));
		} else {
			setSelectedActivity(relations.find(rel => rel.id === activityId) ?? null);
		}
	}, [activityId]);

	useEffect(() => {
		if (initialProjectPlan) {
			const mapped = projectPlanRelationMapper(initialProjectPlan, contact);
			setSelectedProjectPlan(mapped);
		} else {
			setSelectedProjectPlan(relations.find(rel => rel.id === projectPlanId) ?? null);
		}
	}, [projectPlanId]);

	useEffect(() => {
		if (initialTicket) {
			setSelectedTicket(ticketRelationMapper(initialTicket, userId, contact));
		} else {
			setSelectedTicket(relations.find(rel => rel.id === ticketId) ?? null);
		}
	}, [ticketId]);

	useEffect(() => {
		if (initalAgreement) {
			setSelectedAgreement(agreementRelationMapper(initalAgreement, contact));
		} else {
			setSelectedAgreement(relations.find(rel => rel.id === agreementId) ?? null);
		}
	}, [agreementId]);

	useEffect(() => {
		if (!opportunityId && !appointmentId && !activityId && !projectPlanId && !ticketId && !agreementId) return;
		let fetchSelectedPromise: ReturnType<typeof getRelations> | null = null;
		fetchSelectedPromise = getRelations(
			initialOpportunity ? undefined : opportunityId,
			initialAppointment ? undefined : appointmentId,
			initialActivity ? undefined : activityId,
			initialProjectPlan ? undefined : projectPlanId,
			initialTicket ? undefined : ticketId,
			initalAgreement ? undefined : agreementId
		);

		fetchSelectedPromise?.promise
			.then(([opportunity, appointment, activity, projectPlan, ticket, agreement]) => {
				const selectedRelations = mapRelations(
					[initialOpportunity ?? opportunity],
					[initialAppointment ?? appointment],
					[initialActivity ?? activity],
					[initialProjectPlan ?? projectPlan],
					[initialTicket ?? ticket],
					[initalAgreement ?? agreement],
					userId,
					contact
				);
				setSelectedOpportunity(selectedRelations.find(rel => rel.id === opportunityId) ?? null);
				setSelectedAppointment(selectedRelations.find(rel => rel.id === appointmentId) ?? null);
				setSelectedActivity(selectedRelations.find(rel => rel.id === activityId) ?? null);
				setSelectedProjectPlan(selectedRelations.find(rel => rel.id === projectPlanId) ?? null);
				setSelectedTicket(selectedRelations.find(rel => rel.id === ticketId) ?? null);
				setSelectedAgreement(selectedRelations.find(rel => rel.id === agreementId) ?? null);
			})
			.catch(err => logError(err, 'Failed to set selected relations'));

		return () => {
			fetchSelectedPromise?.cancel();
		};
	}, []);

	useEffect(() => {
		let fetchPromise: ReturnType<typeof fetch> | null = null,
			debounce: ReturnType<typeof setTimeout> | null = null;

		if (!hasProjectPlanCallsAndAppointments || !hasProjectPlan) {
			availableEntities.projectPlan = false;
		}

		if (client?.id) {
			setSearching(true);
			debounce = setTimeout(() => {
				fetchPromise = fetch(search, userId, client?.id || null, availableEntities);
				fetchPromise.promise
					.then(([opportunities, appointments, activities, projectPlans, tickets, agreements]) => {
						const relations = mapRelations(
							opportunities,
							appointments,
							activities,
							projectPlans,
							tickets,
							agreements,
							userId,
							contact
						).filter(
							item =>
								!filterOutRelations.some(
									filterItem => filterItem.id === item.id && filterItem.type === item.type
								)
						);
						setRelations(relations);
						if (isFirstFetch) {
							setIsFirstFetch(false);
							onFetchDone?.();
						}
					})
					.catch(err => logError(err, 'Failed to fetch appointment & opportunity'))
					.finally(() => {
						setSearching(false);
					});
			}, 300);
		}
		return () => {
			if (debounce) {
				clearTimeout(debounce);
			}
			if (fetchPromise) {
				fetchPromise.cancel();
			}
		};
	}, [client?.id, search, userId]);

	useEffect(() => {
		let autofocusTimeout: NodeJS.Timeout;
		if (autofocus) {
			autofocusTimeout = setTimeout(() => setOpen(true), autofocusDelay);
		}

		return () => {
			if (autofocus && autofocusTimeout) {
				clearTimeout(autofocusTimeout);
			}
		};
	}, [autofocus]);

	const typeToEntityHandler = {
		appointment: {
			setter: setSelectedAppointment,
			mapper: (appointment: Appointment) => appointmentRelationMapper(appointment, userId, contact),
			id: appointmentId
		},
		opportunity: {
			setter: setSelectedOpportunity,
			mapper: (opportunity: Order) => opportunityRelationMapper(opportunity, contact),
			id: opportunityId
		},
		activity: {
			setter: setSelectedActivity,
			mapper: (activity: Activity) => activityRelationMapper(activity, userId, contact),
			id: activityId
		},
		projectPlan: {
			setter: setSelectedProjectPlan,
			mapper: (projectPlan: ProjectPlan) => projectPlanRelationMapper(projectPlan, contact),
			id: projectPlanId
		},
		ticket: {
			setter: setSelectedTicket,
			mapper: (ticket: Ticket) => ticketRelationMapper(ticket, userId, contact),
			id: ticketId
		},
		agreement: {
			setter: setSelectedAgreement,
			mapper: (agreement: Agreement) => agreementRelationMapper(agreement, contact),
			id: agreementId
		}
	};

	useEffect(() => {
		const onEvent = (event: any, eventEntity: any) => {
			const [eventType, eventAction]: [keyof AvailableRelationConfig, 'updated' | 'deleted'] =
				event.name.split('.');
			const type = eventType === 'order' ? 'opportunity' : eventType;

			if (eventEntity.id === typeToEntityHandler[type].id) {
				const wasDeleted = eventAction === 'deleted';
				if (wasDeleted) {
					typeToEntityHandler[type].setter(null);
					setRelations(currentRelations =>
						currentRelations.filter(rel => rel.type === type && rel.id !== eventEntity.id)
					);

					const value: OnChangeValue = {
						opportunityId,
						appointmentId,
						activityId,
						projectPlanId,
						ticketId,
						agreementId
					};
					value[`${type}Id`] = null;
					onChange(value);
				} else {
					const mappedEventEntity = typeToEntityHandler[type].mapper(eventEntity);
					typeToEntityHandler[type].setter(mappedEventEntity);
					setRelations(currentRelations =>
						currentRelations.map(rel => {
							if (rel.type === type && rel.id === eventEntity.id) {
								return mappedEventEntity as Relation;
							}
							return rel;
						})
					);
				}
			}
		};

		const eventListeners = [...Object.keys(typeToEntityHandler), 'order'].flatMap(type => [
			`${type}.updated`,
			`${type}.deleted`
		]);
		const listeners = eventListeners.map(eventName => Tools.$rootScope.$on(eventName, onEvent));

		return () => {
			listeners.forEach(listener => listener());
		};
	}, [opportunityId, appointmentId, activityId, projectPlanId, ticketId, agreementId]);

	const toggleRow = (rel: Relation) => {
		if (!multiple && hasSelectedValues) return;
		const value: OnChangeValue = { opportunityId, appointmentId, activityId, projectPlanId, ticketId, agreementId };
		if (value[`${rel.type}Id`] === rel.id) {
			value[`${rel.type}Id`] = null;
		} else {
			value[`${rel.type}Id`] = rel.id;
		}
		onChange(value);

		// Close dropdown if we only expect one selection
		if (value[`${rel.type}Id`] === rel.id && !multiple) setOpen(false);
	};

	const relationRow = (rel: Relation | undefined) => {
		if (!rel) {
			return null;
		}
		return (
			<RelationRow
				key={`${rel.type}-${rel.id}`}
				relation={rel}
				toggleRow={toggleRow}
				selectedOpportunityId={opportunityId}
				selectedAppointmentId={appointmentId}
				selectedActivityId={activityId}
				selectedProjectPlanId={projectPlanId}
				selectedTicketId={ticketId}
				selectedAgreementId={agreementId}
			/>
		);
	};

	const groupedRelations = useMemo(() => {
		return relations.reduce<{
			withClient: Relation[];
			withContact: Relation[];
			withClientOld: Relation[];
			withContactOld: Relation[];
		}>(
			(res, relation) => {
				if (relation.withContact) {
					if (relation.date && moment(relation.date).diff(moment(), 'days') < -365)
						res.withContactOld.push(relation);
					else {
						res.withContact.push(relation);
					}
				} else {
					if (relation.date && moment(relation.date).diff(moment(), 'days') < -365)
						res.withClientOld.push(relation);
					else {
						res.withClient.push(relation);
					}
				}
				return res;
			},
			{ withContact: [], withClient: [], withContactOld: [], withClientOld: [] }
		);
	}, [relations]);

	const showRelated = !!relations.length || !!search || initialActivity || initialAppointment || initialOpportunity;

	if (!client || !showRelated) {
		if (openedFromDropdown || showNoRelationsFound) {
			return (
				<Text
					className={classes.elem('notFound').b()}
					italic
					color="grey-11"
					align={showNoRelationsFound ? 'left' : 'center'}
				>
					{T('relationDropdown.notFound')}
				</Text>
			);
		}
		return null;
	}

	const showInput =
		multiple ||
		[appointmentId, opportunityId, activityId, projectPlanId, ticketId, agreementId].every(
			entity => entity === null
		);
	const selectedRelations = [
		selectedOpportunity,
		selectedAppointment,
		selectedActivity,
		selectedProjectPlan,
		selectedTicket,
		selectedAgreement
	].filter(r => r) as Relation[];

	return (
		<Block {...props} className={classes.b()}>
			<OutsideClick
				targetRef={() => mainRef.current || null}
				listen={open && !disableOutsideClick}
				outsideClick={() => {
					setOpen(false);
					setSearch('');
					onClose();
					onInputBlur?.();
				}}
			>
				<div
					className={classes.elem('content').mod({ openedFromDropdown }).b()}
					ref={(r: HTMLInputElement) => (mainRef.current = r)}
				>
					{label ? <Label>{label}</Label> : null}
					<SlideFade visible={showInput} direction="top">
						<Input
							className={classes.elem('input').b()}
							inputRef={r => {
								if (r) {
									inputRef?.(r);
								}
							}}
							icon="search"
							placeholder={T('relateTo.searchActivites')}
							value={search}
							onFocus={() => setOpen(true)}
							onChange={e => setSearch(e.target.value)}
							disabled={disabled}
						/>
					</SlideFade>

					{openedFromDropdown ? (
						<RelatedRelations
							opportunityId={existingRelations.opportunityId}
							appointmentId={existingRelations.appointmentId}
							activityId={existingRelations.activityId}
							projectPlanId={existingRelations.projectPlanId}
							ticketId={existingRelations.ticketId}
							agreementId={existingRelations.agreementId}
							selectedOpportunityId={opportunityId}
							selectedAppointmentId={appointmentId}
							selectedActivityId={activityId}
							selectedProjectPlanId={projectPlanId}
							selectedTicketId={ticketId}
							selectedAgreementId={agreementId}
							userId={userId}
							contact={contact}
							toggleRow={toggleRow}
						/>
					) : null}

					<SlideFade direction="top" bounce visible={showRelated && open}>
						<Card className={openedFromDropdown ? '' : classes.elem('dropdown').b()}>
							<Block className={classes.elem('card').b()}>
								{searching ? <Loader size="sm" /> : null}
								{groupedRelations.withContact.length ? (
									<Label>{`${T('todo.ongoingWith')} ${contact?.name}`}</Label>
								) : null}
								{groupedRelations.withContact.map(relationRow)}
								{groupedRelations.withClient.length ? (
									<Label>{`${T('todo.ongoingWith')} ${client.name}`}</Label>
								) : null}
								{groupedRelations.withClient.map(relationRow)}
								{groupedRelations.withContactOld.length ? (
									<Label>{`${T('todo.moreThan1YearAgoWith')} ${contact?.name}`}</Label>
								) : null}
								{groupedRelations.withContactOld.map(relationRow)}
								{groupedRelations.withClientOld.length ? (
									<Label>{`${T('todo.moreThan1YearAgoWith')} ${client.name}`}</Label>
								) : null}
								{groupedRelations.withClientOld.map(relationRow)}
							</Block>
							<Button
								block
								color={hasSelectedValues ? 'green' : 'super-light-green'}
								onClick={() => {
									onClose();
									setOpen(false);
									setSearch('');
								}}
							>
								<Icon name={hasSelectedValues ? 'check' : 'times'} space="mrs" />
								{hasSelectedValues ? T('default.save') : T('todo.noNotRelated')}
							</Button>
						</Card>
					</SlideFade>
					{!hideRelated && selectedRelations.length ? (
						<Block space="ptm">
							<SelectedRelations
								relations={selectedRelations}
								removeRelation={toggleRow}
								staticRelations={staticRelations}
							/>
						</Block>
					) : null}
				</div>
			</OutsideClick>
		</Block>
	);
};

export default RelationSelect;
