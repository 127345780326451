import React from 'react';
import { Provider, connect } from 'react-redux';
import store from 'App/babel/store';
import { RootState } from 'Store/index';
import bemClass from '@upsales/components/Utils/bemClass';
import T from 'Components/Helpers/translate';
import OpportunityRow from './OpportunityRow';
import OpportunityRowOverAvg from './OpportunityRowOverAvg';
import OpportunityRowCEO from './OpportunityRowCEO';
import OpportunityRowBudget from './OpportunityRowBudget';
import OpportunityRowTimeFrame from './OpportunityRowTimeFrame';
import OpportunityRowExpired from './OpportunityRowExpired';
import OpportunityRowSolution from './OpportunityRowSolution';
import OpportunityRowCustom from './OpportunityRowCustom';
import AllRecommendationsApproved from './AllRecommendationsApproved';
import ReduxListeners from 'Store/ReduxListeners';
import { getParameters, setIds } from 'Store/reducers/OpportunityAIReducer';
import { isEmpty } from 'lodash';
import { makeCancelable } from 'App/babel/helpers/promise';
import { Headline, Card, Icon, Text } from '@upsales/components';
import logError from 'App/babel/helpers/logError';
import SalesCoach from 'Resources/SalesCoach';
import FieldTranslation from 'App/babel/resources/FieldTranslations';
import { SlideFade } from 'App/components/animations';
import {
	Budget,
	GeneralChecklistItemType,
	Timeframe,
	DecisionMakers,
	NextStep,
	Solution,
	SalesCoachChecklistItem
} from 'App/resources/Model/SalesCoach';
import moment from 'moment';
import './Opportunity.scss';
import Order from 'App/resources/Model/Order';
import Appointment from 'App/resources/Model/Appointment';
import Activity from 'App/resources/Model/Activity';

const mapStateToProps = (state: RootState) => ({ ...state.OpportunityAI });

const mapDispatchToProps = {
	getParameters,
	setIds
};

type TitleType = {
	id: number;
	value: string;
	title: string;
};

type StakeholderType = {
	id: number;
	// eslint-disable-next-line camelcase
	contact_id: number;
	// eslint-disable-next-line camelcase
	tag_id: string;
	value: string;
	title: string;
};

type Props = {
	setCreateData: (data: { type: string; description: string }) => void;
	startOpportunity: Order;
	opportunity: Order;
	updateOrder: (order: Partial<Order>) => void;
	updateProbability: (probability: number) => void;
	setIds: (id: number) => void;
	getParameters: () => Promise<void>;
	orderValue?: number;
	avg: number;
	appointment: Appointment;
	activity: Activity;
	phonecall: Activity;
	isDecisionMakerInvolved: boolean;
	decisionMakerId: number;
	decisionMakerName: string;
	includedTitles: TitleType[];
	excludedTitles: TitleType[];
	includedStakeholders: StakeholderType[];
	excludedStakeholders: StakeholderType[];
	disabled?: boolean;
};

type State = {
	opportunity: Order;
	showRecomendations: boolean;
	doneFetchingData: boolean;
	decisionMakersTitle: string | null;
	budget: Budget;
	nextStep: NextStep;
	solution: Solution;
	decisionMakers: DecisionMakers;
	timeframe: Timeframe;
	loadingSalesCoach: boolean;
	checklist: SalesCoachChecklistItem[];
	showCompleted: boolean;
};

type LangType = {
	overAvg: {
		you: { oneDay: () => string; multiple: () => string };
		username: {
			oneDay: (name: string) => string;
			multiple: (name: string) => string;
		};
	};
	underAvg: {
		you: { oneDay: () => string; multiple: () => string };
		username: {
			oneDay: (name: string) => string;
			multiple: (name: string) => string;
		};
	};
};

type PromiseType = { promise: Promise<any>; cancel: () => void } | undefined;

class OpportunityAI extends React.Component<Props, State> {
	listeners: (() => void)[] | undefined;
	hasTodos: boolean;
	hasSalesProcessPro: boolean;
	getParametersPromise: PromiseType;
	getSalesCoachPromise: PromiseType;
	getDecisionMakersNamePromise: PromiseType;
	lang: LangType;

	constructor(p: Props) {
		super(p);
		this.listeners = [
			ReduxListeners.add('appointment.added', getParameters),
			ReduxListeners.add('appointment.updated', getParameters),
			ReduxListeners.add('appointment.deleted', getParameters),
			ReduxListeners.add('activity.added', getParameters),
			ReduxListeners.add('activity.updated', getParameters),
			ReduxListeners.add('activity.deleted', getParameters),
			ReduxListeners.add('opportunity.updated', getParameters),
			ReduxListeners.add('contact.added', getParameters),
			ReduxListeners.add('contact.updated', getParameters),
			ReduxListeners.add('contact.deleted', getParameters)
		];

		this.lang = {
			overAvg: {
				you: {
					oneDay: () => 'opportunity.yourOverAvgOneDay',
					multiple: () => 'opportunity.yourOverAvg'
				},
				username: {
					oneDay: (name: string) => {
						const lastChar = name.substr(name.length - 1);
						return ['s', 'x', 'z'].includes(lastChar)
							? 'opportunity.usernameOverAvgOneDayNonGenitive'
							: 'opportunity.usernameOverAvgOneDay';
					},
					multiple: (name: string) => {
						const lastChar = name.substr(name.length - 1);
						return ['s', 'x', 'z'].includes(lastChar)
							? 'opportunity.usernameOverAvgNonGenitive'
							: 'opportunity.usernameOverAvg';
					}
				}
			},
			underAvg: {
				you: {
					oneDay: () => 'opportunity.notOverYourAvgOneDay',
					multiple: () => 'opportunity.notOverYourAvg'
				},
				username: {
					oneDay: (name: string) => {
						const lastChar = name.substr(name.length - 1);
						return ['s', 'x', 'z'].includes(lastChar)
							? 'opportunity.notOverAvgOneDayNonGenitive'
							: 'opportunity.notOverAvgOneDay';
					},
					multiple: (name: string) => {
						const lastChar = name.substr(name.length - 1);
						return ['s', 'x', 'z'].includes(lastChar)
							? 'opportunity.notOverAvgNonGenitive'
							: 'opportunity.notOverAvg';
					}
				}
			}
		};

		this.state = {
			opportunity: p.opportunity,
			showRecomendations: false,
			doneFetchingData: false,
			decisionMakersTitle: null,
			budget: { active: true, entireSalesProcess: true, stages: [] },
			nextStep: { active: true, entireSalesProcess: true, onlyAppointments: false },
			solution: { active: true, entireSalesProcess: true, stages: [] },
			decisionMakers: { active: true, entireSalesProcess: true, stages: [], titleCategories: [] },
			timeframe: { active: true, entireSalesProcess: true, stages: [] },
			loadingSalesCoach: true,
			checklist: [],
			showCompleted: false
		};

		this.hasSalesProcessPro = Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.SALES_PROCESS_PRO);
		this.hasTodos = Tools.FeatureHelper.hasSoftDeployAccess('TODO_LIST');
		this.overrideOpportunity = this.overrideOpportunity.bind(this);
	}

	componentDidMount() {
		this.props.setIds(this.props.startOpportunity.id);
		this.getParametersPromise = makeCancelable(this.props.getParameters());
		this.getParametersPromise.promise
			.then(() => {
				this.setState({ doneFetchingData: true });
			})
			.catch(e => {
				if (!e.isCanceled) {
					logError(e, 'Failed to getParameters');
				}
			});
	}

	// eslint-disable-next-line camelcase
	async UNSAFE_componentWillReceiveProps(p: Props) {
		if (p.opportunity) {
			this.setState({ opportunity: p.opportunity });

			if (p.opportunity.salesCoach?.length === 1 && p.opportunity.salesCoach[0].id) {
				await this.getSalesCoach(p.opportunity.salesCoach[0].id);
			}
		}
		if (p.orderValue) {
			this.setState({ opportunity: { ...this.state.opportunity, value: p.orderValue } });
		}
	}

	componentWillUnmount() {
		this.listeners?.forEach(listener => listener());

		if (this.getParametersPromise) {
			this.getParametersPromise.cancel();
		}

		if (this.getSalesCoachPromise) {
			this.getSalesCoachPromise.cancel();
		}

		if (this.getDecisionMakersNamePromise) {
			this.getDecisionMakersNamePromise.cancel();
		}
	}

	isOpportunity(opportunity: Order) {
		if (opportunity.probability) {
			return !(opportunity.probability === 100 || opportunity.probability === 0);
		} else {
			return false;
		}
	}

	getSalesCoach(id: number) {
		this.getSalesCoachPromise = makeCancelable(SalesCoach.get(id));
		this.getSalesCoachPromise.promise
			.then(({ data }) => {
				this.setState({
					budget: data.budget,
					nextStep: data.nextStep,
					solution: data.solution,
					decisionMakers: data.decisionMakers,
					timeframe: data.timeframe,
					loadingSalesCoach: false,
					checklist: data.checklist
				});

				const titleId = data.decisionMakers?.titleCategories?.[0]?.titleId;
				if (titleId) {
					this.getDecisionMakersTitle(titleId);
				}
			})
			.catch(e => {
				if (!e.isCanceled) {
					logError(e, 'Failed to getSalesCoach');
				}
			});
	}

	getDecisionMakersTitle(titleId: number) {
		this.getDecisionMakersNamePromise = makeCancelable(
			FieldTranslation.find({ type: 'titlecategory', tagId: titleId })
		);
		this.getDecisionMakersNamePromise.promise
			.then(({ data }) => {
				const name = data?.[0]?.value;
				this.setState({ decisionMakersTitle: name });
			})
			.catch(e => {
				if (!e.isCanceled) {
					logError(e, 'Failed to get title category of current sales process');
				}
			});
	}

	updateOpportunity = (opportunity: Order) => {
		this.setState({ opportunity });
	};

	shouldShowInThisStage(checklistItem: GeneralChecklistItemType) {
		return this.isItemActiveInThisStage(checklistItem);
	}

	isItemActiveInThisStage(checklistItem: GeneralChecklistItemType) {
		if (checklistItem?.active) {
			if (checklistItem.entireSalesProcess && this.isOpportunity(this.state.opportunity)) {
				return true;
			}

			if (checklistItem.stages[this.state.opportunity.stage?.id]) {
				return checklistItem.stages[this.state.opportunity.stage.id]?.active;
			}
			return false;
		}
		return false;
	}

	getOpportunityRow(activity: Activity, appointment: Appointment, phonecall: Activity, opportunity: Order) {
		if (this.state.nextStep?.onlyAppointments) {
			if (isEmpty(appointment)) {
				return [
					null,
					<OpportunityRow
						key={'nextstep'}
						isWarning={true}
						setCreateData={this.props.setCreateData}
						opportunity={opportunity}
						onlyBookAppointmentButton={true}
						disabled={this.props.disabled}
					/>
				];
			} else {
				const extraProps = {
					opportunity: opportunity,
					appointmentId: appointment.id,
					date: appointment.date,
					description: appointment.description
				};
				return [
					<OpportunityRow
						key={'nextstep'}
						isWarning={false}
						{...extraProps}
						disabled={this.props.disabled}
					/>,
					null
				];
			}
		}

		let currentActivity;
		if (this.hasTodos) {
			currentActivity = phonecall;
		} else {
			currentActivity = activity;
		}
		if (isEmpty(appointment) && isEmpty(currentActivity)) {
			return [
				null,
				<OpportunityRow
					key={'nextstep'}
					isWarning={true}
					setCreateData={this.props.setCreateData}
					opportunity={opportunity}
					onlyBookAppointmentButton={false}
					disabled={this.props.disabled}
				/>
			];
		}

		let showAppointment = !isEmpty(appointment) && isEmpty(currentActivity);
		if (!isEmpty(appointment) && !isEmpty(currentActivity)) {
			if (moment(appointment.endDate).isSameOrBefore(moment(currentActivity.date))) {
				showAppointment = true;
			} else {
				showAppointment = false;
			}
		}
		let extraProps = {};
		if (showAppointment) {
			extraProps = {
				appointmentId: appointment.id,
				date: appointment.date,
				description: appointment.description
			};
		} else {
			extraProps = {
				activityId: currentActivity.id,
				date: currentActivity.date,
				description: currentActivity.description
			};
		}

		return [
			<OpportunityRow
				opportunity={opportunity}
				key={'nextstep'}
				isWarning={false}
				{...extraProps}
				disabled={this.props.disabled}
			/>,
			null
		];
	}

	async overrideOpportunity(obj: Partial<Order>) {
		const { updateOrder } = this.props;
		const { opportunity } = this.state;

		await updateOrder(obj);
		const newOpportunity = Object.assign(opportunity, obj);
		this.setState({ opportunity: newOpportunity });
	}

	completed(completed: JSX.Element[]) {
		if (completed.length === 0) {
			return null;
		}

		const classes = new bemClass('OpportunityAI');
		const { showCompleted } = this.state;

		return (
			<Card className={classes.elem('completed').mod({ grey: showCompleted }).b()}>
				<div
					className={classes.elem('textWrapper').b()}
					onClick={() => this.setState({ showCompleted: !showCompleted })}
				>
					<Text>
						{T(showCompleted ? 'opportunity.hideCompleted' : 'opportunity.showCompleted', {
							count: completed.length
						})}
					</Text>{' '}
					<Icon
						name={'chevron-down'}
						space="mlm"
						className={classes.elem('textWrapper').elem('icon').mod({ rotate: showCompleted }).b()}
					/>
				</div>
				<SlideFade direction="top" visible={showCompleted} height maxHeight={completed.length * 50}>
					<div>{completed}</div>
				</SlideFade>
			</Card>
		);
	}

	excludedTitles() {
		const { excludedTitles } = this.props;
		return excludedTitles?.map((field: TitleType) => (
			<OpportunityRowCEO isWarning title={field.value} key={field.value} />
		));
	}

	includedTitles() {
		const { includedTitles } = this.props;
		return includedTitles?.map((contact: TitleType) => (
			<OpportunityRowCEO isWarning={false} decisionMakerId={contact.id} title={contact.title} key={contact.id} />
		));
	}

	excludedStakeholders() {
		const { excludedStakeholders } = this.props;
		return excludedStakeholders?.map((field: StakeholderType) => (
			<OpportunityRowCEO isWarning title={field.value} key={field.id} />
		));
	}

	includedStakeholders() {
		const { includedStakeholders } = this.props;

		return includedStakeholders?.map((field: StakeholderType) => (
			<OpportunityRowCEO
				isWarning={false}
				decisionMakerId={field.contact_id}
				title={field.title}
				key={field.tag_id}
			/>
		));
	}

	CEORow() {
		const {
			isDecisionMakerInvolved,
			decisionMakerId,
			decisionMakerName,
			includedStakeholders,
			includedTitles,
			excludedStakeholders,
			excludedTitles
		} = this.props;
		const { decisionMakers, decisionMakersTitle } = this.state;

		if (this.hasSalesProcessPro) {
			if (includedStakeholders?.length > 0) {
				return this.includedStakeholders();
			} else if (includedTitles?.length > 0) {
				return this.includedTitles();
			}
		}

		if (excludedStakeholders?.length > 0 || excludedTitles?.length > 0) {
			return null;
		}

		if (
			!this.hasSalesProcessPro &&
			this.shouldShowInThisStage(decisionMakers) &&
			isDecisionMakerInvolved &&
			decisionMakerId &&
			decisionMakersTitle
		) {
			return (
				<OpportunityRowCEO
					key="ceo"
					isWarning={false}
					decisionMakerId={decisionMakerId}
					decisionMakerName={decisionMakerName}
					title={decisionMakersTitle}
				/>
			);
		}
		return null;
	}

	CEOWarningRow() {
		const { isDecisionMakerInvolved, excludedStakeholders, excludedTitles, includedStakeholders, includedTitles } =
			this.props;
		const { decisionMakers, decisionMakersTitle } = this.state;

		if (this.hasSalesProcessPro) {
			if (excludedStakeholders?.length > 0) {
				return this.excludedStakeholders();
			} else if (excludedTitles?.length > 0) {
				return this.excludedTitles();
			}
		}
		if (includedStakeholders?.length > 0 || includedTitles?.length > 0) {
			return null;
		}

		if (isDecisionMakerInvolved === null) {
			return null;
		}
		if (!this.hasSalesProcessPro) {
			if (!isDecisionMakerInvolved && this.shouldShowInThisStage(decisionMakers) && decisionMakersTitle) {
				return <OpportunityRowCEO isWarning={true} title={decisionMakersTitle} />;
			}
		}
		return null;
	}

	allGood(checked: JSX.Element[], inOrderStage: boolean) {
		const opportunity = this.state.opportunity;
		const { showRecomendations } = this.state;
		return (
			<React.Fragment>
				<Headline className="opportunityHeadline" size="xs">
					{opportunity.probability === 100 ? T('opportunity.salesCoaching.wonOrder') : null}
					{opportunity.probability === 0 ? T('opportunity.salesCoaching.lostOrder') : null}
					{opportunity.probability > 0 && opportunity.probability < 100
						? T('opportunity.salesCoaching')
						: null}
				</Headline>
				<AllRecommendationsApproved
					flipRecomendations={() => this.setState({ showRecomendations: !showRecomendations })}
					showRecomendations={showRecomendations}
					checked={checked}
					inOrderStage={inOrderStage}
				/>
			</React.Fragment>
		);
	}

	render() {
		const {
			appointment,
			phonecall,
			activity,
			avg,
			decisionMakerId,
			isDecisionMakerInvolved,
			updateProbability,
			setCreateData,
			excludedStakeholders,
			excludedTitles,
			disabled
		} = this.props;

		const {
			opportunity,
			doneFetchingData,
			budget,
			nextStep,
			solution,
			timeframe,
			loadingSalesCoach,
			decisionMakers,
			checklist
		} = this.state;

		if (!opportunity?.user?.id) {
			return null;
		}

		if (!doneFetchingData || loadingSalesCoach) {
			return null;
		}

		const classes = new bemClass('OpportunityAI');

		const regDate = moment(opportunity.regDate);
		const currentDate = moment(moment().format('YYYY-MM-DD'));
		const currentDiff = currentDate.diff(regDate, 'days');
		const isOpportunity = this.isOpportunity(opportunity);

		const overAvg = avg ? currentDiff > avg * 1.2 : false;
		const notOld = currentDate.isSameOrBefore(opportunity.date);
		const noOrNewTimeFrame =
			opportunity.confirmedDate == null || !moment(opportunity.confirmedDate).isSame(opportunity.date);

		const currentUser = Tools.AppService.getSelf().id;
		const isYou = currentUser === opportunity.user.id;
		const roundAvg = Math.round(avg);
		const isOneDay = roundAvg === 1;

		const filteredChecklist = checklist.filter(
			(checklistItem: SalesCoachChecklistItem) =>
				checklistItem.active &&
				((checklistItem.stages.length === 0 && isOpportunity) ||
					checklistItem.stages.some(({ stageId }) => opportunity.stage.id === stageId))
		);

		const solutionsGood = !!opportunity.confirmedSolution || !this.isItemActiveInThisStage(solution);
		const budgetGood =
			(opportunity.confirmedBudget && opportunity.value > 0) || !this.isItemActiveInThisStage(budget);
		const timeframeGood = (!noOrNewTimeFrame && !!notOld) || !this.isItemActiveInThisStage(timeframe);
		const nextStepGood =
			(nextStep?.onlyAppointments && !isEmpty(appointment)) ||
			(!nextStep?.onlyAppointments && (!isEmpty(phonecall) || !isEmpty(appointment))) ||
			!nextStep.active;
		const customChecklistGood = filteredChecklist.every((checklistItem: SalesCoachChecklistItem) =>
			opportunity.checklist?.find(orderChecklistItem => checklistItem.id === orderChecklistItem.id)
		);

		let allGood =
			// If in an order stage, custom checklists are enough
			(!isOpportunity && customChecklistGood) ||
			// If in an opportunity stage, all is required
			(isOpportunity &&
				solutionsGood &&
				budgetGood &&
				timeframeGood &&
				nextStepGood &&
				customChecklistGood &&
				excludedStakeholders?.length === 0 &&
				excludedTitles?.length === 0);
		if (!this.hasSalesProcessPro) {
			allGood =
				allGood &&
				((!!isDecisionMakerInvolved && !!decisionMakerId) ||
					!this.isItemActiveInThisStage(decisionMakers) ||
					decisionMakers?.titleCategories.length === 0);
		}

		if (isOpportunity && avg) {
			allGood = allGood && !overAvg;
		}

		const showExpired = this.shouldShowInThisStage(timeframe) && !notOld;
		const checked = [] as JSX.Element[];
		const notChecked = [] as JSX.Element[];

		filteredChecklist.forEach((checklistItem: SalesCoachChecklistItem) => {
			const isChecked = !!opportunity.checklist?.find(
				orderChecklistItem => orderChecklistItem.id === checklistItem.id
			);
			const target = isChecked ? checked : notChecked;

			target.push(
				<OpportunityRowCustom
					key={checklistItem.id}
					checklistItem={checklistItem}
					opportunity={opportunity}
					isWarning={!isChecked}
					overrideOpportunity={this.overrideOpportunity}
					disabled={disabled}
				/>
			);
		});
		if (this.shouldShowInThisStage(timeframe) && notOld) {
			const target = !noOrNewTimeFrame ? checked : notChecked;
			const text =
				opportunity.confirmedDate == null || !noOrNewTimeFrame
					? T('opportunity.verifedTimeframe')
					: T('opportunity.newTimeframe');
			target.push(
				<OpportunityRowTimeFrame
					key={'timeframe'}
					text={text}
					isWarning={noOrNewTimeFrame}
					opportunity={opportunity}
					overrideOpportunity={this.overrideOpportunity}
					disabled={disabled}
				/>
			);
		}

		if (this.shouldShowInThisStage(budget)) {
			const isChecked = opportunity.confirmedBudget;
			const target = isChecked ? checked : notChecked;
			const text = opportunity.confirmedBudget ? T('opportunity.newBudget') : T('opportunity.hasBudget');

			target.push(
				<OpportunityRowBudget
					key={'budget'}
					text={text}
					isWarning={!isChecked}
					opportunity={opportunity}
					overrideOpportunity={this.overrideOpportunity}
					disabled={disabled}
				/>
			);
		}

		if (this.shouldShowInThisStage(solution)) {
			const target = opportunity.confirmedSolution ? checked : notChecked;

			target.push(
				<OpportunityRowSolution
					key={'solution'}
					isWarning={!opportunity.confirmedSolution}
					text={T('opportunity.haveConfirmedSolution')}
					opportunity={opportunity}
					overrideOpportunity={this.overrideOpportunity}
					disabled={disabled}
				/>
			);
		}

		const CEORow = this.CEORow();
		if (CEORow) {
			checked.push(...(Array.isArray(CEORow) ? CEORow : [CEORow]));
		}

		const [nextStepPlanned, nextStepWarning] = this.getOpportunityRow(
			activity,
			appointment,
			phonecall,
			opportunity
		);

		if (nextStep.active && isOpportunity && nextStepPlanned) {
			checked.push(nextStepPlanned);
		}

		if (isOpportunity && avg && !overAvg) {
			checked.push(
				<OpportunityRowOverAvg
					key={'avg'}
					text={T(
						this.lang.underAvg[isYou ? 'you' : 'username'][isOneDay ? 'oneDay' : 'multiple'](
							opportunity.user.name
						),
						{
							avg: roundAvg,
							name: opportunity.user.name
						}
					)}
					isWarning={false}
				/>
			);
		}

		if (allGood) {
			return this.allGood(checked, !isOpportunity);
		}

		return (
			<React.Fragment>
				<Headline className="opportunityHeadline" size="xs">
					{opportunity.probability === 100 ? T('opportunity.salesCoaching.wonOrder') : null}
					{opportunity.probability === 0 ? T('opportunity.salesCoaching.lostOrder') : null}
					{opportunity.probability > 0 && opportunity.probability < 100
						? T('opportunity.salesCoaching')
						: null}
				</Headline>
				<div
					className={classes
						.mod({
							roundedEdgesLast: checked.length === 0
						})
						.b()}
				>
					{showExpired ? (
						<OpportunityRowExpired
							key={'expired'}
							closeDate={opportunity.date}
							opportunity={opportunity}
							overrideOpportunity={this.overrideOpportunity}
							updateProbability={updateProbability}
							setCreateData={setCreateData}
							disabled={disabled}
						/>
					) : null}

					{notChecked}

					{isOpportunity && avg && overAvg ? (
						<OpportunityRowOverAvg
							key={'avg'}
							text={T(
								this.lang.overAvg[isYou ? 'you' : 'username'][isOneDay ? 'oneDay' : 'multiple'](
									opportunity.user.name
								),
								{
									avg: roundAvg,
									name: opportunity.user.name
								}
							)}
							isWarning={true}
						/>
					) : null}

					{this.CEOWarningRow()}

					{nextStep.active && isOpportunity ? nextStepWarning : null}
				</div>
				{!allGood ? this.completed(checked) : null}
			</React.Fragment>
		);
	}
}

export const detached = OpportunityAI;

const Component = connect(mapStateToProps, mapDispatchToProps)(OpportunityAI) as any;

export default (props: Props) => (
	<Provider store={store}>
		<Component
			/* eslint-disable react/prop-types */
			startOpportunity={props.opportunity}
			updateOrder={props.updateOrder}
			updateProbability={props.updateProbability}
			setCreateData={props.setCreateData}
			orderValue={props.orderValue}
			disabled={props.disabled}
			/* eslint-enable react/prop-types */
		/>
	</Provider>
);
