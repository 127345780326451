import React from 'react';
import useInputController, { options } from '../hooks/useInputController';

import type { InputHTMLAttributes } from 'react';
import type { Options } from '../hooks/useInputController';

type Props = Omit<InputHTMLAttributes<HTMLInputElement>, 'type' | 'value'> & {
	type?: 'text';
	value: string;
	controllerOptions?: Partial<Options<string, string>>;
};

const TextInput = React.forwardRef<HTMLInputElement, Props>(({ controllerOptions, ...props }, ref) => {
	const instanceOptions = controllerOptions ? { ...options.text, ...controllerOptions } : options.text;
	const controlledProps = useInputController(props, instanceOptions);

	return <input {...controlledProps} ref={ref} />;
});

export default TextInput;
