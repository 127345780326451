import {
	EllipsisTooltip,
	DrawerHeader,
	NumberInput,
	SelectAsync,
	DateInput,
	Textarea,
	IconName,
	Tooltip,
	Column,
	Select,
	Button,
	Title,
	Input,
	Icon,
	Text
} from '@upsales/components';
import { StaticUserMultiSelect } from '../Inputs/Selects/StaticUserSelect/StaticUserSelect';
import ClientPlanTypes, { ClientPlanTypeInfo, PlanStatus } from 'App/enum/ClientPlanType';
import { openEditAppointment } from 'Components/Modals/Appointment/EditAppointment';
import EditorHeaderButton from 'Components/EditorHeader/EditorHeaderButton';
import RequestBuilder, { comparisonTypes } from 'Resources/RequestBuilder';
import ValidationModel from 'App/components/FormObserver/ValidationModel';
import ContactAttributes from 'App/babel/attributes/ContactAttributes';
import ClientPlanHistoryLog from '../HistoryLog/ClientPlanHistoryLog';
import { useFeatureAvailable, useSoftDeployAccess } from '../hooks';
import { accountPlanTracker } from 'App/babel/helpers/Tracker';
import { useTranslation } from 'Components/Helpers/translate';
import { FormComponent } from 'App/components/FormComponent';
import ClientPlanType from 'App/resources/Model/ClientPlan';
import { DefaultButton } from '@upsales/components/Buttons';
import React, { useEffect, useMemo, useState } from 'react';
import { SlideFade } from '@upsales/components/animations';
import FormObserver, { FieldModel } from '../FormObserver';
import BemClass from '@upsales/components/Utils/bemClass';
import { ModalProps } from 'App/components/Modals/Modals';
import ClientPlan from 'App/babel/resources/ClientPlan';
import { TYPES } from 'Components/Helpers/SourceHelper';
import ContactResource from 'App/resources/Contact';
import EntitiesDropdown from '../EntitiesDropdown';
import { Currency } from 'App/resources/AllIWant';
import Contact from 'App/resources/Model/Contact';
import { Feature } from '../hooks/featureHelper';
import { openDrawer } from 'Services/Drawer';
import User from 'App/resources/Model/User';
import { useSelector } from 'react-redux';
import { RootState } from 'Store/index';
import logError from 'Helpers/logError';

type Props = ModalProps & {
	clientName: string;
	clientId: number;
	plan?: ClientPlanType;
	onChange?: (plan: ClientPlanType) => void;
	onStatusChanged?: (plan: ClientPlanType) => void;
	cameFrom: 'clientCard' | 'companyList';
	userEditable?: boolean;
};

type State = {
	type?: ClientPlanTypes;
	description: string;
	value: number;
	date: Date | undefined;
	clientNeeds: string;
	meetingNeeds: string;
	buyNowReason: string;
	contacts: Contact[];
	users: User[];
};

const CONTACTS_LIMIT = 20;

const fetchContacts = (clientId: number) => (searchString?: string) => {
	const filter = new RequestBuilder();
	if (searchString) {
		filter.addFilter(ContactAttributes.name, comparisonTypes.Search, searchString);
		filter.next();
	}

	filter.addFilter(ContactAttributes.client.attr.id, comparisonTypes.Equals, clientId);
	filter.done();
	filter.addSort(ContactAttributes.name, true);
	filter.limit = CONTACTS_LIMIT;

	return ContactResource.find(filter.build()).then(({ data }) => data.map((d: Contact) => ({ ...d, title: d.name })));
};

const EditAccountPlan = ({
	close,
	clientName,
	clientId,
	plan,
	onChange,
	cameFrom,
	onStatusChanged,
	userEditable
}: Props) => {
	const isEdit = !!plan?.id;
	const isClosed = !!plan?.status;
	const { t } = useTranslation();
	const [showInternalStakeholders, setShowInternalStakeholders] = useState(false);
	const [saving, setSaving] = useState(false);
	const [anchor, setAnchor] = useState<Element | null>(null);
	const { customerCurrencies, users } = useSelector((state: RootState) => ({
		customerCurrencies: state.App?.metadata?.customerCurrencies ?? [],
		users: state.App?.userMap?.active ?? []
	}));
	const masterCurrency = customerCurrencies.find(currency => currency.masterCurrency) as Currency;
	const classes = new BemClass('EditAccountPlan');
	const {
		CREATE_OPPORTUNITY,
		CREATE_PHONE_CALL,
		CREATE_ACTIVITY,
		CREATE_MEETING,
		NOT_COMPLETED,
		CREATE_ORDER,
		CREATE_TODO,
		COMPLETED,
		CANCELED,
		CREATED,
		EDITED
	} = accountPlanTracker.events;

	const statusMap = {
		[PlanStatus.COMPLETED]: t('accountPlan.completed'),
		[PlanStatus.NOT_COMPLETED]: t('accountPlan.notCompleted')
	} as const;

	const mappedUsers = useMemo(
		() =>
			users.reduce((acc: { [id: number]: string }, curr) => {
				acc[curr.id] = curr.name;
				return acc;
			}, {}),
		[users]
	);

	type OptionType = {
		id: string;
		title: string;
		subtitle: string;
		icon: IconName;
	};
	const options: OptionType[] = [
		{
			id: ClientPlanTypes.GROW,
			title: t(ClientPlanTypeInfo[ClientPlanTypes.GROW].title),
			subtitle: t('accountPlan.growth.subTitle'),
			icon: ClientPlanTypeInfo[ClientPlanTypes.GROW].icon
		},
		{
			id: ClientPlanTypes.KEEP,
			title: t(ClientPlanTypeInfo[ClientPlanTypes.KEEP].title),
			subtitle: t('accountPlan.keep.subTitle'),
			icon: ClientPlanTypeInfo[ClientPlanTypes.KEEP].icon
		},
		{
			id: ClientPlanTypes.RISK,
			title: t(ClientPlanTypeInfo[ClientPlanTypes.RISK].title),
			subtitle: t('accountPlan.risk.subTitle'),
			icon: ClientPlanTypeInfo[ClientPlanTypes.RISK].icon
		}
	];

	const initialState = {
		buyNowReason: plan?.buyNowReason ?? '',
		date: plan?.date ?? undefined,
		meetingNeeds: plan?.meetingNeeds ?? '',
		type: plan?.type ?? undefined,
		description: plan?.description ?? '',
		clientNeeds: plan?.clientNeeds ?? '',
		value: plan?.value ?? 0,
		contacts: plan?.contacts ?? [],
		users: plan?.users ?? []
	};

	const validatonModel: { [field: string]: ValidationModel } = {
		comingBuyingPotential: FieldModel.string('accountPlan.comingBuyingPotential'),
		meetingNeeds: FieldModel.string('accountPlan.meetingNeeds'),
		date: FieldModel.date('accountPlan.executeBy').required(),
		description: FieldModel.string('accountPlan.description').max(128).required(),
		needs: FieldModel.string('accountPlan.whatAreTheirNeeds'),
		value: FieldModel.number('accountPlan.expectedValue'),
		type: FieldModel.string('accountPlan').required(),
		contacts: FieldModel.listOf(
			'default.select',
			FieldModel.object('default.user', {
				value: FieldModel.number('default.id'),
				label: FieldModel.string('default.name')
			})
		),
		users: FieldModel.listOf(
			'default.select',
			FieldModel.object('default.user', {
				value: FieldModel.number('default.id'),
				label: FieldModel.string('default.name')
			})
		)
	};

	const save = (v: State) => {
		const newPlan = { ...v, clientId, id: plan?.id };
		setSaving(true);
		return ClientPlan.save(newPlan)
			.then(({ data }) => {
				accountPlanTracker.track(isEdit ? EDITED : CREATED, { cameFrom, type: data.type, date: data.date });
				onChange?.(data);
				Tools.$rootScope.$broadcast(isEdit ? 'clientPlan.updated' : 'clientPlan.added', data);

				close();
			})
			.catch(e => {
				logError(e, 'Failed to save account plan');
				setSaving(false);
			});
	};

	const markAs = (status: PlanStatus, values: State) => {
		accountPlanTracker.track(status === PlanStatus.COMPLETED ? COMPLETED : NOT_COMPLETED, {
			cameFrom,
			type: plan?.type,
			date: plan?.date
		});

		const newPlan = { ...values, status, clientId, id: plan?.id };
		return ClientPlan.save(newPlan)
			.then(({ data }) => {
				onStatusChanged?.(data);
				Tools.$rootScope.$broadcast('clientPlan.statusChanged', data);
				Tools.NotificationService.addNotification({
					icon: 'book',
					style: Tools.NotificationService.style.SUCCESS,
					title: t('accountPlan.changedStatusToValue'),
					body: statusMap[status]
				});
				close();
			})
			.catch(e => logError(e, 'Failed to save account plan'));
	};

	const removeContactFromList = (id: number, values: Contact[]) => values.filter(val => id !== val.id);

	const client = { name: clientName, id: clientId };
	const source = { id: plan?.id, type: TYPES.CLIENT_PLAN };
	const activitiesModalInfo = { clientId: clientId };
	const hasTodoList = useSoftDeployAccess('TODO_LIST');
	const hasRemoveActivities = useSoftDeployAccess('REMOVE_ACTIVITIES');

	const relatedEntityOptions = [
		{
			title: t('default.activity'),
			type: 'activity',
			show: useFeatureAvailable(Feature.ACTIVITIES_AND_APPOINTMENTS) && !(hasRemoveActivities && hasTodoList),
			icon: 'activity' as const,
			onclick: () => {
				accountPlanTracker.track(CREATE_ACTIVITY, { cameFrom, type: plan?.type, date: plan?.date });
				Tools.$upModal.open('editActivity', {
					activity: {
						client,
						sourceType: source.type,
						sourceId: source.id
					}
				});
			}
		},
		{
			title: t('todo.createTodo'),
			type: 'todo',
			show: hasTodoList,
			icon: 'todo' as const,
			onclick: () => {
				accountPlanTracker.track(CREATE_TODO, { cameFrom, type: plan?.type, date: plan?.date });
				openDrawer('CreateTodo', {
					client
				});
			}
		},
		{
			title: t('todo.planACall'),
			type: 'phonecall',
			show: hasTodoList,
			icon: 'phone' as const,
			onclick: () => {
				accountPlanTracker.track(CREATE_PHONE_CALL, { cameFrom, type: plan?.type, date: plan?.date });
				openDrawer('CreateCall', { client, source });
			}
		},
		{
			title: t('todo.bookAppointment'),
			type: 'appointment',
			show: useFeatureAvailable(Feature.ACTIVITIES_AND_APPOINTMENTS),
			icon: 'calendar' as const,
			onclick: () => {
				accountPlanTracker.track(CREATE_MEETING, { cameFrom, type: plan?.type, date: plan?.date });
				openEditAppointment({
					appointment: { client, sourceId: source.id, sourceType: source.type }
				});
			}
		},
		{
			title: t('default.opportunity'),
			type: 'opportunity',
			show: useFeatureAvailable(Feature.PIPELINE),
			icon: 'opportunity' as const,
			onclick: () => {
				accountPlanTracker.track(CREATE_OPPORTUNITY, { cameFrom, type: plan?.type, date: plan?.date });
				Tools.$upModal.open('editOrder', { ...activitiesModalInfo, source, type: 'opportunity' });
			}
		},
		{
			title: t('default.order'),
			type: 'order',
			show: useFeatureAvailable(Feature.ORDERS),
			icon: 'dollar' as const,
			onclick: () => {
				accountPlanTracker.track(CREATE_ORDER, { cameFrom, type: plan?.type, date: plan?.date });
				Tools.$upModal.open('editOrder', { ...activitiesModalInfo, source, type: 'order' });
			}
		}
	];

	useEffect(() => {
		(function () {
			const bodyElement = document.getElementsByClassName('EditAccountPlan__body')[0];
			setAnchor(bodyElement); // need this to position the dropdowns
		})();
	}, []);

	return (
		<FormObserver<State> initialValues={initialState} model={validatonModel} validateOnMount onSubmit={save}>
			{({ onFormChange, values, submit, isValid, errorMessages }) => (
				<>
					<DrawerHeader
						onHide={() => {
							if (isEdit) {
								accountPlanTracker.track(CANCELED, { cameFrom, type: plan.type, date: plan.date });
							}
							close();
						}}
					>
						<Column className={classes.elem('header').b()}>
							<div className={classes.elem('header').elem('leftSide').b()}>
								<Icon name="book" />
								<div className={classes.elem('header').elem('leftSide').elem('title').b()}>
									<Title>{t('accountPlan')}</Title>
									<EllipsisTooltip title={clientName}>
										<Text>{clientName}</Text>
									</EllipsisTooltip>
								</div>
								{isEdit && userEditable && !isClosed ? (
									<EntitiesDropdown
										icon="plus"
										tooltipTitle={t('todo.createNew')}
										options={relatedEntityOptions}
									/>
								) : null}
							</div>
							<Tooltip disabled={userEditable} title={t('noEditRights.accountPlan')}>
								<Tooltip
									position="top"
									title={Object.values(errorMessages)
										.filter(vl => !!vl)
										.join('\n')}
									disabled={isValid}
								>
									<EditorHeaderButton
										title={t('default.save')}
										supertitle={undefined}
										onClick={submit}
										next
										icon="check"
										className={undefined}
										noIcon={false}
										loading={saving}
										disabled={!isValid || !userEditable || isClosed}
									></EditorHeaderButton>
								</Tooltip>
							</Tooltip>
						</Column>
					</DrawerHeader>
					<div className={classes.elem('body').b()}>
						<FormComponent required label={t('accountPlan')}>
							<Select<OptionType>
								options={options}
								value={options.find(o => o.id === values.type) || null}
								onChange={value => onFormChange('type', value.id)}
								placeholder={t('default.select')}
								anchor={anchor}
								disabled={!userEditable || isClosed}
								className={classes
									.elem('body')
									.elem('planType')
									.mod({ disabled: !userEditable || isClosed })
									.b()}
								renderItem={item => (
									<div className={classes.elem('selectOption').b()}>
										<Icon name={item.icon} />
										<div>
											<Text>{item.title}</Text>
											<Text size="sm" color="grey-10" italic>
												{item.subtitle}
											</Text>
										</div>
									</div>
								)}
							/>
						</FormComponent>
						<SlideFade visible={!!values.type} delayInMs={200}>
							<div>
								<FormComponent
									label={t('accountPlan.description')}
									required
									value={values.description}
									maxLength={128}
								>
									<Input
										placeholder={t('accountPlan.summaryOfPlan')}
										disabled={!userEditable || isClosed}
										onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
											onFormChange('description', e.target.value);
										}}
										value={values.description}
										maxLength={128}
									/>
								</FormComponent>
								<FormComponent label={t('accountPlan.expectedValue')}>
									<NumberInput
										onChange={e => onFormChange('value', e ?? 0)}
										currency={masterCurrency.iso}
										value={values.value}
										disabled={!userEditable || isClosed}
									/>
								</FormComponent>
								<FormComponent label={t('accountPlan.executeBy')} required>
									<DateInput
										onChange={e => onFormChange('date', e.target.value)}
										closeOnSelect
										value={values.date}
										disabled={!userEditable || isClosed}
									/>
								</FormComponent>
								<FormComponent label={t('contacts')}>
									<SelectAsync
										anchor={anchor}
										multi
										value={values.contacts?.map(c => ({ ...c, title: c.name })) ?? []}
										fetchOnMount
										fetcher={fetchContacts(clientId)}
										placeholder={t('default.select')}
										disabled={!userEditable || isClosed}
										onChange={selectedItem => {
											const { contacts } = values;
											if (contacts?.find(stakeholder => stakeholder.id === selectedItem.id)) {
												onFormChange(
													'contacts',
													removeContactFromList(selectedItem.id, contacts)
												);
											} else {
												onFormChange('contacts', [...contacts, selectedItem]);
											}
										}}
										onClear={() => {
											onFormChange('contacts', []);
										}}
										onRemove={id => {
											onFormChange('contacts', removeContactFromList(+id, values.contacts));
										}}
									/>
								</FormComponent>
								<SlideFade
									visible={showInternalStakeholders || values.users?.length > 0}
									delayInMs={200}
									height={!showInternalStakeholders && (!values.users || values.users.length === 0)}
									complete
								>
									<FormComponent
										label={t('default.users')}
										className={classes.elem('body').elem('internalStakeholders').b()}
									>
										<StaticUserMultiSelect
											anchor={anchor}
											value={
												values.users
													?.filter(us => mappedUsers[us.id])
													.map(internal => {
														return {
															...internal,
															title: mappedUsers[internal.id],
															name: mappedUsers[internal.id]
														};
													}) ?? []
											}
											placeholder={t('default.select')}
											onChange={value => {
												onFormChange('users', value);
											}}
											disabled={!userEditable || isClosed}
										/>
									</FormComponent>
								</SlideFade>
								<SlideFade
									direction="top"
									visible={!showInternalStakeholders && (!values.users || values.users.length === 0)}
									height
									complete
								>
									<div>
										<Button
											className={classes.elem('body').elem('addInternalStakeholders').b()}
											onClick={() => setShowInternalStakeholders(true)}
											type="link"
											disabled={!userEditable || isClosed}
										>
											<Icon name="plus" />
											{t('accountPlan.addInternalStakeholders')}
										</Button>
									</div>
								</SlideFade>
								<FormComponent label={t('accountPlan.whatAreTheirNeeds')}>
									<Textarea
										placeholder={t('accountPlan.haveWeTriedToResolve')}
										onChange={e => onFormChange('clientNeeds', e.target.value)}
										disabled={!userEditable || isClosed}
										value={values.clientNeeds}
									/>
								</FormComponent>
								<FormComponent label={t('accountPlan.howCanWeMeetTheirNeeds')}>
									<Textarea
										placeholder={t('accountPlan.whatCanWeOffer')}
										onChange={e => onFormChange('meetingNeeds', e.target.value)}
										value={values.meetingNeeds}
										disabled={!userEditable || isClosed}
									/>
								</FormComponent>
								<FormComponent label={t('accountPlan.comingBuyingPotential')}>
									<Textarea
										placeholder={t('accountPlan.insteadOfChurning')}
										onChange={e => onFormChange('buyNowReason', e.target.value)}
										value={values.buyNowReason}
										disabled={!userEditable || isClosed}
									/>
								</FormComponent>
								{isEdit && userEditable ? (
									<div>
										{!plan.status ? (
											<div className={classes.elem('body').elem('actions').b()}>
												<DefaultButton
													disabled={!isValid}
													onClick={() => {
														markAs(PlanStatus.COMPLETED, values);
													}}
												>
													<Icon name="check" space="mrs" />
													{t('accountPlan.markAsComplete')}
												</DefaultButton>
												<DefaultButton
													disabled={!isValid}
													onClick={() => {
														markAs(PlanStatus.NOT_COMPLETED, values);
													}}
												>
													<Icon name="times" space="mrs" />
													{t('accountPlan.notCompleted')}
												</DefaultButton>
											</div>
										) : null}

										<ClientPlanHistoryLog planId={plan.id as number} />
									</div>
								) : null}
							</div>
						</SlideFade>
					</div>
				</>
			)}
		</FormObserver>
	);
};

export default EditAccountPlan;
