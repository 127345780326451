import CustomerPortfolioPotentialResource from 'Resources/CustomerPortfolioPotential';
import CustomerPortfolioCompanyResource from 'App/resources/CustomerPortfolioCompany';
import { CancelablePromise, makeCancelable } from 'App/babel/helpers/promise';
import { DisplayValueType, ListViewFilterMap } from './CustomerPortfolio';
import { filterConfigs as stageFilterConfigs } from './StageFilter';
import { FilterConfig } from 'App/babel/filterConfigs/FilterConfig';
import { filterConfigs as userFilterConfigs } from './UserFilter';
import { parseFilters } from 'App/helpers/filterHelper';
import RequestBuilder from 'Resources/RequestBuilder';
import { useEffect, useState, useRef } from 'react';
import logError from 'Helpers/logError';

type FilterConfigMap = {
	[key: string]: FilterConfig;
};

const allFilterConfigs: FilterConfigMap = {
	...userFilterConfigs,
	...stageFilterConfigs
};

export const parseFiltersForRequest = (filters: ListViewFilterMap, rb: RequestBuilder | null = null) => {
	const getConfig = (filterName: string): FilterConfig => allFilterConfigs[filterName];
	return parseFilters(filters, 'account', rb, null, { getConfig });
};

export const getCustomerTableData = (
	rb: RequestBuilder,
	displayValueType: DisplayValueType,
	includeLostCustomers = false,
	aggregateSubAccounts = false
) => {
	rb.addExtraParam('includeLostCustomers', includeLostCustomers);
	rb.addExtraParam('valueType', displayValueType);
	rb.addExtraParam('aggregateSubAccounts', aggregateSubAccounts);
	return makeCancelable(CustomerPortfolioCompanyResource.find(rb.build()));
};

export const useFetchTabTotals = (displayValueType: DisplayValueType, filters: ListViewFilterMap) => {
	const [customerAmount, setCustomerAmount] = useState(0);
	const [riskCustomerAmount, setriskCustomerAmount] = useState(0);
	const [upsellAmount, setUpsellAmount] = useState(0);
	const customerPromise = useRef<CancelablePromise | null>(null);
	const riskPromise = useRef<CancelablePromise | null>(null);
	const potentialPromise = useRef<CancelablePromise | null>(null);

	useEffect(() => {
		// So we prevent arequest race conditions between faster and slower requests
		customerPromise.current?.cancel();
		riskPromise.current?.cancel();
		potentialPromise.current?.cancel();

		const customerRB = parseFiltersForRequest(filters);
		customerRB.limit = 0;

		customerPromise.current = getCustomerTableData(customerRB, displayValueType);
		customerPromise.current.promise
			.then(({ metadata }) => setCustomerAmount(metadata.total))
			.catch(error => logError(error));

		if (Tools.FeatureHelper.hasSoftDeployAccess('CUSTOMER_PORTFOLIO_RISK')) {
			const riskRB = parseFiltersForRequest(filters);
			riskRB.addFilter({ field: 'prospectingCreditRating' }, riskRB.comparisonTypes.Equals, ['E', 'F']);
			riskRB.limit = 0;

			riskPromise.current = getCustomerTableData(riskRB, displayValueType);
			riskPromise.current.promise
				.then(({ metadata }) => setriskCustomerAmount(metadata.total))
				.catch(error => logError(error));
		}

		if (Tools.FeatureHelper.hasSoftDeployAccess('CUSTOMER_PORTFOLIO_POTENTIAL')) {
			const rb = new RequestBuilder();
			const upsellRB = parseFiltersForRequest(filters, rb);

			upsellRB.limit = 0;
			upsellRB.addExtraParam('valueType', displayValueType);
			upsellRB.addExtraParam('aggregateSubAccounts', true);
			potentialPromise.current = makeCancelable(CustomerPortfolioPotentialResource.find(upsellRB.build()));
			potentialPromise.current.promise
				.then(({ metadata }) => {
					setUpsellAmount(metadata.total);
				})
				.catch(error => logError(error));
		}

		return () => {
			customerPromise.current?.cancel();
			riskPromise.current?.cancel();
			potentialPromise.current?.cancel();
		};
	}, [displayValueType, filters]);

	return { customerAmount, riskCustomerAmount, upsellAmount };
};
