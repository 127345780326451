import { calculateField } from '@upsales/common';
import { getPlainObject } from './useDraftableStateStore';
import { useEffect } from 'react';

import type { EditOrderDraftableStateStore } from '../types';
import type { Actions } from './useEditOrderLogic';

/**
 * One have to be very careful when adding logic to this hooks.
 * Unlike the angular watch (atleast I think this is how it work) if you have a state
 * that looks like { key: [1, 2, 3] } and do { key: [ ...oldState ] } angular would not trigger the watch as the two states
 * looks the same when running angular.equals.
 * However here it will trigger a new render as key references two different arrays.
 * And with immer you will get a new object for state all the way down to the thing you change if you do things like that.
 */
const useOnOrderChange = (store: EditOrderDraftableStateStore, actions: Actions) => {
	const { state, oldState, getAutoDraftState } = store;

	useEffect(() => {
		const state = getAutoDraftState();

		if (state.hasCalculatingFields) {
			state.order.orderRow.forEach(orderRow => {
				orderRow.custom.forEach(customField => {
					if (customField.datatype === 'Calculation') {
						const newValue = calculateField.calculate(customField.formula, orderRow);

						if (customField.value !== newValue) {
							actions.setCustomFieldValue(orderRow, customField.id, newValue);
						}
					}
				});
			});

			state.order.custom.forEach(customField => {
				if (customField.datatype === 'Calculation') {
					const newValue = calculateField.calculate(customField.formula, state.order);

					if (customField.value !== newValue) {
						actions.setCustomFieldValue(state.order, customField.id, newValue);
					}
				}
			});
		}

		if (state.editOrderListeners?.length && oldState?.order) {
			const order = getPlainObject(state.order);
			actions.callEditOrderListenersIfChanged(order, oldState.order);
		}
	}, [state.order]);
};

export default useOnOrderChange;
