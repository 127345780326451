import useEditOrderContext from '../hooks/useEditOrderContext';
import { useEffect, useRef } from 'react';

import Product from 'App/resources/Model/Product';

type Props = { value: Product | null; name: string; required: true };

// Need this to update the OrderForm when the product changes
const HiddenProductInput = ({ name, required, value }: Props) => {
	const { registerFormComponent, unregisterFormComponent, onFormConponentChange } = useEditOrderContext();
	const lastCommittedValueRef = useRef<Product | null>(value);

	useEffect(() => {
		if (name) {
			const valid = required ? !!value : true;
			registerFormComponent(name, valid);
			return () => unregisterFormComponent(name);
		}
		// Will not add name to the dependency array because then I need something like renameControl
	}, []);

	useEffect(() => {
		if (value !== lastCommittedValueRef.current) {
			if (name) {
				const valid = required ? !!value : true;
				onFormConponentChange(name, valid, true);
			}
		}
	}, [value]);

	// No real point in rendering a hidden input here i guess?
	return null;
};

export default HiddenProductInput;
