import React from 'react';
import useInputController, { options } from '../hooks/useInputController';

import type { InputHTMLAttributes } from 'react';
import type { ModifiedOnChange } from '../types';
import type { Options } from '../hooks/useInputController';

type Props = Omit<InputHTMLAttributes<HTMLInputElement>, 'type' | 'value' | 'onChange'> & {
	decimals?: number;
	type: 'text';
	value: number;
	onChange: ModifiedOnChange<HTMLInputElement, number>;
	controllerOptions?: Partial<Options<number, string>>;
};

const PriceInput = React.forwardRef<HTMLInputElement, Props>(
	({ decimals = 2, onKeyPress: _onKeyPress, controllerOptions, ...props }, ref) => {
		const instanceOptions = controllerOptions ? { ...options.text, ...controllerOptions } : options.text;

		instanceOptions.formatters.push(
			// @ts-expect-error No clue how to type a "pipeline" like this
			function PriceInputFormatter(value: number) {
				// @ts-expect-error parseFloat returns the number if you pass a number
				return parseFloat(parseFloat(value).toFixed(decimals));
			}
		);

		instanceOptions.parsers.push(function PriceInputParser(value) {
			const stringValue = value?.toString() ?? '';
			const negative = stringValue.indexOf('-') === 0;
			let clean = stringValue.replace(/[^0-9.]/g, '');
			const [integerPart, decimalPart] = clean.split('.');

			if (decimalPart !== undefined) {
				clean = `${integerPart}.${decimalPart.slice(0, decimals)}`;
			}

			return negative ? `-${clean}` : clean;
		});

		function onKeyPress(event: React.KeyboardEvent<HTMLInputElement>) {
			if (event.keyCode === 32) {
				event.preventDefault();
			}

			_onKeyPress?.(event);
		}

		const controlledProps = useInputController(props, instanceOptions);

		return <input {...controlledProps} onKeyPress={onKeyPress} ref={ref} />;
	}
);

export default PriceInput;
