import ActivityOutcome from 'App/babel/components/EventActivity';
import ActivityResource from 'Resources/Activity';
import Avatar from 'Components/Avatar';
import Comment from 'Resources/Comment';
import FieldTranslation from 'App/babel/resources/FieldTranslations';
import React, { useMemo } from 'react';
import Tooltip from './Tooltip';
import _ from 'lodash';
import getAngularModule from 'App/babel/angularHelpers/getAngularModule';
import jQuery from 'jquery';
import logError from 'App/babel/helpers/logError';
import moment from 'moment';
import openModal from 'App/services/Modal';
import useDraftableStateStore, { getPlainObject } from '../hooks/useDraftableStateStore';
import {
	ANSWER_ACTIONS,
	FOUND_LEAD_ACTIONS,
	OUTCOME_TYPES,
	ANSWER_ACTIONS_GREAT
} from 'App/babel/enum/activityOutcome';
import { DISQUALIFIED, statusDisqualifiable } from 'Components/Helpers/journeyStep';
import { TYPES } from 'Components/Helpers/SourceHelper';
import { activityOutcomeTracker } from 'App/babel/helpers/Tracker';
import { dateCalendar } from 'App/helpers/DateHelpers';
import { openDrawer } from 'Services/Drawer';
import { openEditAppointment } from 'Components/Modals/Appointment/EditAppointment';
import { openEditOrder } from 'App/components/EditOrder';
import { useTranslation } from 'react-i18next';
// @ts-ignore
import classNames from 'classnames';

import type Activity from 'App/resources/Model/Activity';
import type Contact from 'App/resources/Model/Contact';
import type { ActivityEvent } from '../types';

type TitleCategory = {
	id: number;
	value?: string;
	name?: string;
	tagId?: number;
	usedCount: number | null;
	type: string;
};

type ActivityWithContact = Activity & {
	contact: Contact;
	requiredFieldsContact: {
		[key: string]: boolean;
	};
	titleCategories: TitleCategory[];
};

type State = {
	expanded: boolean;
	loading: boolean;
	isOutcomeEnabled: boolean;
	activity: ActivityWithContact;
	company?: ActivityWithContact['client'];
};

type Props = {
	event: ActivityEvent;
};

function editOpportunity(event: React.MouseEvent<HTMLAnchorElement>, customerId: number, opportunityId: number) {
	event.stopPropagation();

	const options = {
		customerId,
		type: 'opportunity',
		id: opportunityId
	};

	openEditOrder(options);
}

function goToContact(event: React.MouseEvent<HTMLAnchorElement>, customerId: number, contactId: number) {
	event.stopPropagation();

	const $state = getAngularModule('$state');
	$state.go('contact.dashboard', {
		customerId,
		id: contactId
	});
}

function goToCampaign(event: React.MouseEvent<HTMLAnchorElement>, customerId: number, projectId: number) {
	event.stopPropagation();

	const $state = getAngularModule('$state');
	$state.go('campaign.dashboard', {
		customerId,
		id: projectId
	});
}

function tracker(outcome: Activity['outcome']) {
	if (!outcome) {
		return;
	} else if (typeof outcome === 'object') {
		let datePreset: string | undefined = 'no_postpone';

		if (outcome.type === 'postpone' || outcome.outcome === 'postponed') {
			datePreset = outcome.date;
		}

		activityOutcomeTracker.track(activityOutcomeTracker.events.CREATE_EVENT, {
			location: 'timeline',
			type: outcome.type,
			outcome: outcome.outcome,
			date_preset: datePreset
		});
	}
}

function getClosedText(event: ActivityEvent, isPhoneCall: boolean) {
	if (isPhoneCall) {
		return event.contacts?.length
			? event.activity?.outcomeType === OUTCOME_TYPES.NO_ANSWER
				? 'activity.outcomeLog.didntReached'
				: 'activity.outcomeLog.reached'
			: '';
	} else {
		return 'default.phonecall';
	}
}

function getCreatedText(isPhoneCall: boolean, isTodo: boolean, isPassed: boolean) {
	if (isPhoneCall) {
		return isPassed ? 'default.shouldHaveCompletedPhonecall' : 'default.hasPlannedPhonecall';
	} else if (isTodo) {
		return isPassed ? 'default.shouldHaveCompletedTodo' : 'default.hasPlannedTodo';
	} else {
		return isPassed ? 'default.shouldHaveCompletedActivity' : 'default.hasPlannedActivity';
	}
}

/**
 * @deprecated
 * This should not be used!
 * Just ported this so I can be certain we get a 1:1 port the Angular edit order modal.
 */
const EventActivity = ({ event }: Props) => {
	const { t } = useTranslation();
	const { state, getState, getAutoDraftState } = useDraftableStateStore<State>({
		expanded: false,
		loading: false,
		isOutcomeEnabled: false,
		// @ts-ignore
		activity: {}
	});

	const {
		activityTypeName,
		activityTypes,
		customerId,
		hasActivityOutcomeAccess,
		hasPriority,
		isPassed,
		isPhoneCall,
		isTodo,
		metadata,
		outcomeContactActions,
		self,
		todoTypes,

		$translate,
		$upModal,
		Account,
		Activity,
		Appointment,
		Contact,
		FeatureHelper
	} = useMemo(() => {
		const AppService = getAngularModule('AppService');
		const FeatureHelper = getAngularModule('FeatureHelper');
		const $upModal = getAngularModule('$upModal');
		const Activity = getAngularModule('Activity');
		const Contact = getAngularModule('Contact');
		const Appointment = getAngularModule('Appointment');
		const Account = getAngularModule('Account');
		const $translate = getAngularModule('$translate');

		const customerId = AppService.getCustomerId();
		const metadata = AppService.getMetadata();
		const hasPriority =
			// @ts-expect-error
			FeatureHelper.hasSoftDeployAccess('ACTIVITY_PRIORITIZATION') && event.activity.hasHighPriority;
		const hasActivityOutcomeAccess = FeatureHelper.isAvailable(FeatureHelper.Feature.ACTIVITY_OUTCOMES);
		const outcomeContactActions = [ANSWER_ACTIONS.TALK_TO_SOMEONE, FOUND_LEAD_ACTIONS.ADDED_CONTACT];
		const isPassed = moment(event.date).endOf('day').isBefore() ? true : false;
		const todoTypes = AppService.getTodoTypes();
		const activityTypeId = event.activity?.activityType?.id;
		const isTodo = todoTypes?.TODO && activityTypeId === todoTypes.TODO.id ? true : false;
		const isPhoneCall = todoTypes?.PHONE_CALL && activityTypeId === todoTypes.PHONE_CALL.id ? true : false;
		const activityTypes = AppService.getActivityTypes('activity', true);
		const activityTypeName = isPhoneCall ? t('default.phonecall') : event.activity?.activityType?.name ?? '';
		const self = AppService.getSelf();

		return {
			activityTypeName,
			activityTypes,
			customerId,
			hasActivityOutcomeAccess,
			hasPriority,
			isPassed,
			isPhoneCall,
			isTodo,
			metadata,
			outcomeContactActions,
			self,
			todoTypes,

			$translate,
			$upModal,
			Account,
			Activity,
			AppService,
			Appointment,
			Contact,
			FeatureHelper
		};
	}, []);

	function expand() {
		const state = getAutoDraftState();

		if (!isTodo) {
			if (state.expanded || state.loading) {
				state.expanded = false;
			} else if (state.activity.id) {
				state.expanded = true;
			} else {
				state.loading = true;

				Activity.customer(customerId)
					.get(event.entityId)
					.then(response => {
						const state = getAutoDraftState();

						const activity = response.data as ActivityWithContact;

						if (activity) {
							activity.requiredFieldsContact = metadata.requiredFields.Contact;

							if (activity.activityType) {
								const activitysActivitytype = [...activityTypes, ...Object.values(todoTypes)].find(
									({ id }) => id === activity.activityType.id
								);
								const isOutcomeEnabled = activitysActivitytype?.hasOutcome ?? false;
								state.isOutcomeEnabled = isOutcomeEnabled;
							}

							state.activity = activity;
							state.expanded = true;

							if (activity.contacts?.length) {
								getContact(activity.contacts[0].id);
							}

							getTitleCategories();
						}

						state.loading = false;
					})
					.catch(logError);
			}
		}
	}

	function openActivity() {
		if (isTodo) {
			openDrawer('EditTodo', { todo: { id: event.entityId } });
		} else if (isPhoneCall) {
			openDrawer('EditPhonecall', {
				activity: {
					// @ts-expect-error
					id: event.entityId,
					...event.activity,
					client: event.client,
					// @ts-expect-error
					contacts: event.contacts
				}
			});
		} else {
			$upModal.open('editActivity', { id: event.entityId });
		}
	}

	function openActivityAndStopPropagation(event: React.MouseEvent<HTMLAnchorElement>) {
		event.stopPropagation();

		openActivity();
	}

	async function createCommentIfExists(commentDescription: string) {
		const state = getState();

		if (commentDescription) {
			const activity = getPlainObject(state.activity);

			const { data: comment } = await Comment.save({
				description: commentDescription,
				client: activity.contact?.client || activity.client,
				activity: activity,
				user: self,
				outcomeType: typeof activity.outcome === 'object' ? activity.outcome.type : undefined
			});

			return comment;
		} else {
			return undefined;
		}
	}

	function getContact(contactId: number) {
		Contact.customer(contactId)
			.get(contactId)
			.then(response => {
				const state = getAutoDraftState();

				state.activity.contact = response.data;
			})
			.catch(logError);
	}

	function showMoreOptIns() {
		const state = getState();

		const data = _.map(state.activity.contact.optins!, optin => {
			// @ts-expect-error
			optin.date = moment(optin.regDate).format('L LT');
			return getPlainObject(optin);
		});

		const params = {
			title: 'default.optIns',
			data,
			columns: [
				{ title: 'default.name', value: 'title' },
				{ title: 'default.regDate', value: 'date' }
			]
		};

		if (FeatureHelper.hasSoftDeployAccess('REACT_LIST')) {
			openModal('ListModal', params);
		} else {
			$upModal.open('list', params);
		}
	}

	function saveActivityContact(contact: Contact) {
		const state = getAutoDraftState();

		if (contact !== null) {
			state.activity.contacts = [contact];
			getContact(state.activity.contacts[0].id);
		} else {
			// @ts-ignore
			state.activity.contacts = state.activity.contact = null;
		}

		if (hasActivityOutcomeAccess) {
			const outcome = state.activity.outcome;

			// @ts-ignore
			if (outcome && !outcomeContactActions.includes(outcome.outcome)) {
				// @ts-ignore
				state.activity.outcome = null;
			}
		}

		const activity = getPlainObject(state.activity);
		Activity.customer(customerId).save(activity).catch(logError);
	}

	function onCreateContact() {
		const state = getState();

		const account = getPlainObject(state.activity.client);
		$upModal
			.open('editContact', { customerId, account })
			.then(contact => {
				if (contact.active) {
					saveActivityContact(contact);
				}
			})
			.catch(logError);
	}

	function editContact() {
		const state = getState();

		const params = {
			contact: _.cloneDeep(state.activity.contact),
			customerId: customerId
		};

		$upModal
			.open('editContact', params)
			.then(contact => {
				const state = getAutoDraftState();

				state.activity.contact = contact;
			})
			.catch(logError);
	}

	function setJourneyStep(journeyStep: string) {
		const state = getState();

		return Contact.customer(customerId)
			.save({
				id: state.activity.contact.id,
				journeyStep: journeyStep
			})
			.then(res => {
				const state = getAutoDraftState();

				state.activity.contact.journeyStep = res.data.journeyStep;
			})
			.catch(logError);
	}

	function getTitleCategories() {
		FieldTranslation.find({ type: 'titlecategory' })
			.then(response => {
				const state = getAutoDraftState();

				if (response.data) {
					const titleCategories: TitleCategory[] = [];

					response.data.forEach((data: TitleCategory, key: number) => {
						const category = {
							id: key,
							name: data.value,
							tagId: data.tagId,
							type: data.type,
							usedCount: data.usedCount
						};

						titleCategories.push(category);
					});

					titleCategories.push({
						id: titleCategories.length + 1,
						name: t('default.removeTitle'),
						type: 'remove',
						usedCount: null
					});

					state.activity.titleCategories = titleCategories;
				}
			})
			.catch(logError);
	}

	function updateContact(value: string, type: string) {
		const state = getAutoDraftState();

		if (type === 'titleCategory') {
			const selectedCategory = state.activity.titleCategories?.filter(category => {
				if (value === category.name) {
					return {
						tagId: category.tagId,
						type: category.type,
						usedCount: category.usedCount,
						value: category.name
					};
				}
				return false;
			});
			// @ts-expect-error
			state.activity.contact.titleCategory = selectedCategory;
		} else if (type === 'title') {
			state.activity.contact.title = value;
		} else if (type === 'cellphone') {
			state.activity.contact.cellPhone = value;
		} else if (type === 'phone') {
			state.activity.contact.phone = value;
		} else if (type === 'email') {
			state.activity.contact.email = value;
		}

		const contactToSave = getPlainObject(state.activity);
		Contact.customer(customerId)
			.save(contactToSave)
			.then(response => {
				const state = getAutoDraftState();

				state.activity.contact = response.data;
			})
			.catch(logError);
	}

	function updateNotes(notes: string) {
		const state = getAutoDraftState();

		state.activity.notes = notes;

		Activity.customer(customerId).save({ id: state.activity.id, notes }).catch(logError);
	}

	async function createActivityFollowUp(isAppointment: boolean) {
		const state = getAutoDraftState();

		state.activity.closeDate = moment.utc().format();

		const activity = getPlainObject(state.activity);

		// Build init-data and open modal
		let item;

		if (isAppointment) {
			item = Appointment.new().data;
			item.activityType = null;
		} else {
			item = Activity.new().data;
			item.activityType = activity.activityType;
		}
		item.notes = activity.notes;
		item.client = activity.client;
		item.project = activity.project;
		item.description = activity.description;
		item.users = [_.pick(self, ['id', 'name', 'email'])];

		if (isAppointment) {
			item.contacts = activity ? [activity] : null;

			// eslint-disable-next-line promise/catch-or-return
			openEditAppointment({ appointment: item }).then(() => {
				const state = getAutoDraftState();

				state.expanded = false;

				const activity = getPlainObject(state.activity);
				return Activity.customer(customerId)
					.save(activity)
					.catch(logError)
					.finally(() => {
						const state = getAutoDraftState();

						const outcome =
							typeof state.activity.outcome === 'object'
								? getPlainObject(state.activity.outcome)
								: state.activity.outcome;
						tracker(outcome);
					});
			});
		} else {
			item.contacts = activity.contacts;

			if (FeatureHelper.hasSoftDeployAccess('TODO_LIST') && item.activityType?.name === 'Phonecall') {
				await ActivityResource.save(item)
					.then(response => {
						const state = getAutoDraftState();

						state.expanded = false;

						if (response) {
							openDrawer('EditPhonecall', { activity: response.data });

							const activity = getPlainObject(state.activity);
							Activity.customer(customerId).save(activity).catch(logError);
						}
					})
					.catch(logError);
			} else {
				state.expanded = false;

				// eslint-disable-next-line promise/catch-or-return
				$upModal.open('editActivity', { activity: item }).then(() => {
					const state = getState();

					const activity = getPlainObject(state.activity);
					Activity.customer(customerId).save(activity).catch(logError);
				});
			}
		}
	}

	function createCallFollowUp() {
		const state = getAutoDraftState();

		state.activity.closeDate = moment.utc().format();
		state.expanded = false;

		const activity = getPlainObject(state.activity);
		openDrawer('CreateCall', {
			initialDescription: activity.description,
			client: activity.client,
			contact: activity.contact,
			notes: activity.notes,
			activity: activity,
			source: { id: activity.id, type: TYPES.ACTIVITY },
			onSave: () => {
				Activity.customer(customerId).save(activity).catch(logError);
			}
		});
	}

	function createTodoFollowUp() {
		const state = getAutoDraftState();

		state.activity.closeDate = moment.utc().format();
		state.expanded = false;

		const activity = getPlainObject(state.activity);
		openDrawer('CreateTodo', {
			description: activity.description,
			client: activity.client,
			contact: activity.contact,
			notes: activity.notes,
			activity: activity,
			source: { id: activity.id, type: TYPES.ACTIVITY },
			onSave: () => {
				Activity.customer(customerId).save(activity).catch(logError);
			}
		});
	}

	function bookAppointment(commentDescription: string) {
		const state = getAutoDraftState();

		state.activity.closeDate = moment.utc().format();

		const activity = getPlainObject(state.activity);
		const appointment = Appointment.new().data;
		appointment.notes = activity.notes;
		appointment.client = activity.client;
		appointment.project = activity.project;
		appointment.users = [_.pick(self, ['id', 'name', 'email'])];
		appointment.activityType = null;
		appointment.contacts = activity.contact ? [activity.contact] : null;

		// eslint-disable-next-line promise/catch-or-return
		openEditAppointment({ appointment }).then(async (appointment = {}) => {
			createCommentIfExists(commentDescription)
				.then(comment => {
					const state = getAutoDraftState();

					setOutcome({
						type: OUTCOME_TYPES.ANSWER,
						outcome: ANSWER_ACTIONS_GREAT.BOOK_APPOINTMENT,
						appointmentId: appointment?.id,
						...(comment ? { commentId: comment.id } : {})
					});

					state.expanded = false;

					const activity = getPlainObject(state.activity);
					return Activity.customer(customerId).save(activity);
				})
				.catch(logError);
		});
	}

	function addTime(time: string | Date) {
		const state = getAutoDraftState();

		if (typeof time === 'string') {
			time = new Date(time);
		}

		if (typeof state.activity.date === 'string') {
			state.activity.date = new Date(state.activity.date);
		}

		// @ts-ignore
		state.activity.time = time;

		if (time) {
			state.activity.date!.setHours(time.getHours(), time.getMinutes(), 0, 0);
		}

		// @ts-ignore
		activity.showTime = !!time;
	}

	function updateTime(time: string | Date) {
		const state = getAutoDraftState();

		addTime(time);

		if (time) {
			time = moment(time).format('HH:mm:ss');
		}

		state.activity.time = time;
	}

	function addDate(date: Date) {
		const state = getAutoDraftState();

		state.activity.date = date;

		if (state.activity.time && typeof state.activity.time !== 'string') {
			// @ts-ignore
			activity.date.setHours(activity.time.getHours());
			// @ts-ignore
			activity.date.setMinutes(activity.time.getMinutes());
		}
	}

	function presetDateChange(date: Date) {
		const state = getAutoDraftState();

		state.activity.time = null;
		addDate(date);
	}

	function setOutcome(outcome: object, cb = () => {}) {
		const state = getAutoDraftState();

		const dateTimeStr = moment.utc().format();
		const clientJourney = state.activity.client?.journeyStep;
		// @ts-ignore
		state.activity.outcome = { ...outcome, date: dateTimeStr, clientJourney };

		cb();
	}

	function onSetJourneyStepClient(journeyStep: string) {
		const state = getState();

		const account = state.activity.client;

		if (!account) {
			return Promise.resolve();
		}

		return Account.customer(customerId)
			.save({ id: account.id, journeyStep })
			.then(res => {
				const state = getAutoDraftState();

				state.activity.client!.journeyStep = res.data.journeyStep;
				state.company = _.cloneDeep(state.activity.client);
			})
			.catch(logError);
	}

	function setDisqualifyToClient() {
		const state = getState();

		const journeyStep = state.activity.client?.journeyStep;
		// @ts-expect-error
		const disqualify = state.activity.outcome?.disqualify;

		if (disqualify && journeyStep && statusDisqualifiable(journeyStep)) {
			onSetJourneyStepClient(DISQUALIFIED);
		}
	}

	function confirm() {
		const state = getAutoDraftState();

		if (hasActivityOutcomeAccess && state.activity.outcome) {
			setDisqualifyToClient();

			// @ts-expect-error
			const shouldClose = state.activity.outcome?.closeActivity ?? false;

			if (shouldClose) {
				state.activity.closeDate = new Date();
			}
		}

		state.expanded = false;

		const activity = getPlainObject(state.activity);
		Activity.customer(customerId)
			.save({
				id: activity.id,
				closeDate: activity.closeDate,
				outcome: activity.outcome,
				date: activity.date,
				time: activity.time
			})
			.then(() => {
				const state = getState();

				const outcome =
					typeof state.activity.outcome === 'object'
						? getPlainObject(state.activity.outcome)
						: state.activity.outcome;
				tracker(outcome);
			})
			.catch(logError);
	}

	function openContactDropdown() {
		addNewContactButton();
		jQuery('#contact-select').select2('open');
	}

	function addNewContactButton() {
		if (hasActivityOutcomeAccess) {
			setTimeout(() => {
				const select2Drop = jQuery('.select2-with-searchbox');
				const newBtn = jQuery('.add-new-contact-activity');
				const addNewContactBtn = jQuery(
					'<div class="add-new-contact-activity Button up-btn btn-bright-blue btn-link btn-block"/>'
				).text($translate('default.createAContact'));

				if (newBtn.length !== 0) {
					jQuery(newBtn).remove();
					select2Drop.append(addNewContactBtn);
				} else if (newBtn.length === 0) {
					select2Drop.append(addNewContactBtn);
				}

				select2Drop.on('click', '.add-new-contact-activity', () => {
					// @ts-expect-error
					jQuery('#contact-select').select2('close');
					onCreateContact();
				});
			}, 100);
		}
	}

	function createOrderAndSave() {
		const state = getAutoDraftState();

		state.activity.closeDate = moment.utc().format();
		state.expanded = false;

		const activity = getPlainObject(state.activity);
		const options = {
			customerId: customerId,
			clientId: activity.client ? activity.client.id : null,
			contactId: activity.contact ? activity.contact.id : null,
			campaign: activity.project ? activity.project : null,
			notes: activity.notes,
			type: 'order',
			resolveOnSave: true
		};

		return openEditOrder(options)
			.then(order => {
				const state = getAutoDraftState();

				state.activity.opportunity = order;
				state.activity.outcome = {
					type: OUTCOME_TYPES.ANSWER,
					outcome: ANSWER_ACTIONS_GREAT.CREATE_ORDER
				};

				const activity = getPlainObject(state.activity);
				return Activity.customer(customerId).save(activity);
			})
			.finally(() => {
				const state = getState();

				const outcome =
					typeof state.activity.outcome === 'object'
						? getPlainObject(state.activity.outcome)
						: state.activity.outcome;
				tracker(outcome);
			})
			.catch(logError);
	}

	function createOpportunity(skipSaveActivity: boolean) {
		const state = getState();

		const activity = getPlainObject(state.activity);
		const options = {
			customerId: customerId,
			clientId: activity.client ? activity.client.id : null,
			contactId: activity.contact ? activity.contact.id : null,
			campaign: activity.project ? activity.project : null,
			notes: activity.notes,
			type: 'opportunity',
			resolveOnSave: true,
			activityId: !skipSaveActivity ? activity.id : null
		};

		return openEditOrder(options).then(opportunity => {
			const state = getAutoDraftState();

			state.activity.opportunity = opportunity;
			state.activity.closeDate = moment.utc().format();
		});
	}

	function createOpportunityAndSave() {
		createOpportunity(true)
			.then(() => {
				const state = getAutoDraftState();

				state.activity.outcome = {
					type: OUTCOME_TYPES.ANSWER,
					outcome: ANSWER_ACTIONS_GREAT.CREATE_OPPORTUNITY
				};

				const activity = getPlainObject(state.activity);
				return Activity.customer(customerId).save(activity);
			})
			.finally(() => {
				const state = getAutoDraftState();

				state.expanded = false;

				const outcome =
					typeof state.activity.outcome === 'object'
						? getPlainObject(state.activity.outcome)
						: state.activity.outcome;
				tracker(outcome);
			})
			.catch(logError);
	}

	function setAppointment(id: number, notes: string) {
		if (!id) {
			return;
		}

		const state = getAutoDraftState();

		state.activity.closeDate = moment.utc().format();
		state.expanded = false;

		const activity = getPlainObject(state.activity);
		Activity.customer(customerId)
			.save(activity)
			.then(() => {
				const state = getState();

				openEditAppointment({ id, appointment: { notes: `${notes}\n${activity.notes}` } });

				const outcome =
					typeof state.activity.outcome === 'object'
						? getPlainObject(state.activity.outcome)
						: state.activity.outcome;
				tracker(outcome);
			})
			.catch(logError);
	}

	async function planPhonecall(commentDescription: string) {
		const state = getAutoDraftState();

		state.activity.closeDate = moment.utc().format();
		state.activity.outcome = {
			type: OUTCOME_TYPES.ANSWER,
			outcome: ANSWER_ACTIONS_GREAT.PLAN_PHONE_CALL
		};

		const activity = getPlainObject(state.activity);
		openDrawer('CreateCall', {
			contact: activity.contact,
			client: activity.client,
			source: {
				id: activity.id,
				type: TYPES.ACTIVITY
			},
			onSave: (followupActivity: Activity) => {
				createCommentIfExists(commentDescription)
					.then(comment => {
						const state = getAutoDraftState();

						// @ts-expect-error
						state.activity.outcome.activityId = followupActivity.id;
						if (comment) {
							// @ts-expect-error
							state.activity.outcome.commentId = comment.id;
						}
						state.expanded = false;

						const activity = getPlainObject(state.activity);
						return Activity.customer(customerId).save(activity);
					})
					.catch(logError);
			}
		});
	}

	return (
		<td className="clickable">
			<div>
				<table className="details-table">
					<thead onClick={expand}>
						<tr>
							<th style={{ width: 40 }}>
								{event?.users?.[0] ? <Avatar user={event.users[0]} size={30} /> : null}
							</th>
							<th className={classNames({ 'has-priority': hasPriority })}>
								<div className="event-info">
									{event.users.length ? (
										<b>{`${event.users[0].name} `}</b>
									) : (
										<span>{`${t('default.someone')} `}</span>
									)}
									{event.subType === 'Closed' ? (
										<span className="small-text">
											{`${t(getClosedText(event, isPhoneCall)).toLowerCase()} `}
										</span>
									) : null}
									{event.subType === 'Created' ? (
										<span className="small-text">
											<span>{`${t(getCreatedText(isPhoneCall, isTodo, isPassed))} `}</span>
										</span>
									) : null}
									{!(event.subType === 'Closed' && isPhoneCall) ? (
										<a onClick={clickEvent => openActivityAndStopPropagation(clickEvent)}>
											<i>{`${event.activity.description} `}</i>
										</a>
									) : null}
									{event.contacts?.length && !(event.subType === 'Closed' && isPhoneCall) ? (
										<span>
											<span>{`${t('default.with').toLowerCase()} `}</span>
											<a
												onClick={clickEvent =>
													goToContact(clickEvent, customerId, event.contacts![0].id)
												}
											>
												{`${event.contacts[0].name} `}
											</a>
										</span>
									) : null}
									{event.contacts?.length && event.subType === 'Closed' && isPhoneCall ? (
										<span>
											<a
												onClick={clickEvent =>
													goToContact(clickEvent, customerId, event.contacts![0].id)
												}
											>
												{`${event.contacts[0].name} `}
											</a>
											<span>
												{`${t(
													event.contacts?.length
														? 'activity.outcomeLog.stopTryingPhoneCall'
														: 'activity.outcomeLog.stopTryingPhoneCallNoContact'
												).toLowerCase()} `}
											</span>
											<a onClick={clickEvent => openActivityAndStopPropagation(clickEvent)}>
												<i>{`${event.activity.description} `}</i>
											</a>
										</span>
									) : null}
									<br />
									<Tooltip title={dateCalendar(event.date!, true, '', true, true) as string}>
										<span className={classNames('date', { overdue: isPassed })}>
											{dateCalendar(event.date!, true, '', false, true)}
										</span>
									</Tooltip>
									{!isPhoneCall && !isTodo ? <span> &bull;{` ${activityTypeName}`}</span> : null}
								</div>
								{hasPriority ? (
									<div className="high-priority">
										<span className="priority-flag">
											<i className="fa fa-flag"></i>
										</span>
										{t('activity.priority')}
									</div>
								) : null}
							</th>
							{!isTodo ? (
								<th className="event-expand-header">
									{state.expanded ? (
										<span className="pull-right Icon Icon-chevron-up"></span>
									) : (
										<span className="pull-right Icon Icon-chevron-down"></span>
									)}
								</th>
							) : null}
						</tr>
					</thead>
					<tbody>
						{state.expanded && !state.loading && state.activity ? (
							<tr className="animate-repeat">
								<td
									colSpan={3}
									className={classNames('no-padding', {
										'no-overflow table-padding': !hasActivityOutcomeAccess
									})}
								>
									{hasActivityOutcomeAccess ? (
										<ActivityOutcome
											company={state.company}
											disabled={false}
											eventDetails={state.activity}
											isOutcomeEnabled={state.isOutcomeEnabled}
											saving={false}
											addDate={addDate}
											addNewContactButton={addNewContactButton}
											bookAppointment={bookAppointment}
											confirm={confirm}
											createActivityFollowUp={createActivityFollowUp}
											createCallFollowUp={createCallFollowUp}
											createContact={onCreateContact}
											createOpportunityAndSave={createOpportunityAndSave}
											createOrderAndSave={createOrderAndSave}
											createTodoFollowUp={createTodoFollowUp}
											editContact={editContact}
											onPresetDateChange={presetDateChange}
											openActivity={openActivity}
											openContactDropdown={openContactDropdown}
											planPhonecall={planPhonecall}
											saveContact={saveActivityContact}
											setAppointment={setAppointment}
											setJourneyStep={setJourneyStep}
											setOutcome={setOutcome}
											showMoreOptIns={showMoreOptIns}
											updateContact={updateContact}
											updateNotes={updateNotes}
											updateTime={updateTime}
										/>
									) : (
										<div>
											<b>{`${state.activity.description} `}</b>
											{state.activity.project && state.activity.opportunity ? (
												<div>
													{`${t('activity.relatedTo')} ${t(
														'default.theCampaign'
													).toLowerCase()} `}
													<a
														onClick={clickEvent =>
															goToCampaign(
																clickEvent,
																customerId,
																state.activity.project!.id
															)
														}
													>
														{state.activity.project.name}
													</a>
													{` ${t('default.and')} ${t(
														'default.theOpportunity'
													).toLowerCase()} `}
													<a
														onClick={clickEvent =>
															editOpportunity(
																clickEvent,
																customerId,
																state.activity.opportunity!.id
															)
														}
													>
														{state.activity.opportunity.description}
													</a>
												</div>
											) : null}
											{state.activity.project && !state.activity.opportunity ? (
												<div>
													{`${t('default.relatedTo')} ${t(
														'default.theCampaign'
													).toLowerCase()} `}
													<a
														onClick={clickEvent =>
															goToCampaign(
																clickEvent,
																customerId,
																state.activity.project!.id
															)
														}
													>
														{state.activity.project.name}
													</a>
												</div>
											) : null}
											{!state.activity.project && state.activity.opportunity ? (
												<div>
													{`${t('default.relatedTo')} ${t(
														'default.theOpportunity'
													).toLowerCase()} `}
													<a
														onClick={clickEvent =>
															editOpportunity(
																clickEvent,
																customerId,
																state.activity.opportunity!.id
															)
														}
													>
														{state.activity.opportunity.description}
													</a>
												</div>
											) : null}
											{state.activity.notes ? (
												<div style={{ whiteSpace: 'pre-wrap' }}>{state.activity.notes}</div>
											) : null}
										</div>
									)}
								</td>
							</tr>
						) : null}
					</tbody>
				</table>
				<div className="details-table-arrow"></div>
			</div>
		</td>
	);
};

export default EventActivity;
