import Agreement from 'App/resources/Model/Agreement';
import logError from 'Helpers/logError';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getActivePriceLists } from 'Store/selectors/AppSelectors';
import AgreementGroupResource from 'Resources/AgreementGroup';
import { RootState } from 'Store/index';
import { getInitialState, getPriceList, getStage, SubscriptionGroupProvider } from './Context/SubscriptionGroupContext';
import { getDefaultPeriodSettings } from './Context/SubscriptionGroupContextHelpers';
import {
	OrderInterval,
	PeriodLength,
	PeriodSettings,
	SubscriptionGroupState,
	SubscriptionPeriodState
} from './Context/SubscriptionGroupState';
import { cloneDeep } from 'lodash';
import { STEP } from './Step';
import { Currency, Metadata } from 'App/resources/AllIWant';
import Product from 'App/resources/Model/Product';
import ProductResource from 'Resources/Product';
import Order from 'App/resources/Model/Order';
import { makeCancelable, CancelablePromise } from 'App/babel/helpers/promise';

import { default as OpenSubscription, Props } from './OpenSubscription';
import PriceList from 'App/resources/Model/PriceList';
import OrderRow from 'App/resources/Model/OrderRow';
import { ValidationError, requiredCustomFieldsValidator } from 'App/helpers/validateRequiredEntityFields';
import { CustomFieldWithStages } from 'App/resources/Model/CustomField';
import { FieldTranslationsProvider } from '../FieldTranslations/FieldTranslationsContext';
import { useCustomFields, useStages } from '../hooks/appHooks';

const mapOrderRow =
	(
		agreement: Agreement,
		currentUUID: number,
		allProducts: Product[],
		customFields: CustomFieldWithStages[],
		orderRowLength: number,
		endDate?: string
	) =>
	(row: OrderRow, idx: number) => {
		const isNew = idx >= orderRowLength;
		const product = allProducts.find(p => p.id === row.product.id);
		const isTiered = product?.tiers?.length;
		const tierQuantity = isTiered ? Math.max(row.quantity ?? 0, row.tierQuantity ?? 0) : row.quantity;

		const custom = row.custom;
		const agreementIsPassed = moment(endDate ?? agreement.metadata.agreementEnddate).isBefore();
		const customErrors = agreementIsPassed ? [] : requiredCustomFieldsValidator({ custom }, customFields);

		return {
			...row,
			discount: row.discount * agreement.currencyRate,
			listPrice: row.listPrice * agreement.currencyRate,
			price: row.price * agreement.currencyRate,
			orderRowTotal: row.price * row.quantity * agreement.currencyRate,
			tierQuantity,
			isValid: customErrors.length === 0,
			uuid: currentUUID + idx,
			product,
			isNew,
			bundleRows:
				row.bundleRows?.map((bundleRow, index) => {
					const bundle = product?.bundle?.find(b => b.product?.id === bundleRow.productId);
					bundleRow.adjustable = !!bundle?.adjustable;
					bundleRow.bundleRowPrice = bundleRow.price;
					bundleRow.bundleRowCost = bundleRow.purchaseCost;

					return {
						...bundleRow,
						product: allProducts.find(p => p.id === bundleRow.productId),
						uuid: index
					};
				}) ?? []
		};
	};

const getErrorMessages = (errors: ValidationError[]) =>
	errors.reduce((acc, { error, field }) => {
		acc[`custom.Custom_${field.id.toString()}`] = error;
		return acc;
	}, {} as { [key: string]: string | null });

const getSubscriptionMapFromAgreement = (
	currentUUID: number,
	agreements: Agreement[],
	agreementId: number,
	allProducts: Product[],
	priceLists: PriceList[],
	orderCustomFields: CustomFieldWithStages[],
	orderrowCustomFields: CustomFieldWithStages[],
	masterCurrency: Currency,
	params?: Metadata['params'],
	isAdmin?: boolean,
	orderToAdd?: Order,
	updateUpcoming: Set<number> = new Set()
) => {
	const subscriptionMap: {
		[key: number]: SubscriptionPeriodState;
	} = {};
	let groupLocked = true;
	let lockedReason = '';

	const extraRecurringOrderRows = (orderToAdd?.orderRow ?? [])
		.filter(row => row.product.isRecurring)
		.map(({ id, ...rowWithoutId }) => rowWithoutId);

	let childIndexNr;
	const sortingFunction = (
		a: ReturnType<ReturnType<typeof mapOrderRow>>,
		b: ReturnType<ReturnType<typeof mapOrderRow>>
	) => {
		// Sort by isNew in ascending order
		if (a.isNew < b.isNew) return -1;
		if (a.isNew > b.isNew) return 1;

		// If isNew is equal, sort by sortId in ascending order
		if (a.sortId < b.sortId) return -1;
		if (a.sortId > b.sortId) return 1;

		// If both isNew and sortId are equal, return 0 (no sorting)
		return 0;
	};

	for (const [idx, agreement] of Object.entries(agreements)) {
		const oneIndexed = parseInt(idx) + 1;
		const uuid = agreementId === agreement.id ? currentUUID : currentUUID + oneIndexed;
		const endDate = agreement.metadata.agreementEnddate
			? moment(agreement.metadata.agreementEnddate).format('YYYY-MM-DD')
			: undefined;
		const endDateHasPassed = endDate && moment(endDate).isBefore();

		const isUpcoming = moment(agreement.metadata.agreementStartdate).isAfter();
		const addedRows =
			endDateHasPassed || (isUpcoming && !updateUpcoming.has(agreement.id)) ? [] : extraRecurringOrderRows;

		const children =
			agreement.children?.map((child, i) => {
				const shouldAddRows = child.endDate && moment().isBefore(child.endDate);
				const rows = shouldAddRows ? [...child.orderRow, ...addedRows] : [...child.orderRow];
				if (uuid === currentUUID && shouldAddRows) {
					childIndexNr = i;
				}

				return {
					...child,
					orderRow: rows
						.map(
							mapOrderRow(
								agreement,
								currentUUID,
								allProducts,
								orderrowCustomFields,
								child.orderRow.length,
								child.endDate
							)
						)
						.sort(sortingFunction),
					uuid: Date.now() + i
				};
			}) ?? [];

		const orderRows = [...agreement.orderRow, ...addedRows]
			.map(mapOrderRow(agreement, currentUUID, allProducts, orderrowCustomFields, agreement.orderRow.length))
			.sort(sortingFunction);

		let locked = false;
		const hasLockedStage = params?.SubscriptionLockedStages?.indexOf(agreement.stage.id) !== -1;
		const stageLockedForMe = !isAdmin && !params?.SubscriptionAdminCanEditLockedStage;

		if (!agreement.userEditable) {
			locked = true;
			lockedReason = groupLocked ? 'noEditRights.agreement' : '';
		} else if (hasLockedStage && stageLockedForMe) {
			locked = true;
			lockedReason = groupLocked ? 'agreement.lockedStageAgreementInfo' : '';
		} else if (endDateHasPassed) {
			locked = true;
			groupLocked = false;
		} else {
			groupLocked = false;
			lockedReason = '';
		}

		const priceList = getPriceList(priceLists, agreement.priceListId, false);
		const renewalDate = agreement.metadata.agreementRenewalDate
			? moment(agreement.metadata.agreementRenewalDate)
			: moment(agreement.metadata.agreementInvoiceStartdate).add(agreement.metadata.periodLength, 'month');

		const offset = agreement.metadata.agreementOrderCreationTime ?? 0;
		const nextOrderDate = moment(agreement.metadata.agreementNextOrderDate).format('YYYY-MM-DD');
		const nextOrderPeriodStart = moment(nextOrderDate).add(offset, 'days').format('YYYY-MM-DD');

		const custom = agreement.custom;
		const agreementIsPassed = moment(agreement.metadata.agreementEnddate).isBefore();
		const customErrors = agreementIsPassed ? [] : requiredCustomFieldsValidator({ custom }, orderCustomFields);
		const errorMessages = getErrorMessages(customErrors);

		subscriptionMap[uuid] = {
			id: agreement.id,
			uuid,
			locked,
			periodLength: agreement.metadata.periodLength as PeriodLength,
			orderInterval: agreement.metadata.agreementIntervalPeriod as OrderInterval,
			startDate: moment(agreement.metadata.agreementStartdate).format('YYYY-MM-DD'),
			invoiceStartDate: moment(agreement.metadata.agreementInvoiceStartdate).format('YYYY-MM-DD'),
			initialInvoiceStartDate: moment(
				agreement.metadata.agreementInitialInvoiceStartdate || agreement.metadata.agreementInvoiceStartdate
			).format('YYYY-MM-DD'),
			renewalDate: renewalDate.format('YYYY-MM-DD'),
			endDate,
			offset,
			noticePeriod: agreement.metadata.noticePeriod,
			custom,
			isCustomFieldsValid: customErrors.length === 0,
			currency: agreement.currency ?? masterCurrency.iso,
			value: agreement.orderValue ?? agreement.value,
			orderSequenceNr: agreement.metadata.orderSequenceNr,
			nextOrderDate,
			nextOrderPeriodStart,
			willCreateMoreOrders: agreement.metadata.willCreateMoreOrders,
			currencyRate: agreement.currencyRate ?? masterCurrency.rate,
			errorMessages,
			children,
			orderRows,
			untouchedValues: {
				endDate,
				orderRows: orderRows.filter(({ isNew }) => !isNew),
				children: children.map(({ id, uuid, orderRow }) => ({
					id,
					uuid,
					orderRow: orderRow.filter(({ isNew }) => !isNew)
				}))
			},
			priceList
		};
	}

	return { subscriptionMap, locked: groupLocked, lockedReason, childIndexNr };
};

const shouldBeAdvancedMode = (agreements: Agreement[], defaultPeriodSettings: PeriodSettings) => {
	if (Tools.FeatureHelper.hasSoftDeployAccess('SUBSCRIPTION_SPLITS')) {
		return true;
	}

	if (agreements.length > 1) {
		return true;
	}

	const [agreement] = agreements;

	if (agreement.metadata.periodLength !== defaultPeriodSettings.periodLength) {
		return true;
	}
	if (agreement.metadata.agreementIntervalPeriod !== defaultPeriodSettings.orderInterval) {
		return true;
	}
	if (agreement.metadata.agreementOrderCreationTime !== defaultPeriodSettings.offset) {
		return true;
	}
	if (agreement.metadata.noticePeriod !== defaultPeriodSettings.noticePeriod) {
		return true;
	}

	return false;
};

const getReformedOrder = (orderToAdd: Order | undefined, newInterval: number) => {
	if (!orderToAdd) return undefined;

	const order = cloneDeep(orderToAdd);
	const oldInterval = order.recurringInterval || 1;

	order.orderRow.forEach(row => {
		// Order does not have correct quantity for Tiered Total products...
		const isRecurringTotalPrice = row.product.tiers?.[0]?.isTotalPrice;
		row.quantity = isRecurringTotalPrice ? 1 : row.quantity;

		// Set prices right if the intervals differ
		if (row.product.isRecurring && oldInterval !== newInterval) {
			row.price *= newInterval / oldInterval;
			row.listPrice *= newInterval / oldInterval;
		}
	});

	return order;
};

const useInitialState = (
	agreementId: number,
	allProducts: Product[],
	priceLists: PriceList[],
	masterCurrency: Currency,
	agreements?: Agreement[],
	agreementGroupId?: number,
	params?: Metadata['params'],
	isAdmin?: boolean,
	orderToAdd?: Order,
	updateUpcoming?: Set<number>,
	childIndexNr?: number
): Partial<SubscriptionGroupState> => {
	const stages = useStages(); // Yes, all stages in case a previously 100 prob stage was changed
	const priceList = getPriceList(priceLists);
	const defaultPeriodSettings = getDefaultPeriodSettings(priceList, params);
	const agreementCustomFields = useCustomFields('agreement');
	const orderCustomFields = useCustomFields('order');
	const orderrowCustomFields = useCustomFields('orderrow');

	if (!agreements) {
		return { fetching: true };
	}

	const [firstSub] = agreements;

	const currentAgreement =
		[...agreements]
			.reverse()
			.find(agreement => moment().isSameOrAfter(moment(agreement.metadata.agreementStartdate))) || firstSub;

	const user = currentAgreement.user;
	const client = currentAgreement.client;
	const clientConnection = currentAgreement.clientConnection;
	const contact = currentAgreement.contact ?? null;
	const project = currentAgreement.project ?? null;
	const custom = currentAgreement.metadata.custom;
	const description = currentAgreement.description ?? '';
	const notes = currentAgreement.metadata.agreementNotes ?? '';
	const stage = getStage(stages, params, currentAgreement?.stage.id);
	const order = getReformedOrder(orderToAdd, currentAgreement.metadata.agreementIntervalPeriod);
	const invoiceRelatedClient = currentAgreement.invoiceRelatedClient ?? false;

	const agreementCustomErrors = requiredCustomFieldsValidator({ custom }, agreementCustomFields);
	const errorMessages = getErrorMessages(agreementCustomErrors);

	const currentUUID = Date.now();
	const {
		subscriptionMap,
		locked,
		lockedReason,
		childIndexNr: childIdx
	} = getSubscriptionMapFromAgreement(
		currentUUID,
		agreements,
		agreementId,
		allProducts,
		priceLists,
		orderCustomFields,
		orderrowCustomFields,
		masterCurrency,
		params,
		isAdmin,
		order,
		updateUpcoming
	);
	const splitEnabled = agreements.length > 1;
	const isAdvancedMode = shouldBeAdvancedMode(agreements, defaultPeriodSettings);

	return {
		id: agreementGroupId,
		client,
		contact,
		clientConnection,
		user,
		description,
		notes,
		currentUUID,
		subscriptionMap,
		custom,
		campaign: project,
		stage,
		locked,
		lockedReason,
		splitEnabled,
		isAdvancedMode,
		errorMessages,
		allowOffset: defaultPeriodSettings.allowOffset,
		isDetailsValid: agreementCustomErrors.length === 0,
		fetching: false,
		step: STEP.SUMMARY,
		orderToAdd: order,
		childIndexNr: childIndexNr ?? childIdx,
		invoiceRelatedClient
	};
};

type UpdateAgreementProps = {
	orderToAdd?: Order;
	updateUpcoming?: Set<number>;
	isMoved?: boolean;
	groupState?: SubscriptionGroupState;
};

type PropsWithAgreementGroupId = Props &
	UpdateAgreementProps & {
		agreementGroupId: number;
		agreement?: Agreement;
		agreements?: Agreement[];
		agreementId?: number;
		createCopy?: boolean;
		childIndexNr?: number;
	};

type PropsWithAgreementId = Props &
	UpdateAgreementProps & {
		agreementId: number;
		agreement: Agreement;
		agreements?: Agreement[];
		agreementGroupId?: number;
		createCopy?: boolean;
		childIndexNr?: number;
	};

type EditSubscriptionProps = PropsWithAgreementGroupId | PropsWithAgreementId;

export default (props: EditSubscriptionProps) => {
	const {
		agreementId,
		agreement,
		agreements: incomingAgreements,
		agreementGroupId,
		createCopy,
		orderToAdd,
		updateUpcoming,
		childIndexNr,
		isMoved,
		...rest
	} = props;
	const [agreements, setAgreements] = useState<Agreement[] | undefined>(
		incomingAgreements ? incomingAgreements : agreement ? [agreement] : undefined
	);
	const [allProducts, setAllProducts] = useState<Product[]>([]);
	const [groupId, setGroupId] = useState<number | undefined>(agreementGroupId);
	const params = useSelector((state: RootState) => state.App?.metadata?.params);
	const isAdmin = useSelector((state: RootState) => state.App?.self?.administrator);
	const { customerCurrencies } = useSelector((state: RootState) => state.App?.metadata ?? { customerCurrencies: [] });
	const masterCurrency = customerCurrencies.find(currency => currency.masterCurrency) as Currency;
	const priceLists = getActivePriceLists();
	let currentAgreementId = agreementId ?? 0;

	let agreementGroupPromise: CancelablePromise<Awaited<ReturnType<typeof AgreementGroupResource.get>>> | undefined;
	let agreementGroupPromise2:
		| CancelablePromise<Awaited<ReturnType<typeof AgreementGroupResource.getByAgreementId>>>
		| undefined;
	let productPromise: CancelablePromise<Awaited<ReturnType<typeof ProductResource.find>>> | undefined;

	useEffect(() => {
		if (!agreements && agreementGroupId) {
			agreementGroupPromise = makeCancelable(AgreementGroupResource.get(agreementGroupId));
			agreementGroupPromise.promise
				.then(({ data }) => {
					setAgreements(data.agreements);
					if (!currentAgreementId) {
						currentAgreementId = data.currentAgreement.id;
					}
				})
				.catch(e => logError(e, 'Failed to fetch agreement group'));
		}
		return () => {
			agreementGroupPromise?.cancel();
		};
	}, [agreementGroupId]);

	useEffect(() => {
		if (!agreements && !agreementGroupId && agreementId) {
			agreementGroupPromise2 = makeCancelable(AgreementGroupResource.getByAgreementId(agreementId));
			agreementGroupPromise2.promise
				.then(group => {
					setAgreements(group.agreements);
					if (!currentAgreementId) {
						currentAgreementId = group.currentAgreement.id;
					}
					if (group.id) {
						setGroupId(group.id);
					}
				})
				.catch(e => logError(e, 'Failed to fetch agreement group'));
		}
		return () => {
			agreementGroupPromise2?.cancel();
		};
	}, [agreementId]);

	useEffect(() => {
		const productIds = new Set<number>();
		for (const agreement of agreements || []) {
			const rows = [
				...agreement.orderRow,
				...(orderToAdd?.orderRow ?? []),
				...(agreement.children?.flatMap(c => c.orderRow) ?? [])
			];

			for (const orderRow of rows) {
				productIds.add(orderRow.product.id);
				if (orderRow.bundleRows?.length) {
					for (const bundleRow of orderRow.bundleRows) {
						productIds.add(bundleRow.productId);
					}
				}
			}
		}
		const tooManyProducts = Tools.AppService.getTotals('products') > 4000;
		if (tooManyProducts) {
			productPromise = makeCancelable(ProductResource.find({ id: Array.from(productIds), usePriceLists: true }));
			productPromise.promise
				.then(({ data }) => setAllProducts(data))
				.catch(e => logError(e, 'Failed to get products'));
		} else {
			setAllProducts(
				Tools.AppService.getProducts(false, true, true).filter(p =>
					productIds.has(p.id)
				) as unknown as Product[]
			);
		}
		return () => {
			productPromise?.cancel();
		};
	}, [agreements]);

	const initialState = props.groupState
		? props.groupState
		: useInitialState(
				currentAgreementId,
				allProducts,
				priceLists,
				masterCurrency,
				agreements,
				groupId,
				params,
				isAdmin,
				orderToAdd,
				updateUpcoming,
				childIndexNr
		  );

	initialState.dontWait = props.dontWait;

	if (createCopy) {
		initialState.isEdit = false;
		initialState.step = STEP.DETAILS;

		delete initialState.id;
		delete initialState.createdFromOrderId;

		Object.keys(initialState.subscriptionMap || {}).forEach(key => {
			const uuid = parseInt(key);
			const subscription = initialState.subscriptionMap?.[uuid];

			const freshCurrency = customerCurrencies?.find(currency => currency.iso === subscription?.currency);
			const oldRate = subscription?.currencyRate ?? 1;
			if (subscription) {
				delete subscription.id;
				delete subscription.nextOrderDate;
				subscription.orderSequenceNr = 0;
				subscription.currencyRate = freshCurrency?.rate ?? oldRate;
			}
			Object.keys(initialState.subscriptionMap?.[uuid].orderRows || {}).forEach(orderRowKey => {
				const orderRow = subscription?.orderRows[parseInt(orderRowKey)];
				if (orderRow) {
					orderRow.initExcessiveDiscount = true;
					delete orderRow.id;
				}
			});
		});
	}

	if (isMoved) {
		Object.values(initialState.subscriptionMap || {}).forEach(subscription => {
			subscription.orderRows.forEach(orderRow => {
				orderRow.initExcessiveDiscount = true;
			});
		});
	}

	return (
		<SubscriptionGroupProvider
			key={groupId + allProducts.map(p => p.id).join(',')}
			initialState={getInitialState(initialState)}
		>
			<FieldTranslationsProvider types={['clientorderrelation']}>
				<OpenSubscription {...rest} />
			</FieldTranslationsProvider>
		</SubscriptionGroupProvider>
	);
};
