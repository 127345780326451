import moment from 'moment';

export const mapProperties = (properties = {}, arrayProperties = []) => {
	return Object.keys(properties || {}).reduce((result, key) => {
		const isArray = arrayProperties.indexOf(key) !== -1;
		const obj = {
			name: key,
			value: properties[key]
		};

		if (isArray && Array.isArray(obj.value)) {
			obj.value = obj.value.join(',');
		}

		// Do not push empty arrays
		if (!(isArray && !properties[key].length) && obj.value != null) {
			result.push(obj);
		}
		return result;
	}, []);
};

export const parseProperties = function (properties = [], arrayProperties = []) {
	return (properties || []).reduce((result, prop) => {
		result[prop.name] = prop.value;
		if (prop.value === '{{General.CurrentUser}}') {
			result[prop.name] = '{{General.CurrentUserId}}';
		}
		if (arrayProperties.indexOf(prop.name) !== -1) {
			result[prop.name] = result[prop.name].split(',').map(s => parseInt(s));
		}
		return result;
	}, {});
};

export const mapCustomFields = function (properties, selectedFields, entity) {
	selectedFields.forEach(function (field) {
		if (field.value) {
			let value;
			if (field.datatype === 'Date' && field.value.tag) {
				field.value = '{{' + field.value.tag + '}}';
			} else if (field.datatype === 'User' && field.value.id) {
				field.value = String(field.value.id);
			} else if (field.datatype === 'Users' && field.value.length && Array.isArray(field.value)) {
				field.value = field.value
					.map(function (value) {
						return value.id;
					})
					.join(',');
			} else if (field.datatype === 'Date' && moment(field.value).format('HH:mm') === '00:00') {
				value = moment(field.value).format('YYYY-MM-DD');
			}
			properties.push({ name: entity + '.custom_' + field.id.toString(), value: value || field.value });
		} else if (field.datatype === 'Boolean' && !field.value) {
			properties.push({ name: entity + '.custom_' + field.id.toString(), value: false });
		} else if (['Integer', 'Currency', 'Percent', 'Discount'].includes(field.datatype) && field.value === 0) {
			properties.push({ name: entity + '.custom_' + field.id.toString(), value: 0 });
		}
	});
};

export const getCustomFieldValue = function (field) {
	if (field.value) {
		if (field.datatype === 'Date' && field.value.tag) {
			return '{{' + field.value.tag + '}}';
		} else if (field.datatype === 'User') {
			return field.value.id ? String(field.value.id) : null;
		} else if (field.datatype === 'Users') {
			return field.value.map(value => value.id).join(',') || null;
		} else if (field.datatype === 'Date' && moment(field.value).format('HH:mm') === '00:00') {
			return moment(field.value).format('YYYY-MM-DD');
		}
		return field.value;
	} else if (field.datatype === 'Boolean' && !field.value) {
		return false;
	} else if (['Integer', 'Currency', 'Percent', 'Discount'].includes(field.datatype) && field.value === 0) {
		return 0;
	}
	return null;
};

export const parseCustomFields = function (startingFields, customProperties, entity, dateTypes) {
	const res = [];
	const availableFields = [];
	startingFields.forEach(function (field) {
		var key = entity + '.custom_' + field.id.toString();
		if (customProperties[key] !== undefined) {
			field.value = customProperties[key];
			if (field.datatype === 'Boolean') {
				// If field.value is truthy, make it true. If it is falsey, make it false.
				if (field.value === '0') {
					field.value = false;
				}
				if (field.value === '1') {
					field.value = true;
				}
				field.value = !!field.value;
			} else if (field.datatype === 'Date' && dateTypes) {
				var found = _.find(dateTypes, function (type) {
					return field.value.indexOf(type.tag) !== -1;
				});
				if (found) {
					field.value = found;
					field.customDateSelect = true;
				} else {
					found = field.value;
					field.customDateSelect = false;
				}
			}

			res.push(field);
		} else {
			availableFields.push(field);
		}
	});

	return [res, availableFields];
};
