import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Block, Card, Row, Column, Text } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import ActivityShape from 'App/babel/propTypes/ActivityShape';
import Client from 'App/resources/Client';
import Contact from 'App/resources/Contact';
import Activity from 'Resources/Activity';
import logError from 'App/babel/helpers/logError';
import { makeCancelable } from '../../helpers/promise';
import { openDrawer } from 'Services/Drawer';
import EditPhonecallHeader from './EditPhonecallHeader';
import EditPhonecallContact from './EditPhonecallContact';
import EditPhonecallNoContact from './EditPhonecallNoContact';
import EditPhonecallClient from './EditPhonecallClient';
import EditPhonecallActivityOutcome from './EditPhonecallActivityOutcome';
import EditPhonecallNotes from './EditPhonecallNotes';
import PhonecallRelationFields from 'App/pages/Phonecall/PhonecallRelationFields';
import PhonecallCustomFields from './PhonecallCustomFields';
import EditPhonecallTodos from './EditPhonecallTodos';
import UiElements from '../UiElements/UiElements';
import T from 'Components/Helpers/translate';
import EditPhonecallClientContactLoading from './EditPhonecallClientContactLoading';
import FocusMode from 'Components/FocusMode';
import FocusModeFooter from 'Components/FocusMode/FocusModeFooter';
import NotificationService from 'App/babel/NotificationService';
import { OUTCOME_TYPES, ANSWER_ACTIONS_GREAT } from 'App/babel/enum/activityOutcome';
import _ from 'lodash';
import { setSelectedId, recheckFocusItem } from 'Store/actions/TodoActions';
import Comment from 'Resources/Comment';
import openModal, { shouldOpenModal } from 'App/services/Modal';
import { TYPES } from 'Components/Helpers/SourceHelper';
import PopupPortalAnchors from 'App/helpers/PopupPortalAnchors';
import { updateModalProps } from 'Store/actions/ModalActions';
import RequestBuilder, { comparisonTypes } from 'Resources/RequestBuilder';
import RelationSelect from 'App/components/RelationSelect/RelationSelect';
import TicketWidget from 'App/components/TicketWidget/TicketWidget';
import { getFeatureAvailableFromState, Feature } from 'App/components/hooks/featureHelper';
import { openEditAppointment } from 'Components/Modals/Appointment/EditAppointment';

import './EditPhonecall.scss';

const getOutcome = (activity, outcome) => {
	const dateTimeStr = moment.utc().format();
	const clientJourney = _.get(activity, 'client.journeyStep');
	return outcome ? { ...outcome, date: dateTimeStr, clientJourney } : null;
};

const mapStateToProps = ({ Todo }) => ({
	tableData: Todo.tableData,
	focusData: Todo.focus.data
});

const mapDispatchToProps = {
	recheckFocusItem,
	setSelectedId,
	updateModalProps
};

const SAVE_TIMER_SHOWTIME = 3000;
const SAVE_DEBOUNCE_TIME = 1000;

const getClient = id => {
	return makeCancelable(Client.get(id));
};

const saveClient = data => {
	return makeCancelable(Client.save(data));
};

const saveContact = data => {
	return makeCancelable(Contact.save(data));
};

const getContacts = clientId => {
	const rb = new RequestBuilder();

	rb.addFilter({ field: 'active' }, comparisonTypes.Equals, true);

	const orFilter = rb.orBuilder();
	orFilter.next();
	orFilter.addFilter({ field: 'client.id' }, comparisonTypes.Equals, clientId);
	orFilter.next();
	orFilter.addFilter({ field: 'connectedClients.relatedToClientId' }, comparisonTypes.Equals, clientId);
	orFilter.done();

	rb.limit = 100;
	rb.addSort({ field: 'name' }, true);

	return makeCancelable(Contact.find(rb.build()));
};

const getActivity = id => {
	return makeCancelable(Activity.get(id));
};

const saveActivity = (activity, opts) => {
	return makeCancelable(Activity.save(activity, opts));
};

const deleteActivity = id => {
	return makeCancelable(Activity.delete(id));
};

const mapCustomFields = custom => {
	const customFields = Tools.AppService.getCustomFields('activity').filter(field => field.visible);
	return customFields.map(field => {
		const matchingCf = custom?.find(cf => cf.fieldId === field.id);
		let value = null;
		if (matchingCf) {
			value = matchingCf.value;
		}
		return { ...field, fieldId: field.id, value };
	});
};

class EditPhonecall extends React.Component {
	static propTypes = {
		className: PropTypes.string,
		close: PropTypes.func.isRequired,
		activity: ActivityShape.isRequired,
		tableData: PropTypes.array,
		shouldIterateList: PropTypes.bool,
		setSelectedId: PropTypes.func,
		focus: PropTypes.bool,
		focusData: PropTypes.array,
		afterSave: PropTypes.func,
		modalId: PropTypes.number,
		updateModalProps: PropTypes.func,
		recheckFocusItem: PropTypes.func
	};

	static defaultProps = {
		focus: false
	};

	constructor(p) {
		super(p);

		this.state = {
			activity: p.activity,
			contact: p.activity.contacts && p.activity.contacts.length ? p.activity.contacts[0] : null,
			client: p.activity.client || null,
			loading: true,
			closing: false,
			customFields: [],
			clientLoading: true,
			contactsLoading: true,
			savedBannerVisible: false,
			contacts: [],
			pendingOutcome: null,
			pendingDate: p.activity.date,
			pendingTime: null,
			missingClientAccess: false,
			accountSelf: Tools.AppService.getAccountSelf()
		};

		this.users = Tools.AppService.getUsers(Tools.AppService.AccessType.ACTIVITY, false, ['crm', 'service']);
		this.saveBannerTimer = null;
		this.saveTimeout = null;
		this.buttonRef = null;
		this.uiElements = Tools.AppService.getMetadata().integrations?.uiElements?.editActivity;
		this.wrapperRef = React.createRef();
	}

	componentDidMount() {
		this.getAdditionalData();
	}

	componentDidUpdate(prevProps) {
		if (this.props.focus && prevProps.focusData !== this.props.focusData) {
			this.props.recheckFocusItem(this.state.activity?.id);
		}
	}

	componentWillUnmount() {
		this.props.setSelectedId(null);

		if (this.getActivityPromise) {
			this.getActivityPromise.cancel();
		}
		if (this.getClientPromise) {
			this.getClientPromise.cancel();
		}
		if (this.getContactPromise) {
			this.getContactPromise.cancel();
		}
		if (this.getContactsPromise) {
			this.getContactsPromise.cancel();
		}
		if (this.saveActivityPromise) {
			this.saveActivityPromise.cancel();
		}
		if (this.saveContactPromise) {
			this.saveContactPromise.cancel();
		}
		if (this.saveClientPromise) {
			this.saveClientPromise.cancel();
		}
		if (this.deleteActivityPromise) {
			this.deleteActivityPromise.cancel();
		}
		if (this.saveBannerTimer) {
			clearTimeout(this.saveBannerTimer);
		}
	}

	getAdditionalData = () => {
		this.props.setSelectedId(this.state.activity.id);
		const p = this.getActivity();

		if (this.state.client) {
			this.getClientAndContacts();
		} else {
			// eslint-disable-next-line promise/catch-or-return
			p.then(() => this.getClientAndContacts());
		}
	};

	handleRequestError = (error, msg) => {
		logError(error, msg);
		NotificationService.add({
			icon: 'times',
			style: NotificationService.style.ERROR,
			title: 'default.error',
			body: 'errorNotFound.activity'
		});
		this.props.close();
	};

	getClientAndContacts = () => {
		if (!this.state.client) {
			return;
		}
		this.setState({ clientLoading: true, contactsLoading: true });
		this.getClientPromise = getClient(this.state.client.id);
		// Get 100 contacts.. If total was more we flag select to be a type-to-search
		this.getContactsPromise = getContacts(this.state.client.id);
		this.getContactsPromise.promise
			.then(({ data, metadata }) => {
				if (metadata.total > metadata.limit) {
					this.setState({ useAjaxContactSelect: true, contacts: [] });
				} else {
					this.setState({ useAjaxContactSelect: false, contacts: data });
				}
			})
			.catch(err => this.handleRequestError(err, 'Failed to get contacts'))
			.finally(() => {
				this.getContactsPromise = null;
				this.setState({ contactsLoading: false });
			});
		this.getClientPromise.promise
			.then(({ data: client }) => this.setState({ client, missingClientAccess: false }))
			.catch(err => {
				if (err.request.status === 404) {
					this.setState({
						client: { ...this.state.activity.client, userEditable: false },
						missingClientAccess: true
					});
				} else {
					this.handleRequestError(err, 'Failed to get client');
				}
			})
			.finally(() => {
				this.getClientPromise = null;
				this.setState({ clientLoading: false });
			});
	};

	saveClient = data => {
		const client = { ...this.state.client, ...data };
		this.setState({ client });
		this.saveClientPromise = saveClient(client);
		this.saveClientPromise.promise
			.then(() => this.showSaveBanner())
			.finally(() => {
				this.saveClientPromise = null;
			})
			.catch(err => this.handleRequestError(err, 'Failed to save client'));
	};

	saveContact = data => {
		const contact = { ...this.state.contact, ...data };
		this.setState({ contact });
		this.saveContactPromise = saveContact(contact);
		this.saveContactPromise.promise
			.then(() => this.showSaveBanner())
			.finally(() => {
				this.saveContactPromise = null;
			})
			.catch(err => this.handleRequestError(err, 'Failed to save contact'));
	};

	getActivity = () => {
		this.setState({ loading: true });
		this.getActivityPromise = getActivity(this.state.activity.id);
		return this.getActivityPromise.promise
			.then(({ data: activity }) => {
				const state = { activity };
				if (!this.state.client && activity.client) {
					state.client = activity.client;
				}
				if (!this.state.contact && activity.contacts && activity.contacts[0]) {
					state.contact = activity.contacts[0];
				}
				if (activity.custom) {
					this.setState({ customFields: mapCustomFields(activity.custom) });
				}

				// Caught a closed activity in focus mode, step to next
				if (this.props.focus && !!activity.closeDate) {
					this.iterateCallList(true);
				} else {
					this.setState(state);
				}
			})
			.catch(err => this.handleRequestError(err, 'Failed to get activity'))
			.finally(() => {
				this.getActivityPromise = null;
				this.setState({ loading: false });
			});
	};

	showSaveBanner = () => {
		if (this.saveBannerTimer) {
			clearTimeout(this.saveBannerTimer);
		}

		this.setState({ savedBannerVisible: true });

		this.saveBannerTimer = setTimeout(() => {
			this.setState({ savedBannerVisible: false });
		}, SAVE_TIMER_SHOWTIME);
	};

	saveActivity = (activityData, isConfirm = false) => {
		this.setState({ savingActivity: true });
		const saveData = isConfirm ? this.state.activity : { id: this.state.activity.id, ...activityData };
		const { pendingDate, pendingTime } = this.state;
		let fixedDate = null;
		if (pendingDate && isConfirm) {
			if (pendingDate && pendingTime) {
				fixedDate = new Date(pendingDate);
				const timeObject = moment(pendingTime, 'LT');
				const hours = timeObject.hour();
				const minutes = timeObject.minutes();
				fixedDate.setHours(hours, minutes, 0, 0);
			} else if (pendingDate) {
				fixedDate = moment(pendingDate).format('YYYY-MM-DD');
				saveData.time = null;
			}
			saveData.date = fixedDate;
			this.setState({ pendingDate: null, pendingTime: null });
		}
		this.saveActivityPromise = saveActivity(saveData, { skipNotification: !isConfirm });
		let savedActivity = {};
		return this.saveActivityPromise.promise
			.then(saveResponse => {
				savedActivity = saveResponse.data;
				this.showSaveBanner();
			})
			.catch(err => logError(err, 'Failed to save activity'))
			.finally(() => {
				this.saveActivityPromise = null;
				if (this.state.activity.id === savedActivity.id) {
					this.setState({
						savingActivity: false,
						activity: { ...this.state.activity, ...savedActivity, outcome: null }
					});
				} else {
					this.setState({
						savingActivity: false
					});
				}
				if (this.props.afterSave) {
					this.props.afterSave(saveData);
				}

				if (isConfirm) {
					if (this.props.shouldIterateList) {
						this.iterateCallList(true);
					} else {
						this.props.close();
					}
				}
			});
	};

	deleteActivity = () => {
		this.deleteActivityPromise = deleteActivity(this.state.activity.id);
		this.deleteActivityPromise.promise
			.then(() => this.props.close())
			.finally(() => {
				this.deleteActivityPromise = null;
			})
			.catch(err => logError(err, 'Failed to delete activity'));
	};

	// Awkward to keep state up to date in both store and locally - it would probably be better to only keep modal state in one place
	updateModalActivity = activity => {
		this.props.updateModalProps(this.props.modalId, { activity });
	};

	setAndSavePartialActivity = async (activityData, debounce, isConfirm = false) => {
		if (this.saveTimeout) {
			clearTimeout(this.saveTimeout);
		}
		const newAct = { ...this.state.activity, ...activityData };
		this.setState(
			{
				activity: newAct
			},
			() => {
				if (debounce) {
					this.saveTimeout = setTimeout(() => this.saveActivity(activityData), SAVE_DEBOUNCE_TIME);
				} else {
					this.saveActivity(activityData, isConfirm);
				}
			}
		);
		this.updateModalActivity(newAct);
	};

	setAndSavePartialActivityDebounced = activityData => this.setAndSavePartialActivity(activityData, true);

	userChange = user => this.setAndSavePartialActivity({ users: [user] });

	createCommentIfExists = async (commentDesc, outcomeType) => {
		let comment;
		if (commentDesc) {
			({ data: comment } = await Comment.save({
				description: commentDesc,
				client: this.state.client,
				activity: this.state.activity,
				user: Tools.AppService.getSelf(),
				outcomeType
			}));
		}
		return comment;
	};
	contactChange = async contact => {
		if (this.state.pendingOutcome) {
			const comment = await this.createCommentIfExists(
				this.state.pendingOutcome.commentDesc,
				this.state.pendingOutcome.type
			);
			this.setAndSavePartialActivity({
				contacts: [contact],
				outcome: {
					...getOutcome(this.state.activity, this.state.pendingOutcome),
					...(comment ? { commentId: comment.id } : {})
				}
			});
		} else {
			this.setAndSavePartialActivity({ contacts: [contact] });
		}
		this.getClientAndContacts();
		this.setState({
			contact,
			pendingOutcome: null
		});
	};

	notesChange = text => {
		const notesHasChanged = this.state.activity.notes !== text;
		if (notesHasChanged) {
			this.setAndSavePartialActivity({ notes: text });
		}
	};

	descriptionChange = description => this.setAndSavePartialActivity({ description });

	prioChange = priority => this.setAndSavePartialActivity({ priority });

	dateChange = date => {
		this.setState({ pendingDate: date });
	};

	timeChange = time => {
		let { pendingDate } = this.state;
		if (pendingDate && !(pendingDate instanceof Date)) {
			pendingDate = new Date(pendingDate);
		}
		if (pendingDate && time) {
			pendingDate.setHours(time.getHours(), time.getMinutes(), 0, 0);
		}
		this.setState({
			pendingDate,
			pendingTime: time ? `${time.getHours()}:${time.getMinutes()}` : null
		});
	};
	bookAppointment = commentDesc => {
		const { id, notes, project } = this.state.activity;
		const self = Tools.AppService.getSelf();
		// eslint-disable-next-line promise/catch-or-return
		openEditAppointment({
			appointment: {
				notes,
				users: _.pick(self, ['id', 'name', 'role', 'email']),
				client: { id: this.state.client ? this.state.client.id : null },
				contacts: this.state.contact ? [this.state.contact] : null,
				project: project,
				sourceType: 'phonecall',
				sourceId: id,
				activityType: null
			}
		})
		.then(async appointment => {
			const { activity } = this.state;
			const comment = await this.createCommentIfExists(commentDesc, OUTCOME_TYPES.ANSWER);
			const outcome = {
				...getOutcome(activity, {
					type: OUTCOME_TYPES.ANSWER,
					outcome: ANSWER_ACTIONS_GREAT.BOOK_APPOINTMENT,
					appointmentId: _.get(appointment, 'id')
				}),
				...(comment ? { commentId: comment.id } : {})
			};

			await this.setAndSavePartialActivity({ outcome, closeDate: new Date() }, false, true);
		});
	};

	setAppointment = id => {
		const { activity } = this.state;
		const outcome = getOutcome(activity, {
			type: OUTCOME_TYPES.ANSWER,
			outcome: ANSWER_ACTIONS_GREAT.BOOK_APPOINTMENT,
			appointmentId: id
		});
		this.setAndSavePartialActivity({ outcome, closeDate: new Date() }, false, true)
			.then(() => {
				openEditAppointment({ id });
			})
			.catch(err => logError(err, 'Failed to open appointment'));
	};

	afterSaveOrder = async (order, commentDesc, isOrder) => {
		const { activity } = this.state;
		const comment = await this.createCommentIfExists(commentDesc, OUTCOME_TYPES.ANSWER);
		const outcome = {
			...getOutcome(activity, {
				type: OUTCOME_TYPES.ANSWER,
				outcome: isOrder ? ANSWER_ACTIONS_GREAT.CREATE_ORDER : ANSWER_ACTIONS_GREAT.CREATE_OPPORTUNITY
			}),
			...(comment ? { commentId: comment.id } : {})
		};

		await this.setAndSavePartialActivity(
			{ outcome, opportunity: { id: order.id }, closeDate: new Date() },
			false,
			true
		);
	};

	createOpportunity = commentDesc => {
		// eslint-disable-next-line promise/catch-or-return
		Tools.$upModal.open('editOrder', {
			type: 'opportunity',
			clientId: this.state.client.id,
			contactId: this.state.contact ? this.state.contact.id : null,
			campaign: this.state.activity.project,
			notes: this.state.activity.notes,
			source: {
				type: 'phonecall',
				id: this.state.activity.id
			},
			afterSave: order => this.afterSaveOrder(order, commentDesc, false)
		});
	};

	createOrder = commentDesc => {
		Tools.$upModal.open('editOrder', {
			clientId: this.state.client.id,
			contactId: this.state.contact ? this.state.contact.id : null,
			campaign: this.state.activity.project,
			notes: this.state.activity.notes,
			source: {
				type: 'phonecall',
				id: this.state.activity.id
			},
			afterSave: order => this.afterSaveOrder(order, commentDesc, true)
		});
	};

	planPhonecall = async commentDesc => {
		const { activity } = this.state;
		const outcome = getOutcome(activity, {
			type: OUTCOME_TYPES.ANSWER,
			outcome: ANSWER_ACTIONS_GREAT.PLAN_PHONE_CALL
		});

		const newDescription = T('activity.outcome.followUp') + activity.description;
		const source = { id: this.state.activity.id, type: TYPES.PHONECALL };

		openDrawer('CreateCall', {
			contact: this.state.contact,
			client: this.state.client,
			notes: activity.notes,
			initialDescription: newDescription,
			activity: activity,
			project: activity.project,
			source,
			//Should be disabled if the user is in focus mode
			disableSaveAndCreate: this.props.focus,
			onSave: async newActivity => {
				const comment = await this.createCommentIfExists(commentDesc, outcome.type);
				await Activity.save({
					...this.state.activity,
					outcome: { ...outcome, activityId: newActivity.id, ...(comment ? { commentId: comment.id } : {}) },
					closeDate: new Date()
				});
				if (this.props.focus) {
					this.iterateCallList(true);
				} else {
					this.props.close();
				}
			}
		});
	};

	onEmailSent = async isConfirmed => {
		if (isConfirmed) {
			this.onOutcomeConfirm();
		} else {
			const outcome = getOutcome(this.state.activity, this.state.pendingOutcome);
			await this.setAndSavePartialActivity({ outcome }, false, false);
			this.setState({ pendingOutcome: null });
		}
	};

	createTodoFollowUp = () => {
		const source = { id: this.state.activity.id, type: TYPES.PHONECALL };
		const prevActivity = this.state.activity;
		openDrawer('CreateTodo', {
			description: prevActivity.description,
			client: prevActivity.client,
			contact: this.state.contact,
			notes: prevActivity.notes,
			activity: prevActivity,
			source
		});
	};

	createCallFollowUp = () => {
		const source = { id: this.state.activity.id, type: TYPES.PHONECALL };
		const prevActivity = this.state.activity;
		openDrawer('CreateCall', {
			initialDescription: prevActivity.description,
			client: prevActivity.client,
			contact: this.state.contact,
			notes: prevActivity.notes,
			activity: prevActivity,
			project: prevActivity.project,
			source
		});
	};

	createActivityFollowUp = async isAppointment => {
		let item;
		const prevActivity = this.state.activity;
		if (isAppointment) {
			item = Tools.Appointment.new().data;
			item.activityType = null;
		} else {
			item = Tools.Activity.new().data;
			item.activityType = prevActivity.activityType;
		}
		item.description = prevActivity.description;
		item.notes = prevActivity.notes;
		item.client = this.state.client;
		item.users = [_.pick(Tools.AppService.getSelf(), ['id', 'name', 'role', 'email'])];
		item.contacts = prevActivity.contacts;
		item.parentActivityId = prevActivity.id;
		item.project = prevActivity.project;

		item.sourceId = prevActivity.id;
		item.sourceType = 'phonecall';

		if (isAppointment) {
			openEditAppointment({ appointment: item });
			this.props.close();
		} else {
			if (Tools.FeatureHelper.hasSoftDeployAccess('TODO_LIST')) {
				await Activity.save(item)
					.then(res => {
						if (res) {
							openDrawer('EditPhonecall', { activity: res.data });
							this.props.close();
						}
					})
					.catch(err => logError(err, 'Failed to create activity'));
			} else {
				// Should not be able to reach here, but just in case
				Tools.$upModal.open('editActivity', {
					activity: item
				});
				this.props.close();
			}
		}
	};

	outcomeContactChange = (removeOutcome = false) => {
		if (removeOutcome) {
			this.setState({ contact: null, pendingOutcome: null });
		} else {
			this.setState({ contact: null });
		}
		// buttonRef needs time to be set
		setTimeout(() => {
			if (this.buttonRef) {
				this.buttonRef.click();
			}
		}, 100);
	};

	onOutcomeConfirm = async () => {
		const { closeActivity, ...pendingOutcome } = this.state.pendingOutcome;
		const outcome = getOutcome(this.state.activity, pendingOutcome);
		const saveData = { outcome };
		if (closeActivity) {
			saveData.closeDate = new Date();
		}
		await this.setAndSavePartialActivity(saveData, false, true);
		this.setState({ pendingOutcome: null });
	};

	onOutcomeChange = (pendingOutcome, cb) => {
		this.setState({ pendingOutcome }, cb);
	};

	onPresetDateChange = date => {
		this.setState({ pendingDate: date, pendingTime: null });
	};

	onCreateContact = () => {
		const { activity, useAjaxContactSelect, contacts } = this.state;
		// eslint-disable-next-line promise/catch-or-return
		return Tools.$upModal.open('editContact', { account: activity.client }).then(contact => {
			this.getClientAndContacts();
			const state = { contact };
			// Set created contact as selected
			if (!useAjaxContactSelect) {
				state.contacts = [...contacts, contact];
			}

			this.setAndSavePartialActivity({
				contacts: [contact],
				outcome: getOutcome(this.state.activity, this.state.pendingOutcome)
			});
			this.setState(state);
		});
	};

	onEditClient = () => {
		const { client } = this.state;
		if (shouldOpenModal('EditClient')) {
			openModal('EditClient', {
				id: client.id,
				onClose: client => {
					if (client) {
						this.setState({ client });
					}
				}
			});
		} else {
			// eslint-disable-next-line promise/catch-or-return
			return Tools.$upModal.open('editAccount', { account: client }).then(client => {
				this.setState({ client });
			});
		}
	};

	onEditContact = () => {
		const { contact } = this.state;
		// eslint-disable-next-line promise/catch-or-return
		return Tools.$upModal.open('editContact', { id: contact.id }).then(contact => {
			this.setState({ contact });
		});
	};

	onCustomChange = (id, value) => {
		const customFields = [...this.state.customFields];
		const index = _.findIndex(this.state.customFields, { id: id });
		if (index !== -1) {
			customFields[index].value = value;
			this.setState({ customFields }, () => {
				this.setAndSavePartialActivityDebounced({ custom: customFields });
			});
		}
	};

	iterateCallList = increment => {
		const { focusData, tableData, focus, close } = this.props;
		const { activity } = this.state;

		[this.getActivityPromise, this.getClientPromise, this.getContactPromise, this.getContactsPromise].forEach(
			promise => {
				if (promise) promise.cancel();
			}
		);

		const iteratedList = focus ? focusData : tableData.filter(a => a.type === 'phonecall' && !a.closeDate);

		// Find the index of the current activity
		const currentIndex = iteratedList.findIndex(call => call.id === activity.id);

		// next or prev
		const add = increment ? 1 : -1;

		// Get next row
		const nxt = iteratedList[currentIndex + add];

		// If last element in list
		if (!nxt) {
			return close();
		}

		this.setState(
			{
				activity: nxt,
				contact: nxt.contacts && nxt.contacts.length ? nxt.contacts[0] : null,
				client: nxt.client,
				loading: true,
				pendingDate: undefined,
				pendingTime: null
			},
			this.getAdditionalData
		);
		this.updateModalActivity(nxt);
	};
	render() {
		const { close: originalClose, focus, focusData, className } = this.props;
		const {
			loading,
			closing,
			client,
			activity,
			clientLoading,
			contactsLoading,
			contacts,
			useAjaxContactSelect,
			savedBannerVisible,
			customFields,
			pendingOutcome,
			pendingDate,
			pendingTime
		} = this.state;
		const focusIndex = focus ? focusData.findIndex(a => a.id === activity.id) : -1;
		const classes = new BemClass('EditPhonecall', className);
		const self = Tools.AppService.getSelf();
		const hasSupportAndNotCrm = self.support && !self.crm;
		let missingClientActivities = !Tools.FeatureHelper.isAvailable(
			Tools.FeatureHelper.Feature.ACTIVITIES_AND_APPOINTMENTS
		);

		if (hasSupportAndNotCrm) {
			missingClientActivities = !Tools.FeatureHelper.isAvailable(
				Tools.FeatureHelper.Feature.CREATE_ENTITY_FROM_TICKET
			);
		}

		const close = e => {
			originalClose(e);
			this.setState({ closing: true });
		};

		return (
			<Block className={classes.b()} ref={this.wrapperRef}>
				<FocusMode isMounted={focus} closeDrawer={close} closing={closing} />
				<EditPhonecallHeader
					loading={loading}
					closeDrawer={close}
					date={activity.closeDate || activity.date}
					time={activity.time}
					client={client}
					user={(activity.users && activity.users[0]) || null}
					onUserChange={this.userChange}
					users={this.users}
					id={this.state.activity.id}
					onDelete={activity.userRemovable ? this.deleteActivity : null}
					savedBannerVisible={savedBannerVisible}
					closed={!!activity.closeDate}
					focus={focus}
					disabled={missingClientActivities}
				/>
				{loading || client ? (
					<Card className={classes.elem('client-contact-info').b()} borderColor="grey-6" border="bs">
						<Row>
							<Column className={classes.elem('client-contact-col').b()}>
								{loading && !this.state.contact ? (
									<EditPhonecallClientContactLoading />
								) : this.state.contact ? (
									<EditPhonecallContact
										contact={this.state.contact}
										client={client}
										onEditContact={this.onEditContact}
										closeDate={activity.closeDate}
										contactChange={() => this.outcomeContactChange(true)}
										closeDrawer={close}
										saveContact={this.saveContact}
										id={this.state.activity.id}
										missingAccess={this.state.missingClientAccess}
										disabled={missingClientActivities}
									/>
								) : (
									<EditPhonecallNoContact
										asAjax={useAjaxContactSelect}
										contactsLoading={contactsLoading}
										contacts={contacts}
										client={client}
										buttonRef={r => (this.buttonRef = r)}
										triggerShowSelect={this.state.openSelect}
										onContactSelect={this.contactChange}
										onCreateContact={this.onCreateContact}
										canCreateContacts={client.createRights ? client.createRights.Contact : false}
										anchor={this.wrapperRef.current}
										scrollContainer={document.getElementsByClassName('EditPhonecall')[0]}
										disabled={missingClientActivities}
									/>
								)}
							</Column>
							<Column className={classes.elem('client-contact-col').b()}>
								{loading && !client ? (
									<EditPhonecallClientContactLoading />
								) : client ? (
									<EditPhonecallClient
										client={client}
										clientLoading={clientLoading}
										onEditClient={this.onEditClient}
										saveClient={this.saveClient}
										closeDate={activity.closeDate}
										closeDrawer={close}
										id={this.state.activity.id}
										missingAccess={this.state.missingClientAccess}
										disabled={missingClientActivities}
									/>
								) : null}
							</Column>
						</Row>
					</Card>
				) : null}
				<Card className={classes.elem('content').b()}>
					<Row className={classes.elem('outcome-history-row').b()}>
						<Column size={6}>
							<PopupPortalAnchors
								anchor={this.wrapperRef.current}
								scrollContainerSelector=".EditPhonecallActivityOutcome__inner"
							>
								<EditPhonecallActivityOutcome
									contactsLoading={contactsLoading}
									contacts={contacts && contacts.length ? contacts : null}
									contact={this.state.contact}
									client={client}
									setContact={this.contactChange}
									changeContact={this.outcomeContactChange}
									activity={activity}
									onDescriptionChange={this.descriptionChange}
									onDateChange={this.dateChange}
									onTimeChange={this.timeChange}
									onPrioChange={this.prioChange}
									onPresetDateChange={this.onPresetDateChange}
									onEmailSent={this.onEmailSent}
									loading={loading}
									onBookAppointment={this.bookAppointment}
									onSetAppointment={this.setAppointment}
									onCreateOpportunity={this.createOpportunity}
									onCreateOrder={this.createOrder}
									onPlanPhonecall={this.planPhonecall}
									onCreateActivityFollowUp={this.createActivityFollowUp}
									onCreateCallFollowUp={this.createCallFollowUp}
									onCreateTodoFollowUp={this.createTodoFollowUp}
									confirm={this.onOutcomeConfirm}
									onChange={this.onOutcomeChange}
									pendingOutcome={!!pendingOutcome}
									focus={focus}
									isIterable={this.props.shouldIterateList}
									iterateCall={this.iterateCallList}
									dateAnchor={document.getElementsByClassName('EditPhonecall')[0]}
									dateScrollContainer={
										document.getElementsByClassName('EditPhonecallActivityOutcome__inner')[0]
									}
									pendingDate={pendingDate}
									hasPendingTime={!!pendingTime}
									disabled={missingClientActivities}
								/>
							</PopupPortalAnchors>
						</Column>
						<Column size={6}>
							<Block className={classes.elem('right').b()} border="ls" borderColor="grey-5">
								<Block className={classes.elem('right-top').b()} backgroundColor="grey-1">
									{missingClientActivities ? null : (
										<EditPhonecallTodos activity={this.state.activity} />
									)}
									{Tools.FeatureHelper.hasSoftDeployAccess('SUPPORT_SERVICE_V2') &&
									this.state.client?.id &&
									getFeatureAvailableFromState(this.state.accountSelf, Feature.CUSTOMER_SUPPORT) ? (
										<Block space="mtxl" className={classes.elem('unsetCard').b()}>
											<TicketWidget clientId={this.state.client.id} />
										</Block>
									) : null}

									<EditPhonecallNotes
										loading={loading}
										textareaProps={{ disabled: !!activity.closeDate }}
										notes={activity.notes}
										onChange={this.notesChange}
										textareaTooltipText={
											Tools.AppService.getMetadata().standardFields.Activity.Notes.tooltip
										}
										disabled={missingClientActivities}
									/>
									{!loading ? (
										<>
											<Block space="mtl">
												<RelationSelect
													label={T('relateTo.headline')}
													client={this.state.activity?.client}
													userId={activity.users?.[0]?.id || null}
													contact={
														this.state.activity?.contact ||
														this.state.activity?.contacts?.length
															? this.state.activity.contacts[0]
															: null
													}
													opportunityId={this.state.activity?.opportunity?.id}
													appointmentId={this.state.activity?.parentAppointmentId}
													activityId={this.state.activity?.parentActivityId}
													projectPlanId={this.state.activity?.projectPlan?.id}
													ticketId={this.state.activity?.ticketId}
													agreementId={this.state.activity?.agreementId}
													onChange={e => {
														this.setAndSavePartialActivityDebounced({
															opportunity: { id: e.opportunityId },
															parentAppointmentId: e.appointmentId,
															parentActivityId: e.activityId,
															projectPlan: { id: e.projectPlanId },
															ticketId: e.ticketId,
															agreementId: e.agreementId
														});
													}}
													// Shouldn't relate to itself
													filterOutRelations={[
														{ type: 'activity', id: this.state.activity?.id }
													]}
													disabled={missingClientActivities}
												/>
											</Block>
											<PhonecallRelationFields
												phonecall={activity}
												savePhonecall={this.saveActivity}
												disabled={missingClientActivities}
												anchor={this.wrapperRef.current}
											/>
											<PhonecallCustomFields
												disabled={missingClientActivities}
												customFields={customFields}
												onCustomChange={this.onCustomChange}
											/>
										</>
									) : null}
									{this.uiElements?.sidebar ? (
										<React.Fragment>
											<Row className={classes.elem('integrations-title').b()}>
												<Text size="xl" color="black">
													{T('default.integrations')}
												</Text>
											</Row>
											<UiElements
												elements={this.uiElements.sidebar}
												object={activity}
												type="editActivity"
											/>
										</React.Fragment>
									) : null}
								</Block>
							</Block>
						</Column>
					</Row>
				</Card>
				{focus && this.props.tableData?.filter(todo => todo.type === 'phonecall').length > 1 ? (
					<FocusModeFooter
						iterateCallList={this.iterateCallList}
						focusData={this.props.focusData}
						index={focusIndex}
					/>
				) : null}
			</Block>
		);
	}
}
export const detached = EditPhonecall;
export default connect(mapStateToProps, mapDispatchToProps)(EditPhonecall);
