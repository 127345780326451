import Resource from 'Resources/Resource';
import Client, { ClientAddress, ClientAddressType, RawClient } from './Model/Client';
import parseClient from './parsers/client';
import { Metadata } from 'App/babel/resources/ResourceTyped';

class ClientResource extends Resource {
	parse: (client: RawClient) => Client;
	eventName: string = 'account';
	constructor() {
		super('accounts', null, {
			notificationTitle: 'default.client'
		});

		this.eventName = 'account';

		this.parse = parseClient;
	}

	new(): Partial<Client> &
		Pick<
			Client,
			'name' | 'active' | 'userEditable' | 'categories' | 'addresses' | 'custom' | 'users' | 'projects'
		> {
		return {
			name: '',
			active: 1,
			userEditable: true,
			projects: [],
			categories: [],
			addresses: [],
			users: [],
			custom: []
		};
	}

	newAddress(type: keyof typeof ClientAddressType): ClientAddress {
		return {
			zipcode: '',
			city: '',
			state: '',
			address: '',
			type,
			country: '',
			latitude: null,
			longitude: null
		};
	}

	async find(filters?: { [key: string]: any }): Promise<{
		data: Client[];
		metadata: Metadata;
	}> {
		// Users are needed to calculate createRigths
		if (filters?.f && !filters.f.includes('users')) {
			filters.f.push('users');
		}
		return super.find(filters);
	}

	async unassign(clientId: number, userId: number): Promise<any> {
		//userId : customerID, clientID: accountID
		const assignedUrl = `/${userId}/function/assign/${clientId}`;
		this._url = '';
		const options = {
			url: assignedUrl,
			clientId,
			methodName: 'unassign',
			skipNotification: true
		};
		return this._deleteRequest(assignedUrl, options)
			.then(res => {
				Tools.NotificationService.addNotification({
					style: Tools.NotificationService.style.SUCCESS,
					icon: 'check',
					body: 'saved.unassignedDescription',
					title: 'saved.unassigned'
				});
				this._triggerEvent(options, 'unassigned', res, clientId);
				return res;
			})
			.finally(() => {
				this._url = 'accounts';
			});
	}
}

const resource = new ClientResource();

export default resource;
