import CompanyType from './CompanyType';
import CompanyForm from './CompanyForm';
import generateEmployeesFilter from './Employees';
import generateFinancialFilter from './Financials';
import generateLocationFilter from './Location';
import generateIndustryFilter from './Industry';
import generateIndustryCPVFilter from './IndustryCPV';
import Name from './Name';
import Keywords from './Keywords';
import Headquarters from './Headquarters';

export default () => ({
	CompanyType,
	CompanyForm,
	Industry: generateIndustryFilter('sniCode', 'SE', { filterName: 'Industry' }),
	IndustryGB: generateIndustryFilter('ukSicCode', 'GB', { filterName: 'IndustryGB' }),
	IndustryNO: generateIndustryFilter('naceCode', 'NO', { filterName: 'IndustryNO' }),
	IndustryDK: generateIndustryFilter('naceCode', 'DK', { filterName: 'IndustryDK' }),
	IndustryFI: generateIndustryFilter('naceCode', 'FI', { filterName: 'IndustryFI' }),
	IndustryCPV: generateIndustryCPVFilter('cpvCode', { filterName: 'IndustryCPV' }),
	Location: generateLocationFilter({ filterName: 'Location' }),
	LocationGB: generateLocationFilter({ filterName: 'LocationGB', displayType: 'prospectingAddressGB' }),
	LocationNO: generateLocationFilter({ filterName: 'LocationNO', displayType: 'prospectingAddressNO' }),
	LocationDK: generateLocationFilter({ filterName: 'LocationDK', minCount: 50, displayType: 'prospectingAddressNO' }),
	LocationFI: generateLocationFilter({ filterName: 'LocationFI', minCount: 50, displayType: 'prospectingAddressNO' }),
	Employees: generateEmployeesFilter(true, { filterName: 'Employees' }),
	EmployeesGB: generateEmployeesFilter(false, { filterName: 'EmployeesGB' }),
	EmployeesNO: generateEmployeesFilter(false, { filterName: 'EmployeesNO' }),
	Financials: generateFinancialFilter('SEK', true, { filterName: 'Financials' }),
	FinancialsGB: generateFinancialFilter('GBP', false, { filterName: 'FinancialsGB' }),
	FinancialsNO: generateFinancialFilter('NOK', false, { filterName: 'FinancialsNO' }),
	FinancialsDK: generateFinancialFilter('DKK', false, { filterName: 'FinancialsDK' }),
	FinancialsFI: generateFinancialFilter('EUR', false, { filterName: 'FinancialsFI' }),
	Name,
	Keywords,
	Headquarters,
	RegDate: {
		filterName: 'RegDate',
		field: 'registrationDate',
		title: 'default.founded',
		inputType: 'date',
		type: 'dateRange',
		entity: 'account',
		showOnSegment: true,
		presets: [
			'whenever',
			'currentMonth',
			'lastMonth',
			'currentQuarter',
			'lastQuarter',
			'currentYear',
			'lastYear',
			'custom'
		]
	},
	NotInUpsales: {
		filterName: 'NotInUpsales',
		title: 'In upsales',
		type: 'radio',
		entity: 'account',
		field: 'notInUpsales',
		showOnSegment: false,
		displayType: 'radio',
		inputType: 'boolean',
		options: [
			{ text: 'default.all', value: null, inactive: true },
			{ text: 'soliditet.allMatches', value: false },
			{ text: 'soliditet.doesNotExistinUpales', value: true }
		]
	}
});
