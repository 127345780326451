import React from 'react';
import BemClass from '@upsales/components/Utils/bemClass';
import Relation, { AvailableRelationConfig } from 'App/resources/Model/EntityRelation';
import { Card, ClickableItem, Icon, Tooltip } from '@upsales/components';
import './SelectedRelations.scss';
import { openDrawer } from 'Services/Drawer';
import { useSoftDeployAccess } from 'App/components/hooks';
import { useTranslation } from 'Components/Helpers/translate';
import { openEditAppointment } from 'Components/Modals/Appointment/EditAppointment';
import openAgreement from 'App/helpers/openSubscriptionHelper';
import { orderIcon } from '../helpers/relationMappers';

type Props = {
	relations: Relation[];
	removeRelation: (rel: Relation) => void;
	staticRelations?: Partial<AvailableRelationConfig>;
};

const SelectedRelations = ({ relations, removeRelation, staticRelations }: Props) => {
	const classes = new BemClass('SelectedRelations');
	const { t } = useTranslation();
	const hasRemoveActivites = useSoftDeployAccess('REMOVE_ACTIVITIES');
	const hasTodoList = useSoftDeployAccess('TODO_LIST');
	const hasProjectPlanCallsAndAppointments = useSoftDeployAccess('PROJECT_PLAN_CALLS_AND_APPOINTMENTS');

	const goToRelation = (relation: Relation) => {
		switch (relation.type) {
			case 'opportunity':
				Tools.$upModal.open('editOrder', { id: relation.id });
				break;
			case 'appointment':
				openEditAppointment({ id: relation.id });
				break;
			case 'activity':
				if (relation.activityType === 'phonecall') {
					openDrawer('EditPhonecall', { activity: { id: relation.id } });
				} else if (relation.activityType === 'todo' && hasTodoList) {
					openDrawer('EditTodo', { todo: { id: relation.id } });
				} else {
					if (!hasRemoveActivites) {
						Tools.$upModal.open('editActivity', { id: relation.id });
					} else {
						openDrawer('EditPhonecall', { activity: { id: relation.id } });
					}
				}
				break;
			case 'projectPlan':
				openDrawer('EditProjectPlan', { projectPlanId: relation.id });
				break;
			case 'ticket':
				openDrawer('EditTicket', { ticketId: relation.id });
				break;
			case 'agreement':
				openAgreement({ agreementId: relation.id });
				break;
			default:
				break;
		}
	};

	if (!hasProjectPlanCallsAndAppointments) {
		relations = relations.filter(relation => relation.type !== 'projectPlan');
	}

	const getTitleTooltip = (relation: Relation) => {
		let relationType = relation.activityType ?? relation.type;
		if (relation.icon === orderIcon) {
			relationType = 'order' as typeof relationType;
		}

		return `${t('relation.view')} ${t('default.' + relationType).toLowerCase()}`;
	};

	return (
		<Card className={classes.b()} borderRadius>
			{relations.map(relation => (
				<ClickableItem
					block
					size="lg"
					key={`${relation.type}-${relation.id}`}
					title={relation.title}
					titleTooltip={getTitleTooltip(relation)}
					subtitle={relation.subtitle}
					icon={relation.icon}
					className={classes.elem('row').b()}
					renderRight={() => {
						return staticRelations?.[relation.type] ? null : (
							<Tooltip title={t('client.removeRelation')}>
								<Icon
									name="times"
									onClick={e => {
										e.stopPropagation();
										removeRelation(relation);
									}}
								/>
							</Tooltip>
						);
					}}
					onClick={() => goToRelation(relation)}
				/>
			))}
		</Card>
	);
};

export default SelectedRelations;
