import { WidgetMetaProvider } from 'App/pages/Reportcenter/ReportcenterWidgetWithFetch/ReportcenterWidgetWithFetch';
import { RCWidgetFilter, RCDashboardFilterValue } from 'Resources/ReportDashboard';
import CustomerPortfolioCustomerTab from './CustomerPortfolioCustomerTab';
import SalesboardSumSettings from 'App/components/SalesboardSumSettings';
import CustomerPortfolioPotentialTab from './CustomerPortfolioPotentialTab';
import { customerPorfolioTracker } from 'App/babel/helpers/Tracker';
import CustomerPortfolioRiskTab from './CustomerPortfolioRiskTab';
import { Block, Tab, Tabs, IconName, Tooltip, Flex, Toggle, Text } from '@upsales/components';
import { useTranslation } from 'Components/Helpers/translate';
import { getActiveUsers } from 'Store/selectors/AppSelectors';
import { Feature } from 'Store/actions/FeatureHelperActions';
import CustomerPortfolioList from './CustomerPortfolioList';
import { hasCMWithRR } from 'App/helpers/salesModelHelpers';
import LocalStorage from 'Components/Helpers/LocalStorage';
import { comparisonTypes } from 'Resources/RequestBuilder';
import { useFeatureAvailable, useSoftDeployAccess } from 'App/components/hooks';
import BemClass from '@upsales/components/Utils/bemClass';
import { useStages } from 'App/components/hooks/appHooks';
import AddonBanner from './CustomerPortfolioAddonBanner';
import { ListViewFilter } from 'App/resources/AllIWant';
import { useIsFirstRender } from 'App/components/hooks';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { useFetchTabTotals } from './fetchHelper';
import logError from 'App/babel/helpers/logError';
import { useSelector } from 'react-redux';
import { RootState } from 'Store/index';
import StageFilter from './StageFilter';
import UserFilter from './UserFilter';
import Page from '../Page';

import './CustomerPortfolio.scss';

export type WidgetFilter = Omit<RCWidgetFilter, 'value'> & { value: RCDashboardFilterValue };
export type ListViewFilterMap = { [key: string]: ListViewFilter };
export type DisplayValueType = 'value' | 'contributionMargin' | 'annualValue' | 'monthlyValue' | 'oneOffValue';
export enum TabsEnum {
	Customers = 'Customers',
	Risk = 'Risk',
	Potential = 'Potential'
}

type TabConfig = {
	id: TabsEnum;
	title: string;
	subtitle: string;
	icon?: IconName;
	data: { [key: string]: number };
};

export function getValueTypeFromSalesModelOption(salesModelOption: string): DisplayValueType {
	const hasSubscriptionRights = Tools.AppService.getAccessRights().Agreement;

	switch (salesModelOption) {
		case 'arr':
			return hasSubscriptionRights ? 'annualValue' : 'value';
		case 'cm':
			return 'contributionMargin';
		case 'mrr':
			return hasSubscriptionRights ? 'monthlyValue' : 'value';
		case 'sales':
			return 'value';
		default:
			return 'value';
	}
}

export function initializeUserFilter(activeUsers: ReturnType<typeof getActiveUsers>): ListViewFilterMap {
	let userFilter = {
		comparisonType: 'Equals',
		field: 'user.id',
		filterName: 'User',
		inactive: true,
		valueText: '',
		value: []
	} as ListViewFilter;

	const localStorage = new LocalStorage();
	const savedFilterString = localStorage.getValueRaw('userFilter') as string | undefined;

	if (savedFilterString) {
		try {
			userFilter = JSON.parse(savedFilterString) as ListViewFilter;
			if (!Array.isArray(userFilter.value)) {
				userFilter.value = [];
			}
			userFilter.value = userFilter.value.filter((value: null | 'self' | number) => {
				return value === 'self' || value === null || !!activeUsers.find(user => user.id === value);
			});
			if (userFilter.value.length === 0) {
				userFilter.comparisonType = 'Equals';
				userFilter.inactive = true;
			}
		} catch (error) {
			logError(error, 'CustomerPortfolio: Failed to load user filter from local storage');
		}
	}

	return { User: userFilter };
}

export function initializeStageFilter(stages: ReturnType<typeof useStages>): ListViewFilterMap {
	let stageFilter = {
		comparisonType: 'Equals',
		field: 'stage.id',
		filterName: 'Stage',
		inactive: true,
		valueText: '',
		value: []
	} as ListViewFilter;

	if (Tools.FeatureHelper.hasSoftDeployAccess('CUSTOMER_PORTFOLIO_STAGE_FILTER')) {
		const excludedStageIds = stages.filter(stage => stage.exclude).map(stage => stage.id);

		if (excludedStageIds.length > 0) {
			stageFilter = {
				...stageFilter,
				comparisonType: 'NotEquals',
				inactive: false,
				value: excludedStageIds
			};
		}

		const localStorage = new LocalStorage();
		const savedFilterString = localStorage.getValueRaw('stageFilter') as string | undefined;

		if (savedFilterString) {
			try {
				const savedFilter = JSON.parse(savedFilterString) as ListViewFilter;
				stageFilter = {
					...stageFilter,
					...savedFilter,
					value: Array.isArray(savedFilter.value)
						? savedFilter.value.filter((value: number) => stages.find(stage => stage.id === value))
						: []
				};

				if (stageFilter.value.length === 0) {
					stageFilter.comparisonType = excludedStageIds.length > 0 ? 'NotEquals' : 'Equals';
					stageFilter.value = excludedStageIds;
					stageFilter.inactive = excludedStageIds.length === 0;
				}
			} catch (error) {
				logError(error, 'CustomerPortfolio: Failed to load stage filter from local storage');
			}
		}
	}

	return { Stage: stageFilter };
}

const getValidDisplayValues = (salesModelOption: string, salesModelOption3: string) => {
	const hasSubscriptionRights = Tools.AppService.getAccessRights().Agreement;
	const validDisplayTypes = ['contributionMargin', 'value'];
	if (hasSubscriptionRights) {
		if (salesModelOption === 'arr') {
			validDisplayTypes.push('annualValue');
		} else if (salesModelOption === 'mrr') {
			validDisplayTypes.push('monthlyValue');
		} else if (hasCMWithRR()) {
			if (salesModelOption3 === 'cmCombinedWithARR') {
				validDisplayTypes.push('annualValue');
			} else if (salesModelOption3 === 'cmCombinedWithMRR') {
				validDisplayTypes.push('monthlyValue');
			}
		}
	}
	return validDisplayTypes;
};

function initializeDisplayValueType(
	hasV2: boolean,
	salesModel: string,
	salesModelOption: string,
	salesModelOption3: string
): DisplayValueType {
	if (!hasV2) {
		return 'value';
	}

	const localStorage = new LocalStorage();
	const displayValueTypeKey = `salesboard.displayValueType-${salesModel}`;
	const savedDisplayValueType = (localStorage.getValueRaw(displayValueTypeKey) as DisplayValueType) ?? null;
	const validDisplayTypes = getValidDisplayValues(salesModelOption, salesModelOption3);
	return hasV2 && savedDisplayValueType && validDisplayTypes.includes(savedDisplayValueType)
		? savedDisplayValueType
		: getValueTypeFromSalesModelOption(salesModelOption);
}

function saveUserFilterToLocalStorage(filter: ListViewFilter) {
	const localStorage = new LocalStorage();
	const serializedFilter = JSON.stringify(filter);
	localStorage.setValueRaw('userFilter', serializedFilter);
}

function saveStageFilterToLocalStorage(filter: ListViewFilter) {
	const localStorage = new LocalStorage();
	const serializedFilter = JSON.stringify(filter);
	localStorage.setValueRaw('stageFilter', serializedFilter);
}

function saveDisplayValueTypeToLocalStorage(salesModel: string, val: DisplayValueType) {
	const localStorage = new LocalStorage();
	localStorage.setValueRaw(`salesboard.displayValueType-${salesModel}`, val);
}

function trackTabChange(selectedTab: TabsEnum) {
	if (selectedTab === TabsEnum.Customers) {
		customerPorfolioTracker.track(customerPorfolioTracker.events.CLICKED_CUSTOMER_TAB);
	} else {
		customerPorfolioTracker.track(customerPorfolioTracker.events.CLICKED_RISK_TAB);
	}
}

export function apiFilterToWidgetFilter(apiFilter: ListViewFilter): WidgetFilter {
	return {
		attribute: apiFilter.filterName === 'Stage' ? 'stage' : 'user',
		comparison: apiFilter.comparisonType ? comparisonTypes[apiFilter.comparisonType] : comparisonTypes.Equals,
		value: apiFilter.value.map((value: string | number) => (value === 'self' ? '{{self}}' : value))
	};
}

function trackUserFilterChange(isFirstRender: boolean, filters: ListViewFilterMap) {
	const event = isFirstRender
		? customerPorfolioTracker.events.NR_USERS_FILTERED_ON
		: customerPorfolioTracker.events.SWITCHED_NR_USERS_FILTERED_ON;
	const number = filters.User.value.length || 'all';
	customerPorfolioTracker.track(event, { number });
}

function trackStageFilterChange(isFirstRender: boolean, filters: ListViewFilterMap) {
	const event = isFirstRender
		? customerPorfolioTracker.events.NR_STAGES_FILTERED_ON
		: customerPorfolioTracker.events.SWITCHED_NR_STAGES_FILTERED_ON;
	const number = filters.Stage.value.length || 'all';
	customerPorfolioTracker.track(event, { number });
}

function trackDisplayValueTypeChange(isFirstRender: boolean, displayValueType: DisplayValueType) {
	const event = isFirstRender
		? customerPorfolioTracker.events.SALES_MODEL
		: customerPorfolioTracker.events.SWITCHED_SALES_MODEL;
	customerPorfolioTracker.track(event, { valueType: displayValueType });
}

function getTabFromUrlParams(params: { tab?: string }) {
	switch (params.tab) {
		case 'risks':
			return TabsEnum.Risk;
		case 'potential':
			return TabsEnum.Potential;
		case 'customers':
		default:
			return TabsEnum.Customers;
	}
}

function getPathFromTab(tab: TabsEnum) {
	switch (tab) {
		case TabsEnum.Risk:
			return 'risks';
		case TabsEnum.Customers:
			return 'customers';
		case TabsEnum.Potential:
			return 'potential';
		default:
			return '';
	}
}

const CustomerPortfolio = ({ match, history }: RouteComponentProps<{ tab?: string }>) => {
	const classes = new BemClass('CustomerPortfolio', 'Page');

	// Force 'sales' until we release V2
	const metadataParams = useSelector(({ App }: RootState) => App.metadata?.params);
	const activeUsers = getActiveUsers();
	const stages = useStages();

	const hasV2 = useSoftDeployAccess('CUSTOMER_PORTFOLIO_V2');
	const salesModel = hasV2 ? metadataParams?.SalesModel ?? 'sales' : 'sales';
	const salesModelOption = hasV2 ? metadataParams?.SalesModelOption ?? 'sales' : 'sales';
	const salesModelOption3 = metadataParams?.SalesModelOption3 ?? '';
	const showSalesModel = hasV2 && (salesModelOption !== 'sales' || hasCMWithRR());
	const showUserFilter = hasV2 && Tools.FeatureHelper.hasSoftDeployAccess('CUSTOMER_PORTFOLIO_USER_FILTER');
	const hasSubAccounts = useSoftDeployAccess('SUB_ACCOUNTS');
	const hasSubaccountsV2 = useSoftDeployAccess('SUB_ACCOUNTS_V2') && hasSubAccounts;
	const [includeSubaccounts, setIncludeSubaccounts] = useState(true);
	const showStageFilter = hasV2 && Tools.FeatureHelper.hasSoftDeployAccess('CUSTOMER_PORTFOLIO_STAGE_FILTER');

	const { t } = useTranslation();
	const [selectedTab, setSelectedTab] = useState(getTabFromUrlParams(match.params));
	type Filters = {
		User: ListViewFilter;
		Stage: ListViewFilter;
	};
	const [filters, setFilters] = useState<Filters>(() => ({
		User: initializeUserFilter(activeUsers).User,
		Stage: initializeStageFilter(stages).Stage
	}));
	const [widgetFilters, setWidgetFilters] = useState<WidgetFilter[]>([
		apiFilterToWidgetFilter(filters.User),
		apiFilterToWidgetFilter(filters.Stage)
	]);
	const [displayValueType, setDisplayValueType] = useState(() =>
		initializeDisplayValueType(hasV2, salesModel, salesModelOption, salesModelOption3)
	);
	const { customerAmount, riskCustomerAmount, upsellAmount } = useFetchTabTotals(displayValueType, filters);

	const trialPeriod = 7;
	const hasProspectingPRO = useFeatureAvailable(Feature.PROSPECTING_PRO);
	const showAddonBanner = selectedTab === TabsEnum.Risk && !hasProspectingPRO;

	const isFirstRender = useIsFirstRender();
	useEffect(() => trackTabChange(selectedTab), [selectedTab]);
	useEffect(() => trackUserFilterChange(isFirstRender, filters), [filters.User]);
	useEffect(() => trackStageFilterChange(isFirstRender, filters), [filters.Stage]);
	useEffect(() => trackDisplayValueTypeChange(isFirstRender, displayValueType), [displayValueType]);
	useEffect(() => {
		if (!isFirstRender) {
			history.replace(`/customerPortfolio/${getPathFromTab(selectedTab)}`);
		}
	}, [selectedTab]);

	function userFilterChanged(userFilter: ListViewFilter) {
		saveUserFilterToLocalStorage(userFilter);
		const updatedFilters = { ...filters, User: userFilter };
		setFilters(updatedFilters);
		setWidgetFilters([apiFilterToWidgetFilter(userFilter), apiFilterToWidgetFilter(filters.Stage)]);
	}

	function stageFilterChanged(stageFilter: ListViewFilter) {
		saveStageFilterToLocalStorage(stageFilter);
		const updatedFilters = { ...filters, Stage: stageFilter };
		setFilters(updatedFilters);
		setWidgetFilters([apiFilterToWidgetFilter(filters.User), apiFilterToWidgetFilter(stageFilter)]);
	}

	function valueTypeChanged(val: DisplayValueType) {
		saveDisplayValueTypeToLocalStorage(salesModel, val);
		setDisplayValueType(val);
	}

	const tabConfigs: TabConfig[] = [
		{
			id: TabsEnum.Customers,
			title: 'customerPortfolio.header.tab.customer.title',
			subtitle: 'customerPortfolio.header.tab.customer.subtitle',
			data: {
				nrOfCustomers: customerAmount
			}
		}
	];

	if (Tools.FeatureHelper.hasSoftDeployAccess('CUSTOMER_PORTFOLIO_RISK')) {
		tabConfigs.push({
			id: TabsEnum.Risk,
			title: 'customerPortfolio.header.tab.risk.title',
			subtitle: hasProspectingPRO
				? 'customerPortfolio.header.tab.risk.subtitle'
				: t('admin.billing.tryForFree.trialPeriod', { trialPeriod }),
			icon: hasProspectingPRO ? undefined : 'pro',
			data: {
				nrOfCustomers: riskCustomerAmount
			}
		});
	}

	if (Tools.FeatureHelper.hasSoftDeployAccess('CUSTOMER_PORTFOLIO_POTENTIAL')) {
		tabConfigs.push({
			id: TabsEnum.Potential,
			title: 'customerPortfolio.header.tab.upsell.title',
			subtitle: 'customerPortfolio.header.tab.upsell.subtitle',
			data: {
				nrOfCustomers: upsellAmount
			}
		});
	}

	return (
		<Page className={classes.b()} fullHeight={true}>
			<div className={classes.elem('header').b()}>
				<Block backgroundColor="white">
					<Tabs
						onChange={tab => setSelectedTab(tab as TabsEnum)}
						selected={selectedTab}
						noFlex
						style={{ verticalAlign: 'bottom' }}
						className={classes.elem('tabs').b()}
					>
						{tabConfigs.map((tab, i) => (
							<Tab
								key={i}
								id={tab.id}
								title={t(tab.title)}
								subtitle={t(tab.subtitle, tab.data)}
								icon={tab.icon}
							/>
						))}
					</Tabs>
				</Block>
				<Block backgroundColor="white">
					{hasSubaccountsV2 && selectedTab !== TabsEnum.Potential ? (
						<Tooltip position="bottom" title={t('reportcenter.showSubAccounts.tooltip')}>
							<Flex space="mtl" gap="u2">
								<Toggle
									checked={!includeSubaccounts}
									onChange={() => setIncludeSubaccounts(!includeSubaccounts)}
								></Toggle>
								<Text space="mrl">{t('reportcenter.showSubAccounts')}</Text>
							</Flex>
						</Tooltip>
					) : null}
					{showUserFilter ? <UserFilter filter={filters.User} onChange={userFilterChanged} /> : null}
					{showStageFilter && selectedTab !== TabsEnum.Potential ? (
						<StageFilter filter={filters.Stage} onChange={stageFilterChanged} />
					) : null}
					{showSalesModel ? (
						<SalesboardSumSettings
							valueType={displayValueType}
							setDisplayValueType={valueTypeChanged}
							salesModel={!!salesModel}
							salesModelOption={salesModelOption}
							salesModelOption3={salesModelOption3 ?? ''}
							hideOneOffValue={true}
							hideWeightedValue={true}
							closeOnSelect={true}
							alignRight={true}
						/>
					) : null}
				</Block>
			</div>
			<div className={selectedTab !== TabsEnum.Potential ? classes.elem('content').b() : ''}>
				<WidgetMetaProvider>
					{selectedTab === TabsEnum.Customers ? (
						<CustomerPortfolioCustomerTab
							valueType={displayValueType}
							filters={widgetFilters}
							aggregateSubAccounts={includeSubaccounts}
						/>
					) : null}
					{selectedTab === TabsEnum.Risk ? (
						<CustomerPortfolioRiskTab
							hasProspectingPRO={hasProspectingPRO}
							valueType={displayValueType}
							filters={widgetFilters}
							aggregateSubAccounts={includeSubaccounts}
						/>
					) : null}
					{selectedTab === TabsEnum.Potential ? (
						<CustomerPortfolioPotentialTab
							valueType={displayValueType}
							filters={filters}
							aggregateSubAccounts={includeSubaccounts}
						/>
					) : null}
				</WidgetMetaProvider>
			</div>
			{showAddonBanner ? (
				<AddonBanner className={classes.elem('addon-banner').b()} riskCustomerAmount={riskCustomerAmount} />
			) : selectedTab !== TabsEnum.Potential ? (
				<CustomerPortfolioList
					tab={selectedTab}
					valueType={displayValueType}
					filters={filters}
					aggregateSubAccounts={includeSubaccounts}
				/>
			) : null}
		</Page>
	);
};

export default CustomerPortfolio;
