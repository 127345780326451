import { asyncModalAdapter } from 'App/helpers/angularPortingHelpers';

export const openMultiUpdateClientModal = asyncModalAdapter({
	upModalName: 'UpdateClientMulti',
	openModalName: 'MultiUpdateClient',
	featureFlag: 'REACT_MULTI_UPDATE_CLIENT',
	rejectOnEvent: true
});

export const openUpdateClientAction = asyncModalAdapter({
	upModalName: 'UpdateClientAction',
	openModalName: 'MultiUpdateClient',
	featureFlag: 'UPDATE_CLIENT_ACTION_REACT',
	rejectOnEvent: true
});
