import EditOrderContext from '../context';
import { useContext } from 'react';

const useEditOrderContext = () => {
	const context = useContext(EditOrderContext);

	if (typeof context === 'undefined') {
		throw new Error('useEditOrder must be used within a Provider');
	}

	return context;
};

export default useEditOrderContext;
