import ResourceTyped from 'Resources/ResourceTyped';

export type LandingPageTemplate = {
	contactId: number;
	relatedToClientId: number;
	description: string;
	id: number;
};

class LandingPageTemplateResource extends ResourceTyped<LandingPageTemplate> {
	eventName = 'landingPageTemplate';
	constructor() {
		super('landingPageTemplate');
	}
}

const landingPageTemplateResource = new LandingPageTemplateResource();

export default landingPageTemplateResource;
