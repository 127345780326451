import Periodization from 'App/babel/components/Periodization/index';
import React, { useEffect, useState } from 'react';
import { setOrder } from 'Store/reducers/PeriodizationReducer';
import { useAppDispatch } from 'App/components/hooks';

import type Order from 'App/resources/Model/Order';

const PeriodizationWrap = ({ toggle, order }: { toggle: () => void; order: Order }) => {
	const [storeInitialized, setStoreInitialized] = useState(false);
	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(setOrder(order));
		setStoreInitialized(true);
	}, []);

	return storeInitialized 
		? <Periodization toggle={toggle} />
		: null;
};

export default PeriodizationWrap;
