import React from 'react';
import Tooltip from './Tooltip';
import useDropdownToggle from '../hooks/useDropdownToggle';
import { useTranslation } from 'react-i18next';
// @ts-ignore
import classNames from 'classnames';

import type { DocumentTemplate } from 'App/resources/AllIWant';

type Props = {
	disabled: boolean;
	documentTemplates: DocumentTemplate[];
	createDocument: (template: DocumentTemplate) => void;
	tooltip: string;
};

const DocumentTemplateDropdown = ({ documentTemplates, disabled, createDocument, tooltip }: Props) => {
	const { t } = useTranslation();

	const { dropdownOpen, toggleDropdown } = useDropdownToggle();

	return (
		<Tooltip title={tooltip} dynamic={true}>
			<div
				className={classNames('dropdown dropup btn-group pull-left', {
					open: dropdownOpen
				})}
			>
				<button
					type="button"
					className="btn up-btn btn-green no-shadow btn-lined dropdown-toggle"
					disabled={disabled}
					onClick={toggleDropdown}
				>
					<b className="fa fa-file"></b>
					{` ${t('default.create')} ${t('default.document').toLowerCase()} `}
					<b className="fa fa-caret-up"></b>
				</button>

				<ul className="dropdown-menu up-dropdown-white limited">
					{documentTemplates.map(template => (
						<li key={template.id}>
							<a onClick={() => createDocument(template)}>{template.name}</a>
						</li>
					))}
				</ul>
			</div>
		</Tooltip>
	);
};

export default DocumentTemplateDropdown;
