import { Modal, ModalContent, ModalControls, ModalHeader } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import colorMappings from '@upsales/components/Utils/colorMappings';
import getAngularModule from 'App/babel/angularHelpers/getAngularModule';
import PdfTemplateResource from 'App/babel/resources/pdfTemplates';
import { locales } from 'App/helpers/regionHelpers';
import MailAccountResource from 'App/resources/MailAccount';
import openModal from 'App/services/Modal/Modal';
import { useTranslation } from 'Components/Helpers/translate';
import UpCountry from 'Components/Inputs/UpCountry';
import TextEditor from 'Components/TextEditor/TextEditor';
import logError from 'Helpers/logError';
import { makeCancelable } from 'Helpers/promise';
import { validateSender } from 'Store/actions/helpers/sharedMailActions';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import LanguageSelect from '../Inputs/Selects/LanguageSelect';
import { ModalProps } from '../Modals/Modals';
import { useForceRender, useSelector } from '../hooks';
import { useUsers } from '../hooks/appHooks';
import MultiEmailSelect from './MultiEmailSelect';

type Props = {
	entity: string;
	tagEntity: string;
	extraTags: any[];
	properties: any[];
	prefill?: any;
	removeAction?: (actionIndex: number) => void;
	actionIndex: number;
	isFlow?: boolean;
	isTrigger?: boolean;
} & ModalProps<any>;

const SendEmailAction = ({
	className,
	entity,
	tagEntity,
	extraTags,
	isTrigger,
	prefill,
	properties: propertiesIn,
	removeAction,
	actionIndex,
	close
}: Props) => {
	const classes = new BemClass('send-email-action', className);
	const { t } = useTranslation();
	const { triggerRender } = useForceRender();

	const body = useRef('');
	const [properties, setProperties] = useState<Record<string, any>>({});
	const [emails, setEmails] = useState<{ id: number; email: string }[]>([]);
	const [selectedTemplate, setSelectedTemplate] = useState<any>();

	const [language, setLanguage] = useState<string | null>(null);
	const [languageOptions, setLanguageOptions] = useState<string[]>();
	const [showLanguageOptions, setShowLanguageOptions] = useState(false);

	const [countryRegion, setCountryRegion] = useState<(typeof locales)[0]>();

	const [exampleNumberFormat, setExampleNumberFormat] = useState<string>();
	const [exampleDateTimeFormat, setExampleDateTimeFormat] = useState<string>();

	const [documentTemplates, setDocumentTemplates] = useState<any[]>([]);
	const [templateSelectOptions, setTemplateSelectOptions] = useState<any>();

	const [ckEditorOptions, setCkEditorOptions] = useState<any>();

	const [loaded, setLoaded] = useState(false);
	const [saving, setSaving] = useState(false);
	const isValid = properties.FromAddress && properties.ToAddresses?.length && properties.Subject && body.current;

	const users = useUsers('active');

	const { userLocale, docuemntTemplateGroups } = useSelector(({ App }) => ({
		userLocale: App.self?.userParams.locale,
		docuemntTemplateGroups: App.documentTemplates
	}));

	const setFromAddress = (email: any) => {
		setProperties(prev => ({ ...prev, FromAddress: email }));
	};

	const setToAddresses = (emails: any) => {
		setProperties(prev => ({ ...prev, ToAddresses: emails }));
	};

	const setSubject = (subject: string) => {
		setProperties(prev => ({ ...prev, Subject: subject }));
	};

	const setBody = (value: string) => {
		body.current = value;
		triggerRender();
	};

	const loggedinUserRegion = locales.find(language => language.locale === userLocale);
	const isEdit = !!propertiesIn;

	const changeNumberDateFormats = (selectedLocale: (typeof locales)[0]) => {
		const exampleDateTimeFormat = moment().locale(selectedLocale.locale);
		setExampleDateTimeFormat(exampleDateTimeFormat.format('L LT'));
		const currencyOptions = {
			style: 'currency',
			currency: 'SEK',
			currencyDisplay: 'code'
		} as const;

		setExampleNumberFormat(new Intl.NumberFormat(selectedLocale.locale, currencyOptions).format(12345.99));
	};

	const createSelect = function (documentTemplates: any[], properties: any) {
		if (properties.Document) {
			const selectedTemplate = documentTemplates.find(template => {
				if (properties.TemplateUuid) {
					return template.templateId === parseInt(properties.Document);
				}
				return template.id === parseInt(properties.Document);
			});
			setProperties(prev => ({ ...prev, Document: selectedTemplate }));
			setSelectedTemplate(selectedTemplate);

			const isPdfTemplate = selectedTemplate.languages ? true : false;
			if (isPdfTemplate) {
				setShowLanguageOptions(true);
				const language = selectedTemplate.languages.find(
					(language: any) => language.id === properties.TemplateLanguage
				);
				const countryRegion = locales.find(language => language.locale === properties.TemplateCountryRegion);
				setCountryRegion(countryRegion);
				setLanguage(language ? language.id : null);
				setLanguageOptions(selectedTemplate.languages?.map((lang: any) => lang.id));
				if (countryRegion) {
					changeNumberDateFormats(countryRegion);
				}
			}
		}
	};

	useEffect(() => {
		const promises: Promise<any>[] = [];
		const allTemplates: any[] = [];
		let templates: any[] = docuemntTemplateGroups['client'],
			fromAddress: string,
			finalProps: any = {};

		if (propertiesIn) {
			finalProps = propertiesIn.reduce((propertyMap, prop) => {
				let propValue: any = prop.value;
				if (prop.name === 'FromAddress') {
					propValue = { id: '-1', email: prop.value };
					fromAddress = prop.value;
				}
				if (prop.name === 'ToAddresses' && typeof prop.value === 'string') {
					propValue = prop.value
						.split(';')
						.map((email: string) => ({ id: email.trim(), text: email.trim() }));
				}
				if (prop.name === 'Body') {
					body.current = prop.value;
				}
				propertyMap[prop.name] = propValue;
				return propertyMap;
			}, {});
		}

		if (isTrigger && tagEntity) {
			const documentEntity = tagEntity === 'appointment' ? 'activity' : tagEntity;
			if (documentEntity === 'activity' || documentEntity === 'order' || documentEntity === 'agreement') {
				if (docuemntTemplateGroups[documentEntity].length) {
					templates = templates.concat(docuemntTemplateGroups[documentEntity]);
				}
			}
			templates = templates.map(template => {
				let langTag = 'default.' + template.type;
				if (template.type === 'client') {
					langTag = 'feature.companiesAndContacts';
				} else if (template.type === 'activity') {
					langTag = 'default.activityAndAppointment';
				}
				template.group = t(langTag);
				return template;
			});

			allTemplates.push({
				name: t('column.userId'),
				children: templates
			});

			if (tagEntity === 'order') {
				const pdfTemplatePromise = PdfTemplateResource.find()
					.then(response => {
						const PdfTemplates: any[] = [];
						response.data.forEach((template: any) => {
							if (template.active === true) {
								PdfTemplates.push({
									id: template.templateData.id,
									templateId: template.id,
									name: template.templateData.template_name,
									roles: template.roles,
									group: t('admin.customTemplates'),
									type: 'order',
									templateType: template.type,
									languages: template.templateData.template_details.language || null
								});
							}
						});
						allTemplates.push({
							name: t('admin.customTemplates'),
							children: PdfTemplates
						});
						templates = templates.concat(PdfTemplates);
						setDocumentTemplates(templates);
						setTemplateSelectOptions(allTemplates);
						createSelect(templates, finalProps);
					})
					.catch(function (err) {
						logError(err, 'Could not load pdfTemplates');
					});

				promises.push(pdfTemplatePromise);
			} else {
				setDocumentTemplates(templates);
				setTemplateSelectOptions(allTemplates);
				createSelect(templates, finalProps);
			}

			let mailAccountPromise = MailAccountResource.get();
			mailAccountPromise = Promise.all([
				mailAccountPromise,
				// This is so freaking slow when using sendgrid so I make sure we only do it if we are going to send with Halon
				mailAccountPromise.then(({ data: mailAccount }) =>
					mailAccount.useHalon ? MailAccountResource.domains() : { data: [] }
				)
			])
				.then(([{ data: mailAccount }, { data: domains }]) => {
					const mappedDomains = domains.reduce(
						(domainMap, { domain, valid }) => ((domainMap[domain] = valid), domainMap),
						{} as Record<string, boolean>
					);
					const emails = users.filter(user => {
						if (user.email && validateSender(mailAccount, mappedDomains, user.email).valid) {
							if (fromAddress === user.email) {
								setFromAddress(user);
							}
							return true;
						}
						return false;
					});
					setEmails([{ id: -1, email: 'no-reply@upsales.com' }, ...emails]);
				})
				.catch(function (err) {
					logError(err, 'Could not load email addresses');
				});

			promises.push(mailAccountPromise);
		}

		if (prefill) {
			const prefillProperties: any = {};
			if (prefill.subject) {
				prefillProperties.Subject = prefill.subject;
			}
			if (prefill.from) {
				prefillProperties.from = prefill.from;
			}
			if (prefill.to) {
				prefillProperties.to = prefill.to;
			}
			if (prefill.body) {
				prefillProperties.Body = prefill.body;
				if (typeof prefillProperties.Body === 'string') {
					prefillProperties.Body = prefillProperties.Body.replace(/\r?\n/g, '<br />');
					prefillProperties.Body = prefillProperties.Body.split('<br /><br />').join('<br />'); // Lucas style
					prefillProperties.Body = prefillProperties.Body.split('<br />		').join(''); // Lucas style 2.0
					prefillProperties.Body = prefillProperties.Body.split('<br />	').join(''); // Lucas style 2.1
					prefillProperties.Body = prefillProperties.Body.replace('<br />&nbsp;<br />', '<br />'); // etc
				}
				body.current = prefillProperties.Body;
			}

			finalProps = { ...finalProps, ...prefillProperties };
		}

		if (!finalProps.FromAddress) {
			finalProps.FromAddress = { id: '-1', email: 'no-reply@upsales.com' };
		}

		const editorOptions: any = {
			height: '400px',
			enterMode: window.CKEDITOR.ENTER_BR,
			fileEntity: 'mail',
			shiftEnterMode: window.CKEDITOR.ENTER_BR,
			tagEntity: tagEntity
		};

		if ((extraTags?.length ?? 0) > 0) {
			editorOptions.extraTagGroups = {
				title: t('form.form'),
				group: 'FormValues',
				sort: 7,
				tags: extraTags.map(function (tag) {
					return {
						name: tag.text,
						value: tag.value,
						insertTag: function () {
							const htmlObject = document.createElement('div');

							htmlObject.innerHTML = body.current;
							const table = htmlObject.querySelector<HTMLTableElement>(
								'[field-table-exists-for-real=true]'
							);
							if (table) {
								const length = table.rows.length;
								const backgroundColor =
									length % 4 === 0 ? colorMappings.get('white') : colorMappings.get('grey-2');
								const textRow = table.insertRow(length - 1);
								textRow.innerHTML = `<td style="font-family:arial;size:13px;padding:4px;background-color:${backgroundColor}"><font face="arial" size="2"><b>${tag.text}</b></font></td>`;
								const row = table.insertRow(length);
								row.innerHTML = `<td style="font-family:arial;size:13px;padding:4px;background-color:${backgroundColor}"><font face="arial" size="2">${tag.value}</font></td>`;
								setBody(htmlObject.innerHTML);
								return true;
							}
							return false;
						}
					};
				})
			};
		}
		setCkEditorOptions(getAngularModule('EditorCk').getOptions(editorOptions));

		setProperties(finalProps);
		const cancelablePromise = makeCancelable(Promise.all(promises));

		cancelablePromise.promise.catch(() => {}).finally(() => setLoaded(true));

		return () => {
			cancelablePromise.cancel();
		};
	}, []);

	const displayTemplate = function (template: any) {
		return _.escape(template.name);
	};

	const displayEmail = function (user: any) {
		return _.escape(user.email);
	};

	const selectTemplate = (templatedId?: string) => {
		if (templatedId) {
			const selectedTemplate = documentTemplates.find(template => template.id === templatedId);
			setProperties({ ...properties, Document: selectedTemplate });
			setSelectedTemplate(selectedTemplate);
			if (selectedTemplate?.templateId) {
				setShowLanguageOptions(true);
				setLanguageOptions(selectedTemplate?.languages.map((lang: any) => lang.id));
				setLanguage(
					selectedTemplate.languages && selectedTemplate.languages.length > 0
						? selectedTemplate.languages[0].id
						: null
				);
			}
			if (loggedinUserRegion) {
				setCountryRegion(loggedinUserRegion);
				changeNumberDateFormats(loggedinUserRegion);
			}
		} else {
			setSelectedTemplate(undefined);
			setLanguageOptions(undefined);
			setLanguage(null);
			setShowLanguageOptions(false);
		}
	};

	const contryRegionChange = (countryRegion: any) => {
		setCountryRegion(countryRegion);
		changeNumberDateFormats(countryRegion);
	};

	const save = () => {
		setSaving(true);
		const doSave = () => {
			const mappedProperties = { ...properties };
			mappedProperties.Body = body.current;
			if (mappedProperties.FromAddress) {
				mappedProperties.FromAddress = properties.FromAddress.email;
			}
			mappedProperties.ToAddresses = mappedProperties.ToAddresses.map(
				(email: { id: string; text: string }) => email.text
			).join('; ');
			mappedProperties.HTMLContentType = 'true';
			if (selectedTemplate) {
				mappedProperties.Document = Number.isInteger(selectedTemplate.id)
					? selectedTemplate.id
					: selectedTemplate.templateId;
				mappedProperties.TemplateLanguage = language ? language : '';
				mappedProperties.TemplateCountryRegion = countryRegion ? countryRegion.locale : '';
				mappedProperties.TemplateType = selectedTemplate.templateType ? selectedTemplate.templateType : '';
				mappedProperties.TemplateUuid = Number.isInteger(selectedTemplate.id) ? '' : selectedTemplate.id;
			} else {
				delete mappedProperties.Document;
			}

			// map properties
			const propertyArray = Object.entries(mappedProperties).map(([name, value]) => {
				return { name, value };
			});

			close(propertyArray);
		};

		// Check for tags that start or end with only one curly brace
		const tagPattern = /([^{]\{\w+\.\w+\}\})|(\{\{\w+\.\w+\}(?!\}))/g;
		const matches = properties.Body.match(tagPattern);

		if (matches?.length) {
			openModal('Alert', {
				title: t('alerts.invalidTagsDetectedTitle'),
				body: t('alerts.invalidTagsDetectedBody', { tags: matches.join('\n') }),
				confirmButtonText: t('alerts.saveAnyways'),
				headerIcon: 'warning',
				onClose: confirmed => {
					if (confirmed) {
						doSave();
					}
				}
			});
		} else {
			doSave();
		}
	};

	const flagFormat = (obj: any, container: any, escape: any) => {
		var translated = obj.translated || t(obj.lang);
		var countryCode = obj.code || obj.country;
		return (
			'<span><i style="margin-right: 5px;" class="flag-icon flag-icon-' +
			escape(countryCode.toLowerCase()) +
			'"></i>' +
			escape(translated) +
			'</span>'
		);
	};

	const countryOptions = {
		getter: function () {
			let data = _.map(_.cloneDeep(locales), function (loc: any) {
				loc.translated = t(loc.lang);
				return loc;
			});

			data = _.sortBy(locales, 'translated');
			return Promise.resolve({ data, metadata: { total: data.length } });
		},
		countryCodes: locales,
		formatSelection: flagFormat,
		formatResult: (obj: any, container: any, query: any, escape: any) => flagFormat(obj, container, escape)
	};

	return (
		<Modal className={classes.b()} size="xl">
			<ModalHeader title={t('automationAction.SendEmail')} onClose={() => close()} />
			<ModalContent>
				<form>
					{loaded ? (
						<fieldset style={{ maxWidth: '1220px', margin: '0 auto', padding: '16px' }}>
							<div className="input-stack">
								{/* from */}
								{emails.length ? (
									<div className="input-group col-md-12">
										<span className="input-group-addon">
											<b className="text-danger">{'*'}</b>
											{t('mail.from')}
										</span>
										<ReactTemplates.INPUTS.upSelect
											id="field_from"
											className="trigger-select from-select"
											required
											multiple={false}
											options={{
												data: emails
											}}
											defaultValue={properties.FromAddress}
											formatSelection={displayEmail}
											formatResult={displayEmail}
											matcher={(term, text, op) =>
												op.email.toUpperCase().indexOf(term.toUpperCase()) >= 0
											}
											onChange={e => setFromAddress(e.target.value)}
										/>
									</div>
								) : null}
								{/* to */}
								<div className="input-group col-md-12">
									<span className="input-group-addon">
										<b className="text-danger">{'*'}</b>
										{t('mail.to')}
									</span>
									<MultiEmailSelect
										className="email-select form-control"
										value={properties.ToAddresses || []}
										onChange={setToAddresses}
									/>
								</div>
								{/* subject */}
								<div className="input-group col-md-12">
									<span className="input-group-addon">
										<b className="text-danger">{'*'}</b>
										{t('mail.subject')}
									</span>
									<input
										type="text"
										id="field_subject"
										className="form-control"
										name="field_subject"
										value={properties.Subject}
										onChange={e => setSubject(e.target.value)}
										required
										disabled={saving}
									/>
								</div>
								{/* Document template */}
								{templateSelectOptions ? (
									<div className="input-group col-md-12">
										<span className="input-group-addon"> {t('mail.template')} </span>
										<ReactTemplates.INPUTS.upSelect
											id="trigger-template-select"
											className="trigger-select template-select template-input"
											required
											options={{
												data: templateSelectOptions
											}}
											defaultValue={properties.Document}
											placeholder={t('document.selectDocumentTemplate')}
											multiple={false}
											formatSelection={displayTemplate}
											formatResult={displayTemplate}
											matcher={(term, text, op) =>
												op.email.toUpperCase().indexOf(term.toUpperCase()) >= 0
											}
											onChange={({ target: { added } }) => selectTemplate(added.id)}
										/>
									</div>
								) : null}
								{/* Template Settings */}
								{showLanguageOptions ? (
									<div className="input-group col-md-12">
										<span className="input-group-addon"> {t('mail.templateSettings')} </span>
										<div className="row white-select">
											{language ? (
												<div className="col-md-6 select-set">
													<span className="option-heading">{t('mail.templateLanguage')}</span>
													<LanguageSelect
														value={language}
														onChange={lang => setLanguage((lang?.id as string) ?? null)}
														availableLanguagesByCode={languageOptions}
														required
														anchor={'.send-email-action'}
													/>
												</div>
											) : null}
											<div className="col-md-6 select-set">
												<span className="option-heading">{t('mail.templateRegion')}</span>
												<div className="trigger-select">
													<UpCountry
														value={countryRegion}
														options={countryOptions}
														onChange={contryRegionChange}
														required
													/>
												</div>
												{exampleDateTimeFormat && exampleNumberFormat ? (
													<div className="Text Text--italic Text--grey-10 Text--sm">
														{t('mail.templateRegionExample')} {exampleDateTimeFormat}
														{', '}
														{t('and')} {exampleNumberFormat}
													</div>
												) : null}
											</div>
										</div>
									</div>
								) : null}
							</div>
							<div className="ck-wrap">
								<TextEditor
									className="SendEmailActionTextEditor"
									config={ckEditorOptions}
									onInstanceReady={ckEditorOptions.init}
									value={body.current}
									onChange={({ value }: { value: string }) => setBody(value)}
								/>
							</div>
						</fieldset>
					) : null}
				</form>
			</ModalContent>
			<ModalControls>
				{isEdit && removeAction ? (
					<button
						type="button"
						className="up-btn btn-lined btn-grey btn-hover-red no-shadow pull-left"
						disabled={saving}
						onClick={() => {
							removeAction(actionIndex);
							close();
						}}
					>
						<b className="fa fa-trash-o"></b>
					</button>
				) : null}
				<button
					type="button"
					className="btn up-btn btn-bright-blue no-shadow btn-sm"
					disabled={!isValid}
					onClick={() => save()}
				>
					{saving ? t('default.saving') : t('default.save')} {t('admin.action')}
					{saving ? <span className="fa fa-refresh fa-spin"></span> : null}
				</button>
				<button className="btn up-btn btn-grey btn-link" disabled={saving} onClick={() => close()}>
					{t('default.abort')}
				</button>
			</ModalControls>
		</Modal>
	);
};

export default SendEmailAction;
