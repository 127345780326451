import React from 'react';
import moment from 'moment';
import {
	DrawerHeader,
	Block,
	Title,
	Label,
	Input,
	Row,
	Column,
	Toggle,
	Tooltip,
	Button,
	Text
} from '@upsales/components';
import { removeItem } from 'Store/helpers/array';
import bemClass from '@upsales/components/Utils/bemClass';
import T from 'Components/Helpers/translate';
import TodoTimePicker from 'Components/Inputs/TodoTimePicker';
import TriggerDatePicker from 'Components/Inputs/TriggerDatePicker';
import { mapProperties, parseProperties } from 'Services/ActionProperties';
import CampaignSelect from './CampaignSelect';
import UserRoleList from 'Components/Inputs/UserRoleList';
import CheckableList from 'Components/Inputs/UserRoleList/CheckableList';
import NotesWithSignature from 'Components/Inputs/NotesWithSignature';
import ModalTagList from 'Components/Modals/ModalTagList';
import { getDynamicUserProperties, TagEntity, UserProperty } from 'App/helpers/actionHelpers';
import { BasicUserWithPermissions } from 'App/resources/Model/User';
import Role from 'App/resources/Model/Role';
import { Fade } from 'App/components/animations';
import { CampaignSelectItem } from './CampaignSelect/CampaignSelect';
import CallListResource from 'Resources/CallList';
import ProjectResource from 'App/resources/Project';
import logError from 'Helpers/logError';
import type { Metadata } from 'App/resources/AllIWant';

import './PlanPhonecallsDrawer.scss';

const DESCRIPTION_MAX_LENGTH = 100;

type ToggleItem = Optional<UserProperty, 'value'> & { propName: string; hideIfSharedCallList?: boolean };
type ProjectStandardField = Metadata['standardFields']['Activity']['Project'];

// TODO type individual properties properly
type PropertyMap = { [attribute: string]: any };
export type Properties = PropertyMap[];

type Props = {
	close?: () => void;
	properties?: Properties;
	relativeTime?: boolean;
	saveBtnLabel?: string;
	assignOneUser?: boolean;
	hideNotification?: boolean;
	triggerAction?: boolean;
	triggerEntity?: TagEntity;
	className?: string;
	onSave: (p: Properties) => Promise<any> | void;
};

type State = {
	saving: boolean;
	assignmentType: 'users' | 'roles';
	selectedUsersRoles: number[];
	showTagList: boolean;
	properties: PropertyMap;
	callList: (CampaignSelectItem & { isShared: boolean }) | null;
	callListManualAssign: boolean;
	project: CampaignSelectItem | null;
};

let toggleItems: ToggleItem[];

const formIsInvalid = (state: State, projectStandardField: ProjectStandardField) => {
	if (!state.properties.Description) {
		return true;
	}
	if (!state.selectedUsersRoles.length && !(state.callList?.isShared && !state.callListManualAssign)) {
		return true;
	}
	if (projectStandardField.active && projectStandardField.required && !state.properties.Project) {
		return true;
	}

	return false;
};

type SelectItemOption = CampaignSelectItem & { userEditable: boolean; userRemovable: boolean };
const convertToOption = (opt: SelectItemOption): SelectItemOption => ({
	...opt,
	title: opt.name ?? ''
});

class PlanPhonecallsDrawer extends React.Component<Props, State> {
	users: BasicUserWithPermissions[];
	roles: Role[];
	hasCallLists: boolean;
	hasSharedCallLists: boolean;
	projectStandardField: ProjectStandardField;
	_main?: HTMLDivElement | null;

	state: State = {
		saving: false,
		assignmentType: 'users',
		selectedUsersRoles: [],
		showTagList: false,
		properties: {},
		callList: null,
		callListManualAssign: false,
		project: null
	};

	lang: { [k: string]: string } = {
		planACallWithCompany: this.props.triggerAction
			? T('todo.planACallWithCompany')
			: T('todo.planACallWithCompanies'),
		whoShouldCall: this.props.triggerAction ? T('todo.whoShouldCallCompany') : T('todo.whoShouldCallCompanies')
	};
	constructor(p: Props) {
		super(p);
		this.users = Tools.AppService.getActiveUsers();
		this.roles = Tools.AppService.getRoles();

		let properties = {} as State['properties'];
		if (p.properties) {
			properties = parseProperties(p.properties);
			if (typeof properties.Priority === 'string') {
				properties.Priority = parseInt(properties.Priority);
			}
			if (typeof properties.CallList === 'string') {
				properties.CallList = parseInt(properties.CallList);
			}
			if (typeof properties.Project === 'string') {
				properties.Project = parseInt(properties.Project);
			}
		}

		if (typeof properties.User !== 'string' && properties.User?.length) {
			this.state.selectedUsersRoles = isNaN(parseInt(properties.User[0]))
				? properties.User[0]
				: [parseInt(properties.User[0])];
		} else if (properties.User) {
			this.state.selectedUsersRoles = isNaN(parseInt(properties.User))
				? [properties.User]
				: [parseInt(properties.User)];
		}

		this.state.properties = {
			Description: '',
			Notes: '',
			User: [] as number[],
			Priority: 0,
			AssignToManager: false,
			AssignToUser: false,
			SendNotification: false,
			Date: this.props.triggerAction ? '{{General.Today:}}' : new Date(),
			Time: '',
			ActivityDelayInDays: 0,
			Project: null,
			CallList: null,
			ActivityType: Tools.AppService.getTodoTypes().PHONE_CALL.id,
			...properties // Allow for overriding properties via props
		};

		if (!this.state.properties.Description) {
			if (this.props.triggerAction) {
				this.state.properties.Date = '{{General.Today:}}';
			} else {
				this.state.properties.Date = new Date();
			}
		}

		if (
			this.state.properties.Date &&
			typeof this.state.properties.Date === 'string' &&
			moment(this.state.properties.Date).isValid()
		) {
			this.state.properties.Date = new Date(this.state.properties.Date);
		}

		this.hasCallLists = Tools.FeatureHelper.hasSoftDeployAccess('CALL_LISTS');
		this.hasSharedCallLists = this.hasCallLists && Tools.FeatureHelper.hasSoftDeployAccess('SHARED_CALL_LISTS');

		if (p.triggerAction && p.triggerEntity) {
			toggleItems = getDynamicUserProperties(p.triggerEntity).map(i => ({
				...i,
				propName: 'User'
			}));
		} else {
			toggleItems = [
				{ propName: 'AssignToManager', title: 'activity.giveToAccountManager', hideIfSharedCallList: true },
				{ propName: 'AssignToUser', title: 'activity.giveToAssignedUser', hideIfSharedCallList: true },
				{ propName: 'SendNotification', title: 'activity.sendNotification', hideIfSharedCallList: true }
			];
		}

		if (this.state.properties.CallList) {
			CallListResource.find({ id: this.state.properties.CallList })
				.then(res => {
					const callList = res.data?.[0];
					if (callList) {
						const opt = convertToOption(callList);
						let manualAssign = false;
						const user = this.state.properties.User;
						if ((Array.isArray(user) && user.length === 1) || (user + '').indexOf(',') === -1) {
							manualAssign = true;
						}
						this.setCallList(opt, manualAssign);
					}
				})
				.catch(e => logError(e));
		}

		if (this.state.properties.Project) {
			ProjectResource.find({ id: this.state.properties.Project })
				.then(res => {
					const project = res.data?.[0];
					if (project) {
						const opt = convertToOption(project);
						this.setState({ project: opt });
					}
				})
				.catch(e => logError(e));
		}

		const metadata = Tools.AppService.getMetadata();
		this.projectStandardField = metadata.standardFields.Activity.Project;
	}

	save = () => {
		this.setState({ saving: true });
		let properties = { ...this.state.properties };

		const shouldAssignFromCallList = this.state.callList?.isShared && !this.state.callListManualAssign;
		const users: number[] = shouldAssignFromCallList
			? this.state.callList?.users?.flatMap(u => u.id ?? []) ?? []
			: this.state.selectedUsersRoles;

		// If we are picking roles we need to find all selected role users
		if (!shouldAssignFromCallList && this.state.assignmentType === 'roles') {
			properties.User = users.reduce<number[]>((users, roleId) => {
				const roleUsers = this.users
					.filter(u => u.role && u.role.id === roleId && u.ghost === 0)
					.map(u => u.id);
				users = users.concat(roleUsers);
				return users;
			}, []);
		} else {
			properties.User = users;
		}
		properties.User = properties.User.join(',');
		if (this.props.relativeTime) {
			properties.Date = undefined;
		} else {
			properties.ActivityDelayInDays = undefined;

			if (!properties.Date) {
				properties.Time = '';
			}
		}
		properties = Object.keys(properties).reduce<State['properties']>((res, propKey) => {
			const propValue = properties[propKey];
			if (propValue || propValue === 0) {
				res[propKey] = propValue;
			}
			return res;
		}, {});
		const propArray = mapProperties(properties);
		const promise = this.props.onSave(propArray);

		// If onSave is a promise we wait for it to resolve before closing the drawer
		if (promise && promise.then) {
			promise.then(() => this.props.close?.()).catch(() => this.setState({ saving: false }));
		} else {
			this.props.close?.();
		}
	};

	setProps = (newProps: Partial<State['properties']>) =>
		this.setState({ properties: { ...this.state.properties, ...newProps } });
	setProp = <Prop extends keyof State['properties']>(propName: Prop, value: State['properties'][Prop]) => {
		this.setProps({ [propName]: value });
	};

	setCallList = (callList: CampaignSelectItem | null, manualAssign?: boolean) => {
		const isShared =
			Tools.FeatureHelper.hasSoftDeployAccess('SHARED_CALL_LISTS') && (callList?.users?.length ?? 0) > 1;

		const cl = callList
			? {
					...callList,
					isShared
			  }
			: null;

		this.setState({
			callList: cl,
			callListManualAssign: manualAssign ?? false
		});

		/**
		 * 	Store the properties that I want to change in the array to prevent calling once per each
		 * 	of the properties in the state. Then map the array to an object for the setProps function.
		 */
		const callListProps = [];
		if (callList) {
			callListProps.push({ CallList: callList.id });
			if (isShared) {
				toggleItems.forEach(item => {
					if (item.hideIfSharedCallList && this.state.properties[item.propName]) {
						callListProps.push({ [item.propName]: false });
					}
				});
			}
		} else {
			callListProps.push({ CallList: null });
		}

		const callListPropsObject = callListProps.reduce((acc, prop) => {
			return { ...acc, ...prop };
		}, {});
		this.setProps(callListPropsObject);
	};

	clearTimeAndDate = () =>
		this.setState({
			properties: {
				...this.state.properties,
				Date: this.props.triggerAction ? '{{General.Today:}}' : null,
				Time: ''
			}
		});
	toggleUserRole = (id: number) => {
		const index = this.state.selectedUsersRoles.indexOf(id);
		if (index !== -1) {
			this.setState({ selectedUsersRoles: removeItem(this.state.selectedUsersRoles, index) });
		} else {
			this.setState({
				selectedUsersRoles: this.props.assignOneUser ? [id] : [...this.state.selectedUsersRoles, id]
			});
		}
	};
	toggleTagList = () => {
		this.setState({ showTagList: !this.state.showTagList }, () => {
			if (this._main) {
				if (this.state.showTagList) {
					this._main.style.right = '280px';
				} else {
					this._main.style.right = '0';
				}
			}
		});
	};

	changeAssignmentType = (assignmentType: State['assignmentType']) => {
		this.setState({ selectedUsersRoles: [], assignmentType });
	};

	selectUsers = (users: State['selectedUsersRoles']) => {
		this.setState({ selectedUsersRoles: users });
	};

	render() {
		this.lang.selectLabel = this.props.triggerAction
			? T('todo.availableUsers')
			: this.props.assignOneUser
			? T('default.selectUser')
			: `${T('default.select')} ${T(
					this.state.assignmentType === 'users' ? 'default.users' : 'default.roles'
			  ).toLowerCase()}`;
		const classes = new bemClass('PlanPhonecallsDrawer', this.props.className);
		const { assignmentType, properties, saving, selectedUsersRoles, callList } = this.state;
		const canNotSave = formIsInvalid(this.state, this.projectStandardField);
		const callListIsShared = this.hasSharedCallLists && callList?.isShared;

		return (
			<div className={classes.b()} ref={r => (this._main = r)}>
				<DrawerHeader icon="phone" onHide={this.props.close} title={T('todo.planPhonecalls')}>
					{this.props.triggerAction ? (
						<Button
							text={
								this.state.showTagList
									? T('todo.hideTags')
									: T('admin.documentTemplate.standardTemplatesModal.showTags')
							}
							type="lined"
							onClick={this.toggleTagList}
						/>
					) : null}
				</DrawerHeader>
				<Block className={classes.elem('content').b()} backgroundColor="grey-1" space="prxl pbxl plxl">
					<Title className={classes.elem('title').b()} size="lg" bold={true}>
						{this.lang.planACallWithCompany}
					</Title>
					<Row className={classes.elem('description-row').b()}>
						<Column>
							<Label required={true} maxLength={DESCRIPTION_MAX_LENGTH} value={properties.Description}>
								{T('todo.whatIsTheGoalOfTheCall')}
							</Label>
							<Input
								value={properties.Description}
								maxLength={DESCRIPTION_MAX_LENGTH}
								onChange={e => this.setProp('Description', e.target.value)}
							/>
						</Column>
					</Row>
					<Block space="mtl">
						<Tooltip title={T('todo.planPhonecallsPrioDesc')} distance={20}>
							<Toggle
								icon="flag"
								color="red"
								size="lg"
								space="mrm"
								checked={!!properties.Priority}
								onChange={p => this.setProp('Priority', p ? 3 : 0)}
							/>
						</Tooltip>
						<Text
							className="clickable"
							style={{ display: 'inline' }}
							onClick={() => this.setProp('Priority', properties.Priority ? 0 : 3)}
						>
							{T('todo.phonecallsSetAsPrio')}
						</Text>
					</Block>
					<Block space="mtl" style={{ zIndex: 1001 }}>
						<Row className={classes.elem('button-row').b()}>
							<Column>
								{this.props.triggerAction ? (
									<>
										<TriggerDatePicker
											onTimeChange={e =>
												e.target
													? this.setProp('Time', e.target.value)
													: this.setProp('Time', e)
											}
											onDateChange={v => this.setProp('Date', v)}
											onClear={this.clearTimeAndDate}
											entity={this.props.triggerEntity}
											date={properties.Date}
											time={properties.Time}
											withToggle={true}
											dateTag={
												typeof properties.Date === 'string'
													? properties.Date.substring(2, properties.Date.indexOf(':'))
													: ''
											}
											offset={
												typeof properties.Date === 'string'
													? parseInt(
															properties.Date.substring(
																properties.Date.indexOf(':') + 1,
																properties.Date.indexOf('}')
															)
													  )
													: ''
											}
										/>
									</>
								) : this.props.relativeTime ? (
									<>
										<Label required={true}>{T('automationAction.DateOffset')}</Label>
										<Input
											type="number"
											min={0}
											value={properties.ActivityDelayInDays}
											onChange={e => this.setProp('ActivityDelayInDays', e.target.value)}
										/>
										<Column fixedWidth={40} />
									</>
								) : (
									<TodoTimePicker
										date={properties.Date}
										time={properties.Time}
										onTimeChange={e => this.setProp('Time', e.target.value)}
										onDateChange={e => this.setProp('Date', e.target.value)}
										onClear={this.clearTimeAndDate}
										withToggle={true}
										setDueDateToday
									/>
								)}
							</Column>
						</Row>
					</Block>
					{this.hasCallLists ? (
						<Block space="mtl">
							<CampaignSelect
								anchor={this._main as Element}
								value={this.state.callList}
								onChange={v => this.setCallList(v)}
								withToggle={true}
								toggleIcon="call-list"
								isCallList
							/>
						</Block>
					) : null}
					{this.projectStandardField.active ? (
						<Block space={this.hasCallLists ? undefined : 'mtl'}>
							<CampaignSelect
								anchor={this._main as Element}
								value={this.state.project}
								onChange={v => {
									this.setProp('Project', v?.id ?? null);
									this.setState({ project: v });
								}}
								withToggle={true}
								required={this.projectStandardField.required}
							/>
						</Block>
					) : null}

					<Title className={classes.elem('title').b()} size="lg" bold={true}>
						{this.lang.whoShouldCall}
					</Title>

					{this.props.triggerAction && !callListIsShared ? (
						<>
							<Label>{T('tag.User.dynamicOptions')}</Label>
							<CheckableList
								multiple={!this.props.assignOneUser}
								onItemToggle={this.toggleUserRole}
								displayKey="title"
								valueKey="value"
								items={toggleItems}
								selectedItems={this.state.selectedUsersRoles}
							/>
						</>
					) : null}

					<Block>
						{callListIsShared ? (
							<Block space="mbm">
								<Row className={classes.elem('manual-assign-toggle').b()}>
									<Column fixedWidth={40}>
										<Toggle
											size="md"
											color="green"
											checked={this.state.callListManualAssign}
											onChange={v =>
												this.setState({
													callListManualAssign: !this.state.callListManualAssign
												})
											}
											space="mts"
										/>
									</Column>
									<Column>
										<Text>{T('callList.manualAssign')}</Text>
										<Text size="sm" color="grey-10">
											{T('callList.manualAssignInfo', { callList: this.state.callList?.name })}
										</Text>
									</Column>
								</Row>
							</Block>
						) : null}

						<Fade
							visible={!callListIsShared || this.state.callListManualAssign}
							// direction="top"
							height
							speed="slow"
							maxHeight={250}
						>
							<Block>
								<Label required>{this.lang.selectLabel}</Label>
								<UserRoleList
									space="mts mbxl"
									// We can only allow selecting roles if multiple users can be selected (selecting a role selects all users in that role)
									usersOnly={this.props.assignOneUser || this.props.triggerAction}
									assignOneUser={this.props.assignOneUser}
									selectedUsersRoles={selectedUsersRoles}
									setSelectedUsersRoles={this.selectUsers}
									assignmentType={assignmentType}
									changeAssignmentType={this.changeAssignmentType}
									toggleUserRole={this.toggleUserRole}
									userTypes={['crm', 'service']}
								/>
							</Block>
						</Fade>
					</Block>

					<Block space="mbxl">
						{this.props.triggerAction
							? null
							: toggleItems
									.filter(toggleItem => !(toggleItem.hideIfSharedCallList && callListIsShared))
									.map(item => (
										<Row key={item.propName} className={classes.elem('toggle-row').b()}>
											<Column fixedWidth={40}>
												<Toggle
													size="md"
													color="green"
													checked={properties[item.propName]}
													onChange={v => this.setProp(item.propName, v)}
												/>
											</Column>
											<Column>
												<Text>{T(item.title)}</Text>
											</Column>
										</Row>
									))}
					</Block>
					<NotesWithSignature
						value={properties.Notes}
						onChange={e => this.setProp('Notes', e)}
						space="mtl mbxl"
					/>
					<Block space="mtxl">
						<Button
							size="lg"
							loading={saving}
							disabled={canNotSave}
							color={canNotSave ? 'light-grey' : 'green'}
							shadow="none"
							block={true}
							onClick={this.save}
						>
							{this.props.saveBtnLabel}
						</Button>
					</Block>
				</Block>
				{this.props.triggerAction ? (
					<div style={this.state.showTagList ? { display: 'block' } : { display: 'none' }}>
						<ModalTagList entity={this.props.triggerEntity} onClose={this.toggleTagList} />
					</div>
				) : null}
			</div>
		);
	}
}

export default PlanPhonecallsDrawer;
