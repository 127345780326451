import React, { ComponentProps } from 'react';
import SharedViews, { SharedViewsPropsExternal } from 'Components/SharedViews/SharedViews';
import { ListView as ListViewType, ListViewFilter } from 'App/resources/AllIWant';
import {
	Block,
	Button,
	Checkbox,
	Column,
	Icon,
	Row,
	TableColumn,
	TableHeaderColumn,
	TableRow,
	Tooltip,
	Text,
	Link,
	AssistChip,
	EllipsisTooltip,
	Flex,
	Flag
} from '@upsales/components';
import Avatar from 'Components/Avatar';
import TableHeaderDropdown from 'Components/MultiSelect/TableHeaderDropdown';
import T, { useTranslation } from 'Components/Helpers/translate';
import RequestBuilder from 'Resources/RequestBuilder';
import { FilterConfig } from 'App/babel/filterConfigs/FilterConfig';
import ListViewFilters from './ListViewFilters';
import { Attr, DisplayType } from 'Attributes/Attribute';
import CustomField, { EntityCustomField } from 'App/resources/Model/CustomField';
import { isCustom, isCustomCategory, getCategoryTypeIdFromFilterName, isAddress } from 'App/helpers/filterHelper';
import { PrimaryButton } from '@upsales/components/Buttons';
import UsersText from '../UsersText';
import { BasicUser } from 'App/resources/Model/User';
import CustomFieldValueText from '../CustomFieldValueText';
import { numberFormat } from 'Components/Filters/Currencies'; // TODO: Convert to new formatter in babel/utils/numberFormat
import { CurrencyFormat } from 'App/babel/utils/numberFormat';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { RootState } from 'Store/index';
import { dateCalendar } from 'App/helpers/DateHelpers';
import JourneyStepIcon from 'Components/JourneyIcon/JourneyStepIcon';
import MailStatus from 'App/components/MailStatus';
import { GetDataOpts, ListViewSetStateCallback } from './ListView';
import LeadSource from '../../babel/components/Columns/LeadSource';
import moment from 'moment';
import history from 'App/pages/routes/history';
import logError from 'Helpers/logError';
import InlineConfirm from 'Components/Dialogs/InlineConfirm';
import UserStack from '../UserStack';
import Category, { EntityCategory } from 'App/resources/Model/Category';
import AddressField from '../AddressField';
import Order from 'App/resources/Model/Order';
import fileSize from 'Helpers/fileSize';
import { getFileIcon } from 'Helpers/file';
import Contact from 'App/resources/Model/Contact';
import Appointment from 'App/resources/Model/Appointment';
import EsignInvolvees from '../EsignInvolvees';
import { EsignEnum, Esign } from 'App/resources/Model/Esign';
import { SizesLarge } from '@upsales/components/Utils/Sizes';
import BemClass from '@upsales/components/Utils/bemClass';
import './ListViewRenderHelpers.scss';
import ClientTooltip from 'Components/ClientTooltip';
import DefaultNoData from './DefaultNoData';
import { Sort } from 'Resources/RequestBuilder';
import ListViewTable, { ListViewTableProvided } from './ListViewTable/ListViewTable';
import Opportunity from 'App/resources/Model/Opportunity';
import { useMultiselect } from '../MultiselectProvider/MultiselectProvider';
import { MultiAction } from '../ListViewActions';
import type Client from 'App/resources/Model/Client';
import TelephoneLink from 'App/components/columns/TelephoneLink/TelephoneLink';
import ListViewQuickSearch from './ListViewQuickSearch';
import SubAccountLabel from 'Components/Misc/SubAccountLabel';
import { openEditAppointment } from 'Components/Modals/Appointment/EditAppointment';
import SalesHistory from '../SalesHistory';
import { ClientActivityType } from 'App/resources/Model/Client';
import { HistoryProperties } from '../SalesHistory/SalesHistory';
import { getAddress } from 'Components/CreateRelation/AddSubaccount/Views/columnParts';
import { trunc } from 'lodash';
import { openDrawer } from 'Services/Drawer';
import Mail from 'App/resources/Model/Mail';
import { getTooltipNotes } from 'App/pages/TodoList/TodoListTableRow/TodoListColumns';

type ListViewHeaderProps = {
	className: string;
};

export type MultiSelect = {
	active: boolean;
	allSelected: boolean;
	selected: number;
	selectedIds: number[];
	selectedIdMap: { [id: number]: boolean };
};

export type MultiSelectCheckboxProps = {
	disabled: boolean;
	checked: boolean;
	onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
};

export type RenderProvided<ItemType> = {
	setFiltersVisible: (filtersVisible: boolean) => void;
	tableLoading: boolean;
	tableData: ReadonlyArray<ItemType>;
	setTableData: (tableData: ReadonlyArray<ItemType>) => void;
	getTableData: () => ReadonlyArray<ItemType>;
	selectedView: ListViewType | null;
	runMultiAction: (action: MultiAction) => void;
	itemIdentifier: keyof ItemType;
	total: number;
	setFilter: (
		filterName: string,
		partialValueObj: Partial<ListViewFilter>,
		opts?: GetDataOpts,
		callback?: ListViewSetStateCallback
	) => void;
	setColumns: (columns: string[], opts?: GetDataOpts, callback?: ListViewSetStateCallback) => void;
	requestBuilder: RequestBuilder | null;
	filterConfigs: { [name: string]: FilterConfig } | null;
	attributes: { [name: string]: Attr };
	filters: { [filterName: string]: ListViewFilter };
	onFilterChange: (
		filters: { [filterName: string]: ListViewFilter },
		opts?: GetDataOpts,
		callback?: ListViewSetStateCallback
	) => void;
	reloadTable: (opts?: GetDataOpts) => void;
	sorting: Sort[];
	listType?: string;
	onSortChange: (sort: { field: string; asc: boolean }) => void;
	onChangeView: (view: ListViewType, opts?: { fromSave: boolean }) => void;
	hiddenFilters: string[];
	customFields: CustomField[];
	columns: string[];
	hasChanged: boolean;
	hideView: (view: ListViewType) => void;
	showView: (view: ListViewType) => void;
	makeDefault: (view: ListViewType) => void;
	showSaveView: (opts: { editView: ListViewType; createNew?: boolean }) => (dispatch: any, getState: any) => void;
	canSortCustomFields?: boolean;
	broadcastType?: string | string[];
};

export type QuickSearchProps = Pick<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'> & {
	placeholder: string;
	value: string;
	className: string;
};

export type RenderHeaderProvided<ItemType> = RenderProvided<ItemType> & {
	sharedViewsProps: SharedViewsPropsExternal | null;
	props: ListViewHeaderProps;
	listViews: ListViewType[];
	addBtn: boolean;
	onAddBtnClick: (provided: RenderProvided<ItemType>) => void;
	addBtnText: string;
	hideFilters: boolean;
	renderHeaderFirstExtra?: (provided: RenderHeaderExtraProvided<ItemType>) => JSX.Element | null;
	renderHeaderLastExtra?: (provided: RenderHeaderExtraProvided<ItemType>) => JSX.Element | null;
	quickSearchProps: QuickSearchProps | null;
	relatedEntities?: string[];
	classes: BemClass;
	opts?: {
		noTabs?: boolean;
	};
};

export type RenderHeaderExtraProvided<ItemType> = Omit<
	RenderHeaderProvided<ItemType>,
	| 'props'
	| 'sharedViewsProps'
	| 'addBtn'
	| 'onAddBtnClick'
	| 'hideFilters'
	| 'addBtnText'
	| 'renderHeaderFirstExtra'
	| 'renderHeaderLastExtra'
	| 'quickSearchProps'
>;

export const renderDefaultHeader = <ItemType extends {}>({
	props,
	sharedViewsProps,
	addBtn,
	onAddBtnClick,
	addBtnText,
	renderHeaderFirstExtra,
	renderHeaderLastExtra,
	quickSearchProps,
	hideFilters,
	relatedEntities,
	...renderProvidedRest
}: RenderHeaderProvided<ItemType>) => {
	const addBtnProps: React.ComponentProps<typeof PrimaryButton> = {
		size: 'sm',
		onClick: () => onAddBtnClick(renderProvidedRest),
		className: renderProvidedRest.classes.elem('add-btn').b()
	};
	let mappedListType = renderProvidedRest.listType;
	switch (mappedListType) {
		case 'accountGrowth': {
			mappedListType = 'account';
			break;
		}
		case 'page': {
			mappedListType = 'form';
			break;
		}
	}

	return (
		<Row {...props}>
			{sharedViewsProps || renderHeaderFirstExtra ? (
				<Column>
					{sharedViewsProps ? <SharedViews {...sharedViewsProps} /> : null}
					{renderHeaderFirstExtra?.(renderProvidedRest)}
				</Column>
			) : null}
			<Column>
				{renderHeaderLastExtra?.(renderProvidedRest)}
				{addBtn ? (
					<Block space="mrl">
						<Tooltip
							title={addBtnText}
							position="bottom"
							className={renderProvidedRest.classes.elem('add-btn-tooltip').b()}
						>
							<PrimaryButton {...addBtnProps}>
								<Icon name="plus" />
							</PrimaryButton>
						</Tooltip>
						<PrimaryButton {...addBtnProps}>
							<Icon name="plus" space="mrs" />
							{addBtnText}
						</PrimaryButton>
					</Block>
				) : null}
				{quickSearchProps ? (
					<Block space="mrl">
						<ListViewQuickSearch {...quickSearchProps} />
					</Block>
				) : null}
				{renderProvidedRest.filterConfigs && !hideFilters ? (
					<ListViewFilters
						onVisibleChange={renderProvidedRest.setFiltersVisible}
						filterConfigs={renderProvidedRest.filterConfigs}
						activeFilters={renderProvidedRest.filters}
						onChange={renderProvidedRest.onFilterChange}
						hiddenFilters={renderProvidedRest.hiddenFilters}
						hasChanged={renderProvidedRest.hasChanged}
						listType={mappedListType}
						customFields={renderProvidedRest.customFields}
						relatedEntities={relatedEntities}
						onViewChange={renderProvidedRest.onChangeView}
						opts={renderProvidedRest.opts}
					/>
				) : null}
			</Column>
		</Row>
	);
};

export const ListViewMultiSelectColumn = ({ id }: { id: number }) => {
	const multiselect = useMultiselect();
	const multiselectDisabled = !!multiselect?.allSelected;
	return (
		<TableColumn
			onClick={e => {
				e.stopPropagation();
				if (multiselectDisabled) return;

				multiselect.toggleSelected(id);
			}}
		>
			<Checkbox
				size="xs"
				disabled={multiselectDisabled}
				checked={(multiselect?.allSelected || multiselect?.selectedIdMap[id]) ?? false}
			/>
		</TableColumn>
	);
};

type SettingsButtonsColumnProps<ItemType> = {
	entity?: { id: number };
	setTableData?: (tableData: ItemType[]) => void;
	getTableData?: () => ItemType[];
	onEdit?: () => void;
	onExtraDelete?: (tableData: ItemType[]) => void;
	deleteEntity?: string;
	broadcastType?: string;
	EntityResource?: any;
	extraButton?: JSX.Element;
	deleteOpts?: { [key: string]: any };
};

export const RenderSettingsButtonsColumn = <ItemType extends { id: number }>({
	entity,
	deleteEntity,
	onEdit,
	EntityResource,
	extraButton,
	broadcastType,
	deleteOpts
}: SettingsButtonsColumnProps<ItemType>) => {
	const onDelete = () => {
		if (!entity || !EntityResource) return;
		return EntityResource.delete(deleteEntity === 'default.agreement' ? entity : entity.id, deleteOpts)
			.then((res: any) => {
				if (broadcastType) {
					Tools.$rootScope.$broadcast(`${broadcastType}.deleted`, entity);
				}
				return res;
			})
			.catch((err: unknown) => {
				logError(err, 'Failed to delete entity with id: ' + entity.id);
			});
	};

	return (
		<TableColumn align="right">
			{extraButton}
			{onEdit ? (
				<Tooltip position="left" title={T('default.edit')}>
					<Button
						type="link"
						color="grey"
						size="sm"
						onClick={e => {
							e.stopPropagation();
							onEdit();
						}}
					>
						<Icon name="edit" />
					</Button>
				</Tooltip>
			) : null}
			{deleteEntity ? (
				<Tooltip position="left" title={T('default.delete')}>
					<InlineConfirm show keepTabPosition onConfirm={onDelete} entity={deleteEntity}>
						<Button type="link" color="grey" size="sm">
							<Icon name="trash" />
						</Button>
					</InlineConfirm>
				</Tooltip>
			) : null}
		</TableColumn>
	);
};

export const ListViewMultiSelectHeaderColumn = ({
	className,
	selectAllPage
}: {
	className?: string;
	selectAllPage: () => void;
}) => {
	const { total, selectAllResult, selectNone, allSelected, selected } = useMultiselect();
	const { t } = useTranslation();
	const multiselectItems = [
		{ onClick: () => selectAllResult(), text: `${t('default.selectAll')} (${total})` },
		{ onClick: () => selectAllPage(), text: t('default.selectAllOnPage') },
		{ onClick: () => selectNone(), text: t('default.deselectAll') }
	];
	return (
		<TableHeaderColumn className={className}>
			<TableHeaderDropdown items={multiselectItems} selected={allSelected || !!selected} />
		</TableHeaderColumn>
	);
};

export type RenderTableProvided<ItemType> = RenderProvided<ItemType> & {
	formatNoData: () => string;
	renderTableRow: (
		item: ItemType,
		provided: ListViewTableProvided<ItemType, RenderTableRowProvided<ItemType>>
	) => React.ReactElement<typeof TableRow>;
	renderToolsColumn: boolean;
	editColumns: () => void;
	renderNoData: () => JSX.Element;
	classes: BemClass;
};

export const renderDefaultTable = <ItemType extends {}>({
	renderTableRow,
	renderNoData,
	editColumns,
	formatNoData,
	...renderTableRowProvided
}: RenderTableProvided<ItemType>) => {
	const {
		tableLoading,
		tableData,
		renderToolsColumn,
		attributes,
		total,
		itemIdentifier,
		sorting,
		onSortChange,
		columns,
		listType,
		runMultiAction,
		setTableData,
		canSortCustomFields
	} = renderTableRowProvided;

	return (
		<ListViewTable<ItemType, RenderTableRowProvided<ItemType>>
			data={tableData}
			loading={tableLoading}
			total={total}
			formatNoData={formatNoData}
			renderNoData={renderNoData}
			renderTableRow={renderTableRow}
			attributes={attributes}
			columns={columns}
			onSortChange={onSortChange}
			sorting={sorting}
			provided={renderTableRowProvided}
			itemIdentifier={itemIdentifier}
			onEditColumns={editColumns}
			renderToolsColumn={renderToolsColumn}
			runMultiAction={runMultiAction}
			listType={listType}
			onDataChange={setTableData}
			canSortCustomFields={canSortCustomFields}
		/>
	);
};

const getAccountHref = (id: number) =>
	Tools.$state.href(
		Tools.FeatureHelper.hasSoftDeployAccess('REACT_CLIENT_CARD') ? 'react-root-clientCard' : 'account.dashboard',
		{
			customerId: Tools.AppService.getCustomerId(),
			id,
			page: 'overview'
		}
	);

const getContactHref = (id: number) =>
	Tools.$state.href('contact.dashboard', {
		customerId: Tools.AppService.getCustomerId(),
		id
	});

const getProjectHref = (id: number) =>
	Tools.$state.href('campaign.dashboard', {
		customerId: Tools.AppService.getCustomerId(),
		id
	});

export type RenderTableRowProvided<ItemType> = RenderProvided<ItemType> & { classes: BemClass };

export const getDurationFromSeconds = (seconds: number) => {
	const duration = moment().startOf('day').add(seconds, 's');

	let format = '';

	if (!seconds) {
		return '-';
	}

	if (duration.hour() > 0) {
		format += 'H[h] ';
	}

	if (duration.minute() > 0) {
		format += 'm[m] ';
	}

	format += 's[s]';
	return duration.format(format);
};

export const getDurationFromDates = (startDate: string, endDate: string) => {
	const seconds = moment(endDate).diff(moment(startDate), 'seconds');
	return getDurationFromSeconds(seconds);
};

type MailListProps = { type: string; errorCause: string };
type EntityObject = { id: number; name: string };
type ContactEntity = EntityObject & { title: string; titleCategory?: { value: string } };
type ClientEntity = EntityObject & {
	operationalAccount?: { id: number; name: string };
};
type ListViewColumnTextProps = {
	type?: DisplayType.Text | DisplayType.Custom | DisplayType.Assigned;
	data: string | null;
};

type ListViewColumnMainAccountProps = {
	type: DisplayType.MainAccount;
	data: ClientEntity;
};

type ListViewColumnClientLocationProps = {
	type: DisplayType.ClientLocation;
	data: { id: number; name: string; addresses: Client['addresses'] };
};

type ListViewColumnHistoryProps = {
	type: DisplayType.ClientHistory | DisplayType.ContactHistory;
	data: HistoryProperties;
};
type ListViewColumnActivityProps = {
	type: DisplayType.Activity;
	data: ClientActivityType & { activityType?: { id: number } };
};
type ListViewColumnEllipsisTextProps = {
	type: DisplayType.EllipsisText;
	data: string | null;
};
type ListViewColumnSubtitleTextProps = {
	type: DisplayType.SubtitleText;
	data: { title: string | null; subtitle: string | null };
};
type ListViewColumnArrayProps = { type: DisplayType.Array; data: string[] };
type ListViewColumnDateProps = { type: DisplayType.Date | DisplayType.DateTime; data: string | Date | null };
type ListViewColumnDateUserProps = { type: DisplayType.DateUser; data: { date: Date; name: string } };
type ListViewColumnYearProps = { type: DisplayType.Year; data?: string };
type ListViewColumnNumberProps = {
	type: DisplayType.Number | DisplayType.Score | DisplayType.ARRChange | DisplayType.Percent;
	data: string | number | null;
};
type ListViewColumnUserTextProps = {
	type: DisplayType.UserText | DisplayType.ContactsText;
	data: BasicUser | BasicUser[] | null;
};
type ListViewColumnUserProps = { type: DisplayType.User; data: BasicUser | null };
type ListViewColumnUsersProps = { type: DisplayType.Users; data: BasicUser[] };
type ListViewColumnBooleanProps = { type: DisplayType.Boolean; data: boolean };
type ListViewColumnCurrencyProps = {
	type: DisplayType.Currency | DisplayType.CurrencyChange;
	data: { value: number; currency: string; colorize?: boolean };
};
type ListViewColumnJourneyProps = { type: DisplayType.Journey; data: string | null };
export type ListViewColumnPhoneNumberProps = {
	type: DisplayType.PhoneNumber;
	data: {
		number?: string | null;
		client?: Pick<Client, 'id' | 'name'> | null;
		contact?: Pick<Contact, 'id' | 'name' | 'client'> | null;
	};
};
type ListViewColumnStaticValue = { type: DisplayType.StaticValue; data: { value: string; type: string } };
type ListViewColumnCreditRating = { type: DisplayType.CreditRating; data: { value: string; type: string } };
type ListViewColumnURLProps = { type: DisplayType.URL; data: string };
type ListViewColumnLocationaProps = {
	type: DisplayType.Location;
	data: { country: string; name: string };
};
type ListViewColumnContactProps = {
	type: DisplayType.Contact;
	data: { contact: EntityObject | null; client: EntityObject | null; ellipsis?: boolean };
};
type ListViewColumnContactTitleProps = {
	type: DisplayType.ContactTitle;
	data: { contact: ContactEntity | null; client: EntityObject | null; ellipsis?: boolean };
};
type ListViewColumnMailTemplateProps = { type: DisplayType.MailTemplate; data: EntityObject };
type ListViewColumnMailStatusProps = { type: DisplayType.MailStatus; data: MailListProps };
type ListViewColumnLinkProps = {
	type: DisplayType.ClientLink | DisplayType.ContactLink | DisplayType.ProjectLink;
	data: (EntityObject & { journeyStep?: string }) | null;
};
type ListViewColumnContactsProps = {
	type: DisplayType.Contacts;
	data: (EntityObject & { title?: string })[];
};
type ListViewColumnCategoryProps = { type: DisplayType.Category; data: string[] };
type ListViewColumnFileSize = { type: DisplayType.FileSize; data: number };
type ListViewColumnFileRelation = {
	type: DisplayType.FileRelation;
	data: {
		entity: 'Appointment' | 'Contact' | 'Order';
		entityId: number;
		contact: Partial<Contact> | null;
		order: Partial<Order> | null;
		appointment: Partial<Appointment> | null;
	};
};
type ListViewColumnFileType = { type: DisplayType.FileType; data: string };
type ListViewColumnLeadSource = { type: DisplayType.LeadSource; data: {} };
type ListViewColumnEsignInvolved = { type: DisplayType.EsignInvolved; data: Esign['involved'] };
type ListViewColumnEsignStatus = { type: DisplayType.EsignStatus; data: Pick<Esign, 'state' | 'involved'> };
type ListViewColumnEsignOpportunity = { type: DisplayType.EsignOpportunity; data: Esign['opportunity'] };
type ListViewColumnOpportunity = {
	type: DisplayType.Opportunity | DisplayType.Order | DisplayType.Stage;
	data: Pick<Opportunity, 'id' | 'description' | 'orderValue' | 'stage'>;
};

type ListViewColumnProps =
	| ListViewColumnContactsProps
	| ListViewColumnMainAccountProps
	| ListViewColumnClientLocationProps
	| ListViewColumnActivityProps
	| ListViewColumnHistoryProps
	| ListViewColumnArrayProps
	| ListViewColumnTextProps
	| ListViewColumnEllipsisTextProps
	| ListViewColumnSubtitleTextProps
	| ListViewColumnDateProps
	| ListViewColumnDateUserProps
	| ListViewColumnNumberProps
	| ListViewColumnUserProps
	| ListViewColumnUserTextProps
	| ListViewColumnBooleanProps
	| ListViewColumnUsersProps
	| ListViewColumnCurrencyProps
	| ListViewColumnJourneyProps
	| ListViewColumnYearProps
	| ListViewColumnStaticValue
	| ListViewColumnCreditRating
	| ListViewColumnURLProps
	| ListViewColumnPhoneNumberProps
	| ListViewColumnLinkProps
	| ListViewColumnLocationaProps
	| ListViewColumnContactProps
	| ListViewColumnContactTitleProps
	| ListViewColumnMailTemplateProps
	| ListViewColumnMailStatusProps
	| ListViewColumnCategoryProps
	| ListViewColumnFileSize
	| ListViewColumnFileRelation
	| ListViewColumnFileType
	| ListViewColumnLeadSource
	| ListViewColumnEsignInvolved
	| ListViewColumnEsignStatus
	| ListViewColumnOpportunity
	| ListViewColumnEsignOpportunity;

export const ListViewColumn: React.FC<
	ListViewColumnProps & {
		placeholder?: string;
		colSize?: SizesLarge;
		className?: string;
		color?: ComponentProps<typeof Text>['color'];
	}
> = ({ type, data, placeholder = '', colSize = 'sm', className, color }) => {
	let content: React.ReactNode = null;
	const hasGroupBonanza = Tools.FeatureHelper.hasSoftDeployAccess('GROUP_BONANZA');
	const hasSubaccounts = Tools.FeatureHelper.hasSoftDeployAccess('SUB_ACCOUNTS');
	const hasSubaccountsV2 = Tools.FeatureHelper.hasSoftDeployAccess('SUB_ACCOUNTS_V2');
	const companyGroupOrSubaccounts = hasGroupBonanza || (hasSubaccounts && hasSubaccountsV2);

	const { staticValues } = useSelector(({ App }: RootState) => App);
	const placeholderContent = (
		<Text italic size={colSize} color="grey-10">
			{T(placeholder)}
		</Text>
	);
	const classes = new BemClass('ListViewColumn');
	switch (type) {
		case DisplayType.Category:
		case DisplayType.Array: {
			if (Array.isArray(data) && data?.length) {
				const [first, ...rest] = data;
				const firstObject = <Text size="sm">{first}</Text>;
				const andMore = (
					<Tooltip title={rest?.join('\n')}>
						<Text bold size="sm">{` ${T('and')} ${T('listView.andMore', { count: rest?.length })}`}</Text>
					</Tooltip>
				);

				content = (
					<div className={new BemClass('DisplayTypeArray').b()}>
						{firstObject}
						{rest?.length ? andMore : ''}
					</div>
				);
			} else {
				content = placeholderContent;
			}
			break;
		}
		case DisplayType.ContactsText:
		case DisplayType.UserText: {
			const users = data ? (Array.isArray(data) ? data : [data]) : [];
			content = <UsersText users={users || []} size={colSize} placeholder={placeholder} />;
			break;
		}
		case DisplayType.User:
		case DisplayType.Users: {
			const users = data ? (Array.isArray(data) ? data : [data]) : [];
			const avatarSize =
				{
					sm: 20,
					md: 24
				}[colSize as 'sm' | 'md'] ?? 20;
			if (users.length) {
				content =
					users.length > 1 ? (
						<UserStack users={users} />
					) : (
						<Row>
							<Avatar user={users[0]} size={avatarSize} />
							<Text size={colSize} italic={!users?.length}>
								{users?.length ? `${users[0].name}` : ''}
							</Text>
						</Row>
					);
			} else {
				content = placeholderContent;
			}
			break;
		}
		case DisplayType.FileSize: {
			content = fileSize(data);
			break;
		}
		case DisplayType.FileRelation: {
			const entityMap = {
				Contact: {
					icon: 'user' as const,
					field: 'name',
					href: Tools.$state.href('contact.dashboard', { customerId: 1, id: data.contact?.id }),
					onClick: undefined
				},
				Order: {
					icon: 'sales' as const,
					field: 'description',
					href: undefined,
					onClick: () => Tools.$upModal.open('editOrder', { id: data.entityId })
				},
				Appointment: {
					icon: 'appointment' as const,
					field: 'description',
					href: undefined,
					onClick: () => openEditAppointment({ id: data.entityId })
				},
				Activity: {
					// Not actually an option anymore?
					icon: 'flash' as const,
					field: 'description',
					href: undefined,
					onClick: () => Tools.$upModal.open('editActivity', { id: data.entityId })
				}
			};

			const entity = entityMap[data.entity];
			if (!entity) break;

			const entityItem = data[data.entity.toLowerCase() as 'order' | 'contact' | 'appointment'];

			const innerContent = (
				<>
					<Icon space="prm" name={entity.icon} />
					{entityItem?.[entity.field as keyof typeof entityItem]}
				</>
			);

			if (entityItem) {
				content = (
					<Link onClick={entity.onClick} href={entity.href}>
						{innerContent}
					</Link>
				);
			} else {
				content = innerContent;
			}
			break;
		}
		case DisplayType.FileType: {
			content = <Icon name={getFileIcon(data, '', '')} />;
			break;
		}
		case DisplayType.Opportunity:
		case DisplayType.Order:
		case DisplayType.EsignOpportunity: {
			if (data) {
				content = (
					<Link
						onClick={event => {
							event.stopPropagation();
							Tools.$upModal.open('editOrder', { id: data.id });
						}}
					>
						{data.description} {new CurrencyFormat().short(data.orderValue)}
					</Link>
				);
			} else {
				const placeHolder =
					type === DisplayType.EsignOpportunity ? T('default.noOrder') : T('default.noOpportunity');
				content = (
					<Text size="sm" italic color="grey-10">
						{placeHolder}
					</Text>
				);
			}
			break;
		}
		case DisplayType.ClientLocation: {
			const { id, name } = data;

			const address = getAddress(data);
			const country = address?.country || '';

			const onClick = (e: { stopPropagation: () => void }) => {
				e.stopPropagation();
				Tools.$state.go('account.dashboard', { id });
			};

			content = (
				<Flex direction="column" alignItems="flex-start" gap={2}>
					<Link onClick={onClick}>
						<Text size="sm">{name}</Text>
					</Link>
					<Flex gap={4}>
						{country ? <Flag code={country.toLowerCase()} /> : null}
						{address ? (
							<Text color="grey-10" size="sm">
								{address.city}
							</Text>
						) : null}
					</Flex>
				</Flex>
			);
			break;
		}
		case DisplayType.ClientHistory:
		case DisplayType.ContactHistory: {
			const entityName = type === DisplayType.ClientHistory ? 'client' : 'contact';
			content = <SalesHistory entity={data} entityName={entityName} />;
			break;
		}
		case DisplayType.Activity: {
			const isMail = (activity: ClientActivityType | Mail): activity is Mail => {
				return (activity as Mail).type !== undefined;
			};

			if (isMail(data)) {
				const { id, subject, date } = data;

				if (!subject || !date) {
					content = placeholderContent;
					break;
				}

				const onClick = (e: { stopPropagation: () => void }) => {
					e.stopPropagation();
					openDrawer('SentMail', { mail: { id } });
					return;
				};

				const truncDescription = trunc(subject, { length: 50 });

				content = (
					<Flex alignItems="center" gap={8}>
						<Icon name="envelope-o" />
						<Flex direction="column">
							<Link onClick={onClick}>
								<Text size={colSize}>{truncDescription}</Text>
							</Link>
							<Text color={'grey-10'} size={colSize}>
								{moment(date).format('L')}
							</Text>
						</Flex>
					</Flex>
				);
				break;
			}

			const { id, description, date, isAppointment, endDate, closeDate, outcome, time, notes } = data;

			if (!description || !date) {
				content = placeholderContent;
				break;
			}

			const todoType = Tools.AppService.getTodoTypes();
			const activityTypeId = data.activityTypeId ?? data.activityType?.id;
			const isPhoneCall = activityTypeId === todoType.PHONE_CALL.id;
			const icon = isPhoneCall ? 'phone' : isAppointment ? 'appointment' : 'activity';

			const onClick = (e: { stopPropagation: () => void }) => {
				e.stopPropagation();
				Tools.$upModal.open(isAppointment ? 'editAppointment' : 'editActivity', { id });
			};

			const validateDates = () => {
				const isPlanned = outcome === 'planned';
				const endDatePassed = endDate && moment(endDate).isBefore(moment(), 'day');
				const appointmenEndDatePassed = isAppointment && isPlanned && endDatePassed;

				const isOpenActivity = !isAppointment && closeDate === null;
				const timeHasPassed = isOpenActivity && time && moment(date).isBefore();
				const dateHasPassed = isOpenActivity && !time && moment(date).isBefore(moment(), 'day');

				if (appointmenEndDatePassed || timeHasPassed || dateHasPassed) {
					return 'red';
				}

				return 'grey-10';
			};
			const truncDescription = trunc(description, { length: 50 });

			content = (
				<Flex alignItems="center" gap={8}>
					<Icon name={icon} />
					<Flex direction="column">
						<Tooltip position="top" disabled={!notes} title={notes ? getTooltipNotes(notes) : ''}>
							<Link onClick={onClick}>
								<Text size={colSize}>{truncDescription}</Text>
							</Link>
						</Tooltip>
						<Text color={companyGroupOrSubaccounts ? validateDates() : 'grey-10'} size={colSize}>
							{moment(date).format('L')}
						</Text>
					</Flex>
				</Flex>
			);
			break;
		}
		case DisplayType.EsignStatus: {
			const getRejectedInitials = (involved: ListViewColumnEsignStatus['data']['involved']) => {
				const rejectee = involved.find(p => p.declineDate);

				if (rejectee) {
					return (rejectee.fstname[0] || '') + (rejectee.sndname[0] || '');
				} else {
					return T('default.someone').toLowerCase();
				}
			};

			let color: React.ComponentProps<typeof Text>['color'] | undefined;
			let text;
			switch (data.state) {
				case EsignEnum.DRAFT:
					color = 'grey-10';
					text = T('mail.draft');
					break;
				case EsignEnum.PENDING:
					text = T('esign.waitingForSignings');
					break;
				case EsignEnum.ALL_SIGNED:
					text = T('esign.allSigned');
					color = 'success-4';
					break;
				case EsignEnum.CANCELED:
					text = T('esign.canceled');
					color = 'red';
					break;
				case EsignEnum.REJECTED: {
					color = 'red';
					const initials = getRejectedInitials(data.involved);
					text = T('esign.rejectedBy') + ' ' + initials;
					break;
				}
				default:
					color = 'grey-10';
					text = T('esign.unhandledStatus', { statusCode: data.state });
					break;
			}
			content = (
				<Text size="sm" color={color}>
					{text}
				</Text>
			);
			break;
		}
		case DisplayType.EsignInvolved: {
			content = <EsignInvolvees involved={data} />;
			break;
		}
		case DisplayType.Number: {
			// Show placeholder for null or NaN's. Also if placeholder is set to "0" then show placeholder if value is 0
			const number = parseInt(data as string);
			if ((placeholder === '0' && number === 0) || (!data && data !== 0) || isNaN(number)) {
				content = placeholderContent;
			} else {
				content = <Text size={colSize}>{numberFormat(number)}</Text>;
			}
			break;
		}
		case DisplayType.Score: {
			const number = parseInt(data as string);
			if ((placeholder === '0' && number === 0) || (!data && data !== 0) || isNaN(number)) {
				content = placeholderContent;
			} else {
				content = <Text bold size={colSize}>{`${numberFormat(number)}${T('point')}`}</Text>;
			}
			break;
		}
		case DisplayType.DateTime:
		case DisplayType.Date: {
			content = data ? (
				<Text size={colSize} color={color}>
					{dateCalendar(data, type === DisplayType.DateTime)}
				</Text>
			) : (
				placeholderContent
			);
			break;
		}
		case DisplayType.Year: {
			content = data ? (
				<Text size={colSize} color={color}>
					{moment(data).format('YYYY')}
				</Text>
			) : (
				placeholderContent
			);
			break;
		}
		case DisplayType.DateUser: {
			content = (
				<>
					<Text size={colSize}>{dateCalendar(data.date, false)}</Text>
					<span className="column-subtitle">
						{data.name ? (
							<Text color="grey-10" size={colSize}>
								{data.name}
							</Text>
						) : (
							<Text italic color="grey-10" size={colSize}>
								{T('default.noUser')}
							</Text>
						)}
					</span>
				</>
			);
			break;
		}
		case DisplayType.Boolean:
			content = <Text size={colSize}>{T(data ? 'default.yes' : 'default.no')}</Text>;
			break;
		case DisplayType.PhoneNumber: {
			const typedData = data;

			if (typedData?.number) {
				content = <TelephoneLink {...typedData} />;
			} else {
				content = placeholderContent;
			}
			break;
		}
		case DisplayType.Currency:
			if (data) {
				const currData = data;
				const color = currData.colorize
					? currData.value > 0
						? 'success-4'
						: currData.value < 0
						? 'red'
						: undefined
					: undefined;
				content = (
					<Text color={color} size={colSize}>
						{new CurrencyFormat(currData.currency).short(currData.value)}
					</Text>
				);
			}
			break;
		case DisplayType.CurrencyChange:
			if (data) {
				if (data.value >= 0) {
					content = (
						<Row style={{ gap: 8 }}>
							<div className={classes.elem('IconCircle').mod('success').b()}>
								<Icon name="angle-up" color={'success-6'} />
							</div>
							<Text color={'success-6'} size={colSize}>
								{new CurrencyFormat(data.currency).short(data.value, 0)}
							</Text>
						</Row>
					);
				} else {
					content = (
						<Row style={{ gap: 8 }}>
							<div className={classes.elem('IconCircle').mod('danger').b()}>
								<Icon name="angle-down" color="danger-5" />
							</div>
							<Text color="danger-5" size={colSize}>
								{new CurrencyFormat(data.currency).short(data.value, 0)}
							</Text>
						</Row>
					);
				}
			}
			break;
		case DisplayType.Journey:
			content = <JourneyStepIcon id={data} />;
			break;
		case DisplayType.Percent:
			if (data !== null) {
				content = (
					<Text size={colSize}>
						{numberFormat(((data as number) || 0) * 100, undefined, 3, undefined, 0) + '%'}
					</Text>
				);
			} else {
				content = placeholderContent;
			}
			break;
		case DisplayType.CreditRating: {
			const { type, value } = data;
			const creditRatings = staticValues[type] || [];
			const creditRating = creditRatings.find(e => e.id === value);
			const assistChipType = creditRating?.assistChipType;
			const parts = creditRating?.name?.split(' - ') || [];
			const ratingPrefix = parts.length > 1 ? parts[0] : '-';
			const ratingTitle = parts.length > 1 ? parts[1] : parts[0];
			content =
				parts.length > 0 ? (
					<Row style={{ gap: 8 }} align={'left'} direction={'row'}>
						<AssistChip title={ratingPrefix} type={assistChipType} />
						<Text size={colSize}>{ratingTitle}</Text>
					</Row>
				) : (
					placeholderContent
				);
			break;
		}
		case DisplayType.StaticValue:
			if (data) {
				const val = data.value;
				const foundVal = staticValues[data.type]?.find(e => e.id === val?.toString());
				const value = foundVal?.name || data.value;

				content = <Text size={colSize}>{value}</Text>;
			} else {
				content = placeholderContent;
			}
			break;
		case DisplayType.URL:
			content = data ? (
				<Link onClick={event => event.stopPropagation()} href={data.toString?.()} target="_blank">
					{data.toString?.()}
				</Link>
			) : (
				placeholderContent
			);
			break;
		case DisplayType.Contacts: {
			const contacts = data || [];

			const [contact] = contacts;
			if (!contact) {
				content = placeholderContent;
				break;
			}

			const hasMore = contacts.length > 1;

			content = (
				<>
					<Text size={colSize}>
						<Link onClick={e => e.stopPropagation()} href={getContactHref(contact.id)}>
							{contact.name}
						</Link>
						{hasMore ? <b>{` ${contacts.length - 1} ${T('filters.more').toLowerCase()}`}</b> : null}
					</Text>
					<Text color="grey-10" size={colSize}>
						{contact.title}
					</Text>
				</>
			);

			break;
		}
		case DisplayType.ClientLink:
		case DisplayType.ContactLink:
		case DisplayType.ProjectLink: {
			const linkProps = data;

			const typeHrefMap = {
				[DisplayType.ClientLink]: getAccountHref,
				[DisplayType.ContactLink]: getContactHref,
				[DisplayType.ProjectLink]: getProjectHref
			};

			content = linkProps?.id ? (
				type === DisplayType.ClientLink ? (
					<Flex alignItems="center">
						<Flex direction="column">
							<Text size={colSize}>
								<ClientTooltip id={linkProps.id}>
									<Link onClick={e => e.stopPropagation()} href={typeHrefMap[type](linkProps.id)}>
										{linkProps.name}
									</Link>
								</ClientTooltip>
							</Text>
							{linkProps.journeyStep ? (
								<Text size={colSize} color="grey-10">
									{T(`default.journeyStep.${linkProps.journeyStep}`)}
								</Text>
							) : null}
						</Flex>
						<SubAccountLabel operationalAccount={(linkProps as ClientEntity).operationalAccount ?? null} />
					</Flex>
				) : (
					<Text size={colSize}>
						<Link onClick={e => e.stopPropagation()} href={typeHrefMap[type](linkProps.id)}>
							{linkProps.name}
						</Link>
					</Text>
				)
			) : (
				<Text size={colSize} italic color="grey-10">
					{T(placeholder)}
				</Text>
			);
			break;
		}
		case DisplayType.MainAccount: {
			const { id, name, operationalAccount } = data;
			const account = operationalAccount || { id, name };

			content = (
				<Flex alignItems="center">
					<Flex direction="column">
						<Text size={colSize}>
							<ClientTooltip id={account.id}>
								<Link onClick={e => e.stopPropagation()} href={getAccountHref(account.id)}>
									{account.name}
								</Link>
							</ClientTooltip>
						</Text>
					</Flex>
				</Flex>
			);
			break;
		}
		case DisplayType.ARRChange: {
			content = <Text size="sm">{T(`arrchange.type.${data}`)}</Text>;
			break;
		}
		case DisplayType.SubtitleText: {
			content = (
				<>
					<Text size={colSize}>{data.title}</Text>
					<span className="column-subtitle">
						{data.subtitle ? (
							<Text color="grey-10" size={colSize}>
								{data.subtitle}
							</Text>
						) : null}
					</span>
				</>
			);
			break;
		}

		case DisplayType.Location: {
			content = data?.name ? (
				<Text size={colSize} style={{ display: 'flex', gap: '8px' }}>
					{data.country ? (
						<i className={`flag-icon flag-icon-${data.country?.toLowerCase()}`} />
					) : (
						<Icon name="map-pin" />
					)}{' '}
					{data.name}
				</Text>
			) : null;
			break;
		}
		case DisplayType.LeadSource:
			content = <LeadSource visit={{ leadSource: data }} />;
			break;
		case DisplayType.ContactTitle: {
			const { contact } = data;

			content = contact ? (
				<>
					<EllipsisTooltip title={contact.name}>
						<Text color="bright-blue" size="sm">
							<Link onClick={e => e.stopPropagation()} href={getContactHref(contact.id)}>
								{contact.name}
							</Link>
						</Text>
					</EllipsisTooltip>

					{contact.titleCategory?.value || contact.title ? (
						<Flex gap={4}>
							{contact.titleCategory?.value ? (
								<EllipsisTooltip title={contact.titleCategory?.value}>
									<Text size="sm" color="black">
										{contact.titleCategory?.value + ' '}
									</Text>
								</EllipsisTooltip>
							) : null}
							{contact.title ? (
								<EllipsisTooltip title={contact.title}>
									<Text size="sm" color="grey-10">
										{contact.titleCategory?.value ? `(${contact.title})` : contact.title}
									</Text>
								</EllipsisTooltip>
							) : null}
						</Flex>
					) : null}
				</>
			) : (
				placeholderContent
			);
			break;
		}
		case DisplayType.Contact: {
			const { contact, client, ellipsis = true } = data;
			const Wrapper = ellipsis ? EllipsisTooltip : React.Fragment;

			content = contact ? (
				<>
					<Wrapper {...(ellipsis ? { title: contact.name } : {})}>
						<Text color="bright-blue" size="sm">
							{contact?.id ? (
								<Link onClick={e => e.stopPropagation()} href={getContactHref(contact.id)}>
									{contact.name}
								</Link>
							) : (
								contact.name
							)}
						</Text>
					</Wrapper>
					<Text color="bright-blue" size="sm">
						{client?.id ? (
							<Wrapper {...(ellipsis ? { title: client.name } : {})}>
								<div>
									<Link
										color="grey-10"
										onClick={e => e.stopPropagation()}
										href={getAccountHref(client.id)}
									>
										{client.name?.toUpperCase() ?? '-'}
									</Link>
								</div>
							</Wrapper>
						) : (
							'-'
						)}
					</Text>
				</>
			) : (
				placeholderContent
			);
			break;
		}
		case DisplayType.MailTemplate:
			content = data ? (
				<Link
					onClick={e => {
						e.stopPropagation();
						history.push(`/mail-template-editor/${data.id}/design`);
					}}
				>
					{data.name}
				</Link>
			) : (
				placeholderContent
			);
			break;
		case DisplayType.MailStatus:
			content = data ? <MailStatus type={data.type ?? data} errorCause={data.errorCause} /> : placeholderContent;
			break;
		case DisplayType.EllipsisText: {
			content = data ? (
				<EllipsisTooltip title={data.toString?.()}>
					<Text size={colSize}>{data.toString?.()}</Text>
				</EllipsisTooltip>
			) : (
				placeholderContent
			);
			break;
		}
		case DisplayType.Stage: {
			content = data ? (
				<Flex alignItems="center">
					<Text size={colSize}>
						<Icon space="mrm" name="opportunity" />
						{data}
					</Text>
				</Flex>
			) : (
				placeholderContent
			);
			break;
		}
		case DisplayType.Text:
		default:
			content = data ? <Text size={colSize}>{data.toString?.()}</Text> : placeholderContent;
			break;
	}
	return <TableColumn className={new BemClass('ListViewColumn', className).b()}>{content}</TableColumn>;
};

type ListViewCustomColumnProps = {
	values: EntityCustomField[];
	column: string;
	fields: CustomField[];
	colSize?: SizesLarge;
};
export const ListViewCustomColumn: React.FC<ListViewCustomColumnProps> = ({
	values,
	column,
	fields,
	colSize = 'sm'
}) => {
	return (
		<TableColumn>
			<CustomFieldValueText size={colSize} values={values} column={column} fields={fields} />
		</TableColumn>
	);
};

type ListViewAddressColumnProps = {
	column: string;
	item: any;
	colSize?: SizesLarge;
};
export const ListViewAddressColumn: React.FC<ListViewAddressColumnProps> = ({ column, item, colSize }) => {
	return (
		<TableColumn size={colSize}>
			<AddressField column={column} client={item}></AddressField>
		</TableColumn>
	);
};

type ListViewCategoryColumnProps = {
	values: EntityCategory[];
	column: string;
	categories: Category[];
	colSize?: SizesLarge;
};
export const ListViewCategoryColumn: React.FC<ListViewCategoryColumnProps> = ({
	values,
	column,
	categories,
	colSize
}) => {
	const categoryTypeId = column === 'categories' ? 0 : getCategoryTypeIdFromFilterName(column);
	const categoryMap = categories.reduce<{ [id: number]: number }>((res, category) => {
		res[category.id] = category.categoryTypeId ?? category.categoryType;
		return res;
	}, {});

	const names = values
		.filter(entityCategory => categoryMap[entityCategory.id] === categoryTypeId)
		.map(category => category.name);

	return (
		<ListViewColumn colSize={colSize} type={DisplayType.Array} data={names} placeholder={'default.noCategories'} />
	);
};

type ListViewDefaultColumnProps<ItemType> = {
	item: ItemType;
	column: string;
	attributes: { [name: string]: Attr };
	customFields?: CustomField[];
	categories?: Category[] | null;
	colSize?: SizesLarge;
};

export const ListViewDefaultColumn = <ItemType extends {}>({
	item,
	column,
	attributes,
	customFields,
	categories,
	colSize
}: ListViewDefaultColumnProps<ItemType>) => {
	const masterCurrency = useSelector(({ App }: RootState) => App.metadata?.customerCurrencies)?.find(
		c => c.masterCurrency
	)?.iso;
	if (isCustom(column) && customFields) {
		return (
			<ListViewCustomColumn
				fields={customFields}
				values={(item['custom' as keyof ItemType] as unknown as EntityCustomField[]) || []}
				column={column}
				colSize={colSize}
			/>
		);
	} else if (isAddress(column)) {
		return <ListViewAddressColumn item={item} column={column} />;
	} else if ((attributes[column]?.displayType === DisplayType.Category || isCustomCategory(column)) && categories) {
		const field = attributes[column]?.displayKey || 'categories';
		const values = _.get<EntityCategory[]>(item, field, []);

		return (
			<ListViewCategoryColumn colSize={colSize} categories={categories} values={values || []} column={column} />
		);
	} else if (column.startsWith('AppSyncStatus_')) {
		const order = item as unknown as Order;
		const integId = parseInt(column.split('_')[1]);
		const statusCode = order.lastIntegrationStatus?.find(e => e.integrationId === integId)?.callbackStatus;
		const [message, color] =
			statusCode === 0
				? ['integration.tooltip.inProgress', 'yellow']
				: statusCode === 1
				? ['integration.tooltip.success', 'success-4']
				: ['integration.tooltip.failed', 'red'];

		return (
			<TableColumn>
				<Tooltip
					position="left"
					title={T(message, { entity: T('default.order'), app: attributes[column]?.displayKey || '' })}
					distance={20}
				>
					<Icon name="circle" color={color} />
				</Tooltip>
			</TableColumn>
		);
	}

	const displayType = attributes[column]?.displayType || DisplayType.Text;
	const placeholder = attributes[column]?.placeholder || '';
	const displayAttr = attributes[column]?.displayKey || column;
	const displayKeyMap = attributes[column]?.displayKeyMap;

	let data;
	if (Array.isArray(displayAttr) && displayKeyMap && displayType === DisplayType.Currency) {
		const currencyFallback = attributes[column]?.currencyFallback;
		data = {} as { [key: string]: any };
		for (const attr of displayAttr) {
			const mappedKey = displayKeyMap[attr] ?? attr;
			data[mappedKey] = _.get<any>(item, attr) ?? null;
			if (mappedKey === 'currency' && !data[mappedKey]) {
				switch (currencyFallback) {
					case 'master': {
						data[mappedKey] = masterCurrency;
						break;
					}
					default: {
						data[mappedKey] = currencyFallback;
					}
				}
			}
		}
		data.colorize = attributes[column].colorize;
	} else if (displayType === DisplayType.Array) {
		const [key, ...child] = displayAttr;
		data = Array.isArray(_.get<any>(item, key)) ? _.map(_.get<any>(item, key), i => _.get<any>(i, child)) : null;
	} else if (Array.isArray(displayAttr)) {
		data = {} as { [key: string]: any };
		for (const attr of displayAttr) {
			const mappedKey = displayKeyMap ? displayKeyMap[attr] : attr;
			data[mappedKey] = _.get<any>(item, attr) ?? null;
		}
	} else if (displayType === DisplayType.CreditRating || displayType === DisplayType.StaticValue) {
		data = { value: _.get<any>(item, displayAttr) ?? null, type: column };
	} else if (attributes[column]?.displayType === DisplayType.PhoneNumber) {
		data = {
			number: _.get<any>(item, displayAttr),
			client: _.get<any>(item, 'client'),
			contact: _.get<any>(item, 'contact')
		};
	} else if (displayType === DisplayType.Activity) {
		data = _.get<any>(item, displayAttr) || item;
	} else if (displayType === DisplayType.Contact) {
		const contact = _.get<any>(item, 'contact') || item;
		data = {
			contact,
			ellipsis: true,
			client: _.get<any>(item, 'client')
		};
	} else if (displayType === DisplayType.ContactTitle) {
		const contact = _.get<any>(item, 'contact') || item;
		data = {
			contact,
			ellipsis: true
		};
	} else {
		data = _.get<any>(item, displayAttr) ?? null;
	}
	// some weird quirk with enums? Both `displayType` and `type` prop resolve correctly to `DisplayType`, but it still breaks on individual union members
	// @ts-expect-error
	return <ListViewColumn type={displayType} data={data} placeholder={placeholder} colSize={colSize} />;
};

// This default row renderer will try to figure out the column content by using the provided attributes
// If you need any custom column content you will have to implement your own
export const renderDefaultTableRow = <ItemType extends {}>(
	item: ItemType,
	{
		attributes,
		columns,
		itemIdentifier,
		customFields,
		categories,
		renderToolsColumn
	}: ListViewTableProvided<ItemType, RenderTableRowProvided<ItemType>>
) => {
	const columnElements = columns.map(column => (
		<ListViewDefaultColumn<ItemType>
			key={column}
			column={column}
			attributes={attributes}
			item={item}
			customFields={customFields}
			categories={categories}
		/>
	));
	if (renderToolsColumn) {
		columnElements.push(<TableColumn key="tools" />);
	}
	return <TableRow key={item[itemIdentifier] as any}>{columnElements}</TableRow>;
};

export const renderDefaultNoData = (formatNoData: () => string, subtitle?: string) => () => {
	return <DefaultNoData subtitle={subtitle} formatNoData={formatNoData} />;
};
