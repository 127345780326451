import Resource from 'Resources/Resource';

class ProjectPlanInvoiceResource extends Resource {
	eventName = 'projectPlanInvoice';
	constructor() {
		super('projectPlanInvoices', null, { notificationTitle: 'editProjectPlan.invoice' });
	}
}

const resource = new ProjectPlanInvoiceResource();

export default resource;
