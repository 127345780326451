import React from 'react';
import useInputController, { options } from '../hooks/useInputController';

import type { TextareaHTMLAttributes } from 'react';
import type { Options } from '../hooks/useInputController';

type Props = Omit<TextareaHTMLAttributes<HTMLTextAreaElement>, 'value'> & {
	value: string;
	controllerOptions?: Partial<Options<string, string>>;
};

const TextArea = React.forwardRef<HTMLTextAreaElement, Props>(({ controllerOptions, ...props }, ref) => {
	const instanceOptions = controllerOptions ? { ...options.text, ...controllerOptions } : options.text;
	const controlledProps = useInputController<HTMLTextAreaElement, string, string>(props, instanceOptions);

	return <textarea {...controlledProps} ref={ref} />;
});

export default TextArea;
