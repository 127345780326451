import { Modal, ModalContent, ModalControls, ModalHeader } from '@upsales/components';
import { asyncModalAdapter } from 'App/helpers/angularPortingHelpers';
import { useTranslation } from 'Components/Helpers/translate';
import React, { useEffect, useState } from 'react';
import { ModalProps } from '../Modals/Modals';
import { PrimaryButton, ThirdButton } from '@upsales/components/Buttons';
import openModal from 'App/services/Modal';
import RequestBuilder from 'Resources/RequestBuilder';
import _ from 'lodash';

type Props = ModalProps & {
	account: { name: string; id: number; userRemovable?: boolean };
	accountId: number;
	customerId: number;
	duns: number;
	dontRedirect?: boolean;
};

export default function ConfirmSoliditetUpdateModal(props: Props) {
	const [hasExisting, setHasExisting] = useState<boolean>(false);
	const [buying, setBuying] = useState<boolean>(false);
	const [merging, setMerging] = useState<boolean>(false);
	const [credits, setCredits] = useState<number>(0);

	const { t } = useTranslation();

	const [meta, setMeta] = useState<{
		metadata: any | null;
		existingAccounts: any | null;
		currentAccount: any | null;
		accounts: any | null;
	}>({
		metadata: null,
		existingAccounts: [],
		currentAccount: null,
		accounts: []
	});

	const customerId = Tools.AppService.getCustomerId();

	const properties: any[] = [];

	useEffect(() => {
		const fetchData = async () => {
			try {
				let existingAccountsData: any = null;

				const nameFilter = new RequestBuilder();
				nameFilter.addFilter(
					Tools.SoliditetClient.attr.name,
					nameFilter.comparisonTypes.Search,
					props.account.name
				);
				nameFilter.limit = 250;
				nameFilter.addSort('_score', false);
				nameFilter.addSort(Tools.SoliditetClient.attr.headquarters, false);

				const accountsPromise = Tools.SoliditetClient.customer(customerId)
					.find(nameFilter.build())
					.then(res => {
						const dunsFilter = new RequestBuilder();
						const duns = Array.isArray(res.data)
							? res.data.map((soliditetClient: any) => soliditetClient.dunsNo)
							: [];
						dunsFilter.addFilter(Tools.Account.attr.dunsNo, nameFilter.comparisonTypes.Equals, duns);
						dunsFilter.addFilter(
							Tools.Account.attr.id,
							nameFilter.comparisonTypes.NotEquals,
							props.accountId
						);
						dunsFilter.limit = 250;
						return Tools.Account.customer(customerId)
							.find(dunsFilter.build())
							.then(existingAccounts => {
								existingAccountsData = existingAccounts.data;
								return res ? res.data : null;
							});
					});

				const currentAccountPromise = new Promise((resolve, reject) => {
					if (props.duns && props.duns !== 0 && props.duns !== -1) {
						const dunsFilter = new RequestBuilder();
						dunsFilter.addFilter(
							Tools.SoliditetClient.attr.dunsNo,
							dunsFilter.comparisonTypes.Search,
							props.duns
						);
						dunsFilter.limit = 250;
						Tools.SoliditetClient.customer(customerId)
							.find(dunsFilter.build())
							.then(res => {
								if (!res.data || !Array.isArray(res.data) || !res.data.length) {
									resolve(null);
								} else {
									resolve(res.data[0]);
								}
							})
							.catch(reject);
					} else {
						resolve(null);
					}
				});

				const meta = {
					metadata: Promise.resolve(Tools.AppService.getMetadata()),
					existingAccounts: existingAccountsData,
					currentAccount: currentAccountPromise,
					accounts: accountsPromise
				};

				const results = await Promise.all([
					meta.metadata,
					meta.existingAccounts,
					meta.currentAccount,
					meta.accounts
				]);

				setMeta({
					metadata: results[0],
					existingAccounts: results[1],
					currentAccount: results[2],
					accounts: results[3]
				});

				setCredits(results[0].soliditetCredits);
			} catch (error) {
				console.error('Error fetching data:', error);
			}
		};

		fetchData();
	}, []);

	const hasDuns = props.duns && props.duns !== 0 && props.duns !== -1 && meta.currentAccount;

	if (!hasDuns && props.account.userRemovable) {
		meta.existingAccounts.data.forEach((existing: any) => {
			// eslint-disable-next-line you-dont-need-lodash-underscore/find
			const match = _.find(meta.accounts, (account: any) => {
				return account.dunsNo === parseInt(existing.dunsNo);
			});
			if (match) {
				setHasExisting(true);
				match.mergeAble = true;
				match.userEditable = existing.userEditable;
			}
		});
	}

	const stateAccounts = !hasDuns ? meta.accounts : [];

	const options = {
		updateExisting: true,
		skipProjects: true,
		skipAccountManagers: true,
		skipAddresses: false,
		skipCategories: true
	};

	const confirm = () => {
		setBuying(true);
		Tools.SoliditetClient.customer(customerId)
			.refresh(props.accountId, properties, options)
			.then((res: any) => {
				props.close(res.data.dunsNo);
			})
			.catch(() => {
				setBuying(false);
			});
	};

	const handleData = function (account: any) {
		setBuying(true);
		account.$buying = true;

		Tools.Account.customer(customerId)
			.save({ id: props.accountId, dunsNo: Number(account.dunsNo) }, { skipNotification: true })
			.then(() => {
				confirm();
			})
			.catch(function () {
				setBuying(false);
			});
	};

	const onUpdateConfirm = (account: any) => {
		setMerging(true);
		account.$merging = true;

		const mergeAccount = (meta?.existingAccounts?.data ?? []).find((existing: any) => {
			return account.dunsNo === parseInt(existing.dunsNo);
		});

		Tools.Account.customer(customerId)
			.merge(mergeAccount, account)
			.then(function () {
				setTimeout(function () {
					if (!props.dontRedirect) {
						Tools.$state.go('account.dashboard', { id: mergeAccount.id });
					} else {
						props.close();
					}
				}, 1500);
			})
			.catch(function () {
				setMerging(false);
			});
	};

	const mergeAccounts = function (account: any) {
		// if multiple show all
		// show modal for confirmation
		let str = t('account.mergeInfo1');
		str += `<b> ${account.name}</b>`;
		str += `<br/><br/>${t('account.mergeInfo2')}`;

		if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
			openModal('Alert', {
				title: 'account.mergeClients',
				body: str,
				headerIcon: 'merge',
				confirmButtonText: 'default.merge',
				onClose: confirmed => {
					if (confirmed) {
						onUpdateConfirm(account);
					}
				}
			});
			return;
		}

		// eslint-disable-next-line promise/catch-or-return
		Tools.$upModal
			.open('defaultConfirm', {
				title: 'account.mergeClients',
				body: str,
				resolveFalse: 'default.merge',
				no: 'default.abort',
				icon: 'fa-success'
			})
			.then(function () {
				onUpdateConfirm(account);
			});
	};

	return (
		<Modal className={props.className}>
			<ModalHeader
				title={t('soliditet.update') + t('soliditet.companyDataFromSoliditet').toLowerCase()}
				onClose={() => props.close()}
			></ModalHeader>
			<ModalContent>
				<div className="up-panel">
					<div className="up-panel-content">
						{credits ? (
							<>
								{hasDuns ? (
									<p>
										{t('soliditet.doYouWantToUpdate')} {t('soliditet.companyDataFor').toLowerCase()}{' '}
										<b>{props.account.name}</b> {t('soliditet.fromSoliditet').toLowerCase()}?
									</p>
								) : (
									<>
										{stateAccounts.length > 0 ? (
											<p>
												<b>{props.account.name}</b> {t('soliditet.missingDuns')}
											</p>
										) : (
											<p>{t('soliditet.noMatches')}</p>
										)}
										{hasExisting ? (
											<h2
												style={{
													fontSize: '17px',
													marginBottom: '10px',
													marginTop: '10px',
													fontWeight: 'bold'
												}}
											>
												{t('default.existsInUpsales')}
											</h2>
										) : null}
										{stateAccounts.length > 0
											? stateAccounts.map((account: any, index: number) => (
													<div key={index} style={{ marginBottom: '10px' }}>
														{account.mergeAble === true ? (
															<div>
																{account.userEditable ? (
																	<button
																		type="button"
																		className="pull-right btn up-btn btn-sm btn-bright-blue"
																		disabled={merging}
																		onClick={() => mergeAccounts(account)}
																		style={{ marginTop: '5px' }}
																	>
																		{account.$merging ? (
																			<b
																				className={`fa fa-refresh ${
																					account.$merging ? 'fa-spin' : ''
																				}`}
																			></b>
																		) : null}
																		{t(
																			account.$merging
																				? 'default.merging'
																				: 'default.merge'
																		)}
																	</button>
																) : null}

																<span
																	style={{
																		display: 'inline-block',
																		width: '260px',
																		textOverflow: 'ellipsis',
																		whiteSpace: 'nowrap',
																		overflow: 'hidden'
																	}}
																>
																	{account.name}
																</span>

																<br />

																<span
																	style={{
																		display: 'inline-block',
																		color: '#999'
																	}}
																>
																	{account.city}
																</span>

																{account.isHeadqueraters ? (
																	<b
																		className="fa fa-home"
																		title={t('soliditet.headquarters')}
																	></b>
																) : null}

																<div className="clearfix"></div>
															</div>
														) : null}
													</div>
											  ))
											: null}
									</>
								)}

								{stateAccounts.length > 0 && !hasExisting && stateAccounts.length === 1 ? (
									<h2
										style={{
											fontSize: '17px',
											marginBottom: '10px',
											marginTop: '10px',
											fontWeight: 'bold'
										}}
									>
										{t('default.fromBisnode')}
									</h2>
								) : null}

								{stateAccounts.length > 0
									? stateAccounts.map((account: any, index: number) => (
											<div key={index} className="list-row">
												{!account.mergeAble ? (
													<div>
														<button
															className="pull-right btn up-btn btn-sm btn-bright-blue"
															disabled={buying}
															onClick={() => handleData(account)}
															style={{ marginTop: '5px' }}
														>
															{account.$buying ? (
																<b
																	className={`fa fa-refresh ${
																		account.$buying ? 'fa-spin' : ''
																	}`}
																></b>
															) : null}
															{t(account.$buying ? 'default.updating' : 'default.choose')}
														</button>

														<span
															style={{
																display: 'inline-block',
																width: '268px',
																textOverflow: 'ellipsis',
																whiteSpace: 'nowrap',
																overflow: 'hidden'
															}}
														>
															{account.name}
														</span>

														<br />

														<span
															style={{
																display: 'inline-block',
																color: '#999',
																textTransform: 'uppercase',
																fontSize: '12px',
																letterSpacing: '0.5px'
															}}
														>
															{account.city}
														</span>

														{account.isHeadqueraters ? (
															<b
																className="fa fa-home text-bright-blue"
																title={t('soliditet.headquarters')}
															></b>
														) : null}

														<div className="clearfix"></div>
													</div>
												) : null}
											</div>
									  ))
									: null}
							</>
						) : (
							<p style={{ color: 'red' }}>{t('soliditet.notEnoughCredits')}.</p>
						)}

						{hasDuns || (!hasDuns && stateAccounts.length > 0) ? (
							<p style={{ marginTop: '20px' }}>
								{t('soliditet.youHave')} <b className={credits ? 'text-green' : ''}>{credits}</b>{' '}
								{t('soliditet.availableCompanyPurchases').toLowerCase()}.
							</p>
						) : null}
					</div>
				</div>
			</ModalContent>
			<ModalControls>
				<div className="up-modal-controls">
					{hasDuns ? (
						<PrimaryButton
							onClick={() => confirm()}
							className="btn up-btn btn-green"
							disabled={!credits || buying}
						>
							{buying ? <span className={`fa fa-refresh ${buying ? 'fa-spin' : ''}`}></span> : null}
							{t(buying ? 'default.updating' : 'default.update')}
						</PrimaryButton>
					) : null}
					<ThirdButton className="btn up-btn btn-bright-blue btn-link" onClick={() => props.close()}>
						{t('default.abort')}
					</ThirdButton>
				</div>
			</ModalControls>
		</Modal>
	);
}

export const openConfirmSoliditetUpdate = asyncModalAdapter({
	featureFlag: 'OPEN_CONFIRM_SOLIDITET_UPDATE_REACT',
	openModalName: 'ConfirmSoliditetUpdateModal',
	upModalName: 'confirmSoliditetUpdate'
});
