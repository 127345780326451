import React from 'react';
import FieldTranslations from 'Resources/FieldTranslations';
import StandardField from 'Resources/StandardField';
import T from 'Components/Helpers/translate';
import {
	Text,
	Headline,
	Card,
	Icon,
	Title,
	ButtonBox,
	Help,
	Tooltip,
	Flex,
	Block,
	StateFrame
} from '@upsales/components';
import Stages from '../Stages';
import { connect } from 'react-redux';
import { setDecisionMakers, save } from 'Store/reducers/SalesCoachReducer';
import BemClass from '@upsales/components/Utils/bemClass';
import EditorHeaderButton from 'Components/EditorHeader/EditorHeaderButton';
import UpSelect from 'Components/Inputs/UpSelect';
import { makeCancelable } from 'App/babel/helpers/promise';
import './DecisionMakers.scss';
import { cloneDeep } from 'lodash';
import logError from 'App/babel/helpers/logError';
import { salesCoachTracker } from 'App/babel/helpers/Tracker';
import { getStages } from 'Store/actions/AppGetterActions';
import OrderStage from 'App/resources/Model/OrderStage';
import SalesProcessAddonHint from 'Components/Admin/SalesProcess/SalesCoach/SalesProcessAddonHint';
import InAppChat from 'App/babel/services/InAppChat';
import { PrimaryButton, ThirdButton } from '@upsales/components/Buttons';
import { Fade } from '@upsales/components/animations';
import { asyncModalAdapter, setupComponentCompatibility } from 'App/helpers/angularPortingHelpers';

type StageObj = {
	active: boolean;
	required?: boolean;
};

type titleCategoryObj = {
	titleId: number;
};

type allTitleCategoriesObj = {
	tagId: number;
	value: string;
};

type Selected = {
	[key: number]: StageObj;
};

type DecisionMakersType = {
	active: boolean;
	entireSalesProcess: boolean;
	stages: Selected;
	titleCategories: Array<titleCategoryObj>;
};

type Props = {
	reject: () => void;
	setDecisionMakers: (decisionMakers: Object) => void;
	save: () => void;
	decisionMakers: DecisionMakersType;
	id: number;
	getStages: (
		type: 'all' | 'order' | 'opportunity' | 'lost' | 'won' | 'excludedIds',
		skipAuth: boolean
	) => OrderStage[] | number[];
};

type State = {
	decisionMakers: DecisionMakersType;
	titleCategoriesToChooseFrom: Array<allTitleCategoriesObj>;
	isTitleCategoryFieldActive: boolean;
	saving: boolean;
};

const mapStateToProps = ({
	SalesCoachReducer
}: {
	SalesCoachReducer: { decisionMakers: DecisionMakersType; id: number };
}) => ({
	decisionMakers: SalesCoachReducer.decisionMakers,
	id: SalesCoachReducer.id
});

const mapDispatchToProps = { setDecisionMakers, save, getStages };

class DecisionMakers extends React.Component<Props, State> {
	fetchTitleCategoriesPromise:
		| { promise: Promise<{ data: allTitleCategoriesObj[] }>; cancel: () => void }
		| undefined;
	lang: { [key: string]: string };

	constructor(p: Props) {
		super(p);
		this.lang = {
			cancel: T('default.cancel'),
			save: T('default.save'),
			decisionMakers: T('admin.newSalesProcess.decisionMakers'),
			activateTitleCategoryHeader: T('admin.newSalesProcess.activateTitleCategoryHeader'),
			activateTitleCategoryText: T('admin.newSalesProcess.activateTitleCategoryText'),
			activateTitleCategoryButton: T('admin.newSalesProcess.activateTitleCategoryButton'),
			activateNewFieldsHeader: T('admin.newSalesProcess.activateNewFieldsHeader'),
			activateNewFields: T('admin.newSalesProcess.activateNewFields'),
			chatWithUpsales: T('admin.newSalesProcess.chatWithUpsales'),
			descriptionDecisionMaker: T('admin.newSalesProcess.descriptionDecisionMaker'),
			whichTitleCategory: T('admin.newSalesProcess.whichTitleCategory'),
			addMoreDecisionMakers: T('admin.newSalesProcess.addMoreDecisionMakers'),
			thisAddOnHelpsYou: T('admin.newSalesProcess.thisAddOnHelpsYou'),
			readMore: T('default.readMore'),
			viewAddOn: T('admin.newSalesProcess.viewAddOn'),
			billingAdminRequired: T('admin.newSalesProcess.billingAdminRequired'),
			searchTitles: T('admin.newSalesProcess.searchTitles'),
			atWhatPoint: T('admin.newSalesProcess.atWhatPoint'),
			throughEntireSalesProcess: T('admin.newSalesProcess.throughEntireSalesProcess'),
			specificStage: T('admin.newSalesProcess.specificStage'),
			backToChecklist: T('admin.newSalesProcess.backToChecklist'),
			chooseStagesToSave: T('admin.newSalesProcess.chooseStagesToSave'),
			pickATitleCategoryToSave: T('admin.newSalesProcess.pickATitleCategoryToSave')
		};
		this.state = {
			decisionMakers: cloneDeep(p.decisionMakers),
			titleCategoriesToChooseFrom: [],
			isTitleCategoryFieldActive: true,
			saving: false
		};
	}

	componentDidMount() {
		const titleCategoryField = Tools.AppService.getMetadata().standardFields.Contact.TitleCategory;

		this.fetchTitleCategoriesPromise = makeCancelable(FieldTranslations.find({ type: 'titlecategory' }));
		this.fetchTitleCategoriesPromise.promise
			.then(({ data }) => {
				this.setState({
					titleCategoriesToChooseFrom: data,
					isTitleCategoryFieldActive: titleCategoryField?.active
				});
				// If no title category is chosen then we pick them CEO or similar if they have one
				if (this.state.decisionMakers.titleCategories.length === 0) {
					const ceoId = data.find(title => this.hasCeoTitleCategory(title))?.tagId;
					if (ceoId) {
						this.setState({
							decisionMakers: {
								...this.state.decisionMakers,
								titleCategories: [{ titleId: ceoId }]
							}
						});
					}
				}
			})
			.catch(err => {
				logError(err, 'Failed to fetch category titles');
			});
	}

	componentWillUnmount() {
		if (this.fetchTitleCategoriesPromise) {
			this.fetchTitleCategoriesPromise.cancel();
		}
	}

	hasCeoTitleCategory = (title: { value: string }) => {
		return (
			title.value.toUpperCase() === 'CEO' ||
			title.value.toUpperCase() === 'VD' ||
			title.value.toUpperCase() === 'HÖGSTA ANSVARIG' ||
			title.value.toUpperCase() === 'VERKSTÄLLANDE DIREKTÖR'
		);
	};

	track = (id: number, active: boolean) => {
		const { getStages } = this.props;
		const probability = (getStages('opportunity', false) as OrderStage[]).find(
			stage => stage.id === id
		)?.probability;
		if (!probability) {
			return;
		}

		const { ACTIVATED_DECISION_MAKER, DEACTIVATED_DECISION_MAKER } = salesCoachTracker.events;
		const event = active ? ACTIVATED_DECISION_MAKER : DEACTIVATED_DECISION_MAKER;
		salesCoachTracker.track(event, { probability });
	};

	flipSelected = (id: number) => {
		const { decisionMakers: decisionMaker } = this.state;
		if (!decisionMaker.stages[id]) {
			decisionMaker.stages[id] = { active: false };
		}
		decisionMaker.stages[id].active = !decisionMaker.stages[id]?.active;
		if (!decisionMaker.stages[id].active && decisionMaker.stages[id]?.required) {
			decisionMaker.stages[id].required = false;
		}

		this.track(id, decisionMaker.stages[id].active);
		this.setState({ decisionMakers: decisionMaker });
	};

	flipRequired = (id: number) => {
		const { decisionMakers } = this.state;
		if (!decisionMakers.stages[id]) {
			decisionMakers.stages[id] = { active: false };
		}
		decisionMakers.stages[id].required = !decisionMakers.stages[id]?.required;
		if (decisionMakers.stages[id].required && !decisionMakers.stages[id]?.active) {
			decisionMakers.stages[id].active = true;
		}
		this.setState({ decisionMakers: decisionMakers });
	};

	toggleSpecificStage = () => {
		const { decisionMakers } = this.state;
		if (!decisionMakers.entireSalesProcess) {
			return;
		}
		this.setState({ decisionMakers: { ...decisionMakers, entireSalesProcess: false, stages: {} } });
	};

	toggleEntireSalesProcess = () => {
		const { decisionMakers } = this.state;
		if (decisionMakers.entireSalesProcess) {
			return;
		}
		this.setState({ decisionMakers: { ...decisionMakers, entireSalesProcess: true, stages: {} } });
	};

	haveNoStagesSelected() {
		if (!this.state.decisionMakers.entireSalesProcess) {
			const activeStage = Object.values(this.state.decisionMakers.stages).find(stage => {
				return stage.active;
			});
			return !activeStage;
		}
		return false;
	}

	activateTitleCategory = async () => {
		const { isTitleCategoryFieldActive } = this.state;
		const metadata = Tools.AppService.getMetadata();

		const titleCategoryField = {
			...metadata.standardFields.Contact.TitleCategory,
			active: !isTitleCategoryFieldActive
		};

		try {
			this.setState({ saving: true });

			await StandardField.save(titleCategoryField);

			metadata.standardFields.Contact.TitleCategory.active = true;
			Tools.AppService.setMetadata(metadata);
			this.setState({
				isTitleCategoryFieldActive: true,
				saving: false
			});
		} catch (e) {
			logError(e, 'Failed to activate title category');
		}
	};

	startChatWithSupport() {
		InAppChat.open();
	}

	trackContact(tagId: number) {
		const { titleCategoriesToChooseFrom } = this.state;
		const titleCategory = titleCategoriesToChooseFrom.find(titleCategory => titleCategory.tagId === tagId);
		if (!titleCategory?.value) {
			return;
		}

		salesCoachTracker.track(salesCoachTracker.events.ADDED_TITLE_CATEGORY, { titleCategory: titleCategory.value });
	}

	render() {
		const { reject, setDecisionMakers, save } = this.props;
		const { decisionMakers, saving, isTitleCategoryFieldActive } = this.state;
		const hasNewFields = Tools.FeatureHelper.hasSoftDeployAccess('NEW_FIELDS');

		const isBillingAdmin = Tools.AppService.getSelf().billingAdmin;
		const classNames = new BemClass('DecisionMakers');

		return (
			<Fade>
				<Flex className={classNames.b()} justifyContent="center" direction="column" gap="u7">
					<Card>
						<Flex justifyContent="space-between" flex={[0, 0, '50px']} alignItems="center" space="pll">
							<Title>{this.lang.decisionMakers}</Title>
							<div>
								<EditorHeaderButton
									title={this.lang.cancel}
									onClick={() => {
										reject();
									}}
									supertitle={undefined}
									className={undefined}
									noIcon
									next={false}
								/>
								<Tooltip
									title={
										!decisionMakers.titleCategories.length
											? this.lang.pickATitleCategoryToSave
											: this.lang.chooseStagesToSave
									}
									disabled={
										!this.haveNoStagesSelected() && decisionMakers.titleCategories.length !== 0
									}
								>
									<EditorHeaderButton
										title={this.lang.save}
										supertitle={this.lang.backToChecklist}
										disabled={this.haveNoStagesSelected() || !decisionMakers.titleCategories.length}
										onClick={() => {
											setDecisionMakers({ ...decisionMakers, active: true });
											this.trackContact(decisionMakers.titleCategories?.[0]?.titleId);
											reject();
										}}
										next
										className="DecisionMakers__EditorHeaderButton"
										noIcon={false}
									/>
								</Tooltip>
							</div>
						</Flex>
					</Card>
					<div className={classNames.elem('content').b()}>
						<Headline>{this.lang.decisionMakers}</Headline>
						<Text color="grey-11">{this.lang.descriptionDecisionMaker}</Text>

						{!hasNewFields ? (
							<StateFrame
								space="mtxl"
								state="warning"
								icon="exclamation-triangle"
								title={this.lang.activateNewFieldsHeader}
								subtitle={this.lang.activateNewFields}
							>
								<Flex gap="u1" direction="column">
									{InAppChat.isEnabledAndLoaded() ? (
										<PrimaryButton
											block
											size="sm"
											loading={saving}
											onClick={this.startChatWithSupport}
										>
											<Icon space="mrm" name="comments" />
											{this.lang.chatWithUpsales}
										</PrimaryButton>
									) : null}
									<Help sidebar articleId={1220}>
										<ThirdButton block>
											<Icon name="info-circle" space="mrs" />
											{this.lang.readMore}
										</ThirdButton>
									</Help>
								</Flex>
							</StateFrame>
						) : !isTitleCategoryFieldActive ? (
							<StateFrame
								space="mtxl"
								state="warning"
								icon="exclamation-triangle"
								title={this.lang.activateTitleCategoryHeader}
								subtitle={this.lang.activateTitleCategoryText}
							>
								<Flex gap="u1" direction="column">
									<PrimaryButton
										block
										size="sm"
										loading={saving}
										onClick={this.activateTitleCategory}
									>
										{this.lang.activateTitleCategoryButton}
									</PrimaryButton>
									<Help sidebar articleId={1220}>
										<ThirdButton block>
											<Icon name="info-circle" space="mrs" />
											{this.lang.readMore}
										</ThirdButton>
									</Help>
								</Flex>
							</StateFrame>
						) : null}

						<Flex space="mtxl" gap="u4" justifyContent="space-between">
							<div>
								<Flex justifyContent="space-between" alignItems="center" space="mbs" gap="u1">
									<Text bold>{this.lang.whichTitleCategory}</Text>
									<Help sidebar articleId={1220} />
								</Flex>
								<UpSelect
									disabled={!isTitleCategoryFieldActive}
									key={this.state.titleCategoriesToChooseFrom.length}
									className="DecisionMakers__up-select2"
									placeholder={this.lang.searchTitles}
									onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
										let newValue = [] as Array<titleCategoryObj>;
										if (e.target.value) {
											newValue = [{ titleId: parseInt(e.target.value) }];
										}
										this.setState({
											decisionMakers: {
												...decisionMakers,
												titleCategories: newValue
											}
										});
									}}
									data={this.state.titleCategoriesToChooseFrom.map(titleCategory => ({
										id: titleCategory.tagId,
										name: titleCategory.value
									}))}
									defaultValue={decisionMakers.titleCategories?.[0]?.titleId}
								/>
							</div>
							{isTitleCategoryFieldActive && hasNewFields ? (
								<Card
									color={isBillingAdmin ? 'super-light-green' : 'grey-1'}
									space="mbs pbl prl ptl pll"
									borderRadius
								>
									<Flex gap="u2" alignItems="center">
										<Title>{this.lang.addMoreDecisionMakers}</Title>
										<Help articleId={1155} sidebar />
										<Tooltip
											title={this.lang.billingAdminRequired}
											position="top"
											disabled={isBillingAdmin}
										>
											<PrimaryButton
												disabled={!isBillingAdmin}
												onClick={async () => {
													await save();
													Tools.$state.go('administration.billing', { initialTab: 'addons' });
												}}
											>
												{!isBillingAdmin ? <Icon name="ban" space="mrs" /> : null}
												{this.lang.viewAddOn}
											</PrimaryButton>
										</Tooltip>
									</Flex>
								</Card>
							) : null}
						</Flex>

						<Block space="mtxl">
							<Title size="lg" color={isTitleCategoryFieldActive ? 'black' : 'grey-8'}>
								{this.lang.atWhatPoint}
							</Title>
						</Block>
						<Flex className={classNames.elem('buttonBoxes').b()} space="mtl" gap="u4">
							<ButtonBox
								onClick={isTitleCategoryFieldActive ? this.toggleEntireSalesProcess : undefined}
								selected={decisionMakers.entireSalesProcess}
								title={this.lang.throughEntireSalesProcess}
								disabled={!isTitleCategoryFieldActive || !hasNewFields}
							/>
							<ButtonBox
								onClick={isTitleCategoryFieldActive ? this.toggleSpecificStage : undefined}
								selected={!decisionMakers.entireSalesProcess}
								title={this.lang.specificStage}
								disabled={!isTitleCategoryFieldActive || !hasNewFields}
							/>
						</Flex>
						<Fade visible={!decisionMakers.entireSalesProcess}>
							<Block space="mtl">
								<Stages selected={decisionMakers.stages} onToggleItem={this.flipSelected} />
								<SalesProcessAddonHint />
							</Block>
						</Fade>
					</div>
				</Flex>
			</Fade>
		);
	}
}

const Component = connect(mapStateToProps, mapDispatchToProps)(DecisionMakers);

export const DecisionMakersModal = setupComponentCompatibility(Component, {
	modalName: 'DecisionMakers FullscreenModal PortedDecisionMakers'
});

export const openDecisionMakersModal = asyncModalAdapter({
	upModalName: 'DecisionMakers',
	openModalName: 'DecisionMakersModal',
	featureFlag: 'REACT_DECISION_MAKERS_MODAL',
	rejectOnEmpty: false
});

export const detached = DecisionMakers;

export default Component;
