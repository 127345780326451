import { Flex, Tooltip } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import { useTranslation } from 'Components/Helpers/translate';
import React from 'react';

import './SignalsBar.scss';

type SignalsBarProps = {
	signals?: { positive: number; negative: number };
	periodText?: string;
	loading?: boolean;
};

const SignalsBar = ({ signals, periodText, loading }: SignalsBarProps) => {
	const classes = new BemClass('SignalsBar');
	const { t } = useTranslation();
	const total = (signals?.positive || 0) + (signals?.negative || 0);
	const distribution = [
		{ value: signals?.positive, color: 'success-4' },
		{ value: signals?.negative, color: 'red' }
	].filter(v => Number(v.value));

	const text = `${signals?.positive ? signals.positive + ' ' + t('default.positiveSignals') : ''} \n${
		signals?.negative ? signals.negative + ' ' + t('default.negativeSignals') : ''
	} \n${periodText ? periodText : ''}`;

	return (
		<Tooltip title={text} className={classes.b()}>
			<Flex space="u2">
				{loading ? (
					<div className={classes.elem('entryBar').mod('loading').b()}></div>
				) : (
					distribution.map((entry, index) => (
						<div
							className={classes.elem('entryBar').mod(entry.color).b()}
							key={index}
							style={{
								width: `${((entry.value ?? 0) / total) * 100}%`
							}}
						/>
					))
				)}
			</Flex>
		</Tooltip>
	);
};

export default SignalsBar;
