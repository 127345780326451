import {
	Flex,
	Input,
	Link,
	Modal,
	ModalContent,
	ModalControls,
	ModalHeader,
	NumberInput,
	Select,
	Text,
	Toggle
} from '@upsales/components';
import { ThirdButton } from '@upsales/components/Buttons';
import BemClass from '@upsales/components/Utils/bemClass';
import { SelectItem } from '@upsales/components/Utils/selectHelpers';
import { ACTIVITY_PRIORITY } from 'App/babel/enum/activity';
import LabeledInput from 'App/components/Inputs/LabeledInput';
import { CoreSelectItem } from 'App/components/Inputs/Selects/StaticSelect';
import { StaticUserSelect, useUserOptions } from 'App/components/Inputs/Selects/StaticUserSelect/StaticUserSelect';
import { ModalProps } from 'App/components/Modals/Modals';
import { ResourceIdSelect } from 'App/components/ResourceSelect';
import { useSelector, useSoftDeployAccess } from 'App/components/hooks';
import { useActivityTypes } from 'App/components/hooks/appHooks';
import { TagEntity, getDynamicUserProperties } from 'App/helpers/actionHelpers';
import { CustomFieldWithValue } from 'App/resources/Model/CustomField';
import ProjectResource from 'App/resources/Project';
import NotesWithSignature from 'Components/Inputs/NotesWithSignature';
import ModalTagList from 'Components/Modals/ModalTagList';
import { formatDateType, prepDates } from 'Helpers/multiModals';
import ComparisonTypes from 'Resources/ComparisonTypes';
import { mapCustomFields, mapProperties, parseCustomFields, parseProperties } from 'Services/ActionProperties';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PropertyArray, PropertyMap } from '../MultiActionModal';
import { parseDatePropToState } from '../MultiHelpers';
import { prepTriggerCategories } from '../MultiUpdateActivity/MultiUpdateActivity';
import './CreateActivityAction.scss';

type Props = {
	entity: string;
	tagEntity: string;
	removeAction?: (actionIndex: number) => void;
	actionIndex: number;
	isFlow?: boolean;
	isTrigger?: boolean;
	isAutomation?: boolean;
	properties?: PropertyArray;
} & ModalProps<PropertyArray>;

const CreateActivityActionModal: React.FC<Props> = ({ close, className, ...modalParams }) => {
	const { entity, tagEntity, isTrigger, isAutomation, actionIndex, removeAction } = modalParams;
	const { t } = useTranslation();

	const modalRef = useRef<Element | null>(null);

	useEffect(() => {
		if (!modalRef.current) {
			modalRef.current = document.querySelector('.Modals');
		}
	}, [modalRef.current]);

	const classes = new BemClass('CreateActivityAction', className);

	const isPriorityEnabled = useSoftDeployAccess('ACTIVITY_PRIORITIZATION');
	const { customerId, customFields: startingFields } = useSelector(({ App }) => ({
		customerId: App.customerId,
		customFields: App.customFields.activity
	}));
	const activityTypeOptions = useActivityTypes().map(({ id, name }) => ({ id, title: name }));
	const users = useUserOptions();
	const userTags = getDynamicUserProperties(tagEntity as TagEntity, isAutomation).map(p => ({
		id: p.value,
		title: p.title
	}));

	const [saving, setSaving] = useState(false);
	const [tagsVisible, setTagsVisible] = useState(false);

	const [properties, setProperties] = useState<PropertyMap>({
		ActivityType: activityTypeOptions[0].id
	});
	const [selectedUser, setSelectedUser] = useState<CoreSelectItem | null>(null);
	const [selectedActivityType, setSelectedActivityType] = useState<(typeof activityTypeOptions)[number] | null>(
		activityTypeOptions[0] ?? null
	);

	const [availableFields, setAvailableFields] = useState<CustomFieldWithValue[]>([]);
	const [customFields, setCustomFields] = useState<CustomFieldWithValue[]>([]);
	const [customDateTypes, setCustomDateTypes] = useState<{ [key: string]: any }>();

	const [triggerDateGroups, setTriggerDateGroups] = useState<SelectItem[]>([]);
	const [activityDate, setActivityDate] = useState<{
		selectItem: SelectItem | null;
		offset: number;
	}>({
		selectItem: {
			id: 'General.Today',
			title: 'Today'
		},
		offset: 0
	});

	const isEdit = !!modalParams.properties;
	const isValid =
		!!properties.Description && !!properties.User && !!properties.ActivityType && !!activityDate.selectItem?.id;

	const findSelectedUserOrTag = (value: any) => {
		const user = users.find(user => user.id === value);
		if (user) {
			return user;
		}

		const tag = userTags.find(tag => tag.id === value);
		if (tag) {
			return tag;
		}

		return null;
	};

	useEffect(() => {
		const { customDateTypes, dateTypes, dateTags } = prepDates(entity || tagEntity);
		const newFields = structuredClone(startingFields)
			.filter(field => field.editable)
			.map(field => {
				if (customDateTypes && field.datatype === 'Date') {
					(field as any).customDateSelect = true;
				}
				return field;
			});

		const dateSelectItems = prepTriggerCategories(dateTypes);

		setCustomDateTypes(formatDateType(customDateTypes));
		setTriggerDateGroups(dateSelectItems);

		if (modalParams.properties) {
			const parsedProperties = parseProperties(modalParams.properties);

			for (const [name, value] of Object.entries(parsedProperties)) {
				switch (name) {
					case 'Priority':
						parsedProperties[name] = parseInt(value as string);
						break;

					case 'User':
						setSelectedUser(findSelectedUserOrTag(value));
						break;

					case 'ActivityType':
						setSelectedActivityType(
							activityTypeOptions.find(type => type.id === parseInt(value as string)) ?? null
						);
						break;

					case 'Date':
						setActivityDate(parseDatePropToState(value as string, dateSelectItems));
						parsedProperties[name] = null;
						break;
				}
			}

			const [customFieldsRes, availableFields] = parseCustomFields(
				newFields,
				parsedProperties,
				'Activity',
				dateTags
			);

			setCustomFields(customFieldsRes);
			setAvailableFields(availableFields);
			setProperties(parsedProperties);
		} else {
			setAvailableFields(newFields as CustomFieldWithValue[]);
		}
	}, []);

	const save = async () => {
		setSaving(true);

		const propArray: PropertyArray = [];

		mapCustomFields(propArray, customFields, 'Activity');
		propArray.push(...mapProperties(properties));

		if (activityDate.selectItem?.id) {
			propArray.push({
				name: 'Date',
				value: '{{' + activityDate.selectItem?.id + ':' + (activityDate.offset || 0) + '}}'
			});
		}

		close(propArray);
	};

	const createCampaign = async () => {
		return Tools.$upModal
			.open('editCampaign', { customerId: customerId, noRedirect: true })
			.then(res => setProperties({ ...properties, Project: res.id }));
	};

	const handleCustomFieldChange = (value: {
		id: CustomFieldWithValue['id'];
		title: CustomFieldWithValue['name'];
	}) => {
		const selectedField = availableFields.find(field => field.id === value.id);
		if (selectedField) {
			setCustomFields(prev => [...prev, selectedField]);
		}

		setAvailableFields(prev => prev.filter(field => field.id !== value.id));
	};

	const removeCustomField = (field: CustomFieldWithValue) => {
		if (customDateTypes && field.datatype === 'Date') {
			(field as any).customDateSelect = true;
		}
		setCustomFields(prev => prev.filter(f => f.id !== field.id));
		setAvailableFields(prev => [...prev, field]);
	};

	const handleCustomFieldValueChange = (field: CustomFieldWithValue, value: any) => {
		setCustomFields(
			customFields.map(f => {
				if (f.id === field.id) {
					let newValue = value;
					if (field.datatype === 'Date' && value?.tag === 'RegularDate') {
						(field as any).customDateSelect = false;
						newValue = '';
					}

					return { ...f, value: newValue };
				}
				return f;
			})
		);
	};

	return (
		<Modal className={classes.b()} size="lg">
			<div className={classes.elem('content').b()}>
				<ModalHeader
					title={`${t('default.create')} ${t('default.activity').toLocaleLowerCase()}`}
					onClose={() => close()}
					icon="check"
				>
					{isTrigger ? (
						<button
							type="button"
							id="toggle-tags-btn"
							className="up-btn btn-lined no-shadow btn-bright-blue btn-sm"
							onClick={() => setTagsVisible(!tagsVisible)}
						>
							{t('tag.available')}
						</button>
					) : null}
				</ModalHeader>
				<ModalContent>
					{/* Description */}
					<div className="row">
						<div className={isPriorityEnabled ? 'col-md-6' : 'col-md-12'}>
							<LabeledInput
								title={
									(
										<>
											{t('default.description')} <b className="text-danger">*</b>
										</>
									) as any
								}
							>
								<Input
									value={properties.Description ?? ''}
									onChange={e => {
										setProperties(prev => ({ ...prev, Description: e.target.value }));
									}}
								/>
							</LabeledInput>
						</div>
						{isPriorityEnabled ? (
							<div className="col-md-6">
								<LabeledInput
									title={`${t('activity.high')} ${t('activity.priority').toLocaleLowerCase()}`}
								>
									<div>
										<Toggle
											checked={properties.Priority === ACTIVITY_PRIORITY.HIGH}
											onChange={() =>
												setProperties(prev => ({
													...prev,
													Priority: prev.Priority
														? ACTIVITY_PRIORITY.NONE
														: ACTIVITY_PRIORITY.HIGH
												}))
											}
										/>
									</div>
								</LabeledInput>
							</div>
						) : null}
					</div>

					{/* Activity type */}
					<div className="row">
						<div className="col-md-6">
							<LabeledInput title={t('default.activityType')}>
								<Select
									required
									options={activityTypeOptions}
									value={selectedActivityType ?? null}
									placeholder={`${t('default.select')} ${t('default.activityType').toLowerCase()}`}
									onChange={value => {
										setSelectedActivityType(value);
										setProperties({ ...properties, ActivityType: value?.id ?? null });
									}}
									anchor={modalRef.current}
									ellipsisTooltip
								/>
							</LabeledInput>
						</div>
						<div className="col-md-6">
							<LabeledInput
								title={
									(
										<>
											{t('default.user')} <b className="text-danger">*</b>
										</>
									) as any
								}
							>
								<StaticUserSelect
									extraOptions={userTags}
									value={selectedUser}
									onChange={value => {
										setSelectedUser(value);
										setProperties({ ...properties, User: value?.id ?? null });
									}}
									anchor={modalRef.current}
								/>
							</LabeledInput>
						</div>
					</div>

					<div className="row">
						{/* Date with offset */}
						{isTrigger ? (
							<>
								<div className="col-md-3">
									<LabeledInput
										title={
											(
												<>
													{t('default.startDate')} <b className="text-danger">*</b>
												</>
											) as any
										}
									>
										<Select
											required
											options={triggerDateGroups}
											optionHeaderType="disabled"
											value={activityDate.selectItem}
											onChange={item => {
												setActivityDate(prev => ({ ...prev, selectItem: item ?? null }));
											}}
											placeholder={`${t('default.select')} ${t('default.date').toLowerCase()}`}
											anchor={modalRef.current}
										/>
									</LabeledInput>
								</div>
								<div className="col-md-3">
									<LabeledInput
										title={
											(
												<>
													{t('automationAction.DateOffset')} <b className="text-danger">*</b>
												</>
											) as any
										}
									>
										<NumberInput
											value={activityDate.offset}
											min={-365}
											max={365}
											onChange={offset => {
												setActivityDate(prev => ({ ...prev, offset: offset ?? 0 }));
											}}
										/>
									</LabeledInput>
								</div>
							</>
						) : null}
						{/* Campaign */}
						<div className="col-md-6">
							<LabeledInput
								title={t('default.select') + ' ' + t('default.campaign')}
								topRight={
									<Link
										nostyle={true}
										className={classes.elem('create-campaign').b()}
										onClick={createCampaign}
									>
										{t('default.create')} {t('default.new').toLowerCase()}
									</Link>
								}
							>
								<ResourceIdSelect
									resource={ProjectResource}
									placeholder={t('default.select') + ' ' + t('default.campaign')}
									value={properties.Project ?? null}
									onChange={campaign => {
										setProperties(prev => ({
											...prev,
											Project: campaign
										}));
									}}
									modifyRb={rb => {
										rb.addFilter(ProjectResource.attr.active, ComparisonTypes.Equals, true);
									}}
									anchor={modalRef.current}
								/>
							</LabeledInput>
						</div>
					</div>

					{/* Notes */}
					<div className="row">
						<div className="col-md-12">
							<NotesWithSignature
								value={properties.Notes ?? ''}
								onChange={value => {
									setProperties({ ...properties, Notes: value });
								}}
							/>
						</div>
					</div>

					{customFields.length || availableFields.length ? (
						<legend>
							<span>{t('default.otherInfo')}</span>
						</legend>
					) : null}

					{customFields.length ? (
						<>
							<div className="row">
								{customFields.map((field, index) => (
									<div className="col-md-6" key={field.id}>
										<div className="form-group">
											<Flex justifyContent="space-between">
												<Text className={classes.elem('custom-title').b()} bold>
													{field.name}
													<Text
														size="lg"
														color="red"
														bold={true}
														style={{ display: field.obligatoryField ? 'inline' : 'none' }}
													>
														&bull;
													</Text>
												</Text>
												<ThirdButton
													className={classes.elem('remove-custom').b()}
													icon={'trash'}
													onClick={() => removeCustomField(field)}
													size="sm"
													color="blue"
												/>
											</Flex>
											{customDateTypes &&
											field.datatype === 'Date' &&
											(field as any).customDateSelect ? (
												<ReactTemplates.INPUTS.upSelect
													className={classes.elem('custom-field-input', 'form-control').b()}
													getId={customDateTypes.id}
													options={{ data: customDateTypes.data }}
													defaultValue={field.value}
													multiple={false}
													required={false}
													formatResult={customDateTypes.formatResult}
													formatSelection={customDateTypes.formatSelection}
													matcher={customDateTypes.matcher}
													onChange={value =>
														handleCustomFieldValueChange(field, value.target.added)
													}
												/>
											) : (
												<ReactTemplates.INPUTS.customFieldInput
													field={field}
													name={field.name}
													className={classes.elem('custom-field-input').b()}
													multiple={field.datatype === 'Users'}
													onChange={(value: string) =>
														handleCustomFieldValueChange(field, value)
													}
													usenewdate
													useNewTime
													useNumberInput
													useNewCheckbox
													anchor={modalRef.current}
												/>
											)}
										</div>
									</div>
								))}
							</div>
						</>
					) : null}

					{availableFields.length ? (
						<div className="row">
							<div className="col-md-6">
								<Select
									data-testid="Input_field"
									anchor={modalRef.current}
									options={availableFields.map(field => ({
										id: field.id,
										title: field.name
									}))}
									value={null}
									onChange={handleCustomFieldChange}
									disabled={!availableFields.length}
									className={classes.elem('custom-field-select').b()}
									placeholder={t('default.add') + ' ' + t('default.field').toLowerCase()}
								/>
							</div>
						</div>
					) : null}
				</ModalContent>
				<ModalControls>
					{isEdit && removeAction ? (
						<button
							type="button"
							className="up-btn btn-lined btn-grey btn-hover-red no-shadow pull-left ng-scope"
							onClick={() => {
								removeAction(actionIndex);
								close();
							}}
						>
							<b className="fa fa-trash-o"></b>
						</button>
					) : null}
					<button
						type="submit"
						className="btn up-btn btn-green no-shadow"
						disabled={saving || !isValid}
						onClick={save}
					>
						{t(saving ? 'default.saving' : 'default.save')} {t('admin.action').toLocaleLowerCase()}{' '}
						{saving ? <span className={`fa fa-refresh ${saving ? 'fa-spin' : ''}`}></span> : null}
					</button>
					<button className="btn up-btn btn-grey btn-link" onClick={() => close()}>
						{t('default.abort')}
					</button>
				</ModalControls>
			</div>
			{isTrigger ? (
				<div className={classes.elem('tags').mod({ translateTagsList: tagsVisible }).b()}>
					<ModalTagList modalParams={modalParams} onClose={() => setTagsVisible(false)} />
				</div>
			) : null}
		</Modal>
	);
};

export default CreateActivityActionModal;
