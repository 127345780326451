import {
	ModalHeader,
	ModalContent,
	ModalControls,
	Row,
	Column,
	Button,
	Text,
	Block,
	Label,
	Modal,
	Icon
} from '@upsales/components';
import React, { useEffect, useRef, useState } from 'react';
import BemClass from '@upsales/components/Utils/bemClass';
import { chunk, cloneDeep } from 'lodash';
import Prospecting from 'Resources/Prospecting';
import CustomField, { CustomFieldWithValue } from 'App/resources/Model/CustomField';

import './ConfirmParentProspecting.scss';
import { ModalProps } from 'App/components/Modals/Modals';
import { useTranslation } from 'Components/Helpers/translate';

interface Props extends ModalProps<object | null> {
	title?: string;
	icon?: React.ComponentProps<typeof Icon>['name'];
	prospectingId: number;
	name: string;
	customerId: number;
}

const Component = (props: Props) => {
	const classes = new BemClass('ConfirmParentProspecting');
	const { t } = useTranslation();
	const anchor = useRef<Element | null>(null);
	useEffect(() => {
		if (!anchor.current) {
			anchor.current = document.querySelector('.Modals');
		}
	});

	const customFields: CustomFieldWithValue[] = Tools.AppService.getCustomFields('account').reduce(
		(res: CustomFieldWithValue[], field: CustomField) => {
			if (field.obligatoryField && field.editable && field.alias !== 'ORG_NO') {
				res.push({ ...field, fieldId: field.id, value: null });
			}
			return res;
		},
		[]
	);

	const [loading, setLoading] = useState(false);
	const [fields, setFields] = useState(customFields);

	const lang = {
		title: t('prospecting.parentCompanyModal.title'),
		confirm: t('prospecting.parentCompanyModal.fetch', { company: props.name }),
		abort: t('default.abort'),
		text: t('prospecting.parentCompanyModal.text', { company: props.name }),
		customFields: t('default.youNeedToFillRequiredFields')
	};

	const customFieldValueChange = (field: CustomFieldWithValue, value: any) => {
		const f = cloneDeep(fields);
		const item = f.find(f => f.id === field.id);
		if (item) {
			item.value = value;
			setFields(f);
		}
	};

	const confirm = async () => {
		setLoading(true);

		const properties: any[] = [];
		Tools.ActionProperties.mapCustomFields(properties, fields, 'Client');
		const res = await Prospecting.save({
			prospectingId: props.prospectingId,
			customValues: properties
		});

		if (!res.error) {
			props.close(res.data);
		}
		setLoading(false);
	};

	const close = () => {
		props.close();
	};

	const valid = () => {
		return fields.every(f => f.value);
	};

	const customFieldRows = chunk(fields, 2).map(([fieldA, fieldB]) => {
		const key = `${fieldA.id}-${fieldB ? fieldB.id : 'field-select'}`;
		// Added the if-statement to include the properties for the custom fields
		// The old one is broken, and this make sure the new one work.
		// This will be removed when we remove Angular.
		if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_CONFIRM_PARENT_PROSPECTING')) {
			return (
				<Block space="mtl mbl" key={key}>
					<Row>
						<Column size={6} className={classes.elem('field-column').b()}>
							<Label required={!!fieldA.obligatoryField}>{fieldA.name}</Label>
							<ReactTemplates.INPUTS.customFieldInput
								field={fieldA}
								name={fieldA.name}
								onChange={(value: any) => customFieldValueChange(fieldA, value)}
								usenewdate
								useNewTime
								useNumberInput
								anchor={anchor.current}
							/>
						</Column>
						{fieldB ? (
							<Column size={6} className={classes.elem('field-column').b()}>
								<React.Fragment>
									<Label required={!!fieldB.obligatoryField}>{fieldB.name}</Label>
									<ReactTemplates.INPUTS.customFieldInput
										field={fieldB}
										name={fieldB.name}
										onChange={(value: any) => customFieldValueChange(fieldB, value)}
										usenewdate
										useNewTime
										useNumberInput
										anchor={anchor.current}
									/>
								</React.Fragment>
							</Column>
						) : null}
					</Row>
				</Block>
			);
		} else {
			return (
				<Block space="mtl mbl" key={key}>
					<Row>
						<Column size={6} className={classes.elem('field-column').b()}>
							<Label required={!!fieldA.obligatoryField}>{fieldA.name}</Label>
							<ReactTemplates.INPUTS.customFieldInput
								field={fieldA}
								name={fieldA.name}
								onChange={(value: any) => customFieldValueChange(fieldA, value)}
							/>
						</Column>
						{fieldB ? (
							<Column size={6} className={classes.elem('field-column').b()}>
								<React.Fragment>
									<Label required={!!fieldB.obligatoryField}>{fieldB.name}</Label>
									<ReactTemplates.INPUTS.customFieldInput
										field={fieldB}
										name={fieldB.name}
										onChange={(value: any) => customFieldValueChange(fieldB, value)}
									/>
								</React.Fragment>
							</Column>
						) : null}
					</Row>
				</Block>
			);
		}
	});

	return (
		<div>
			<ModalHeader title={lang.title} />
			<ModalContent>
				<Text>{lang.text}</Text>
				<br></br>
				{fields.length ? (
					<React.Fragment>
						<Text>{lang.customFields}</Text>
						{customFieldRows}
					</React.Fragment>
				) : null}
			</ModalContent>
			<ModalControls>
				<Row>
					<Column align="right">
						<Button
							onClick={confirm}
							loading={loading}
							shadow="none"
							disabled={!valid()}
							className={classes.elem('confirm-btn').b()}
						>
							{lang.confirm}
						</Button>
						<Button onClick={close} color="grey" className={classes.elem('close-btn').b()} type="link">
							{lang.abort}
						</Button>
					</Column>
				</Row>
			</ModalControls>
		</div>
	);
};
interface PropsAngular {
	$modalParams: Props;
	resolve: (account?: object) => void;
	reject: () => void;
}

const ConfirmParentProspecting = <TProps extends Props | PropsAngular>(props: TProps) => {
	if (props.hasOwnProperty('$modalParams')) {
		const classes = new BemClass('ConfirmParentProspecting');
		return (
			<div className={classes.b()}>
				{Component({
					...props.$modalParams,
					close: (account?: object | null) => {
						if (account) {
							props.resolve(account);
						} else {
							props.reject();
						}
					},
					className: '',
					modalId: 0
				})}
			</div>
		);
	} else {
		return <Modal className={props.className}>{Component(props)}</Modal>;
	}
};

export default ConfirmParentProspecting<Props>;
