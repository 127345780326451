import CardParent, { AgreementWithValueChange, ChildType } from '../CardParent';
import { getDocumentTemplates } from 'Store/selectors/AppSelectors';
import Agreement, { Child } from 'App/resources/Model/Agreement';
import BemClass from '@upsales/components/Utils/bemClass';
import { ClientIdName } from 'App/resources/Model/Client';
import { OldOrNewAgreement } from '../SubscriptionCards';
import OrderRow from 'App/resources/Model/OrderRow';
import Order from 'App/resources/Model/Order';
import moment from 'moment';
import React from 'react';

type Props = {
	agreementGroups: OldOrNewAgreement[];
	showInactive?: boolean;
	hasMRR?: boolean;
	willUpdateAgreement?: boolean;
	orderToAdd?: Order;
	client?: ClientIdName;
	onSave?: (agreementGroup?: OldOrNewAgreement) => void;
	showClientName?: boolean;
	showContactLink?: boolean;
	isGroupBySubAccount?: boolean;
};

const getARR = (orderRows: OrderRow[], agreementIntervalPeriod: number) => {
	const value = orderRows.reduce((acc, row) => acc + (row.quantity ?? 1) * (row.price ?? 0), 0);
	return (value * 12) / agreementIntervalPeriod;
};

const getFakeAgreement = (
	{ metadata: { agreementIntervalPeriod, agreementRenewalDate }, currencyRate, currency }: Agreement,
	child: Child
) => ({
	metadata: {
		agreementStartdate: new Date(child.startDate),
		agreementEnddate: new Date(child.endDate),
		agreementIntervalPeriod,
		agreementRenewalDate
	},
	currency,
	currencyRate,
	orderRow: child.orderRow
});

const CardParents = ({
	agreementGroups,
	showContactLink = true,
	showInactive = false,
	hasMRR = false,
	showClientName = false,
	willUpdateAgreement,
	orderToAdd,
	client,
	isGroupBySubAccount = false,
	onSave
}: Props) => {
	const classes = new BemClass('CardParents');
	const documentTemplates = getDocumentTemplates('agreement');

	const cards = agreementGroups.map(agreementGroup => {
		const { currentAgreement, agreements } = agreementGroup;
		const currentEndDate = currentAgreement?.metadata.agreementEnddate;
		const isInactive = !!currentEndDate && moment(currentEndDate).isBefore();
		if ((!showInactive && isInactive) || !currentAgreement) return null;

		let currentChild;
		if (currentAgreement.children?.length) {
			currentChild = [...currentAgreement.children]
				.reverse()
				.find(child => moment().isSameOrAfter(moment(child.startDate)));
		}

		const currentStartDate = currentChild?.startDate ?? currentAgreement.metadata.agreementStartdate;
		const firstChild = agreements?.[0].children?.[0];

		const passedAgreements: (AgreementWithValueChange | ChildType)[] = [];
		const upcomingAgreements: (AgreementWithValueChange | ChildType)[] = [];
		const upcomingParents: AgreementWithValueChange[] = [];

		let previousYearlyValue =
			(firstChild
				? getARR(firstChild.orderRow, agreements[0].metadata.agreementIntervalPeriod)
				: agreementGroup.currentAgreement.yearlyValue) ?? 0;
		let upcomingYearlyValue = agreementGroup.currentAgreement.yearlyValue ?? 0;
		if (currentChild) {
			upcomingYearlyValue = getARR(currentChild.orderRow, currentAgreement.metadata.agreementIntervalPeriod);
		}

		agreementGroup.agreements.forEach((agreement, groupIndex) => {
			if (moment(agreement.metadata.agreementStartdate).isAfter(currentStartDate, 'day')) {
				upcomingParents.push(agreement);
			}

			if (!agreement.children?.length) {
				if (moment(agreement.metadata.agreementStartdate).isAfter(currentStartDate)) {
					upcomingAgreements.push({
						...agreement,
						valueChange: (agreement.yearlyValue ?? 0) - upcomingYearlyValue
					});
					upcomingYearlyValue = agreement.yearlyValue ?? 0;
				} else if (moment(agreement.metadata.agreementStartdate).isBefore(currentStartDate)) {
					passedAgreements.push(agreement);
					previousYearlyValue = agreement.yearlyValue ?? 0;
				}
			}

			agreement.children?.forEach((child, i) => {
				if (!moment(child.startDate).isSame(currentStartDate)) {
					const isPassed = moment(child.startDate).isBefore(currentStartDate);
					const {
						metadata: { agreementIntervalPeriod }
					} = agreement;
					const fakeAgreement = getFakeAgreement(agreement, child);
					const arr = getARR(child.orderRow, agreementIntervalPeriod);

					(isPassed ? passedAgreements : upcomingAgreements).push({
						...child,
						...fakeAgreement,
						child: true as const,
						isPassed,
						childIndexNr: i,
						firstChild: i === 0,
						valueChange: isPassed
							? i === 0
								? previousYearlyValue
								: arr - previousYearlyValue
							: arr - upcomingYearlyValue,
						lastAgreement: agreementGroup.agreements.length - 1 === groupIndex,
						isFirstAgreement: isPassed && i === 0,
						parentEndDate: agreement.metadata.agreementEnddate
					});
					if (isPassed) {
						previousYearlyValue = arr;
					} else {
						upcomingYearlyValue = arr;
					}
				}
			});
		});

		return (
			<CardParent
				key={agreementGroup.id ?? agreementGroup.tempId}
				willUpdateAgreement={willUpdateAgreement}
				upcomingAgreements={upcomingAgreements}
				documentTemplates={documentTemplates}
				passedAgreements={passedAgreements.reverse()}
				showContactLink={showContactLink}
				upcomingParents={upcomingParents}
				showClientName={showClientName}
				agreementGroup={agreementGroup}
				isInactive={isInactive}
				orderToAdd={orderToAdd}
				client={client}
				hasMRR={hasMRR}
				onSave={onSave}
				isGroupBySubAccount={isGroupBySubAccount}
			/>
		);
	});

	return <div className={classes.b()}>{cards}</div>;
};

export default CardParents;
