import React from 'react';
import useInputController, { options } from '../hooks/useInputController';

import type { InputHTMLAttributes } from 'react';
import type { Options } from '../hooks/useInputController';
import type { ModifiedOnChange } from '../types';

type Props = Omit<InputHTMLAttributes<HTMLInputElement>, 'type' | 'value' | 'onChange'> & {
	type: 'number';
	value: number;
	onChange: ModifiedOnChange<HTMLInputElement, number>;
	controllerOptions?: Partial<Options<number, number>>;
};

const NumberInput = React.forwardRef<HTMLInputElement, Props>(({ controllerOptions, ...props }, ref) => {
	const instanceOptions = controllerOptions ? { ...options.number, ...controllerOptions } : options.text;
	const controlledProps = useInputController<HTMLInputElement, number, number>(props, instanceOptions);

	return <input {...controlledProps} ref={ref} />;
});

export default NumberInput;
