import moment from 'moment-timezone';

export const getDateString = (date: string | Date | moment.Moment, format = 'L') => {
	const dateMoment = moment(date);
	const diff = dateMoment.clone().startOf('day').diff(moment().startOf('day'), 'days');
	if (diff > 1 || diff < -1) {
		return dateMoment.format(format);
	} else {
		return dateMoment.calendar();
	}
};

export const getDateTimeString = (date: string | Date) => {
	const dateMoment = moment(date);
	return `${getDateString(dateMoment)} ${dateMoment.format('LT')}`;
};

export const dateCalendar = (
	date: string | Date,
	showTime: boolean = false,
	format?: string,
	useFrom?: boolean,
	notZeroTime?: boolean
) => {
	const momentDate = moment(date);
	if (momentDate.isValid()) {
		const time = momentDate.format('HH:mm');
		const shouldShowTime = showTime && !(notZeroTime && time === '00:00');

		const d = momentDate.calendar();
		// the d is '0' if date is not today, tomorrow or yesterady (configured in momentLanguageConfig in app.js)
		if (d !== '0' && Math.abs(moment().startOf('day').diff(momentDate.clone().startOf('day'), 'days')) < 2) {
			if (shouldShowTime) {
				return `${d} ${momentDate.format('LT')}`;
			}
			return d;
		}
		if (!format) {
			format = shouldShowTime ? 'L LT' : 'L';
		}

		if (useFrom) {
			return momentDate.fromNow();
		}
		return momentDate.format(format);
	} else {
		return date;
	}
};

export type Timezone = { id: string; title: string; utcOffset: number; description: string };

export const getAvailableTimezones = () => {
	const validTimeZones = moment.tz.names().filter(tz => !tz.startsWith('Etc') && tz !== 'Greenwich');

	const timezones: Timezone[] = [];

	validTimeZones.forEach((name: string) => {
		const tz = moment.tz.zone(name);
		if (tz) {
			const utcOffset = tz.offsets[tz.offsets.length - 1] / -60;
			const offsetSign = utcOffset > 0 ? '+' : '';
			timezones.push({
				id: name,
				title: name,
				utcOffset: tz.offsets[tz.offsets.length - 1] / -60,
				description: name + ' (UTC ' + offsetSign + utcOffset + ')'
			});
		}
	});

	return timezones;
};
