import React, { useEffect, useState } from 'react';
import BemClass from '@upsales/components/Utils/bemClass';
import { Title, Row, Tooltip, Icon } from '@upsales/components';
import T from 'Components/Helpers/translate';
import Activity from 'App/resources/Model/Activity';
import OpportunityTodoRow from 'Components/OpportunityTodos/Rows/OpportunityTodoRow';
import OpportunityEditTodoRow from 'Components/OpportunityTodos/Rows/OpportunityEditTodoRow';
import { SlideFade } from 'App/components/animations';
import ActivityResource from 'Resources/Activity';
import { makeCancelable } from 'App/babel/helpers/promise';
import { removeItem } from 'Store/helpers/array';
import logError from 'App/babel/helpers/logError';
import BorderBox from 'App/components/BorderBox';
import { PrimaryButton, ThirdButton } from '@upsales/components/Buttons';

import './QuickCreateTodoCard.scss';

interface Props {
	fetchTodos: () => ReturnType<ReturnType<typeof Tools.Activity.customer>['find']>;
	onSave: (activity: Activity) => ReturnType<typeof ActivityResource.save>;
	hideRelation?: boolean;
	showTimePicker?: boolean;
	canCreate?: boolean;
}
// Component for quick createing todos and inline editing related todos
const QuickCreateTodoCard = ({
	fetchTodos,
	onSave,
	hideRelation = false,
	showTimePicker = true,
	canCreate = true,
	...props
}: Props) => {
	const [todos, setTodos] = useState<Activity[]>([]);
	const [createData, setCreateData] = useState<Activity>();
	const promises = React.useRef<ReturnType<typeof makeCancelable>[]>([]);

	const fetch = async () => {
		const { data: res } = (await fetchTodos()) || [];
		const todoType = Tools.AppService.getTodoTypes().TODO.id;
		setTodos(res.filter(activity => activity.activityType && activity.activityType.id === todoType));
	};
	const updateTodo = (data: Activity) => {
		const newTodos = todos.map(todo => {
			if (todo.id === data.id) {
				todo = { ...todo, ...data };
			}
			return todo;
		});
		setTodos(newTodos);
	};

	const toggleTodoDone = (todo: Activity, done: boolean) => {
		const newTodo = { ...todo, closeDate: done ? new Date() : null };
		updateTodo(newTodo as Activity);
		promises.current.push(makeCancelable(ActivityResource.save(newTodo)));
	};

	const deleteTodo = (id: number) => {
		const p = makeCancelable(ActivityResource.delete(id));
		promises.current.push(p);
		p.promise
			.then(() => {
				const index = todos.findIndex(t => t.id === id);
				setTodos(removeItem(todos, index));
			})
			.catch(err => logError(err, 'Failed to delete todo'));
	};
	const save = () => {
		const p = makeCancelable(onSave(createData as Activity));
		promises.current.push(p);
		p.promise
			.then(({ data }) => {
				if (createData?.id) {
					updateTodo(data);
				} else {
					fetch();
				}
				setCreateData(undefined);
			})
			.catch(err => logError(err, 'Failed to save todo'));
	};

	// cancel all cancelable promises
	useEffect(() => {
		promises.current = promises.current || [];
		// fetches todos and filters out non to-do type
		promises.current.push(makeCancelable(fetch()));

		return () => {
			promises.current.forEach(promise => promise.cancel());
			promises.current = [];
		};
	}, []);

	useEffect(() => {
		const l = Tools.$rootScope.$on('activity.updated', (event, data) => {
			const todoIds = todos.map(todo => todo.id);
			if (todoIds.includes(data.id)) {
				updateTodo(data);
			}
		});
		return () => l();
	}, [todos]);
	const classes = new BemClass('QuickCreateTodoCard');
	const renderEdit = () => {
		return (
			<form
				onSubmit={e => {
					e?.preventDefault();
					e?.stopPropagation();
					save();
				}}
				key="edit"
			>
				<Row className={classes.mod({ ['create-row']: !createData?.id }).b()}>
					<OpportunityEditTodoRow
						onChange={todo => {
							setCreateData({ ...createData, ...todo });
						}}
						todo={createData || ({ description: '' } as Activity)}
						inputIcon={createData?.id ? '' : 'pencil'}
						autofocus={!!createData?.id}
						noTooltip={!!createData?.id}
						showTimePicker={showTimePicker}
					/>
					<SlideFade visible={!!createData?.description} direction="right">
						<div>
							<Tooltip title={T('default.save')} position="top" distance={24}>
								<PrimaryButton
									id="submit-btn"
									size="sm"
									gradient={!!createData?.description}
									disabled={!createData?.description}
									submit={true}
								>
									<Icon name="check"></Icon>
								</PrimaryButton>
							</Tooltip>
							<Tooltip title={T('default.cancel')} position="top" distance={24}>
								<ThirdButton size="sm" onClick={() => setCreateData(undefined)}>
									<Icon name="times"></Icon>
								</ThirdButton>
							</Tooltip>
						</div>
					</SlideFade>
				</Row>
			</form>
		);
	};
	return (
		<BorderBox boxShadow space="ptl" className={classes.b()}>
			<Title color="black">{T('default.todos')}</Title>
			<div className={classes.elem('related-todos').b()}>
				{todos.map(todo => {
					if (createData?.id === todo.id) {
						return renderEdit();
					} else {
						return (
							<OpportunityTodoRow
								key={todo.id}
								todo={{ ...todo, type: 'todo', hot: false, weblinkUrl: null, location: null }}
								onEdit={() => setCreateData(todo)}
								onDelete={() => deleteTodo(todo.id)}
								onCheckToggle={(id, done) => toggleTodoDone(todo, done)}
								hideActionBtn
								hideRelation={hideRelation}
							/>
						);
					}
				})}
			</div>
			<SlideFade visible={!createData?.id && canCreate} height>
				<div>{renderEdit()}</div>
			</SlideFade>
		</BorderBox>
	);
};

export default QuickCreateTodoCard;
