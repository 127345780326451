import React, { ComponentProps } from 'react';
import { SplitButtonWithActions } from '@upsales/components';
import IconName from '@upsales/components/Icon/IconName';
import { openDrawer } from 'Services/Drawer';
import { useTranslation } from 'Components/Helpers/translate';
import { getSoftDeployAccessFromState } from '../hooks/featureHelper';
import { AccountSelf } from 'App/resources/AllIWant';
import { useSelector } from '../hooks';
import { openEditAppointment } from 'Components/Modals/Appointment/EditAppointment';

type SplitButtonWithActionsProps = ComponentProps<typeof SplitButtonWithActions>;

type Props = Omit<SplitButtonWithActionsProps, 'actions'> & {
	mainAction?: 'appointments' | 'todos' | 'phonecalls';
	createArgs?: {
		client?: { id: number; name: string };
		contact?: { id: number; name: string };
		opportunity?: { id: number; description: string; orderValue: number };
		projectPlan?: { id: number; name: string };
	};
	availableActions?: (keyof typeof createActions)[];
};

type Action = {
	id: string;
	visible: (accountSelf: AccountSelf) => boolean;
	onClick: (createArgs: Props['createArgs']) => void;
	title: string;
	icon?: IconName;
};

const createActions: Record<string, Action> = {
	todos: {
		id: 'todos',
		visible: (accountSelf: AccountSelf) => {
			return getSoftDeployAccessFromState(accountSelf, 'TODO_LIST');
		},
		icon: 'activity',
		title: 'todo.createTodo',
		onClick: (createArgs: Props['createArgs']) => {
			openDrawer('CreateTodo', {
				client: createArgs?.client,
				contact: createArgs?.contact,
				opportunity: createArgs?.opportunity,
				projectPlan: createArgs?.projectPlan
			});
		}
	},
	phonecalls: {
		id: 'phonecalls',
		visible: (accountSelf: AccountSelf) => {
			return getSoftDeployAccessFromState(accountSelf, 'TODO_LIST');
		},
		icon: 'phone',
		title: 'todo.planACall',
		onClick: (createArgs: Props['createArgs']) => {
			openDrawer('CreateCall', {
				client: createArgs?.client,
				contact: createArgs?.contact,
				opportunity: createArgs?.opportunity,
				projectPlan: createArgs?.projectPlan
			});
		}
	},
	appointments: {
		id: 'appointments',
		visible: () => {
			return true;
		},
		icon: 'calendar',
		title: 'todo.bookAppointment',
		onClick: (createArgs: Props['createArgs']) => {
			openEditAppointment({
				appointment: {
					client: createArgs?.client,
					contacts: createArgs?.contact ? [createArgs?.contact] : [],
					opportunity: createArgs?.opportunity,
					projectPlan: createArgs?.projectPlan
				}
			});
		}
	},
	activities: {
		id: 'activities',
		icon: 'activity',
		visible: (accountSelf: AccountSelf) => {
			return !getSoftDeployAccessFromState(accountSelf, 'REMOVE_ACTIVITIES');
		},
		title: 'create_activity',
		onClick: (createArgs: Props['createArgs']) => {
			Tools.$upModal.open('editActivity', {
				activity: {
					client: createArgs?.client,
					contacts: createArgs?.contact
				}
			});
		}
	}
};

const CreateTaskButton = ({ size, mainAction = 'phonecalls', createArgs, availableActions, ...passProps }: Props) => {
	const accountSelf = useSelector(({ App }) => App.accountSelf);
	const { t } = useTranslation();

	const actions = Object.values(createActions).filter(
		i => availableActions?.includes(i.id as keyof typeof createActions) ?? true
	);

	return (
		<>
			<SplitButtonWithActions
				{...passProps}
				icon="plus"
				size={size}
				mainAction={mainAction}
				dropdownColor="green"
				actions={actions
					.filter(i => i.visible?.(accountSelf!) ?? true)
					.map(i => ({ ...i, title: t(i.title), onClick: () => i.onClick(createArgs) }))}
			/>
		</>
	);
};

export default CreateTaskButton;
