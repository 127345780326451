import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Tooltip, Icon } from '@upsales/components';
import Dropzone from 'react-dropzone';
import moment from 'moment';

import DocumentTemplates from './AppointmentController/documentTemplatesDropdown';
import InlineAction from 'Components/Dialogs/InlineAction/InlineAction';
import { APPOINTMENT_ACTIONS } from 'App/babel/enum/activityOutcome';
import { useSoftDeployAccess } from 'App/components/hooks';

const isInFutureAndHasParticipants = appointment => {
	const { date, contacts, users, emailAttendees } = appointment;
	if (date && moment(date).isAfter()) {
		if (contacts && contacts.length && contacts.find(c => !!c.email)) {
			return true;
		}
		if (emailAttendees && emailAttendees.length && emailAttendees.find(attendee => !!attendee.email)) {
			return true;
		}
		const selfId = Tools.AppService.getSelf().id;
		if (users && users.find(user => user.id !== selfId && !!user.email)) {
			return true;
		}
	}
	return false;
};

const isHost = appointment =>
	!appointment.isExternalHost && appointment.regBy && appointment.regBy.id === Tools.AppService.getSelf().id;

const canInvite = appointment => {
	if (!Tools.AppService.getCalendarIntegrations().length) {
		return false;
	}
	return (
		(appointment.id && isHost(appointment) && isInFutureAndHasParticipants(appointment)) ||
		(!appointment.id && isInFutureAndHasParticipants(appointment))
	);
};

const isEqualStringField = (str1, str2) => {
	if (typeof str1 !== 'string') {
		str1 = '';
	}
	if (typeof str2 !== 'string') {
		str2 = '';
	}
	return str1 === str2;
};

// Logic is based on code in app-calendar-google / app-calendar-office "generateEventPatchObject"
// if we change code here or there we need to change it in both places
const willSendUpdateToParticipants = (existingAppointment, appointment) => {
	if (!existingAppointment.id) {
		return false;
	}
	if (!isEqualStringField(existingAppointment.agenda, appointment.agenda)) {
		return true;
	}
	if (!isEqualStringField(existingAppointment.description, appointment.description)) {
		return true;
	}
	if (!isEqualStringField(existingAppointment.location, appointment.location)) {
		return true;
	}
	if (
		!moment(existingAppointment.date).isSame(appointment.date) ||
		!moment(existingAppointment.endDate).isSame(appointment.endDate)
	) {
		return true;
	}
	if (
		appointment.contacts?.some(
			contact =>
				contact.email &&
				!(existingAppointment?.contacts ?? []).find(existingContact => existingContact.id === contact.id)
		)
	) {
		return true;
	}
	if (
		appointment.emailAttendees?.some(
			attendee =>
				attendee.email &&
				!(existingAppointment?.emailAttendees ?? []).find(existing => existing.email === attendee.email)
		)
	) {
		return true;
	}
	if (
		appointment.users?.some(
			user => user.email && !(existingAppointment?.users ?? []).find(userExisting => userExisting.id === user.id)
		)
	) {
		return true;
	}
	// Everything below is for checking conference data, we don't have a conferenceData object here
	// like in generateEventPatchObject to make the comparison but this should cover it
	if (!isEqualStringField(existingAppointment.bookedRooms, appointment.bookedRooms)) {
		return true;
	}
	if (!!existingAppointment.includeWeblink !== !!appointment.includeWeblink) {
		return true;
	}
	if (!isEqualStringField(existingAppointment.weblinkUrl, appointment.weblinkUrl)) {
		return true;
	}
	return false;
};

const getSaveButtonText = (
	appointment,
	actions,
	allContactsInvited,
	allEmailAttendeesInvited,
	existingAppointment,
	itWasAlreadyCancelled
) => {
	if (!Tools.AppService.getCalendarIntegrations().length || itWasAlreadyCancelled) {
		return 'default.save';
	}

	if (canInvite(appointment)) {
		return allContactsInvited && allEmailAttendeesInvited
			? willSendUpdateToParticipants(existingAppointment, appointment)
				? 'appointment.saveAndSendUpdate'
				: 'default.save'
			: 'appointment.saveAndInviteParticipants';
	}

	if (actions?.isRescheduling?.()) {
		return 'appointment.saveAndReschedule';
	}

	return 'default.save';
};

const itHasAttendees = appointment =>
	appointment.contacts?.length ||
	appointment.client ||
	appointment.emailAttendees?.length ||
	appointment.users?.length > 1;

const canBeCancelled = appointment =>
	appointment.id &&
	appointment.userEditable &&
	isHost(appointment) &&
	appointment.outcomeType !== APPOINTMENT_ACTIONS.CANCELLED &&
	moment().isBefore(moment(appointment.date)) &&
	itHasAttendees(appointment);

const AppointmentModalFooter = props => {
	const [isCancelling, setIsCancelling] = useState(false);
	const editable = props.appointment.userEditable;
	const removable = props.appointment.userRemovable;
	const appointment = props.appointment;
	const invitedContacts = props.invitedContacts;
	const invitedEmailAttendees = props.invitedEmailAttendees;
	const hasCancelOptionEnabled = useSoftDeployAccess('CANCEL_APPOINTMENT_OPTION');
	const didInitializeCancelled = useRef(props.appointment.outcomeType === APPOINTMENT_ACTIONS.CANCELLED).current;

	const hasActivityOutcomeAccess = Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.ACTIVITY_OUTCOMES);

	const allContactsInvited = !appointment.contacts?.length
		? true
		: appointment.contacts.every(contact => !contact.email || invitedContacts[contact.id]);
	const allEmailAttendeesInvited = !appointment.emailAttendees?.length
		? true
		: appointment.emailAttendees.every(attendee => !attendee.email || invitedEmailAttendees[attendee.email]);

	const showCancelButton = hasCancelOptionEnabled && props.cancel && canBeCancelled(appointment) && !isCancelling;

	let saveButton;
	if (props.created && !props.saving) {
		const hasCalendarIntegration = !!Tools.AppService.getCalendarIntegrations().length;
		saveButton = (
			<Button key={'appointment-saved'} shadow="none" color="super-light-green">
				<Icon name="check" space="mrs" />
				{Tools.$translate(
					hasCalendarIntegration ? 'appointment.calendarInviteSent' : 'appointment.saveButton.saved'
				)}
			</Button>
		);
	} else {
		saveButton = (
			<>
				{props.savingType !== 'noInvite' ? (
					<Button
						className={hasActivityOutcomeAccess ? 'negative-indent-10' : ''}
						key="save-button-appointment"
						onClick={() => {
							if (canInvite(appointment)) {
								props.setSavingType('invite');
							}
							props.saveAppointment(canInvite(appointment));
						}}
						shadow="none"
						loading={props.saving}
						disabled={!props.appointment.userEditable}
					>
						{Tools.$translate(
							getSaveButtonText(
								appointment,
								props.actions,
								allContactsInvited,
								allEmailAttendeesInvited,
								props.existingAppointment,
								didInitializeCancelled
							)
						)}
					</Button>
				) : null}
				{canInvite(appointment) &&
				!didInitializeCancelled &&
				props.savingType !== 'invite' &&
				!(props.saving && props.savingType === '') &&
				(!allContactsInvited || !allEmailAttendeesInvited) ? (
					<>
						{'  '}
						<Button
							className={hasActivityOutcomeAccess ? 'negative-indent-10' : ''}
							key="save-button-appointment-no-invite"
							onClick={() => {
								props.setSavingType('noInvite');
								props.saveAppointment(false);
							}}
							shadow="none"
							loading={props.saving}
							disabled={!props.appointment.userEditable}
						>
							{Tools.$translate('appointment.saveNoInvite')}
						</Button>
					</>
				) : null}
			</>
		);
	}

	if (!props.appointment.userEditable) {
		saveButton = (
			<Tooltip position="left" title={Tools.$translate('noEditRights.appointment')}>
				{saveButton}
			</Tooltip>
		);
	}

	const cancelAppointment = () => {
		setIsCancelling(true);
	};

	return (
		<div className="up-modal-controls">
			{editable && (
				<React.Fragment>
					{appointment.id ? (
						<Tooltip
							className="appointment-modal-footer-delete-tooltip"
							title={Tools.$translate(removable ? 'default.delete' : 'noDeleteRights.activity')}
							position="top"
						>
							<Button
								key="delete-button-appointment"
								style={{ float: 'left', margin: '0 10px 0 0' }}
								onClick={props.remove}
								hoverColor="red"
								shadow="none"
								type="lined"
								disabled={props.saving || !removable}
							>
								<Icon name="trash" />
							</Button>
						</Tooltip>
					) : null}
					{showCancelButton ? (
						<Button
							key="cancel-appointment-button"
							color="red"
							shadow="none"
							disabled={props.saving}
							onClick={cancelAppointment}
							className="cancel-appointment-button"
							style={{ marginLeft: 0 }}
						>
							<Icon name="calendar-times-o" /> {Tools.$translate('default.cancelAppointment')}
						</Button>
					) : null}
					{isCancelling ? (
						<InlineAction
							confirmTitleText={Tools.$translate('default.areYouSureCancelAppointment')}
							saveChangesText={Tools.$translate('default.yesCancel')}
							discardChangesText={Tools.$translate('default.noKeep')}
							onConfirm={() => {
								props.cancel();
								props.saveAppointment(false);
							}}
							toggleInlineAction={() => setIsCancelling(false)}
							onReject={() => setIsCancelling(false)}
							noRemove
						/>
					) : null}

					<Dropzone noDrag noKeyboard onDrop={props.onDrop}>
						{({ getRootProps, getInputProps }) => {
							return (
								<div {...getRootProps()}>
									<input {...getInputProps()} />
									<Button
										key="upload-button-appointment"
										style={{ float: 'left', margin: '0 10px 0 0' }}
										color="super-light-green"
										shadow="none"
										disabled={props.saving}
									>
										<Icon name="cloud-upload" space="mrs" />
										{Tools.$translate('default.uploadFile')}
									</Button>
								</div>
							);
						}}
					</Dropzone>

					{props.appointment.id && props.hasDocumentTemplates && props.isAvailable.document && editable && (
						<DocumentTemplates
							hasDocumentTemplates={props.hasDocumentTemplates}
							documentTemplates={props.documentTemplates}
							actions={props.actions}
							disabled={props.saving}
						/>
					)}

					<div style={{ float: 'left', lineHeight: '34px' }}>
						<ReactTemplates.elevio articleId={1138} sidebar>
							<Icon name="info-circle" />
						</ReactTemplates.elevio>
					</div>

					{!hasActivityOutcomeAccess ? saveButton : null}
				</React.Fragment>
			)}

			{hasActivityOutcomeAccess ? saveButton : null}

			{props.showInlineAction === 'bottom' ? (
				<InlineAction
					toggleInlineAction={props.toggleInlineAction}
					onReject={props.discard}
					onConfirm={() => props.saveAppointment(false)}
				/>
			) : null}

			<Button
				key="cancel-button-appointment"
				onClick={() => (props.dirty ? props.toggleInlineAction('bottom') : props.close())}
				shadow="none"
				type="link"
				color="grey"
				disabled={props.saving}
			>
				{Tools.$translate('cancel')}
			</Button>
		</div>
	);
};

AppointmentModalFooter.propTypes = {
	appointment: PropTypes.object.isRequired,
	remove: PropTypes.func.isRequired,
	cancel: PropTypes.func,
	hasDocumentTemplates: PropTypes.bool,
	isAvailable: PropTypes.object,
	documentTemplates: PropTypes.array,
	actions: PropTypes.object.isRequired,
	close: PropTypes.func.isRequired,
	savingType: PropTypes.string,
	setSavingType: PropTypes.func,
	saveAppointment: PropTypes.func.isRequired,
	onDrop: PropTypes.func.isRequired,
	saving: PropTypes.bool,
	toggleInlineAction: PropTypes.func,
	dirty: PropTypes.bool,
	discard: PropTypes.func,
	created: PropTypes.bool,
	showInlineAction: PropTypes.string,
	invitedContacts: PropTypes.object,
	invitedEmailAttendees: PropTypes.object,
	existingAppointment: PropTypes.object
};

export default AppointmentModalFooter;
