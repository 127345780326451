import Resource from 'Resources/Resource';
import ScriptType from './Model/Script';

class Script extends Resource {
	eventName = 'script';

	constructor() {
		super('uiScript');
	}

	find(...args: any): Promise<{ data: ScriptType[] }> {
		return super.find(...args);
	}
}

const resource = new Script();

export default resource;
