import ResourceTyped from './ResourceTyped';
import type CustomerPortfolioPotential from 'App/resources/Model/CustomerPortfolioPotential';

class CustomerPortfolioPotentialResource extends ResourceTyped<CustomerPortfolioPotential> {
	constructor() {
		super('customerPortfolioPotential');
	}
}

const resource = new CustomerPortfolioPotentialResource();

export default resource;
