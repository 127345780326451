import {
	Select,
	Label,
	Text,
	Icon,
	ButtonSelect,
	Block,
	ClickableItem,
	Tooltip,
	Row,
	Link,
	Input,
	Loader,
	Flex,
	EllipsisTooltip,
	DropDownMenu
} from '@upsales/components';
import SuggestedClientContact, { SuggestedContent } from './SuggestedClientContact';
import { useFeatureAvailable, useSoftDeployAccess } from 'App/components/hooks';
import RequestBuilder, { comparisonTypes } from 'Resources/RequestBuilder';
import { PrimaryButton, ThirdButton } from '@upsales/components/Buttons';
import { FormObserverOnFieldChange } from 'App/components/FormObserver';
import { TicketForm, getMainRecipient } from '../../Context/Helpers';
import { MultiMatchClientContact } from 'App/resources/Model/Ticket';
import { BasicUserWithPermissions } from 'App/resources/Model/User';
import { CancelablePromise, makeCancelable } from 'Helpers/promise';
import TelephoneLink from 'App/components/columns/TelephoneLink';
import { useSupportUsers } from 'App/components/hooks/appHooks';
import { getJourneyStep } from 'Components/Helpers/journeyStep';
import openModal, { shouldOpenModal } from 'App/services/Modal';
import { useTranslation } from 'Components/Helpers/translate';
import { useEditTicketContext } from '../../Context/Context';
import { FormComponent } from 'App/components/FormComponent';
import React, { useEffect, useRef, useState } from 'react';
import ValidationService from 'Services/ValidationService';
import BemClass from '@upsales/components/Utils/bemClass';
import { dateCalendar } from 'App/helpers/DateHelpers';
import CustomFields from 'App/components/CustomFields';
import type Ticket from 'App/resources/Model/Ticket';
import TicketResource from 'App/resources/Ticket';
import logError from 'App/babel/helpers/logError';
import Contact from 'App/resources/Model/Contact';
import PrioSelect from '../../Common/PrioSelect';
import TypeSelect from '../../Common/TypeSelect';
import Client from 'App/resources/Model/Client';
import CcSelect from '../../Common/CcSelect';
import ChangeContact from './ChangeContact';
import { useSelector } from 'react-redux';
import { RootState } from 'Store/index';
import Avatar from 'Components/Avatar';
import RelateTo from '../RelateTo';
import './TicketInfo.scss';

const LIMIT = 10;

const TicketInfo = ({
	onFormChange,
	formInputProps,
	values,
	errors,
	tooltipAnchorRef,
	isMerged
}: {
	onFormChange: FormObserverOnFieldChange;
	formInputProps: any;
	values: TicketForm;
	errors: any;
	tooltipAnchorRef: React.RefObject<HTMLDivElement>;
	isMerged: boolean;
}) => {
	const {
		state: { ticket, customFields, isMatchPopupDismissed },
		dismissMatchPopup
	} = useEditTicketContext();

	const { metadata } = useSelector((state: RootState) => state.App);
	const hasPriority = metadata?.params?.TicketPriority;
	const hasCustomerSupport = useFeatureAvailable('CUSTOMER_SUPPORT');
	const hasTicketCC = useSoftDeployAccess('TICKET_CC');
	const hasTicketRelations = useSoftDeployAccess('TICKET_RELATIONS');
	const hasTicketRemoveUser = useSoftDeployAccess('TICKET_REMOVE_USER');
	const hasImprovedMatching = useSoftDeployAccess('TICKET_IMPROVED_MATCHING');

	const { t } = useTranslation();
	const classes = new BemClass('TicketInfo');
	const anchor = document.getElementsByClassName('EditTicket')[0];
	const openTicketsPromise = useRef<null | CancelablePromise<Awaited<ReturnType<typeof TicketResource.find>>>>(null);
	const closedTicketsPromise = useRef<null | CancelablePromise<Awaited<ReturnType<typeof TicketResource.find>>>>(
		null
	);
	const typeInputRef = useRef<HTMLInputElement>(null);

	const mainRecipient = getMainRecipient(ticket.involved);
	const { contact, email } = mainRecipient ?? {};
	const { user, client } = ticket ?? {};

	const [openTickets, setOpenTickets] = useState<Ticket[]>([]);
	const [closedTickets, setClosedTickets] = useState<Ticket[]>([]);

	const [hasMoreOpen, setHasMoreOpen] = useState(false);
	const [totalOpen, setTotalOpen] = useState(0);
	const [openOffset, setOpenOffset] = useState(0);
	const [loadingMoreOpen, setLoadingMoreOpen] = useState(false);

	const [hasMoreClosed, setHasMoreClosed] = useState(false);
	const [totalClosed, setTotalClosed] = useState(0);
	const [closedOffset, setClosedOffset] = useState(0);
	const [loadingMoreClosed, setLoadingMoreClosed] = useState(false);

	const [ticketType, setTicketType] = useState<'open' | 'closed'>('open');
	const [validEmail, setValidEmail] = useState(false);
	const [newEmail, setNewEmail] = useState('');

	const isMultiMatch = ticket?.isMultiMatch;
	const disableEditing = ticket?.status.closed;
	const getRb = (closed: boolean, offset?: number) => {
		const rb = new RequestBuilder();

		rb.addFilter({ field: 'status.closed' }, comparisonTypes.Equals, closed);
		rb.addFilter({ field: 'client.id' }, comparisonTypes.Equals, client.id);
		rb.addFilter({ field: 'isArchived' }, comparisonTypes.Equals, false);
		rb.addFilter({ field: 'id' }, comparisonTypes.NotEquals, ticket.id);
		rb.addSort('lastUpdated', false);

		rb.offset = offset ?? 0;
		rb.limit = LIMIT;

		return rb.build();
	};

	useEffect(() => {
		if (!client) {
			return;
		}

		const openTicketsRb = getRb(false);
		const closedTicketsRb = getRb(true);

		openTicketsPromise.current = makeCancelable(TicketResource.find(openTicketsRb));
		closedTicketsPromise.current = makeCancelable(TicketResource.find(closedTicketsRb));

		openTicketsPromise.current.promise
			.then(({ data, metadata }) => {
				setOpenTickets(data);
				setTotalOpen(metadata.total);
				setHasMoreOpen(metadata.offset + metadata.limit < metadata.total);
				setOpenOffset(metadata.limit);
			})
			.catch(e => logError(e, 'Failed to find open tickets'));

		closedTicketsPromise.current.promise
			.then(({ data, metadata }) => {
				setClosedTickets(data);
				setTotalClosed(metadata.total);
				setHasMoreClosed(metadata.offset + metadata.limit < metadata.total);
				setClosedOffset(metadata.offset + metadata.limit);
			})
			.catch(e => logError(e, 'Failed to find closed tickets'));

		return () => {
			openTicketsPromise.current?.cancel();
			closedTicketsPromise.current?.cancel();
		};
	}, [client?.id]);

	const loadMoreTickets = () => {
		if (ticketType === 'closed') {
			setLoadingMoreClosed(true);
			const rb = getRb(true, closedOffset);
			openTicketsPromise.current = makeCancelable(TicketResource.find(rb));
			openTicketsPromise.current.promise
				.then(({ data, metadata }) => {
					setClosedTickets([...closedTickets, ...data]);
					setHasMoreClosed(metadata.offset + metadata.limit < metadata.total);

					setClosedOffset(metadata.offset + metadata.limit);
				})
				.catch(e => logError(e, 'Failed to find tickets'))
				.finally(() => setLoadingMoreClosed(false));
		} else {
			setLoadingMoreOpen(true);
			const rb = getRb(false, openOffset);
			closedTicketsPromise.current = makeCancelable(TicketResource.find(rb));
			closedTicketsPromise.current.promise
				.then(({ data, metadata }) => {
					setOpenTickets([...openTickets, ...data]);
					setHasMoreOpen(metadata.offset + metadata.limit < metadata.total);

					setOpenOffset(metadata.offset + metadata.limit);
				})
				.catch(e => logError(e, 'Failed to find tickets'))
				.finally(() => setLoadingMoreOpen(false));
		}
	};

	const users = useSupportUsers('active').filter(u => !u.ghost);
	const { self, customerId } = useSelector((state: RootState) => ({
		customerId: state.App?.customerId,
		self: state.App?.self
	}));
	const isSupportUser = self?.support;

	const tickets = ticketType === 'open' ? openTickets : closedTickets;
	const foundUser: BasicUserWithPermissions | undefined = users.find(u => u.id === user?.id);
	const userIsInactive: boolean = !foundUser && !!user;
	const hasMore = ticketType === 'open' ? hasMoreOpen : hasMoreClosed;
	const loadingMore = ticketType === 'open' ? loadingMoreOpen : loadingMoreClosed;
	const canEdit = !disableEditing && isSupportUser;
	// If all entries with this ticket id have contact value NULL, then it is a domain match
	const hasNonNullContacts = ticket?.multiMatchClientContacts?.some(
		(match: MultiMatchClientContact) => match.contact !== null
	);
	// Can happen if we switch to a whole different contact and the email we matched on doesn't match to the selected email.
	// This is relevant for a multi contact match, so if it's a domain match, set it to true
	const sameEmailAsMatch = hasNonNullContacts
		? ticket?.multiMatchClientContacts?.some(
				(match: MultiMatchClientContact) => match.contact?.email === contact?.email
		  )
		: true;

	return (
		<Block className={classes.b()}>
			<Flex direction="column" space="pll prl ptl" gap="u4">
				<Block>
					<Flex justifyContent="space-between">
						<Label>{t('ticket.assignee')}</Label>
						{self && isSupportUser && !disableEditing && hasCustomerSupport && !isMerged ? (
							<Link onClick={() => onFormChange('user', { id: self.id, name: self.name })}>
								{t('ticket.assignMe')}
							</Link>
						) : null}
					</Flex>
					<Tooltip disabled={isSupportUser} title={t('ticket.assignPermission')}>
						<Select
							options={users.map(u => ({ id: u.id, title: u.name }))}
							anchor={anchor}
							onClear={hasTicketRemoveUser ? () => onFormChange('user', null) : undefined}
							disabled={disableEditing || !isSupportUser || !hasCustomerSupport || isMerged}
							renderItem={value => {
								return (
									<Text ellipsis size={'md'} color={value ? 'black' : 'grey-10'} italic={!value}>
										{value.title}
									</Text>
								);
							}}
							renderSelectedItem={value => {
								return (
									<Text ellipsis size={'md'} color={value ? 'black' : 'grey-10'} italic={!value}>
										{value
											? userIsInactive
												? `${value.name} (${t('default.inactive')})`
												: value.name
											: t('ticket.chooseAssignee')}
									</Text>
								);
							}}
							value={user}
							onChange={u => onFormChange('user', { id: u.id, name: u.title })}
						/>
					</Tooltip>
				</Block>

				{hasPriority ? (
					<Block>
						<Label>{t('default.priority')}</Label>
						<PrioSelect
							disabled={!canEdit || !hasCustomerSupport || isMerged}
							onChange={value => onFormChange('priority', value)}
						/>
					</Block>
				) : null}

				<Block>
					<Label>{t('default.type')}</Label>
					<Tooltip disabled={isSupportUser} title={t('ticket.typePermission')}>
						<TypeSelect
							inputRef={typeInputRef}
							anchor={anchor}
							onChange={value => onFormChange('type', value)}
							disabled={!isSupportUser || !hasCustomerSupport || isMerged}
						/>
					</Tooltip>
				</Block>

				{/* TODO: Fix better way to check for one domain match */}
				{hasImprovedMatching &&
				isMultiMatch &&
				ticket?.multiMatchClientContacts.length > 1 &&
				sameEmailAsMatch &&
				!isMatchPopupDismissed ? (
					<Block
						className={classes.elem('matchesFoundBox').b()}
						backgroundColor="super-light-blue"
						space="ptl pll pbl prl"
					>
						<Text color="blue">
							{ticket?.multiMatchClientContacts?.some(
								(match: MultiMatchClientContact) => match.contact !== null
							)
								? t('ticket.suggestedContactDescription')
								: t('ticket.suggestedClientDescription')}
						</Text>
						<Flex space="ptm">
							<DropDownMenu
								align="left"
								className={classes.elem('suggestedDropdown').b()}
								renderTrigger={(isExpanded, setExpanded) => (
									<PrimaryButton onClick={setExpanded}>
										{hasNonNullContacts ? t('default.showContacts') : t('default.showClients')}
									</PrimaryButton>
								)}
							>
								{close => (
									<SuggestedClientContact
										onFormChange={onFormChange}
										hasCustomerSupport={hasCustomerSupport}
										close={close}
										suggestedContent={
											hasNonNullContacts ? SuggestedContent.Contact : SuggestedContent.Client
										}
									/>
								)}
							</DropDownMenu>
							<ThirdButton onClick={dismissMatchPopup}>{t('default.dismiss')}</ThirdButton>
						</Flex>
					</Block>
				) : null}

				<Flex direction="column">
					<Flex justifyContent="space-between" alignItems="center">
						<Label>{t('default.contact')}</Label>
						{canEdit ? (
							<ChangeContact
								onChange={(type, value) => {
									onFormChange(type, value);
								}}
								values={values.contactInfo}
								disabled={!hasCustomerSupport || isMerged}
								useText={hasImprovedMatching}
							/>
						) : null}
					</Flex>
					{email && !contact?.email ? (
						<Flex alignItems="center" space="plxl" justifyContent="space-between">
							<EllipsisTooltip title={email} appendTo={tooltipAnchorRef.current as HTMLDivElement}>
								<Text>
									<Icon space="mrm" name="email" />
									{email}
								</Text>
							</EllipsisTooltip>
							{hasImprovedMatching ? (
								<Tooltip title={t('ticket.changeToContact')} position="left">
									<ThirdButton
										onClick={() => {
											// eslint-disable-next-line promise/catch-or-return
											Tools.$upModal
												.open('editContact', { contact: { email }, account: client })
												.then((addedContact?: Contact) => {
													if (addedContact) {
														onFormChange('contactInfo', {
															email: addedContact.email,
															contact: addedContact,
															client: addedContact.client
														});
													}
												});
										}}
										disabled={!hasCustomerSupport || isMerged}
										icon={'user-plus'}
									/>
								</Tooltip>
							) : null}
						</Flex>
					) : null}

					{contact ? (
						<>
							<Flex alignItems="center" justifyContent="space-between">
								<Tooltip
									title={t('ticket.visitContact')}
									className={classes.elem('visitTooltip').b()}
									position="bottom"
									distance={20}
								>
									<ClickableItem
										bold
										size="lg"
										icon="user"
										borderRadius
										textSize="lg"
										color="black"
										iconColor="black"
										title={contact.name}
										onClick={() => {
											window.open(
												Tools.$state.href('contact.dashboard', {
													customerId,
													id: contact?.id
												}),
												'_blank'
											);
										}}
										className={classes.elem('contact').b()}
										subtitle={contact.title}
									/>
								</Tooltip>
								{hasImprovedMatching ? (
									<Tooltip title={t('default.editContact')} position="left">
										<ThirdButton
											onClick={() => {
												// eslint-disable-next-line promise/catch-or-return
												Tools.$upModal
													.open('editContact', contact)
													.then((updatedContact?: Contact) => {
														if (updatedContact) {
															onFormChange('contactInfo', {
																email: updatedContact.email,
																contact: updatedContact,
																client: updatedContact.client
															});
														}
													});
											}}
											disabled={!hasCustomerSupport || isMerged}
											icon={'edit'}
										/>
									</Tooltip>
								) : null}
							</Flex>
							<Flex direction="column" gap="u2">
								{contact?.cellPhone || contact?.phone ? (
									<Flex space="plxl" alignItems="center">
										<Icon name="phone" space="mrl" />
										<TelephoneLink
											client={client}
											contact={contact}
											number={contact.cellPhone || contact.phone}
										/>
									</Flex>
								) : null}
								{email && !contact?.email ? null : contact?.email ? (
									<Flex space="plxl" alignItems="center">
										<Icon name="email" space="mrl" />
										{ReactTemplates.TOOLS.mailTo(
											customerId,
											{ ...contact, email: contact.email },
											{}
										)}
									</Flex>
								) : (
									<Flex space="plxl" gap="u2">
										<Flex flex={1} alignItems="center">
											<Icon name="email" space="mrl" />
											<Input
												className={classes.elem('addEmail').b()}
												type="email"
												value={newEmail}
												data-1p-ignore
												disabled={disableEditing || isMerged}
												placeholder={t('default.enterEmail')}
												state={validEmail ? undefined : 'error'}
												onChange={e => {
													setNewEmail(e.target.value);
													setValidEmail(ValidationService.validEmail(e.target.value));
												}}
												required
											/>
										</Flex>

										<PrimaryButton
											disabled={!validEmail || !hasCustomerSupport || isMerged}
											onClick={() => {
												onFormChange('contactInfo', {
													client,
													contact: { ...contact, email: newEmail },
													email: newEmail
												});
											}}
										>
											<Icon name="check" />
										</PrimaryButton>
									</Flex>
								)}
							</Flex>
						</>
					) : null}
				</Flex>
				{client ? (
					<Flex direction="column">
						<Label>{t('ticket.companyInformation')}</Label>
						<Flex alignItems="center" justifyContent="space-between">
							<Tooltip
								title={t('ticket.visitCompany')}
								className={classes.elem('visitTooltip').b()}
								position="bottom"
								distance={30}
							>
								<ClickableItem
									className={classes.elem('client').b()}
									title={client?.name}
									subtitle={getJourneyStep(client?.journeyStep)?.name}
									size="lg"
									bold
									textSize="lg"
									icon="home"
									color="black"
									iconColor="black"
									onClick={() => {
										window.open(
											Tools.$state.href('account.dashboard', {
												customerId,
												id: client.id
											}),
											'_blank'
										);
									}}
									borderRadius
								/>
							</Tooltip>
							{/* TODO: Add userEditable check */}
							{hasImprovedMatching ? (
								<Tooltip title={t('default.editCompany')} position="left">
									<ThirdButton
										onClick={() => {
											if (shouldOpenModal('EditClient')) {
												openModal('EditClient', {
													id: client.id,
													onClose: (updatedClient?: Client) => {
														if (updatedClient) {
															onFormChange('contactInfo', {
																email: contact?.email || email,
																contact,
																client: updatedClient
															});
														}
													}
												});
											} else {
												return Tools.$upModal
													.open('editAccount', { account: client })
													.then((updatedClient?: Client) => {
														if (updatedClient) {
															onFormChange('contactInfo', {
																email: contact?.email || email,
																contact,
																client: updatedClient
															});
														}
													});
											}
										}}
										disabled={!hasCustomerSupport || isMerged}
										icon={'edit'}
									/>
								</Tooltip>
							) : null}
						</Flex>
						{client.users?.length > 0 ? (
							<Block>
								<Label>
									{t(client?.users.length > 1 ? 'ticket.accountManagers' : 'ticket.accountManager')}
								</Label>
								<Block space="plxl">
									{client.users.map((user: any) => (
										<Flex space="mtm" gap="u3" key={'u-' + user.id} alignItems="center">
											<Avatar size={18} user={user} />
											<Text>{user.name}</Text>
										</Flex>
									))}
								</Block>
							</Block>
						) : null}
					</Flex>
				) : null}

				{hasTicketCC && !isMerged ? (
					<FormComponent label={t('mail.ccShort')} hideMessage>
						<CcSelect value={values.cc} onChange={values => onFormChange('cc', values)} />
					</FormComponent>
				) : null}
			</Flex>
			{hasTicketRelations && client && contact && client.active ? (
				<>
					<hr />
					<RelateTo onChange={onFormChange} />
				</>
			) : null}

			{customFields.length ? (
				<>
					<hr />
					<FormComponent label={t('default.customFields')} hideMessage space="mrl mll">
						<CustomFields
							type="ticket"
							inputProps={formInputProps}
							onChange={(id: number, value: string) => {
								onFormChange(`custom.Custom_${id}`, value);
							}}
							onlyShowIds={customFields.map(cf => cf.id)}
							disabled={!hasCustomerSupport || isMerged}
						/>
					</FormComponent>
				</>
			) : null}
			{openTickets.length > 0 || closedTickets.length > 0 ? (
				<>
					<hr />
					<Block className={classes.elem('tickets').b()} space="mll mrl pbxl">
						<Block space="pbm">
							<Text bold>{t('ticket.tickets')}</Text>
						</Block>
						<Block space="mbs">
							<ButtonSelect
								options={[
									{
										title: t('ticket.openTickets', { count: totalOpen }),
										value: 'open'
									},
									{
										title: t('ticket.closedTickets', { count: totalClosed }),
										value: 'closed'
									}
								]}
								value={ticketType}
								onChange={setTicketType}
							/>
						</Block>
						{tickets.map(ticket => {
							const date = dateCalendar(new Date(ticket.regDate), true).toString().toLowerCase();
							return (
								<ClickableItem
									color="black"
									size="lg"
									key={'ticket' + ticket.id}
									title={ticket.title}
									block
									onClick={() => openModal('EditTicket', { ticketId: ticket.id })}
									subtitle={
										!ticket.contact
											? t('ticket.created', { date })
											: ticket.source === 'email'
											? `${t('ticket.submitted')} ${date} ${t('default.by').toLowerCase()} ${
													ticket.contact?.name
											  }`
											: t('ticket.createdBy', { date, name: ticket.regBy?.name || '' })
									}
								/>
							);
						})}
						{tickets.length === 0 ? (
							<Block space="mtxl">
								<Row align="center">
									<Text color="grey-10" italic>
										{ticketType === 'open'
											? t('ticket.noOpenTickets')
											: t('ticket.noClosedTickets')}
									</Text>
								</Row>
							</Block>
						) : null}
						{hasMore ? (
							<Row align="center">
								<Block space="ptm">
									{loadingMore ? (
										<Loader size="xs" />
									) : (
										<Link onClick={loadMoreTickets}>{t('ticket.showMoreTickets')}</Link>
									)}
								</Block>
							</Row>
						) : null}
					</Block>
				</>
			) : null}
		</Block>
	);
};

export default TicketInfo;
