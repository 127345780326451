import EditOrderContext from './context';
import React from 'react';
import useEditOrderLogic from './hooks/useEditOrderLogic';
import useDraftableStateStore from './hooks/useDraftableStateStore';
import useEventListeners from './hooks/useEventListeners';
import useOnModalClose from './hooks/useOnModalClose';
import useOnOrderChange from './hooks/useOnOrderChange';

import type { ReactNode } from 'react';
import type { State, ModalParams, Meta, Resolve, Reject } from './types';

type ProviderProps = {
	modalId: number;
	modalParams: ModalParams;
	meta: Meta;
	resolve: Resolve;
	reject: Reject;
	children: ReactNode;
};

const EditOrderProvider = ({ modalId, modalParams, meta, resolve, reject, children }: ProviderProps) => {
	const store = useDraftableStateStore<State>();
	const { actions, selectConfigs } = useEditOrderLogic(store, modalId, modalParams, meta, resolve, reject);
	useOnOrderChange(store, actions); // Maby it is cleaner to not have these hooks here and instead use useEditOrderContext in them, but that is for someone in the future to refactor as I dont have time.
	useEventListeners(store, actions, resolve, reject); // Maby it is cleaner to not have these hooks here and instead use useEditOrderContext in them, but that is for someone in the future to refactor as I dont have time.
	useOnModalClose(store, modalId, reject); // Maby it is cleaner to not have these hooks here and instead use useEditOrderContext in them, but that is for someone in the future to refactor as I dont have time.

	// For now just spread these so it looks kinda like the EditOrder controller in the Angular code.
	// IMO it would later be cleaner to separate them like, actions.onPriceChange and state.order.price in the views instead always refering to a mega EditOrder object.
	const value = { select: selectConfigs, ...actions, ...store.state };

	return <EditOrderContext.Provider value={value}>{children}</EditOrderContext.Provider>;
};

export default EditOrderProvider;
