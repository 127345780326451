import RequestBuilder from 'Resources/RequestBuilder';
import type { MultiSelect } from 'App/components/ListView/ListViewRenderHelpers';
import { MultiSelectContext } from 'App/components/MultiselectProvider/MultiselectProvider';
import { openMultiUpdateActivity } from 'App/components/MultiActionModal/MultiUpdateActivity/openMultiUpdateActivity';

interface UpdateTasksOptions {
	customerId: number;
	filters: RequestBuilder;
	entity: string;
	multiSelect: MultiSelect;
	isTodoList: boolean;
}

export default function updateTasks(rb: RequestBuilder, entity: string, multiSelectContext: MultiSelectContext) {
	const multiSelect = {
		active: multiSelectContext.active,
		allSelected: multiSelectContext.allSelected,
		selected: multiSelectContext.selected,
		selectedIds: multiSelectContext.selectedIds,
		selectedIdMap: multiSelectContext.selectedIdMap
	};
	const opts: UpdateTasksOptions = {
		customerId: Tools.AppService.getCustomerId(),
		filters: rb,
		entity,
		multiSelect,
		isTodoList: true
	};
	return openMultiUpdateActivity(opts);
}
