import React, { useMemo } from 'react';
import moment from 'moment';
import { containsPhoneWords } from 'App/babel/helpers/phoneWords';
// @ts-ignore
import classNames from 'classnames';

import type Event from 'App/resources/Model/Event';

type Props = {
	line?: boolean;
	event: Event;
};

function isPhoneType(event: Event) {
	if (!event?.activity?.activityType?.name) {
		return false;
	}

	const activityTypeName = event.activity.activityType.name;
	return containsPhoneWords(activityTypeName);
}

function isPassedActivity(event: Event) {
	return event?.entityType === 'Activity' && moment(event.date).endOf('day').isBefore() ? true : false;
}

const EventIcon = ({ line, event }: Props) => {
	const modifiers = useMemo(
		() => ({
			isPassedActivity: isPassedActivity(event),
			isPhoneType: isPhoneType(event)
		}),
		[]
	);

	return (
		<td className="icon-td active">
			<div>
				{event.entityType === 'Activity' ? (
					<div className={classNames('icon activity', { overdue: modifiers.isPassedActivity })}>
						{modifiers.isPhoneType ? (
							<i className="Icon Icon-phone"></i>
						) : (
							<div className="Icon Icon-activity Icon--white"></div>
						)}
					</div>
				) : null}
				{event.entityType === 'Appointment' ? (
					<div className="icon appointment">
						<i className="Icon Icon-calendar"></i>
					</div>
				) : null}
			</div>

			{line ? <div className="line"></div> : null}
		</td>
	);
};

export default EventIcon;
