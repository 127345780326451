//eslint-disable-next-line
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Bem from '@upsales/components/Utils/bemClass';
import { Text, Icon, Link, Progressbar, Block } from '@upsales/components';
import logError from 'App/babel/helpers/logError';
import history from 'App/pages/routes/history';
import { NOTIFICATION_TYPES, NOTIFICATION_ACTION } from 'App/babel/enum/notification';
import { requestAddon } from 'Store/reducers/BillingReducer';
import './index.scss';
import { PrimaryButton } from '@upsales/components/Buttons';
import { connect } from 'react-redux';
import { makeCancelable } from '@upsales/components/Utils/CancelablePromise';
import Comment from 'Resources/Comment';
import { openDrawer } from 'Services/Drawer';
import T from 'Components/Helpers/translate';
import { openYourYearWithUpsalesModal } from 'Components/YourYearWithUpsales/YourYearWithUpsales';
import openModal from 'App/services/Modal';
import { openEditAppointment } from 'Components/Modals/Appointment/EditAppointment';

const mapStateToProps = ({ Billing, App }) => ({
	boughtAddons: Billing.addons.reduce((res, a) => {
		res[a.alias] = a;
		return res;
	}, {}),
	trialAddons: Billing.trialAddons,
	canBuyAddon: !!App.self?.billingAdmin
});

const mapDispatchToProps = { requestAddon };

class NotificationCard extends Component {
	static defaultProps = {
		exportUrl: ''
	};

	state = {
		hasRequestedAddon: false,
		requestingAddon: false,
		errorMessage: false
	};

	constructor(props) {
		super(props);
		const t = Tools.$translate;
		this.lang = {
			didSign: t('esign.didSign').toLowerCase(),
			viewed: t('esign.viewed').toLowerCase(),
			rejected: t('esign.rejected').toLowerCase(),
			undelivered: t('esign.undelivered').toLowerCase(),
			closedDeal: t('default.closedDeal').toLowerCase(),
			sharedView: t('notifications.sharedView').toLowerCase(),
			sharedViewWithYou: t('notifications.sharedView.withYou').toLowerCase(),
			with: t('default.with').toLowerCase(),
			createdAgreement: t('default.createdAgreement').toLowerCase(),
			yearlyValue: t('default.yearlyValue').toLowerCase(),
			didAssign: t('default.didAssign').toLowerCase(),
			mail: {
				soft_bounce: t('mail.bounced').toLowerCase(),
				hard_bounce: t('mail.bounced').toLowerCase(),
				error: t('mail.failed').toLowerCase()
			},
			toYou: t('default.toYou').toLowerCase(),
			at: t('default.at').toLowerCase(),
			to: t('default.to').toLowerCase(),
			theMail: t('default.theMail'),
			formSubmited: t('event.form.submited').toLowerCase(),
			visited: t('event.visited').toLowerCase(),
			failed: t('noti.failed').toLowerCase(),
			done: t('default.done').toLowerCase(),
			export: t('export.export').toLowerCase(),
			hadMarketingEvent: t('event.hadMarketingEvent'),
			clickDownload: t('noti.export.clickDownload').toLowerCase(),
			contacts: t('default.contacts2'),
			contact: t('contact'),
			clients: t('default.clients'),
			client: t('default.client'),
			products: t('default.products'),
			product: t('default.product'),
			showProducts: t('import.showProducts'),
			showProduct: t('import.showProduct'),
			order: t('notificationList.name.order'),
			sharedViewName: t('notificationList.name.sharedView'),
			sharedReport: t('notificationList.name.sharedReport'),
			subscription: t('notificationList.name.subscription'),
			assignedLead: t('notificationList.name.assignedLead'),
			formSubmit: t('notificationList.name.formSubmit'),
			websiteVisit: t('notificationList.name.websiteVisit'),
			email: t('notificationList.name.email'),
			marketingEvent: t('notificationList.name.marketingEvent'),
			upsales: t('notificationList.name.upsales'),
			exportName: t('notificationList.name.export'),
			import: t('notificationList.name.import'),
			jobFaled: t('notificationList.name.jobFaled'),
			jobComplete: t('notificationList.name.jobComplete'),
			job: t('admin.job'),
			esign: t('notificationList.name.esign'),
			mailCampaign: t('notificationList.name.mailCampaign'),
			yourAccount: t('notificationList.name.yourAccount'),
			oneOffValue: t('default.oneOffValue'),
			monthlyValue: t('default.monthlyValue'),
			annualValue: t('default.annualValue'),
			contributionMargin: t('default.contributionMarginShort'),
			worth: t('notifications.order.value.worth').toLowerCase(),
			yourYearWithUpsales: t('yourYearWithUpsales'),
			open: t('default.open'),
			ticket: t('ticket.supportTicket'),
			newTicket: t('ticket.newSupportTicket'),
			closedTicket: t('ticket.closedSupportTicket'),
			ticketMessage: t('ticket.newMessageTicket')
		};

		if (props.notification.type === NOTIFICATION_TYPES.ADDON_PROMOTION) {
			this.hadTrial = [...this.props.trialAddons]
				.sort((a, b) => (a.endDate < b.endDate && 1) || -1)
				.find(ta => this.props.notification.action === ta.alias);
		}
	}

	requestTrial(e) {
		e.stopPropagation();
		this.setState({ requestingAddon: true });
		this.requestAddonPromise = makeCancelable(
			this.props.requestAddon(this.props.notification.action, !this.hadTrial)
		);
		this.requestAddonPromise.promise
			.then(() => {
				this.setState({ requestingAddon: false, hasRequestedAddon: true });
			})
			.catch(e => {
				logError(e, 'Failed to request addon');
				this.setState({ requestingAddon: false });
			});
	}

	handleNotificationClick = (canView, e) => {
		const { notification, onTabChanged = () => {} } = this.props;
		this.props.onClick(notification, e);
		if (notification.type === NOTIFICATION_TYPES.PROSPECTING_NEW_SIGNALS) {
			onTabChanged('signals');
		} else if (notification.type === NOTIFICATION_TYPES.ADDON_PROMOTION) {
			if (this.props.canBuyAddon) {
				Tools.$state.go('administration.billing', {
					alias: this.props.notification.action
				});
				this.props.closeDrawer();
			} else {
				this.requestTrial(e);
			}
		} else if (notification.hasData) {
			openModal('NotificationDetail', { notification });
		} else if (canView) {
			this.props.closeDrawer();
		}
	};

	showImport = (e, type) => {
		const { notification } = this.props;
		e.stopPropagation();
		this.props.showImport(notification, type);
	};

	getStateHref(stateName, params = {}) {
		const newParams = { ...params, customerId: Tools.AppService.getCustomerId() };
		return Tools.$state.href(stateName, newParams);
	}

	renderJobProgress = status => status > -1 && status < 100 && <Progressbar value={status} color="bright-blue" />;

	renderClientLink = prefixText => {
		const { notification } = this.props;

		if (!notification.client) {
			return null;
		}

		return (
			<>
				{` ${prefixText.toLowerCase()} `}
				<b>
					<Link href={this.getStateHref('account.dashboard', { id: notification.client.id })}>
						{notification.client.name}
					</Link>
				</b>
			</>
		);
	};

	get notificationContent() {
		const classNames = new Bem('NotificationCard');
		const { notification, exportUrl } = this.props;
		const currencyFormat = Tools.$filter('currencyFormat');
		const t = Tools.$translate;
		const cn = classNames.elem('text').b();
		let contact = null;
		const metadata = Tools.AppService.getMetadata();
		const defaultCurrencyIso = _.find(metadata.customerCurrencies, { masterCurrency: true }).iso;
		let headingText = '';
		let iconName = '';
		let childContent = null;
		let iconColor = '';
		let canView = true;

		switch (notification.type) {
			case NOTIFICATION_TYPES.ADDON_PROMOTION: {
				iconName = 'lightbulb';
				iconColor = 'yellow-4';
				let data;
				try {
					data = JSON.parse(notification.message);
					headingText = t('prospecting.promoNotification.title');
					childContent = (
						<div className={cn}>
							<span>
								{t('prospecting.promoNotification.body', { num: data.total })}{' '}
								{t(
									this.hadTrial
										? 'prospecting.promoNotification.bodyActivate'
										: `prospecting.promoNotification.bodyTry`
								)}
							</span>
							<Block space="mtl">
								{this.props.canBuyAddon ? (
									<PrimaryButton
										block
										onClick={() =>
											Tools.$state.go('administration.billing', {
												alias: this.props.notification.action
											})
										}
										disabled={this.state.hasRequestedAddon}
										loading={this.state.requestingAddon}
									>
										{t('system.activate_in_billing')}
									</PrimaryButton>
								) : (
									<PrimaryButton
										block
										onClick={e => this.requestTrial(e)}
										disabled={this.state.hasRequestedAddon}
										loading={this.state.requestingAddon}
									>
										<Icon name={this.state.hasRequestedAddon ? 'check' : 'envelope'} space="mrs" />
										{this.state.hasRequestedAddon
											? t('admin.billing.addon.askAdminRequestSent')
											: !this.hadTrial
											? t('admin.billing.addon.askAdminToTry')
											: t('admin.billing.addon.askAdminToBuy')}
									</PrimaryButton>
								)}
							</Block>
						</div>
					);
				} catch (err) {
					logError(err, 'Failed to parse notification message');
				}
				break;
			}
			case NOTIFICATION_TYPES.ESIGN:
				{
					let status = '';
					headingText = this.lang.esign;

					switch (notification.action) {
						case NOTIFICATION_ACTION.SIGNED:
							iconName = 'edit';
							status = this.lang.didSign;
							break;
						case NOTIFICATION_ACTION.SEEN:
							iconName = 'eye';
							status = this.lang.viewed;
							break;
						case NOTIFICATION_ACTION.DECLINED:
							iconName = 'times';
							status = this.lang.rejected;
							break;
						case NOTIFICATION_ACTION.UNDELIVERED:
							status = this.lang.undelivered;
							break;
					}
					let fileName = '';
					if (notification.esign) {
						if (notification.esign.file) {
							fileName = notification.esign.file.filename;
						} else if (notification.esign.title) {
							fileName = notification.esign.title;
						}
					}

					childContent = (
						<div className={cn}>
							<b>
								{notification.contact ? (
									<a href={this.getStateHref('contact.dashboard', { id: notification.contact.id })}>
										{notification.contact.name}
									</a>
								) : (
									<span>{notification.registeredBy ? notification.registeredBy.name : ''}</span>
								)}
							</b>
							<span> {status}</span>
							<b> {fileName}</b>
						</div>
					);
				}

				canView = !!notification.message;
				break;
			case NOTIFICATION_TYPES.TICKET_REPLIES:
				{
					headingText = this.lang.ticketMessage;
					iconName = 'customer-support';
					const ticketLink = (
						<Link onClick={() => openDrawer('EditTicket', { ticketId: notification.entityId })}>
							{notification.message}
						</Link>
					);
					const content = (
						<>
							{T('ticket.newMessage') + ' '}
							{ticketLink}
							{this.renderClientLink(T('default.on'))}
						</>
					);
					childContent = <div className={cn}>{content}</div>;
				}
				break;
			case NOTIFICATION_TYPES.TICKET_ASSIGNED:
			case NOTIFICATION_TYPES.TICKET_ACCOUNT_MANAGERS:
			case NOTIFICATION_TYPES.TICKET:
				{
					iconName = 'customer-support';
					headingText = this.lang.ticket;
					const wasClosed = notification.action === 'Closed';
					let content;
					const ticketLink = (
						<Link onClick={() => openDrawer('EditTicket', { ticketId: notification.entityId })}>
							{notification.message}
						</Link>
					);

					if (Tools.FeatureHelper.hasSoftDeployAccess('TICKET_NOTIFICATION_V2')) {
						if (notification.action.indexOf('assigneeChanged') >= 0) {
							const [, oldUserid] = notification.action.split(':');

							if (parseInt(oldUserid) === Tools.AppService.getSelf()?.id) {
								content = (
									<>
										{T('ticket.NoLongerAssigned') + ' '}
										{ticketLink}
										{this.renderClientLink(T('default.on'))}
									</>
								);
							} else {
								headingText = this.lang.newTicket;
								content = (
									<>
										<b>{notification.registeredBy?.name || T('default.someone')}</b>
										{' ' + T('ticket.assignedTicket').toLowerCase() + ' '}
										{ticketLink}
										{this.renderClientLink(T('default.on'))}
										{' ' + T('default.toYou').toLowerCase()}
									</>
								);
							}
						} else if (notification.action === 'assigned') {
							headingText = this.lang.newTicket;
							content = (
								<>
									<b>{notification.registeredBy?.name || T('default.someone')}</b>
									{' ' + t('ticket.assignedTicket').toLowerCase() + ' '}
									{ticketLink}
									{this.renderClientLink(T('default.on'))}
									{' ' + T('default.toYou').toLowerCase()}
								</>
							);
						} else if (wasClosed) {
							headingText = this.lang.closedTicket;
							content = (
								<>
									<b>{notification.registeredBy?.name || T('default.someone')}</b>
									{' ' + T('ticket.closedTicket').toLowerCase() + ' '}
									{ticketLink}
									{this.renderClientLink(T('default.on'))}
								</>
							);
						} else {
							let createdBy = T('default.someone');
							let onClient = '';
							let createdText = ' ' + T('ticket.createdTheTicket').toLowerCase() + ' ';
							headingText = this.lang.newTicket;

							const users = notification.client?.users?.flatMap(user => user.id) || [];
							const userId = notification.action.split(':')[1] || [];

							if (
								notification.registeredBy &&
								users.includes(Tools.AppService.getSelf()?.id) &&
								parseInt(userId) !== Tools.AppService.getSelf()?.id
							) {
								createdBy = <b>{notification.registeredBy.name}</b>;
								onClient = this.renderClientLink(T('default.on'));
							} else if (notification.registeredBy) {
								createdBy = <b>{notification.registeredBy.name}</b>;
								onClient = (
									<>
										{this.renderClientLink(T('default.on'))}
										{' ' + T('ticket.andAssignedIt').toLowerCase()}
										{' ' + T('default.toYou').toLowerCase()}
									</>
								);
							} else if (notification.contact) {
								createdBy = (
									<>
										<b>
											<Link
												href={this.getStateHref('contact.dashboard', {
													id: notification.contact.id
												})}
											>
												{notification.contact.name}
											</Link>
										</b>
										{this.renderClientLink(T('default.at'))}
									</>
								);
								createdText = ' ' + T('ticket.sentInTheTicket').toLowerCase() + ' ';
								onClient = '';
							}
							content = (
								<>
									{createdBy}
									{createdText}
									{ticketLink}
									{onClient}
								</>
							);
						}
					} else {
						if (wasClosed) {
							headingText = this.lang.closedTicket;
							content = (
								<>
									<b>{notification.registeredBy?.name || T('default.someone')}</b>
									{' ' + T('ticket.closedTicket').toLowerCase() + ' '}
									{ticketLink}
									{this.renderClientLink(T('default.on'))}
								</>
							);
						} else {
							let createdBy = T('default.someone');
							let createdText = ' ' + T('ticket.createdTheTicket').toLowerCase() + ' ';
							let onClient = '';
							headingText = this.lang.newTicket;

							if (notification.registeredBy) {
								createdBy = <b>{notification.registeredBy.name}</b>;
								onClient = this.renderClientLink(T('default.on'));
							} else if (notification.contact) {
								createdBy = (
									<>
										<b>
											<Link
												href={this.getStateHref('contact.dashboard', {
													id: notification.contact.id
												})}
											>
												{notification.contact.name}
											</Link>
										</b>
										{this.renderClientLink(T('default.at'))}
									</>
								);
								createdText = ' ' + T('ticket.sentInTheTicket').toLowerCase() + ' ';
							}
							content = (
								<>
									{createdBy}
									{createdText}
									{ticketLink}
									{onClient}
								</>
							);
						}
					}
					childContent = <div className={cn}>{content}</div>;
				}
				break;

			case NOTIFICATION_TYPES.ORDER:
				iconName = 'sales';
				headingText = this.lang.order;
				iconColor = 'bright-green';

				var salesModel = metadata.params.SalesModel;
				var salesModelOption = metadata.params.SalesModelOption;
				var showContributionMargin = salesModel === 'cm' && salesModelOption === 'cm';
				var showRecurring = salesModel === 'rr';
				var showARR = showRecurring && salesModelOption === 'arr';

				var orderValue = 0;
				var recurringValue = 0;
				var oneOffValue = 0;
				var displayRecurring, displayARR, displayMRR, displayOneOff, displayCM;

				if (notification.order) {
					let formatted;
					orderValue = notification.order.orderValue;
					if (showContributionMargin) {
						formatted = formatted = currencyFormat(
							notification.order.contributionMarginLocalCurrency,
							notification.order.currency || defaultCurrencyIso
						);
						displayCM = `${formatted} (${this.lang.contributionMargin})`;
					} else if (showRecurring) {
						if (showARR) {
							recurringValue = notification.order.annualValue;
							formatted = currencyFormat(
								notification.order.annualValue,
								notification.order.currency || defaultCurrencyIso
							);
							displayARR = recurringValue ? `${formatted} (${this.lang.annualValue})` : null;
						} else {
							recurringValue = notification.order.monthlyValue;
							formatted = currencyFormat(
								notification.order.monthlyValue,
								notification.order.currency || defaultCurrencyIso
							);
							displayMRR = recurringValue ? `${formatted} (${this.lang.monthlyValue})` : null;
						}

						oneOffValue = notification.order.oneOffValue;
						formatted = currencyFormat(
							notification.order.oneOffValue,
							notification.order.currency || defaultCurrencyIso
						);
						displayOneOff = oneOffValue ? `${formatted} (${this.lang.oneOffValue})` : null;

						displayRecurring = displayARR || displayMRR;
						if (displayOneOff) {
							if (displayRecurring) {
								displayRecurring = displayRecurring + ' & ' + displayOneOff;
							} else {
								displayRecurring = displayOneOff;
							}
						}
					}
				}
				childContent = (
					<div className={cn}>
						<b>{notification.registeredBy ? notification.registeredBy.name : ''} </b>
						<span>
							{this.lang.closedDeal} {this.lang.with}
						</span>
						<b>
							{notification.client ? (
								<a href={this.getStateHref('account.dashboard', { id: notification.client.id })}>
									{' '}
									{notification.client.name}
								</a>
							) : null}
						</b>
						<b>
							{' ' + this.lang.worth + ' '}
							<span>
								{showRecurring ? displayRecurring : null}
								{showContributionMargin ? displayCM : null}
								{!showRecurring && !showContributionMargin
									? currencyFormat(
											orderValue,
											(notification.order && notification.order.currency) || defaultCurrencyIso
									  )
									: null}
							</span>
						</b>
					</div>
				);
				break;
			case NOTIFICATION_TYPES.REPORTVIEW:
			case NOTIFICATION_TYPES.LISTVIEW:
				headingText =
					notification.type === NOTIFICATION_TYPES.REPORTVIEW
						? this.lang.sharedReport
						: this.lang.sharedViewName;
				iconName = 'eye';
				childContent = (
					<div className={cn}>
						<b>{notification.registeredBy ? notification.registeredBy.name : ''} </b>
						<span>{this.lang.sharedView}</span>
						<b> {notification.message}</b>
						<span>{' ' + this.lang.sharedViewWithYou + '.'}</span>
					</div>
				);
				break;
			case NOTIFICATION_TYPES.AGREEMENT:
				headingText = this.lang.subscription;
				iconName = 'sales';
				iconColor = 'bright-green';

				childContent = (
					<div className={cn}>
						<b>{notification.registeredBy ? notification.registeredBy.name : ''} </b>
						{this.lang.createdAgreement} {this.lang.with}
						<b>
							{notification.client ? (
								<a href={this.getStateHref('account.dashboard', { id: notification.client.id })}>
									{' '}
									{notification.client.name}
								</a>
							) : null}
						</b>
						<b>
							{' '}
							{'('}
							<span>
								{this.lang.yearlyValue}{' '}
								{currencyFormat(
									notification.agreement ? notification.agreement.yearlyValue : 0,
									(notification.agreement ? notification.agreement.currency : null) ||
										defaultCurrencyIso
								)}
							</span>
							{')'}
						</b>
					</div>
				);
				break;
			case NOTIFICATION_TYPES.ASSIGNED:
				headingText = this.lang.assignedLead;
				iconName = 'star';
				childContent = (
					<div className={cn}>
						<b>{notification.registeredBy ? notification.registeredBy.name : ''}</b> {this.lang.didAssign}
						<b>
							{notification.client ? (
								<a href={this.getStateHref('account.dashboard', { id: notification.client.id })}>
									{' '}
									{notification.client.name}
								</a>
							) : null}
						</b>{' '}
						{this.lang.toYou}
					</div>
				);
				break;
			case NOTIFICATION_TYPES.SUBMIT:
				headingText = this.lang.formSubmit;
				iconName = 'file';
				childContent = (
					<div className={cn}>
						{notification.contact ? (
							<b>
								<a href={this.getStateHref('contact.dashboard', { id: notification.contact.id })}>
									{' '}
									{notification.contact.name}
								</a>
							</b>
						) : null}{' '}
						{this.lang.at}
						<b>
							{notification.client ? (
								<a href={this.getStateHref('account.dashboard', { id: notification.client.id })}>
									{' '}
									{notification.client.name}
								</a>
							) : null}
						</b>{' '}
						{this.lang.formSubmited}
						<b>
							{notification.form ? (
								<a href={'#/form-overview/' + notification.form.id}> {notification.form.name}</a>
							) : null}
						</b>
					</div>
				);
				break;
			case NOTIFICATION_TYPES.VISIT:
				headingText = this.lang.websiteVisit;
				iconName = 'globe';
				contact = null;
				if (notification.contact) {
					contact = (
						<span>
							<b>
								<a href={this.getStateHref('contact.dashboard', { id: notification.contact.id })}>
									{notification.contact.name}
								</a>
							</b>{' '}
							{this.lang.at}{' '}
						</span>
					);
				}
				childContent = (
					<div className={cn}>
						{contact}
						<b>
							{notification.client ? (
								<a href={this.getStateHref('account.dashboard', { id: notification.client.id })}>
									{notification.client.name}
								</a>
							) : null}
						</b>{' '}
						{this.lang.visited}
					</div>
				);
				break;
			case NOTIFICATION_TYPES.MAIL:
				headingText = this.lang.email;
				iconName = 'envelope';
				childContent = (
					<div className={cn}>
						{this.lang.theMail + ' ' + this.lang.to + ' '}
						{notification.contact ? (
							<React.Fragment>
								<a href={this.getStateHref('contact.dashboard', { id: notification.contact.id })}>
									{notification.contact.name}
								</a>
								{' ' + this.lang.at + ' '}
							</React.Fragment>
						) : null}
						{notification.client ? (
							<a href={this.getStateHref('account.dashboard', { id: notification.client.id })}>
								{notification.client.name}
							</a>
						) : null}{' '}
						{this.lang.mail[notification.action]}
					</div>
				);

				if (notification.action.indexOf('bounce') >= 0) {
					iconColor = 'red';
				}

				break;
			case NOTIFICATION_TYPES.APP_CALLBACK:
				canView = !!notification.entityId;
				headingText = notification.action;
				if (notification.message && notification.message.includes('"easybooking"')) {
					iconName = 'calendar';
					try {
						const message = JSON.parse(notification.message);
						const { contact, appointment } = message.easybooking;
						childContent = (
							<div className={cn}>
								<Link href={this.getStateHref('contact.dashboard', { id: contact.id })}>
									{contact.name}
								</Link>
								{` ${Tools.$translate('default.booked').toLowerCase()} `}
								<Link onClick={() => openEditAppointment({ id: appointment.id })}>
									{appointment.description}
								</Link>
								{` ${Tools.$translate('notifications.sharedView.withYou')} @ ${moment(
									appointment.date
								).format('YYYY-MM-DD - HH:mm')}`}
							</div>
						);
						break;
					} catch (e) {
						logError(e, 'Could not parse json');
					}
				} else if (notification.message === 'invalid_grant') {
					iconName = 'exclamation-triangle';
					iconColor = 'red';
					const appName = notification.action;
					const thirdPartyName = appName.indexOf('Office 365') === 0 ? 'Office 365' : appName;
					const message = Tools.$translate('error.invalidGrant', { appName, thirdPartyName });
					childContent = <div className={cn}>{message}</div>;
					break;
				} else if (notification.status !== 1) {
					iconName = 'exclamation-triangle';
					iconColor = 'red';
				} else {
					iconName = 'info-circle';
				}

				childContent = (
					<div className={cn}>
						<i>{notification.message ? Tools.$translate(notification.message) : ''}</i>
					</div>
				);
				break;
			case NOTIFICATION_TYPES.SHARED_EASY_BOOKING:
				headingText = t(notification.action);
				iconName = 'calendar';

				try {
					const { bookingPageTitle, bookingPageId } = JSON.parse(notification.message);

					if (notification.action.includes('done')) {
						childContent = (
							<div className={cn}>
								<b>{notification.registeredBy.name}</b>{' '}
								{t('admin.sharedAppointmentAvailability.invitation.done.text')}{' '}
								<Link
									onClick={() =>
										Tools.$state.go('administration.sharedAppointmentAvailabilitySettings', {
											id: bookingPageId
										})
									}
								>
									{bookingPageTitle}
								</Link>
							</div>
						);
					} else if (notification.status) {
						childContent = (
							<div className={cn}>
								<b>{notification.registeredBy.name}</b>{' '}
								{t('admin.sharedAppointmentAvailability.invitation.default.active')}{' '}
								<Link onClick={() => Tools.$state.go('administration.appointmentAvailability')}>
									{bookingPageTitle}
								</Link>
							</div>
						);
					} else {
						childContent = (
							<div className={cn}>
								<b>{notification.registeredBy.name}</b>{' '}
								{t('admin.sharedAppointmentAvailability.invitation.default.inactive')}{' '}
								<Link onClick={() => Tools.$state.go('administration.appointmentAvailability')}>
									{t('admin.sharedAppointmentAvailability.invitation.default.inactive.link')}
								</Link>
							</div>
						);
					}
				} catch (e) {
					logError(e, `NotificationCard - ${NOTIFICATION_TYPES.SHARED_EASY_BOOKING}`);
					childContent = (
						<div className={cn}>
							<i>{notification.message ? t(notification.message) : ''}</i>
						</div>
					);
				}
				break;
			case NOTIFICATION_TYPES.DEFAULT:
				if (notification.action === 'ActivityReminder') {
					try {
						const msg = JSON.parse(notification.message);
						const { activities, message, client } = msg.reminder;
						headingText = Tools.$translate('reminders.customerContactReminder');
						iconName = 'info-circle';
						childContent = (
							<div className={cn}>
								<i>
									{message
										? Tools.$translate(message, {
												//old notifications was sent in array of ids
												number: typeof activities === 'number' ? activities : activities.length,
												customerName: client.name
										  })
										: ''}
								</i>
							</div>
						);
					} catch (e) {
						logError(e, `NotificationCard - ${NOTIFICATION_TYPES.DEFAULT}`);
						childContent = (
							<div>
								<i>{''}</i>
							</div>
						);
					}
				} else {
					headingText = this.lang.upsales;
					iconName = 'info-circle';
					childContent = (
						<div className={cn}>
							<i>{notification.message ? Tools.$translate(notification.message) : ''}</i>
						</div>
					);
				}
				break;
			case NOTIFICATION_TYPES.MAIL_CAMPAIGN:
				headingText = this.lang.mailCampaign;
				iconName = 'exclamation-triangle';
				if (notification.action === 'NO_UNSUB' && notification.mailCampaign) {
					childContent = (
						<div className={cn}>
							{Tools.$translate('groupMail.wasNotSentMissingUnsub', {
								NAME: notification.mailCampaign.name
							})}{' '}
							<a onClick={() => history.push(`/group-mail-editor/${notification.mailCampaign.id}`)}>
								{Tools.$translate('groupMail.clickHereToEdit')}
							</a>
						</div>
					);
				}
				if (notification.status === -1) {
					iconColor = 'red';
				}

				break;
			case NOTIFICATION_TYPES.MARKETING_CUSTOM:
				headingText = this.lang.marketingEvent;
				iconName = 'bullseye';
				contact = null;
				if (notification.contact) {
					contact = (
						<span>
							<b>
								<a href={this.getStateHref('contact.dashboard', { id: notification.contact.id })}>
									{notification.contact.name}
								</a>
							</b>{' '}
							{this.lang.at}{' '}
						</span>
					);
				}
				childContent = (
					<div className={cn}>
						{contact}
						<b>
							{notification.client ? (
								<a href={this.getStateHref('account.dashboard', { id: notification.client.id })}>
									{notification.client.name}
								</a>
							) : null}
						</b>{' '}
						{this.lang.hadMarketingEvent} <i>{notification.message ? notification.message : ''}</i>
					</div>
				);
				break;
			case NOTIFICATION_TYPES.SYSTEM:
				headingText = this.lang.upsales;
				if (notification.action === 'message') {
					iconName = 'robot';
					iconColor = 'orange';
				}
				if (notification.action === 'alert') {
					iconName = 'exclamation-triangle';
					iconColor = 'red';
				}
				childContent = <div className={cn}>{notification.message}</div>;
				break;
			case NOTIFICATION_TYPES.PROJECT_PLAN: {
				iconName = 'check-square-o';
				headingText = T('default.projectPlan');
				const message = JSON.parse(notification.message);
				childContent = (
					<div className={cn}>
						<span>{T('projectPlan.created') + ': '}</span>
						<Link
							onClick={() =>
								openDrawer('EditProjectPlan', {
									projectPlanId: notification.entityId
								})
							}
						>
							{message.projectName}
						</Link>
						<br />
						<span>{`${t('order.createdFromOpportunity')}: `}</span>
						<Link onClick={() => Tools.$upModal.open('editOrder', { id: message.orderId })}>
							{message.orderDescription}
						</Link>
					</div>
				);
				break;
			}
			case NOTIFICATION_TYPES.PROVISIONING:
				{
					const nameLangTag = `admin.billing.addon.${notification.message}.name`;
					const translatedAddonName = Tools.$translate(nameLangTag);
					const missingName = translatedAddonName === nameLangTag;
					// Make sure we do not show lang tag if missing name
					const addonName = missingName
						? Tools.$translate('admin.billing.addon.anAddon')
						: translatedAddonName;
					headingText = this.lang.yourAccount;
					iconName = 'tiers';
					childContent = (
						<div className={cn}>
							<Text size="sm">
								{notification.status === 100
									? Tools.$translate('admin.billing.addon.reachedNextTierOn')
									: Tools.$translate('admin.billing.addon.boutToReachNextTierOn')}{' '}
							</Text>
							<Text size="sm" bold={!missingName}>
								{addonName}
								{'. '}
							</Text>
							<Link size="sm" href={this.getStateHref('administration.billing')}>
								{Tools.$translate('admin.billing.addon.manageAddons')}
							</Link>
						</div>
					);
				}
				break;
			case NOTIFICATION_TYPES.EASY_BOOKING_INTEGRATION_ERROR:
				headingText = t('admin.appointmentavailability.notification.heading');
				iconName = 'warning';
				canView = false;
				childContent = (
					<div className={cn}>
						<p>{t('admin.appointmentavailability.notification.integrationBrokenText')}</p>
						<a href={this.getStateHref('administration.appointmentAvailability')}>
							<strong>
								{t('admin.appointmentavailability.notification.integrationBrokenGoToSettings')}
							</strong>
						</a>
					</div>
				);
				break;

			case NOTIFICATION_TYPES.MENTION: {
				//Todo fix these lang tags
				const mentioner = `${notification.registeredBy?.name || t('notificationcenter.mention.someone')}`;
				Comment.get(notification.entityId)
					.then(comment => {
						const obj = comment.data;
						let text = null;
						let params = null;
						let entity = null;
						let onClick = null;
						// We should store the source of the comment in the comment object in the future
						if (obj.client?.id) {
							text = obj.client?.name;
							params = { id: obj.client.id };
							entity = 'account.dashboard';
						}
						if (obj.contact?.id) {
							text = obj.contact?.name;
							params = { id: obj.contact.id };
							entity = 'contact.dashboard';
						}
						if (obj.opportunity?.id) {
							text = obj.opportunity?.description;
							params = { type: 'order', id: obj.opportunity.id };
							entity = 'open';
						}
						if (obj.appointment?.id) {
							text = obj.appointment?.description;
							params = { type: 'appointment', id: obj.appointment.id };
							entity = 'open';
						}
						if (obj.activity?.id) {
							text = obj.activity?.description;
							params = { type: 'activity', id: obj.activity.id };
							entity = 'open';
						}
						if (obj.ticket?.id) {
							text = obj.ticket?.title;
							onClick = () => {
								this.props.closeDrawer();
								openDrawer('EditTicket', { ticketId: obj.ticket.id });
							};
						}
						if (obj.projectPlan?.id) {
							text = obj.projectPlan?.name;
							onClick = () => {
								this.props.closeDrawer();
								openDrawer('EditProjectPlan', {
									projectPlanId: obj.projectPlan.id,
									openedFromMention: true
								});
							};
						}
						const commentLinks = document.querySelectorAll(`.notification-${notification.entityId}`);
						[...commentLinks].forEach(link => {
							if (onClick) {
								link.href = 'javascript:void(0)';
								link.onclick = onClick;
							} else {
								link.href = this.getStateHref(entity, params);
							}
							link.innerText = text || t('default.noName');
						});
					})
					.catch(() => {
						if (!this.state.errorMessage) {
							this.setState({ errorMessage: true });
						}
					});
				headingText = `${mentioner} ${t('notificationcenter.mention.you')}`;
				iconName = 'comment';
				canView = false;
				childContent = (
					<div className={cn}>
						{!this.state.errorMessage ? (
							<div>
								<p>{`${mentioner} ${t('notificationcenter.mention.comment')}`}</p>
								{notification.client ? (
									<a
										className={`notification-${notification.entityId}`}
										href={this.getStateHref('account.dashboard', { id: notification.client.id })}
									>
										<strong>{notification.client.name || t('default.noName')}</strong>
									</a>
								) : (
									<i>{t('default.removedAccount')}</i>
								)}
							</div>
						) : (
							<p className={classNames.elem('removed-content').b()}>
								{t('notificationcenter.deleted.comment')}
							</p>
						)}
					</div>
				);
				break;
			}
			case NOTIFICATION_TYPES.JOB: {
				var content = null;
				const customerId = Tools.AppService.getCustomerId();
				var udoName = notification.udo ? notification.udo.name : '';
				if (notification.action.indexOf('yourYearWithUpsales') === 0) {
					headingText = this.lang.yourYearWithUpsales;
					iconName = 'magic';
					content = (
						<Link
							onClick={() => {
								this.props.closeDrawer();
								setTimeout(() => {
									openYourYearWithUpsalesModal();
								}, 500);
							}}
						>
							{this.lang.open}
						</Link>
					);
					childContent = <div className={cn}>{content}</div>;
					break;
				}
				if (notification.name) {
					if (notification.status === -1) {
						content = (
							<span>
								{t(notification.action)} {notification.name} {this.lang.failed}
							</span>
						);
					} else if (notification.status === 100) {
						iconName = 'check-square-o';
						content = (
							<span>
								{t(notification.action)} {notification.name} {this.lang.done}
							</span>
						);
					}
				} else {
					if (notification.status === -1) {
						if (notification.action.indexOf('prospecting-save-bulk:') === 0) {
							let boxname;
							switch (notification.action) {
								case 'prospecting-save-bulk:prospecting-startups':
									boxname = t('prospecting.box.startups');
									break;
								case 'prospecting-save-bulk:prospecting-profitableCompanies':
									boxname = t('prospecting.box.profitableCompanies');
									break;
								case 'prospecting-save-bulk:prospecting-hiringAtAFastRate':
									boxname = t('prospecting.box.hiringAtAFastRate');
									break;
								case 'prospecting-save-bulk:prospecting-ai':
									boxname = t('prospecting.box.ai');
									break;
								case 'prospecting-save-bulk:prospecting-manual':
									boxname = t('prospecting.entry.prospecting.title');
									break;
								case 'prospecting-save-bulk:prospecting-similarTo':
									boxname = t('admin.prospecting');
									break;
							}
							content = (
								<span>
									{t('noti.prospecting-failed-to-add-companies-from')}
									<b>{boxname}</b>
								</span>
							);
						} else {
							content = (
								<span>
									{t('noti.' + notification.action + '-failed', {
										total: notification.message,
										name: udoName
									})}
								</span>
							);
						}
					} else if (notification.status === 100) {
						const action =
							notification.action.indexOf('prospecting-save-bulk:') === 0
								? 'prospecting-save-bulk'
								: notification.action;
						const hideAccessString = ['multi-ProspectingMatcher-Client'].includes(action);
						let accessString = '';
						if (action === 'multi-UpdateActivity-Activity') {
							accessString = Tools.AppService.getSelf().administrator
								? t('notifications.cantUpdateClosedActivities')
								: t('default.missingRights');
						}

						if (notification.hasData) {
							canView = true;
						}

						iconName = 'check-square-o';
						var splt = (notification.message || '').toString().split(':');
						if (
							!['merge-client', 'merge-contact', 'merge-activity', 'merge-appointment'].includes(
								action
							) &&
							splt &&
							splt.length === 2
						) {
							content = (
								<span>
									{t('noti.' + action + '-partial-done', {
										changed: splt[0],
										total: splt[1],
										name: udoName
									})}{' '}
									<br />
									{hideAccessString ? null : accessString}
								</span>
							);
							/* eslint-disable eqeqeq */
						} else if (notification.message == 0) {
							/* eslint-enable eqeqeq */
							content = (
								<span>
									{t('noti.' + action + '-noop-done', {
										total: notification.message,
										name: udoName
									})}{' '}
									<br />
									{hideAccessString ? null : accessString}
								</span>
							);
						} else {
							if (notification.action.indexOf('prospecting-save-bulk:') === 0) {
								let boxname;
								switch (notification.action) {
									case 'prospecting-save-bulk:prospecting-startups':
										boxname = t('prospecting.box.startups');
										break;
									case 'prospecting-save-bulk:prospecting-profitableCompanies':
										boxname = t('prospecting.box.profitableCompanies');
										break;
									case 'prospecting-save-bulk:prospecting-hiringAtAFastRate':
										boxname = t('prospecting.box.hiringAtAFastRate');
										break;
									case 'prospecting-save-bulk:prospecting-ai':
										boxname = t('prospecting.box.ai');
										break;
									case 'prospecting-save-bulk:prospecting-manual':
										boxname = t('prospecting.entry.prospecting.title');
										break;
									case 'prospecting-save-bulk:prospecting-similarTo':
										boxname = t('admin.prospecting');
										break;
								}
								content = (
									<span>
										{t('noti.prospecting-added-companies-from') + ' '}
										<b>{boxname}</b>
									</span>
								);
							} else {
								content = (
									<span>
										{t('noti.' + action + '-done', {
											total: notification.message,
											name: udoName
										})}
									</span>
								);
							}
						}
					} else {
						if (notification.action === 'prospecting-save-bulk:prospecting-manual') {
							content = <span>{t('noti.prospecting-save-bulk')}</span>;
						} else {
							content = <span>{t('noti.' + notification.action, { total: notification.message })}</span>;
						}
					}
				}
				if (notification.status === 100) {
					iconName = 'check-square-o';
					headingText = this.lang.jobComplete;
				} else if (notification.status === -1) {
					headingText = this.lang.jobFaled;
					iconName = 'times';
					iconColor = 'red';
				} else {
					iconName = 'square-o';
					headingText = this.lang.job;
				}

				canView = notification.hasData || notification.data;
				const entityId = notification.entityId;
				const entityType = entityId > 0 ? 'campaign' : 'call-list';
				const project = Math.abs(entityId);
				if (notification.action.startsWith('prospecting-save-bulk') && project && project > 0) {
					let projectRef;
					switch (entityType) {
						case 'campaign':
							projectRef = `#/${customerId}/campaigns/${project}/`;
							break;
						case 'call-list':
							projectRef = `#/todo/custom-${project}`;
							break;
					}
					content = (
						<React.Fragment>
							{content}
							<br />
							<br />
							<b>
								<a href={projectRef}>
									{entityType === 'campaign'
										? t('noti.prospecting.viewCampaign')
										: t('noti.prospecting.viewCallList')}
								</a>
							</b>
						</React.Fragment>
					);
				}
				childContent = (
					<div className={cn}>
						{content}
						{this.renderJobProgress(notification.status)}
					</div>
				);
				break;
			}
			case NOTIFICATION_TYPES.EXPORT:
				var text = null;
				headingText = this.lang.exportName;
				if (notification.name) {
					switch (notification.status) {
						case -1:
							text = (
								<span>
									{notification.name} {this.lang.export}
									{this.lang.failed}
								</span>
							);
							break;
						case 0:
							text = (
								<span>
									{notification.name} {this.lang.export}
								</span>
							);
							break;
						case 100:
							var exportSplt = notification.message ? notification.message.split(',') : [];
							text =
								exportSplt.length === 1 ? (
									<span>
										{notification.name} {this.lang.export}{' '}
										<a href={exportUrl + notification.message} target="_blank">
											{this.lang.clickDownload}
										</a>
									</span>
								) : (
									<span>
										{notification.name} {this.lang.export} {this.lang.done}{' '}
										<a>{this.lang.clickDownload}</a> {'('}
										{exportSplt.length}
										{')'}
									</span>
								);
							break;
					}
				} else {
					switch (notification.status) {
						case -1:
							text = (
								<span>
									{notification.name} {t('noti.export.' + notification.action + '-failed')}
								</span>
							);
							break;
						case 0:
							text = <span>{t('noti.export.' + notification.action)}</span>;
							break;
						case 100:
							var split = notification.message ? notification.message.split(',') : [];
							text =
								split.length === 1 ? (
									<span>
										{t('noti.export.' + notification.action + '-done')}{' '}
										<a href={exportUrl + notification.message} target="_blank">
											{this.lang.clickDownload}
										</a>
									</span>
								) : (
									<span>
										{t('noti.export.' + notification.action + '-done')}{' '}
										<a>{this.lang.clickDownload}</a> {'('}
										{split.length}
										{')'}
									</span>
								);
							break;
					}
				}

				if (notification.status === 100) {
					iconName = 'check-square-o';
				} else if (notification.status === -1) {
					canView = false;
					iconName = 'times';
					iconColor = 'red';
				} else {
					iconName = 'square-o';
				}

				canView = !!notification.message;

				childContent = (
					<div className={cn}>
						<div>{text}</div>
					</div>
				);
				break;
			case NOTIFICATION_TYPES.PROSPECTING_NEW_SIGNALS: {
				canView = false;
				headingText = t('notifications.prospectingSignals.update.title');
				iconName = 'rss';
				childContent = (
					<div className={cn}>
						<span>{t('notifications.prospectingSignals.new', { count: notification.message }) + ' '}</span>
						{notification.client ? (
							<a href={this.getStateHref('account.dashboard', { id: notification.client.id })}>
								{notification.client.name}
							</a>
						) : null}
					</div>
				);
				break;
			}
			case NOTIFICATION_TYPES.PROSPECTING_SIGNALS_GENERAL:
			case NOTIFICATION_TYPES.PROSPECTING_SIGNALS_ACCOUNTS:
			case NOTIFICATION_TYPES.PROSPECTING_SIGNALS_DIRECTORS: {
				canView = false;
				const events = notification.action.split(',');
				let clickState = '';
				let langKey = events[0];

				switch (notification.type) {
					case NOTIFICATION_TYPES.PROSPECTING_SIGNALS_ACCOUNTS:
						clickState = 'account.financial';
						iconName = 'chart-line';
						if (events.length > 1) {
							langKey = 'multipleAccounts';
						}
						break;
					case NOTIFICATION_TYPES.PROSPECTING_SIGNALS_DIRECTORS:
						clickState = 'account.contacts';
						iconName = 'user';
						break;
					case NOTIFICATION_TYPES.PROSPECTING_SIGNALS_GENERAL:
						clickState = 'account.dashboard';
						iconName = 'home';
						if (events.length > 1) {
							langKey = 'multipleGeneral';
						}
						break;
				}

				headingText = t(`notifications.prospectingSignals.${langKey}.title`);
				childContent = (
					<div className={cn}>
						{notification.client ? (
							<a
								href={this.getStateHref(clickState, { id: notification.client.id })}
								style={{ marginRight: 5 }}
							>
								{notification.client.name}
							</a>
						) : null}

						<span>{t(`notifications.prospectingSignals.${langKey}.body`)}</span>
					</div>
				);

				break;
			}
			case NOTIFICATION_TYPES.PROSPECTING_NEWS: {
				iconName = 'newspaper';
				headingText = t('notifications.prospectingNews.title');
				canView = false;
				try {
					const message = JSON.parse(notification.message);

					childContent = (
						<div className={cn}>
							<span>{t('notifications.prospectingNews.body', { count: message.newsCount }) + ' '}</span>
							{notification.client ? (
								<a
									href={this.getStateHref('account.dashboard', { id: notification.client.id })}
									style={{ marginRight: 5 }}
								>
									{notification.client.name}
								</a>
							) : (
								<span></span>
							)}
						</div>
					);
				} catch (err) {
					logError(err, `NotificationCard - ${NOTIFICATION_TYPES.PROSPECTING_NEWS}`);
					childContent = (
						<div className={cn}>
							<span>{t('notifications.prospectingNews.body', { count: 1 }) + ' '}</span>
							{notification.client ? (
								<a
									href={this.getStateHref('account.dashboard', { id: notification.client.id })}
									style={{ marginRight: 5 }}
								>
									{notification.client.name}
								</a>
							) : (
								<span></span>
							)}
						</div>
					);
				}

				break;
			}
			case NOTIFICATION_TYPES.IMPORT:
				var importText = null;
				headingText = this.lang.import;
				switch (notification.status) {
					case -1:
						importText = (
							<span>
								{notification.name} {t('noti.import-failed')}
							</span>
						);
						break;
					case 100:
						var numContacts = 0;
						var numClients = 0;
						var numProducts = 0;
						var totalSplit;
						var notiText;
						var notiButtons = [];
						switch (notification.action) {
							case 'Product':
								totalSplit = (notification.message || 'r0:pr0').split(':');
								if (totalSplit.length === 2) {
									numProducts = parseInt(totalSplit[1].replace('pr', ''));
								}
								notiText = `${numProducts} ${
									numProducts === 1 ? this.lang.product : this.lang.products
								}`;
								notiButtons = [
									{
										text: numProducts === 1 ? this.lang.showProduct : this.lang.showProducts,
										show: 'product'
									}
								];
								break;
							default:
								totalSplit = (notification.message || 'r0:co0:cl0').split(':');
								if (totalSplit.length === 3) {
									numContacts = parseInt(totalSplit[1].replace('co', ''));
									numClients = parseInt(totalSplit[2].replace('cl', ''));
								}
								notiText = `${numContacts} ${
									numContacts === 1 ? this.lang.contact : this.lang.contacts
								}, ${numClients} ${numClients === 1 ? this.lang.client : this.lang.clients}`;

								notiButtons = [
									{
										text: t('import.showContacts'),
										show: 'contact'
									},
									{
										text: t('import.showClients'),
										show: 'client'
									}
								];
						}
						var btnStyle = {
							display: 'block',
							width: '100%',
							height: '20px',
							lineHeight: '10px',
							textAlign: 'right',
							padding: '0'
						};
						importText = (
							<div>
								<div className="pull-left">
									<div>{t('noti.import-done')}</div>
									<div style={{ fontSize: '10px' }}>{notiText}</div>
								</div>
								<div className="pull-right">
									{notiButtons.map(btn => (
										<button
											type="button"
											className="up-btn btn-xs btn-bright-blue btn-link"
											style={btnStyle}
											key={btn.show}
											onClick={e => this.showImport(e, btn.show)}
										>
											{btn.text}
										</button>
									))}
								</div>
							</div>
						);
						break;
					default:
						var total = ((notification.message || 'r0').split(':')[0] || 'r0').replace('r', '');
						importText = <span>{t('noti.importingRows', { total: total })}</span>;
				}

				if (notification.status === 100) {
					iconName = 'check-square-o';
				} else if (notification.status === -1) {
					canView = false;
					iconName = 'times';
					iconColor = 'red';
				} else {
					iconName = 'square-o';
				}

				childContent = (
					<div className={cn}>
						<div>{importText}</div>
						{this.renderJobProgress(notification.status)}
					</div>
				);
		}
		return { iconName, childContent, headingText, iconColor, canView };
	}
	render() {
		const classNames = new Bem('NotificationCard');
		const { notification } = this.props;
		const { iconName, childContent, headingText, iconColor, canView } = this.notificationContent;
		const notificationTime = moment(notification.date).format('HH:mm');

		return (
			<div
				className={classNames.mod({ unread: !notification.read, link: canView }).b()}
				onClick={this.handleNotificationClick.bind(null, canView)}
			>
				<div className={classNames.elem('heading').b()}>
					<Text color="grey-11" size="sm">
						<Icon name={iconName} color={iconColor || 'grey-10'} />
						{headingText}
					</Text>
					<Text color="grey-11" size="sm">
						{notificationTime}
					</Text>
				</div>
				<div className={classNames.elem('content').b()}>{childContent}</div>
			</div>
		);
	}
}

NotificationCard.propTypes = {
	canBuyAddon: PropTypes.bool,
	trialAddons: PropTypes.array,
	requestAddon: PropTypes.func,
	closeDrawer: PropTypes.func,
	notification: PropTypes.shape({ type: PropTypes.string, action: PropTypes.string }),
	exportUrl: PropTypes.string.isRequired,
	onClick: PropTypes.func,
	showImport: PropTypes.func,
	onTabChanged: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationCard);
