import $ from 'jquery';
import React, { useEffect, useRef } from 'react';
import UpSelect from 'Components/Inputs/UpSelect';
import getAngularModule from 'App/babel/angularHelpers/getAngularModule';
import useEditOrderContext from '../hooks/useEditOrderContext';

import type { ComponentProps } from 'react';

type Props<T, S> = Omit<ComponentProps<typeof UpSelect>, 'onChange' | 'value' | 'defaultValue'> & {
	value: T;
	onChange: (item: S) => void;
	focusEvent?: string;
	focusTimeout?: number;
};

const Select = <T, S = T>({
	name,
	required,
	onChange: _onChange,
	value,
	focusEvent,
	focusTimeout = 200,
	...props
}: Props<T, S>) => {
	const { registerFormComponent, unregisterFormComponent, onFormConponentChange } = useEditOrderContext();
	const lastCommittedValueRef = useRef<S | T>(value);
	const ref = useRef<HTMLInputElement | null>(null);

	useEffect(() => {
		if (name) {
			const valid = required ? !!value : true;
			registerFormComponent(name, valid);
			return () => unregisterFormComponent(name);
		}
		// Will not add name to the dependency array because then I need something like renameControl
	}, []);

	useEffect(() => {
		if (value !== lastCommittedValueRef.current) {
			if (name) {
				const valid = required ? !!value : true;
				onFormConponentChange(name, valid, false);
			}
		}
	}, [value]);

	useEffect(() => {
		if (focusEvent) {
			const $rootScope = getAngularModule('$rootScope');
			let timeoutId: NodeJS.Timeout | null = null;

			const unsubScribe = $rootScope.$on(focusEvent, () => {
				timeoutId = setTimeout(() => {
					if (ref.current) {
						const input = $(ref.current);
						input.select2('open');
					}
				}, focusTimeout);
			});

			return () => {
				unsubScribe();
				if (timeoutId) {
					clearTimeout(timeoutId);
				}
			};
		}
	}, [focusEvent]);

	function onChange(event: { target: { added: S } }) {
		const value = (lastCommittedValueRef.current = (event.target.added ?? null) as S);

		_onChange?.(value);

		if (name) {
			const valid = required ? !!value : true;
			onFormConponentChange(name, valid, true);
		}
	}

	return (
		<UpSelect {...props} name={name} defaultValue={value} onChange={onChange} required={required} inputRef={ref} />
	);
};

export default Select;
