import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Card, CardHeader, CardContent, Icon, Text, Button, Tooltip, RadioItem, Label } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import { find, get } from 'lodash';
import ContactsAsText from '../ContactsAsText';
import { openEditAppointment } from 'Components/Modals/Appointment/EditAppointment';

import './index.scss';

class PlannedAppointments extends PureComponent {
	constructor(props) {
		super(props);

		const isMultiple = props.appointments.length > 1;
		const t = Tools.$translate;
		this.lang = {
			you: t('default.you'),
			confirmButton: t('activity.plannedAppointment.confirmButton'),
			addAppointment: t('activity.plannedAppointment.addAppointment'),
			goToAppointment: t('activity.plannedAppointment.goToAppointment'),
			markAsDone: t('activity.plannedAppointment.markAsDone')
		};

		this.state = {
			selected: isMultiple ? null : get(props.appointments, '[0].id')
		};
	}

	onAppointmentSelect = selected => {
		this.setState({ selected });
	};

	openAppointment = (event, id) => {
		event.stopPropagation();
		openEditAppointment({ id });
	};

	acceptAppointment = () => {
		const { selected } = this.state;
		const appointment = find(this.props.appointments, { id: selected });
		if (!appointment) return;
		this.props.accept(appointment.id, appointment.notes);
	};

	get appointmentsList() {
		const { appointments } = this.props;
		const isMultiple = appointments.length > 1;
		const classNames = new BemClass('PlannedAppointments');

		return appointments.map(appointment => {
			const description = (
				<div
					className={classNames.elem('description').b()}
					onClick={event => this.openAppointment(event, appointment.id)}
				>
					<Text className={classNames.elem('link').b()}>{appointment.description}</Text>
					<Text color="grey-10" size="sm">
						{moment(appointment.date).format('L LT')}
					</Text>
				</div>
			);

			return isMultiple ? (
				<RadioItem
					key={appointment.id}
					value={appointment.id}
					onChange={this.onAppointmentSelect}
					checked={appointment.id === this.state.selected}
				>
					<Label className={classNames.elem('item').b()}>
						{description}
						<ContactsAsText contacts={appointment.contacts} users={appointment.users} />
					</Label>
				</RadioItem>
			) : (
				<div key={appointment.id} className={classNames.elem('item', 'single-item').b()}>
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<Icon name="calendar" />
						{description}
					</div>
					<ContactsAsText contacts={appointment.contacts} users={appointment.users} />
				</div>
			);
		});
	}

	render() {
		const t = Tools.$translate;
		const { appointments, contactName } = this.props;
		const classNames = new BemClass('PlannedAppointments');
		const isMultiple = appointments.length > 1;
		const { selected } = this.state;
		const title = isMultiple
			? t('activity.plannedAppointment.titleForPlural', { name: contactName })
			: t('activity.plannedAppointment.titleForSingle', { name: contactName });
		const tooltipText = `• ${this.lang.addAppointment}\n• ${this.lang.markAsDone}\n• ${this.lang.goToAppointment}`;

		return (
			<Card className={classNames.b()}>
				<CardHeader size="sm" title={title} />
				<CardContent>
					<div className={classNames.elem('list').b()} onChange={this.onAppointmentSelect}>
						{this.appointmentsList}
					</div>
					<div className={classNames.elem('actions').b()}>
						<Tooltip position="bottom" title={tooltipText} distance={30}>
							<Button disabled={!selected} onClick={this.acceptAppointment}>
								{this.lang.confirmButton}
							</Button>
						</Tooltip>
					</div>
				</CardContent>
			</Card>
		);
	}
}

PlannedAppointments.propTypes = {
	appointments: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.number,
			description: PropTypes.string,
			date: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
			users: PropTypes.arrayOf(
				PropTypes.shape({
					id: PropTypes.number,
					name: PropTypes.string
				})
			),
			contacts: PropTypes.arrayOf(
				PropTypes.shape({
					id: PropTypes.number,
					name: PropTypes.string
				})
			)
		})
	),
	contactName: PropTypes.string,
	accept: PropTypes.func
};

PlannedAppointments.defaultProps = {
	appointments: [],
	accept: () => {}
};

export default PlannedAppointments;
