import React, { useState, useMemo, useRef, useEffect, useCallback } from 'react';
import _ from 'lodash';
import getAngularModule from 'App/babel/angularHelpers/getAngularModule';

import type Product from 'App/resources/Model/Product';
import type ProductCategory from 'App/resources/Model/ProductCategory';
import type { StandardFieldConfig } from 'App/resources/AllIWant';

type ProductTreeCategory = Omit<ProductCategory, 'id'> & {
	id?: number;
	$id: number;
	children: (ProductTreeCategory | Product)[];
};
type ProductTree = (Product | ProductTreeCategory)[];

type CategoryTreeCategory = ProductCategory & { children: ProductCategory[] };
type CategoryTree = CategoryTreeCategory[];

type ProductCache = { [term: string]: { results: Product[] } };

export type ProvidedProps = {
	searchableProductCustomFieldIds: number[];
	cachedProductSearches: ProductCache;
	cacheProductSearch: (term: string, result: { results: Product[] }) => void;
	searchCategories: (term: string, callback: (data: { results: ProductCategory[] }) => void) => void;
	searchProducts: (term: string, callback: (data: { results: Product[] }) => void) => void;
	categoryTree: CategoryTree;
	productTree: ProductTree;
	categories: ProductCategory[];
	isSearch: boolean;
};

type Props = {
	children: React.ReactElement<ProvidedProps>[] | React.ReactElement<ProvidedProps>;
};

function buildProductTree(categories: ProductCategory[], products: Product[]): ProductTree {
	const root: ProductTree = [];
	const foundParents: ProductTreeCategory[] = [];
	const categoryCopies = categories.map(category => _.clone(category));

	// eslint-disable-next-line you-dont-need-lodash-underscore/each
	_.each(products, product => {
		// If root
		if (!product.category || product.category.id === 0) {
			root.push(product);
		} else {
			// eslint-disable-next-line you-dont-need-lodash-underscore/find
			const parent = _.find(categoryCopies, { id: product.category.id }) as ProductTreeCategory | undefined;

			if (parent) {
				if (!parent.children) {
					foundParents.push(parent);
					parent.children = [];
				}

				parent.children.push(product);
			}
		}
	});

	if (foundParents.length) {
		findParents(categoryCopies, foundParents, root);
	}

	// Sort it recusive from the root
	return sortChildren(root);
}

// Find parents for an array of categories, recusive for the found parents
function findParents(categories: ProductCategory[], foundParents: ProductTreeCategory[], root: ProductTree) {
	const newParents: ProductTreeCategory[] = [];

	// eslint-disable-next-line you-dont-need-lodash-underscore/each
	_.each(foundParents, category => {
		// If root
		if (category.parentId === 0) {
			root.push(category);
		} else {
			// eslint-disable-next-line you-dont-need-lodash-underscore/find
			const parent = _.find(categories, (cat: any) => {
				return category.parentId === cat.id || category.parentId === cat.$id;
			}) as ProductTreeCategory | undefined;

			if (parent) {
				if (!parent.children) {
					newParents.push(parent);
					parent.children = [];
				}
				parent.children.push(category);
			}
		}

		// don't want select2 to be able to select a optgroup
		category.$id = category.id!;
		delete category.id;
	});

	if (newParents.length) {
		findParents(categories, newParents, root);
	}
}

// Recusive function for sorting down the tree children on all branches
function sortChildren(children: ProductTree): ProductTree {
	const itemsWithChildren: any = [];

	// eslint-disable-next-line local-rules/chain
	const result = _.chain(children)
		.sortBy('name')
		.sortBy('sortId')
		.filter((item: ProductTree['0']) => {
			if (item.hasOwnProperty('children') && item.children && item.children.length) {
				item.children = sortChildren(item.children);
				itemsWithChildren.push(item);
				return false;
			}
			return true;
		})
		.value();

	return itemsWithChildren.length ? result.concat(itemsWithChildren) : result;
}

function buildCategoryTree(categories: ProductCategory[]) {
	const root: CategoryTree = [];
	const categoryCopies = categories.map(category => _.clone(category));

	// eslint-disable-next-line you-dont-need-lodash-underscore/each
	_.each(categoryCopies, function (category) {
		// If root
		if (category.parentId === 0) {
			root.push(category as CategoryTreeCategory);
		} else {
			// eslint-disable-next-line you-dont-need-lodash-underscore/find
			const parent = _.find(categoryCopies, { id: category.parentId }) as undefined | CategoryTreeCategory;

			if (parent) {
				if (!parent.children) {
					parent.children = [];
				}

				parent.children.push(category);
			}
		}
	});

	return root;
}

function getTreeSize(tree: ProductTree): number {
	// eslint-disable-next-line you-dont-need-lodash-underscore/reduce
	return _.reduce(
		tree,
		(sum, productOrCategory: Product | ProductTreeCategory) => {
			const isCategory = productOrCategory.hasOwnProperty('$id');

			if (isCategory) {
				return sum + getTreeSize(productOrCategory.children);
			} else {
				return sum + 1;
			}
		},
		0
	);
}

const OrderRows = ({ children }: Props) => {
	const AppService = getAngularModule('AppService');
	const $rootScope = getAngularModule('$rootScope');
	const cachedProductSearchesRef = useRef<ProductCache>({});

	const searchableProductCustomFieldIds = useMemo(() => {
		const productCustomFields = AppService.getReadOnlyCustomFields('product');
		return _.filter(productCustomFields, { searchable: 1 }).map(customField => customField.id);
	}, []);
	const standardFields = useMemo(() => {
		const metadata = AppService.getMetadata();
		// eslint-disable-next-line you-dont-need-lodash-underscore/reduce
		return _.reduce(
			metadata.standardFields.Product,
			(standardFields, standardField) => {
				if (standardField.active && standardField.type === 'String') {
					standardFields.push(standardField);
				}
				return standardFields;
			},
			[] as StandardFieldConfig[]
		);
	}, []);
	const [categories, setCategories] = useState(() => AppService.getProductCategories());
	const [products, setProducts] = useState(() => AppService.getProducts(true, false, true));
	const [categoryTree, setCategoryTree] = useState(() => buildCategoryTree(categories));
	const [productTree, setProductTree] = useState(() => buildProductTree(categories, products));

	useEffect(() => {
		const unsubscribeHandlers = [
			$rootScope.$on('cacheRefresher.products', function () {
				const products = AppService.getProducts(true, false, true);
				const productTree = buildProductTree(categories, products);
				setProducts(products);
				setProductTree(productTree);
			}),
			$rootScope.$on('cacheRefresher.productCategories', function () {
				const categories = AppService.getProductCategories();
				const categoryTree = buildCategoryTree(categories);
				const productTree = buildProductTree(categories, products);
				setCategories(categories);
				setCategoryTree(categoryTree);
				setProductTree(productTree);
			})
		];

		return unsubscribeHandlers.forEach(unsubscribeHandler => unsubscribeHandler());
	}, [categories, products]);

	const isSearch = useMemo(() => {
		return AppService.getTotals('products') > 4000 || getTreeSize(productTree) > 2000;
	}, [productTree]);

	const searchProducts = useCallback(
		(term, callback) => {
			const searchTerm = term.toLowerCase();

			// eslint-disable-next-line you-dont-need-lodash-underscore/filter
			const filteredProducts = _.filter(products, product => {
				// eslint-disable-next-line you-dont-need-lodash-underscore/filter
				const customFields = _.filter(
					product.custom,
					customField => searchableProductCustomFieldIds.indexOf(customField.fieldId) > -1
				);

				// eslint-disable-next-line you-dont-need-lodash-underscore/some
				const customFieldMatch = _.some(customFields, customField => {
					if (typeof customField.value !== 'string') {
						return false;
					}
					return customField.value.toLowerCase().indexOf(searchTerm) !== -1;
				});

				const isMatchStandard = standardFields.some(
					({ field }) =>
						(product[field as keyof PickOfType<Product, string>] || '')
							.toLowerCase()
							.indexOf(searchTerm) !== -1
				);

				return product.name.toLowerCase().indexOf(searchTerm) !== -1 || customFieldMatch || isMatchStandard;
			}).slice(0, 500);

			// Limit res to 500
			callback({ results: buildProductTree(categories, filteredProducts) });
		},
		[categories, products, standardFields, searchableProductCustomFieldIds]
	);

	const searchCategories = useCallback(
		(term, callback) => {
			// eslint-disable-next-line you-dont-need-lodash-underscore/filter
			const res = _.filter(categories, category => {
				return category.name.toLowerCase().indexOf(term.toLowerCase()) !== -1;
			}).slice(0, 500);

			// Limit res to 500
			callback({ results: res });
		},
		[categories]
	);

	const cacheProductSearch = useCallback((term: string, result: { results: Product[] }) => {
		if (Tools.FeatureHelper.hasSoftDeployAccess('SPEED_EDIT_ORDER_PRODUCT_SEARCH')) {
			const cachedProductSearches = cachedProductSearchesRef.current;
			cachedProductSearches[term] = result;
		}
	}, []);

	const cachedProductSearches = cachedProductSearchesRef.current;

	// Maby this is not the "new" way of doing it, could be refactored to its own context or moved to the order context if I had time
	const mappedChildren = React.Children.map(children, child => {
		if (React.isValidElement(child)) {
			return React.cloneElement(child, {
				searchableProductCustomFieldIds,
				cachedProductSearches,
				cacheProductSearch,
				searchCategories,
				searchProducts,
				categoryTree,
				productTree,
				categories,
				isSearch
			});
		}
		return child;
	});

	return <>{mappedChildren}</>;
};

export default OrderRows;
