import BemClass from '@upsales/components/Utils/bemClass';
import { useTranslation } from 'Components/Helpers/translate';
import { Flex, Modal, ModalContent, ModalControls, ModalHeader, Tooltip } from '@upsales/components';
import React, { useEffect, useState } from 'react';

import './EditColumnsModal.scss';
import { ModalProps } from '../Modals/Modals';
import { asyncModalAdapter } from 'App/helpers/angularPortingHelpers';
import { hasCMWithRR, hasRRWithCM } from 'App/helpers/salesModelHelpers';
import { useFeatureAvailable, useSoftDeployAccess } from '../hooks';
import { Feature } from 'Store/actions/FeatureHelperActions';
import { useMetadata } from '../hooks/appHooks';
import { getCustomConfig, isAddress, isCategory, isCustom, parseNested } from 'App/helpers/filterHelper';
import logError from 'Helpers/logError';
import CustomField from 'App/resources/Model/CustomField';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import _ from 'lodash';

export type OnCloseReturnType = {};

type Column = {
	key: string;
	title: string;
	$translated: string;
	$selected?: boolean;
	unreleasedFeature?: string;
	locked?: boolean;
	hide?: boolean;
	field?: string;
};

type Props = ModalProps<OnCloseReturnType> & {
	maxColumns: number;
	tableType: string;
	columns: string[];
	selectables: Column[];
	customfields?: CustomField[];
	attributes?: Record<string, any>;
};

const getAttrField = (type: string, field: string) => {
	let fixedType = type;
	if (type === 'assignedLeads' || type === 'unassignedLeads') {
		fixedType = 'account';
	} else if (type.indexOf('userDefinedObject') !== -1) {
		fixedType = 'userDefinedObject';
	}

	return Tools.ListAttributes.get(fixedType).attr[field];
};

const EditColumnsModal = (props: Props) => {
	const classes = new BemClass('EditColumnsModal', props.className);
	const { t } = useTranslation();
	const maxColumns = props.maxColumns || 8;

	const [columns, setColumns] = useState<Column[]>([]);
	const [addressColumns, setAddressColumns] = useState<Column[]>([]);
	const [nestedColumns, setNestedColumns] = useState<Column[]>([]);
	const [maxColumnsSelected, setMaxColumnsSelected] = useState(false);
	const [columnTitle, setColumnTitle] = useState('');
	const [search, setSearch] = useState('');
	const [selectableColumns, setSelectableColumns] = useState<Column[]>([]);
	const [customFields, setCustomFields] = useState<Column[]>([]);

	const recurringOrderAvailable = useFeatureAvailable(Feature.RECURRING_ORDER);
	const hasNewFields = useSoftDeployAccess('NEW_FIELDS');
	const hasMailCampaignLabels = useSoftDeployAccess('MAIL_CAMPAIGN_LABELS');
	const metadata = useMetadata();
	const standardFields = metadata?.standardFields;

	const getField = props.attributes
		? (_tableType: string, key: string) => props.attributes![key] ?? null
		: getAttrField;

	useEffect(() => {
		let columnTitle = 'filters.columns.' + props.tableType;

		if (props.tableType.indexOf('userDefinedObject') === 0) {
			const udos = metadata?.params.UserDefinedObject ?? [];
			const type = props.tableType;
			const udo = udos.find(u => u.id === parseInt(type[type.length - 1]));
			if (udo) {
				columnTitle = udo.name;
			}
		}
		setColumnTitle(columnTitle);

		// Convert columns to a Set in order to remove duplicates
		const columnsSet = new Set(props.columns);
		columnsSet.forEach(key => {
			if (props.tableType !== 'mailCampaign' || key !== 'labels' || hasMailCampaignLabels) {
				let field: Column | undefined;
				if (isCustom(key)) {
					field = getCustomConfig(key, props.customfields) as any;
					setColumns(prev => [
						...prev,
						{
							key: key,
							title: field!.title!,
							$translated: t(field!.title!)
						}
					]);
					return;
				} else if (isCategory(key) || isAddress(key)) {
					field = props.selectables.find(c => c.key === key);
					if (field) {
						setColumns(prev => [
							...prev,
							{
								key: key,
								title: field!.title,
								$translated: t(field!.title)
							}
						]);
					}
					return;
				} else if (parseNested(key)) {
					setColumns(prev => [
						...prev,
						{
							key: key,
							title: parseNested(key)!.child!,
							$translated: t('nested.' + parseNested(key)?.child)
						}
					]);
					return;
				}

				const c = getField(props.tableType, key);
				if (c) {
					c.key = key;
					c.$translated = t(c.title);
					setColumns(prev => [...prev, c]);
				}
			}
		});

		fixAndOrderSelectables();

		setMaxColumnsSelected(columnsSet.size === maxColumns);
	}, []);

	const done = () => {
		if (columns.length) {
			props.close(columns.map(column => column.key));
		} else {
			//Shouldn't reach this code since button should be disabled.
			logError(new Error("Can't save without selected columns"));
		}
	};

	const add = (col: Column) => {
		if (col.$selected || columns.length >= maxColumns) {
			return;
		}

		col.$selected = true;
		const updatedColumns = [...columns, col];
		setColumns(updatedColumns);
		setMaxColumnsSelected(updatedColumns.length === maxColumns);
	};

	const remove = (col: Column) => {
		const newColumns = columns.filter(c => c.key !== col.key);

		const updateSelection = (selectables: Column[], key: string) =>
			selectables.map(item => (item.key === key ? { ...item, $selected: false } : item));

		if (isCustom(col.key)) {
			setCustomFields(prevCustomFields => updateSelection(prevCustomFields, col.key));
		} else if (isAddress(col.key)) {
			setAddressColumns(prevAddressColumns => updateSelection(prevAddressColumns, col.key));
		} else if (parseNested(col.key)) {
			setNestedColumns(prevNestedColumns => updateSelection(prevNestedColumns, col.key));
		} else {
			setSelectableColumns(prevSelectableColumns => updateSelection(prevSelectableColumns, col.key));
		}

		setColumns(newColumns);
		setMaxColumnsSelected(newColumns.length === maxColumns);
	};

	function fixAndOrderSelectables() {
		const cols: Column[] = [];
		props.selectables.forEach(selectable => {
			const index = props.columns.indexOf(selectable.key);
			const col = { ...selectable } as Column;
			col.$translated = t(selectable.title);
			col.$selected = index !== -1;

			if (isAddress(selectable.key)) {
				setAddressColumns(prev => [...prev, col]);
			} else if (parseNested(selectable.key)) {
				col.title = parseNested(selectable.key)!.child!;
				col.$translated = t('nested.' + parseNested(selectable.key)?.child);
				setNestedColumns(prev => [...prev, col]);
			} else {
				cols.push(col);
			}
		});

		if (props.customfields) {
			props.customfields.forEach(field => {
				if (!field.$hasAccess || !field.visible) {
					return false;
				}

				let key = 'Custom_' + field.id;
				if (field.nameType) {
					key = 'Custom' + field.nameType + '_' + field.id;
				}

				const index = props.columns.indexOf(key);
				setCustomFields(prev => [
					...prev,
					{
						key: key,
						title: field.name,
						$translated: t(field.name),
						$selected: index !== -1
					}
				]);
			});
		}

		const filteredCols = cols.filter(column => {
			let returnValue = true;

			if (column.unreleasedFeature === 'NEW_FIELDS') {
				if (hasNewFields) {
					let entity = props.tableType === 'account' ? 'client' : props.tableType;
					if (entity) {
						entity = entity[0].toUpperCase() + entity.substring(1);
					}
					const found = _.find(standardFields![entity], { field: column.field });

					if (!found || !found.active) {
						return false;
					}
				} else {
					return false;
				}
			}

			if (column.locked) {
				returnValue = false;
			}

			if (column.hasOwnProperty('hide') && column.hide === true) {
				returnValue = false;
			}

			const cmColumns = ['cmLast12Months', 'cmTrend12Months', 'cmPercentOfTurnover'];
			const salesModel = metadata?.params.SalesModel;

			const dontHaveCM = salesModel !== 'cm' && !hasRRWithCM();

			if (cmColumns.includes(column.field ?? '') && dontHaveCM) {
				returnValue = false;
			}

			const rrColumns = [
				'arr',
				'arrLast12Months',
				'arrPercentOfTurnover',
				'arrOneYearAgo',
				'mrr',
				'mrrLast12Months',
				'mrrPercentOfTurnover',
				'mrrOneYearAgo'
			];
			const enabledSubscriptions = recurringOrderAvailable && metadata?.params.AgreementEnabled;

			if (rrColumns.includes(column.field ?? '') && !enabledSubscriptions && !hasCMWithRR()) {
				returnValue = false;
			}

			return returnValue;
		});

		setSelectableColumns(filteredCols);
	}

	function onDrop({ destination, source }: { source: { index: number }; destination?: { index: number } }) {
		if (!destination) {
			return;
		}

		const newColumns = [...columns];
		const [draggedCol] = newColumns.splice(source.index, 1);
		newColumns.splice(destination.index, 0, draggedCol);
		setColumns(newColumns);
	}

	const filterColumnbySearch = (column: Column) => {
		return column.$translated.toLowerCase().includes(search.toLowerCase());
	};

	return (
		<Modal className={classes.b()} size="lg">
			<ModalHeader onClose={() => props.close()} title={t('filters.editColumns')} icon="edit" />

			<ModalContent>
				<div className="up-panel">
					<p>{t('filters.editModeText', { maxColumns })}</p>

					<div className="row">
						<div className="col-md-6">
							<b>{t('filters.selectedColumns')}</b>
							<DragDropContext onDragEnd={onDrop}>
								<Droppable droppableId="columns">
									{droppableProvided => (
										<div
											className="dropzone"
											{...droppableProvided.droppableProps}
											ref={droppableProvided.innerRef}
										>
											{columns.map((column, index) => (
												<Draggable
													key={`${column.key}`}
													draggableId={column.key}
													index={index}
													isDragDisabled={column.locked}
												>
													{draggableProvided => (
														<div
															className={`${
																column.locked ? 'not-draggable' : 'draggable'
															}`}
															ref={draggableProvided.innerRef}
															{...draggableProvided.draggableProps}
															{...draggableProvided.dragHandleProps}
														>
															<Flex
																justifyContent="space-between"
																alignItems="center"
																className="inner"
															>
																<div className="textContent">
																	{column.locked ? (
																		<b className="fa fa-lock"></b>
																	) : (
																		<b className="fa fa-bars handle"></b>
																	)}
																	<span>{column.$translated}</span>
																</div>
																{!column.locked ? (
																	<Tooltip
																		position="right"
																		title={t('filters.columns.remove')}
																	>
																		<b
																			className="fa fa-times remove"
																			onClick={() => remove(column)}
																		></b>
																	</Tooltip>
																) : null}
															</Flex>
														</div>
													)}
												</Draggable>
											))}
											{droppableProvided.placeholder}
										</div>
									)}
								</Droppable>
							</DragDropContext>
						</div>
						<div className="col-md-6">
							<p>
								<b>{t('default.search')}</b>
								<input
									type="text"
									className="form-control"
									value={search}
									onChange={e => setSearch(e.target.value)}
									autoFocus
								/>
							</p>

							<p>
								<i className="grey">
									{maxColumnsSelected
										? t('filters.columns.maxNrColumnsSelected')
										: t('filters.columns.clickToAdd')}
								</i>
							</p>

							{nestedColumns.length > 0 && (
								<p>
									<b>{t('columns.nested')}</b>
									{nestedColumns
										.sort((a, b) => a.$translated.localeCompare(b.$translated))
										.filter(filterColumnbySearch)
										.map(column => (
											<a
												key={column.key}
												data-test-id={`edit-columns-column-${column.title}`}
												className={maxColumnsSelected ? 'not-addable' : 'addable'}
												onClick={() => add(column)}
											>
												{column.$translated}
											</a>
										))}
								</p>
							)}

							<p>
								<b>{t(columnTitle)}</b>
								{selectableColumns
									.sort((a, b) => a.$translated.localeCompare(b.$translated))
									.filter(filterColumnbySearch)
									.map(column => (
										<a
											key={column.key}
											data-test-id={`edit-columns-column-${column.title}`}
											className={
												maxColumnsSelected || column.$selected ? 'not-addable' : 'addable'
											}
											onClick={() => add(column)}
										>
											{column.$translated}
										</a>
									))}
							</p>

							{addressColumns.length > 0 && (
								<p>
									<b>{t('address.fields')}</b>
									{addressColumns
										.sort((a, b) => a.$translated.localeCompare(b.$translated))
										.filter(filterColumnbySearch)
										.map(column => (
											<a
												key={column.key}
												data-test-id={`edit-columns-column-${column.title}`}
												className={
													maxColumnsSelected || column.$selected ? 'not-addable' : 'addable'
												}
												onClick={() => add(column)}
											>
												{column.$translated}
											</a>
										))}
								</p>
							)}

							<p>
								<b>{`${t('default.field.other')} ${t(columnTitle)}`}</b>
								{customFields
									.sort((a, b) => a.$translated.localeCompare(b.$translated))
									.filter(filterColumnbySearch)
									.map(column => (
										<a
											key={column.key}
											data-test-id={`edit-columns-column-${column.title}`}
											className={
												maxColumnsSelected || column.$selected ? 'not-addable' : 'addable'
											}
											onClick={() => add(column)}
										>
											{column.$translated}
										</a>
									))}
							</p>
						</div>
					</div>
				</div>
			</ModalContent>

			<ModalControls>
				<button
					data-test-id="edit-columns-submit"
					type="submit"
					className={`btn up-btn btn-bright-blue no-shadow btn-primary-action ${
						!columns.length ? 'disabled' : ''
					}`}
					onClick={done}
				>
					{t('default.done')}
				</button>

				<button type="button" className="btn up-btn btn-grey btn-link" onClick={() => props.close()}>
					{t('default.abort')}
				</button>
			</ModalControls>
		</Modal>
	);
};

export const openEditColumns = asyncModalAdapter({
	upModalName: 'EditColumns',
	openModalName: 'EditColumnsModal',
	featureFlag: 'REACT_EDIT_COLUMNS'
});

export default EditColumnsModal;
