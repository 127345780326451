import {
	Block,
	Button,
	Card,
	Checkbox,
	Flex,
	Icon,
	Label,
	Progressbar,
	Select,
	Table,
	TableColumn,
	TableHeader,
	TableRow,
	Text,
	Tooltip
} from '@upsales/components';
import { PrimaryButton, ThirdButton } from '@upsales/components/Buttons';
import { useEditProjectPlanContext } from 'App/babel/components/EditProjectPlan/Context';
import Activity from 'App/resources/Model/Activity';
import BemClass from '@upsales/components/Utils/bemClass';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import t from 'Components/Helpers/translate';
import TodoListTableRowQuickActions from 'App/pages/TodoList/TodoListTableRow/TodoListTableRowQuickActions';
import UserStack from 'App/components/UserStack';
import CreateTaskButton from 'App/components/CreateTaskButton/CreateTaskButton';
import './ProjectTasks.scss';
import { useTodoTypes } from 'App/components/hooks/appHooks';
import { openDrawer } from 'Services/Drawer';
import { useSoftDeployAccess } from 'App/components/hooks';
import ProjectPlanTemplateResource from 'Resources/ProjectPlanTemplate';
import ProjectPlanTemplate from 'App/resources/Model/ProjectPlanTemplate';
import logError from 'Helpers/logError';

type Props = {
	close: () => void;
};

type TodoActivity = Activity & {
	type: string;
	hot: boolean;
	weblinkUrl: string | null;
	location: string | null;
};

type ProjectPlanTemplateSelectOption = Pick<ProjectPlanTemplate, 'id'> & { title: string };
const ProjectTasks = (props: Props) => {
	const classes = new BemClass('ProjectTasks');
	const todoTypes = useTodoTypes();
	const [showClosedTasks, setShowClosedTasks] = useState(false);
	const hasProjectPlanCallsAndAppointments = useSoftDeployAccess('PROJECT_PLAN_CALLS_AND_APPOINTMENTS');
	const [templates, setTemplates] = useState<ProjectPlanTemplateSelectOption[]>([]);
	const [selectedTemplate, setSelectedTemplate] = useState<ProjectPlanTemplateSelectOption | null>(null);
	const [addingTasks, setAddingTasks] = useState(false);

	const getTemplates = async () => {
		const { data: templates } = await ProjectPlanTemplateResource.find({}, { fields: ['id', 'name'] });
		if (templates.length > 0) {
			setTemplates(templates.map(template => ({ ...template, title: template.name })));
		}
	};

	const {
		getTasks,
		onToggleTaskCheckbox,
		onToggleTaskPriority,
		openCreateTodoDrawer,
		openEditTodoDrawer,
		addTemplateTasks,
		state: { projectPlan, tasks, client, order, canEdit }
	} = useEditProjectPlanContext();

	const lang = useMemo(
		() => ({
			addTask: t('default.addTask'),
			createFirstTask: t('admin.taskSettings.createFirstTask'),
			task: t('default.task'),
			dueDate: t('admin.dueDate'),
			user: t('default.user'),
			unassigned: t('default.unassigned')
		}),
		[]
	);

	useEffect(() => {
		if (projectPlan?.id) {
			getTasks(projectPlan.id);
			getTemplates();
		}
	}, []);

	const doAddTemplateTasks = async () => {
		if (projectPlan?.id && selectedTemplate) {
			setAddingTasks(true);
			try {
				await addTemplateTasks(projectPlan.id, selectedTemplate.id);
				setSelectedTemplate(null);
			} catch (e) {
				logError(e);
			} finally {
				setAddingTasks(false);
			}
		}
	};

	const toggleShowClosedTasks = () => {
		setShowClosedTasks(!showClosedTasks);
	};

	const getTaskType = (task: Activity) => {
		if (task.activityType.id === todoTypes.TODO?.id) {
			return 'todo';
		} else if (task.activityType.id === todoTypes.PHONE_CALL?.id) {
			return 'phonecall';
		} else {
			return 'appointment';
		}
	};

	const isCompleted = (task: Activity) => {
		const type = getTaskType(task);
		return type === 'appointment' ? task.outcome === 'completed' : !!task.closeDate;
	};

	const taskTable = (taskType: Activity[], greyBackground: boolean) => {
		return taskType.map(task => {
			const type = getTaskType(task);
			const isPhonecall = type === 'phonecall';
			const isTodo = type === 'todo';
			const isAppointment = type === 'appointment';
			return (
				<TableRow
					onClick={
						isTodo
							? openEditTodoDrawer.bind(null, task)
							: isPhonecall
							? openDrawer.bind(null, 'EditPhonecall', { activity: task })
							: Tools.$upModal.open.bind(null, 'editAppointment', { id: task.id })
					}
					key={task.id}
					className={classes.elem('completed').mod({ grey: greyBackground }).b()}
				>
					<TableColumn size="lg" className={classes.elem('checkboxRow').b()}>
						<Flex>
							{isTodo ? (
								<Checkbox
									checked={!!task.closeDate}
									onClick={event => {
										event.stopPropagation();
										onToggleTaskCheckbox(task);
									}}
									size="sm"
									disabled={!canEdit}
								/>
							) : isPhonecall ? (
								<Icon name="phone" />
							) : isAppointment ? (
								<Icon name="calendar" />
							) : null}
							<Text
								space="mll"
								className={classes
									.elem('taskTitle')
									.mod({ strike: isCompleted(task) })
									.b()}
							>
								{task.description}
							</Text>
						</Flex>
					</TableColumn>
					<TableColumn>{task.date ? moment(task.date).format('YYYY-MM-DD') : ''}</TableColumn>
					<TableColumn>
						{task.users.length ? (
							<UserStack users={task.users}></UserStack>
						) : (
							<Text italic>{lang.unassigned}</Text>
						)}
					</TableColumn>
					<TableColumn align="center">
						<Flex justifyContent="flex-end">
							{!isAppointment ? (
								<Tooltip
									title={t(task.priority === 3 ? 'todo.prioTodo' : 'todo.clickToPrioCall')}
									disabled={!canEdit}
								>
									<Button
										type="link"
										color={task.priority === 3 ? 'red' : 'grey'}
										onClick={event => {
											event.stopPropagation();
											onToggleTaskPriority(task);
										}}
										disabled={!canEdit}
									>
										<Icon name="flag" />
									</Button>
								</Tooltip>
							) : null}
							{canEdit ? (
								<TodoListTableRowQuickActions
									todo={{
										...(task as TodoActivity),
										type
									}}
								/>
							) : null}
						</Flex>
					</TableColumn>
				</TableRow>
			);
		});
	};

	const columns = [{ title: lang.task }, { title: lang.dueDate }, { title: lang.user }, { title: '' }];

	const openTasks = tasks.filter(task => !isCompleted(task));
	const closedTasks = tasks.filter(task => isCompleted(task));
	const finishedTasks = closedTasks.length;

	const hasClosedTasks = finishedTasks > 0;
	const totalNumberOfTasks = tasks.length;

	return (
		<Block className={classes.b()} space="mrl pll ptxl prxl pbl">
			{hasProjectPlanCallsAndAppointments && canEdit ? (
				<>
					<Label>{t('default.addTasksFromTemplate')}</Label>
					<Flex className={classes.elem('template-select-wrapper').b()} gap="u2" space="mbxl">
						<Select
							anchor=".ProjectTasks"
							options={templates}
							value={selectedTemplate}
							onChange={value => setSelectedTemplate(value)}
							ellipsisTooltip
							placeholder={t('reminders.selectTemplate')}
						/>
						<PrimaryButton disabled={!selectedTemplate} loading={addingTasks} onClick={doAddTemplateTasks}>
							{t('default.add')}
						</PrimaryButton>
					</Flex>
				</>
			) : null}

			{tasks.length > 0 ? (
				<Flex>
					<Block className={classes.elem('column').b()}>
						<Label>
							{t('projectPlan.tasksCompleted', { completed: finishedTasks, total: totalNumberOfTasks })}
						</Label>
						<Progressbar size="sm" color="green" value={(finishedTasks / totalNumberOfTasks) * 100} />
					</Block>
					{client && canEdit ? (
						hasProjectPlanCallsAndAppointments ? (
							<CreateTaskButton
								space="mlm"
								mainAction="todos"
								createArgs={{
									client: client,
									opportunity: order ? { ...order, orderValue: order.value } : undefined,
									projectPlan: projectPlan!
								}}
								availableActions={['todos', 'phonecalls', 'appointments']}
							/>
						) : (
							<Block space="mlm">
								<PrimaryButton size="sm" onClick={openCreateTodoDrawer}>
									<Icon space="mrs" name="plus" />
									{lang.addTask}
								</PrimaryButton>
							</Block>
						)
					) : null}
				</Flex>
			) : canEdit ? (
				<Card space="mtxl plxl" color={'light-grey'}>
					<Flex space="pll" justifyContent="space-between" align-items="center">
						<Block space="ptxl">
							<Text space="mtm mbxl" size="xl">
								{lang.createFirstTask}
							</Text>
							{hasProjectPlanCallsAndAppointments ? (
								<CreateTaskButton
									mainAction="todos"
									createArgs={{
										client: client!,
										opportunity: order ? { ...order, orderValue: order.value } : undefined,
										projectPlan: projectPlan!
									}}
									availableActions={['todos', 'phonecalls', 'appointments']}
								/>
							) : (
								<Button onClick={openCreateTodoDrawer}>
									<Icon space="mrm" name="plus" />
									{lang.addTask}
								</Button>
							)}
						</Block>
						<Block>
							<img src={'img/upsales-todo.gif'} alt="todo-gif" />
						</Block>
					</Flex>
				</Card>
			) : null}
			{tasks.length > 0 ? (
				<Card space="mtxl mbxl">
					<Table>
						<TableHeader columns={columns}></TableHeader>
						{taskTable(openTasks, false)}
						{hasClosedTasks ? (
							<TableRow className={classes.elem('completed').mod({ grey: showClosedTasks }).b()}>
								<TableColumn size="lg" colSpan={4} align="center">
									<ThirdButton onClick={toggleShowClosedTasks}>
										{!showClosedTasks
											? t('projectPlan.tasksCompleted.show', {
													total: closedTasks.length
											  })
											: t('projectPlan.tasksCompleted.hide')}
										<Icon
											name={'angle-down'}
											className={classes.elem('icon').mod({ rotate: showClosedTasks }).b()}
										/>
									</ThirdButton>
								</TableColumn>
							</TableRow>
						) : null}
						{showClosedTasks ? taskTable(closedTasks, true) : null}
					</Table>
				</Card>
			) : null}
		</Block>
	);
};

export default ProjectTasks;
