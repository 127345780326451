import RequestBuilder from 'Resources/RequestBuilder';
import type { MultiSelect } from 'App/components/ListView/ListViewRenderHelpers';
import { openMultiUpdateActivity } from 'App/components/MultiActionModal/MultiUpdateActivity/openMultiUpdateActivity';

export default function updateActivities(rb: RequestBuilder, entity: string, multiSelect: MultiSelect) {
	const opts = {
		customerId: Tools.AppService.getCustomerId(),
		filters: rb,
		entity,
		multiSelect
	};
	return openMultiUpdateActivity(opts);
}
