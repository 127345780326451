import { CustomFieldFilterConfig, FilterConfig, FilterConfigRaw } from 'App/babel/filterConfigs/FilterConfig';
import CustomField, { EntityCustomField } from 'App/resources/Model/CustomField';
import { insertAfter } from 'App/babel/store/helpers/array';
import _ from 'lodash';
import { getLoggedInUserFilter } from 'App/babel/filterConfigs/filterGenerators';
import logError from 'Helpers/logError';
import { ListViewFilter } from 'App/resources/AllIWant';
import UserRoleTree from 'Components/Helpers/UserRoleTree';
import RequestBuilder from 'Resources/RequestBuilder';
import filterParse from 'App/upsales/common/components/listFilters/filterParse';
import ComparisonTypes from 'Resources/ComparisonTypes';
import { ComparisonTypeName } from 'Resources/ComparisonTypes';
import buildCustomFilter from 'App/upsales/common/components/listFilters/buildCustomFilter';
import moment from 'moment';
import { BuildFilters, Filter, GroupFilter, OrFilter } from 'Resources/RequestBuilder';
import { Attr } from 'App/babel/attributes/Attribute';

export const isCustom = (filterName: string) => {
	return filterName.indexOf('Custom_') !== -1 || filterName.indexOf('CustomAgreement_') !== -1;
};

export const isAddress = (filterName: string) => {
	return filterName.indexOf('Address_') !== -1;
};

export const getCFFieldIdFromFilterName = (filterName: string) => {
	if (!isCustom(filterName)) {
		return null;
	}
	const fieldId = parseInt(filterName.split('_')[1]);
	return isNaN(fieldId) ? null : fieldId;
};

export const isCategory = (filterName: string) => {
	return filterName.endsWith('Category');
};

export const isCustomCategory = (filterName: string) => {
	return filterName.indexOf('Category_') !== -1;
};

export const getCategoryTypeIdFromFilterName = (filterName: string) => {
	if (isCategory(filterName)) {
		return 0;
	}
	if (isCustomCategory(filterName)) {
		const fieldId = parseInt(filterName.split('_')[1]);
		return isNaN(fieldId) ? null : fieldId;
	}
	return null;
};

type GetCustomFieldConfigOpts = {
	name?: string;
	fieldId?: number;
	entityType?: string;
	field?: CustomField | null;
};

export const getCustomFieldConfig = function (options: GetCustomFieldConfigOpts = {}) {
	const config: CustomFieldFilterConfig = {
		type: 'custom',
		inputType: 'custom',
		filterName: options.name,
		field: options.fieldId ? options.fieldId + '' : undefined,
		parent: 'default.field.other'
	};

	if (options.entityType) {
		config.entity = options.entityType;
	}

	if (options.field) {
		const cf = options.field;

		if (config.entity === 'agreement') {
			if (cf.nameType === 'Agreement') {
				config.field = 'metadata.custom';
				config.fieldOverride = 'metadata.custom';
				config.comparisonType = 'Wildcard';
				config.filterName = 'CustomAgreement_' + cf.id;
				config.parent = 'order.periodAndBillingOther';
			} else {
				config.parent = 'order.orderDetailsOther';
			}
		}

		config.title = cf.name;
		config.$field = cf;

		switch (cf.datatype) {
			case 'String':
			case 'Text':
			case 'Link':
			case 'Email':
				config.displayType = 'text';
				break;
			case 'Boolean':
				config.displayType = 'radio';
				config.options = [
					{ text: 'default.all', inactive: true },
					{ text: 'default.with', value: true, comparisonType: 'Equals' },
					{ text: 'default.without', value: false, comparisonType: 'Equals' }
				];

				break;
			case 'Checkbox':
				config.displayType = 'radio';

				break;
			case 'Currency':
			case 'Percent':
			case 'Integer':
			case 'Discount':
			case 'Calculation':
				config.displayType = 'range';

				break;
			case 'Date': {
				config.presets = [
					'whenever',
					'currentDay',
					'lastDay',
					'currentWeek',
					'lastWeek',
					'currentMonth',
					'lastMonth',
					'currentQuarter',
					'lastQuarter',
					'currentYear',
					'lastYear',
					'last7days',
					'last14days',
					'last30days',
					'lastXdays',
					'custom'
				];

				const brokenFiscalYearEnabled =
					Tools.AppService.getMetadata().params.brokenFiscalYearEnabled &&
					Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.BROKEN_FISCAL_YEAR);

				if (brokenFiscalYearEnabled) {
					insertAfter(config.presets, 'currentQuarter', 'currentFiscalQuarter');
					insertAfter(config.presets, 'lastQuarter', 'lastFiscalQuarter');
					insertAfter(config.presets, 'currentYear', 'currentFiscalYear');
					insertAfter(config.presets, 'lastYear', 'lastFiscalYear');
				}

				config.displayType = 'dateRange';

				break;
			}
			case 'Time':
				config.displayType = 'time';

				break;
			case 'MultiSelect':
			case 'Select':
				config.displayType = 'listLazy';

				config.inputType = 'selectGroup';

				config.dataPromise = async () => {
					const data = (options.field?.default as []).map((opt, i) => ({ id: opt, name: opt }));

					return { data };
				};

				config.resource = function () {
					return function (customerId, selectedValues) {
						const items = selectedValues.map(function (selectedValue) {
							const name = Array.isArray(cf?.default)
								? cf?.default?.find(cfValue => cfValue.toLowerCase() === selectedValue) || selectedValue
								: selectedValue;
							return { id: selectedValue, name };
						});

						return Promise.resolve({ data: items });
					};
				};

				config.searchFn = function () {
					let myData: { id: string; name: string }[];

					/* Should this not filter on term before slicing to? */
					return function (term, fields, offset, limit) {
						if (myData) {
							let filtered = myData;
							if (term) {
								filtered = _.filter(myData, function (item) {
									return item.name && item.name.toLowerCase().indexOf(term.toLowerCase()) !== -1;
								});
							}
							return Promise.resolve({
								data: filtered.slice(offset, offset + limit),
								metadata: {
									total: filtered.length,
									offset: offset,
									limit: limit
								}
							});
						}

						return Tools.Lookup.customer(Tools.AppService.getCustomerId())
							.setType(options.entityType)
							.findCustomValues(cf.id, term, 10000, config.fieldOverride)
							.then(function (res) {
								const items = _.map(res.data, function (val) {
									const name = Array.isArray(cf?.default)
										? cf?.default?.find(cfValue => cfValue.toLowerCase() === val.value) || val.value
										: val.value;
									return { id: val.value, name };
								});
								if (!myData) {
									myData = items;
								}
								return {
									data: items.slice(offset, offset + limit),
									metadata: {
										total: items.length,
										offset: offset,
										limit: limit
									}
								};
							});
					};
				};

				break;
			case 'User': {
				const activeUsers = Tools.AppService.getActiveUsers();

				config.displayType = 'listLazy';

				config.inputType = 'selectGroup';

				config.dataPromise = async () => {
					const data = UserRoleTree({ accessType: null });

					data.unshift(getLoggedInUserFilter());

					return { data };
				};

				config.resource = function () {
					return function (customerId, selectedValues) {
						const items = selectedValues.map(function (selectedValue) {
							if (selectedValue === 'self') {
								return {
									id: 'self',
									name: Tools.AppService.getSelf().name
								};
							}

							return {
								id: selectedValue,
								name: activeUsers.find(function (user) {
									return user.id === Number(selectedValue);
								})?.name
							};
						});

						return Promise.resolve({ data: items });
					};
				};

				config.searchFn = function () {
					let myData: { id: string; name: string }[];

					/* Should this not filter on term before slicing to? */
					return function (term, fields, offset, limit) {
						if (myData) {
							let filtered = myData;
							if (term) {
								filtered = _.filter(myData, function (item) {
									return item.name && item.name.toLowerCase().indexOf(term.toLowerCase()) !== -1;
								});
							}
							return Promise.resolve({
								data: filtered.slice(offset, offset + limit),
								metadata: {
									total: filtered.length,
									offset: offset,
									limit: limit
								}
							});
						}

						return Tools.Lookup.customer(Tools.AppService.getCustomerId())
							.setType(options.entityType)
							.findCustomValues(cf.id, term, 10000, config.fieldOverride)
							.then(function (res) {
								const items = [];
								_.forEach(res.data, function (fieldUser) {
									const activeUser = activeUsers.find(function (user) {
										return user.id === Number(fieldUser.value);
									});
									if (activeUser) {
										items.push({
											id: fieldUser.value,
											name: activeUser.name
										});
									}
								});

								items.unshift(getLoggedInUserFilter() as (typeof myData)[0]);

								if (!myData) {
									myData = items;
								}
								return {
									data: items.slice(offset, offset + limit),
									metadata: {
										total: items.length,
										offset: offset,
										limit: limit
									}
								};
							});
					};
				};

				break;
			}
			case 'Users': {
				const activeUsers = Tools.AppService.getActiveUsers();

				config.displayType = 'listLazy';

				config.resource = function () {
					return function (customerId, selectedValues) {
						const items = selectedValues.map(function (selectedValue) {
							return {
								id: selectedValue,
								name: activeUsers.find(function (user) {
									return user.id === Number(selectedValue);
								})?.name
							};
						});

						return Promise.resolve({ data: items });
					};
				};

				config.searchFn = function () {
					let myData: { id: string; name: string }[];

					/* Should this not filter on term before slicing to? */
					return function (term, fields, offset, limit) {
						if (myData) {
							let filtered = myData;
							if (term) {
								filtered = _.filter(myData, function (item) {
									return item.name && item.name.toLowerCase().indexOf(term.toLowerCase()) !== -1;
								});
							}
							return Promise.resolve({
								data: filtered.slice(offset, offset + limit),
								metadata: {
									total: filtered.length,
									offset: offset,
									limit: limit
								}
							});
						}

						return Tools.Lookup.customer(Tools.AppService.getCustomerId())
							.setType(options.entityType)
							.findCustomValues(cf.id, term, 10000, config.fieldOverride)
							.then(function (res) {
								let items: { id: string; name: string }[] = [];

								_.forEach(res.data, function (val) {
									const valueArray = val.value.split(',');
									_.forEach(valueArray, function (v) {
										items.push({
											id: v,
											name:
												activeUsers.find(function (user) {
													return user.id === Number(v);
												})?.name || ''
										});
									});
								});

								items = _.uniq(items, 'id');

								if (!myData) {
									myData = items;
								}
								return {
									data: items.slice(offset, offset + limit),
									metadata: {
										total: items.length,
										offset: offset,
										limit: limit
									}
								};
							});
					};
				};

				break;
			}
			default:
				config.displayType = 'text';
				break;
		}
	}

	return config;
};

export const getCustomConfig = (filterName: string, customFields: CustomField[] = [], entityType?: string) => {
	const fieldId = getCFFieldIdFromFilterName(filterName);

	let field;
	if (filterName && filterName.indexOf('CustomAgreement_') === 0) {
		field = _.find(customFields, { id: fieldId, nameType: 'Agreement' });
	} else {
		field = _.find(customFields, function (cf) {
			return cf.nameType !== 'Agreement' && cf.id === fieldId;
		});
	}

	return getCustomFieldConfig({ name: filterName, field, fieldId: fieldId || undefined, entityType });
};

export const getConfig = (
	filterName: string,
	filterConfigs: {
		[name: string]: FilterConfig<any>;
	},
	customFields?: CustomField[],
	entityType?: string
): FilterConfig<any> | null => {
	let config;
	if (customFields && isCustom(filterName)) {
		config = getCustomConfig(filterName, customFields);
	} else {
		config = filterConfigs?.[filterName] || null;
	}

	if (!config) {
		logError(new Error('Missing filter config'), 'Missing filter config', { filterName });
		return null;
	}

	let comparisonType: string | undefined;
	if (config.isExcludeFilter) {
		comparisonType = 'NotEquals';
	} else if (config.type === 'custom' || config.type !== 'raw') {
		comparisonType = (config as CustomFieldFilterConfig).comparisonType;
	}

	// Some filters, especially raw, has their functions on the prototype
	if (config) {
		const prototypeDescriptors = Object.getOwnPropertyDescriptors(Object.getPrototypeOf(config));
		const proto = Object.create(null, prototypeDescriptors);
		return Object.setPrototypeOf({ ...config, filterName, comparisonType }, proto);
	}
	return null;
};

// This is not a 1-1 port of the one in the old FilterHelper
export const isInactiveValue = (filter: ListViewFilter, filterConfig: FilterConfig) => {
	// Raw filters
	if (filterConfig.type === 'raw') {
		if (filterConfig.isInactive) {
			return filterConfig.isInactive(filter);
		} else {
			return filter.inactive;
		}
	}

	// Empty multi selects
	if (Array.isArray(filter.value) && !filter.value.length) {
		return true;
	}

	const filterType = filterConfig.displayType || filterConfig.type;

	// Inactive booleans
	if (filterType === 'boolean') {
		return filter.value === null;
	}

	if (filterType === 'radio') {
		return filter.value === null;
	}

	const isEmptyRange = (value?: string | null) => [undefined, null, ''].includes(value);

	// Empty range filters
	if (
		['date', 'integer', 'calculation', 'currency', 'discount', 'percent', 'dateRange', 'range', 'time'].includes(
			filterType
		)
	) {
		if (typeof filter.value === 'object' && !Array.isArray(filter.value)) {
			return (
				['whenever', 'custom', 'pastXdays'].includes(filter.value?.preset) &&
				isEmptyRange(filter.value?.start) &&
				isEmptyRange(filter.value?.end)
			);
		} else {
			return true;
		}
	}

	// Empty text filters and other filters
	if (filter.value === '' || !filter.value) {
		return true;
	}

	return false;
};

export const getConfigName = function (filterName: string) {
	// This code was copied from less typed angular service, that is why we need to check for typeof
	if (typeof filterName === 'string') {
		return filterName.indexOf('_fix_') > -1 ? filterName.substring(0, filterName.indexOf('_fix_')) : filterName;
	} else {
		return '';
	}
};

export const getFieldWithPrefix = function (prefix?: string | null) {
	return function (field: string) {
		var pre = prefix ? prefix + '.' : '';
		return { field: pre + field };
	};
};

type ParseFiltersOptions = {
	// This is required for now, when the angular service default getConfig is moved we can make it optional and fall back to that.
	// Or maybe we should never do that since it relies in all attributes being loaded in one big object.
	getConfig: (filterName: string, type: string) => FilterConfig | null;
	// getConfig?: (filterName: string, type: string) => FilterConfig,
	useTags?: boolean;
	groupAllFilters?: boolean;
	idListIsInactive?: boolean;
};

function isRaw(filterConfig: FilterConfig, type: any): filterConfig is FilterConfigRaw {
	return type === 'raw';
}

export const parseFilters = (
	filters: { [key: string]: ListViewFilter },
	type: string,
	rb: RequestBuilder | null,
	fieldPrefix: string | null,
	options: ParseFiltersOptions = { getConfig: () => null }
) => {
	const requestBuilder = rb || new RequestBuilder();

	const categories: { [key: string]: { field?: string | null; value: any[] } } = {};
	let prefix = '';
	// This is required for now, when the angular service default getConfig is moved we can fallback to that.
	// Or maybe we should never do that since it relies in all attributes being loaded in one big object.
	const getConfigFn = options.getConfig;
	// const getConfigFn = options.getConfig || getConfig;

	if (fieldPrefix) {
		prefix = fieldPrefix + '.';
	}

	Object.keys(filters).forEach(filterName => {
		const filter = filters[filterName];
		const configName = getConfigName(filterName);
		const filterConfig = getConfigFn(configName, type);

		// custom filters are already grouped so need to wrap them.
		const useLocalBuilder = _.get(options, 'groupAllFilters', false) && !isCustom(configName);
		const localBuilder = useLocalBuilder ? requestBuilder.groupBuilder() : requestBuilder;

		// If we do not find the filter we skip it
		if (!filterConfig) {
			return;
		}

		const field = prefix + filterConfig.field;

		// switch filter type
		const configType = filter.forceType || filterConfig.type;
		switch (configType) {
			// RAW-filter (can do whatever the fuck it wants :D )
			case 'raw':
				if (isRaw(filterConfig, configType) && filterConfig.build && typeof filterConfig.build === 'function') {
					// Run the filterConstructor function
					filterConfig.build(
						filter,
						localBuilder,
						getFieldWithPrefix(fieldPrefix),
						options.useTags,
						filters,
						getConfigFn
					);
				}
				break;

			// list
			case 'idList':
			case 'list':
			case 'listShort':
				if (isCategory(filterName) && filter.value && filter.value.length) {
					if (filter.comparisonType) {
						if (!categories[filter.comparisonType]) {
							// Should this not be filterConfig.field??? I don't dare to change
							categories[filter.comparisonType] = { field: filter.field, value: [] };
						}
						categories[filter.comparisonType].value.push(filter.value);
					}
				} else if (
					!filter.inactive &&
					!(filterConfig.isExcludeFilter && options?.idListIsInactive) &&
					filter.comparisonType
				) {
					filterParse.list(localBuilder, field, filter.value, ComparisonTypes[filter.comparisonType]);
				}
				break;

			// normal
			case 'text':
			case 'normal':
				if (filter.comparisonType) {
					filterParse.string(localBuilder, field, filter.value, ComparisonTypes[filter.comparisonType]);
				}
				break;

			case 'boolean':
				filterParse.boolean(localBuilder, field, filter.value);
				break;

			case 'radio':
				if (!filter.inactive) {
					var comparisonType = filter.comparisonType
						? ComparisonTypes[filter.comparisonType]
						: ComparisonTypes.Equals;
					localBuilder.addFilter({ field: field }, comparisonType, filter.value);
				}
				break;

			// not equals null (only for bool filterType)
			case 'notNull':
				if (filter.value) {
					localBuilder.addFilter({ field: field }, ComparisonTypes.NotEquals, null);
				} else {
					// This feels kinda inverted if you think of the naming
					if (filterConfig.inactiveOnTrue) {
						// Do nothing.
					} else {
						localBuilder.addFilter({ field: field }, ComparisonTypes.Equals, null);
					}
				}
				break;

			// not equals 0 (only for bool filterType)
			case 'notZero':
				if (filter.value) {
					localBuilder.addFilter({ field: field }, ComparisonTypes.NotEquals, 0);
				} else {
					localBuilder.addFilter({ field: field }, ComparisonTypes.Equals, 0);
				}
				break;

			// not equals empty array (only for bool filterType)
			case 'notEmptyArray':
				if (filter.value) {
					localBuilder.addFilter({ field: field }, ComparisonTypes.NotEquals, []);
				} else {
					localBuilder.addFilter({ field: field }, ComparisonTypes.Equals, []);
				}
				break;

			// range
			case 'dateRange':
			case 'datePreset':
				filterParse.dateRange(localBuilder, field, filter.value, null, options.useTags);

				if (filter.value && filter.value.status) {
					switch (filter.value.status) {
						case 'open':
							// @ts-expect-error unsure what's true here. The Attr['type'] enum uses capitalized strings
							localBuilder.addFilter({ field: 'closeDate', type: 'date' }, ComparisonTypes.Equals, null);
							localBuilder.addFilter(
								// @ts-expect-error
								{ field: 'isAppointment', type: 'boolean' },
								ComparisonTypes.Equals,
								false
							);
							break;
						case 'closed':
							localBuilder.addFilter(
								// @ts-expect-error
								{ field: 'closeDate', type: 'date' },
								ComparisonTypes.NotEquals,
								null
							);
							localBuilder.addFilter(
								// @ts-expect-error
								{ field: 'isAppointment', type: 'boolean' },
								ComparisonTypes.Equals,
								false
							);
							break;
					}
				}
				break;

			case 'range':
				filterParse.range(localBuilder, field, filter.value);
				break;

			// custom field filter
			case 'custom':
				buildCustomFilter(
					filter,
					filterName,
					filterConfig as CustomFieldFilterConfig,
					type,
					localBuilder,
					prefix,
					options
				);
				break;
		}

		if (useLocalBuilder) {
			const groupFilter = localBuilder.getQuery();
			const innerFilters = _.get(groupFilter, 'group.q[0]', []);
			if (innerFilters.length) {
				if (innerFilters.length === 1 && !(groupFilter as GroupFilter)?.group?.not) {
					requestBuilder.queryArray.push(innerFilters[0]);
				} else {
					localBuilder.done();
				}
			}
		}

		Object.keys(categories).forEach(comparisonType => {
			const cat = categories[comparisonType];
			filterParse.list(
				requestBuilder,
				prefix + cat.field,
				_.flatten(cat.value),
				ComparisonTypes[comparisonType as ComparisonTypeName]
			);
		});
	});

	return requestBuilder;
};

export const parseNested = (str: string) => {
	const re = /([A-z]{1,}[.][a-z]{1,})_(\d{0,}).(\w{1,})/;
	const m = re.exec(str);
	let parent, parentId, child;

	if (m !== null) {
		if (m.index === re.lastIndex) {
			re.lastIndex++;
		}
		parent = m[1];
		parentId = parseInt(m[2]);
		child = m[3];
		if (parent && parentId && child) {
			return {
				parent: parent,
				parentId: parentId,
				child: child
			};
		}
	}

	return null;
};

// From ui/app/upsales/common/helpers/filterType.js
const FilterType = {
	Boolean: 'Boolean',
	String: 'String',
	Number: 'Number',
	Float: 'Float',
	Email: 'Email',
	Date: 'Date',
	Object: 'Object'
};

// Port of FilterHelper.match (ui/app/upsales/common/helpers/filterHelper.js) and its helper functions
// The old version wasn't finished and this one isn't either.
// It's currently only used for custom field filters and is not tested for regular filters.
const matchValues = function (objVal: any, filterVal: any, op: string, attr: any) {
	let match = false;
	let mVal: any;
	let mFilt: any;

	if (attr.type === FilterType.Date) {
		mFilt = moment(filterVal);
		mVal = moment(objVal);
	}

	if (typeof objVal === 'string') {
		objVal = objVal.toLowerCase();
	}
	if (typeof filterVal === 'string') {
		filterVal = filterVal.toLowerCase();
	}

	switch (op) {
		default:
		case 'eq':
			if (attr.type === FilterType.Date && moment(objVal).isValid()) {
				match = mVal.isSame(mFilt, 'day');
			} else if (Array.isArray(filterVal)) {
				match = filterVal.indexOf(objVal) !== -1;
			} else {
				// eslint-disable-next-line eqeqeq
				match = objVal == filterVal; // no triple operator here 0 needs to match false
			}
			break;

		case 'ne':
			if (Array.isArray(filterVal)) {
				match = filterVal.indexOf(objVal) === -1;
			} else {
				// eslint-disable-next-line eqeqeq
				match = objVal != filterVal; // no triple operator here 0 needs to match false
			}
			break;

		case 'lte':
			if (attr.type === FilterType.Date) {
				match = mVal.isSame(mFilt, 'day') || mVal.isBefore(mFilt, 'day');
			} else {
				match = objVal <= filterVal;
			}
			break;

		case 'lt':
			if (attr.type === FilterType.Date) {
				match = mVal.isBefore(mFilt, 'day');
			} else {
				match = objVal < filterVal;
			}
			break;

		case 'gte':
			if (attr.type === FilterType.Date) {
				match = mVal.isSame(mFilt, 'day') || mVal.isAfter(mFilt, 'day');
			} else {
				match = objVal >= filterVal;
			}
			break;

		case 'gt':
			if (attr.type === FilterType.Date) {
				match = mVal.isAfter(mFilt, 'day');
			} else {
				match = objVal > filterVal;
			}
			break;
		case 'src':
			if (Array.isArray(filterVal)) {
				match = filterVal.some(val => ('' + objVal).toLowerCase().indexOf(val.toLowerCase()) !== -1);
			} else {
				match = ('' + objVal).toLowerCase().indexOf(filterVal.toLowerCase()) !== -1;
			}
			break;
		case 'wc':
			match = ('' + objVal).toLowerCase().indexOf(filterVal.toLowerCase()) !== -1;
			break;
		case 'wce':
			match = ('' + objVal).toLowerCase().endsWith(filterVal.toLowerCase());
			break;
	}

	// Good for debug do not remove
	// console.log('matching', objVal, 'against', filterVal, 'op:', op, 'match:', match);

	return match;
};

const matchFilter = function (filter: any, obj: any, attrs: any, throwIfNoAttr?: boolean) {
	let superattr: any;
	_.forEach(attrs, function (subAttr) {
		if (superattr) {
			return;
		}

		if (subAttr.field === filter.a) {
			superattr = subAttr;
			return;
		}
		if (subAttr.hasOwnProperty('attr')) {
			superattr = _.find(_.values(subAttr.attr), { field: filter.a });
			if (superattr) {
				superattr.parent = subAttr.parent;
				return;
			}
		}
		superattr = null;
	});

	if (!superattr) {
		if (console) {
			console.warn('No such attr in filter:', filter);
		}
		if (throwIfNoAttr) {
			throw new Error('No such attr in filter');
		}
		return false;
	}

	if (superattr.hasOwnProperty('parent')) {
		const split = filter.a.split('.');
		const parent = superattr.parent;
		let match = false;

		// Remove first split element if it is the same as the parent, for example
		//   contact uses users.id instead of client.users.id, where client is the parent
		if (split[0] === superattr.parent) {
			split.shift();
		}

		if (Array.isArray(parent)) {
			for (const sub of parent) {
				if (matchValues(sub[split[split.length - 1]], filter.v, filter.c, superattr)) {
					match = true;
				}
			}
			return match;
		} else {
			if (parent) {
				if (Array.isArray(parent[split[0]])) {
					for (const sub of parent[split[0]]) {
						if (matchValues(sub[split[split.length - 1]], filter.v, filter.c, superattr)) {
							match = true;
						}
					}
					return match;
				} else {
					return matchValues(parent[split[0]], filter.v, filter.c, superattr);
				}
			} else {
				return matchValues(null, filter.v, filter.c, superattr);
			}
		}
	} else {
		return matchValues(filter.a, filter.v, filter.c, superattr);
	}
};

const groupIsCustom = function (filterGroup: any) {
	var idFilterIndex = _.find(filterGroup, { a: 'custom.fieldId' });
	if (idFilterIndex) {
		return true;
	}
	return false;
};

const matchCustomFilter = function (filterGroup: any[], obj: { custom: EntityCustomField[] }) {
	// Remove id filter to get value filter
	var filters = _.cloneDeep(filterGroup);
	var idFilter = _.remove(filters, { a: 'custom.fieldId' });

	// If we did find idFilter and value filter we match it
	if (idFilter && filters[0] && obj.custom) {
		const results = filters.map(filter => {
			var objValue = _.find(obj.custom, { fieldId: Number(idFilter[0].v) });
			if (objValue) {
				let type;
				switch (filters[0].a) {
					case 'custom.valueDouble':
					case 'custom.valueInteger':
						type = FilterType.Number;
						break;
					case 'custom.valueDate':
						type = FilterType.Date;
						break;
					case 'custom.valueBoolean':
						type = FilterType.Boolean;
						break;
					default:
						type = 'string';
				}
				return matchValues(objValue.value, filter.v, filter.c, { type: type });
			}
			return false;
		});
		return results.every(result => result);
	}
	return false;
};

export const match = (filters: BuildFilters['q'], obj: any, attrs: { [k: string]: Attr }, throwIfNoAttr?: boolean) => {
	if (!filters || !filters.length) {
		return true;
	}

	// TODO: Put these type checks in RequestBuilder instead once it's ported to TS
	const isOrFilter = (filter: Filter | OrFilter | GroupFilter): filter is OrFilter => filter.hasOwnProperty('or');
	const isGroupFilter = (filter: Filter | OrFilter | GroupFilter): filter is GroupFilter =>
		filter.hasOwnProperty('group');

	let isMatch = true;

	for (const filter of filters) {
		if (isOrFilter(filter)) {
			const orFilters = filter.or;
			let orMatch = false;
			if (!orFilters.q || !orFilters.q.length) {
				orMatch = true;
			} else {
				for (const orFilter of orFilters.q) {
					if (!orFilter || !orFilter.length) {
						orMatch = true;
					} else {
						let matches = 0;
						for (const of of orFilter) {
							if (
								(isGroupFilter(of) && match([of], obj, attrs)) ||
								(!isGroupFilter(of) && matchFilter(of, obj, attrs, throwIfNoAttr))
							) {
								matches++;
							}
						}
						// console.log('OR', matches, '/', orFilter.length);
						if (matches === orFilter.length) {
							orMatch = true;
						}
					}
				}
				if (!orMatch) {
					isMatch = false;
				}
			}
		} else if (isGroupFilter(filter)) {
			const groupFilter = filter.group;
			let groupMatch = false;
			if (!groupFilter.q || !groupFilter.q.length) {
				groupMatch = true;
			} else {
				for (const gf of groupFilter.q) {
					if (!gf || !gf.length) {
						groupMatch = true;
					} else {
						if (groupIsCustom(gf)) {
							groupMatch = matchCustomFilter(gf, obj);
						} else {
							let matches = 0;
							for (const of of gf) {
								if (matchFilter(of, obj, attrs)) {
									matches++;
								}
							}
							if (matches === gf.length) {
								groupMatch = true;
							}
						}
					}
				}
				if ((!groupFilter.not && !groupMatch) || (groupFilter.not && groupMatch)) {
					isMatch = false;
				}
			}
		} else {
			if (!matchFilter(filter, obj, attrs, throwIfNoAttr)) {
				isMatch = false;
			}
		}
	}

	return isMatch;
};
