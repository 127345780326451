import { Modal, ModalContent, ModalControls, ModalHeader } from '@upsales/components';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'Components/Helpers/translate';
import { asyncModalAdapter } from 'App/helpers/angularPortingHelpers';
import RequestBuilder from 'Resources/RequestBuilder';
import ComparisonTypes from 'Resources/ComparisonTypes';
import { makeCancelable } from 'Helpers/promise';
import logError from 'Helpers/logError';
import CustomField, { CustomFieldWithValue } from 'App/resources/Model/CustomField';
import { Metadata } from 'App/resources/AllIWant';
import { ModalProps } from '../Modals/Modals';
import { useCustomFields, useAccountSelf, useMetadata } from '../hooks/appHooks';
import { findAndReplaceItem } from 'Store/helpers/array';

export interface Props extends ModalProps<string | null> {
	customerId: number | undefined;
	duns: any;
	name: string;
	metadata: Metadata;
	className: string;
}

const ConfirmSoliditetBuyParent = (props: Props) => {
	const { t } = useTranslation();
	const { className, duns, name } = props;

	const anchor = useRef<Element | null>(null);
	useEffect(() => {
		if (!anchor.current) {
			anchor.current = document.querySelector('.Modals');
		}
	});

	const [matches, setMatches] = useState<any>([]);
	const [buying, setBuying] = useState(false);
	const [saving, setSaving] = useState(false);
	const { soliditetCredits } = useMetadata() || {};
	const customerId = useAccountSelf()?.client.id;

	const allCustomFields = useCustomFields('account') as CustomFieldWithValue[];

	const customFields = allCustomFields.reduce((res: CustomFieldWithValue[], field: CustomField) => {
		if (field.obligatoryField && field.editable && field.alias !== 'ORG_NO') {
			res.push({ ...field, fieldId: field.id, value: null });
		}
		return res;
	}, []);

	const [fields, setFields] = useState(customFields);

	const customFieldValueChange = (field: CustomFieldWithValue, value: string) => {
		const updatedFields = findAndReplaceItem(fields, { ...field, value }, (f: any) => f.id === field.id);
		setFields(updatedFields);
	};

	const valid = () => {
		return fields.every(f => f.value);
	};

	const useExisting = () => {
		setSaving(true);
		// Set duns on existing account
		const data = { id: matches[0].id, dunsNo: duns };
		if (!customerId) {
			logError('Error missing customer id');
			return;
		}
		Tools.Account.customer(customerId)
			.save(data)
			.then(() => {
				setMatches(matches.data[0]);
			})
			.catch(e => {
				logError(e, 'Failed saving account');
			});
	};

	const close = (account?: any) => {
		props.close(account);
	};

	const confirm = () => {
		setBuying(true);

		const options = {
			updateExisting: false,
			skipProjects: false,
			skipAccountManagers: false,
			skipAddresses: false,
			skipCategories: false
		};

		const properties: any[] = [];
		Tools.ActionProperties.mapCustomFields(properties, fields, 'Client');

		if (!customerId) {
			logError('Error missing customer id');
			return;
		}
		Tools.SoliditetClient.customer(customerId)
			.buy(duns, properties, options)
			.then((res: any) => {
				// Go to account
				setTimeout(() => {
					close(res.data);
				}, 2000);
			})
			.catch(() => {
				setBuying(false);
			});
	};

	useEffect(() => {
		const filter = new RequestBuilder();
		filter.addFilter(Tools.Account.attr.name, ComparisonTypes.Wildcard, name);
		filter.fields = ['id', 'name'];
		filter.limit = 1;

		const { promise, cancel } = makeCancelable(Tools.Account.customer(customerId ?? -1).find(filter.build()));

		promise
			.then(response => {
				setMatches(response.data);
			})
			.catch(() => {
				setMatches([]);
			});
		return cancel;
	}, []);

	return (
		<Modal size="lg" className={className}>
			<form
				autoComplete="off"
				onSubmit={e => {
					e.preventDefault();
					confirm();
				}}
			>
				<ModalHeader
					onClose={() => close()}
					title={`${t('soliditet.buy')} ${t('soliditet.companyDataFromSoliditet').toLowerCase()}`}
					icon="info-circle"
				></ModalHeader>
				<ModalContent>
					<div className="up-panel">
						<div className="up-panel-content">
							{matches.length ? (
								<>
									{t('soliditet.thereIsAnAccount')}
									<b>{matches[0].name}</b>{' '}
									{t('soliditet.inTheDb').toLowerCase() +
										'. ' +
										t('soliditet.wouldYouLikeToUseAsParent') +
										'?'}
									<br />
									<br />
									<button
										className="btn btn-tool"
										type="button"
										onClick={useExisting}
										disabled={saving || buying}
									>
										{!saving ? (
											`${t('default.yes')}, ${t('default.useExisting').toLowerCase()}`
										) : (
											<span className="fa fa-refresh fa-spin"></span>
										)}
									</button>
									<br />
									<br />
									<h2>
										{t('soliditet.buy')} {t('soliditet.companyDataFromSoliditet').toLowerCase()}
									</h2>
								</>
							) : (
								<>
									<p>
										{t('soliditet.youDontHave') + ' '}
										<b>{name}</b>{' '}
										{t('soliditet.inTheDb').toLowerCase() +
											'. ' +
											t('soliditet.doYouWantToBuy') +
											' ' +
											t('soliditet.companyDataFor').toLowerCase() +
											' ' +
											t('soliditet.fromSoliditet').toLowerCase() +
											'?'}
									</p>
								</>
							)}
							{soliditetCredits ? (
								<p>
									{t('soliditet.youHave')} <b>{soliditetCredits}</b>{' '}
									{t('soliditet.availableCompanyPurchases').toLowerCase()}.
								</p>
							) : (
								<p style={{ color: 'red' }}>{t('soliditet.notEnoughCredits') + '.'}</p>
							)}

							{fields.length ? (
								<>
									<div style={{ margin: '10px 0' }}>
										{t('default.youNeedToFillRequiredFields') + '.'}
									</div>

									<div className="row">
										{fields.map(field => (
											<div key={field.id} className="col-md-6" style={{ height: '80px' }}>
												<div className="form-group">
													<label>
														{field.name}{' '}
														{field.obligatoryField && <b className="text-danger"> *</b>}
													</label>
													<ReactTemplates.INPUTS.customFieldInput
														field={field}
														name={field.name}
														disabled={soliditetCredits === 0 || buying || saving}
														onChange={(value: string) =>
															customFieldValueChange(field, value)
														}
														anchor={anchor.current}
														multiple
														usenewdate
														useNewTime
														useNumberInput
													/>
												</div>
											</div>
										))}
									</div>
								</>
							) : null}
						</div>
					</div>
				</ModalContent>
				<ModalControls>
					<button
						type="submit"
						className="btn btn-submit btn-sm"
						disabled={!soliditetCredits || buying || saving || !valid()}
					>
						{!buying ? t('soliditet.buy') : <span className="fa fa-refresh fa-spin"></span>}
					</button>
					<button type="button" className="btn btn-link btn-sm" onClick={() => close(null)}>
						{t('default.abort')}
					</button>
				</ModalControls>
			</form>
		</Modal>
	);
};

export const openConfirmSoliditetBuyParentModal = asyncModalAdapter({
	upModalName: 'confirmSoliditetBuyParent',
	openModalName: 'ConfirmSoliditetBuyParentModal',
	featureFlag: 'REACT_CONFIRM_SOLIDITET_BUY_PARENT'
});

export default ConfirmSoliditetBuyParent;
