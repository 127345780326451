import Resource from './Resource';
import FileAttributes from 'Attributes/FileAttributes';
import config from 'App/babel/config';

class File extends Resource {
	maxUploadSize = 25000000;

	constructor() {
		super('files', FileAttributes);
	}

	parse(file) {
		file.$$previewUrl = this.getInlineThumbnailUrl(file.id);
		file.$$publicUrl = this.getPublicUrl(file.filename);

		return file;
	}

	getInlineThumbnailUrl(id) {
		const customerId = Tools.AppService.getCustomerId(); // not proud bout' this one
		return `${config.URL}${config.API}${customerId}/resources/download/internal/${id}?inline=true&thumbnail=true`;
	}

	getPublicUrl(name) {
		const meta = Tools.AppService.getMetadata();
		const encodedName = encodeURIComponent(name);
		return `${config.PUBLICPATH}${meta.publicUrlHash}/images/${encodedName}`;
	}

	getUploadUrl(opts) {
		let url = 'resources/upload/';

		if (opts.accountId) {
			url += `internal/client/${opts.accountId}/`;
			if (opts.folderId) {
				url += opts.folderId + '/';
			}
		} else if (opts.contactId) {
			url += `internal/contact/${opts.contactId}/`;
		} else if (opts.orderId) {
			url += `internal/order/${opts.orderId}/`;
		} else if (opts.isCreative) {
			url += 'external/ads/creative/';
		} else if (opts.library) {
			url += 'internal/general/0';
		} else if (opts.fileEntity) {
			url += `internal/${opts.fileEntity}/${opts.fileId || 0}`;
		} else if (opts.isMailAttachment) {
			return 'file/upload';
		} else if (opts.esign) {
			url = `${opts.customerId}/resources/upload/internal/esign/0`;
		}

		return url;
	}

	upload(file, opts = {}) {
		if (file && file.size > this.maxUploadSize) {
			return Promise.reject('file.tooLarge');
		}

		const formData = new FormData();
		formData.append('file', file);

		if (!opts.onUploadProgress) {
			opts.onUploadProgress = () => {};
		}

		return this._postRequest('', formData, {
			methodName: 'upload',
			_url: this.getUploadUrl(opts),
			onUploadProgress: progressEvent => opts.onUploadProgress(progressEvent.loaded / progressEvent.total)
		}).then(res => this.parse(res.data.data));
	}

	makePublic(id) {
		return this._putRequest(`${id}/public`, { methodName: 'makePublic' });
	}

	compose(sourcepath, composition) {
		return this._postRequest(
			'',
			{ sourcepath, composition },
			{ methodName: 'compose', _url: 'image/compose' }
		).then(res => this.parse(res.data.data));
	}
}

const resource = new File();

export default resource;
