import type { DependencyList } from 'react';
import { CancelablePromise, makeCancelable } from 'Helpers/promise';
import { useRef, useEffect, useCallback } from 'react';

const useMakePromiseSafe = (dependencyArray: DependencyList = []) => {
	const promises = useRef<Set<CancelablePromise>>(new Set());

	useEffect(() => () => promises.current.forEach(promise => promise.cancel()), dependencyArray);

	return useCallback((promise: Promise<any>) => {
		const cancelablePromise = makeCancelable(promise);
		promises.current.add(cancelablePromise);

		cancelablePromise.promise
			.then(() => {
				promises.current.delete(cancelablePromise);
			})
			.catch(() => {
				promises.current.delete(cancelablePromise);
			});

		return cancelablePromise.promise;
	}, []);
};

export default useMakePromiseSafe;
