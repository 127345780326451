import {
	Block,
	Flex,
	FullscreenModal,
	Icon,
	Label,
	Loader,
	ModalContent,
	ModalHeader,
	Select,
	Text,
	Title
} from '@upsales/components';
import React, { useEffect, useRef, useState } from 'react';
import { ModalProps } from '../Modals/Modals';
import EditorHeaderButton from 'Components/EditorHeader/EditorHeaderButton';
import bemClass from '@upsales/components/Utils/bemClass';
import { useTranslation } from 'Components/Helpers/translate';
import './MergeTickets.scss';
import Ticket from 'App/resources/Model/Ticket';
import TicketResource from 'App/resources/Ticket';
import RequestBuilder from 'Resources/RequestBuilder';
import logError from 'Helpers/logError';
import { SelectItem } from '@upsales/components/Utils/selectHelpers';
import { MultiSelectContext } from '../MultiselectProvider/MultiselectProvider';
import { useAnimationData } from '../hooks';
import Lottie from 'react-lottie';
import ComparisonTypes from 'Resources/ComparisonTypes';
import TicketAttributes from 'App/babel/attributes/Ticket';
import _ from 'lodash';
import openModal from 'App/services/Modal';
import { CancelablePromise, makeCancelable } from '@upsales/components/Utils/CancelablePromise';
import T from 'Components/Helpers/translate';

type Props = ModalProps & {
	rb: RequestBuilder;
	multiSelectContext: MultiSelectContext;
};

const formatSelectItem = (ticket: Ticket) => {
	let title = `${T('default.id2')} ${ticket.id} - ${ticket.title}`;
	if (ticket.client) {
		title += ` - ${ticket.client.name}`;
	}
	if (ticket.contact) {
		title += ` - ${ticket.contact.name}`;
	}

	return {
		id: ticket.id,
		title
	};
};

const MergeTickets = ({ className, close, rb, multiSelectContext }: Props) => {
	const [mainTicket, setMainTicket] = useState<Ticket | null>(null);
	const [selectedTickets, setSelectedTickets] = useState<Ticket[]>([]);
	const [allTickets, setAllTickets] = useState<Ticket[]>([]);
	const [loading, setLoading] = useState(true);
	const classes = new bemClass('MergeTickets', className);
	const { t } = useTranslation();
	const lang = {
		cancel: t('default.cancel'),
		confirm: t('default.merge.confirm'),
		title: t('ticket.merge.title'),
		paragraph1: t('ticket.merge.text.paragraph1'),
		paragraph2: t('ticket.merge.text.paragraph2'),
		paragraph3: t('ticket.merge.text.paragraph3'),
		select: t('default.select'),
		mainTicket: t('ticket.merge.label.mainTicket'),
		ticketsToMerge: t('ticket.merge.label.mergeTickets'),
		description: t('ticket.merge.description')
	};
	const anchor = useRef<Element | null>(null);
	const { animationData } = useAnimationData(() => import('./mergeTickets.json'));
	const allTicketsPromise = useRef<CancelablePromise<{
		data: Ticket[];
	}> | null>(null);
	const selectedTicketsPromise = useRef<CancelablePromise<{
		data: Ticket[];
	}> | null>(null);

	const getSelectedTickets = () => {
		const query = rb.build();
		selectedTicketsPromise.current = makeCancelable(TicketResource.find(query));
		selectedTicketsPromise.current.promise
			.then(res => {
				setSelectedTickets(res.data);
				return res.data;
			})
			.catch(err => {
				logError('Failed to get tickets', err);
			});
	};

	const getAllTickets = () => {
		const rb = new RequestBuilder();
		rb.addFilter(TicketAttributes.mergedWithId, ComparisonTypes.Equals, null);
		allTicketsPromise.current = makeCancelable(TicketResource.find(rb.build()));
		allTicketsPromise.current.promise
			.then(res => {
				setAllTickets(res.data);
			})
			.catch(err => {
				logError('Failed to get tickets', err);
			});
	};

	useEffect(() => {
		anchor.current = document.getElementsByClassName('MergeTickets')[0];
		getAllTickets();

		return () => {
			allTicketsPromise?.current?.cancel();
			selectedTicketsPromise?.current?.cancel();
		};
	}, []);

	useEffect(() => {
		if (multiSelectContext.allSelected || multiSelectContext.selectedIds.length === 0) {
			getSelectedTickets();
		} else {
			setSelectedTickets(
				allTickets.filter(ticket => multiSelectContext.selectedIds.find(id => id === ticket.id))
			);
		}
	}, [allTickets]);

	useEffect(() => {
		if (selectedTickets.length > 0) {
			setLoading(false);
		}
	}, [selectedTickets.length]);

	const changeMainTicket = (ticket: SelectItem) => {
		const selectedTicket = selectedTickets.find(t => t.id === ticket.id);

		if (selectedTicket) {
			setMainTicket(selectedTicket);
			const filteredSelectedTickets = selectedTickets.filter(t => t.id !== selectedTicket.id);
			if (mainTicket) {
				filteredSelectedTickets.push(mainTicket);
			}
			setSelectedTickets(filteredSelectedTickets);
		}
	};

	const changeSelectedTickets = (ticketId: number, add: boolean) => {
		const isSelected = selectedTickets.find(ticket => ticket.id === ticketId);

		if (add && !isSelected) {
			const ticketToAdd: Ticket | undefined = allTickets.find(t => t.id === ticketId);
			if (ticketToAdd) {
				setSelectedTickets([...selectedTickets, ticketToAdd]);
			}
		} else {
			const ticketToRemove: Ticket | undefined = selectedTickets.find(t => t.id === ticketId);
			if (ticketToRemove) {
				setSelectedTickets(selectedTickets.filter(t => t.id !== ticketToRemove.id));
			}
		}
	};

	const mergeTickets = async () => {
		const mainTicketCopy = _.cloneDeep(mainTicket)!;
		const rb = new RequestBuilder();
		rb.addFilter(
			TicketAttributes.id,
			ComparisonTypes.Equals,
			selectedTickets.map(t => t.id)
		);

		let involvedContactsFromMergedTickets: Ticket['involved'] = [];

		selectedTickets.forEach(ticket => {
			involvedContactsFromMergedTickets = [...involvedContactsFromMergedTickets, ...ticket.involved];
		});

		if (involvedContactsFromMergedTickets) {
			involvedContactsFromMergedTickets.forEach(ic => {
				if (ic.contact) {
					if (
						!mainTicketCopy.involved.some(i => i?.contact?.id === ic?.contact?.id) &&
						!mainTicketCopy.involved.some(i => i.email === ic.email)
					) {
						mainTicketCopy.involved.push({ ...ic, type: 'CC' });
					}
				} else {
					if (!mainTicketCopy.involved.some(i => i.email === ic.email)) {
						mainTicketCopy.involved.push({ ...ic, type: 'CC' });
					}
				}
			});
		}

		await TicketResource.mergeTickets({
			to: mainTicketCopy,
			from: selectedTickets,
			name: 'ticket'
		})
			.then(() => {
				multiSelectContext.selectNone();
				openModal('EditTicket', { ticketId: mainTicketCopy.id });
				close();
			})
			.catch(err => {
				logError('Failed to merge tickets', err);
			});
	};

	return (
		<FullscreenModal className={classes.b()} headerAtTop>
			<ModalHeader title={lang.title} alignContent="left">
				<Block className={classes.elem('controls').b()}>
					<EditorHeaderButton
						title={lang.cancel}
						onClick={close}
						supertitle={undefined}
						className={classes.elem('cancel').b()}
						noIcon
						next={false}
					/>
					<EditorHeaderButton
						title={lang.confirm}
						onClick={mergeTickets}
						supertitle={undefined}
						className={undefined}
						noIcon={false}
						next
						icon={'check'}
						disabled={selectedTickets.length === 0 || !mainTicket}
					/>
				</Block>
			</ModalHeader>
			<ModalContent color="grey-1">
				<Block backgroundColor="grey-1" className={classes.elem('info').b()}>
					<Flex>
						<Block>
							<Title color="black" bold size="lg">
								{lang.title}
							</Title>
							<Flex direction="column">
								<Text space="mtm" color="grey-11">
									{lang.paragraph1}
								</Text>
								<Text space="mtl" color="grey-11">
									{lang.paragraph2}
								</Text>
								<Text space="mtl" color="grey-11">
									{lang.paragraph3}
								</Text>
							</Flex>
						</Block>
						<Lottie options={{ animationData }} height={168} width={120} isClickToPauseDisabled={true} />
					</Flex>
				</Block>
				<Block border="s" borderColor="grey-6" borderRadius space="mtxl" className={classes.elem('select').b()}>
					{loading ? (
						<Flex justifyContent="center">
							<Loader />
						</Flex>
					) : (
						<>
							<Label>{lang.mainTicket}</Label>
							<Select
								options={(mainTicket ? [...selectedTickets, mainTicket] : selectedTickets).map(ticket =>
									formatSelectItem(ticket)
								)}
								value={mainTicket ? formatSelectItem(mainTicket) : null}
								onChange={changeMainTicket}
								placeholder={lang.select}
								anchor={anchor.current}
							/>
							<Flex alignItems="center" direction="column" space="mtl mbl">
								<Icon name="arrow-up" />
								<Text size="sm" space="mts">
									{lang.description}
								</Text>
							</Flex>
							<Label>{lang.ticketsToMerge}</Label>
							<Select
								options={allTickets
									.filter(ticket => ticket.id !== mainTicket?.id)
									.map(ticket => formatSelectItem(ticket))}
								value={selectedTickets.map(ticket => formatSelectItem(ticket))}
								onChange={v => changeSelectedTickets(v.id, true)}
								onRemove={v => changeSelectedTickets(typeof v === 'string' ? parseInt(v) : v, false)}
								multi
								anchor={anchor.current}
							/>
						</>
					)}
				</Block>
			</ModalContent>
		</FullscreenModal>
	);
};

export default MergeTickets;
