import RequestBuilder from 'Resources/RequestBuilder';
import { MultiSelectContext } from 'App/components/MultiselectProvider/MultiselectProvider';
import openModal from 'App/services/Modal';

export default function mergeTickets(rb: RequestBuilder, entity: string, selected: MultiSelectContext) {
	return openModal('MergeTickets', {
		rb,
		multiSelectContext: selected
	});
}
