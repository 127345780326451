import RequestBuilder from 'Resources/RequestBuilder';
import getAngularModule from 'App/babel/angularHelpers/getAngularModule';
import AppService from 'App/legacy/AppService';

const readSupportReplies = (rb: RequestBuilder, entity: string, selected: number, extraParams?: object) => {
	const properties = [{ name: 'isRead', value: true }];
	const MultiActions = getAngularModule('MultiActions');
	const customerId = AppService.getCustomerId();
	return MultiActions.customer(customerId).updateComment(selected, properties, rb.build());
};

export default readSupportReplies;
