import React, { Fragment, useEffect, useRef, useState, useMemo } from 'react';
import { Input, Card, Block, Text, Button, Icon, Loader, OutsideClick, Link } from '@upsales/components';
import T from 'Components/Helpers/translate';
import BemClass from '@upsales/components/Utils/bemClass';
import { SlideFade } from 'App/components/animations';
import './CreateCallClientContactSelect.scss';
import { CancelablePromise, makeCancelable } from 'App/babel/helpers/promise';
import Contact from 'App/resources/Model/Contact';
import Client from 'App/resources/Model/Client';
import RequestBuilder, { comparisonTypes } from 'Resources/RequestBuilder';
import ContactResource from 'App/resources/Contact';
import logError from 'Helpers/logError';
import ClientContactRow from './ClientContactRow';
import { useSoftDeployAccess } from 'App/components/hooks';
import { binaryGroup, fetchClientsFromCustomFields, setCustomClientFieldsFilters } from 'App/helpers/accountsHelper';

const LIMIT = 10;
const MIN_LENGTH = 2;

export type ClientValue = (Partial<Client> & Pick<Client, 'id' | 'name'>) | null;
export type ContactValue = (Partial<Contact> & Pick<Contact, 'id' | 'name'>) | null;

type Props = Omit<React.ComponentProps<typeof Block>, 'onChange'> & {
	className?: string;
	client?: ClientValue;
	contact?: ContactValue;
	onChange: (
		selected: { client: ClientValue | null; contact: null } | { client: Contact['client']; contact: Contact }
	) => void;
	clientId?: number;
	inline?: boolean;
	onlyContacts?: boolean;
	inputRef?: (r: HTMLInputElement) => void;
	disabled?: boolean;
};

const fetchContacts = async (
	searchString: string,
	clientIds: number[] | null,
	limit?: number,
	customFieldClientIds?: number[]
) => {
	limit = limit || LIMIT;
	const [clientId] = clientIds || [];
	return ContactResource.search(searchString, clientIds, limit, undefined, customFieldClientIds).then(res => {
		const sorted = res.data.sort((a, b) => (a.client.id === clientId ? -1 : 0));
		return { data: sorted, metadata: res.metadata };
	});
};

const fetchClients = async (searchString: string, hasSubAccounts: boolean = false) => {
	const { Account, AppService } = Tools;

	const accountFilter = new RequestBuilder();
	accountFilter.addFilter(Account.attr.active, comparisonTypes.Equals, 1);
	const orBuilder = accountFilter.orBuilder();
	orBuilder.next();
	orBuilder.addFilter(Account.attr.name, comparisonTypes.Search, searchString);

	setCustomClientFieldsFilters(orBuilder, searchString);

	orBuilder.done();
	if (hasSubAccounts) {
		accountFilter.addSort(Account.attr.operationalAccount.attr.id, true, 'first');
		accountFilter.fields = ['id', 'name', 'operationalAccount'];
	}

	accountFilter.addSort(Account.attr.name, true);

	accountFilter.limit = LIMIT;

	return Account.customer(AppService.getCustomerId()).find(accountFilter.build());
};

const search = (
	searchString: string,
	clientIds: number[] | null,
	onlyContacts: boolean = false,
	hasSubAccounts: boolean = false
) => {
	const promises: [Promise<{ data: Contact[] }>, Promise<{ data: Client[] }>] = [
		fetchClientsFromCustomFields(searchString).then(customClientIds =>
			fetchContacts(searchString, clientIds, undefined, customClientIds)
		),
		onlyContacts
			? Promise.resolve({
					data: [] as Client[],
					metadata: {
						total: 0
					}
			  })
			: fetchClients(searchString, hasSubAccounts)
	];

	return makeCancelable(Promise.all(promises).then(a => a.map(r => r.data))) as CancelablePromise<
		[Contact[], Client[]]
	>;
};

const groupContacts = (contacts: Contact[], operationalAccountId?: number) => {
	const predicate = (c: Contact) => (operationalAccountId ? c.client.id === operationalAccountId : false);
	return binaryGroup(contacts, predicate);
};

const header = (classes: BemClass, title: string, white?: boolean) => (
	<Block
		className={classes.elem('header').b()}
		backgroundColor={white ? 'white' : 'grey-2'}
		border="bs"
		borderColor="grey-4"
		space="ptm prm pbm plm"
	>
		<Text size="sm" bold>
			{T(title)}
		</Text>
	</Block>
);

// Feel free to move if needed elseware
function isElementInViewport(list: HTMLDivElement, el: HTMLDivElement) {
	const rect = el.getBoundingClientRect();
	const viewTop = list.scrollTop;
	const viewBottom = viewTop + list.offsetHeight;
	const _top = el.offsetTop;
	const _bottom = _top + rect.height;
	const compareTop = _top;
	const compareBottom = _bottom;

	return compareBottom <= viewBottom && compareTop >= viewTop;
}

const CreateCallClientContactSelect = ({
	className,
	onChange,
	client,
	contact,
	clientId,
	inputRef,
	disabled = false,
	inline = false,
	onlyContacts = false,
	...props
}: Props) => {
	const mainRef = useRef<HTMLDivElement>();
	const listRef = useRef<HTMLDivElement>();
	const localInputRef = useRef<HTMLInputElement | null>();
	const [open, setOpen] = useState(false);
	const [contacts, setContacts] = useState<Contact[]>([]);
	const [clients, setClients] = useState<Client[]>([]);
	const [mainContacts, setMainContacts] = useState<Contact[]>([]);
	const [subAccounts, setSubAccounts] = useState<Client[]>([]);
	const [searching, setSearching] = useState(false);
	const [searchString, setSearchString] = useState('');
	const [keys, setKeys] = useState<string[]>([]);
	const [highlighted, setHighlighted] = useState<string | null>(null);
	const hasSubAccounts = useSoftDeployAccess('SUB_ACCOUNTS');
	const classes = new BemClass('CreateCallClientContactSelect', className);
	const operationalAccountId = client?.operationalAccount?.id;

	const clientIds: number[] = [];
	if (client) {
		clientIds.push(client.id);
		if (hasSubAccounts && client.operationalAccount) {
			clientIds.push(client.operationalAccount.id);
		}
	} else if (clientId) {
		clientIds.push(clientId);
	}

	const clientIdsString = clientIds.join(',');

	const resetSearch = () => {
		setSearchString('');
		if (!onlyContacts) setClients([]);
		setContacts([]);
		setOpen(false);
	};
	const addContact = () => {
		// eslint-disable-next-line promise/catch-or-return
		Tools.$upModal.open('editContact', { clientId }).then((contact: Contact) => {
			onChange({ client: contact.client, contact });
		});
	};
	const scrollIntoView = (key: string | null) => {
		const element = listRef.current?.querySelectorAll<HTMLDivElement>(`[data-id="${key}"]`)[0];
		if (listRef.current && element && !isElementInViewport(listRef.current, element)) {
			element.scrollIntoView({ block: 'nearest' });
		}
	};
	const keyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
		// Do nothing if no keys or if pressed key not interesting
		if (
			(!keys.length && ['ArrowUp', 'ArrowDown', 'Enter'].includes(e.key)) ||
			!['ArrowUp', 'ArrowDown', 'Enter', 'Escape', 'Tab'].includes(e.key)
		) {
			return;
		}
		e.preventDefault();
		const key = highlighted || keys[0];
		const currentI = keys.indexOf(key);
		switch (e.key) {
			case 'ArrowUp':
			case 'ArrowDown': {
				let newKey = null;
				if (e.key === 'ArrowUp') {
					if (currentI === 0) {
						newKey = keys[keys.length - 1]; // go to end
					} else {
						newKey = keys[currentI - 1]; // go to prev
					}
				} else if (e.key === 'ArrowDown') {
					if (currentI === keys.length - 1) {
						newKey = keys[0]; // go to beginning
					} else {
						newKey = keys[currentI + 1]; // go to next
					}
				}
				setHighlighted(newKey);
				scrollIntoView(newKey);
				break;
			}
			case 'Tab':
			case 'Escape':
				resetSearch();
				localInputRef.current?.blur();
				break;
			case 'Enter':
				if (highlighted) {
					if (highlighted.startsWith('cli-')) {
						const client = clients.find(c => c.id === parseInt(highlighted.replace('cli-', ''))) || null;
						onChange({ client, contact: null });
					}
					if (highlighted.startsWith('con-')) {
						const contact = contacts.find(c => c.id === parseInt(highlighted.replace('con-', ''))) || null;
						if (contact) {
							onChange({ client: contact.client, contact });
						}
					}
					resetSearch();
				}
				break;
		}
	};

	// If not searched and we have a clientId we want to fetch all contact (if within limit of LIMIT)
	useEffect(() => {
		if (clientIds.length && !searchString) {
			const searchPromise = makeCancelable(fetchContacts('', clientIds, 500));
			searchPromise.promise
				.then(res => {
					if (res.data.length === res.metadata.total) {
						if (hasSubAccounts) {
							const [mainContacts, clientContacts] = groupContacts(res.data, operationalAccountId);
							setMainContacts(mainContacts);
							setContacts(clientContacts);
						} else {
							setContacts(res.data);
						}
						const keys = [...res.data.map((c: Contact) => `con-${c.id}`)];
						setKeys(keys);
						setHighlighted(keys[0] || null);
					}
				})
				.catch(e => logError(e, 'Failed to fetch contacts'));
			return () => {
				if (searchPromise) {
					searchPromise.cancel();
				}
			};
		}
	}, [searchString, clientIdsString]);

	useEffect(() => {
		let searchPromise: ReturnType<typeof search> | null = null;
		const debounce = setTimeout(() => {
			if (searchString.length >= MIN_LENGTH) {
				setSearching(true);
				searchPromise = search(searchString, clientIds ?? null, onlyContacts, hasSubAccounts);
				searchPromise.promise
					.then(([contacts, clients]) => {
						if (hasSubAccounts) {
							const [mainContacts, clientContacts] = groupContacts(contacts, operationalAccountId);
							setMainContacts(mainContacts);
							setContacts(clientContacts);
						} else {
							setContacts(contacts);
						}
						let keys = [...contacts.map(c => `con-${c.id}`)];
						if (clients) {
							if (hasSubAccounts) {
								const [subAccounts, accounts] = binaryGroup(
									clients,
									c => c.operationalAccount !== null
								);

								setClients(accounts);
								setSubAccounts(subAccounts);
							} else {
								setClients(clients);
							}
							keys = keys.concat(clients.map(c => `cli-${c.id}`));
						}
						setKeys(keys);
						setHighlighted(keys[0] || null);
						setSearching(false);
					})
					.catch(e => logError(e, 'Failed to search for contacts and clients'));
			}
		}, 300);
		return () => {
			if (debounce) {
				clearTimeout(debounce);
			}
			if (searchPromise) {
				searchPromise.cancel();
			}
		};
	}, [searchString, clientIdsString, onlyContacts, hasSubAccounts]);

	const permissionToCreateContact = useMemo(() => {
		const canAlwaysCreateContacts = Tools.AppService.getSelf().createRights.Contact === 'ALL';
		const canCreateContactsOnClient = client?.createRights?.Contact;
		return canAlwaysCreateContacts || canCreateContactsOnClient;
	}, [client]);

	const removeSelectedRow = () => {
		if (contact && client) {
			onChange({ client: client, contact: null });
		} else {
			onChange({ client: null, contact: null });
		}
	};

	let content;
	if ((onlyContacts && contact) || (!onlyContacts && (client || contact))) {
		content = inline ? (
			<div className={classes.elem('selected').mod('inline').b()}>
				<div>
					<Link
						onClick={e => e.stopPropagation()}
						href={Tools.$state.href('contact.dashboard', {
							id: contact?.id,
							customerId: Tools.AppService.getCustomerId()
						})}
					>
						{contact?.name}
					</Link>
				</div>
				<div>
					<Button type="link" color="grey" onClick={() => onChange({ client: null, contact: null })}>
						<Icon name="times" />
					</Button>
				</div>
			</div>
		) : (
			<Card className={classes.mod('normal').b()}>
				<Fragment>
					{contact ? (
						<ClientContactRow
							classes={classes}
							highlighted={null}
							contact={contact as Contact}
							removable={!disabled}
							removeSelectedRow={removeSelectedRow}
							extraIcons={true}
						/>
					) : null}
					{client && !contact ? (
						<ClientContactRow
							classes={classes}
							highlighted={null}
							removable={!disabled}
							removeSelectedRow={removeSelectedRow}
							// TODO Add type for sub account selection MM-14392
							client={client as ClientValue & Pick<Client, 'operationalAccount'>}
							extraIcons={false}
						/>
					) : null}
				</Fragment>
			</Card>
		);
	} else {
		content = (
			<OutsideClick targetRef={() => mainRef.current || null} listen={open} outsideClick={() => setOpen(false)}>
				<div ref={(r: HTMLInputElement) => (mainRef.current = r)}>
					<Input
						icon={inline ? undefined : 'search'}
						inputRef={r => {
							localInputRef.current = r;
							if (r) {
								inputRef?.(r);
							}
						}}
						placeholder={T(clientId ? 'default.searchContact' : 'todo.searchContactsAndCompanies')}
						onFocus={() => setOpen(true)}
						onKeyDown={keyDown}
						inline={inline}
						value={searchString}
						onChange={e => setSearchString(e.target.value)}
						disabled={disabled}
					/>
					<SlideFade direction="top" bounce visible={open}>
						<div className={classes.elem('list').b()} ref={(r: HTMLInputElement) => (listRef.current = r)}>
							{searchString.length < MIN_LENGTH && !contacts.length && !clients.length
								? header(classes, T('default.typeToSearch'), true)
								: null}
							{searching ? <Loader size="sm" /> : null}
							{contacts.length ? (
								<Fragment>
									{header(classes, 'default.contacts')}
									{contacts.map(contact => (
										<ClientContactRow
											key={`con-${contact.id}`}
											classes={classes}
											highlighted={highlighted}
											contact={contact as Contact}
											removable={false}
											onClick={() => {
												resetSearch();
												onChange({
													client: client ? (client as Contact['client']) : contact.client,
													contact
												});
											}}
											extraIcons={true}
										/>
									))}
								</Fragment>
							) : null}
							{hasSubAccounts && mainContacts.length ? (
								<Fragment>
									{header(
										classes,
										`${T('default.contacts')} ${T('default.fromMainAccount').toLowerCase()}`
									)}
									{mainContacts.map(contact => (
										<ClientContactRow
											key={`con-${contact.id}`}
											classes={classes}
											highlighted={highlighted}
											contact={contact as Contact}
											removable={false}
											onClick={() => {
												resetSearch();
												onChange({
													client: client ? (client as Contact['client']) : contact.client,
													contact
												});
											}}
											extraIcons={true}
										/>
									))}
								</Fragment>
							) : null}
							{clients.length ? (
								<Fragment>
									{header(classes, 'default.accounts')}
									{clients.map(client => (
										<ClientContactRow
											key={`cli-${client.id}`}
											classes={classes}
											highlighted={highlighted}
											removable={false}
											onClick={() => {
												resetSearch();
												onChange({ client, contact: null });
											}}
											client={client}
											extraIcons={false}
										/>
									))}
								</Fragment>
							) : null}
							{hasSubAccounts && subAccounts.length ? (
								<Fragment>
									{header(classes, 'account.subaccounts')}
									{subAccounts.map(client => (
										<ClientContactRow
											key={`cli-${client.id}`}
											classes={classes}
											highlighted={highlighted}
											removable={false}
											onClick={() => {
												resetSearch();
												onChange({ client, contact: null });
											}}
											client={client}
											extraIcons={false}
										/>
									))}
								</Fragment>
							) : null}
							{searchString && !searching && !contacts.length && !clients.length ? (
								<Text className={classes.elem('no-results').b()} center>
									{T('default.noResults')}
								</Text>
							) : null}
							{!searching && permissionToCreateContact ? (
								<Button
									block
									size="lg"
									color="bright-blue"
									type="link"
									onClick={addContact}
									tabIndex={-1}
								>
									<Icon name="user-plus" space="mrs" /> {T('default.createAContact')}
								</Button>
							) : null}
						</div>
					</SlideFade>
				</div>
			</OutsideClick>
		);
	}

	return (
		<Block {...props} className={classes.b()}>
			{content}
		</Block>
	);
};

export default CreateCallClientContactSelect;
